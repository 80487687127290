import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useMemo, useState } from 'react';

import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';
import {
  ONCE,
  TRANSITION_VARIANTS,
} from '@/hooks/framerMotion/useSetVariantOnLoadingEnd';
import useLiquidLockingInteraction from '@/hooks/interaction/useLiquidLockingInteraction';
import { useCollapse } from '@/hooks/layout/useCollapse';
import useTheme from '@/hooks/useTheme';

import { TOKEN_KEY } from '@/components/popups/XLaunchpadSnapshotPopup/types';
import WrappedLottiePlayer from '@/components/WrappedLottiePlayer';

import { useAppSelector } from '@/store/index';
import {
  liquidLockingAppSelector,
  UnlockedToken,
} from '@/store/liquid-locking-app';
import { getRouteGroup } from '@/store/popup';
import { protocolSelector } from '@/store/protocol';
import {
  TRANSACTION_SUBGROUP_TYPE,
} from '@/store/transaction';

import { getDateWithTime } from '@/sections/Lend/MyPosition/helpers';
import clsxm from '@/services/clsxm';
import { formatNumber } from '@/utils/helpers';

import ClaimButton from './components/ClaimButton';
import ClaimRow from './components/ClaimRow';
import TabButton from './components/TabButton';


export const TimerAnimation: React.FC<any> = () => {
  return (
    <WrappedLottiePlayer className='w-[12px]' src='https://cdn.app.hatom.com/animations/time.json' />
  );
};

const symbolMap: Record<string, string> = {
  [TOKEN_KEY.SEGLD]: 'sEGLD',
  [TOKEN_KEY.HSEGLD]: 'HsEGLD',
};

const ClaimsAndRequests: React.FC<{ isLaunchpad?: boolean }> = ({
  isLaunchpad,
}) => {
  const router = useRouter();
  const { t } = useTranslation('liquid-app');
  const [tab, setTab] = useState(0);
  const { unlockedTokens } = useAppSelector(liquidLockingAppSelector);
  const { signTransactions } = useSignMultipleTransactions();
  const { unbond } = useLiquidLockingInteraction();
  const { markets } = useAppSelector(protocolSelector);

  const { isDark } = useTheme();

  const activeClaims = useMemo(() => {
    const uniqueUnlockTokens = unlockedTokens
      .filter(
        ({ availableToRedeemDate }) =>
          new Date(availableToRedeemDate).getTime() <= Date.now(),
      )
      .reduce(
        (prev, current) => ({
          ...prev,
          [current.symbol]: {
            ...current,
            amount: new DefiUtils(prev[current.symbol]?.amount || 0)
              .plus(current.amount)
              .toString(),
          },
        }),
        {} as Record<string, UnlockedToken>,
      );

    return Object.values(uniqueUnlockTokens);
  }, [unlockedTokens]);

  const unstakeClaims = useMemo(
    () =>
      unlockedTokens.filter(
        ({ availableToRedeemDate }) =>
          new Date(availableToRedeemDate).getTime() > Date.now(),
      ),
    [unlockedTokens],
  );

  const activeTab = useMemo(() => {
    return !activeClaims?.length && unstakeClaims?.length ? 1 : 0;
  }, [activeClaims, unstakeClaims]);

  const [p1Ref, , p1Toggle] = useCollapse<HTMLDivElement>({
    defaultValue: true,
    callback: () => {},
  });

  const [p2Ref, , p2Toggle] = useCollapse({
    defaultValue: false,
    callback: () => {},
  });

  const handleTabClick = (tabI: number) => {
    if (tabI == tab) return;
    setTab(tabI);
    p1Toggle();
    p2Toggle();
  };

  useEffect(() => {
    handleTabClick(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const data = tab === 0 ? activeClaims : unstakeClaims;

  const decimalsMap = Object.values(markets).reduce(
    (prev, current) => {
      return {
        ...prev,
        [current.underlying.symbol]: current.underlying.decimals,
        [current.hToken.symbol]: current.hToken.decimals,
      };
    },
    {} as Record<string, number>,
  );

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        initial='hidden'
        custom={{ onlyY: true, movePx: 12 }}
        variants={TRANSITION_VARIANTS}
        whileInView='visible'
        viewport={{ once: ONCE, amount: 0.3 }}
        className='relative'
      >
        <div
          className={classNames(
            'flex flex-col gap-[4px]',
            'bg-[#F6F1FC] pl-[6.5px] py-[7px] md:pl-[10.5px] md:py-[11px] font-hass font-semibold dark:bg-[#463B70]',
            'rounded-[12px] border-[1.6px] border-[#D3D6EE] dark:border-[#6B5E9C]',
            isLaunchpad &&
              'bg-[#F7F6FF] border-[#F4F1FD] dark:border-[#6B5E9C]',
            'md:max-h-fit',
            'lg:min-w-auto',
            data.length >= 2 && 'min-h-[130px] lg:min-h-[159px]',
            isLaunchpad ? 'lg:gap-[8.6px] ' : 'lg:gap-[8px]',
          )}
        >
          <div className='w-full pr-[6.5px] md:pr-[10.5px]'>
            <div className='flex items-center justify-between border-b border-[#D3D6EE] dark:border-[#6B5E9C]'>
              <div className={clsxm(
                'flex gap-[2px] md:gap-[4px]',
                isLaunchpad ? 'lg:gap-[9px]' : 'lg:gap-[23px]'
              )}>
                <TabButton
                  className={clsxm(
                    isLaunchpad && 'md:!px-[19px] md:!pb-[10.8px] !text-[10px] lg:!text-[14px]  py-[8.6px] lg:pt-2.5 lg:pb-[9px] ',
                    isLaunchpad && tab === 0 && '!bg-white dark:!bg-[#191733] hover:!bg-[#EFEFF5] dark:hover:!bg-[#302D5A] hover:border-[#DCDCE3]',
                    isLaunchpad && tab !== 0 && '!text-[#535367] dark:!text-[#7F73AB] hover:bg-[#EFEDFF] dark:hover:bg-[#5A4D8D]'
                  )}
                  label={t('active-claims', 'Active Claims')}
                  active={tab === 0}
                  onClick={() => handleTabClick(0)}
                />
                <TabButton
                  className={clsxm(
                    isLaunchpad && 'md:!px-[18.9px] md:!pb-[10.8px] !text-[10px] lg:!text-[14px] py-[8.6px] lg:pt-2.5 lg:pb-[9px]',
                    isLaunchpad && tab === 1 && ' !bg-white dark:!bg-[#191733] hover:!bg-[#EFEFF5] dark:hover:!bg-[#302D5A] hover:border-[#DCDCE3]',
                    isLaunchpad && tab !== 1 && '!text-[#535367] dark:!text-[#7F73AB] hover:bg-[#EFEDFF] dark:hover:bg-[#5A4D8D]'
                  )}
                  label={t('unstake-requests', 'Unstake Requests')}
                  active={tab === 1}
                  onClick={() => handleTabClick(1)}
                />
              </div>
              {data.length >= 2 && tab === 0 && (
                <ClaimButton
                  label='Claim All'
                  className={clsxm(
                    isLaunchpad && ' lg:-translate-x-px lg:!pl-[20.2px] lg:!text-[12px] py-0 lg:!pr-[20px] rounded-[6.8px] lg:rounded-[8px] h-[21px] lg:h-[27px]'
                  )}
                  onClick={() => {
                    if (!data) {
                      return;
                    }

                    const tokensIdentifier = data.map(
                      ({ tokenIdentifier }) => tokenIdentifier,
                    );

                    signTransactions([unbond({ tokensIdentifier })], {
                      group: getRouteGroup(),
                      subgroup: TRANSACTION_SUBGROUP_TYPE.UNBOND_ALL,
                    });
                  }}
                />
              )}
            </div>
          </div>
          {data.length <= 0 ? (
            <m.div
              className={clsxm(
                'flex items-center justify-center w-full h-[60px] md:h-[50px]',
              )}
            >
              <span className={clsxm(
                'text-center text-[12px]  text-[#2F2F2F] dark:text-white',
                isLaunchpad && '!text-[10px] lg:!text-[14px] '
              )}>
                {tab == 0
                  ? 'You Have No Active Claims'
                  : 'You Have No Unstake Requests'}
              </span>
            </m.div>
          ) : (
            <m.div
              className={clsxm(
                'flex flex-col gap-[3px] max-h-[75px] lg:max-h-[60px] md:gap-[1px] w-full ',
              )}
            >
              <div className='w-full pr-[6.5px] md:pr-[10.5px]'>
                <ClaimRow
                  isLaunchpad={isLaunchpad}
                  col1={t('unstaked-tokens', 'Unstaked Tokens')}
                  claim={false}
                  className='text-[#6A6A8C] dark:text-[#7f73ab]'
                />
              </div>
              <div
                className={clsxm(
                  'relative max-h-[58px] lg:max-h-[62px] w-[99.4%]',
                )}
              >
                <div
                  className={clsxm(
                    'absolute -top-px left-0',
                    'w-full h-1 z-10',
                    isDark
                      ? 'bg-gradient-to-b from-[#463B70] from-20% to-95% to-transparent'
                      : 'bg-gradient-to-b from-[#F6F1FC] from-30% to-90% to-transparent',
                  )}
                />
                <div
                  className={clsxm(
                    'absolute -bottom-px left-0',
                    'w-full h-1 z-10',
                    isDark
                      ? 'bg-gradient-to-t from-[#463B70] from-20% to-95% to-transparent'
                      : 'bg-gradient-to-t from-[#F6F1FC] from-30% to-90% to-transparent',
                  )}
                />
                <div
                  className={clsxm(
                    'flex flex-col gap-[7px] lg:gap-[6px] max-h-[58px] lg:max-h-[62px]pr-[2.5px] md:pr-[6.5px]',
                    isLaunchpad ? 'pt-[5.3px]' : 'pt-0.5',
                    data.length >= 2 &&
                      'overflow-hidden scroll-container1 min-h-[58px] lg:min-h-[62px]',
                  )}
                >
                  {data.map(
                    ({
                      amount,
                      availableToRedeemDate,
                      symbol,
                      unbondEpoch,
                      tokenIdentifier,
                    }) => (
                      <ClaimRow
                        isLaunchpad={isLaunchpad}
                        key={`${symbol}-${unbondEpoch}`}
                        col1={`${formatNumber(
                          new DefiUtils(amount)
                            .toFullDecimals(decimalsMap[symbol] || 0)
                            .toString(),
                          2,
                        )} ${symbolMap[symbol]}`}
                        symbol={symbol}
                        timestamp={getDateWithTime(availableToRedeemDate, true)}
                        claim
                        tab={tab}
                        tokenIdentifier={tokenIdentifier}
                        amount={new DefiUtils(amount)
                          .toFullDecimals(decimalsMap[symbol] || 0)
                          .toString()}
                        img
                        className='text-[#2F2F2F] dark:text-white'
                      />
                    ),
                  )}
                </div>
              </div>
            </m.div>
          )}
        </div>
      </m.div>
    </LazyMotion>
  );
};

export default ClaimsAndRequests;
