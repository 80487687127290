import Head from 'next/head';
import { useMemo } from 'react';

import useTheme from '@/hooks/useTheme';

import { indexationEnabled } from '@/config/envVars';

interface MetaProps {
  title?: string;
  keywords?: string;
  description?: string;
}

const Meta: React.FC<MetaProps> = ({ title, keywords, description }) => {
  const { isDark } = useTheme();

  const bodyBg = useMemo(
    () => (isDark ? '#0C0B21' : '#FAFAFD'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDark]
  );

  const _title = `Hatom ${title?.toString() ? ` | ${title.toString()}` : ''}`;

  return (
    <Head>
      <meta charSet='UTF-8' />
      <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
      <link rel='icon' href='https://cdn.app.hatom.com/favicon.ico' />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />
      <meta name='keywords' content={keywords} />
      <meta name='content' content={description} />
      <title>{_title}</title>
      {!indexationEnabled && <meta name='robots' content='noindex' />}
      <meta name='theme-color' content={bodyBg} />
    </Head>
  );
};

Meta.defaultProps = {
  keywords: 'Hatom, Lending and Borrowing, Crypto assets, HTM',
  description:
    "MultiversX's First Platform for Lending and Borrowing Crypto Assets.",
};

export default Meta;
