import { CSSProperties, ReactElement, useContext, useEffect } from 'react';

import useImage from '@/hooks/useImage';

import { LoaderContext } from '@/contexts/LoaderContextProvider';

type WrappedImgProps = {
  callback?: (e: string) => void;
  fallBack?: ReactElement;
} & React.ImgHTMLAttributes<HTMLImageElement>;

const WrappedImg: React.FC<WrappedImgProps> = ({
  src,
  alt,
  className,
  style,
  id,
  fallBack,
  callback,
}) => {
  const { mark } = useContext(LoaderContext);

  // eslint-disable-next-line unused-imports/no-unused-vars
  const [imgTag, status] = useImage(src || '');

  useEffect(() => {
    if (status === 'loaded') {
      mark?.(`IMG: ${src && src.substring(src.lastIndexOf('/')).substring(1)}`);
      callback && callback('loaded');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  if (status === 'loading' && fallBack) {
    return fallBack;
  }

  return (
    <img
      id={id}
      style={style as CSSProperties}
      className={`loading-resource ${className}`}
      src={src}
      alt={alt ?? ''}
    />
  );
};

export default WrappedImg;
