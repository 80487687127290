import DefiUtils from 'defi-utils';

import { links } from '@/config/envVars';

export const socialMedia = [
  {
    name: 'discord',
    url: links.discordURL,
    icon: (
      <svg
        className='transform-colors cursor-pointer hover:text-indigo-200'
        width='16'
        height='12'
        viewBox='0 0 16 12'
        fill='currentColor'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M13.0693 1.02855C12.0706 0.561278 11.0027 0.221687 9.88628 0.0283203C9.74917 0.276209 9.58898 0.609625 9.47854 0.874856C8.29176 0.696378 7.11589 0.696378 5.95094 0.874856C5.84052 0.609625 5.6767 0.276209 5.53836 0.0283203C4.42073 0.221687 3.35165 0.562526 2.35294 1.03102C0.338551 4.07503 -0.207518 7.04344 0.0655168 9.96969C1.40157 10.9674 2.69636 11.5735 3.96931 11.9701C4.2836 11.5376 4.56391 11.0777 4.80539 10.5931C4.34549 10.4184 3.90499 10.2027 3.48878 9.95235C3.5992 9.87054 3.70721 9.78502 3.81156 9.69701C6.35017 10.8844 9.10843 10.8844 11.6167 9.69701C11.7223 9.78502 11.8303 9.87054 11.9395 9.95235C11.5221 10.2039 11.0804 10.4196 10.6204 10.5944C10.8619 11.0777 11.141 11.5388 11.4565 11.9713C12.7307 11.5747 14.0267 10.9687 15.3628 9.96969C15.6831 6.57742 14.8155 3.63627 13.0693 1.02855ZM5.15125 8.17007C4.38918 8.17007 3.76422 7.45864 3.76422 6.59228C3.76422 5.72593 4.37583 5.01327 5.15125 5.01327C5.92668 5.01327 6.55161 5.72468 6.53827 6.59228C6.53947 7.45864 5.92668 8.17007 5.15125 8.17007ZM10.277 8.17007C9.51496 8.17007 8.89001 7.45864 8.89001 6.59228C8.89001 5.72593 9.5016 5.01327 10.277 5.01327C11.0524 5.01327 11.6774 5.72468 11.6641 6.59228C11.6641 7.45864 11.0524 8.17007 10.277 8.17007Z' />
      </svg>
    ),
  },
  {
    name: 'github',
    url: links.githubURL,
    icon: (
      <svg
        className='transform-colors cursor-pointer hover:text-indigo-200'
        width='15'
        height='14'
        viewBox='0 0 15 14'
        fill='currentColor'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M7.60554 0C3.6422 0 0.428345 3.21329 0.428345 7.1772C0.428345 10.3483 2.48483 13.0386 5.33657 13.9877C5.69525 14.0541 5.82696 13.832 5.82696 13.6424C5.82696 13.4713 5.82026 12.9058 5.81722 12.3061C3.82047 12.7403 3.39914 11.4593 3.39914 11.4593C3.07266 10.6297 2.60225 10.4092 2.60225 10.4092C1.95109 9.9637 2.65133 9.97282 2.65133 9.97282C3.37206 10.0235 3.75157 10.7125 3.75157 10.7125C4.39169 11.8097 5.43057 11.4925 5.84013 11.3091C5.90453 10.8452 6.09057 10.5286 6.2958 10.3494C4.70168 10.1679 3.02583 9.55245 3.02583 6.80237C3.02583 6.01882 3.3062 5.37852 3.76536 4.87591C3.69083 4.69511 3.44518 3.96515 3.83488 2.97654C3.83488 2.97654 4.43757 2.78363 5.80912 3.71224C6.38158 3.55317 6.99559 3.47346 7.60554 3.47076C8.2155 3.47346 8.82995 3.55317 9.40355 3.71224C10.7735 2.78363 11.3753 2.97654 11.3753 2.97654C11.766 3.96515 11.5202 4.69511 11.4457 4.87591C11.9058 5.37852 12.1843 6.01876 12.1843 6.80237C12.1843 9.55898 10.5053 10.166 8.90713 10.3437C9.16454 10.5664 9.39392 11.0032 9.39392 11.6728C9.39392 12.6331 9.38559 13.406 9.38559 13.6424C9.38559 13.8334 9.51477 14.0572 9.87863 13.9867C12.7288 13.0366 14.7827 10.3473 14.7827 7.1772C14.7827 3.21329 11.5693 0 7.60554 0Z' />
        <path d='M3.11643 10.2242C3.10067 10.2599 3.04449 10.2706 2.99343 10.2461C2.94137 10.2227 2.9121 10.1741 2.92898 10.1383C2.94446 10.1016 3.00064 10.0913 3.05259 10.116C3.10477 10.1394 3.1345 10.1884 3.11643 10.2242ZM3.46947 10.5392C3.43525 10.571 3.36832 10.5562 3.3229 10.5061C3.27595 10.456 3.26717 10.3892 3.3019 10.3569C3.33719 10.3252 3.4021 10.34 3.44915 10.3901C3.4961 10.4407 3.50522 10.5072 3.46942 10.5393L3.46947 10.5392ZM3.71169 10.9423C3.66767 10.9728 3.59573 10.9442 3.55132 10.8804C3.50736 10.8166 3.50736 10.74 3.55228 10.7093C3.59686 10.6787 3.66767 10.7063 3.7127 10.7696C3.75661 10.8345 3.75661 10.911 3.71163 10.9423L3.71169 10.9423ZM4.12125 11.4091C4.0819 11.4524 3.99815 11.4408 3.93679 11.3816C3.87408 11.3237 3.85658 11.2416 3.89604 11.1982C3.93583 11.1548 4.0201 11.167 4.0819 11.2257C4.14422 11.2834 4.16324 11.3662 4.12131 11.4091H4.12125ZM4.6506 11.5667C4.63332 11.6229 4.5526 11.6484 4.47132 11.6246C4.39015 11.6 4.33701 11.5341 4.35339 11.4773C4.37028 11.4207 4.45133 11.3941 4.53323 11.4197C4.61429 11.4442 4.66754 11.5095 4.65065 11.5667H4.6506ZM5.25312 11.6335C5.25515 11.6927 5.18619 11.7418 5.10086 11.7429C5.01502 11.7447 4.94561 11.6968 4.94471 11.6386C4.94471 11.5789 5.01209 11.5302 5.09787 11.5288C5.18321 11.5271 5.25312 11.5747 5.25312 11.6335ZM5.84495 11.6108C5.85519 11.6686 5.79587 11.7279 5.71115 11.7437C5.62784 11.7589 5.55072 11.7232 5.54009 11.666C5.52973 11.6068 5.59018 11.5475 5.67332 11.5321C5.75821 11.5174 5.83414 11.5521 5.84495 11.6108Z' />
      </svg>
    ),
  },
  {
    name: 'medium',
    url: links.blogURL,
    icon: (
      <svg
        className='transform-colors cursor-pointer hover:text-indigo-200'
        width='16'
        height='12'
        viewBox='0 0 16 12'
        fill='currentColor'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M15.6234 1.85741H15.0364C14.8185 1.85741 14.5104 2.17179 14.5104 2.37301V9.66733C14.5104 9.86878 14.8185 10.1434 15.0364 10.1434H15.6234V11.8749H10.3055V10.1434H11.4186V2.47573H11.364L8.7648 11.8749H6.7525L4.1868 2.47573H4.12187V10.1434H5.23492V11.8749H0.782715V10.1434H1.35286C1.58759 10.1434 1.89577 9.86878 1.89577 9.66733V2.37295C1.89577 2.17173 1.58759 1.85735 1.35286 1.85735H0.782715V0.125977H6.34995L8.17779 6.92794H8.22822L10.0728 0.125977H15.6234V1.85741Z' />
      </svg>
    ),
  },
  {
    name: 'twitter',
    url: links.twitterURL,
    icon: (
      <svg
        className='transform-colors cursor-pointer hover:text-indigo-200'
        width='16'
        height='12'
        viewBox='0 0 16 12'
        fill='currentColor'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M14.7166 1.45918C14.49 1.55965 14.2571 1.64445 14.0192 1.71329C14.3009 1.39466 14.5157 1.01974 14.6468 0.609471C14.6762 0.517509 14.6458 0.416849 14.5702 0.356704C14.4947 0.296512 14.3898 0.289302 14.3067 0.338562C13.8013 0.638311 13.256 0.853726 12.6843 0.979691C12.1084 0.416942 11.3262 0.0966797 10.5175 0.0966797C8.81064 0.0966797 7.42195 1.48532 7.42195 3.19217C7.42195 3.3266 7.43047 3.46029 7.4473 3.59212C5.3292 3.40615 3.36005 2.36507 2.00783 0.706363C1.95964 0.647242 1.8854 0.615379 1.80939 0.621472C1.73334 0.627426 1.66501 0.670314 1.62658 0.736227C1.35233 1.20683 1.20734 1.74506 1.20734 2.2927C1.20734 3.03858 1.47364 3.74627 1.94406 4.29925C1.80102 4.24971 1.66222 4.1878 1.52974 4.11426C1.45862 4.07467 1.37182 4.07528 1.30116 4.11579C1.23046 4.15631 1.18613 4.23083 1.18427 4.31228C1.18394 4.326 1.18394 4.33972 1.18394 4.35363C1.18394 5.46699 1.78316 6.46936 2.69929 7.01569C2.62058 7.00783 2.54192 6.99643 2.46378 6.9815C2.38321 6.9661 2.30037 6.99434 2.24604 7.05578C2.19161 7.11718 2.17361 7.20277 2.19868 7.28092C2.53778 8.33962 3.41084 9.11835 4.46629 9.35576C3.5909 9.90405 2.58984 10.1912 1.53895 10.1912C1.31967 10.1912 1.09914 10.1783 0.883307 10.1528C0.776088 10.1401 0.673567 10.2034 0.637052 10.3053C0.600537 10.4073 0.639238 10.5211 0.730456 10.5795C2.08049 11.4452 3.64151 11.9027 5.24468 11.9027C8.39632 11.9027 10.3679 10.4165 11.4668 9.16975C12.8372 7.61514 13.6231 5.55741 13.6231 3.52425C13.6231 3.43931 13.6218 3.35354 13.6192 3.26804C14.1599 2.8607 14.6254 2.36773 15.0041 1.80112C15.0617 1.71506 15.0554 1.60133 14.9888 1.52211C14.9222 1.44285 14.8113 1.41722 14.7166 1.45918Z' />
      </svg>
    ),
  },
  {
    name: 'telegram',
    url: links.telegramURL,
    icon: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        height='14'
        viewBox='0 0 48 48'
        className='transform-colors -ml-1 scale-110 cursor-pointer hover:text-indigo-200'
        fill='currentColor'
      >
        <path d='M41.4193 7.30899C41.4193 7.30899 45.3046 5.79399 44.9808 9.47328C44.8729 10.9883 43.9016 16.2908 43.1461 22.0262L40.5559 39.0159C40.5559 39.0159 40.3401 41.5048 38.3974 41.9377C36.4547 42.3705 33.5408 40.4227 33.0011 39.9898C32.5694 39.6652 24.9068 34.7955 22.2086 32.4148C21.4531 31.7655 20.5897 30.4669 22.3165 28.9519L33.6487 18.1305C34.9438 16.8319 36.2389 13.8019 30.8426 17.4812L15.7331 27.7616C15.7331 27.7616 14.0063 28.8437 10.7686 27.8698L3.75342 25.7055C3.75342 25.7055 1.16321 24.0823 5.58815 22.459C16.3807 17.3729 29.6555 12.1786 41.4193 7.30899Z' />
      </svg>
    ),
  },
];

export const LOCKED_HTM_PRICE = '2';

export const WAD = 1e18;

export const WAD_WAD = WAD * WAD;

export const SECONDS_PER_DAY = '86400';

export const SECONDS_IN_A_YEAR = new DefiUtils(365)
  .multipliedBy(SECONDS_PER_DAY)
  .toNumber();
