import { domAnimation, LazyMotion, m } from 'framer-motion';
import React, { CSSProperties, useMemo, useRef } from 'react';
import { useHover, useUpdateEffect } from 'usehooks-ts';

import { mapValueInRange, shadeColor } from '@/utils/helpers';

export const BrandPieChartWrapper = React.forwardRef<any, any>(
  ({ children, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        width={160}
        height={160}
        viewBox='0 0 186 186'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <defs>
          <linearGradient id='placeholder-gradient'>
            <stop stopColor='#003A8C' />
            <stop offset='1' stopColor='#340175' />
          </linearGradient>
        </defs>
        {children}
      </svg>
    );
  }
);

const BrandPieChart: React.FC<any> = ({
  color,
  secondaryColor,
  percentage,
  rotateTurn = 0,
  barAnimDuration = 0.5,
  placeholder,
  tail,
  handleHover,
  // eslint-disable-next-line unused-imports/no-unused-vars
  valueUSD,
  ...props
}) => {
  const ref = useRef(null);
  const hoverOn = useHover(ref);
  const tailRef = useRef(null);
  const tailHoverOn = useHover(tailRef);

  const hover = useMemo(() => hoverOn || tailHoverOn, [hoverOn, tailHoverOn]);

  useUpdateEffect(() => {
    handleHover && handleHover(hover);
  }, [hover]);

  const strokeColor = useMemo(
    () =>
      percentage === 0
        ? '#fff0'
        : hoverOn || tailHoverOn
        ? secondaryColor
          ? secondaryColor
          : shadeColor(color, 0.7)
        : color,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hoverOn, color, percentage, tailHoverOn]
  );

  return (
    <>
      <LazyMotion features={domAnimation}>
        <m.ellipse
          {...props}
          className='transition-colors'
          ref={ref}
          stroke={placeholder ? 'url(#placeholder-gradient)' : strokeColor}
          cx='50%'
          cy='50%'
          rx={80}
          ry={80}
          style={
            {
              rotate: `${rotateTurn / 100}turn`,
            } as CSSProperties
          }
          strokeWidth={placeholder ? 26 : 22}
          strokeLinecap='round'
          // motion props
          initial={{ strokeDasharray: 500, strokeDashoffset: 500 }}
          animate={{
            strokeDashoffset: mapValueInRange(percentage, 0, 100, 500, 0),
          }}
          transition={{
            duration: placeholder ? 0.0 : barAnimDuration,
            ease: 'easeOut',
          }}
        />
      </LazyMotion>

      {tail && (
        <LazyMotion features={domAnimation}>
          <m.ellipse
            ref={tailRef}
            data-js='tail'
            className='tail transition-colors'
            stroke={strokeColor}
            cx='50%'
            cy='50%'
            rx={80}
            ry={80}
            strokeWidth={22}
            strokeLinecap='round'
            // motion props
            initial={{
              opacity: 0,
              rotate: `${rotateTurn / 100}turn`,
              strokeDasharray: 500,
              strokeDashoffset: 500,
            }}
            animate={{
              opacity: 1,
              rotate: `${(percentage + rotateTurn) / 100}turn`,
            }}
            transition={{
              delay: 0,
              duration: barAnimDuration,
              ease: 'easeOut',
            }}
          />
        </LazyMotion>
      )}
    </>
  );
};
export default BrandPieChart;
