import { motion } from 'framer-motion';

import { walletItemsAnimationsTransition } from '@/components/popups/ConnectBridgeWalletPopup/components/wallets/constants';

function BridgeWalletPhishingShape({
  className,
  fill,
  hoverFill,
  onClick,
}: {
  className?: string;
  fill: string;
  hoverFill: string;
  onClick: () => void;
}) {
  const variants = {
    normal: {
      fill,
    },
    hover: {
      fill: hoverFill,
    },
    transition: walletItemsAnimationsTransition,
  };
  return (
    <svg
      width='166'
      height='82'
      viewBox='0 0 166 82'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
      className={className}
    >
      <motion.path
        d='M0.0135498 20C0.0135498 8.9543 8.96786 0 20.0135 0H47.0135H145.014C156.059 0 165.014 8.9543 165.014 20V52.4297C165.014 58.9793 161.807 65.1139 156.429 68.8521L142.66 78.4224C139.31 80.7516 135.326 82 131.245 82H37.6671C31.8547 82 26.3297 79.4714 22.5306 75.0724L4.8771 54.6315C1.73978 50.9988 0.0135498 46.359 0.0135498 41.5591V20Z'
        variants={variants}
      />
    </svg>
  );
}

export default BridgeWalletPhishingShape;
