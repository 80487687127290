import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';
import DefiUtils from 'defi-utils';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import {
  formatExchangeRates,
  formatMarketsInteracted,
  formatProtocol,
  formatProtocolUpdated,
  formatUserBalances,
} from '@/store/parsers/protocol-parser';

import { smartContracts } from '@/config/envVars';
import { isMainnet } from '@/config/network';
import blockchainService from '@/services/blockchain';
import gatewayService from '@/services/gateway';
import * as indexService from '@/services/index';
import * as indexerService from '@/services/indexer';
import { padTwo, subtractGasFee } from '@/utils/helpers';
import logger from '@/utils/logger';
import { MAX_CACHE_TIME, queryClient } from '@/utils/query';
import { calcFunctionTime } from '@/utils/time';

import { LogoSet } from '@/types/token';

export const HIDDEN_DELEGATION_CONTRACTS: string[] = [
  // 'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqghllllsku0nzf'
];

export enum MARKET_KEY {
  EGLD = 'EGLD',
  MEX = 'MEX',
  HTM = 'HTM',
  RIDE = 'RIDE',
  USDC = 'USDC',
  USDT = 'USDT',
  BUSD = 'BUSD',
  UTK = 'UTK',
  sEGLD = 'SEGLD',
  WTAO = 'WTAO',
  sWTAO = 'SWTAO',
  USH = 'USH',
  WBTC = 'WBTC',
  WETH = 'WETH',
}

export const H_TOKEN_DECIMALS = 8;

export const TAO_SERVICE_FEE = '7';

export const getLogoSet = ([normal, nightMode, h, hNight]: (
  | number
  | string
  | [number, 'svg' | 'png']
  | null
)[]): LogoSet => {
  const isNumber = (x: any) => !isNaN(x),
    isArray = (x: any) => Array.isArray(x),
    isString = (x: any) => typeof x === 'string';

  const imgNbr = (i: any) => {
    const ext = isArray(i) ? i[1] : 'svg';
    const n = isArray(i) ? i[0] : i;
    return i ? `https://cdn.app.hatom.com/images/img${n}.${ext}` : '';
  };

  return {
    normal: isString(normal)
      ? `https://cdn.app.hatom.com/images/${normal}`
      : imgNbr(normal),
    nightMode: isString(nightMode)
      ? `https://cdn.app.hatom.com/images/${nightMode}`
      : imgNbr(nightMode),
    hLogo: isString(h) ? `https://cdn.app.hatom.com/images/${h}` : imgNbr(h),
    alt: isString(h) ? `https://cdn.app.hatom.com/images/${h}` : imgNbr(h),
    altDelta: isString(hNight)
      ? `https://cdn.app.hatom.com/images/${hNight}`
      : imgNbr(hNight),
  };
};

export const MARKET_ORDER_MAP: Record<string, number> = {
  [MARKET_KEY.EGLD]: 1,
  [MARKET_KEY.sEGLD]: 2,
  [MARKET_KEY.WTAO]: 3,
  [MARKET_KEY.sWTAO]: 4,
  [MARKET_KEY.HTM]: 5,
  [MARKET_KEY.USH]: 6,
  [MARKET_KEY.USDC]: 7,
  [MARKET_KEY.USDT]: 8,
  [MARKET_KEY.BUSD]: 9,
  [MARKET_KEY.MEX]: 10,
  [MARKET_KEY.UTK]: 11,
  [MARKET_KEY.RIDE]: 12,
};

export const LISTED_TOKENS = [
  MARKET_KEY.EGLD,
  MARKET_KEY.MEX,
  MARKET_KEY.HTM,
  MARKET_KEY.RIDE,
  MARKET_KEY.USDC,
  MARKET_KEY.USDT,
  MARKET_KEY.BUSD,
  MARKET_KEY.UTK,
  MARKET_KEY.sEGLD,
  MARKET_KEY.USH,
  MARKET_KEY.WBTC,
  MARKET_KEY.WETH,
];

const ASHSWAP_TOKEN_ID = 'ASH-a642d1';

export const OTHER_TOKENS_IDS = [ASHSWAP_TOKEN_ID];

export const TOKEN_LOGO_MAP = {
  WEGLD: getLogoSet([1, 1, 'tokens/h/EGLD.svg']),
  [MARKET_KEY.EGLD]: getLogoSet([1, 1, 'tokens/h/EGLD.svg']),
  [MARKET_KEY.MEX]: getLogoSet(['tokens/MEX.svg', null, 'tokens/h/MEX.svg']),
  [MARKET_KEY.HTM]: getLogoSet([[42, 'svg'], null, 'tokens/h/HTM.svg']),
  [MARKET_KEY.USDC]: getLogoSet([
    'tokens/USDC-v1.svg',
    null,
    'tokens/h/USDC.svg',
  ]),
  [MARKET_KEY.RIDE]: getLogoSet([
    'tokens/RIDE-v1.svg',
    null,
    'tokens/h/RIDE.svg',
  ]),
  [MARKET_KEY.USDT]: getLogoSet([
    'tokens/USDT-v1.svg',
    null,
    'tokens/h/USDT.svg',
  ]),
  [MARKET_KEY.BUSD]: getLogoSet([
    'tokens/BUSD-v1.svg',
    null,
    'tokens/h/BUSD.svg',
  ]),
  [MARKET_KEY.UTK]: getLogoSet(['UTK.svg', null, 'tokens/h/UTK.svg']),
  [MARKET_KEY.sEGLD]: getLogoSet([
    'tokens/SEGLD.svg',
    null,
    'tokens/h/SEGLD.svg',
  ]),
  [MARKET_KEY.USH]: getLogoSet(['tokens/USH.svg', null, 'tokens/h/USH.svg']),
  [MARKET_KEY.WBTC]: getLogoSet(['tokens/BTC.svg', null, 'tokens/h/BTC.svg']),
  [MARKET_KEY.WETH]: getLogoSet(['tokens/ETH.svg', null, 'tokens/h/ETH.svg']),
  [MARKET_KEY.WTAO]: getLogoSet(['tokens/WTAO.svg', null, 'tokens/h/WTAO.svg']),
  [MARKET_KEY.sWTAO]: getLogoSet([
    'tokens/SWTAO.svg',
    null,
    'tokens/h/SWTAO.svg',
  ]),
} as Record<
  string,
  {
    normal: string;
    nightMode: string;
    hLogo: string;
    alt: string;
    altDelta: string;
  }
>;

export const ASSET_ALT_NAME: Record<string, string> = {
  [MARKET_KEY.EGLD]: 'MultiversX',
  [MARKET_KEY.sEGLD]: 'Staked MultiversX',
  [MARKET_KEY.WTAO]: 'Wrapped TAO',
  [MARKET_KEY.sWTAO]: 'Staked WTAO',
  [MARKET_KEY.MEX]: 'xExchange',
  [MARKET_KEY.UTK]: 'xMoney',
  [MARKET_KEY.HTM]: 'Hatom',
  [MARKET_KEY.USDT]: 'Wrapped USDT',
  [MARKET_KEY.USDC]: 'Wrapped USDC',
  [MARKET_KEY.BUSD]: 'Wrapped BUSD',
  [MARKET_KEY.WBTC]: 'Wrapped BTC',
  [MARKET_KEY.WETH]: 'Wrapped ETH',
};

export const LISTED_MARKETS = [
  MARKET_KEY.EGLD,
  MARKET_KEY.HTM,
  MARKET_KEY.RIDE,
  MARKET_KEY.USDC,
  MARKET_KEY.USDT,
  MARKET_KEY.BUSD,
  MARKET_KEY.UTK,
  MARKET_KEY.sEGLD,
  MARKET_KEY.sWTAO,
  MARKET_KEY.WTAO,
  ...(!isMainnet ? [MARKET_KEY.MEX, MARKET_KEY.USH] : []),
];

export enum H_MARKET_KEY {
  HEGLD = 'HEGLD',
  HMEX = 'HMEX',
  HHTM = 'HHTM',
  HRIDE = 'HRIDE',
  HUSDC = 'HUSDC',
  HUSDT = 'HUSDT',
  HBUSD = 'HBUSD',
  HUTK = 'HUTK',
  HsEGLD = 'HSEGLD',
  HsWTAO = 'HSWTAO',
  HWTAO = 'HWTAO',
  HUSH = 'HUSH',
}

export const defaultUserBalance: UserBalance = {
  borrowBalance: '0',
  collateralBalance: '0',
  underlyingCollateralBalance: '0',
  underlyingCollateralBalanceWithoutSimulate: '0',
  underlyingBalance: '0',
  hTokenBalance: '0',
};

export const defaultLogo = {
  normal: '',
  nightMode: '',
  hLogo: '',
  alt: '',
  altDelta: '',
};

export const defaultMarket: Market = {
  address: '',
  collateralFactor: '0',
  liquidationIncentive: '1000000000000000000',
  cash: '0',
  underlying: {
    id: '',
    name: '',
    decimals: 0,
    symbol: '',
    priceUSD: '0',
  },
  hToken: {
    id: '',
    name: '',
    decimals: 0,
    symbol: '',
    priceUSD: '0',
  },
  hTokenExchangeRate: '0',
  hTokenExchangeRateWithoutSimulate: '0',
  totalSupply: '0',
  totalBorrow: '0',
  totalSupplyUSD: '0',
  totalBorrowUSD: '0',
  supplyAPY: '0',
  borrowAPY: '0',
  logo: defaultLogo,
  supported: false,
  supplyCap: 'Infinity',
  borrowCap: 'Infinity',
  mintStatus: 'Active',
  borrowStatus: 'Active',
  mintEnabled: true,
  borrowEnabled: true,
};

export const fakeMarketsList: string[] = [MARKET_KEY.WTAO, MARKET_KEY.sWTAO];

export const fakeMarkets = ({}: {
  marketPricesTuple: indexerService.ResponseTokenPricesTuple;
  wtaoId?: string;
  hwtaoId?: string;
  swtaoId?: string;
  hswtaoId?: string;
}): Record<string, Market> => {
  return {};
};

export interface Market {
  address: string;
  collateralFactor: string;
  liquidationIncentive: string;
  cash: string;
  underlying: {
    id: string;
    name: string;
    decimals: number;
    symbol: string;
    priceUSD: string;
  };
  hToken: {
    id: string;
    name: string;
    decimals: number;
    symbol: string;
    priceUSD: string;
  };
  hTokenExchangeRate: string; // exchange rate with simulate
  hTokenExchangeRateWithoutSimulate: string;
  supported: boolean;
  totalSupply: string;
  totalBorrow: string;
  totalSupplyUSD: string;
  totalBorrowUSD: string;
  supplyAPY: string;
  borrowAPY: string;
  logo: LogoSet;
  supplyCap: string;
  borrowCap: string;
  mintStatus: string;
  borrowStatus: string;
  mintEnabled: boolean;
  borrowEnabled: boolean;
}

export interface Governance {
  address: string;
  votingDelayInBlocks: string;
  quorum: string;
  votingPeriodInBlocks: string;
  voteNftId: string;
  blockNonce: number;
}

export interface LiquidStaking {
  undelegateTokenId: string;
  address: string;
  lsTokenId: string;
  zapAddress: string;
  totalStakers: string;
  segldStakers: string;
  hsegldStakers: string;
  totalStaked: string;
  totalStakedUSD: string;
  totalFee: string;
  apy: string;
  unbondPeriod: string;
  minEgldToDelegate: string;
  exchangeRate: string; // egld to segld
}

export interface LiquidStakingTao {
  address: string;
  lsTokenId: string;
  totalFee: string;
  totalStaked: string;
  totalStakedUSD: string;
  exchangeRate: string;
  apy: string;
  totalStakers: string;
  zapAddress: string;
  tokenSupply: string; // minted-burn
}

export interface Controller {
  address: string;
  maxMarketsPerAccount: string;
}

export interface LiquidLocking {
  address: string;
  unbondPeriod: string;
}

export interface Blockchain {
  initialRoundsPassed: number;
  initialEpoch: number;
  epoch: number;
  roundsPassed: number;
  roundsPerEpoch: number;
  timeLeftInSeconds: number;
  timestamp: string;
}

export interface Booster {
  address: string;
  stakingRatioThreshold: string;
  cooldownPeriod: string;
  totalStaked: string;
}

export interface UserBalance {
  borrowBalance: string; // underlying borrow taken
  collateralBalance: string; // collateral in a market as h token
  underlyingCollateralBalance: string; // collateral in a market as underlying
  underlyingCollateralBalanceWithoutSimulate: string; // collateral in a market as underlying wihout simulate
  underlyingBalance: string; // uderlying balance in wallet
  hTokenBalance: string; // h token balance in wallet
}

export interface MarketInteracted {
  address: string;
  canRemove: boolean;
}

export interface ProtocolState {
  markets: Record<MARKET_KEY, Market>;
  governance: Governance;
  liquidStaking: LiquidStaking;
  liquidStakingTao: LiquidStakingTao;
  controller: Controller;
  liquidLocking: LiquidLocking;
  blockchain: Blockchain;
  userBalances: Record<MARKET_KEY, UserBalance>;
  exchangeRates: Record<string, string>;
  marketsInteractedAmount: number;
  booster: Booster;
  marketsInteracted: MarketInteracted[];
}

const initialState: ProtocolState = {
  markets: {} as Record<MARKET_KEY, Market>,
  governance: {
    address: '',
    votingDelayInBlocks: '0',
    quorum: '0',
    votingPeriodInBlocks: '0',
    voteNftId: '',
    blockNonce: 0,
  },
  liquidLocking: {
    address: smartContracts.liquidLockingAddress,
    unbondPeriod: '0',
  },
  liquidStaking: {
    address: '',
    undelegateTokenId: '',
    lsTokenId: '',
    zapAddress: '',
    totalStakers: '0',
    hsegldStakers: '0',
    segldStakers: '0',
    totalFee: '0',
    totalStaked: '0',
    totalStakedUSD: '0',
    apy: '0',
    unbondPeriod: '0',
    minEgldToDelegate: '0',
    exchangeRate: '0',
  } as LiquidStaking,
  liquidStakingTao: {
    address: '',
    lsTokenId: '',
    totalFee: '0',
    totalStaked: '0',
    totalStakedUSD: '0',
    exchangeRate: '0',
    apy: '0',
    totalStakers: '0',
    zapAddress: '',
    tokenSupply: '0',
  },
  controller: {
    address: '',
    maxMarketsPerAccount: '0',
  },
  blockchain: {
    initialRoundsPassed: 0,
    initialEpoch: 0,
    epoch: 0,
    roundsPassed: 0,
    roundsPerEpoch: 0,
    timeLeftInSeconds: 0,
    timestamp: '2023-07-15T15:00:00.000Z',
  },
  userBalances: {} as Record<MARKET_KEY, UserBalance>,
  exchangeRates: {} as Record<string, string>,
  marketsInteractedAmount: 0,
  booster: {
    address: '',
    stakingRatioThreshold: '0',
    cooldownPeriod: '0',
  } as Booster,
  marketsInteracted: [],
};

export const protocolSlice = createSlice({
  name: 'protocol',
  initialState,
  reducers: {
    setProtocol: (state, action: PayloadAction<Partial<ProtocolState>>) => {
      Object.entries(action.payload).map(([key, value]) => {
        state[key as keyof ProtocolState] = value;
      });
    },

    setBlockchain: (state, action: PayloadAction<Partial<Blockchain>>) => {
      Object.entries(action.payload).map(([key, value]) => {
        state.blockchain[key as keyof Blockchain] = value;
      });
    },

    updateLiquidStaking: (
      state,
      action: PayloadAction<Partial<LiquidStaking>>,
    ) => {
      Object.entries(action.payload).map(([key, value]) => {
        state.liquidStaking[key as keyof LiquidStaking] = value;
      });
    },

    updateLiquidStakingTao: (
      state,
      action: PayloadAction<Partial<LiquidStakingTao>>,
    ) => {
      Object.entries(action.payload).map(([key, value]) => {
        state.liquidStakingTao[key as keyof LiquidStakingTao] = value;
      });
    },

    setUserBalances: (
      state,
      action: PayloadAction<Partial<Record<MARKET_KEY, UserBalance>>>,
    ) => {
      Object.entries(action.payload).map(([key, value]) => {
        state.userBalances[key as keyof Record<MARKET_KEY, UserBalance>] = {
          ...defaultUserBalance,
          ...state.userBalances[key as keyof Record<MARKET_KEY, UserBalance>],
          ...value,
        };
      });
    },
  },
});

export const {
  setProtocol,
  setBlockchain,
  setUserBalances,
  updateLiquidStaking,
  updateLiquidStakingTao,
} = protocolSlice.actions;

const getLiquidStakingStats = async ({
  address,
  zapAddress,
}: {
  address: string;
  zapAddress: string;
}) => {
  const [segldStakers, hsegldStakers, totalStakers] = await Promise.all([
    calcFunctionTime(
      '0. x. getProtocol/indexService.getLiquidStakingStakers({liquidStakingAddress})',
      () =>
        queryClient.fetchQuery({
          queryKey: ['getLiquidStakingStakers', address],
          queryFn: async () => {
            const data = await indexService
              .getLiquidStakingStakers({
                liquidStakingAddress: address,
              })
              .catch(() => {});

            return data || 0;
          },
          cacheTime: MAX_CACHE_TIME,
          staleTime: MAX_CACHE_TIME,
        }),
    ),
    calcFunctionTime(
      '0. y. getProtocol/indexService.getLiquidStakingStakers({zapAddress})',
      () =>
        queryClient.fetchQuery({
          queryKey: ['getLiquidStakingStakers', zapAddress],
          queryFn: async () => {
            const data = await indexService
              .getLiquidStakingStakers({
                zapAddress,
              })
              .catch(() => {});
            return data || 0;
          },
          cacheTime: MAX_CACHE_TIME,
          staleTime: MAX_CACHE_TIME,
        }),
    ),
    calcFunctionTime(
      '0. z. getProtocol/indexService.getLiquidStakingStakers({liquidStakingAddress,zapAddress})',
      () =>
        queryClient.fetchQuery({
          queryKey: ['getLiquidStakingStakers', address, zapAddress],
          queryFn: async () => {
            const data = await indexService
              .getLiquidStakingStakers({
                liquidStakingAddress: address,
                zapAddress,
              })
              .catch((error) => {});
            return data || 0;
          },
          cacheTime: MAX_CACHE_TIME,
          staleTime: MAX_CACHE_TIME,
        }),
    ),
  ]);

  return {
    segldStakers,
    hsegldStakers,
    totalStakers,
  };
};

const getLiquidStakingTaoStats = async ({
  liquidStakingAddress,
  zapAddress,
}: {
  liquidStakingAddress: string;
  zapAddress: string;
}) => {
  if (liquidStakingAddress.length === 0) {
    return {
      totalStakers: '0',
    };
  }

  const [totalStakers] = await Promise.all([
    calcFunctionTime(
      '0. v. getProtocol/indexService.getLiquidStakingStakers({liquidStakingAddress,zapAddress})',
      () =>
        queryClient.fetchQuery({
          queryKey: ['liquidStaking', liquidStakingAddress, zapAddress],
          queryFn: async () => {
            const data = await indexService
              .getLiquidStakingStakers({
                liquidStakingAddress,
                zapAddress,
              })
              .catch(() => {});

            return data || 0;
          },
          cacheTime: MAX_CACHE_TIME,
          staleTime: MAX_CACHE_TIME,
        }),
    ),
  ]);

  return {
    totalStakers,
  };
};

export const getProtocol =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const { protocol } = getState();

      const [
        protocolResponse,
        marketPricesMap,
        networkStatus,
        liquidLockingUnbondPeriod,
      ] = await Promise.all([
        calcFunctionTime(
          '0. a. getProtocol/blockchain.protocol.getProtocol',
          () => blockchainService.protocol.getProtocol(),
        ),
        calcFunctionTime(
          '0. b. getProtocol/indexerService.getTokenPricesV2',
          () => blockchainService.lens.getTokenPricesV2(),
        ),
        calcFunctionTime(
          '0. c. getProtocol/gatewayService.network.getNetworkStatus',
          () => gatewayService.network.getNetworkStatus(),
        ),
        calcFunctionTime(
          '0. d. getProtocol/blockchainService.liquidLocking.getUnbondPeriod',
          () =>
            queryClient.fetchQuery({
              queryKey: ['LiquidLockingUnbondPeriod'],
              queryFn: async () => {
                const data = await blockchainService.liquidLocking
                  .getUnbondPeriod(protocol.liquidLocking.address)
                  .catch(() => {});

                return data || 0;
              },
              cacheTime: MAX_CACHE_TIME,
              staleTime: MAX_CACHE_TIME,
            }),
        ),
      ]);

      const formattedProtocol = formatProtocol(
        protocolResponse,
        marketPricesMap,
        networkStatus,
        {},
      );

      const exchangeRates = formatExchangeRates(
        formattedProtocol.markets,
        formattedProtocol.liquidStaking,
        formattedProtocol.liquidStakingTao,
      );

      await dispatch(
        setProtocol({
          ...formattedProtocol,
          exchangeRates,
          liquidLocking: {
            address: protocol.liquidLocking.address,
            unbondPeriod: liquidLockingUnbondPeriod,
          } as LiquidLocking,
        }),
      );

      getLiquidStakingStats({
        address: protocolResponse.liquidStaking.address,
        zapAddress: protocolResponse.liquidStaking.zapAddress,
      }).then((liquidStakingStats) => {
        dispatch(
          updateLiquidStaking({
            segldStakers: liquidStakingStats.segldStakers.toString(),
            hsegldStakers: liquidStakingStats.hsegldStakers.toString(),
            totalStakers: liquidStakingStats.totalStakers.toString(),
          }),
        );
      });

      getLiquidStakingTaoStats({
        liquidStakingAddress: protocolResponse.liquidStakingTao.address,
        zapAddress: protocolResponse.liquidStakingTao.zapAddress,
      }).then((liquidStakingTaoStats) => {
        dispatch(
          updateLiquidStakingTao({
            totalStakers: liquidStakingTaoStats.totalStakers.toString(),
          }),
        );
      });
    } catch (error) {
      logger.error('store:getProtocol', error);
      captureException(error);
    }
  };

export const updateProtocol =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();
      const { address: accountAddress } = state.auth.account;
      const { markets, controller } = state.protocol;

      const [
        protocolResponse,
        accountMarketsResponse,
        marketPricesMap,
        accountMarketsList,
      ] = await Promise.all([
        calcFunctionTime(
          '1. a. before:promisesByRoute:updateProtocol 1. blockchainService.protocol.getProtocolUpdated',
          () => blockchainService.protocol.getProtocolUpdated(),
        ),
        calcFunctionTime(
          '1. a. before:promisesByRoute:updateProtocol 2. blockchainService.protocol.getProtocolAccountMarkets',
          () =>
            blockchainService.protocol.getProtocolAccountMarkets(
              accountAddress,
              Object.values(markets).map(({ address, underlying }) => ({
                address,
                symbol: underlying.symbol,
              })),
            ),
        ),
        calcFunctionTime(
          '1. a. before:promisesByRoute:updateProtocol 3. indexerService.getTokenPricesTuple',
          () => blockchainService.lens.getTokenPricesV2(),
        ),
        calcFunctionTime(
          '1. a. before:promisesByRoute:updateProtocol 4. blockchainService.controller.getAccountMarkets',
          () =>
            blockchainService.controller.getAccountMarkets(
              controller.address,
              accountAddress,
            ),
        ),
      ]);

      const formattedProtocol = formatProtocolUpdated(
        state.protocol,
        protocolResponse,
        marketPricesMap,
        {},
      );

      const userBalances = formatUserBalances(
        formattedProtocol.markets,
        accountMarketsResponse,
      );

      const exchangeRates = formatExchangeRates(
        formattedProtocol.markets,
        formattedProtocol.liquidStaking,
        formattedProtocol.liquidStakingTao,
      );

      const marketsInteracted = formatMarketsInteracted({
        accountMarketsList,
        markets,
        userBalances,
      });

      await Promise.all([
        dispatch(setUserBalances(userBalances)),
        dispatch(
          setProtocol({
            ...formattedProtocol,
            exchangeRates,
            marketsInteractedAmount: accountMarketsList.length,
            marketsInteracted,
          }),
        ),
      ]);

      getLiquidStakingStats({
        address: formattedProtocol.liquidStaking.address,
        zapAddress: formattedProtocol.liquidStaking.zapAddress,
      }).then((liquidStakingStats) => {
        dispatch(
          updateLiquidStaking({
            segldStakers: liquidStakingStats.segldStakers.toString(),
            hsegldStakers: liquidStakingStats.hsegldStakers.toString(),
            totalStakers: liquidStakingStats.totalStakers.toString(),
          }),
        );
      });

      getLiquidStakingTaoStats({
        liquidStakingAddress: formattedProtocol.liquidStakingTao.address,
        zapAddress: formattedProtocol.liquidStakingTao.zapAddress,
      }).then((liquidStakingTaoStats) => {
        dispatch(
          updateLiquidStakingTao({
            totalStakers: liquidStakingTaoStats.totalStakers.toString(),
          }),
        );
      });
    } catch (error) {
      logger.error('store:updateProtocol', error);
      captureException(error);
    }
  };

export const subtractTimeLeftInSeconds =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    const state = getState();
    const { timestamp, initialEpoch, initialRoundsPassed, roundsPerEpoch } =
      state.protocol.blockchain;

    const timestampInSeconds = +(new Date(timestamp).getTime() / 1000).toFixed(
      0,
    );
    const currentTimestampInSeconds = +(new Date().getTime() / 1000).toFixed(0);

    const diffTimestampInSeconds =
      currentTimestampInSeconds - timestampInSeconds;

    const roundsPassed = parseInt(
      (
        (initialRoundsPassed + diffTimestampInSeconds / 6) %
        roundsPerEpoch
      ).toString(),
    );

    const roundsPassedInSeconds = (roundsPerEpoch - roundsPassed) * 6;

    const timeLeftInSeconds =
      roundsPassedInSeconds - (currentTimestampInSeconds - timestampInSeconds);

    const epoch = parseInt(
      (
        initialEpoch +
        (initialRoundsPassed + diffTimestampInSeconds / 6) / roundsPerEpoch
      ).toString(),
    );

    dispatch(
      setBlockchain({
        timeLeftInSeconds,
        roundsPassed,
        epoch,
      }),
    );
  };

export const nextEpochTimeLeftSelector = (state: RootState) => {
  const { timeLeftInSeconds } = state.protocol.blockchain;

  const safeTimeLeftInSeconds = timeLeftInSeconds <= 0 ? 0 : timeLeftInSeconds;

  const timeLeftInMiliseconds = safeTimeLeftInSeconds * 1000;

  const seconds = Math.floor((timeLeftInMiliseconds / 1000) % 60);
  const minutes = Math.floor((timeLeftInMiliseconds / 1000 / 60) % 60);
  const hours = Math.floor((timeLeftInMiliseconds / (1000 * 60 * 60)) % 24);
  const days = Math.floor(timeLeftInMiliseconds / (1000 * 60 * 60 * 24));

  const list = [
    ...(days !== 0 ? [`${padTwo(days)}d`] : []),
    ...(hours !== 0 ? [`${padTwo(hours)}h`] : []),
    ...(minutes !== 0 ? [`${padTwo(minutes)}m`] : []),
    ...(seconds !== 0 ? [`${padTwo(seconds)}s`] : []),
  ];

  return list.join(' ');
};

export const nativeMarketSelector = (state: RootState) => {
  const markets = state.protocol.markets;

  return markets?.[MARKET_KEY.EGLD] || defaultMarket;
};

export const wtaoMarketSelector = (state: RootState) => {
  const markets = state.protocol.markets;

  return markets?.[MARKET_KEY.WTAO] || defaultMarket;
};

export const nativeUserBalanceSelector = (state: RootState) => {
  const userBalances = state.protocol.userBalances;

  return userBalances?.[MARKET_KEY.EGLD] || defaultUserBalance;
};

export const wtaoUserBalanceSelector = (state: RootState) => {
  const userBalances = state.protocol.userBalances;

  return userBalances?.[MARKET_KEY.WTAO] || defaultUserBalance;
};

export const sEgldMarketSelector = (state: RootState) => {
  const markets = state.protocol.markets;

  return markets?.[MARKET_KEY.sEGLD] || defaultMarket;
};

export const sWtaoMarketSelector = (state: RootState) => {
  const markets = state.protocol.markets;

  return markets?.[MARKET_KEY.sWTAO] || defaultMarket;
};

export const sEgldUserBalanceSelector = (state: RootState) => {
  const userBalances = state.protocol.userBalances;

  return userBalances?.[MARKET_KEY.sEGLD] || defaultUserBalance;
};

export const sWtaoUserBalanceSelector = (state: RootState) => {
  const userBalances = state.protocol.userBalances;

  return userBalances?.[MARKET_KEY.sWTAO] || defaultUserBalance;
};

export const hasEnoughEGLDBalanceSelector = (state: RootState) => {
  const nativeUserBalance = nativeUserBalanceSelector(state);

  return new DefiUtils(
    subtractGasFee(nativeUserBalance.underlyingBalance),
  ).isGreaterThan(0);
};

export const htmMarketSelector = (state: RootState) => {
  const markets = state.protocol.markets;

  return markets?.[MARKET_KEY.HTM] || defaultMarket;
};

export const usdcMarketSelector = (state: RootState) => {
  const markets = state.protocol.markets;

  return markets?.[MARKET_KEY.USDC] || defaultMarket;
};

export const hasTakenBorrowsSelector = (state: RootState) => {
  const { userBalances } = state.protocol;

  return Object.entries(userBalances)
    .map(([, { borrowBalance }]) => borrowBalance)
    .reduce((prev, current) => prev.plus(current), new DefiUtils('0'))
    .isGreaterThan('0');
};

export const calcHasMaxMarketPerAccount = ({
  userBalances,
  controller,
  token,
}: {
  userBalances: Record<MARKET_KEY, UserBalance>;
  controller: Controller;
  token: MARKET_KEY;
}) => {
  const filteredBalances = Object.entries(userBalances)
    .map(([symbol, { collateralBalance, borrowBalance }]) => ({
      symbol,
      amount: new DefiUtils(collateralBalance).plus(borrowBalance).toString(),
    }))
    .filter(({ amount }) => new DefiUtils(amount).isGreaterThan('0'));

  const existInBalance = filteredBalances.some(
    ({ symbol }) => symbol === token,
  );

  if (existInBalance) {
    return false;
  }

  return DefiUtils.max(filteredBalances.length).isGreaterThanOrEqualTo(
    controller.maxMarketsPerAccount,
  );
};

export const protocolSelector = (state: RootState) => state.protocol;

export default protocolSlice.reducer;
