export const shortenHash = (
  address: string,
  charsAmount = 6,
  lastBlock = charsAmount
) => {
  const firstPart = address.substring(0, charsAmount);
  const lastPart = address.substring(
    address.length - lastBlock,
    address.length
  );
  return `${firstPart}...${lastPart}`;
};
