import { SVGProps } from 'react';

const InfoIcon = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1em'
      height='1em'
      fill='none'
      viewBox='0 0 16 16'
      {...props}
    >
      <path
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M8.871 1H7.13A6.129 6.129 0 001 7.129V8.87A6.129 6.129 0 007.129 15H8.87A6.129 6.129 0 0015 8.871V7.13A6.129 6.129 0 008.871 1zM8 8v3.111'
      ></path>
      <path
        fill='currentColor'
        d='M8 5.666A.778.778 0 108 4.11a.778.778 0 000 1.556z'
      ></path>
    </svg>
  );
};

export default InfoIcon;
