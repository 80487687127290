import { gql } from 'graphql-request';

import client from '@/services/indexer/client';
import {
  formatHistoryPoints,
  formatMarkets,
} from '@/services/indexer/market/parsers';
import {
  QueryHistoryPoint,
  QueryMarkets,
  ResponseHistoryPoint,
  ResponseMarkets,
} from '@/services/indexer/market/types';

export const getMarkets = async (): Promise<ResponseMarkets[]> => {
  const query = gql`
    query Market_QueryMarkets() @cacheControl(maxAge: 180) {
      queryMoneyMarket {
        reserveFactor
        collateralFactor
        interestRateModel {
          baseRate
          firstSlope
          lastSlope
          optimalUtilization
        }
        lastState {
          totalSupply
          borrows
          supplyRatePerSecond
          borrowRatePerSecond
          cash
          reserves
        }
        underlying {
          symbol
        }
      }
    }
  `;

  const variables = {};

  const queryMarketsResponse = await client.request<QueryMarkets>(
    query,
    variables,
  );

  return formatMarkets(queryMarketsResponse);
};

export const getHistoryPoints = async (
  address: string,
  assetKey: string,
  first: number,
): Promise<ResponseHistoryPoint> => {
  const query = gql`
    query Market_QueryHistoryPoint($address: String!, $first: Int!)
    @cacheControl(maxAge: 3600) {
      getMoneyMarket(address: $address) {
        address
        dailyStateHistory(first: $first, order: { desc: day }) {
          day
          moneyMarketState {
            totalSupply
            borrows
            cash
            reserves
            supplyRatePerSecond
            borrowRatePerSecond
            timestamp
          }
        }
        underlying {
          dailyPriceHistory(first: $first, order: { desc: day }) {
            day
            quote {
              priceInEgld
              timestamp
            }
          }
        }
      }
    }
  `;

  const variables = {
    address,
    first,
  };

  const queryHistoryPointResponse = await client.request<QueryHistoryPoint>(
    query,
    variables,
  );

  const historyPoints = formatHistoryPoints(
    queryHistoryPointResponse,
    assetKey,
    first,
  );

  return {
    address,
    assetKey,
    historyPoints,
  };
};
