const CheckCircleIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width={12}
      height={12}
      viewBox='0 0 13 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x='0.5' width={12} height={12} rx={6} fill='#47A57E' />
      <path
        d='M3.41992 6L5.72992 8.40625L9.57992 3.59375'
        stroke='white'
        strokeWidth='1.42593'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CheckCircleIcon;
