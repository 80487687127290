import ColorHash from 'color-hash';
import Identicon from 'identicon.js';
import { keccak256 } from 'js-sha3';
import { useMemo } from 'react';
import { AspectRatio } from 'react-aspect-ratio';

interface Props {
  address: string;
  className?: string;
  ratio?: number | string;
  style?: {};
}

const colorHash = new ColorHash();

const AvatarAddress = ({ address, ...props }: Props) => {
  const avatar = useMemo(
    () =>
      new Identicon(keccak256(address), {
        size: 40,
        background: [0, 0, 0, 0],
        foreground: [...colorHash.rgb(keccak256(address)), 250],
        margin: 0,
      }).toString(),
    [address]
  );

  return (
    <AspectRatio {...props}>
      <img src={`data:image/png;base64,${avatar}`} alt='Avatar' />
    </AspectRatio>
  );
};

export default AvatarAddress;
