import DefiUtils from 'defi-utils';

import { LendAppState, Market } from '@/store/lend-app';
import {
  MARKET_KEY,
  MARKET_ORDER_MAP,
  Market as MarketProtocol,
  UserBalance,
} from '@/store/protocol';
import { Market as RewardMarket } from '@/store/reward-batch';

import * as indexerService from '@/services/indexer';
import { calcTokenApyEnumerator } from '@/utils/math/apy';
import {
  calcBorrowBalance,
  calcBorrowLimit,
  calcSupplyBalanceUSD,
} from '@/utils/math/market';

import { showNetApyV2 } from '@/config/envVars';
import { ESDTTokenBalance } from '@/types/account';
import { AccountToken } from '@/store/auth';

export const getBorrowTotalBalanceUSD = (state: LendAppState) => {
  const total = Object.values(state.markets).reduce(
    (prev, { underlying, accountBalances }) => {
      const result: string = calcBorrowBalance(
        accountBalances.borrow,
        underlying.priceUSD,
        underlying.decimals,
      );

      return prev.plus(result);
    },
    new DefiUtils('0'),
  );

  return total.isNaN() ? '0' : total.toSafeFixed(18, DefiUtils.ROUND_DOWN);
};

export const getBorrowLimitUSD = (
  state: LendAppState,
  hasSimulate: boolean = true,
) => {
  const total = Object.values(state.markets).reduce(
    (acc, { underlying, collateralFactor, accountBalances }) => {
      const collateralBalance = calcBorrowLimit(
        hasSimulate
          ? accountBalances.underlyingCollateral
          : accountBalances.underlyingCollateralWithoutSimulate,
        collateralFactor,
        underlying.priceUSD,
        underlying.decimals,
      );

      return acc.plus(collateralBalance);
    },
    new DefiUtils('0'),
  );

  return total.isNaN() ? '0' : total.toSafeFixed(18, DefiUtils.ROUND_DOWN);
};

export const getSupplyTotalBalanceUSD = (state: LendAppState) => {
  const total = Object.values(state.markets).reduce(
    (prev, { accountBalances, underlying, hTokenExchangeRate }) => {
      const result = calcSupplyBalanceUSD(
        accountBalances.hTokenWallet,
        hTokenExchangeRate,
        accountBalances.collateral,
        underlying.priceUSD,
        underlying.decimals,
      );

      return prev.plus(result);
    },
    new DefiUtils('0'),
  );

  return total.isNaN() ? '0' : total.toSafeFixed(18, DefiUtils.ROUND_DOWN);
};

export const getCollateralBalanceUSD = (state: LendAppState) => {
  const total = Object.values(state.markets).reduce(
    (prev, { underlying, accountBalances }) => {
      const collateralBalance = new DefiUtils(
        accountBalances.underlyingCollateral,
      )
        .toFullDecimals(underlying.decimals)
        .toUSD(underlying.priceUSD)
        .toString();

      return prev.plus(collateralBalance);
    },
    new DefiUtils(0),
  );

  return total.isNaN() ? '0' : total.toSafeFixed(18, DefiUtils.ROUND_DOWN);
};

export const getBorrowLimitUsedPercent = (
  borrowedBalance: DefiUtils.Value,
  borrowLimit: DefiUtils.Value,
) => {
  if (new DefiUtils(borrowLimit).isLessThanOrEqualTo(0)) {
    return '0';
  }

  return new DefiUtils(borrowedBalance)
    .dividedBy(borrowLimit)
    .multipliedBy(100)
    .toString();
};

export const calculateNetAPY = ({
  markets,
  rewardMarkets,
  liquidStakingAPY,
  showLiquidStakingAPY,
  liquidStakingTaoAPY,
  showLiquidStakingTaoAPY,
  accountsBoosterAPYMap,
}: {
  markets: Record<MARKET_KEY, Market>;
  rewardMarkets: Record<MARKET_KEY, RewardMarket>;
  liquidStakingAPY: string;
  showLiquidStakingAPY: boolean;
  liquidStakingTaoAPY: string;
  showLiquidStakingTaoAPY: boolean;
  accountsBoosterAPYMap: Record<MARKET_KEY, string>;
}) => {
  const tokenAPYNumerators = Object.values(markets).reduce(
    (
      acc,
      {
        assetKey,
        underlying,
        supplyAPY,
        accountBalances,
        borrowAPY,
        hTokenExchangeRate,
        supported,
      },
    ) => {
      if (!supported) {
        return acc;
      }

      const rewardsToken = rewardMarkets[assetKey as MARKET_KEY]?.rewards || [];

      const tokenAPY = calcTokenApyEnumerator(
        liquidStakingAPY,
        showLiquidStakingAPY,
        liquidStakingTaoAPY,
        showLiquidStakingTaoAPY,
        assetKey,
        accountBalances.underlyingHTokenWallet,
        accountBalances.underlyingCollateral,
        underlying.priceUSD,
        supplyAPY,
        accountBalances.borrow,
        borrowAPY,
        rewardsToken,
        hTokenExchangeRate,
        underlying.decimals,
        accountBalances.underlyingWallet,
        accountBalances.hTokenWallet,
        accountBalances.collateral,
        accountsBoosterAPYMap[assetKey as MARKET_KEY] || '0',
      );

      return new DefiUtils(acc).plus(tokenAPY);
    },
    new DefiUtils('0'),
  );

  const dividers = Object.values(markets).reduce(
    (acc, { underlying, supported, accountBalances, hTokenExchangeRate }) => {
      if (!supported) {
        return acc;
      }

      const accountSupplyUSD = calcSupplyBalanceUSD(
        accountBalances.hTokenWallet,
        hTokenExchangeRate,
        accountBalances.collateral,
        underlying.priceUSD,
        underlying.decimals,
      );

      let accountBorrowUSD = 0;

      if (showNetApyV2) {
        accountBorrowUSD = new DefiUtils(accountBalances.borrow)
          .toFullDecimals(underlying.decimals)
          .toUSD(underlying.priceUSD)
          .toNumber();
      }

      const divider = new DefiUtils(accountSupplyUSD).minus(accountBorrowUSD);

      return new DefiUtils(acc).plus(divider);
    },
    new DefiUtils('0'),
  );

  const result = tokenAPYNumerators.dividedBy(dividers);

  return result.toSafeString();
};

export const getHTokenByProvider = async (
  hTokenBalanceProvider: string,
  accountAddress: string,
  accountTokens: AccountToken[],
  protocolMarkets: Record<MARKET_KEY, MarketProtocol>,
): Promise<Record<MARKET_KEY, string>> => {
  switch (hTokenBalanceProvider) {
    case 'multiversx': {
      return getHTokenBalance(accountTokens, Object.values(protocolMarkets));
    }

    case 'indexer': {
      return indexerService.getHTokenBalance(accountAddress);
    }

    default: {
      return getHTokenBalance(accountTokens, Object.values(protocolMarkets));
    }
  }
};

export const getHTokenBalance = (
  tokenBalance: AccountToken[],
  moneyMarketsIds: { hToken: { id: string }; underlying: { symbol: string } }[],
) => {
  const hTokenAccountBalances = tokenBalance.reduce(
    (acc, token) => {
      const index = moneyMarketsIds.findIndex(
        ({ hToken }) => hToken.id === token.tokenIdentifier,
      );

      if (index === -1) return acc;

      const assetKey = moneyMarketsIds[index].underlying.symbol;

      return {
        ...acc,
        [assetKey]: token.balance,
      };
    },
    {} as Record<MARKET_KEY, string>,
  );

  return hTokenAccountBalances;
};

export const getUnderlyingBalance = (
  tokenBalance: ESDTTokenBalance[],
  moneyMarketsIds: {
    underlying: {
      symbol: string;
      id: string;
    };
  }[],
): Record<string, string> => {
  const accountBalances = tokenBalance.reduce(
    (acc, token) => {
      const index = moneyMarketsIds.findIndex(
        ({ underlying }) => underlying.id === token.tokenIdentifier,
      );

      if (index === -1) return acc;

      const { underlying } = moneyMarketsIds[index] || {};

      return {
        ...acc,
        [underlying.symbol]: token.balance,
      };
    },
    {} as Record<MARKET_KEY, string>,
  );

  return accountBalances;
};

export const formatMarkets = (
  protocolMarkets: Record<MARKET_KEY, MarketProtocol>,
  userBalances: Record<MARKET_KEY, UserBalance>,
  hTokenBalances: Record<MARKET_KEY, string>,
  underlyingBalances: Record<MARKET_KEY, string>,
) => {
  return Object.values(protocolMarkets)
    .map(
      ({
        underlying: { name, ...underlying },
        hToken: { name: _5, ...hToken },
        hTokenExchangeRate,
        supported,
        logo,
        address,
        supplyAPY,
        borrowAPY,
        collateralFactor,
        totalBorrow,
        totalSupplyUSD,
        totalSupply,
        supplyCap,
        borrowCap,
        mintStatus,
        borrowStatus,
        cash,
      }) => {
        const userBalance = userBalances[underlying.symbol as MARKET_KEY];
        // const marketItem = marketsMap[underlying.id as MARKET_KEY];

        const accountBalances = {
          collateral: userBalance?.collateralBalance || '0',
          underlyingCollateral: userBalance?.underlyingCollateralBalance || '0',
          underlyingCollateralWithoutSimulate:
            userBalance?.underlyingCollateralBalanceWithoutSimulate || '0',
          borrow: userBalance?.borrowBalance || '0',
          hTokenWallet: hTokenBalances[underlying.symbol as MARKET_KEY] || '0',
          underlyingHTokenWallet: new DefiUtils(
            hTokenBalances[underlying.symbol as MARKET_KEY] || '0',
          )
            .toUnderlying(hTokenExchangeRate)
            .toString(),
          underlyingWallet:
            underlyingBalances[underlying.symbol as MARKET_KEY] || '0',
        };

        return {
          order: MARKET_ORDER_MAP[underlying.symbol as MARKET_KEY] || 99,
          address,
          assetKey: underlying.symbol,
          underlying,
          hToken,
          hTokenExchangeRate,
          supported,
          logo,
          name,
          accountBalances,
          supplyAPY,
          borrowAPY,
          cash,
          collateralFactor,
          totalBorrow,
          totalSupplyUSD,
          totalSupply,
          supplyCap,
          borrowCap,
          mintEnabled: mintStatus === 'Active',
          borrowEnabled: borrowStatus === 'Active',
        } as Market;
      },
    )
    .reduce(
      (prev, current) => ({
        ...prev,
        [current.assetKey]: current,
      }),
      {} as Record<MARKET_KEY, Market>,
    );
};
