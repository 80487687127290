import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/index';

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    expanded: false,
    mobileView: false,
    isMenuOpen: false,
  },
  reducers: {
    toggle: (state, action: PayloadAction<boolean | undefined>) => {
      state.expanded = action.payload ?? !state.expanded;
    },
    setMobileView: (state, action: PayloadAction<boolean>) => {
      state.mobileView = action.payload;
    },
    toggleMenu: (state, action: PayloadAction<boolean | undefined>) => {
      state.isMenuOpen = action.payload ?? !state.isMenuOpen;
    },
  },
});

export const {
  toggle: toggleSidebar,
  setMobileView: setSidebarMobileView,
  toggleMenu,
} = sidebarSlice.actions;

export const sidebarSelector = (state: RootState) => state.sidebar;

export default sidebarSlice.reducer;
