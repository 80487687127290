
import useMediaQuery from '@/hooks/useMediaQuery';


const MultiVerseWallet = () => {
  const lg = useMediaQuery('(min-width: 1024px )');
  return (<div className='w-[55px] lg:w-[100px] h-fit relative z-3'>
    <img
      src="https://cdn.app.hatom.com/images/pages/bridge/multiversTv.svg"
      alt='tv icon'
      className="w-[37px] h-auto lg:w-[52px] mb-[9px] lg:mb-[12px]"
    />
    <p className='text-start text-[#1F2226]'>MultiversX Web Wallet</p>
  </div>
  )
}
export default MultiVerseWallet