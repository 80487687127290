import WrappedLottiePlayer from '@/components/WrappedLottiePlayer';

const PendingLoader = () => {
  return (
    <WrappedLottiePlayer
      className='h-[148px] origin-top scale-105'
      src='https://cdn.app.hatom.com/animations/liquidstaking/pending-tx-loader.json'
    />
  );
};

export default PendingLoader;
