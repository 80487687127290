
import BridgeXPortalShape from "@/components/popups/ConnectBridgeWalletPopup/components/shapes/BridgeXportalShape";

const XportalWallet = ({ onClick }: { onClick: () => void }) => {
  return (
    <div className='relative'>
      <BridgeXPortalShape onClick={onClick} className="absolute z-0 w-[214.7px] h-[88.8px] lg:w-fit lg:h-[124px]" />
      <div onClick={onClick} className='flex justify-end pt-[37.1px] lg:pt-[52px] pr-[4.2px] lg:pr-[4.6px]'>
        <div className='relative z-3 w-[78.8px] lg:w-[111] mr-[15.03px]  lg:mr-[22.23px]'>
          <img
            src="https://cdn.app.hatom.com/images/pages/bridge/xPortal.svg"
            alt='x portal'
            height="auto"
            className="w-full mb-[8.4px] lg:mb-[12px]"
          />
          <p className='text-end text-white lg:translate-x-[-0.7px]'>xPortal App</p>
        </div>
      </div>
    </div>
  )
}

export default XportalWallet;