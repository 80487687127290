import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/index';

export interface DrawerState {
  isOpen: boolean;
}

const initialState: DrawerState = {
  isOpen: false,
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setDrawer: (state, action: PayloadAction<DrawerState>) => {
      state.isOpen = action.payload.isOpen;
    },

    toggleDrawer: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { setDrawer, toggleDrawer } = drawerSlice.actions;

export const drawerSelector = (state: RootState) => state.drawer;

export default drawerSlice.reducer;
