import classNames from 'classnames';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

import useTheme from '@/hooks/useTheme';

import FilterDashIcon from '@/components/Icons/FilterDashsIcon';

type FilterButtonProps =
  DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >

const FilterButton = ({
  className,
  ...props
}: FilterButtonProps) => {
  const { isDark } = useTheme();

  return (
    <button
      className={classNames(
        'bg-[#ECDDFE] hover:bg-[#dfc9fc]',
        'lg:rounded-[4.571px]',
        'rounded-[3.547px]',
        'cursor-pointer',
        'dark:bg-[#2E2C51]',
        className
      )}
      {...props}
    >
      <FilterDashIcon
        className='w-full h-full'
        fill={isDark ? '#FFFFFF' : '#404056'} />
    </button>
  );
};

export default FilterButton;