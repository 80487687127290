import * as boosterBatch from '@/services/blockchain/booster-batch';
import * as controller from '@/services/blockchain/controller';
import * as interestRateModel from '@/services/blockchain/interest-rate-model';
import * as lens from '@/services/blockchain/lens';
import * as liquidLocking from '@/services/blockchain/liquid-locking';
import * as liquidStaking from '@/services/blockchain/liquid-staking';
import * as moneyMarket from '@/services/blockchain/money-market';
import * as priceAggregator from '@/services/blockchain/price-aggregator';
import * as protocol from '@/services/blockchain/protocol';
import * as rewardBatch from '@/services/blockchain/reward-batch';

const blockchainService = {
  controller,
  moneyMarket,
  liquidStaking,
  priceAggregator,
  interestRateModel,
  protocol,
  rewardBatch,
  boosterBatch,
  liquidLocking,
  lens,
};

export default blockchainService;
