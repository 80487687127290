import {
  Container,
  Left,
  Right,
} from '@/sections/Governance/Participate/components';

export const Participate = () => {
  return (
    <Container>
      <Left />
      <Right />
    </Container>
  );
};
