import { SVGProps } from 'react';

const CheckmarkIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width={10}
      height={8}
      viewBox='0 0 10 8'
      fill='none'
      stroke='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_238_1142)'>
        <path
          d='M1 4L4 7L9 1'
          strokeWidth={1.5}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_238_1142'>
          <rect width={10} height={8} fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckmarkIcon;
