import DefiUtils from 'defi-utils';

import {
  Proposal,
  PROPOSAL_STATUS,
  PROPOSAL_TAG,
  staticProposals,
  USER_BALANCE_SOURCE,
  UserBalance,
  VOTE_TYPE,
} from '@/store/governance';
import { Market } from '@/store/protocol';

import governanceABI from '@/abis/governance';
import { ResponseGovernanceProposal } from '@/services/indexer';
import { getTypeFromData } from '@/utils/helpers';

import { ESDTTokenBalance } from '@/types/account';
import { AccountToken } from '@/store/auth';

export const formatVoteNfts = (
  accountTokens: AccountToken[],
  voteNftId: string,
  htmMarket: Market,
) => {
  if (accountTokens.length === 0) {
    return [];
  }

  return accountTokens.filter((token) => token.tokenIdentifier.includes(voteNftId))
    .map((token) => {
      const data = getTypeFromData({
        type: 'VoteNFTAttributes',
        value: token.attributes || '',
        abi: governanceABI,
      });

      const amountAsBigNumber = data?.fieldsByName
        .get('payment')
        .value.fieldsByName.get('amount')
        .value.toString() as string;
      const tokenNonce = token.nonce as number;
      const proposalId = data?.fieldsByName.get('proposal_id').value.toString();
      const amount = new DefiUtils(amountAsBigNumber)
        .toFullDecimals(htmMarket.underlying.decimals)
        .toString();
      const voteType = data?.fieldsByName.get('vote_type').value
        .discriminant as VOTE_TYPE;

      return {
        tokenIdentifier: token.tokenIdentifier,
        proposalId,
        tokenNonce,
        amount,
        voteType,
      };
    })
    .filter((item) => item !== null);
};

export const formatProposals = (
  proposals: ResponseGovernanceProposal[],
  htmMarket: Market,
  {
    blockNonce,
    votingDelayInBlocks,
    quorum,
    votingPeriodInBlocks,
  }: {
    blockNonce: number;
    votingDelayInBlocks: string;
    quorum: string;
    votingPeriodInBlocks: string;
  },
) => {
  const formattedProposals = proposals.map((proposalItem) =>
    formatProposal(
      {
        status: PROPOSAL_STATUS.pending,
        totalVotes: '0',
        percentageUpVotes: '0',
        percentageDownVotes: '0',
        highLighted: null,
        title: '',
        tag: PROPOSAL_TAG.lendingProtocol,
        ...proposalItem,
      },
      {
        htmDecimals: htmMarket.underlying.decimals,
        blockNonce,
        votingDelayInBlocks,
        votingPeriodInBlocks,
        quorum,
      },
    ),
  );

  return [
    ...staticProposals.map((proposalItem, proposalItemIndex) => {
      const downvotes = proposalItem.downvotes;
      const upvotes = proposalItem.upvotes;
      const totalVotes = new DefiUtils(downvotes).plus(upvotes).toString();
      const percentageUpVotes = new DefiUtils(upvotes)
        .multipliedBy(100)
        .dividedBy(totalVotes)
        .toString();
      const percentageDownVotes = new DefiUtils(downvotes)
        .multipliedBy(100)
        .dividedBy(totalVotes)
        .toString();

      return {
        ...proposalItem,
        id: String(proposalItemIndex),
        creationTimestamp: new Date('2023').toISOString(),
        publishedTimestamp: new Date('2023').toISOString(),
        percentageUpVotes,
        percentageDownVotes,
        downvotes,
        upvotes,
        totalVotes,
        creationBlock: '100',
        published: true,
        executed: true,
        highLighted: null,
      };
    }),
    ...formattedProposals.slice(staticProposals.length),
  ];
};

export const formatProposal = (
  proposal: Proposal,
  {
    htmDecimals,
    blockNonce,
    votingDelayInBlocks,
    votingPeriodInBlocks,
    quorum,
  }: {
    htmDecimals: number;
    blockNonce: number;
    votingDelayInBlocks: string;
    votingPeriodInBlocks: string;
    quorum: string;
  },
): Proposal => {
  const upvotes = new DefiUtils(proposal.upvotes)
    .toFullDecimals(htmDecimals)
    .toString();

  const downvotes = new DefiUtils(proposal.downvotes)
    .toFullDecimals(htmDecimals)
    .toString();

  const totalVotes = new DefiUtils(upvotes).plus(downvotes).toString();

  const highLighted =
    proposal.upvotes === '0' && proposal.downvotes === '0'
      ? null
      : new DefiUtils(proposal.upvotes).isGreaterThan(proposal.downvotes)
      ? 'for'
      : 'against';

  const percentageUpVotes = new DefiUtils(upvotes)
    .multipliedBy(100)
    .dividedBy(totalVotes)
    .toString();

  const percentageDownVotes = new DefiUtils(downvotes)
    .multipliedBy(100)
    .dividedBy(totalVotes)
    .toString();

  const status = getProposalStatus(proposal, {
    blockNonce,
    votingDelayInBlocks,
    votingPeriodInBlocks,
    quorum,
  });

  const [title = '', description = ''] = proposal.description.split('_____');

  return {
    ...proposal,
    highLighted,
    totalVotes,
    upvotes,
    downvotes,
    percentageUpVotes,
    percentageDownVotes,
    status,
    description,
    title,
  };
};

export const getProposalStatus = (
  proposal: Proposal,
  {
    blockNonce,
    votingDelayInBlocks,
    votingPeriodInBlocks,
    quorum,
  }: {
    blockNonce: number;
    votingDelayInBlocks: string;
    votingPeriodInBlocks: string;
    quorum: string;
  },
) => {
  const { executed, published, downvotes, upvotes, publishedBlock } = proposal;

  if (executed) {
    return PROPOSAL_STATUS.executed;
  }

  if (!published) {
    return PROPOSAL_STATUS.building;
  }

  const currentBlock = new DefiUtils(blockNonce);
  const votingStart = new DefiUtils(publishedBlock).plus(votingDelayInBlocks);
  const votingEnd = new DefiUtils(votingStart).plus(votingPeriodInBlocks);

  if (currentBlock.isLessThan(votingStart)) {
    return PROPOSAL_STATUS.pending;
  }

  if (
    currentBlock.isGreaterThanOrEqualTo(votingStart) &&
    currentBlock.isLessThan(votingEnd)
  ) {
    return PROPOSAL_STATUS.active;
  }

  const totalUpvotes = new DefiUtils(upvotes);
  const totalDownvotes = new DefiUtils(downvotes);

  if (totalUpvotes.isGreaterThan(totalDownvotes)) {
    return PROPOSAL_STATUS.succeeded;
  }

  return PROPOSAL_STATUS.defeated;
};

export const formatUserBalances = (
  htmToken: ESDTTokenBalance,
  htmMarket: Market,
) => {
  const userBalances: Record<USER_BALANCE_SOURCE, UserBalance> = {
    [USER_BALANCE_SOURCE.wallet]: {
      source: USER_BALANCE_SOURCE.wallet,
      amount: new DefiUtils(htmToken.balance)
        .toFullDecimals(htmMarket.underlying.decimals)
        .toString(),
    },
    [USER_BALANCE_SOURCE.staked]: {
      source: USER_BALANCE_SOURCE.staked,
      amount: '0',
    },
    [USER_BALANCE_SOURCE.safety]: {
      source: USER_BALANCE_SOURCE.safety,
      amount: '0',
    },
  };

  return userBalances;
};
