import { Placement } from 'react-laag';

export const cardClasses = [
  'relative p-[18px] rounded-[16px]',
  'bg-[#FFFFFF] dark:bg-[#191733]',
  'border-[2px] border-[#F4F1FD] dark:border-[#2E2C51]',
  '[box-shadow:0px_6px_0px_#D3CFE2,6px_15px_14px_rgba(222,222,236,0.82)] dark:[box-shadow:0px_6px_0px_#171430,_9px_16px_14px_#000000]',
  'page-smooth-mode-transition',
].join(' ');

export const useHintProps = (secondary = false) => {
  return {
    placement: 'bottom-center' as Placement,
    ...(secondary
      ? {
          placement: 'top-right' as Placement,
          arrow: false,
          offset: -10,
          layerStyle: {
            display: 'inline-flex',
            padding: '6px',
            borderRadius: 6,
            translate: '-10px 0',
            fontSize: 10,
            fontWeight: 600,
            letterSpacing: '0.03em',
            lineHeight: 1,
          },
        }
      : {}),
  };
};
