import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';

import { accountSelector } from '@/store/auth';
import { useAppDispatch, useAppSelector } from '@/store/index';
import {
  defaultTransactionConfig,
  setCurrentTransactions,
  setTransactionAmount,
  setTransactionConfig,
  TRANSACTION_GROUP_TYPE,
  transactionSelector,
} from '@/store/transaction';

import { ROUTES } from '@/types/enums';

const useStoreCurrentTransactionGroup = () => {
  const dispatch = useAppDispatch();
  const { transactionGroups } = useAppSelector(transactionSelector);
  const { address: accountAddress } = useAppSelector(accountSelector);
  const router = useRouter();

  const currentTransactionGroupByRoute = useMemo(() => {
    switch (router.route) {
      case ROUTES.LEND: {
        return TRANSACTION_GROUP_TYPE.LENDING;
      }
      case ROUTES.LIQUID_MULTIVERSX_APP: {
        return TRANSACTION_GROUP_TYPE.LIQUID;
      }
      case ROUTES.LIQUID_TAO_APP: {
        return TRANSACTION_GROUP_TYPE.LIQUID_TAO;
      }
      default: {
        return TRANSACTION_GROUP_TYPE.DEFAULT;
      }
    }
  }, [router.route]);

  useEffect(() => {
    const transactionGroupIndex = transactionGroups.findIndex(
      (transactionGroup) =>
        transactionGroup.id ===
        `${accountAddress}_${currentTransactionGroupByRoute}`,
    );
    const transactionGroup = transactionGroups[transactionGroupIndex];

    if (transactionGroupIndex === -1) {
      dispatch(setCurrentTransactions([]));
      dispatch(setTransactionConfig(defaultTransactionConfig));
      dispatch(setTransactionAmount(0));
      return;
    }

    dispatch(setCurrentTransactions(transactionGroup.transactions));
    dispatch(setTransactionConfig(transactionGroup.config));
    dispatch(setTransactionAmount(transactionGroup.amount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionGroups, accountAddress, currentTransactionGroupByRoute]);
};

export default useStoreCurrentTransactionGroup;
