

import { WalletTypes } from '@/components/popups/ConnectBridgeWalletPopup/components/types';

import clsxm from '@/services/clsxm';



const WalletChoiceHeader = ({ type }: { type: WalletTypes }) => {

  return (
    <div className={clsxm(
      'w-full px-[16px] flex  items-center h-[39px] lg:h-[56px]',
      // type === "MULTIVERSE_X" && "mb-[46px]",
      // type === "FINNEY" && "mb-[25.3px] lg:mb-[36.2px]",
    )}>
      <div className={clsxm(
        'flex items-center w-[182] h-[20px]',
        type === "MULTIVERSE_X" && 'ml-[51.4px] lg:ml-[83px]',
        type === "FINNEY" && 'ml-[53.3px] lg:ml-[85.7px]'

      )}>
        <p
          className={clsxm(
            'text-[12px] lg:text-[16px] leading-[15px] lg:leading-[20px] font-[600] ',
            type === 'FINNEY' && 'text-white mr-[3.5px] lg:mr-[5px]',
            type === 'MULTIVERSE_X' && 'text-[#3C3A40] mr-[3.3px] lg:mr-[5px]'
          )}
        >
          Connect {type === 'FINNEY' ? 'Bittensor' : ''}
        </p>
        <img
          className={
            clsxm(
              type === "FINNEY" && 'translate-x-[-1.3px] translate-y-[0.5px] lg:translate-y-[0.9px] lg:translate-x-[-0.4px] w-[11px] lg:w-[11.1px]',
              type === "MULTIVERSE_X" && 'w-[69px] lg:w-[90px]'
            )}
          src={"https://cdn.app.hatom.com/images/pages/bridge/" + (type === "MULTIVERSE_X" ? "multiversTextLogo.svg" : "bittensorNoBg.svg")}
          alt="multiverse text icon"
          height="auto"
        />
      </div>
    </div>
  )
}

export default WalletChoiceHeader;