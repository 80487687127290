import classNames from 'classnames';

export const CollateralBadge = ({
  on = false,
  className,
}: {
  on: boolean;
  className?: string;
}) => {
  return (
    <div className='-m-2 p-2'>
      <div
        className={classNames(
          'h-2.5 w-1',
          'rounded-[28px]',
          {
            'bg-[#45A373] [box-shadow:0px_0px_2px_#44A474]': on,
            'bg-[#FF813A] [box-shadow:0px_0px_2px_#FF813A]': !on,
          },
          className
        )}
      ></div>
    </div>
  );
};
