import DefiUtils from 'defi-utils';
import { omit } from 'lodash';

import { Market, MARKET_KEY } from '@/store/protocol';

interface HistoryPoint {
  totalSupplyUSD: string;
  totalBorrowUSD: string;
  totalBorrows: string;
  supplyRate: string;
  borrowRate: string;
  exchangeRate: string;
  totalSupply: string;
  borrowAPY: string;
  supplyAPY: string;
  timestamp: string;
  priceInEgld: string;
}

interface GetHistoryPointFilledResponse
  extends Omit<HistoryPoint, 'timestamp'> {}

export const getHistoryPointFilled = (
  historyPoint: HistoryPoint,
  historyPoints: HistoryPoint[]
): GetHistoryPointFilledResponse => {
  const _borrowAPY = new DefiUtils(historyPoint.borrowAPY);
  const _supplyAPY = new DefiUtils(historyPoint.supplyAPY);
  const _totalBorrowUSD = new DefiUtils(historyPoint.totalBorrowUSD);
  const _totalSupplyUSD = new DefiUtils(historyPoint.totalSupplyUSD);

  if (historyPoints.length === 0) {
    return omit(historyPoint, ['timestamp']);
  }

  if (
    _borrowAPY.isEqualTo('0') ||
    _supplyAPY.isEqualTo('0') ||
    _totalBorrowUSD.isEqualTo('0') ||
    _totalSupplyUSD.isEqualTo('0')
  ) {
    return getHistoryPointFilled(
      historyPoints[0],
      historyPoints.slice(1, historyPoints.length)
    );
  }

  const { priceInEgld } = historyPoint;

  if (priceInEgld !== '0') {
    return omit(historyPoint, ['timestamp']);
  }

  return getHistoryPointFilled(
    {
      ...historyPoints[0],
      ...omit(historyPoint, ['priceInEgld']),
    },
    historyPoints.slice(1, historyPoints.length)
  );
};

export const getTotalBorrowUSD = ({
  totalBorrows,
  decimals,
  priceUSD,
}: {
  totalBorrows: string;
  decimals: number;
  priceUSD: number | string;
}) => {
  const value1 = new DefiUtils(totalBorrows)
    .toFullDecimals(decimals)
    .toString();

  return new DefiUtils(value1).toUSD(priceUSD).toNumber();
};

export const getTotalSupplyUSD = ({
  hTokenAmount,
  hTokenExchangeRate,
  decimals,
  priceUSD,
}: {
  hTokenAmount: string;
  hTokenExchangeRate: string;
  decimals: number;
  priceUSD: number | string;
}) => {
  return new DefiUtils(hTokenAmount)
    .toUnderlying(hTokenExchangeRate)
    .toFullDecimals(decimals)
    .toUSD(priceUSD)
    .toNumber();
};

export const getListedTokensIdentifiers = (markets: Market) => {
  const nativeTokenKey = MARKET_KEY.EGLD; // excluding it because it's not an ESDT and if the user has WEGLD it will be considered as a listed token
  const ids = Object.entries(markets)
    .filter(([key]) => key !== nativeTokenKey)
    .map(([key, value]: [string, Market]) => [key, value.underlying.id]);

  return ids;
};

export const getHTokensIdentifiers = (markets: Market) => {
  const ids = Object.entries(markets).map(([key, value]: [string, Market]) => [
    key,
    value.hToken.id,
  ]);
  return ids;
};

export const getListedESDTTokenKeyById = (
  markets: Market,
  tokenId: string
): string | null => {
  const [tokenKey] = getListedTokensIdentifiers(markets).filter(
    (token) => tokenId === token[1]
  );
  return tokenKey?.[0];
};

export const getHTokenAssetKeyById = (
  markets: Market,
  tokenId: string
): string | null => {
  const [tokenKey] = getHTokensIdentifiers(markets).filter(
    (token) => tokenId === token[1]
  );
  return tokenKey?.[0];
};

export const getIsListedESDTToken = (
  markets: Market,
  tokenId: string
): boolean => {
  return getListedTokensIdentifiers(markets).some(
    (token) => tokenId === token[1]
  );
};

export const getIsHToken = (markets: Market, tokenId: string): boolean => {
  return getHTokensIdentifiers(markets).some((token) => tokenId === token[1]);
};

export const convertHTokenToUnderlyingTokenBalance = () => {};
