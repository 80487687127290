import { LayoutGroup, motion } from 'framer-motion';
import React, { ReactNode } from 'react';

import { useLogin } from '@/hooks/auth/useLogin';
import useIsSafari from '@/hooks/useIsSafari';

import BridgeWalletPhishingShape from '@/components/popups/ConnectBridgeWalletPopup/components/shapes/BridgeScamPhisingShape';
import BridgeWalletShape1 from '@/components/popups/ConnectBridgeWalletPopup/components/shapes/BridgeWalletShape1';
import BridgeWalletShape2 from '@/components/popups/ConnectBridgeWalletPopup/components/shapes/BridgeWalletShape2';
import BridgeWalletShape3 from '@/components/popups/ConnectBridgeWalletPopup/components/shapes/BridgeWalletShape3';
import { WalletTypes } from '@/components/popups/ConnectBridgeWalletPopup/components/types';
import WalletChoiceHeader from '@/components/popups/ConnectBridgeWalletPopup/components/WalletChoiceHeader';
import { textClassName } from '@/components/popups/ConnectBridgeWalletPopup/components/wallets/constants';
import LedgerWallet from '@/components/popups/ConnectBridgeWalletPopup/components/wallets/LedgerWallet';
import ManuelConnectWallet from '@/components/popups/ConnectBridgeWalletPopup/components/wallets/ManuelConnectWallet';
import MultiVerseXDefiWallet from '@/components/popups/ConnectBridgeWalletPopup/components/wallets/MultiverseDefiWallet';
import MultiVerseWallet from '@/components/popups/ConnectBridgeWalletPopup/components/wallets/MultiverseWallet';
import NovaWallet from '@/components/popups/ConnectBridgeWalletPopup/components/wallets/NovaWallet';
import PolkaWallet from '@/components/popups/ConnectBridgeWalletPopup/components/wallets/PolkaWallet';
import ScamFishing from '@/components/popups/ConnectBridgeWalletPopup/components/wallets/ScamFishing';
import SubWallet from '@/components/popups/ConnectBridgeWalletPopup/components/wallets/SubWallet';
import XportalWallet from '@/components/popups/ConnectBridgeWalletPopup/components/wallets/XPortalWallet';

import { useAppDispatch } from '@/store';
import { closePopup } from '@/store/popup';

import clsxm from '@/services/clsxm';

import { LoginMethodsEnum } from '@/types/enums';

type ConnectProvidersStepProps = {
  type: WalletTypes;
  expanded: boolean;
};

// const container = {
//   hidden: { opacity: 0 },
//   show: {
//     opacity: 1,
//     transition: {
//       staggerChildren: 0.05
//     }
//   }
// }

const AnimatedWrapper = ({
  className,
  children,
  animateScale,
}: {
  animateScale: boolean;
  className: string;
  children: ReactNode;
}) => {
  const animatedWrapperVariants = {
    normal: {
      scale: animateScale ? 0.9 : 1,
      opacity: 0,
    },
    show: {
      opacity: 1,
      scale: 1,
    },
    hover: {
      scale: animateScale ? 1.05 : 1,
    },
    tap: {
      scale: animateScale ? 0.98 : 1,
    },
    transition: {
      duration: 0.5,
      ease: 'easeIn',
      type: 'spring',
    },
  };

  return (
    <LayoutGroup>
      <motion.div
        className={className}
        initial='normal'
        animate='show'
        whileHover='hover'
        whileTap='tap'
        variants={animatedWrapperVariants}
      >
        {children}
      </motion.div>
    </LayoutGroup>
  );
};

const WalletChoiceStep = ({ type, expanded }: ConnectProvidersStepProps) => {
  const dispatch = useAppDispatch();
  const isSafari = useIsSafari();
  const { login } = useLogin();

  return (
    <div className={clsxm(textClassName, 'select-none')}>
      <WalletChoiceHeader type={type as WalletTypes} />
      <motion.div
        initial='hidden'
        animate='show'
        className='w-[216px] lg:w-[302px] h-[292.8px] lg:h-[409px] relative   ml-[20px] lg:ml-[28px] mt-[20px] lg:mt-[28px]'
      >
        <AnimatedWrapper
          animateScale
          className='absolute top-0 left-0 w-[104.4px] h-[114px] lg:w-[146px] lg:h-[160px]'
        >
          <div className='relative'>
            <BridgeWalletShape1
              onClick={() => {
                login(LoginMethodsEnum.webwallet);
                dispatch(closePopup());
              }}
              className='absolute z-0  w-[104.4px] h-[114px] lg:!w-[146px] lg:h-[160px]'
              withGradient={type !== 'MULTIVERSE_X'}
            />
            <div
              onClick={() => {
                login(LoginMethodsEnum.webwallet);
                dispatch(closePopup());
              }}
              className={clsxm(
                type === 'MULTIVERSE_X' &&
                  'pl-[19.3px] pt-[26.5px] lg:pl-[27px] lg:pt-[36.9px]',
                type === 'FINNEY' &&
                  'pt-[18.7px] pl-[17.7px] lg:pl-[25px] lg:pt-[25.7px]',
              )}
            >
              {type === 'MULTIVERSE_X' ? <MultiVerseWallet /> : <SubWallet />}
            </div>
          </div>
        </AnimatedWrapper>

        <AnimatedWrapper
          animateScale={false}
          className='absolute top-[18px] lg:top-[25px] right-0 w-[118px] h-[58.7px] lg:w-[165px] lg:h-[82px]'
        >
          <div onClick={() => {}} className='relative'>
            <BridgeWalletPhishingShape
              onClick={() => {}}
              className='absolute z-0 w-[1  18px] h-[58.7px] lg:w-[165px] lg:h-[82px]'
              fill={type === 'MULTIVERSE_X' ? '#F3F3FE' : '#26264A'}
              hoverFill={type === 'MULTIVERSE_X' ? '#F3F3FE' : '#373764'}
            />
            <ScamFishing type={type} />
          </div>
        </AnimatedWrapper>

        <AnimatedWrapper
          animateScale
          className={clsxm(
            'absolute z-[9] top-[92.6px] left-0 w-[134.3px] h-[156px] lg:w-[188px]',
            !isSafari
              ? ' lg:h-[217px] lg:top-[129.8px]'
              : 'lg:top-[127.8px] lg:h-[220px]',
          )}
        >
          <div className='relative'>
            <BridgeWalletShape2
              onClick={() => {
                login(LoginMethodsEnum.extension);
                dispatch(closePopup());
              }}
              className='absolute z-0  w-[134.3px] h-[156px]  lg:w-[188px] lg:h-[217px]'
              fill={type === 'MULTIVERSE_X' ? '#000000' : '#FF8C00'}
              hoverFill={type === 'MULTIVERSE_X' ? '#373737' : '#FFA639'}
            />
            <div
              onClick={() => {
                login(LoginMethodsEnum.extension);
                dispatch(closePopup());
              }}
              className={clsxm(
                type === 'MULTIVERSE_X' &&
                  'pt-[56.4px] pl-[24.9px] lg:pt-[60.6px] lg:pl-[4.1px]',
                type === 'FINNEY' &&
                  'pt-[45.9px] pl-[28.4px] lg:pt-[64.2px] lg:pl-[40.1px]',
              )}
            >
              {type === 'MULTIVERSE_X' ? (
                <MultiVerseXDefiWallet />
              ) : (
                <PolkaWallet />
              )}
            </div>
          </div>
        </AnimatedWrapper>

        <AnimatedWrapper
          animateScale
          className='absolute top-[87.3px] lg:top-[122px] right-[0.3px] lg:right-[2px] w-[72px] h-[116.3px] lg:w-[99px] lg:h-[162.5px]'
        >
          <div className='relative'>
            <BridgeWalletShape3
              onClick={() => {
                login(LoginMethodsEnum.ledger);
              }}
              className='absolute z-0 w-[72px] h-[116.3px] lg:w-[99px] lg:h-[162.5px]'
              withGradient={type !== 'MULTIVERSE_X'}
            />
            <div
              onClick={() => {
                login(LoginMethodsEnum.ledger);
              }}
              className={clsxm(
                type === 'MULTIVERSE_X' &&
                  'pt-[24.2px] pl-[14.5px] lg:pl-0 lg:pt-[37.1px]',
                type === 'FINNEY' && 'pt-[20.9px] lg:pt-[30.1px]',
              )}
            >
              {type === 'MULTIVERSE_X' ? <LedgerWallet /> : <NovaWallet />}
            </div>
          </div>
        </AnimatedWrapper>

        {type === 'MULTIVERSE_X' && (
          <AnimatedWrapper
            animateScale
            className='absolute bottom-0 left-[0.9px] w-full h-[88.8px] lg:h-[124px]'
          >
            <XportalWallet
              onClick={() => {
                login(LoginMethodsEnum.maiar);
              }}
            />
          </AnimatedWrapper>
        )}

        {type === 'FINNEY' && (
          <AnimatedWrapper
            animateScale
            className='absolute bottom-0 left-[-1.1px] lg:left-[0.9px] w-full h-[89px] lg:h-[124px]'
          >
            <ManuelConnectWallet
              expanded={expanded}
              onManuelConfirmConnectClick={() => {}}
              onManuelConnectClick={() => {}}
            />
          </AnimatedWrapper>
        )}
      </motion.div>
    </div>
  );
};

export default WalletChoiceStep;
