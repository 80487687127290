import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import { RadioInput } from '@/components/popups/VoteV2Popup/components/VoteIntentRadio/components';

import { VOTE_TYPE } from '@/store/governance';

interface VoteIntentRadioProps {
  voteIntent: VOTE_TYPE;
  forCount: number;
  againstCount: number;
  onClick: (value: VOTE_TYPE) => void;
}

export const VoteIntentRadio = ({
  voteIntent,
  againstCount,
  forCount,
  onClick,
}: VoteIntentRadioProps) => {
  const { t } = useTranslation('governance');

  return (
    <label
      className={classNames(
        'mb-5',
        'block',
        'relative',
        'cursor-pointer',
        'select-none'
      )}
    >
      <span
        className={classNames(
          'text-sm',
          'flex',
          'gap-1',
          'items-center',
          'leading-[18px]',
          'mb-2'
        )}
      >
        {t('vote', 'Vote')}
      </span>

      <div className='space-y-2'>
        <RadioInput
          count={forCount}
          action={VOTE_TYPE.For}
          selected={voteIntent === VOTE_TYPE.For}
          onClick={() => {
            onClick(VOTE_TYPE.For);
          }}
        />
        <RadioInput
          count={againstCount}
          action={VOTE_TYPE.Against}
          selected={voteIntent === VOTE_TYPE.Against}
          onClick={() => {
            onClick(VOTE_TYPE.Against);
          }}
        />
      </div>
    </label>
  );
};
