import client from '@/services/gateway/client';
import { NetworkStatus } from '@/services/gateway/network/types';

export const getNetworkStatus = async (
  shard: number = 0,
): Promise<NetworkStatus> => {
  const { data } = await client.get(`/network/status/${shard}`);

  return data.data;
};
