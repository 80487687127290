import DefiUtils from 'defi-utils';
import { useEffect } from 'react';

import { boosterSelector } from '@/store/booster';
import { useAppDispatch, useAppSelector } from '@/store/index';
import {
  lendAppSelector,
  updateAccountMarket,
  updateAccountMarketNetAPY,
} from '@/store/lend-app';
import { calculateNetAPY } from '@/store/parsers/lend-app-parser';
import {
  MARKET_KEY,
  htmMarketSelector,
  protocolSelector,
} from '@/store/protocol';
import { rewardBatchSelector } from '@/store/reward-batch';

import { getAccountPenaltyRewardsBoosterAPY } from '@/utils/math/apy';

const useStoreAccountMarket = () => {
  const dispatch = useAppDispatch();

  const {
    liquidStaking: { apy: liquidStakingAPY },
    liquidStakingTao: { apy: liquidStakingTaoAPY },
    markets: protocolMarkets,
    userBalances,
  } = useAppSelector(protocolSelector);
  const {
    boosterAccount,
    totalBoosterCollateralMoneyMarkets,
    maximumApyBooster,
  } = useAppSelector(boosterSelector);
  const { markets, showLiquidStakingAPY, showLiquidStakingTaoAPY } =
    useAppSelector(lendAppSelector);
  const htmMarket = useAppSelector(htmMarketSelector);
  const { markets: rewardMarkets } = useAppSelector(rewardBatchSelector);

  useEffect(() => {
    dispatch(updateAccountMarket());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markets]);

  useEffect(() => {
    const accountsBoosterAPYMap = Object.entries(protocolMarkets).reduce(
      (prev, [assetKey, { hTokenExchangeRate, underlying }]) => {
        if (!boosterAccount[assetKey]) {
          return {
            ...prev,
            [assetKey]: '0',
          };
        }

        const value = getAccountPenaltyRewardsBoosterAPY({
          speed: boosterAccount[assetKey]?.speed || '',
          hTokenExchangeRate,
          totalCollateral: totalBoosterCollateralMoneyMarkets[assetKey],
          marketPrice: underlying.priceUSD,
          rewardsToken: boosterAccount[assetKey]?.reward,
          marketDecimals: underlying.decimals,
          htmStakedUSD: new DefiUtils(
            boosterAccount[assetKey]?.accountMarketBooster.staked,
          )
            .toFullDecimals(htmMarket.underlying.decimals)
            .toUSD(htmMarket.underlying.priceUSD)
            .toString(),
          moneyMarketCollateralUSD: new DefiUtils(
            userBalances[assetKey as MARKET_KEY]?.underlyingCollateralBalance,
          )
            .toFullDecimals(underlying.decimals)
            .toUSD(underlying.priceUSD)
            .toString(),
          maximumApyBooster,
        });

        return {
          ...prev,
          [assetKey]: value.toSafeString(),
        };
      },
      {} as Record<MARKET_KEY, string>,
    );

    const netAPY = calculateNetAPY({
      markets,
      rewardMarkets,
      liquidStakingAPY,
      showLiquidStakingAPY,
      liquidStakingTaoAPY,
      showLiquidStakingTaoAPY,
      accountsBoosterAPYMap,
    });
    dispatch(updateAccountMarketNetAPY(netAPY));
  }, [
    markets,
    rewardMarkets,
    liquidStakingAPY,
    showLiquidStakingAPY,
    protocolMarkets,
    dispatch,
    boosterAccount,
    totalBoosterCollateralMoneyMarkets,
    htmMarket.underlying.decimals,
    htmMarket.underlying.priceUSD,
    userBalances,
    maximumApyBooster,
    liquidStakingTaoAPY,
    showLiquidStakingTaoAPY,
  ]);
};

export default useStoreAccountMarket;
