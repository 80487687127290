import React from 'react';

const Soon = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.30535 11.1373C4.21353 12.2957 4.87028 13.2541 6.14549 13.3301H9.17688C10.4527 13.2541 11.1089 12.2957 11.0171 11.1373C10.9404 10.3333 10.1906 9.60058 8.89413 8.35577C8.47695 7.92158 8.47757 7.48795 8.89413 7.05377C10.1906 5.809 10.9404 5.07683 11.0171 4.27292C11.1089 3.11447 10.4527 2.15609 9.17688 2.08008H6.14549C4.87028 2.15609 4.21353 3.11447 4.30535 4.27292C4.38198 5.07683 5.13177 5.809 6.42826 7.05377C6.84545 7.48795 6.84545 7.92158 6.42826 8.35577C5.13177 9.60058 4.38198 10.3333 4.30535 11.1373Z'
        stroke='#8C89B7'
        strokeWidth='0.936523'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.30469 11.4566C4.50171 11.5537 4.74313 11.6167 5.06421 11.6167C6.36313 11.6167 6.36313 10.5645 7.66266 10.5645C8.96216 10.5645 8.96216 11.6167 10.2617 11.6167C10.5822 11.6167 10.8242 11.5537 11.0267 11.4566'
        fill='#8C89B7'
      />
      <path
        d='M4.68445 12.2283C4.88148 12.3254 5.12289 12.3884 5.44398 12.3884C6.74289 12.3884 6.74289 11.3362 8.04242 11.3362C9.34192 11.3362 9.34192 12.3884 10.6415 12.3884C10.9619 12.3884 10.0592 12.0971 10.2617 12'
        fill='#8C89B7'
      />
      <path
        d='M5.06421 11.6167C4.74313 11.6167 4.50171 11.5537 4.30469 11.4566L5.06421 13H7.32812H9.32812L10.2617 12.5L11.0267 11.4566C10.8242 11.5537 10.5822 11.6167 10.2617 11.6167M5.06421 11.6167C6.36313 11.6167 6.36313 10.5645 7.66266 10.5645C8.96216 10.5645 8.96216 11.6167 10.2617 11.6167M5.06421 11.6167C6.36313 11.6167 7.0286 12.5 8.32812 12.5C9.62763 12.5 8.96216 11.6167 10.2617 11.6167M4.68445 12.2283C4.88148 12.3254 5.12289 12.3884 5.44398 12.3884C6.74289 12.3884 6.74289 11.3362 8.04242 11.3362C9.34192 11.3362 9.34192 12.3884 10.6415 12.3884C10.9619 12.3884 10.0592 12.0971 10.2617 12'
        stroke='#8C89B7'
        strokeWidth='0.936523'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Soon;
