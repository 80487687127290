import { SVGProps } from 'react';

const CrossIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width='9'
      height='9'
      viewBox='0 0 9 9'
      fill='none'
      stroke='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      strokeWidth='2'
      {...props}
    >
      <path
        d='M1.22656 7.78571L7.79798 1.21429'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.22656 1.21429L7.79798 7.78571'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CrossIcon;
