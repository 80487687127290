import classNames from 'classnames';
import React from 'react';

import { Checkbox } from './Checkbox';

export const AcceptDeal: React.FC<any> = ({
  iconSrc = 'https://cdn.app.hatom.com/images/staking/app/rocket.png',
  message,
  checked,
  onChange,
  asMessage,
  disabled,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'grid grid-cols-[27px,auto,auto] place-items-start gap-1',
        className
      )}
      {...props}
    >
      <div className='w-7'>
        <img
          className={classNames({
            'saturate-0': disabled || !checked,
          })}
          src={iconSrc}
          alt=''
        />
      </div>
      <div
        className={classNames(
          'font-hass text-[14px] transition-colors sm:text-[16px] sm:leading-6',
          disabled || !checked
            ? 'text-[#A4A4AB]'
            : 'text-[#5C5C5C] dark:text-[#9692BA]',
          {
            'max-w-[343px]': !asMessage,
          }
        )}
      >
        {message}
      </div>
      {!asMessage && (
        <div className='justify-self-end pt-1.5'>
          <Checkbox
            className={classNames({
              'cursor-not-allowed': disabled,
            })}
            disabled={disabled}
            checked={checked}
            onChange={onChange}
            data-testid='addCollateral'
            data-testvalue={checked}
          />
        </div>
      )}
    </div>
  );
};
