import { useState } from 'react';

import useAccountInfoSync from '@/contexts/AuthContext/hooks/useAccountInfoSync';
import useAccountNetworkSync from '@/contexts/AuthContext/hooks/useAccountNetworkSync';
import useApiNetworkProviderSync from '@/contexts/AuthContext/hooks/useApiNetworkProviderSync';
import useAuthProviderSync from '@/contexts/AuthContext/hooks/useAuthProviderSync';
import useLoginInfoSync from '@/contexts/AuthContext/hooks/useLoginInfoSync';
import useLoginWithNativeAuthToken from '@/contexts/AuthContext/hooks/useLoginWithNativeAuthToken';
import useLoginExperimentalWebview from '@/contexts/AuthContext/hooks/useLoginExperimentalWebview';

export const STORAGE_KEYS = {
  LOGIN_INFO_KEY: 'elrond_dapp__loginInfo',
  ACCOUNT_KEY: 'elrond_dapp__account',
};

const useElrondNetworkSync = () => {
  const [accountDone, setAccountDone] = useState(false);
  const [loginInfoDone, setLoginInfoDone] = useState(false);

  useApiNetworkProviderSync();
  useLoginInfoSync({ setLoginInfoDone });
  useAccountInfoSync({ setAccountDone });
  useAuthProviderSync({ accountDone, loginInfoDone });
  useAccountNetworkSync();
  useLoginWithNativeAuthToken();
  useLoginExperimentalWebview();
};

export default useElrondNetworkSync;
