import classNames from 'classnames';
import { PropsWithChildren } from 'react';

export const Container = ({ children }: PropsWithChildren) => {
  return (
    <div
      className={classNames(
        'bg-[#fff]',
        'shadow-[0px_6px_0px_#D3CFE2,9px_16px_14px_#DEDEEC]',
        'rounded-[20px]',
        'grid',
        'grid-cols-1',
        'xl:grid-cols-2',
        'overflow-hidden',
        'mb-[22px]',
        'dark:shadow-[0px_6px_0px_#171430,9px_16px_14px_#000000]',
        'dark:bg-[#171430]'
      )}
    >
      {children}
    </div>
  );
};
