import { motion } from 'framer-motion'

import { walletItemsAnimationsTransition } from '@/components/popups/ConnectBridgeWalletPopup/components/wallets/constants';

const noGradientVariants = {
  normal: {
    fill: "#313944",
  },
  show: {
    fill: "#313944",
  },
  hover: {
    fill: "#626B78",
  },

  transition: walletItemsAnimationsTransition
}


const withGradientVariants = (stopColor: string, hoverStopColor: string) => ({
  normal: {
    stopColor
  },
  hover: {
    stopColor: hoverStopColor
  },
  transition: walletItemsAnimationsTransition
})



function BridgeWalletShape3({ className, withGradient = false, onClick }: { className?: string, withGradient?: boolean, onClick: () => void }) {


  return (
    <svg className={className} width="100" height="163" viewBox="0 0 100 163" fill="none" xmlns="http://www.w3.org/2000/svg" >
      {
        !withGradient
          ? (<motion.path
            onClick={onClick}
            d="M65.6421 0C71.8043 0 77.6224 2.8406 81.4123 7.69952L95.7464 26.077C98.4875 29.5914 99.9763 33.9206 99.9763 38.3775V128.181C99.9763 137.452 93.6043 145.508 84.5821 147.643L25.2804 161.677C12.7198 164.649 0.674561 155.122 0.674561 142.215V20C0.674561 8.95431 9.62887 0 20.6746 0H65.6421Z"
            variants={noGradientVariants}
          />)
          : (<>
            <path onClick={onClick} d="M65.6421 0C71.8043 0 77.6224 2.8406 81.4123 7.69952L95.7464 26.077C98.4875 29.5914 99.9763 33.9206 99.9763 38.3775V128.181C99.9763 137.452 93.6043 145.508 84.5821 147.643L25.2804 161.677C12.7198 164.649 0.674561 155.122 0.674561 142.215V20C0.674561 8.95431 9.62887 0 20.6746 0H65.6421Z" fill="url(#paint0_linear_1410_727)" />
            <defs>
              <linearGradient id="paint0_linear_1410_727" x1="80.5254" y1="1.33733" x2="67.5379" y2="168.835" gradientUnits="userSpaceOnUse">
                <motion.stop
                  offset="0.569099"
                  variants={withGradientVariants("#1A58C9", "#3977E8")}
                />
                <motion.stop
                  offset="1"
                  variants={withGradientVariants("#818AE8", "#A3AAF9")}
                />
              </linearGradient>
            </defs>
          </>)
      }
    </svg>
  )

}

export default BridgeWalletShape3;