import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';
import useLiquidLockingInteraction from '@/hooks/interaction/useLiquidLockingInteraction';
import useMediaQuery from '@/hooks/useMediaQuery';

import ClosePopupBtn from '@/components/ClosePopupBtn';
import Hint from '@/components/Hint';
import PopupBed from '@/components/popups/PopupBed';
import AmountInput from '@/components/popups/XLaunchpadSnapshotPopup/components/AmountInput';
import ClaimsAndRequests from '@/components/popups/XLaunchpadSnapshotPopup/components/ClaimsAndRequests';
import HeaderLogo from '@/components/popups/XLaunchpadSnapshotPopup/components/HeaderLogo';
import InteractionSwitch from '@/components/popups/XLaunchpadSnapshotPopup/components/InteractionSwitch';
import { Label } from '@/components/popups/XLaunchpadSnapshotPopup/components/Label';
import SubmitButton from '@/components/popups/XLaunchpadSnapshotPopup/components/SubmitButton';
import TokenSwitch from '@/components/popups/XLaunchpadSnapshotPopup/components/TokenSwitch';
import {
  INTERACTION_KEY,
  TOKEN_KEY,
} from '@/components/popups/XLaunchpadSnapshotPopup/types';

import { useAppSelector } from '@/store';
import { liquidLockingAppSelector } from '@/store/liquid-locking-app';
import { getRouteGroup } from '@/store/popup';
import { H_MARKET_KEY, MARKET_KEY, protocolSelector } from '@/store/protocol';
import {
  hasPendingTransactionsSelector,
  TRANSACTION_SUBGROUP_TYPE,
} from '@/store/transaction';

import clsxm from '@/services/clsxm';


const symbolMap: Record<string, string> = {
  [TOKEN_KEY.SEGLD]: 'sEGLD',
  [TOKEN_KEY.HSEGLD]: 'HsEGLD',
};

const XLaunchpadSnapshotPopup = () => {
  const md = useMediaQuery('(min-width: 768px)');
  const lg = useMediaQuery('(min-width: 1024px)');

  const { t } = useTranslation();
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const router = useRouter();
  const { lockedTokens } = useAppSelector(liquidLockingAppSelector);
  const { userBalances, markets } = useAppSelector(protocolSelector);

  const [inputValue, setInputValue] = useState<string>('');
  const [selectedInteraction, setSelectedInteraction] =
    useState<INTERACTION_KEY>(INTERACTION_KEY.STAKE);
  const [selectedToken, setSelectedToken] = useState<TOKEN_KEY>(
    TOKEN_KEY.SEGLD,
  );

  const { signTransactions } = useSignMultipleTransactions();
  const { lock, unlock } = useLiquidLockingInteraction();

  const priceUSD = useMemo(() => {
    const priceUSDMap = Object.values(markets).reduce(
      (prev, current) => {
        return {
          ...prev,
          [current.underlying.symbol]: current.underlying.priceUSD,
          [current.hToken.symbol]: current.hToken.priceUSD,
        };
      },
      {} as Record<MARKET_KEY | H_MARKET_KEY, string>,
    );

    return priceUSDMap[selectedToken] || '0';
  }, [markets, selectedToken]);

  const decimalsLimit = useMemo(() => {
    const decimalsLimitDMap = Object.values(markets).reduce(
      (prev, current) => {
        return {
          ...prev,
          [current.underlying.symbol]: current.underlying.decimals,
          [current.hToken.symbol]: current.hToken.decimals,
        };
      },
      {} as Record<MARKET_KEY | H_MARKET_KEY, number>,
    );

    return decimalsLimitDMap[selectedToken] || 0;
  }, [markets, selectedToken]);

  const maxValue = useMemo(() => {
    switch (selectedInteraction) {
      case INTERACTION_KEY.STAKE: {
        const balancesMap = Object.values(markets).reduce(
          (prev, current) => ({
            ...prev,
            [current.underlying.symbol]: new DefiUtils(
              userBalances[current.underlying.symbol as MARKET_KEY]
                ?.underlyingBalance || '0',
            )
              .toFullDecimals(current.underlying.decimals)
              .toString(),
            [current.hToken.symbol]: new DefiUtils(
              userBalances[current.underlying.symbol as MARKET_KEY]
                ?.hTokenBalance || '0',
            )
              .toFullDecimals(current.hToken.decimals)
              .toString(),
          }),
          {} as Record<MARKET_KEY | H_MARKET_KEY, string>,
        );

        return balancesMap?.[selectedToken] || '0';
      }

      case INTERACTION_KEY.UNSTAKE: {
        const balancesMap = Object.values(markets).reduce(
          (prev, current) => {
            const underlyingUnlockToken = lockedTokens.find(
              ({ tokenIdentifier }) => {
                const selectedTokenId = current.underlying.id;

                return selectedTokenId === tokenIdentifier;
              },
            );

            const hTokenUnlockToken = lockedTokens.find(
              ({ tokenIdentifier }) => {
                const selectedTokenId = current.hToken.id;

                return selectedTokenId === tokenIdentifier;
              },
            );

            return {
              ...prev,
              [current.underlying.symbol]: new DefiUtils(
                underlyingUnlockToken?.amount || 0,
              )
                .toFullDecimals(current.underlying.decimals)
                .toString(),
              [current.hToken.symbol]: new DefiUtils(
                hTokenUnlockToken?.amount || 0,
              )
                .toFullDecimals(current.hToken.decimals)
                .toString(),
            };
          },
          {} as Record<MARKET_KEY | H_MARKET_KEY, string>,
        );

        return balancesMap[selectedToken] || '0';
      }
    }

    return '0';
  }, [lockedTokens, markets, selectedInteraction, selectedToken, userBalances]);

  const btnDisabled = useMemo(() => {
    return (
      new DefiUtils(inputValue || 0).isLessThanOrEqualTo(0) ||
      new DefiUtils(inputValue).isGreaterThan(maxValue) ||
      hasPendingTransactions
    );
  }, [hasPendingTransactions, inputValue, maxValue]);

  const inputValueUSD = useMemo(
    () => new DefiUtils(inputValue).toUSD(priceUSD).toSafeString(),
    [inputValue, priceUSD],
  );

  const maxValueUSD = useMemo(
    () => new DefiUtils(maxValue).toUSD(priceUSD).toSafeString(),
    [maxValue, priceUSD],
  );

  const handleSubmit = async () => {
    if (btnDisabled) {
      return;
    }

    if (selectedInteraction === INTERACTION_KEY.STAKE) {
      await signTransactions(
        [
          lock({
            amount: inputValue,
            symbol: selectedToken?.toUpperCase() as any,
          }),
        ],
        {
          group: getRouteGroup(),
          subgroup: TRANSACTION_SUBGROUP_TYPE.LOCK,
          result: inputValue,
          token: symbolMap[selectedToken],
        },
      );
    }

    if (selectedInteraction === INTERACTION_KEY.UNSTAKE) {
      const unlockTokenMap = Object.values(markets).reduce(
        (prev, current) => {
          const underlyingUnlockToken = lockedTokens.find(
            ({ tokenIdentifier }) => {
              const selectedTokenId = current.underlying.id;

              return selectedTokenId === tokenIdentifier;
            },
          );

          const hTokenUnlockToken = lockedTokens.find(({ tokenIdentifier }) => {
            const selectedTokenId = current.hToken.id;

            return selectedTokenId === tokenIdentifier;
          });

          return {
            ...prev,
            [current.underlying.symbol]: underlyingUnlockToken,
            [current.hToken.symbol]: hTokenUnlockToken,
          };
        },
        {} as Record<
          MARKET_KEY | H_MARKET_KEY,
          { tokenNonce: string; tokenIdentifier: string }
        >,
      );

      await signTransactions(
        [
          unlock({
            tokens: [
              {
                tokenNonce: unlockTokenMap[selectedToken]?.tokenNonce || '',
                tokenIdentifier:
                  unlockTokenMap[selectedToken]?.tokenIdentifier || '',
                amount: new DefiUtils(inputValue)
                  .toBasicUnits(decimalsLimit)
                  .toString(),
              },
            ],
          }),
        ],
        {
          group: getRouteGroup(),
          subgroup: TRANSACTION_SUBGROUP_TYPE.UNLOCK,
          result: inputValue,
          token: symbolMap[selectedToken],
        },
      );
    }
  };

  const handleMax = () => {
    setInputValue(maxValue);
  };

  useEffect(() => {
    setInputValue('');
  }, [selectedInteraction, selectedToken]);

  return (
    <PopupBed
      close={false}
      width={lg ? 496 : md ? 386 : '100vw'}
      className={clsxm(
        'md:translate-y-[11px]',
        !md && '!max-w-[370px] !h-[100vh] rounded-none',
      )}
      enableScaling={false}
    >
      <div className='relative h-screen w-full lg:w-[496px] md:h-[auto]'>
        <div
          className={classNames(
            'text-[#3C3A40] dark:text-white',
            'p-[12px] pb-0 mb-[24px]',
            'md:h-fit lg:p-[16px] lg:pt-[18px] lg:md-[36px]',
            'h-[43.45px] px-[12.41px] py-[12.23px]',
          )}
        >
          <div
            className={clsxm(
              'relative flex h-full w-full justify-center',
              'h-[28.5px] max-h-[28.5px] lg:h-[40px] lg:max-h-[40px]',
            )}
          >
            <div
              className={clsxm([
                'flex items-center',
                'gap-[8px]',
                'font-semibold',
                ' text-[14px] lg:text-[18px]',
                'truncate',
                'leading-[22.5px]',
                'dark:text-[#fff]',
                'text-[#1F2226]',
              ])}
            >
              <HeaderLogo className='h-full' />
              <span className=' inline-block translate-y-[-0.5px]'>
                xLaunchpad Snapshot
              </span>
            </div>

            <ClosePopupBtn
              className={clsxm([
                'absolute right-[-2px] top-[47%] z-10  m-0 flex -translate-y-1/2 items-center  justify-center ',
                'lg:mt-[0.7px] lg:w-[18px] lg:px-[1.6px] lg:pb-[1.05px] lg:pt-[2.05px]',
                // 'w-[14px] h-[14px] pl-[3px] pb-[2.9px] pt-[2px] pr-[2.8px]  mt-[1.25px]',
                'mt-[0.8px] h-[13.97px] w-[13.97px] px-[2.8px] py-[2.7px]',
              ])}
              iconClassName={clsxm(['w-[11px]'])}
              theme={{ light: '#1E1E1E', dark: 'white' }}
            />
          </div>
        </div>

        <div
          className={clsxm(['w-full md:pb-[14.8px]', 'pb-[10.4px] px-[18px]'])}
        >
          <div className='w-full flex flex-col items-center md:px-[18px] lg:px-0'>
            <InteractionSwitch
              className='w-[323px] lg:w-[100%] mb-[24px] lg:mb-[36px] gap-[26px]'
              value={selectedInteraction}
              onChange={setSelectedInteraction}
            />
          </div>

          <TokenSwitch
            className='w-full lg:w-[460px] mb-[24px] lg:mb-[34px]'
            value={selectedToken}
            onChange={setSelectedToken}
          />

          <Label
            className='mb-[6px]'
            label='Amount'
            valueText={
              selectedInteraction === INTERACTION_KEY.STAKE
                ? 'Wallet Balance'
                : 'Staked Balance'
            }
            token={symbolMap[selectedToken]}
            value={maxValue}
            valueUSD={maxValueUSD}
          />

          <AmountInput
            className='mb-[12px] md:mb-[24px] lg:mb-[36px]'
            estimation={inputValueUSD}
            token={selectedToken}
            decimalsLimit={decimalsLimit}
            onChange={setInputValue}
            inputValue={inputValue}
            onMax={handleMax}
          />

          <Hint
            autoReplace
            content={
              <span className='text-[12px] md:text-[10px]'>
                {hasPendingTransactions
                  ? t('translation:wait-tx-inprogress')
                  : t('translation:tx-not-allowed')}
              </span>
            }
            placement='top-center'
            className='w-full'
            unvisible={!hasPendingTransactions}
          >
            <SubmitButton
              isDisabled={btnDisabled}
              onSubmit={handleSubmit}
              className={classNames('mb-[24px]', 'lg:mb-[36px]')}
            >
              {selectedInteraction}
            </SubmitButton>
          </Hint>

          <ClaimsAndRequests isLaunchpad />
        </div>
      </div>
    </PopupBed>
  );
};

export default XLaunchpadSnapshotPopup;
