import { gql } from 'graphql-request';

import client from '@/services/indexer/client';
import { formatMoneyMarketsMap } from '@/services/indexer/lend-app/parsers';
import {
  QueryMoneyMarket,
  ResponseMoneyMarket,
} from '@/services/indexer/lend-app/types';

export const getMoneyMarkets = async (): Promise<ResponseMoneyMarket[]> => {
  const query = gql`
    query LendApp_QueryMoneyMarket()
    @cacheControl(maxAge: 10) {
      queryMoneyMarket {
        underlying {
          id
        }
        dailyStateHistory(first: 2, order: { desc: day }) {
          moneyMarketState {
            cash
          }
        }
      }
    }
  `;

  const variables = {};

  const queryMoneyMarketResponse = await client.request<QueryMoneyMarket>(
    query,
    variables
  );

  return formatMoneyMarketsMap(queryMoneyMarketResponse);
};
