import DefiUtils from 'defi-utils';
import { useEffect, useMemo } from 'react';

import { EXCHANGE_RATE_KEY } from '@/hooks/protocol/useExchangeRate';

import { useAppDispatch, useAppSelector } from '@/store/index';
import {
  setLiquidStakingApp,
  Token,
  TOKEN_SOURCE,
} from '@/store/liquid-staking-app';
import {
  H_TOKEN_DECIMALS,
  sEgldMarketSelector,
  sEgldUserBalanceSelector,
} from '@/store/protocol';

const useLiquidStakingTokens = () => {
  const dispatch = useAppDispatch();

  const sEgldMarket = useAppSelector(sEgldMarketSelector);
  const sEgldUserBalance = useAppSelector(sEgldUserBalanceSelector);

  const hsEgldWallet = useMemo<Token>(() => {
    const symbol = EXCHANGE_RATE_KEY.HsEGLD;
    const source = TOKEN_SOURCE.wallet;
    const id = `${symbol}-${source}`;

    const priceUSD = new DefiUtils(1)
      .toBasicUnits(H_TOKEN_DECIMALS)
      .toUnderlying(sEgldMarket.hTokenExchangeRate)
      .toFullDecimals(sEgldMarket.underlying.decimals)
      .toUSD(sEgldMarket.underlying.priceUSD)
      .toString();

    const balance = new DefiUtils(sEgldUserBalance.hTokenBalance)
      .toFullDecimals(H_TOKEN_DECIMALS)
      .toString();

    return {
      id,
      symbol,
      source,
      balance,
      priceUSD,
      balanceUSD: new DefiUtils(balance).toUSD(priceUSD).toString(),
      decimals: H_TOKEN_DECIMALS,
      index: 1,
    };
  }, [
    sEgldUserBalance.hTokenBalance,
    sEgldMarket.hTokenExchangeRate,
    sEgldMarket.underlying.priceUSD,
    sEgldMarket.underlying.decimals,
  ]);

  const hsEgldCollateral = useMemo<Token>(() => {
    const symbol = EXCHANGE_RATE_KEY.HsEGLD;
    const source = TOKEN_SOURCE.collateral;
    const id = `${symbol}-${source}`;
    const balance = new DefiUtils(sEgldUserBalance.collateralBalance)
      .toFullDecimals(H_TOKEN_DECIMALS)
      .toString();
    const priceUSD = new DefiUtils(1)
      .toBasicUnits(H_TOKEN_DECIMALS)
      .toUnderlying(sEgldMarket.hTokenExchangeRate)
      .toFullDecimals(sEgldMarket.underlying.decimals)
      .toUSD(sEgldMarket.underlying.priceUSD)
      .toString();

    return {
      id,
      symbol,
      source,
      balance,
      priceUSD,
      balanceUSD: new DefiUtils(balance).toUSD(priceUSD).toString(),
      decimals: H_TOKEN_DECIMALS,
      index: 2,
    };
  }, [
    sEgldUserBalance.collateralBalance,
    sEgldMarket.hTokenExchangeRate,
    sEgldMarket.underlying.priceUSD,
    sEgldMarket.underlying.decimals,
  ]);

  const sEgldWallet = useMemo<Token>(() => {
    const balance = new DefiUtils(sEgldUserBalance.underlyingBalance)
      .toFullDecimals(sEgldMarket.underlying.decimals)
      .toString();

    const symbol = EXCHANGE_RATE_KEY.sEGLD;
    const source = TOKEN_SOURCE.wallet;

    return {
      id: `${symbol}-${source}`,
      symbol,
      source,
      balance,
      balanceUSD: new DefiUtils(balance)
        .toUSD(sEgldMarket.underlying.priceUSD)
        .toString(),
      decimals: sEgldMarket.underlying.decimals,
      priceUSD: sEgldMarket.underlying.priceUSD,
      index: 0,
    };
  }, [
    sEgldUserBalance.underlyingBalance,
    sEgldMarket.underlying.priceUSD,
    sEgldMarket.underlying.decimals,
  ]);

  useEffect(() => {
    const tokens = {
      [sEgldWallet.id]: sEgldWallet,
      [hsEgldCollateral.id]: hsEgldCollateral,
      [hsEgldWallet.id]: hsEgldWallet,
    } as Record<string, Token>;

    dispatch(setLiquidStakingApp({ tokens }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hsEgldCollateral, hsEgldWallet, sEgldWallet]);
};

export default useLiquidStakingTokens;
