import classNames from 'classnames';

type TokensGroupTitleProps = {
  children: React.ReactNode;
};

export default function TokensGroupTitle({ children }: TokensGroupTitleProps) {
  return (
    <div
      className={classNames(
        'mb-2 text-sm font-semibold leading-tight',
        'dark:text-white'
      )}
    >
      {children}
    </div>
  );
}
