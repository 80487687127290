import {
  HATOM_TOKENS_REPORTER_MAP,
  HatomToken,
  MARKET_TOKENS_REPORTER_MAP,
  MarketToken,
} from '@/store/price';
import { H_MARKET_KEY, MARKET_KEY, MARKET_ORDER_MAP, Market } from '@/store/protocol';

import { chainType, networkConfig } from '@/config/network';

export const formatMarketTokenPrices = (
  markets: Record<MARKET_KEY, Market>,
  variation24hPercentMap: Record<string, string>,
): MarketToken[] => {
  return Object.values(markets)
    .map(({ underlying, address, logo, supported }) => {
      const reporterId =
        MARKET_TOKENS_REPORTER_MAP[underlying.symbol as MARKET_KEY] || '';

      const order = MARKET_ORDER_MAP[underlying.symbol as MARKET_KEY] || 999;

      return {
        id: address,
        reporterId,
        url: `${networkConfig[chainType].explorerAddress}/accounts/${address}`,
        symbol: underlying.symbol,
        priceUSD: underlying.priceUSD,
        variation24hPercent: variation24hPercentMap[underlying.symbol] || '0',
        image: logo.normal,
        isSupported: supported,
        order,
      };
    })
    .sort((a, b) => a.order - b.order)
    .map(({ order, ...rest }) => rest)
    .filter(({ symbol }) => symbol !== MARKET_KEY.BUSD);
};

export const formatHatomTokenPrices = (
  markets: Record<MARKET_KEY, Market>,
  variation24hPercentMap: Record<string, string>,
): HatomToken[] => {
  return Object.values(markets)
    .map(({ underlying, hToken, supported, logo }) => {
      const reporterId =
        HATOM_TOKENS_REPORTER_MAP[underlying.symbol as MARKET_KEY] || '';
      const image = logo.hLogo;

      const order = MARKET_ORDER_MAP[underlying.symbol as MARKET_KEY] || 999;

      return {
        id: hToken.id,
        url: `${networkConfig[chainType].explorerAddress}/tokens/${hToken.id}`,
        symbol: hToken.symbol,
        priceUSD: hToken.priceUSD,
        variation24hPercent: variation24hPercentMap[underlying.symbol] || '0',
        image,
        reporterId,
        isSupported: supported,
        order,
      };
    })
    .sort((a, b) => a.order - b.order)
    .map(({ order, ...rest }) => rest)
    .filter(({ symbol }) => symbol !== H_MARKET_KEY.HBUSD);
};
