import classNames from 'classnames';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import React from 'react';

import useSetVariantOnLoadingEnd, {
    TRANSITION_VARIANTS,
} from '@/hooks/framerMotion/useSetVariantOnLoadingEnd';

import WrappedImg from '@/components/WrappedImg';

import clsxm from '@/services/clsxm';

const BuiltOn: React.FC<any> = ({ isLiquidHome = false }) => {
  const { t } = useTranslation('staking-app');

  return (
    <div
      className={clsxm(
        'flex items-center font-hass md:translate-x-1',
        isLiquidHome && 'md:translate-y-[-3.1px] sliderLg:translate-y-0 ',
      )}
    >
      <WrappedImg
        className={clsxm('h-[46px] md:h-[47px] sliderLg:h-[64px]')}
        src='https://cdn.app.hatom.com/images/liquidstaking/app/built-on.svg'
      />
      <div
        className={clsxm(
          'flex  items-center gap-[4px] whitespace-nowrap text-superxs font-semibold dark:text-white',
          'translate-x-[-0.5px] translate-y-[-0.4px] md:translate-y-0 md:translate-x-0 sliderLg:text-[14px]',
        )}
      >
        {t('built-on')}{' '}
        <svg
          width='85'
          height='16'
          viewBox='0 0 85 16'
          fill='currentColor'
          xmlns='http://www.w3.org/2000/svg'
          className={clsxm(
            '-translate-x-px translate-y-[0.5px] ',
            'w-[62px] md:h-2.5 h-auto md:w-auto sliderLg:h-4',
          )}
        >
          <path d='M37.5332 0.710938V2.20388H35.7891V0.710938H37.5332Z' />
          <path d='M1.99987 4.56635V5.37561C1.99987 7.80745 1.9958 10.2387 2.00568 12.67C2.00568 12.9374 1.93417 13.0269 1.66558 13.0095C1.32781 12.9874 0.987714 12.9961 0.648779 13.0095C0.469138 13.0147 0.400537 12.9444 0.416234 12.7723C0.420884 12.724 0.416234 12.6752 0.416234 12.6269V3.09434C0.416234 2.70075 0.416234 2.70075 0.808072 2.70075C1.43769 2.70075 2.06789 2.70948 2.69692 2.69552C2.91493 2.69087 3.01783 2.76296 3.10388 2.96702C4.07553 5.33123 5.05319 7.69253 6.03685 10.0509C6.09906 10.1992 6.1694 10.3416 6.26184 10.5474C6.42055 10.1759 6.55775 9.8614 6.69031 9.54456C7.60886 7.34778 8.52548 5.14984 9.44015 2.95074C9.51341 2.77633 9.59538 2.6932 9.79711 2.69669C10.5134 2.70889 11.2296 2.70134 11.9819 2.70134C11.9889 2.81761 12.0005 2.92051 12.0005 3.02283C12.0005 6.24823 12.0026 9.47383 12.0069 12.6996C12.0069 12.9391 11.9423 13.0223 11.6982 13.0118C11.2738 12.9938 10.8488 13.0066 10.3971 13.0066V4.50414L10.3279 4.48031C10.2895 4.55705 10.2459 4.63146 10.2116 4.71285C9.10548 7.37666 8.00089 10.0418 6.89785 12.7083C6.7967 12.9537 6.68507 13.0653 6.39904 13.0572C5.57525 13.0368 5.577 13.0508 5.27178 12.3124C4.2354 9.79745 3.19767 7.28325 2.15858 4.76983C2.12951 4.69948 2.09463 4.63204 2.06207 4.56344L1.99987 4.56635Z' />
          <path d='M57.5064 8.67686H50.026C50.025 9.35388 50.2568 10.0107 50.6823 10.5372C51.8793 12.0563 54.4147 12.0563 55.6303 10.5512C55.6803 10.489 55.7617 10.4134 55.8297 10.4116C56.3308 10.4 56.8326 10.4053 57.3639 10.4053C57.2587 10.9122 57.0186 11.3052 56.7192 11.6622C55.8844 12.6592 54.7815 13.1156 53.5107 13.1935C51.5131 13.3161 49.9225 12.6121 48.9039 10.8331C47.6017 8.56059 48.5109 5.39855 50.7707 4.23525C53.0653 3.0545 56.589 3.85329 57.3506 7.43274C57.4343 7.82749 57.4535 8.23386 57.5064 8.67686ZM55.5879 7.26241C55.3309 6.11131 54.3298 5.15322 52.8839 5.16485C51.4951 5.17647 50.3573 6.02643 50.1451 7.26241H55.5879Z' />
          <path d='M65.2422 10.4677C65.8137 10.4677 66.3468 10.4642 66.877 10.4723C66.9132 10.4759 66.9482 10.487 66.9799 10.5049C67.0115 10.5228 67.039 10.5472 67.0607 10.5764C67.5188 11.299 68.2234 11.6356 69.0274 11.7124C69.6088 11.7676 70.2035 11.7164 70.7866 11.6542C71.0419 11.6269 71.3046 11.4851 71.5227 11.3357C72.193 10.8758 72.1505 9.87875 71.4116 9.54796C70.9552 9.34448 70.4268 9.29041 69.9245 9.20495C69.1147 9.06659 68.2833 9.02124 67.4897 8.82125C66.0491 8.45907 65.2986 7.26204 65.555 5.8656C65.6898 5.13018 66.1195 4.60637 66.7625 4.24593C67.7444 3.69596 68.8123 3.65061 69.8966 3.72909C70.8523 3.79886 71.7447 4.05873 72.4988 4.68311C72.9799 5.06954 73.3322 5.59301 73.5092 6.18419C72.9243 6.18419 72.3755 6.19059 71.8267 6.17721C71.7482 6.17721 71.6622 6.08768 71.5942 6.02373C70.7024 5.16099 69.0193 4.8552 67.8769 5.35924C67.4636 5.54178 67.1543 5.8127 67.1648 6.3179C67.1746 6.7766 67.3973 7.10565 67.9205 7.26378C68.3519 7.39343 68.8077 7.45331 69.2577 7.51086C70.1588 7.62713 71.0674 7.67597 71.9412 7.97595C73.9016 8.64394 74.0475 11.049 73.04 12.1001C72.4883 12.6757 71.804 12.9925 71.0331 13.1274C69.6669 13.3664 68.3275 13.3216 67.0706 12.6559C66.2852 12.2397 65.6904 11.6327 65.3445 10.8014C65.3079 10.7031 65.284 10.602 65.2422 10.4677Z' />
          <path d='M14.1039 3.95024H15.7643V4.26592C15.7643 5.74782 15.7591 7.23088 15.7643 8.71103C15.7643 9.44064 15.8806 10.1482 16.3399 10.7411C16.9701 11.5551 17.8316 11.7841 18.8054 11.5934C19.8007 11.3981 20.4274 10.7621 20.71 9.80457C20.8025 9.4687 20.8481 9.12162 20.8454 8.77323C20.8602 7.27293 20.8602 5.77204 20.8454 4.27057C20.8425 3.99268 20.9321 3.92176 21.1908 3.93513C21.6221 3.95373 22.0553 3.94036 22.5186 3.94036V4.29848C22.5186 7.0774 22.5145 9.85689 22.525 12.6364C22.525 12.9224 22.4634 13.0329 22.157 13.012C21.7355 12.984 21.3105 13.005 20.8629 13.005V11.4754C20.8144 11.5077 20.7696 11.5451 20.7292 11.587C19.8641 12.891 18.645 13.4003 17.1247 13.1265C15.6341 12.8591 14.708 11.9254 14.2865 10.4981C14.1672 10.0692 14.1065 9.62602 14.1063 9.18077C14.0853 7.49481 14.097 5.80886 14.097 4.12291C14.0935 4.07814 14.0987 4.03105 14.1039 3.95024Z' />
          <path d='M30.9668 1.65858V3.93984H31.3063C32.2947 3.93984 33.283 3.94624 34.2713 3.93519C34.5038 3.93519 34.6015 3.98461 34.5881 4.23808C34.5678 4.63225 34.5829 5.02758 34.5829 5.45197H30.9924C30.9819 5.56185 30.968 5.6444 30.968 5.72696C30.968 7.03444 30.9616 8.34309 30.9709 9.64941C30.9808 10.9703 31.468 11.4423 32.7964 11.4441H34.5608V12.9556C34.541 12.9696 34.5259 12.9893 34.5108 12.9893C33.5225 12.969 32.5307 13.0219 31.5557 12.9074C30.2285 12.7521 29.4576 11.8766 29.3419 10.5372C29.2907 9.94068 29.303 9.3378 29.2995 8.73842C29.2942 7.75998 29.2995 6.78213 29.2995 5.80369C29.2995 5.70021 29.2907 5.59615 29.2849 5.46244C28.7175 5.46244 28.1681 5.45313 27.6193 5.46709C27.3926 5.47348 27.3083 5.40895 27.3217 5.1764C27.3403 4.84793 27.3362 4.51772 27.3217 4.18809C27.3141 3.99391 27.3909 3.93694 27.5751 3.93984C28.135 3.9474 28.6954 3.93984 29.2966 3.93984C29.2966 3.41139 29.2966 2.91141 29.2966 2.41202C29.2966 1.65625 29.2966 1.65625 30.0523 1.65625L30.9668 1.65858Z' />
          <path
            d='M75.3125 7.25522L79.3239 5.10418L75.3166 2.95313C75.5235 2.55199 75.7125 2.1648 75.9282 1.79041C75.9566 1.74157 76.1351 1.7375 76.2188 1.77122C77.3356 2.21189 78.4455 2.66943 79.5634 3.10836C79.7139 3.16155 79.8781 3.16155 80.0285 3.10836C81.1558 2.66884 82.2772 2.21364 83.4004 1.75727C83.5353 1.70204 83.633 1.67704 83.7091 1.83401C83.8893 2.19678 84.0783 2.55548 84.2835 2.95313L80.275 5.10418L84.2864 7.25522C84.0795 7.65345 83.8888 8.04122 83.6713 8.41271C83.6429 8.46097 83.4621 8.46038 83.3737 8.42608C82.5441 8.10227 81.7191 7.76566 80.893 7.4337C80.8188 7.41178 80.7466 7.38338 80.6773 7.34882C80.0867 6.97384 79.5262 7.0436 78.9059 7.3401C78.0519 7.74705 77.1508 8.05808 76.2747 8.4226C76.0532 8.51445 75.9258 8.48945 75.8241 8.25633C75.6706 7.92321 75.4904 7.60404 75.3125 7.25522Z'
            fill='#1EEBC6'
          />
          <path d='M43.551 10.8657C43.8079 10.2878 44.0486 9.75122 44.2858 9.21346C45.0189 7.54786 45.7549 5.884 46.4793 4.21374C46.5688 4.00677 46.6717 3.91957 46.906 3.93062C47.3583 3.95154 47.8129 3.93643 48.3205 3.93643C48.2222 4.16897 48.1461 4.36315 48.0606 4.55209C46.8165 7.30388 45.5735 10.0557 44.3317 12.8075C44.2114 13.0766 44.0736 13.197 43.7725 13.1644C43.4713 13.1319 43.087 13.2539 42.8644 13.1127C42.6417 12.9714 42.5696 12.5894 42.4406 12.3104C41.1875 9.59736 39.9357 6.88142 38.685 4.16258C38.6582 4.10444 38.6425 4.03933 38.6094 3.94224C39.2035 3.94224 39.7721 3.93178 40.3413 3.95387C40.4209 3.95678 40.5215 4.10561 40.5668 4.20734C41.1371 5.47762 41.6982 6.75662 42.2627 8.02981C42.6471 8.89643 43.0328 9.76285 43.4196 10.6291C43.4498 10.6971 43.4917 10.7593 43.551 10.8657Z' />
          <path d='M59.0799 3.95579H60.7187V5.40455C60.7705 5.39757 60.7984 5.40106 60.8036 5.39234C61.4204 4.30112 62.4314 3.93893 63.604 3.87615C63.9325 3.85871 64.2627 3.87615 64.5924 3.86743C64.7668 3.86278 64.8348 3.92556 64.8313 4.10404C64.8226 4.511 64.8214 4.91795 64.8313 5.3249C64.8354 5.5121 64.7499 5.54524 64.5854 5.55164C63.9186 5.57605 63.2482 5.56617 62.5867 5.65744C61.3257 5.83185 60.7391 6.5353 60.7327 7.80849C60.7245 9.43631 60.7245 11.0641 60.7327 12.6919C60.7327 12.9286 60.6746 13.0175 60.4269 13.0076C59.9839 12.9902 59.5403 13.0024 59.0781 13.0024L59.0799 3.95579Z' />
          <path d='M24.6094 1.18359H26.2325V12.9643H24.6094V1.18359Z' />
          <path d='M35.8594 12.9731V3.96484H37.4872V12.976L35.8594 12.9731Z' />
        </svg>
      </div>
    </div>
  );
};

const Jumbotron: React.FunctionComponent<any> = ({ isLiquidHome = false }) => {
  const { controls } = useSetVariantOnLoadingEnd();
  const { t } = useTranslation('liquid-app');

  return (
    <LazyMotion features={domAnimation}>
      <m.section
        custom={{ onlyY: true, movePx: 12 }}
        initial='hidden'
        variants={TRANSITION_VARIANTS}
        animate={controls}
        style={{ margin: '-1px 0px -1px -1px' }}
        className={classNames(
          'relative ml-1',
          'flex flex-col',
          'gap-5 gap-x-[38px]',
          'md:gap-x-0',
          'md:flex-row',
          'items-center md:items-center',
          'md:justify-center',
        )}
      >
        <h1
          className={classNames(
            'font-ttFirsNeue text-[32px] font-bold ',
            'max-w-[300px]',
            'translate-x-[-12.8px] text-[40px] md:text-[32px] md:mr-2 md:max-w-[100%] sliderLg:text-[40px]',
          )}
        >
          <div className='sm:hidden'>
            <span
              className={classNames(
                'inline gradient-text [--gradient:linear-gradient(90.2deg,#1F2125,#4B1D90)] dark:[--gradient:linear-gradient(90.2deg,#FFFFFF,#AF80F2)]',
              )}
            >
              Novel Liquid
            </span>

            <div>
              <span
                className={classNames(
                  'inline gradient-text [--gradient:linear-gradient(90.2deg,#4B1D90,#8942EB)] dark:[--gradient:linear-gradient(90.2deg,#AF80F2,#701BE7)]',
                )}
              >
                Staking
              </span>
              <div
                className={clsxm(
                  'inline-block translate-y-[16px] translate-x-[20px] md:hidden',
                  // 'translate-x-[154.4px] translate-y-[-49.5px] md:translate-x-0 md:translate-y-3',
                )}
              >
                <BuiltOn isLiquidHome={isLiquidHome} />
              </div>
            </div>
          </div>

          <span
            className={classNames(
              'hidden sm:inline gradient-text [--gradient:linear-gradient(90.2deg,#1F2226_13.63%,#6F19E7_99.84%)] dark:[--gradient:linear-gradient(90.2deg,#FFFFFF_13.63%,#6F19E7_99.84%)]',
            )}
          >
            {t('page-title')}
          </span>

          <div
            className={clsxm(
              'hidden sm:inline-block translate-y-[16px] translate-x-[20px] md:hidden',
              // 'translate-x-[154.4px] translate-y-[-49.5px] md:translate-x-0 md:translate-y-3',
            )}
          >
            <BuiltOn isLiquidHome={isLiquidHome} />
          </div>
        </h1>

        <div
          className={clsxm(
            'hidden md:block',
            'md:translate-x-[-0.7px] sliderLg:translate-x-0',
          )}
        >
          <BuiltOn isLiquidHome={isLiquidHome} />
        </div>
      </m.section>
    </LazyMotion>
  );
};

export default Jumbotron;
