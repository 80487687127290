import { captureException } from '@sentry/nextjs';
import { useEffect } from 'react';

import { useAppDispatch } from '@/store/index';
import { setIndexer } from '@/store/indexer';
import { getProtocol } from '@/store/protocol';

import { calcFunctionTime } from '@/utils/time';

const useStoreProtocolInfo = () => {
  const dispatch = useAppDispatch();

  const getProtocolInfo = async () => {
    try {
      await Promise.all([
        calcFunctionTime('0.   getProtocol', () => dispatch(getProtocol())),
      ]);

      dispatch(setIndexer({ hasProtocolInfo: true }));
    } catch (error) {
      captureException(error);
    }
  };

  // get initial info
  useEffect(() => {
    getProtocolInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useStoreProtocolInfo;
