const ChevronRightIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width='5'
      height='8'
      viewBox='0 0 5 8'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M0.885061 7.3844C0.769253 7.38506 0.654451 7.36336 0.547239 7.32056C0.440027 7.27776 0.342514 7.21469 0.26029 7.13497C0.177813 7.05501 0.11235 6.95988 0.0676751 6.85507C0.0230008 6.75026 0 6.63784 0 6.52429C0 6.41075 0.0230008 6.29833 0.0676751 6.19352C0.11235 6.0887 0.177813 5.99357 0.26029 5.91362L2.2842 3.94396L0.26029 1.97431C0.178244 1.89411 0.113162 1.79891 0.068759 1.69413C0.024356 1.58935 0.00150174 1.47704 0.00150174 1.36363C0.00150174 1.13458 0.0945908 0.914913 0.26029 0.752951C0.42599 0.590989 0.650727 0.5 0.885061 0.5C1.00109 0.5 1.11599 0.522338 1.22318 0.56574C1.33038 0.609141 1.42779 0.672756 1.50983 0.752951L4.14971 3.33328C4.23219 3.41324 4.29765 3.50837 4.34232 3.61318C4.387 3.718 4.41 3.83042 4.41 3.94396C4.41 4.05751 4.387 4.16993 4.34232 4.27474C4.29765 4.37955 4.23219 4.47468 4.14971 4.55464L1.50983 7.13497C1.42761 7.21469 1.3301 7.27776 1.22288 7.32056C1.11567 7.36336 1.00087 7.38506 0.885061 7.3844Z' />
    </svg>
  );
};

export default ChevronRightIcon;
