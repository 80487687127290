import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import DefiUtils from 'defi-utils';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useMemo } from 'react';
import toast from 'react-hot-toast';
import { useToggle } from 'usehooks-ts';

import useMediaQuery from '@/hooks/useMediaQuery';
import useTheme from '@/hooks/useTheme';

import AvatarAddress from '@/components/AvatarAddress';
import Hint from '@/components/Hint';
import ApproEqualIcon from '@/components/Icons/ApproEqualIcon';
import CopyIcon from '@/components/Icons/CopyIcon';
import QrCodeDarshboardIcon from '@/components/Icons/QrCodeDarshboardIcon';
import QrCodeReaderIcon from '@/components/Icons/QrCodeReaderIcon';
import { TokenRowSkeleton } from '@/components/popups/AccountDetailsPopup/components/TokenRow/TokenRowSkeleton';
import TokensGroupTitle from '@/components/popups/AccountDetailsPopup/components/TokensGroupTitle';
import useTabber from '@/components/popups/AccountDetailsPopup/components/useTabber';
import PopupBed from '@/components/popups/PopupBed';
import PopupHead from '@/components/popups/PopupBed/components/PopupHead';
import ScrollBlockTargetElement from '@/components/ScrollBlockTargetElement';
import Tabber from '@/components/Tabber';

import { accountSelector } from '@/store/auth';
import { useAppSelector } from '@/store/index';
import { indexerSelector } from '@/store/indexer';
import {
  MARKET_KEY,
  nativeMarketSelector,
  OTHER_TOKENS_IDS,
  protocolSelector,
  sEgldMarketSelector,
  sEgldUserBalanceSelector,
} from '@/store/protocol';

import clsxm from '@/services/clsxm';
import { formatNumber } from '@/utils/helpers';
import { shortenHash } from '@/utils/shortenHash';

import ActionButtons from './components/ActionButtons';
import NA from './components/NA';
import NoTransaction from './components/NoTransaction';
import TokenRow from './components/TokenRow';
import TxHistoryItem from './components/TxHistoryItem';
import OtherTokenItem from '@/components/popups/AccountDetailsPopup/components/OtherTokenItem';
import logger from '@/utils/logger';

const AddressQrCode = dynamic(() => import('./AddressQrCode'), {
  ssr: false,
});

interface Token {
  tokenIdentifier: string;
  logoUrl: string;
  name: string;
  symbol: string;
  decimals: number;
  priceUSD: string;
  balance: string;
  s_balance: string;
}

const AccountDetailsPopup = () => {
  const { selectedTab, tabChanged } = useTabber();
  const { address, balance, transactions, tokens } =
    useAppSelector(accountSelector);
  const nativeMarket = useAppSelector(nativeMarketSelector);
  const sEgldMarket = useAppSelector(sEgldMarketSelector);
  const sEgldUserBalance = useAppSelector(sEgldUserBalanceSelector);
  const { markets } = useAppSelector(protocolSelector);
  const { isLoadingFirstInfo } = useAppSelector(indexerSelector);

  const s_balance = useMemo(
    () =>
      new DefiUtils(balance)
        .toFullDecimals(nativeMarket.underlying.decimals)
        .toString(),
    [nativeMarket.underlying.decimals, balance],
  );

  const hasTransactions = useMemo(
    () => transactions.length > 0,
    [transactions.length],
  );

  const listedTokensIds = useMemo(() => {
    const marketsUnderlyingIds = Object.values(markets)
      .filter(
        ({ underlying }) =>
          ![MARKET_KEY.EGLD, MARKET_KEY.sEGLD].some(
            (value) => value === underlying.symbol,
          ),
      )
      .map(({ underlying }) => underlying.id);

    return [...marketsUnderlyingIds, ...OTHER_TOKENS_IDS];
  }, [markets]);

  const listedTokens = useMemo(() => {
    const populatedListedTokens = tokens
      .filter((token) => listedTokensIds.includes(token.tokenIdentifier))
      .map(
        ({
          tokenIdentifier,
          balance,
          decimals,
          priceUSD,
          symbol,
          logoUrl,
          name,
        }) => {
          const marketItem = Object.values(markets).find(
            (market) => tokenIdentifier === market.underlying.id,
          );

          const _priceUSD = marketItem?.underlying.priceUSD || priceUSD || '0';

          const s_balance = new DefiUtils(balance).toFullDecimals(decimals);

          return {
            tokenIdentifier: tokenIdentifier,
            logoUrl: marketItem?.logo.normal || logoUrl,
            name: marketItem?.underlying.name || name,
            symbol,
            decimals,
            priceUSD: _priceUSD,
            balance,
            s_balance: s_balance.toString(),
          };
        },
      );

    return populatedListedTokens as Token[];
  }, [tokens, listedTokensIds, markets]);

  const hTokensIds = useMemo(
    () =>
      Object.values(markets)
        .filter(({ hToken }) => hToken.symbol !== MARKET_KEY.USH)
        .map(({ hToken }) => hToken.id),
    [markets],
  );

  const hTokens = useMemo(
    () =>
      tokens
        .filter((token) => hTokensIds.includes(token.tokenIdentifier))
        .map(
          ({
            tokenIdentifier,
            logoUrl,
            name,
            symbol,
            decimals,
            priceUSD,
            balance,
          }) => {
            const marketItem = Object.values(markets).find(
              (market) => tokenIdentifier === market.hToken.id,
            );

            const _priceUSD = marketItem?.hToken.priceUSD || priceUSD || '0';

            const s_balance = new DefiUtils(balance).toFullDecimals(decimals);

            return {
              tokenIdentifier: tokenIdentifier,
              logoUrl: marketItem?.logo.hLogo || logoUrl,
              name: marketItem?.hToken.name || name,
              symbol,
              decimals,
              priceUSD: _priceUSD,
              balance,
              s_balance: s_balance.toString(),
            };
          },
        ) as Token[],
    [tokens, hTokensIds, markets],
  );

  const hasListedTokens = useMemo(
    () => listedTokens && listedTokens.length > 0,
    [listedTokens],
  );

  const hasHTokens = useMemo(() => hTokens && hTokens.length > 0, [hTokens]);

  const topTokens = useMemo(
    () =>
      [
        {
          tokenIdentifier: nativeMarket.underlying.id,
          logoUrl: nativeMarket.logo?.normal,
          name: nativeMarket.underlying.name,
          symbol: nativeMarket.underlying.symbol,
          decimals: nativeMarket.underlying.decimals,
          priceUSD: nativeMarket.underlying.priceUSD,
          balance: balance,
          s_balance: parseFloat(s_balance),
        },
        {
          tokenIdentifier: sEgldMarket.underlying.id,
          logoUrl: sEgldMarket.logo?.normal,
          name: sEgldMarket.underlying.name,
          symbol: sEgldMarket.underlying.symbol,
          decimals: sEgldMarket.underlying.decimals,
          priceUSD: sEgldMarket.underlying.priceUSD,
          balance: sEgldUserBalance.underlyingBalance,
          s_balance: new DefiUtils(sEgldUserBalance.underlyingBalance)
            .toFullDecimals(sEgldMarket.underlying.decimals)
            .toString(),
        },
      ] as Token[],
    [s_balance, nativeMarket, sEgldMarket, sEgldUserBalance],
  );

  const totalBalanceUSD = useMemo(() => {
    const totalTopTokens = topTokens.reduce((prev, current) => {
      const value = new DefiUtils(current.s_balance).toUSD(current.priceUSD);

      return prev.plus(value);
    }, new DefiUtils(0));

    const totalListedTokens = listedTokens.reduce((prev, current) => {
      const value = new DefiUtils(current.s_balance).toUSD(current.priceUSD);

      return prev.plus(value);
    }, new DefiUtils(0));

    const totalHTokens = hTokens.reduce((prev, current) => {
      const value = new DefiUtils(current.s_balance).toUSD(current.priceUSD);

      return prev.plus(value);
    }, new DefiUtils(0));

    return totalTopTokens.plus(totalListedTokens).plus(totalHTokens).toString();
  }, [hTokens, listedTokens, topTokens]);

  const otherTokens = useMemo(() => {
    const ids = [
      ...listedTokens.map(({ tokenIdentifier }) => tokenIdentifier),
      ...hTokens.map(({ tokenIdentifier }) => tokenIdentifier),
      ...topTokens.map(({ tokenIdentifier }) => tokenIdentifier),
    ];

    return tokens
      .filter(
        (item) =>
          !ids.includes(item.tokenIdentifier) &&
          typeof item.attributes !== 'undefined' &&
          item.balance === '1',
      )
      .map(({ tokenIdentifier, balance, decimals }) => {
        return {
          balance,
          tokenIdentifier,
          decimals,
        };
      });
  }, [listedTokens, hTokens, topTokens, tokens]);

  const { t } = useTranslation();
  const copyToClipboard = (value: string) => {
    toast(t('address-copied-to-clipboard'), {
      icon: (
        <div className='grid h-[22px] w-[20px] place-content-center rounded bg-[#45A373] text-white'>
          <CopyIcon height={14} />
        </div>
      ),
    });
    copy(value);
  };

  logger.info({ otherTokens });

  const md = useMediaQuery('(min-width: 768px)', window.innerWidth > 768);
  const { isDark } = useTheme();
  const [showQrCode, toggleVisibility] = useToggle();

  return (
    <PopupBed close={false} width={md ? 562 : 454}>
      <div style={{ width: md ? 562 : 454 }} className='flex flex-col'>
        <PopupHead>
          <div className='flex items-center gap-2 '>
            <Hint content={t('id-signature')} backgroundColor='#030303'>
              <AvatarAddress address={address} className='w-3.5' />
            </Hint>
            {t('account-details')}
          </div>
        </PopupHead>

        {/* Body */}
        <div className='md:px-[50px] min-h-[113px] px-5 py-4 font-semibold leading-tight tracking-wide'>
          <span
            className={classNames('text-sm text-[#3A3C40] dark:text-white')}
          >
            {t('your-wallet')}
          </span>

          <div
            className={classNames(
              'relative mt-1.5 flex overflow-hidden rounded-[8px] border py-2.5',
              'dark:border-[#6A6A8C] border-[#535367]',
            )}
          >
            <button
              className={classNames(
                'absolute left-3 top-1/2 -translate-y-1/2',
                'dark:text-[#6A6A8C] text-[#535367]',
              )}
              onClick={() => copyToClipboard(address)}
            >
              <CopyIcon />
            </button>
            <div
              className={classNames(
                'select-all',
                'mx-auto text-base',
                'dark:text-[#6A6A8C] text-[#535367]',
              )}
            >
              {shortenHash(address, md ? 17 : 12)}
            </div>
            <button
              className={classNames(
                'absolute right-3 top-1/2 -translate-y-1/2',
                'dark:text-[#6A6A8C] text-[#535367]',
              )}
              onClick={() => toggleVisibility()}
            >
              {showQrCode ? (
                <QrCodeDarshboardIcon width={26} height={26} />
              ) : (
                <QrCodeReaderIcon width={26} height={26} />
              )}
            </button>
          </div>
        </div>

        <div className='h-full'>
          <div className={clsxm('relative isolate', showQrCode && 'h-[341px]')}>
            {/* Address QrCode */}
            <div
              className={classNames(
                'flex-xy-center dark:bg-[#191733]  absolute inset-0 z-10 overflow-hidden bg-white transition-opacity',
                showQrCode ? 'opacity-100' : 'pointer-events-none opacity-0',
              )}
            >
              <div className='absolute bottom-[42px] left-[8px] -z-20'>
                <Image
                  src={
                    isDark
                      ? 'https://cdn.app.hatom.com/images/pages/lend/qr-graphic-dark.svg'
                      : 'https://cdn.app.hatom.com/images/pages/lend/qr-graphic.svg'
                  }
                  width={542}
                  height={215.83}
                  alt='qr-code'
                />
              </div>
              <AddressQrCode address={address} />
            </div>

            <Tabber
              onChange={tabChanged}
              tabs={[t('balances'), t('history')]}
              defaultSelected={selectedTab}
              customTheme={(tabIdx) => {
                if (Number.isInteger(tabIdx))
                  return 'text-[#006FFF] border-current';
                else
                  return 'text-[#6A6A8C] dark:text-[#625E8F] border-[#E2DFFB] dark:border-[#3F3B76]';
              }}
            >
              <ScrollBlockTargetElement
                style={{ height: [320, 288][selectedTab] }}
                className={classNames(
                  'dark:bg-[#191733]/80 bg-white/70 dark:text-white',
                  'overflow-scroll',
                )}
              >
                {
                  [
                    <div className='mt-px space-y-9 px-5 py-[30px]'>
                      <div>
                        <div className='flex justify-between'>
                          <TokensGroupTitle>
                            {t('total-account-balance')}
                          </TokensGroupTitle>
                          <TokensGroupTitle>
                            <div className='flex items-center gap-1'>
                              <ApproEqualIcon />$
                              {formatNumber(totalBalanceUSD, 3)}
                            </div>
                          </TokensGroupTitle>
                        </div>
                        <div className='space-y-2'>
                          {isLoadingFirstInfo ? (
                            <>
                              {new Array(topTokens.length)
                                .fill(0)
                                .map((_, index) => (
                                  <TokenRowSkeleton key={index} />
                                ))}
                            </>
                          ) : (
                            <>
                              {topTokens.map((token) => (
                                <TokenRow
                                  key={token.tokenIdentifier}
                                  img={token.logoUrl}
                                  night={token.logoUrl}
                                  name={token.name}
                                  currency={token.symbol}
                                  decimals={token.decimals}
                                  price={new DefiUtils(
                                    token.priceUSD,
                                  ).toSafeFixed(6, DefiUtils.ROUND_DOWN)}
                                  balance={token.s_balance}
                                />
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                      <div>
                        <TokensGroupTitle>
                          {t('market-tokens')}
                        </TokensGroupTitle>
                        {isLoadingFirstInfo ? (
                          <>
                            {new Array(3).fill(0).map((_, index) => (
                              <TokenRowSkeleton key={index} />
                            ))}
                          </>
                        ) : (
                          <>
                            {hasListedTokens ? (
                              <div className='space-y-2'>
                                {listedTokens.map((token) => (
                                  <TokenRow
                                    key={token.tokenIdentifier}
                                    img={token.logoUrl}
                                    night={token.logoUrl}
                                    name={token.name}
                                    currency={token.symbol}
                                    decimals={token.decimals}
                                    price={new DefiUtils(
                                      token.priceUSD,
                                    ).toSafeFixed(6, DefiUtils.ROUND_DOWN)}
                                    balance={token.s_balance}
                                  />
                                ))}
                              </div>
                            ) : (
                              <NA>{t('no-market-tokens')}</NA>
                            )}
                          </>
                        )}
                      </div>

                      <div>
                        <TokensGroupTitle>Hatom Tokens</TokensGroupTitle>
                        {hasHTokens ? (
                          <div className='space-y-2'>
                            {isLoadingFirstInfo ? (
                              <>
                                {new Array(3).fill(0).map((_, index) => (
                                  <TokenRowSkeleton key={index} />
                                ))}
                              </>
                            ) : (
                              <>
                                {hTokens.map((token) => (
                                  <TokenRow
                                    key={token.tokenIdentifier}
                                    img={token.logoUrl}
                                    night={token.logoUrl}
                                    name={`Hatom Interest Bearing ${token.symbol}`}
                                    currency={token.symbol}
                                    decimals={token.decimals}
                                    balance={token.s_balance}
                                    price={token.priceUSD}
                                  />
                                ))}
                              </>
                            )}
                          </div>
                        ) : (
                          <NA>{t('no-hatom-tokens')}</NA>
                        )}
                      </div>
                      <div>
                        <TokensGroupTitle>{t('other-tokens')}</TokensGroupTitle>
                        <div className='space-y-2'>
                          {isLoadingFirstInfo ? (
                            <>
                              {new Array(9).fill(0).map((_, index) => (
                                <TokenRowSkeleton key={index} other />
                              ))}
                            </>
                          ) : (
                            //  : otherTokens?.length > 0 ? (
                            //   <>
                            //     {otherTokens.map((token) => {
                            //       return (
                            //         <OtherTokenItem
                            //           key={token.tokenIdentifier}
                            //           identifier={token.tokenIdentifier}
                            //           decimals={token.decimals}
                            //           balance={token.balance}
                            //         />
                            //       );
                            //     })}
                            //   </>
                            // )
                            <NA>{t('no-other-tokens')}</NA>
                          )}
                        </div>
                      </div>
                    </div>,
                    <div className='mt-px space-y-4 px-5 py-5'>
                      {hasTransactions ? (
                        transactions.map(
                          ({ txHash, status, timestamp, ...rest }) => (
                            <TxHistoryItem
                              key={txHash}
                              txHash={txHash as string}
                              status={status}
                              timestamp={timestamp}
                            />
                          ),
                        )
                      ) : (
                        <NoTransaction />
                      )}
                    </div>,
                  ][selectedTab]
                }
              </ScrollBlockTargetElement>
            </Tabber>
          </div>
        </div>
        <div
          className={classNames(
            'z-[99] flex h-[74px] gap-2.5 p-5 pt-2',
            'dark:bg-[#191733] bg-white',
          )}
        >
          <ActionButtons />
        </div>
      </div>
    </PopupBed>
  );
};

export default AccountDetailsPopup;
