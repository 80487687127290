const defaultTheme = require('tailwindcss/defaultTheme');

const fontFamily = {
  hass: ['"Haas", "Lucida Grande", Tahoma, Sans-Serif'],
  ttFirsNeue: ['ttFirsNeue, "Lucida Grande", Tahoma, Sans-Serif'],
  anersbo: ['ANERSBO, "Lucida Grande", Tahoma, Sans-Serif'],
  pixter: ['"PixterDisplay", "Lucida Grande", Tahoma, Sans-Serif'],
};

const colors = {
  blue: {
    DEFAULT: '#3555F7',
    dark: '#0E2D99',
  },
  segld: '#006FFF',
  hsegld: '#885FFD',
  aqua: '#8BD3C0',
  ube: '#D6A4E9',
  purple: '#8D81D5',
  agora: '#06021F',
  metal: '#D2D4DD', // grey family
  indigo: {
    100: '#FBFBFD',
    200: '#D3DBEE',
    300: '#8493B5',
    400: '#6A7DAA',
    500: '#2A3D6B',
    900: '#122145',
    1000: '#031222',
  },
  card: {
    bg: '#0B2037',
  },
  overlay: '#12263B',
  table: {
    heading: '#53648C',
    'row-hover': '#06192F',
  },
  error404: {
    title: '#1F2226',
    darktitle: '#FFFFFF',
    subtitle: '#6A6A8C',
    darksubtitle: '#625E8F',
  },
  success: '#00D394',
  green: '#46A273',
  warning: '#D9A625',
  grey: '#737B80',
  danger: '#E47070',
  red: '#C13A64',
  transparent: 'transparent',
  current: 'currentColor',
  white: '#fff',
  black: '#000',
  dark: '#1F2226',
};

const screens = {
  '2xs': '390px',
  '1xs': '410px',
  xs: '475px',
  '2md': '980px',
  tablet: '640px',
  laptop: '1024px',
  desktop: '1280px',
  'header-phone-bk': '950px',
  sliderLg: '1162px',
  sliderXl: '1446px',
  '1xl': '1144px',
  '2xl': '1512px',
  bridge2md: '960px',
  bridgeXl: '1002px',
  bridgeXxl: '1200px',
  bridge3xl: '1320px',
};

module.exports = {
  darkMode: 'class',
  content: [
    './src/pages/**/*.{js,ts,jsx,tsx}',
    './src/components/**/*.{js,ts,jsx,tsx}',
    './src/sections/**/*.{js,ts,jsx,tsx}',
    './src/hoc/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    maxWidth: {
      localhost: '1512px',
    },
    minWidth: {
      localhost: '1512px',
    },
    colors,
    fontFamily,
    screens: {
      mmd: '850px',
      '1lg': '1174px',
      '2lg': '1370px',
      '2xxl': '1446px',
      xxl: '1512px',
      ...screens,
      ...defaultTheme.screens,
    },
    extend: {
      transitionProperty: {
        width: 'width',
        height: 'height',
      },
      keyframes: {
        'fade-in-out': {
          '0%': { opacity: '0' },
          '50%': { opacity: '1' },
          '100%': { opacity: '0' },
        },
        flip: {
          '0%': { transform: 'rotateX(0deg)', transformOrigin: 'center ' },
          '50%': { transform: 'rotateX(180deg)', transformOrigin: 'center ' },
          '100%': { transform: 'rotateX(0deg)', transformOrigin: 'center ' },
        },
      },
      animation: {
        'fade-in-out': 'fade-in-out 2s infinite 1.4s ease-in-out',
        'fade-in-out-1': 'fade-in-out 2s infinite 2.3s ease-in-out',
        'fade-in-out-2': 'fade-in-out 2s infinite 3.1s ease-in-out',
        flip: 'flip 1s cubic-bezier(0, 0, 0.2, 1) 1s infinite',
      },
      letterSpacing: {
        wide: '0.03em',
      },
      fontSize: {
        superxs: '10px',
      },
      borderRadius: {
        lg: '10px',
      },
    },
  },
  plugins: [require('tailwindcss-scoped-groups')],
};
