import { useEffect } from 'react';

const useEvent = (event: any, element: any, handler: any, passive = false) => {
  element =
    typeof element === 'string' && typeof localStorage !== 'undefined'
      ? document.querySelector(element)
      : element;

  useEffect(() => {
    (element || window).addEventListener(event, handler, passive);
    return function cleanup() {
      (element || window).removeEventListener(event, handler);
    };
  });
};

export default useEvent;
