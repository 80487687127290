import useMediaQuery from '@/hooks/useMediaQuery';
import DefiUtils from 'defi-utils';
import { isEqual, xorWith } from 'lodash';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useLogin } from '@/hooks/auth/useLogin';
import useSignMultipleTransactions, {
  ALLOWED_TRANSACTIONS,
} from '@/hooks/core/useSignMultipleTransactions';
import useLendInteraction from '@/hooks/interaction/useLendInteraction';
import useLiquidStakingHsEgldCollateral from '@/hooks/liquidStakingApp/useLiquidStakingHsEgldCollateral';
import useLiquidStakingMessageError, {
  OPERATION_KEYS,
} from '@/hooks/liquidStakingApp/useLiquidStakingMessageError';
import useExchangeRate, {
  EXCHANGE_RATE_KEY,
} from '@/hooks/protocol/useExchangeRate';

import Hint from '@/components/Hint';

import { boosterSelector } from '@/store/booster';
import { useAppDispatch, useAppSelector } from '@/store/index';
import {
  RECOMMENDED_REMOVED_COLLATERAL_LIMIT_MARGIN,
  TOKEN_SOURCE,
  liquidStakingAppSelector,
} from '@/store/liquid-staking-app';
import { openPopup } from '@/store/popup';
import {
  H_TOKEN_DECIMALS,
  MARKET_KEY,
  calcHasMaxMarketPerAccount,
  hasEnoughEGLDBalanceSelector,
  hasTakenBorrowsSelector,
  protocolSelector,
  sEgldMarketSelector,
} from '@/store/protocol';
import {
  TRANSACTION_GROUP_TYPE,
  TRANSACTION_SUBGROUP_TYPE,
  hasPendingTransactionsSelector,
} from '@/store/transaction';

import { FormContext } from '@/contexts/LiquidStakingFormContext';
import {
  AcceptDeal,
  AmountLimitErrors,
  Label,
  MigrateInput,
  Notes,
  SubmitButton,
  SwitchBtn,
} from '@/sections/Liquid/App/Form/components';
import { MAX_AMOUNT } from '@/sections/Liquid/App/Form/constants';
import { useHintProps } from '@/sections/Liquid/App/global';
import { PrettyToken as ColoredName } from '@/sections/Liquid/Common/PrettyToken';
import { formatNumber } from '@/utils/helpers';

export const MigratingInputs: React.FC<any> = ({ setShowAlert }) => {
  const md = useMediaQuery('(min-width: 768px)');
  const { t } = useTranslation('liquid-app');
  const dispatch = useAppDispatch();
  const { isLoggedIn } = useLogin();
  const {
    exchangeRates,
    userBalances,
    controller,
    marketsInteracted,
    marketsInteractedAmount,
    markets,
  } = useAppSelector(protocolSelector);
  const { tokens: liquidStakingTokens } = useAppSelector(
    liquidStakingAppSelector,
  );
  const hasEnoughEGLDBalance = useAppSelector(hasEnoughEGLDBalanceSelector);
  const sEgldMarket = useAppSelector(sEgldMarketSelector);
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const hasTakenBorrows = useAppSelector(hasTakenBorrowsSelector);

  const hasMaxMarketPerAccount = useMemo(
    () =>
      calcHasMaxMarketPerAccount({
        userBalances,
        controller,
        token: MARKET_KEY.sEGLD,
      }),
    [controller, userBalances],
  );

  const { hasStaked: hasHtmStaked } = useAppSelector(boosterSelector);
  const { signTransactions, isAllowedTransaction, isWhiteListed } =
    useSignMultipleTransactions();
  const {
    withdrawLiquidity,
    removeCollateral,
    supplyLiquidity,
    addCollateral,
    removeAccountMarket,
    supplyLiquidityAndAddCollateral,
    rebalancePortfolio,
  } = useLendInteraction();
  const defaultHintProps = useHintProps();
  const [selectedNumbers, setSelectedNumbers] = useState([0, 1]);
  const router = useRouter();
  const {
    maxRemovableCollateralBalance,
    recommendedMaxRemovableCollateralBalance,
  } = useLiquidStakingHsEgldCollateral();

  const {
    formsState: {
      migratingInputs: { inputValue, onChange },
      operation,
    },
  } = useContext<any>(FormContext);

  const options = useMemo(() => {
    return Object.values(liquidStakingTokens).sort((a, b) => a.index - b.index);
  }, [liquidStakingTokens]);

  const selected = useMemo(() => {
    return selectedNumbers.map((index) => options[index]);
  }, [options, selectedNumbers]);

  const getOtherOptions = useCallback(() => {
    return xorWith(options, selected, isEqual);
  }, [options, selected]);

  const firstSelected = useMemo(() => {
    return selected[0];
  }, [selected]);

  const secondSelected = useMemo(() => {
    return selected[1];
  }, [selected]);

  const options1 = useMemo(() => {
    return [firstSelected, getOtherOptions()[0]];
  }, [firstSelected, getOtherOptions]);

  const options2 = useMemo(() => {
    return [secondSelected, getOtherOptions()[0]];
  }, [secondSelected, getOtherOptions]);

  const exchangeRate = useMemo(() => {
    return (
      exchangeRates[
        `${firstSelected.symbol.toUpperCase()}/${secondSelected.symbol.toUpperCase()}`
      ] || '0'
    );
  }, [exchangeRates, firstSelected.symbol, secondSelected.symbol]);

  const inputValueUSD = useMemo(() => {
    return new DefiUtils(inputValue).toUSD(firstSelected.priceUSD).toString();
  }, [firstSelected.priceUSD, inputValue]);

  const receivedValue = useMemo(() => {
    return new DefiUtils(inputValue).multipliedBy(exchangeRate).toString();
  }, [exchangeRate, inputValue]);

  const receivedValueUSD = useMemo(() => {
    return new DefiUtils(receivedValue)
      .toUSD(secondSelected.priceUSD)
      .toString();
  }, [receivedValue, secondSelected.priceUSD]);

  const notes = useMemo(
    () => [
      {
        caption: t('exchange-rate'),
        tooltipMax: md ? 204 : 294,
        captionTooltip: (
          <>
            <Trans
              i18nKey='tp-14'
              components={[
                <ColoredName token={firstSelected.symbol} />,
                <ColoredName token={secondSelected.symbol} />,
              ]}
              ns='liquid-app'
            />
          </>
        ),
        value: (
          <>
            1 {firstSelected.symbol} ={' '}
            <Hint
              {...defaultHintProps}
              content={exchangeRate}
              hidden={new DefiUtils(exchangeRate).isZero()}
              className='-mt-1 sm:-mt-0.5'
            >
              {formatNumber(
                exchangeRate,
                new DefiUtils(exchangeRate).decimalPlaces() > 2
                  ? 2
                  : new DefiUtils(exchangeRate).decimalPlaces(),
              )}
            </Hint>{' '}
            {secondSelected.symbol}
          </>
        ),
      },
    ],
    [
      defaultHintProps,
      exchangeRate,
      firstSelected.symbol,
      secondSelected.symbol,
      t,
    ],
  );

  const hsegldToSEgld = useExchangeRate(
    EXCHANGE_RATE_KEY.HsEGLD,
    EXCHANGE_RATE_KEY.sEGLD,
  );

  const minValue = useMemo(() => {
    switch (firstSelected.symbol) {
      // 1 htoken en segld
      case EXCHANGE_RATE_KEY.sEGLD: {
        const result = new DefiUtils(1)
          .toFullDecimals(H_TOKEN_DECIMALS)
          .multipliedBy(hsegldToSEgld)
          .removeScientificNotation();

        return result;
      }

      case EXCHANGE_RATE_KEY.HsEGLD: {
        return '0';
      }

      default: {
        return '0';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstSelected.symbol, exchangeRate]);

  const isBtnDisabled = useMemo(() => {
    const fromIsHsegldCollateral =
      firstSelected.symbol === EXCHANGE_RATE_KEY.HsEGLD &&
      firstSelected.source === TOKEN_SOURCE.collateral;

    return (
      !hasEnoughEGLDBalance ||
      new DefiUtils(inputValue).isGreaterThan(firstSelected.balance) ||
      new DefiUtils(inputValue).isZero() ||
      (fromIsHsegldCollateral &&
        new DefiUtils(inputValue).isGreaterThan(maxRemovableCollateralBalance))
    );
  }, [
    firstSelected.balance,
    firstSelected.source,
    firstSelected.symbol,
    hasEnoughEGLDBalance,
    inputValue,
    maxRemovableCollateralBalance,
  ]);

  const hasEightyPercentButton = useMemo(() => {
    const isHsEgldCollateral =
      firstSelected.symbol === EXCHANGE_RATE_KEY.HsEGLD &&
      firstSelected.source === TOKEN_SOURCE.collateral;

    const isBalanceEqualToRecommended = new DefiUtils(
      firstSelected.balance,
    ).isEqualTo(recommendedMaxRemovableCollateralBalance);

    return (
      isHsEgldCollateral && hasTakenBorrows && !isBalanceEqualToRecommended
    );
  }, [
    firstSelected.balance,
    firstSelected.source,
    firstSelected.symbol,
    hasTakenBorrows,
    recommendedMaxRemovableCollateralBalance,
  ]);

  const error = useLiquidStakingMessageError({
    operation: OPERATION_KEYS.MIGRATE,
    balance: firstSelected.balance,
    inputValue: inputValue || '0',
    minValue,
    symbol: firstSelected.symbol,
    source: firstSelected.source as TOKEN_SOURCE,
  });

  const handleConnectWallet = () => {
    dispatch(
      openPopup({
        name: 'connect_bridge_multiverse_wallet',
      }),
    );
  };

  const sEgldToHsEgld = useExchangeRate(
    EXCHANGE_RATE_KEY.sEGLD,
    EXCHANGE_RATE_KEY.HsEGLD,
  );
  const hsEgldToSEgld = useExchangeRate(
    EXCHANGE_RATE_KEY.HsEGLD,
    EXCHANGE_RATE_KEY.sEGLD,
  );

  const isFromSEgldWallet =
    firstSelected.symbol === EXCHANGE_RATE_KEY.sEGLD &&
    firstSelected.source === TOKEN_SOURCE.wallet;
  const isFromHsEgldWallet =
    firstSelected.symbol === EXCHANGE_RATE_KEY.HsEGLD &&
    firstSelected.source === TOKEN_SOURCE.wallet;
  const isFromHsEgldCollateral =
    firstSelected.symbol === EXCHANGE_RATE_KEY.HsEGLD &&
    firstSelected.source === TOKEN_SOURCE.collateral;

  const isToSEgldWallet =
    secondSelected.symbol === EXCHANGE_RATE_KEY.sEGLD &&
    secondSelected.source === TOKEN_SOURCE.wallet;
  const isToHsEgldWallet =
    secondSelected.symbol === EXCHANGE_RATE_KEY.HsEGLD &&
    secondSelected.source === TOKEN_SOURCE.wallet;
  const isToHsEgldCollateral =
    secondSelected.symbol === EXCHANGE_RATE_KEY.HsEGLD &&
    secondSelected.source === TOKEN_SOURCE.collateral;

  const handleMigrate = async () => {
    if (
      hasPendingTransactions ||
      !isAllowedTransaction(ALLOWED_TRANSACTIONS.LIQUID_APP_MIGRATE) ||
      hasMigrateMaxMarketPerAccount
    ) {
      return;
    }

    const tokenKey = sEgldMarket.underlying.symbol;
    const underlyingDecimals = sEgldMarket.underlying.decimals;

    if (isFromSEgldWallet && isToHsEgldWallet) {
      const result = new DefiUtils(inputValue)
        .multipliedBy(sEgldToHsEgld)
        .toString();

      await signTransactions(
        [
          supplyLiquidity({
            tokenKey,
            amountAsBigInteger: new DefiUtils(inputValue)
              .toBasicUnits(underlyingDecimals)
              .toString(),
          }),
        ],
        {
          group: TRANSACTION_GROUP_TYPE.LIQUID,
          subgroup:
            TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_WALLET,
          result,
        },
      );
    } else if (isFromSEgldWallet && isToHsEgldCollateral) {
      const result = new DefiUtils(inputValue)
        .multipliedBy(sEgldToHsEgld)
        .toString();

      const marketInteractedAvaiableToRemove = marketsInteracted.find(
        ({ canRemove }) => canRemove,
      );

      const hasMaxMarketPerAccountByController =
        marketsInteractedAmount >= +controller.maxMarketsPerAccount &&
        !marketsInteracted
          .map(({ address }) => address)
          .includes(markets[MARKET_KEY.sEGLD]?.address || '') &&
        (marketInteractedAvaiableToRemove?.address.length || 0) > 0;

      await signTransactions(
        [
          ...(hasMaxMarketPerAccountByController
            ? [
                removeAccountMarket(
                  marketInteractedAvaiableToRemove?.address || '',
                ),
              ]
            : []),
          supplyLiquidityAndAddCollateral({
            tokenKey,
            amountAsBigNumber: new DefiUtils(inputValue)
              .toBasicUnits(underlyingDecimals)
              .toString(),
          }),
          ...(hasHtmStaked ? [rebalancePortfolio()] : []),
        ],
        {
          group: TRANSACTION_GROUP_TYPE.LIQUID,
          subgroup:
            TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_COLLATERAL,
          result,
          isSecuencial: hasHtmStaked || hasMaxMarketPerAccountByController,
        },
      );
    } else if (isFromHsEgldWallet && isToHsEgldCollateral) {
      const result = inputValue;

      const marketInteractedAvaiableToRemove = marketsInteracted.find(
        ({ canRemove }) => canRemove,
      );

      const hasMaxMarketPerAccountByController =
        marketsInteractedAmount >= +controller.maxMarketsPerAccount &&
        !marketsInteracted
          .map(({ address }) => address)
          .includes(markets[MARKET_KEY.sEGLD]?.address || '') &&
        (marketInteractedAvaiableToRemove?.address.length || 0) > 0;

      await signTransactions(
        [
          ...(hasMaxMarketPerAccountByController
            ? [
                removeAccountMarket(
                  marketInteractedAvaiableToRemove?.address || '',
                ),
              ]
            : []),
          addCollateral({
            tokenKey,
            amountAsBigNumber: new DefiUtils(inputValue)
              .toBasicUnits(H_TOKEN_DECIMALS)
              .toString(),
            maxHTokenBalance: new DefiUtils(inputValue)
              .toBasicUnits(H_TOKEN_DECIMALS)
              .toString(),
            isUnderlyingAmount: false,
          }),
          ...(hasHtmStaked ? [rebalancePortfolio()] : []),
        ],
        {
          group: TRANSACTION_GROUP_TYPE.LIQUID,
          subgroup:
            TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_TOKEN_COLLATERAL,
          result,
          isSecuencial: hasHtmStaked || hasMaxMarketPerAccountByController,
        },
      );
    } else if (isFromHsEgldWallet && isToSEgldWallet) {
      const result = new DefiUtils(inputValue)
        .multipliedBy(hsEgldToSEgld)
        .toString();
      await signTransactions(
        [
          withdrawLiquidity({
            tokenKey,
            amountAsBigNumber: new DefiUtils(inputValue)
              .toBasicUnits(H_TOKEN_DECIMALS)
              .toString(),
            isUnderlyingAmount: false,
          }),
        ],
        {
          group: TRANSACTION_GROUP_TYPE.LIQUID,
          subgroup:
            TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_UNDERLYING_WALLET,
          result,
        },
      );
    } else if (isFromHsEgldCollateral && isToHsEgldWallet) {
      const result = inputValue;

      await signTransactions(
        [
          removeCollateral({
            tokenKey,
            amountAsHTokenBigNumber: new DefiUtils(inputValue)
              .toBasicUnits(H_TOKEN_DECIMALS)
              .toString(),
            isMax: false,
          }),
          ...(hasHtmStaked ? [rebalancePortfolio()] : []),
        ],
        {
          group: TRANSACTION_GROUP_TYPE.LIQUID,
          subgroup:
            TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_TOKEN_WALLET,
          result,
          isSecuencial: hasHtmStaked,
        },
      );
    } else if (isFromHsEgldCollateral && isToSEgldWallet) {
      const result = new DefiUtils(inputValue)
        .multipliedBy(hsEgldToSEgld)
        .toString();

      await signTransactions(
        [
          removeCollateral({
            tokenKey,
            amountAsHTokenBigNumber: new DefiUtils(inputValue)
              .toBasicUnits(H_TOKEN_DECIMALS)
              .toString(),
            isMax: false,
          }),
          withdrawLiquidity({
            tokenKey,
            amountAsBigNumber: new DefiUtils(inputValue)
              .toBasicUnits(H_TOKEN_DECIMALS)
              .toString(),
            isUnderlyingAmount: false,
          }),
        ],
        {
          isSecuencial: true,
          group: TRANSACTION_GROUP_TYPE.LIQUID,
          subgroup:
            TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_UNDERLYING_WALLET,
          result,
        },
      );
    }
  };

  const handleSwitch = () => {
    if (selectedNumbers[0] === firstSelected.index) {
      setSelectedNumbers([secondSelected.index, firstSelected.index]);
    } else {
      setSelectedNumbers([firstSelected.index, secondSelected.index]);
    }

    onChange('0');
  };

  const handleChange = (value: string) => {
    if (!value) {
      onChange(value);
      return;
    }

    const isHsEgld = firstSelected.symbol === EXCHANGE_RATE_KEY.HsEGLD;
    const maxAmount = isHsEgld
      ? new DefiUtils(MAX_AMOUNT).multipliedBy(50).toString()
      : MAX_AMOUNT;

    if (new DefiUtils(value).isGreaterThan(maxAmount)) {
      return;
    }

    onChange(value);
  };

  const handleChangeAmountLimit = (value: string) => {
    onChange(value);
  };

  const handleMax = () => {
    let maxValue = '0';

    const { symbol, source, balance } = firstSelected;

    const isHsEgld = symbol === EXCHANGE_RATE_KEY.HsEGLD;

    const isHsEgldCollateral = isHsEgld && source === TOKEN_SOURCE.collateral;

    if (isHsEgldCollateral && hasTakenBorrows) {
      const isBalanceEqualToRecommended = new DefiUtils(balance).isEqualTo(
        recommendedMaxRemovableCollateralBalance,
      );

      maxValue = isBalanceEqualToRecommended
        ? recommendedMaxRemovableCollateralBalance
        : new DefiUtils(recommendedMaxRemovableCollateralBalance)
            .multipliedBy(RECOMMENDED_REMOVED_COLLATERAL_LIMIT_MARGIN)
            .toSafeFixed(H_TOKEN_DECIMALS, DefiUtils.ROUND_DOWN);
    } else if (isHsEgldCollateral && !hasTakenBorrows) {
      maxValue = maxRemovableCollateralBalance;
    } else {
      maxValue = balance;
    }

    const maxAmount = isHsEgld
      ? new DefiUtils(MAX_AMOUNT).multipliedBy(50).toString()
      : MAX_AMOUNT;

    const value = new DefiUtils(
      new DefiUtils(maxValue).isGreaterThan(maxAmount) ? maxAmount : maxValue,
    ).removeScientificNotation();

    onChange(value);
  };

  const hasMigrateMaxMarketPerAccount = useMemo(() => {
    const isFromSEgldWallet =
      firstSelected.symbol === EXCHANGE_RATE_KEY.sEGLD &&
      firstSelected.source === TOKEN_SOURCE.wallet;
    const isFromHsEgldWallet =
      firstSelected.symbol === EXCHANGE_RATE_KEY.HsEGLD &&
      firstSelected.source === TOKEN_SOURCE.wallet;

    const isToHsEgldCollateral =
      secondSelected.symbol === EXCHANGE_RATE_KEY.HsEGLD &&
      secondSelected.source === TOKEN_SOURCE.collateral;

    return (
      (isFromSEgldWallet && isToHsEgldCollateral && hasMaxMarketPerAccount) ||
      (isFromHsEgldWallet && isToHsEgldCollateral && hasMaxMarketPerAccount)
    );
  }, [
    firstSelected.source,
    firstSelected.symbol,
    hasMaxMarketPerAccount,
    secondSelected.source,
    secondSelected.symbol,
  ]);

  const sendRebalance = useMemo(() => {
    const cond1 = isFromSEgldWallet && isToHsEgldCollateral;
    const cond2 = isFromHsEgldWallet && isToHsEgldCollateral;
    const cond3 = isFromHsEgldCollateral && isToHsEgldWallet;
    const cond4 = isFromHsEgldCollateral && isToSEgldWallet;

    return isLoggedIn && hasHtmStaked && (cond1 || cond2 || cond3 || cond4);
  }, [
    hasHtmStaked,
    isFromHsEgldCollateral,
    isFromHsEgldWallet,
    isFromSEgldWallet,
    isToHsEgldCollateral,
    isToHsEgldWallet,
    isLoggedIn,
    isToSEgldWallet,
  ]);

  useEffect(() => {
    if (sendRebalance) setShowAlert(true);
    else setShowAlert(false);
  }, [sendRebalance]);

  return (
    <>
      <div className='mx-auto mb-9 max-w-[246px] pb-4 text-center text-[#535367] dark:text-white'>
        {t('token-to-swap')}
        <div className='-mb-3' />
      </div>

      <div>
        <Label
          label=''
          valueText={t('total-available')}
          token={firstSelected.symbol}
          value={firstSelected.balance}
          valueUSD={firstSelected.balanceUSD}
          className='mb-1'
        />

        <div className='mt-1'>
          <MigrateInput
            key='input1'
            options={options1}
            decimalsLimit={firstSelected.decimals}
            onChange={(optionSelected: any) => {
              setSelectedNumbers((_) => [optionSelected.index, _[1]]);
            }}
            allowEdit
            mutableSource
            value={inputValue}
            valueUSD={inputValueUSD}
            onInputValueChange={handleChange}
            onMax={handleMax}
            hasHundredPercentButton={!hasEightyPercentButton}
          />

          <div className='-mt-2 mb-1 flex justify-center'>
            <SwitchBtn onClick={handleSwitch} />
          </div>

          <MigrateInput
            key='input1-replacement'
            mutableSource
            options={options2}
            onChange={(optionSelected: any) => {
              setSelectedNumbers((_) => [_[0], optionSelected.index]);
            }}
            value={receivedValue}
            valueUSD={receivedValueUSD}
            onInputValueChange={onChange}
          />
        </div>
      </div>

      {error && (
        <AmountLimitErrors
          {...error}
          operation={operation}
          token={firstSelected.symbol}
          onChange={handleChangeAmountLimit}
        />
      )}

      {notes.length && <Notes className='mb-9 mt-9' notes={notes} />}

      <AcceptDeal
        className='mb-9'
        iconSrc='https://cdn.app.hatom.com/images/liquidstaking/app/magic-hat.png'
        asMessage
        message={
          <div>
            <Trans
              i18nKey='migrate-note'
              components={[
                <span className='text-black dark:text-white' />,
                // eslint-disable-next-line @next/next/no-html-link-for-pages
                <a
                  href='/lend'
                  rel='noreferrer'
                  onClick={(e) => {
                    e.preventDefault();
                    router.push('/lend');
                  }}
                  className='text-[#006FFF] hover:underline'
                />,
              ]}
              ns='liquid-app'
            />
          </div>
        }
        checked
      />

      {isLoggedIn ? (
        <Hint
          content={
            hasPendingTransactions
              ? t('translation:wait-tx-inprogress')
              : !isWhiteListed()
              ? t('translation:tx-not-enable-to-sign')
              : hasMigrateMaxMarketPerAccount
              ? t('translation:limit-money-market-reached')
              : t('translation:tx-not-allowed')
          }
          placement='top-center'
          className='w-full'
          unvisible={
            !(
              hasPendingTransactions ||
              !isAllowedTransaction(ALLOWED_TRANSACTIONS.LIQUID_APP_MIGRATE) ||
              !isWhiteListed() ||
              hasMigrateMaxMarketPerAccount
            )
          }
        >
          <SubmitButton onClick={handleMigrate} disabled={isBtnDisabled}>
            {t('migrate')}
          </SubmitButton>
        </Hint>
      ) : (
        <SubmitButton secondary onClick={handleConnectWallet}>
          {t('translation:connect-wallet')}
        </SubmitButton>
      )}
      {sendRebalance && (
        <div className='GreenAlert mt-3 flex min-h-[34px] w-full items-center justify-center rounded-[6px] bg-[#BDF9DF] translate-y-1'>
          <div className='flex max-w-[320px] items-start gap-0.5 text-[12px] leading-[15px] text-[#3E9179]'>
            <img
              src='https://cdn.app.hatom.com/images/green-info.svg'
              alt='green-info'
              className='-translate-x-[1px] translate-y-[1.5px]'
            />
            <div className='flex w-full flex-col'>
              <span className='translate-x-[0.3px] text-center'>
                You'll need to sign two transactions to rebalance your
              </span>
              <span className='-translate-x-[6px] text-center'>
                position within the HTM Booster.
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
