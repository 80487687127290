import DefiUtils from 'defi-utils';
import { useEffect, useMemo } from 'react';

import { EXCHANGE_RATE_KEY } from '@/hooks/protocol/useExchangeRate';

import { useAppDispatch, useAppSelector } from '@/store/index';
import { Token, TOKEN_SOURCE } from '@/store/liquid-staking-app';
import { setLiquidStakingTaoApp } from '@/store/liquid-staking-tao-app';
import {
  H_TOKEN_DECIMALS,
  sWtaoMarketSelector,
  sWtaoUserBalanceSelector,
} from '@/store/protocol';

const useLiquidStakingTokens = () => {
  const dispatch = useAppDispatch();

  const sWtaoMarket = useAppSelector(sWtaoMarketSelector);
  const sWtaoUserBalance = useAppSelector(sWtaoUserBalanceSelector);

  const hsWtaoWallet = useMemo<Token>(() => {
    const symbol = EXCHANGE_RATE_KEY.HsWTAO;
    const source = TOKEN_SOURCE.wallet;
    const id = `${symbol}-${source}`;

    const priceUSD = new DefiUtils(1)
      .toBasicUnits(H_TOKEN_DECIMALS)
      .toUnderlying(sWtaoMarket.hTokenExchangeRate)
      .toFullDecimals(sWtaoMarket.underlying.decimals)
      .toUSD(sWtaoMarket.underlying.priceUSD)
      .toString();

    const balance = new DefiUtils(sWtaoUserBalance.hTokenBalance)
      .toFullDecimals(H_TOKEN_DECIMALS)
      .toString();

    return {
      id,
      symbol,
      source,
      balance,
      priceUSD,
      balanceUSD: new DefiUtils(balance).toUSD(priceUSD).toString(),
      decimals: H_TOKEN_DECIMALS,
      index: 1,
    };
  }, [
    sWtaoUserBalance.hTokenBalance,
    sWtaoMarket.hTokenExchangeRate,
    sWtaoMarket.underlying.priceUSD,
    sWtaoMarket.underlying.decimals,
  ]);

  const hsWtaoCollateral = useMemo<Token>(() => {
    const symbol = EXCHANGE_RATE_KEY.HsWTAO;
    const source = TOKEN_SOURCE.collateral;
    const id = `${symbol}-${source}`;
    const balance = new DefiUtils(sWtaoUserBalance.collateralBalance)
      .toFullDecimals(H_TOKEN_DECIMALS)
      .toString();
    const priceUSD = new DefiUtils(1)
      .toBasicUnits(H_TOKEN_DECIMALS)
      .toUnderlying(sWtaoMarket.hTokenExchangeRate)
      .toFullDecimals(sWtaoMarket.underlying.decimals)
      .toUSD(sWtaoMarket.underlying.priceUSD)
      .toString();

    return {
      id,
      symbol,
      source,
      balance,
      priceUSD,
      balanceUSD: new DefiUtils(balance).toUSD(priceUSD).toString(),
      decimals: H_TOKEN_DECIMALS,
      index: 2,
    };
  }, [
    sWtaoUserBalance.collateralBalance,
    sWtaoMarket.hTokenExchangeRate,
    sWtaoMarket.underlying.priceUSD,
    sWtaoMarket.underlying.decimals,
  ]);

  const sWtaoWallet = useMemo<Token>(() => {
    const balance = new DefiUtils(sWtaoUserBalance.underlyingBalance)
      .toFullDecimals(sWtaoMarket.underlying.decimals)
      .toString();

    const symbol = EXCHANGE_RATE_KEY.sWTAO;
    const source = TOKEN_SOURCE.wallet;

    return {
      id: `${symbol}-${source}`,
      symbol,
      source,
      balance,
      balanceUSD: new DefiUtils(balance)
        .toUSD(sWtaoMarket.underlying.priceUSD)
        .toString(),
      decimals: sWtaoMarket.underlying.decimals,
      priceUSD: sWtaoMarket.underlying.priceUSD,
      index: 0,
    };
  }, [
    sWtaoUserBalance.underlyingBalance,
    sWtaoMarket.underlying.priceUSD,
    sWtaoMarket.underlying.decimals,
  ]);

  useEffect(() => {
    const tokens = {
      [sWtaoWallet.id]: sWtaoWallet,
      [hsWtaoCollateral.id]: hsWtaoCollateral,
      [hsWtaoWallet.id]: hsWtaoWallet,
    } as Record<string, Token>;

    dispatch(setLiquidStakingTaoApp({ tokens }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hsWtaoCollateral, hsWtaoWallet, sWtaoWallet]);
};

export default useLiquidStakingTokens;
