import classNames from 'classnames';
import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

import useTheme from '@/hooks/useTheme';

import FilterIcon from '@/components/Icons/FilterIcon';

import { PROPOSAL_TAG } from '@/store/governance';

interface FilterButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  selectedFilterTag: string;
}

type SelectedIcon = {
  [key: string]: string;
};

const selectedIcon: SelectedIcon = {
  [PROPOSAL_TAG.liquidStaking]: 'https://cdn.app.hatom.com/images/governance/proposal/tags/liquid-staking.svg',
  [PROPOSAL_TAG.lendingProtocol]: 'https://cdn.app.hatom.com/images/governance/proposal/tags/lending-protocol.svg',
  [PROPOSAL_TAG.ushStaking]: 'https://cdn.app.hatom.com/images/governance/proposal/tags/ush-staking.svg',
  [PROPOSAL_TAG.isolatedPools]: 'https://cdn.app.hatom.com/images/governance/proposal/tags/isolated-pools.svg',
};

export const FilterButton = ({
  selectedFilterTag,
  className,
  ...props
}: FilterButtonProps) => {
  const { isDark } = useTheme();

  return (
    <button
      className={classNames(
        'flex',
        'items-center',
        'justify-center',
        'bg-[#e6e6f7]',
        'h-[32px]',
        'w-[64px]',
        'rounded-md',
        'gap-2.5',
        'cursor-pointer',
        'dark:bg-[#2E2C51]',
        className
      )}
      {...props}
    >
      <img
        className={classNames('w-[20px]', 'h-[20px]')}
        alt='Hatom'
        src={selectedIcon[selectedFilterTag] || 'https://cdn.app.hatom.com/images/see-all.svg'}
      />

      <FilterIcon fill={isDark ? '#FFFFFF' : '#404056'} />
    </button>
  );
};
