import DefiUtils from 'defi-utils';
import { useTranslation } from 'next-i18next';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { useLogin } from '@/hooks/auth/useLogin';
import useExchangeRate, {
  EXCHANGE_RATE_KEY,
} from '@/hooks/protocol/useExchangeRate';

import { WAD } from '@/data/constants';

import { boosterSelector } from '@/store/booster';
import { useAppSelector } from '@/store/index';
import { liquidStakingAppSelector } from '@/store/liquid-staking-app';
import {
  H_TOKEN_DECIMALS,
  htmMarketSelector,
  nativeMarketSelector,
  sEgldMarketSelector,
  sEgldUserBalanceSelector,
} from '@/store/protocol';
import { rewardBatchSelector } from '@/store/reward-batch';

export enum Operation {
  STAKE = 'stake',
  UNSTAKE = 'unstake',
  MIGRATE = 'migrate',
}

export const FormContext = createContext<any>({
  formsState: {},
  setFormsState: () => {},
});

export const useFormContext = () => {
  const context = useContext<any>(FormContext);

  const staking = () => context.formsState.operation == Operation.STAKE;
  const unstaking = () => context.formsState.operation == Operation.UNSTAKE;
  const migrating = () => context.formsState.operation == Operation.MIGRATE;

  return { ...context, staking, unstaking, migrating };
};

type FormContextProviderProps = {
  children: React.ReactNode;
};

export const FormContextProvider = ({ children }: FormContextProviderProps) => {
  const { t } = useTranslation('liquid-app');
  const { apy } = useAppSelector(liquidStakingAppSelector);
  const { markets: rewardMarkets } = useAppSelector(rewardBatchSelector);
  const { boosterAccount, hasStaked: hasHtmStaked } =
    useAppSelector(boosterSelector);
  const htmMarket = useAppSelector(htmMarketSelector);
  const nativeMarket = useAppSelector(nativeMarketSelector);
  const sEgldMarket = useAppSelector(sEgldMarketSelector);
  const sEgldUserBalance = useAppSelector(sEgldUserBalanceSelector);
  const { isLoggedIn } = useLogin();

  const [formsState, setFormsState] = useState({
    operation: Operation.STAKE,
    stakingInputs: {
      token: EXCHANGE_RATE_KEY.sEGLD,
      supplyHsEGLd: true,
      changeToken: (token: EXCHANGE_RATE_KEY) => {
        setFormsState((_) => ({
          ..._,
          stakingInputs: { ..._.stakingInputs, token },
        }));
      },
      check: () => {
        setFormsState((_) => ({
          ..._,
          stakingInputs: {
            ..._.stakingInputs,
            supplyHsEGLd: !_.stakingInputs.supplyHsEGLd,
          },
        }));
      },
      inputValue: 0,
      estimation: '0',
      annualEarnings: '0',
      onEstimation: (estimation: string) => {
        setFormsState((_) => ({
          ..._,
          stakingInputs: {
            ..._.stakingInputs,
            estimation,
          },
        }));
      },
      onAnnualEarnings: (annualEarnings: string) => {
        setFormsState((_) => ({
          ..._,
          stakingInputs: {
            ..._.stakingInputs,
            annualEarnings,
          },
        }));
      },
      onChange: (inputValue: number) => {
        inputValue = inputValue ?? 0;

        setFormsState((_) => ({
          ..._,
          stakingInputs: {
            ..._.stakingInputs,
            inputValue,
          },
        }));
      },
      reset: () => {
        setFormsState((_: any) => ({
          ..._,
          stakingInputs: {
            ..._.stakingInputs,
            inputValue: '0',
          },
        }));
      },
    },
    unstakingInputs: {
      token: EXCHANGE_RATE_KEY.sEGLD,
      estimation: '0',
      onEstimation: (estimation: string) => {
        setFormsState((_) => ({
          ..._,
          unstakingInputs: {
            ..._.unstakingInputs,
            estimation,
          },
        }));
      },
      changeToken: (token: EXCHANGE_RATE_KEY) => {
        setFormsState((_) => ({
          ..._,
          unstakingInputs: { ..._.unstakingInputs, token },
        }));
      },
      inputValue: 0,
      onChange: (inputValue: number) => {
        inputValue = inputValue ?? 0;
        setFormsState((_) => ({
          ..._,
          unstakingInputs: {
            ..._.unstakingInputs,
            inputValue,
          },
        }));
      },
      reset: () => {
        setFormsState((_: any) => ({
          ..._,
          unstakingInputs: {
            ..._.unstakingInputs,
            inputValue: '0',
          },
        }));
      },
    },
    migratingInputs: {
      inputValue: '0',
      onChange: (inputValue: string) => {
        inputValue = inputValue ?? '0';
        setFormsState((_) => ({
          ..._,
          migratingInputs: {
            ..._.migratingInputs,
            inputValue,
          },
        }));
      },
      supplyHsEGLd: true,
      check: () => {
        setFormsState((_) => ({
          ..._,
          migratingInputs: {
            ..._.migratingInputs,
            supplyHsEGLd: !_.migratingInputs.supplyHsEGLd,
          },
        }));
      },
      reset: () => {
        setFormsState((_) => ({
          ..._,
          migratingInputs: {
            ..._.migratingInputs,
            inputValue: '0',
          },
        }));
      },
    },
  });

  const segldToHsegld = useExchangeRate(
    EXCHANGE_RATE_KEY.sEGLD,
    EXCHANGE_RATE_KEY.HsEGLD,
  );

  const totalRewardsSupplyAPY = useMemo(
    () => rewardMarkets['SEGLD']?.totalRewardsSupplyAPY || '0',
    [rewardMarkets],
  );

  const userRewardsSupplyAPY = useMemo(
    () => rewardMarkets['SEGLD']?.userRewardsSupplyAPY || '0',
    [rewardMarkets],
  );

  const showMarketApy = useMemo(() => {
    const hsEgldBalance = new DefiUtils(sEgldUserBalance.underlyingBalance)
      .toFullDecimals(sEgldMarket.underlying.decimals)
      .multipliedBy(segldToHsegld);

    const hTokenBalance = new DefiUtils(
      sEgldUserBalance.hTokenBalance,
    ).toFullDecimals(H_TOKEN_DECIMALS);

    const collateralBalance = new DefiUtils(
      sEgldUserBalance.collateralBalance,
    ).toFullDecimals(H_TOKEN_DECIMALS);

    return !(
      hsEgldBalance.isZero() ||
      (hTokenBalance.isZero() && collateralBalance.isZero())
    );
  }, [
    sEgldUserBalance.underlyingBalance,
    sEgldUserBalance.hTokenBalance,
    sEgldUserBalance.collateralBalance,
    sEgldMarket.underlying.decimals,
    segldToHsegld,
  ]);

  const showRewardsApy = useMemo(() => {
    const hsEgldBalance = new DefiUtils(sEgldUserBalance.underlyingBalance)
      .toFullDecimals(sEgldMarket.underlying.decimals)
      .multipliedBy(segldToHsegld);
    const hTokenBalance = new DefiUtils(
      sEgldUserBalance.hTokenBalance,
    ).toFullDecimals(H_TOKEN_DECIMALS);

    const collateralBalance = new DefiUtils(
      sEgldUserBalance.collateralBalance,
    ).toFullDecimals(H_TOKEN_DECIMALS);

    return !(
      (hsEgldBalance.isZero() && hTokenBalance.isZero()) ||
      collateralBalance.isZero()
    );
  }, [
    sEgldMarket.underlying.decimals,
    sEgldUserBalance.collateralBalance,
    sEgldUserBalance.hTokenBalance,
    sEgldUserBalance.underlyingBalance,
    segldToHsegld,
  ]);

  const showBoosterApy = useMemo(() => {
    const hsEgldBalance = new DefiUtils(sEgldUserBalance.underlyingBalance)
      .toFullDecimals(sEgldMarket.underlying.decimals)
      .multipliedBy(segldToHsegld);
    const hTokenBalance = new DefiUtils(
      sEgldUserBalance.hTokenBalance,
    ).toFullDecimals(H_TOKEN_DECIMALS);

    const collateralBalance = new DefiUtils(sEgldUserBalance.collateralBalance);

    const collateralBalanceInUSD = collateralBalance
      .multipliedBy(sEgldMarket.hTokenExchangeRate)
      .dividedBy(WAD)
      .toUSD(sEgldMarket.underlying.priceUSD)
      .toFullDecimals(sEgldMarket.underlying?.decimals);

    const htmStakedSEgldInUsd = new DefiUtils(
      boosterAccount?.SEGLD?.accountMarketBooster?.staked || '0',
    )
      .toFullDecimals(htmMarket.underlying.decimals)
      .toUSD(htmMarket.underlying.priceUSD);

    const calcCollateralHtm = htmStakedSEgldInUsd
      .dividedBy(collateralBalanceInUSD)
      .dividedBy(0.1);

    if (calcCollateralHtm.isLessThan(1)) return true;
    /* aca_aca */
    return !(
      (hsEgldBalance.isZero() && hTokenBalance.isZero()) ||
      collateralBalance.isZero() ||
      !hasHtmStaked ||
      calcCollateralHtm.isLessThan(1)
    );
  }, [
    sEgldUserBalance.underlyingBalance,
    sEgldUserBalance.hTokenBalance,
    sEgldUserBalance.collateralBalance,
    sEgldMarket.underlying.decimals,
    sEgldMarket.underlying.priceUSD,
    sEgldMarket.hTokenExchangeRate,
    segldToHsegld,
    boosterAccount?.SEGLD?.accountMarketBooster?.staked,
    htmMarket.underlying.decimals,
    htmMarket.underlying.priceUSD,
    hasHtmStaked,
  ]);

  const percentages = useMemo(() => {
    const hasBalance = new DefiUtils(
      sEgldUserBalance.underlyingBalance,
    ).isGreaterThan('0');
    const hasCollateral = new DefiUtils(
      sEgldUserBalance.collateralBalance,
    ).isGreaterThan('0');
    const hasTokenWalletBalance = new DefiUtils(
      sEgldUserBalance.hTokenBalance,
    ).isGreaterThan('0');

    const hasLoginValidation =
      isLoggedIn && (hasBalance || hasCollateral || hasTokenWalletBalance);

    const option1 = {
      value: apy,
      color: '#E25F00',
      isActive: true,
    };

    const hsEgldBalance = new DefiUtils(sEgldUserBalance.underlyingBalance)
      .toFullDecimals(sEgldMarket.underlying.decimals)
      .multipliedBy(segldToHsegld)
      .toString();
    const hTokenBalance = new DefiUtils(sEgldUserBalance.hTokenBalance)
      .toFullDecimals(H_TOKEN_DECIMALS)
      .toString();
    const collateralBalance = new DefiUtils(sEgldUserBalance.collateralBalance)
      .toFullDecimals(H_TOKEN_DECIMALS)
      .toString();

    const supplyAPY = sEgldMarket.supplyAPY;

    const lendingAPY = new DefiUtils(
      new DefiUtils(hTokenBalance).plus(collateralBalance),
    )
      .multipliedBy(supplyAPY)
      .dividedBy(
        new DefiUtils(hsEgldBalance)
          .plus(hTokenBalance)
          .plus(collateralBalance),
      );

    const option2 = {
      value:
        new DefiUtils(hsEgldBalance)
          .plus(hTokenBalance)
          .plus(collateralBalance)
          .isZero() ||
        lendingAPY.isNaN() ||
        lendingAPY.isZero()
          ? supplyAPY
          : lendingAPY.toString(),
      label: t('lending'),
      color: '#8c80d4',
      isActive: hasLoginValidation
        ? hasTokenWalletBalance || hasCollateral
        : formsState.stakingInputs.token === EXCHANGE_RATE_KEY.HsEGLD,
    };

    const hasMarketInteraction = new DefiUtils(hsEgldBalance)
      .plus(hTokenBalance)
      .isZero();

    const option3 = {
      value:
        !hasMarketInteraction || new DefiUtils(userRewardsSupplyAPY).isZero()
          ? totalRewardsSupplyAPY
          : userRewardsSupplyAPY,
      label: t('rewards'),
      color: '#D6A4E9',
      isActive: hasLoginValidation
        ? hasCollateral
        : formsState.stakingInputs.token === EXCHANGE_RATE_KEY.HsEGLD &&
          formsState.stakingInputs.supplyHsEGLd,
    };

    const sEgldBoosterMarket = boosterAccount.SEGLD;

    const option4 = {
      value:
        !hasMarketInteraction ||
        new DefiUtils(sEgldBoosterMarket?.accountBoosterApy || '0').isZero()
          ? sEgldBoosterMarket?.totalBoosterApy || '0'
          : sEgldBoosterMarket?.accountBoosterApy || '0',
      label: 'Booster',
      color: '#E24949',
      isActive: hasLoginValidation
        ? hasCollateral
        : formsState.stakingInputs.token === EXCHANGE_RATE_KEY.HsEGLD &&
          formsState.stakingInputs.supplyHsEGLd,
    };

    return [option1, option2, option3, option4];
  }, [
    sEgldUserBalance.underlyingBalance,
    sEgldUserBalance.collateralBalance,
    sEgldUserBalance.hTokenBalance,
    isLoggedIn,
    apy,
    sEgldMarket.underlying.decimals,
    sEgldMarket.supplyAPY,
    segldToHsegld,
    t,
    formsState.stakingInputs.token,
    formsState.stakingInputs.supplyHsEGLd,
    userRewardsSupplyAPY,
    totalRewardsSupplyAPY,
    boosterAccount.SEGLD,
  ]);

  const userTotalAPY = useMemo(() => {
    const hsEgldBalance = new DefiUtils(sEgldUserBalance.underlyingBalance)
      .toFullDecimals(sEgldMarket.underlying.decimals)
      .multipliedBy(segldToHsegld)
      .toString();
    const hTokenBalance = new DefiUtils(sEgldUserBalance.hTokenBalance)
      .toFullDecimals(H_TOKEN_DECIMALS)
      .toString();
    const collateralBalance = new DefiUtils(sEgldUserBalance.collateralBalance)
      .toFullDecimals(H_TOKEN_DECIMALS)
      .toString();
    const supplyAPY = sEgldMarket.supplyAPY;

    const cond1 = new DefiUtils(hsEgldBalance).multipliedBy(apy);
    const cond2 = new DefiUtils(hTokenBalance).multipliedBy(
      new DefiUtils(apy).plus(supplyAPY),
    );
    const cond3 = new DefiUtils(collateralBalance).multipliedBy(
      new DefiUtils(apy)
        .plus(supplyAPY)
        .plus(totalRewardsSupplyAPY)
        .plus(boosterAccount?.SEGLD?.accountBoosterApy),
    );

    if (
      new DefiUtils(hsEgldBalance)
        .plus(hTokenBalance)
        .plus(collateralBalance)
        .isZero()
    ) {
      return apy;
    }

    const result = new DefiUtils(cond1)
      .plus(cond2)
      .plus(cond3)
      .dividedBy(
        new DefiUtils(hsEgldBalance)
          .plus(hTokenBalance)
          .plus(collateralBalance),
      );

    return result.toSafeString();
  }, [
    apy,
    boosterAccount?.SEGLD?.accountBoosterApy,
    totalRewardsSupplyAPY,
    sEgldMarket.supplyAPY,
    sEgldMarket.underlying.decimals,
    sEgldUserBalance.collateralBalance,
    sEgldUserBalance.hTokenBalance,
    sEgldUserBalance.underlyingBalance,
    segldToHsegld,
  ]);

  const totalAPY = useMemo(() => {
    const hasBalance = new DefiUtils(
      sEgldUserBalance.underlyingBalance,
    ).isGreaterThan('0');
    const hasCollateral = new DefiUtils(
      sEgldUserBalance.collateralBalance,
    ).isGreaterThan('0');
    const hasTokenWalletBalance = new DefiUtils(
      sEgldUserBalance.hTokenBalance,
    ).isGreaterThan('0');

    const hasLoginValidation =
      isLoggedIn && (hasBalance || hasCollateral || hasTokenWalletBalance);

    const percentagesActives = percentages.filter(
      ({ isActive }) => isActive,
    ).length;

    if (hasLoginValidation) {
      return userTotalAPY;
    }

    if (percentagesActives === 1) {
      return apy;
    }

    if (percentagesActives === 2) {
      return new DefiUtils(apy).plus(sEgldMarket.supplyAPY).toString();
    }

    if (
      !isLoggedIn &&
      formsState.operation === 'stake' &&
      formsState.stakingInputs.supplyHsEGLd &&
      formsState.stakingInputs.token === 'HSEGLD'
    ) {
      return new DefiUtils(apy)
        .plus(sEgldMarket.supplyAPY)
        .plus(totalRewardsSupplyAPY)
        .toString();
    }

    return new DefiUtils(apy)
      .plus(sEgldMarket.supplyAPY)
      .plus(totalRewardsSupplyAPY)
      .plus(boosterAccount.SEGLD?.totalBoosterApy || '0')
      .toString();
  }, [
    sEgldUserBalance.underlyingBalance,
    sEgldUserBalance.collateralBalance,
    sEgldUserBalance.hTokenBalance,
    isLoggedIn,
    percentages,
    formsState.operation,
    formsState.stakingInputs.supplyHsEGLd,
    formsState.stakingInputs.token,
    apy,
    sEgldMarket.supplyAPY,
    totalRewardsSupplyAPY,
    boosterAccount.SEGLD?.totalBoosterApy,
    userTotalAPY,
  ]);

  useEffect(() => {
    const estimation = new DefiUtils(formsState.stakingInputs.inputValue || 0)
      .toUSD(nativeMarket.underlying.priceUSD)
      .toString();

    const token = formsState.stakingInputs.token;
    const supplyHsEGLd = formsState.stakingInputs.supplyHsEGLd;

    let interactiveAPY = '0';

    if (token === EXCHANGE_RATE_KEY.sEGLD) {
      interactiveAPY = apy;
    } else if (token === EXCHANGE_RATE_KEY.HsEGLD && !supplyHsEGLd) {
      interactiveAPY = new DefiUtils(apy)
        .plus(sEgldMarket.supplyAPY)
        .toString();
    } else if (token === EXCHANGE_RATE_KEY.HsEGLD && supplyHsEGLd) {
      interactiveAPY = new DefiUtils(apy)
        .plus(sEgldMarket.supplyAPY)
        .plus(totalRewardsSupplyAPY)
        .toString();
    }

    const annualEarnings = new DefiUtils(estimation)
      .multipliedBy(interactiveAPY)
      .dividedBy(100)
      .toString();

    formsState.stakingInputs.onEstimation(estimation);
    formsState.stakingInputs.onAnnualEarnings(annualEarnings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formsState.stakingInputs.token,
    formsState.stakingInputs.supplyHsEGLd,
    formsState.stakingInputs.inputValue,
    nativeMarket.underlying.priceUSD,
    apy,
    sEgldMarket.supplyAPY,
    totalRewardsSupplyAPY,
  ]);

  useEffect(() => {
    switch (formsState.unstakingInputs.token) {
      case EXCHANGE_RATE_KEY.sEGLD: {
        const estimation = new DefiUtils(
          formsState.unstakingInputs.inputValue || 0,
        )
          .toUSD(sEgldMarket.hToken.priceUSD)
          .toString();

        formsState.unstakingInputs.onEstimation(estimation);
        break;
      }

      default: {
        formsState.unstakingInputs.onEstimation('0');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formsState.unstakingInputs.inputValue,
    formsState.unstakingInputs.token,
    nativeMarket.underlying.priceUSD,
    nativeMarket.hToken.priceUSD,
    totalAPY,
  ]);

  return (
    <FormContext.Provider
      value={{
        formsState,
        setFormsState,
        totalAPY,
        percentages,
        rewardsTokensAPY: totalRewardsSupplyAPY,
        boosterRewardsAPY: boosterAccount.SEGLD?.totalBoosterApy || '0',
        userRewardsSupplyAPY,
        showMarketApy,
        showRewardsApy,
        showBoosterApy,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
