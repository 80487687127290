import { SVGProps } from 'react';

const Ledger = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={32}
      height='100%'
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
      fill='#3555F7'
      {...props}
    >
      <path d='M23 24H32V27.6579C32 30.056 30.056 32 27.6579 32H23V24Z' />
      <path d='M0 24H7.26316V32H4.34C1.94309 32 0 30.0569 0 27.66V24Z' />
      <path d='M12 24H19.2632V32H12V24Z' />
      <path d='M0 4.3421C0 1.94403 1.94403 0 4.3421 0H7.26316V9H0V4.3421Z' />
      <path d='M0 13H7.26316V20H0V13Z' />
      <path
        d='M12.5 0H27.6579C30.056 0 32 1.94403 32 4.3421V20H12.5V0Z'
        fill='black'
      />
    </svg>
  );
};

export default Ledger;
