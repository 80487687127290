import React, { createContext, useEffect, useState } from 'react';

import useIsomorphicLayoutEffect from '@/hooks/useIsomorphicLayoutEffect';

import Loader from '@/components/Loader';

import { useAppDispatch, useAppSelector } from '@/store/index';
import { isLoadingSelector, setIsLoading } from '@/store/loader';

import { isProd, loaderEnabled } from '@/config/envVars';

export const LoaderContext = createContext({
  mark: (extra?: string) => {},
  show: () => {},
  open: loaderEnabled,
});

const DISABLE_LOADER = process.env.NEXT_PUBLIC_DISABLE_LOADER == 'true';

const DEBUG = false && !isProd;

const LoaderContextProvider: React.FC<any> = (props) => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isLoadingSelector);
  const [open, changeState] = useState(true);
  const INITIAL_TICK = 0;
  const [tc, augment] = useState(INITIAL_TICK);

  useEffect(() => {
    const pageTotalResources =
      document.querySelectorAll('.loading-resource').length;

    if (tc >= pageTotalResources) hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tc]);

  // eslint-disable-next-line unused-imports/no-unused-vars
  const mark = (label = '<unknown>') => {
    if (DISABLE_LOADER) return;
    augment((_) => ++_);
  };

  const [iRefs, storeRefs] = useState([]);

  useIsomorphicLayoutEffect(() => {
    augment(INITIAL_TICK);
  }, [isLoading]);

  const loaderGuard = () => {
    let c = 0;

    const ref = setInterval(() => {
      const mainEl = document.querySelector('main');

      if ((mainEl?.children?.length || 0) > 0) {
        dispatch(setIsLoading(false));
        changeState(false);
        clearInterval(ref);
        return;
      }

      if (c === 20) {
        location.reload();
      }

      c++;
    }, 1000);
  };

  const show = () => {
    if (DISABLE_LOADER) {
      return;
    }

    loaderGuard();
  };

  const hide = () => {
    setTimeout(() => {
      dispatch(setIsLoading(false));
    }, 500);
    changeState(false);
    iRefs.forEach((r) => clearInterval(r));
    storeRefs([]);
  };

  return (
    <LoaderContext.Provider value={{ mark, show, open }}>
      {loaderEnabled && <Loader />}

      {props.children}
    </LoaderContext.Provider>
  );
};
export default LoaderContextProvider;
