const CopyIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={24}
      fill='currentColor'
      viewBox='0 0 20 24'
      {...props}
    >
      <g clipPath='url(#clip0_1499_13927)'>
        <path d='M11.923 20.192c-6.956 0-8.076-2.979-8.076-10.096C3.847 2.978 4.967 0 11.923 0S20 3.029 20 10.096s-1.12 10.096-8.077 10.096zm0-18.173c-5.048 0-6.057 1.282-6.057 8.077 0 6.794 1.01 8.077 6.057 8.077 5.048 0 6.058-1.282 6.058-8.077s-.96-8.077-6.058-8.077z' />
        <path d='M18.788 7.067h-4.845a1.01 1.01 0 01-1.01-1.01V1.122a1.01 1.01 0 112.019 0v3.927h3.836a1.01 1.01 0 010 2.02zM14.952 11.105H8.894a1.01 1.01 0 010-2.019h6.058a1.01 1.01 0 110 2.02zM11.923 14.134H8.894a1.01 1.01 0 010-2.019h3.03a1.01 1.01 0 010 2.02z' />
        <path d='M8.077 23.22C1.12 23.22 0 20.243 0 13.126v-1.646c0-4.735-.06-7.865 4.735-8.45a1.01 1.01 0 011.13.878 1.01 1.01 0 01-.888 1.141C2.08 5.391 2.02 6.461 2.02 11.469v1.656c0 6.794 1.01 8.076 6.058 8.076 2.544 0 4.038-.403 4.836-1.342a2.767 2.767 0 010-.818 1.016 1.016 0 012.019.232v.08a1.18 1.18 0 01-.212 1.384c-1.13 1.747-3.13 2.484-6.643 2.484z' />
      </g>
      <defs>
        <clipPath id='clip0_1499_13927'>
          <path fill='#fff' d='M0 0H20V23.221H0z' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CopyIcon;
