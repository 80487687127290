import DefiUtils from 'defi-utils';

import { WAD, WAD_WAD } from '@/data/constants';

import {
    AccountBoosterReward as BoosterAccountBoosterReward,
    TTotalCollateralTokens,
} from '@/store/booster';
import {
    MARKET_KEY,
    Market,
    TOKEN_LOGO_MAP,
    UserBalance,
} from '@/store/protocol';

import {
    AccountBoosterReward,
    AccountMarketBoosterData,
    BoosterRewardsBatch,
    MarketsBoosterData,
} from '@/services/blockchain/lens/types';
import {
    QueryBoosterAccount,
    ResponseBoosters,
} from '@/services/indexer/booster/types';
import {
    getAccountBoosterAPY,
    getAccountBoosterAPYWithPenalty,
    getTotalBoosterAPY,
} from '@/utils/math/apy';
import { getBoosterPenalty } from '@/utils/math/booster';

export type ParsedBoosterAccount = Record<
  MARKET_KEY,
  {
    speed: string;
    amount: string;
    reward: {
      price: string;
      decimals: number;
      token: string;
      symbol: string;
    };
    marketBooster: {
      priceIntegral: string;
      priceIntegralTimestamp: number;
    };
    accountMarketBooster: {
      staked: string;
      priceIntegral: string;
      priceIntegralTimestamp: number;
    };
    rewards: string;
    totalBoosterApy: string;
    accountBoosterApy: string;
    accountBoosterAPYWithPenalty: string;
    maxAccountBoosterApy: string;
    hasCollateral: boolean;
    percentageBoost: string;
  }
>;

export const parseBoosterAccount = (
  boosters: ResponseBoosters[],
  boosterAccount: QueryBoosterAccount['getBoosterAccount'],
  rewardsPrices: Record<string, string>,
  userBalances: Record<MARKET_KEY, UserBalance>,
  totalCollateralTokensTemp: TTotalCollateralTokens,
  markets: Record<MARKET_KEY, Market>,
  maximumApyBooster: string,
) => {
  let resp = {} as ParsedBoosterAccount;

  boosters.forEach(
    ({
      rewardsToken,
      marketBooster: { moneyMarket, priceIntegral, priceIntegralTimestamp },
      speed,
    }) => {
      const rewardPrice = rewardsPrices[rewardsToken.id] || '0';

      const protocolMarket =
        markets[moneyMarket.underlying.symbol as MARKET_KEY];
      const totalCollateralMarket =
        totalCollateralTokensTemp[moneyMarket.underlying.symbol as MARKET_KEY];

      const reward = {
        price: rewardPrice,
        decimals: rewardsToken?.decimals || 0,
        token: rewardsToken.id,
        symbol: rewardsToken?.symbol || '',
      };

      const totalBoosterPayload = {
        speed,
        hTokenExchangeRate: protocolMarket.hTokenExchangeRate,
        totalCollateral: totalCollateralMarket,
        marketPrice: protocolMarket.underlying.priceUSD,
        rewardsToken: reward,
        marketDecimals: protocolMarket.underlying.decimals,
      };
      const totalBoosterApy = getTotalBoosterAPY(totalBoosterPayload);

      const marketKey = moneyMarket.underlying.symbol as MARKET_KEY;
      const market = markets[marketKey];
      const collateralBalance = new DefiUtils(
        userBalances[marketKey].underlyingCollateralBalance,
      );
      const collateralBalanceUSD = collateralBalance
        .toUSD(market.underlying.priceUSD)
        .toFullDecimals(protocolMarket.underlying.decimals);

      const calcBoosted = new DefiUtils(totalBoosterApy).multipliedBy(
        collateralBalanceUSD,
      );

      const hTokenSum = new DefiUtils(userBalances[marketKey]?.hTokenBalance)
        .toUSD(market.underlying?.priceUSD)
        .multipliedBy(market.hTokenExchangeRate)
        .dividedBy(WAD)
        .toFullDecimals(market.underlying?.decimals)
        .plus(collateralBalanceUSD);

      const maxAccountBoostedApy = calcBoosted.dividedBy(hTokenSum);

      const accountCollateral = new DefiUtils(
        userBalances[marketKey as MARKET_KEY].collateralBalance || '0',
      );

      resp[marketKey] = {
        ...resp[marketKey],
        speed,
        reward,
        marketBooster: {
          priceIntegral,
          priceIntegralTimestamp: new Date(priceIntegralTimestamp).getTime(),
        },
        amount: '0',
        rewards: '0',
        accountBoosterApy: '0',
        accountBoosterAPYWithPenalty: '0',
        accountMarketBooster: {
          priceIntegral: '0',
          priceIntegralTimestamp: new Date().getTime(),
          staked: '0',
        },
        totalBoosterApy,
        maxAccountBoosterApy: maxAccountBoostedApy.toSafeString(),
        hasCollateral: accountCollateral.isGreaterThan(0),
        percentageBoost: '0',
      };
    },
  );

  boosterAccount?.marketBoosters.forEach(
    ({ moneyMarket, staked, priceIntegral, priceIntegralTimestamp }) => {
      const market = moneyMarket.underlying.symbol;

      if (!resp[market]?.reward) {
        return;
      }

      resp[market] = {
        ...resp[market],
        accountMarketBooster: {
          priceIntegral,
          priceIntegralTimestamp: new Date(priceIntegralTimestamp).getTime(),
          staked,
        },
      };
    },
  );

  boosterAccount?.boostedRewardsBatches.forEach(
    ({ boostedRewardsBatchState, amount, index }) => {
      const marketBooster = boostedRewardsBatchState.marketBooster;
      const market = marketBooster.moneyMarket;
      const moneyMarket = market.underlying.symbol;
      const htmMarket = markets.HTM;

      const speed = resp[moneyMarket].speed;
      const reward = resp[moneyMarket].reward;

      const accountCollateral = new DefiUtils(
        userBalances[moneyMarket as MARKET_KEY].collateralBalance,
      );

      const amountFullDecimals = new DefiUtils(amount).toFullDecimals(
        reward?.decimals || 18,
      );

      const currentDate = new Date();
      const currentDateInSeconds = currentDate.getTime() / 1000;
      const previousTimestampInSeconds =
        resp[moneyMarket].marketBooster.priceIntegralTimestamp / 1000;

      const timeCalc = new DefiUtils(currentDateInSeconds).minus(
        previousTimestampInSeconds,
      );

      const totalCollateralMarket =
        totalCollateralTokensTemp[moneyMarket as MARKET_KEY];

      const indexSimulated = new DefiUtils(speed)
        .multipliedBy(timeCalc)
        .multipliedBy(WAD)
        .dividedBy(totalCollateralMarket)
        .toSafeString();

      let indexCalc = new DefiUtils(boostedRewardsBatchState.index)
        .minus(index)
        .plus(indexSimulated);

      indexCalc = indexCalc.multipliedBy(accountCollateral).dividedBy(WAD_WAD);

      const collateralPenalty = new DefiUtils(
        userBalances[moneyMarket as MARKET_KEY].collateralBalance,
      );
      const boosterPenalty = getBoosterPenalty({
        boosterAccount: resp,
        accountCollateralHToken: collateralPenalty.toString(),
        assetKey: moneyMarket,
      });
      const boosterPenaltyParsed = boosterPenalty
        .dividedBy(WAD)
        .dividedBy(maximumApyBooster);

      const boosterPenaltyCalc = boosterPenaltyParsed.isGreaterThan(1)
        ? 1
        : boosterPenalty.dividedBy(WAD).dividedBy(maximumApyBooster);

      const rewards = new DefiUtils(boosterPenaltyCalc)
        .multipliedBy(indexCalc.toFullDecimals(reward.decimals))
        .plus(amountFullDecimals)
        .removeScientificNotation();

      const protocolMarket = markets[moneyMarket as MARKET_KEY];

      const accountBoosterPayload = {
        speed: speed,
        hTokenExchangeRate: protocolMarket.hTokenExchangeRate,
        totalCollateral: totalCollateralMarket,
        marketPrice: protocolMarket.underlying.priceUSD,
        rewardsToken: resp[moneyMarket]?.reward,
        marketDecimals: protocolMarket.underlying.decimals,
        htmStakedUSD: new DefiUtils(
          resp[moneyMarket]?.accountMarketBooster.staked,
        )
          .toFullDecimals(htmMarket.underlying.decimals)
          .toUSD(htmMarket.underlying.priceUSD)
          .toString(),
        moneyMarketCollateralUSD: new DefiUtils(
          userBalances[moneyMarket as MARKET_KEY]?.underlyingCollateralBalance,
        )
          .toFullDecimals(protocolMarket.underlying.decimals)
          .toUSD(protocolMarket.underlying.priceUSD)
          .toString(),
        hTokenBalance: userBalances[moneyMarket as MARKET_KEY]?.hTokenBalance,
        maximumApyBooster,
      };

      const accountBoosterApy = getAccountBoosterAPY(accountBoosterPayload);

      const accountBoosterAPYWithPenalty = getAccountBoosterAPYWithPenalty({
        speed,
        hTokenExchangeRate: protocolMarket.hTokenExchangeRate,
        totalCollateral: totalCollateralMarket,
        marketPrice: protocolMarket.underlying.priceUSD,
        rewardsToken: resp[moneyMarket]?.reward,
        marketDecimals: protocolMarket.underlying.decimals,
        htmStakedUSD: new DefiUtils(
          resp[moneyMarket].accountMarketBooster.staked,
        )
          .toFullDecimals(htmMarket.underlying.decimals)
          .toUSD(htmMarket.underlying.priceUSD)
          .toString(),
        moneyMarketCollateralUSD: new DefiUtils(
          userBalances[moneyMarket as MARKET_KEY].underlyingCollateralBalance,
        )
          .toFullDecimals(protocolMarket.underlying.decimals)
          .toUSD(protocolMarket.underlying.priceUSD)
          .toString(),
        maximumApyBooster,
      });

      const percentageBoost = new DefiUtils(accountBoosterApy)
        .multipliedBy(100)
        .dividedBy(resp[moneyMarket].maxAccountBoosterApy || 0)
        .toSafeString();

      resp[moneyMarket] = {
        ...resp[moneyMarket],
        amount,
        accountBoosterApy,
        accountBoosterAPYWithPenalty,
        percentageBoost,
        rewards,
      };
    },
  );

  return resp;
};

export const calcNeedRebalance = ({
  maximumApyBooster,
  totalCollateralUSD,
  totalStakedUsd,
  boosterAccount,
  userBalances,
  htmMarket,
  markets,
}: {
  maximumApyBooster: string;
  totalCollateralUSD: string;
  totalStakedUsd: string;
  boosterAccount: ParsedBoosterAccount;
  userBalances: Record<MARKET_KEY, UserBalance>;
  htmMarket: Market;
  markets: Record<MARKET_KEY, Market>;
}) => {
  const percentageBoost = new DefiUtils(totalStakedUsd)
    .dividedBy(totalCollateralUSD)
    .dividedBy(maximumApyBooster);

  const marketsWithCollateral = Object.entries(boosterAccount).filter(
    ([key]) => {
      const userCollateral =
        userBalances[key as MARKET_KEY]?.collateralBalance || '0';

      return new DefiUtils(userCollateral).isGreaterThan(0);
    },
  );

  const hasOptimalBoost = percentageBoost.isGreaterThanOrEqualTo(1);

  const needRebalanceInOneMarket = marketsWithCollateral.some(
    ([key, { accountMarketBooster }]) => {
      const userStakedUSD = new DefiUtils(accountMarketBooster?.staked)
        .toFullDecimals(htmMarket.underlying.decimals)
        .toUSD(htmMarket.underlying.priceUSD);

      if (userStakedUSD.isZero()) {
        return true;
      }

      const userCollateralUSD = new DefiUtils(
        userBalances[key as MARKET_KEY]?.collateralBalance,
      )
        .toUnderlying(markets[key as MARKET_KEY]?.hTokenExchangeRate)
        .toFullDecimals(markets[key as MARKET_KEY]?.underlying.decimals)
        .toUSD(markets[key as MARKET_KEY]?.underlying.priceUSD);

      const marketPercentageBoost = new DefiUtils(userStakedUSD)
        .dividedBy(userCollateralUSD)
        .dividedBy(maximumApyBooster);

      const hasMarketOptimalBoost =
        marketPercentageBoost.isGreaterThanOrEqualTo(1);

      return !hasMarketOptimalBoost;
    },
  );

  return hasOptimalBoost && needRebalanceInOneMarket;
};

export const calcTotalCollateralUSD = ({
  markets,
  userBalances,
}: {
  markets: Record<MARKET_KEY, Market>;
  userBalances: Record<MARKET_KEY, UserBalance>;
}) => {
  return Object.values(markets)
    .reduce((prev, current) => {
      const collateralMM =
        userBalances[current.underlying.symbol as MARKET_KEY]
          ?.collateralBalance;

      const calc = new DefiUtils(collateralMM)
        .toUnderlying(current.hTokenExchangeRate)
        .toUSD(current.underlying.priceUSD)
        .toFullDecimals(current.underlying.decimals);

      return prev.plus(calc);
    }, new DefiUtils(0))
    .toString();
};

export const formatAccountRewardsWithoutIndexer = ({
  accountBoosterRewards,
  rewardsMap,
  rewardsPrices,
}: {
  accountBoosterRewards: AccountBoosterReward[];
  rewardsMap: Record<string, Market['underlying']>;
  rewardsPrices: Record<string, string>;
}): BoosterAccountBoosterReward[] => {
  return accountBoosterRewards.map(({ tokenId, rewards }) => {
    const priceUSD = rewardsPrices[tokenId] || '0';

    const { decimals = 18, symbol = '', name = '' } = rewardsMap[tokenId] || {};

    const userRewardBalanceAsBigNumber = rewards;

    const userRewardBalance = new DefiUtils(userRewardBalanceAsBigNumber)
      .toFullDecimals(decimals)
      .removeScientificNotation();

    const userRewardBalanceUSD = new DefiUtils(userRewardBalance)
      .toUSD(priceUSD)
      .removeScientificNotation();

    return {
      tokenId,
      userRewardBalance,
      userRewardBalanceUSD,
      symbol,
      priceUSD,
      logo: TOKEN_LOGO_MAP[symbol]?.normal || 'https://cdn.app.hatom.com/images/coin_placeholder.png',
      name,
      decimals,
    };
  });
};

export const formatAccountRewards = ({
  boosterAccount,
}: {
  boosterAccount: ParsedBoosterAccount;
}): BoosterAccountBoosterReward[] => {
  const accountBoosterRewardsMap = Object.values(boosterAccount).reduce(
    (prev, { reward, rewards }) => {
      const key = reward.token;

      return {
        ...prev,
        [key]: {
          rewards: new DefiUtils(prev[key]?.rewards || '0')
            .plus(rewards)
            .toString(),
          reward,
        },
      };
    },
    {} as Record<
      string,
      {
        rewards: string;
        reward: {
          price: string;
          decimals: number;
          token: string;
          symbol: string;
        };
      }
    >,
  );

  return Object.values(accountBoosterRewardsMap).map(({ reward, rewards }) => {
    const userRewardBalance = new DefiUtils(rewards).removeScientificNotation();

    const userRewardBalanceUSD = new DefiUtils(userRewardBalance)
      .toUSD(reward.price)
      .removeScientificNotation();

    return {
      tokenId: reward.token,
      userRewardBalance,
      userRewardBalanceUSD,
      symbol: reward.symbol,
      priceUSD: reward.price,
      logo:
        TOKEN_LOGO_MAP[reward.symbol]?.normal || 'https://cdn.app.hatom.com/images/coin_placeholder.png',
      name: reward.symbol,
      decimals: reward.decimals,
    };
  });
};

export const formatBoosterAccount = ({
  marketsBoosterData,
  marketsMap,
  rewardsMap,
  boosterRewardsBatchesMap,
  accountMarketsBoosterDataMap,
  rewardsPrices,
  htmMarket,
  userBalances,
  maximumApyBooster,
}: {
  marketsBoosterData: MarketsBoosterData[];
  marketsMap: Record<string, Market>;
  rewardsMap: Record<string, Market['underlying']>;
  boosterRewardsBatchesMap: Record<string, BoosterRewardsBatch>;
  accountMarketsBoosterDataMap: Record<string, AccountMarketBoosterData>;
  rewardsPrices: Record<string, string>;
  htmMarket: Market;
  userBalances: Record<MARKET_KEY, UserBalance>;
  maximumApyBooster: string;
}) => {
  const currentTime = new Date().getTime();

  return marketsBoosterData.reduce(
    (
      prev,
      {
        market,
        priceIntegral,
        priceIntegralTimestamp,
        storedTotalCollateralTokens,
      },
    ) => {
      const marketItem = marketsMap[market];

      if (!marketItem) {
        return prev;
      }

      const boosterRewardsBatchItem = boosterRewardsBatchesMap[market];
      const rewardItem = rewardsMap[boosterRewardsBatchItem?.tokenId];
      const accountMarketsBoosterItem = accountMarketsBoosterDataMap[market];

      const speed =
        !boosterRewardsBatchItem?.endTime ||
        currentTime > new Date(boosterRewardsBatchItem?.endTime).getTime()
          ? '0'
          : boosterRewardsBatchItem?.speed;

      const reward = {
        decimals: rewardItem?.decimals || 18,
        price: rewardsPrices[rewardItem?.id] || '0',
        symbol: rewardItem?.symbol || '-',
        token: rewardItem?.id || '-',
      };

      const accountMarketBooster = {
        priceIntegral: accountMarketsBoosterItem?.priceIntegral || '0',
        priceIntegralTimestamp:
          accountMarketsBoosterItem?.priceIntegralTimestamp ||
          new Date().toISOString(),
        staked: accountMarketsBoosterItem?.stake || '0',
      };

      const accountBoosterAPYWithPenalty = getAccountBoosterAPYWithPenalty({
        speed,
        hTokenExchangeRate: marketItem.hTokenExchangeRate || '0',
        totalCollateral: storedTotalCollateralTokens,
        marketPrice: marketItem.underlying.priceUSD || '0',
        rewardsToken: reward,
        marketDecimals: marketItem.underlying.decimals || 1,
        htmStakedUSD: new DefiUtils(accountMarketBooster.staked)
          .toFullDecimals(htmMarket.underlying.decimals)
          .toUSD(htmMarket.underlying.priceUSD)
          .toString(),
        moneyMarketCollateralUSD: new DefiUtils(
          userBalances[(marketItem.underlying.symbol || '') as MARKET_KEY]
            ?.underlyingCollateralBalance || '0',
        )
          .toFullDecimals(marketItem.underlying.decimals || 1)
          .toUSD(marketItem.underlying.priceUSD || '0')
          .toString(),
        maximumApyBooster,
      });

      const accountBoosterApy = getAccountBoosterAPY({
        speed: speed,
        hTokenExchangeRate: marketItem.hTokenExchangeRate || '0',
        totalCollateral: storedTotalCollateralTokens,
        marketPrice: marketItem.underlying.priceUSD || '0',
        rewardsToken: reward,
        marketDecimals: marketItem.underlying.decimals || 1,
        htmStakedUSD: new DefiUtils(accountMarketBooster.staked)
          .toFullDecimals(htmMarket.underlying.decimals)
          .toUSD(htmMarket.underlying.priceUSD)
          .toString(),
        moneyMarketCollateralUSD: new DefiUtils(
          userBalances[(marketItem.underlying.symbol || '') as MARKET_KEY]
            ?.underlyingCollateralBalance,
        )
          .toFullDecimals(marketItem.underlying.decimals || 1)
          .toUSD(marketItem.underlying.priceUSD || '0')
          .toString(),
        hTokenBalance:
          userBalances[(marketItem.underlying.symbol || '') as MARKET_KEY]
            ?.hTokenBalance,
        maximumApyBooster,
      });

      const totalBoosterApy = getTotalBoosterAPY({
        speed,
        hTokenExchangeRate: marketItem.hTokenExchangeRate || '0',
        totalCollateral: storedTotalCollateralTokens,
        marketPrice: marketItem.underlying.priceUSD || '0',
        rewardsToken: reward,
        marketDecimals: marketItem.underlying.decimals || 1,
      });

      const collateralBalance = new DefiUtils(
        userBalances[(marketItem.underlying.symbol || '') as MARKET_KEY]
          ?.underlyingCollateralBalance,
      );
      const collateralBalanceUSD = collateralBalance
        .toUSD(marketItem.underlying.priceUSD || '0')
        .toFullDecimals(marketItem.underlying.decimals || 1);

      const calcBoosted = new DefiUtils(totalBoosterApy).multipliedBy(
        collateralBalanceUSD,
      );

      const hTokenSum = new DefiUtils(
        userBalances[(marketItem.underlying.symbol || '') as MARKET_KEY]
          ?.hTokenBalance,
      )
        .toUSD(marketItem.underlying?.priceUSD || '0')
        .multipliedBy(marketItem.hTokenExchangeRate || '0')
        .dividedBy(WAD)
        .toFullDecimals(marketItem.underlying?.decimals || 1)
        .plus(collateralBalanceUSD);

      const maxAccountBoosterApy = calcBoosted
        .dividedBy(hTokenSum)
        .toSafeString();

      const accountCollateral = new DefiUtils(
        userBalances[(marketItem.underlying.symbol || '') as MARKET_KEY]
          ?.collateralBalance || '0',
      );
      const hasCollateral = accountCollateral.isGreaterThan(0);

      const percentageBoost = new DefiUtils(accountBoosterApy)
        .multipliedBy(100)
        .dividedBy(maxAccountBoosterApy)
        .toSafeString();

      return {
        ...prev,
        [marketItem.underlying.symbol]: {
          speed,
          reward,
          marketBooster: {
            priceIntegral,
            priceIntegralTimestamp,
          },
          accountMarketBooster,
          accountBoosterAPYWithPenalty,
          accountBoosterApy,
          totalBoosterApy,
          maxAccountBoosterApy,
          hasCollateral,
          percentageBoost,
        },
      };
    },
    {},
  ) as ParsedBoosterAccount;
};

export const getAcumulatorsPairsIds = (
  acumulators: {
    rewardsToken: {
      id: string;
      symbol: string;
    };
    swapPath: {
      id: string;
    }[];
    premium: string;
  }[],
) => {
  return acumulators
    .map(({ rewardsToken, swapPath }) => {
      const tokensIds = [rewardsToken.id, ...swapPath.map(({ id }) => id)];

      return tokensIds
        .slice(0, -1)
        .map((tokenId, index) => `${tokenId}/${tokensIds[index + 1]}`);
    })
    .flat();
};

export const formatAcumulators = ({
  acumulators,
  pairs,
}: {
  acumulators: {
    rewardsToken: {
      id: string;
      symbol: string;
    };
    swapPath: {
      id: string;
    }[];
    premium: string;
  }[];
  pairs: {
    pairsIds: string;
    exchangeRate: string;
  }[];
}) => {
  const pairsMap = pairs.reduce(
    (prev, { pairsIds, exchangeRate }) => ({
      ...prev,
      [pairsIds]: exchangeRate,
    }),
    {} as Record<string, string>,
  );

  const boosterBoosters = acumulators.reduce(
    (prev, { premium, rewardsToken, swapPath }) => {
      const tokensIds = [rewardsToken.id, ...swapPath.map(({ id }) => id)];
      const exchangeRates = tokensIds
        .slice(0, -1)
        .map(
          (tokenId, index) =>
            pairsMap[`${tokenId}/${tokensIds[index + 1]}`] || '0',
        );

      const exchangeRate = exchangeRates
        .reduce((prev, current) => prev.multipliedBy(current), new DefiUtils(1))
        .toString();

      return {
        ...prev,
        [rewardsToken.symbol]: {
          premium,
          exchangeRate,
        },
      };
    },
    {} as Record<string, { premium: string; exchangeRate: string }>,
  );

  return boosterBoosters;
};
