export const items = [
  {
    value: 200000,
    date: 'December 14th 2022',
    time: '12.00 PM',
    active: true,
  },
  {
    value: 150000,
    date: 'December 14th 2022',
    time: '08.00 PM',
  },
  {
    value: 340000,
    date: 'December 14th 2022',
    time: '10.00 PM',
  },
  {
    value: 250000,
    date: 'December 14th 2022',
    time: '02.00 PM',
  },
  {
    value: 250000,
    date: 'December 14th 2022',
    time: '02.00 PM',
  },
];
