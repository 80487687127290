import { useRef } from 'react';
import { isIOS } from 'react-device-detect';

const safeDocument = typeof document !== 'undefined' ? document : {};

const useScrollBlock = () => {
  const scrollBlocked = useRef<any>();
  const html = (safeDocument as Document).documentElement;
  const { body } = safeDocument as Document;

  const blockScroll = () => {
    if (!body || !body.style || scrollBlocked.current) return;

    const scrollBarWidth = window.innerWidth - html.clientWidth;
    const bodyPaddingRight =
      parseInt(
        window.getComputedStyle(body).getPropertyValue('padding-right')
      ) || 0;

    html.style.position = isIOS ? 'fixed' : 'relative';
    html.style.overflow = 'hidden';
    body.style.position = isIOS ? 'fixed' : 'relative';
    body.style.overflow = 'hidden';
    body.style.height = '100%';
    body.style.width = '100%';
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scrollBlocked.current = true;
  };

  const allowScroll = () => {
    if (!body || !body.style || !scrollBlocked.current) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.height = '';
    body.style.width = '';
    body.style.paddingRight = '';

    scrollBlocked.current = false;
  };

  return [blockScroll, allowScroll];
};

export default useScrollBlock;
