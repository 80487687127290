import { motion } from 'framer-motion'

import { walletItemsAnimationsTransition } from '@/components/popups/ConnectBridgeWalletPopup/components/wallets/constants';


const noGradientVariants = {
  normal: {
    fill: "#B6F5EE",
  },
  hover: {
    fill: "#D4FFFA",
  },
  transition: walletItemsAnimationsTransition
}

const withGradientVariants = (stopColor: string, hoverStopColor: string) => ({
  normal: {
    stopColor
  },
  hover: {
    stopColor: hoverStopColor
  },
  transition: walletItemsAnimationsTransition
})


function BridgeWalletShape1({ className, onClick, withGradient = false }: { className?: string, withGradient?: boolean, onClick: () => void }) {

  return (
    <svg className={className} width="146" height="160" viewBox="0 0 146 160" fill="none" xmlns="http://www.w3.org/2000/svg">
      {
        !withGradient
          ? (<motion.path
            onClick={onClick}
            variants={noGradientVariants}
            d="M0 20C0 8.9543 8.95431 0 20 0H63.5798C71.4441 0 78.5785 4.60885 81.8116 11.7778L82.6884 13.7222C85.9215 20.8912 93.0559 25.5 100.92 25.5H104.5C115.546 25.5 124.5 34.4543 124.5 45.5V74.4853C124.5 79.3298 126.258 84.0096 129.448 87.6554L140.801 100.63C149.911 111.042 145.952 127.337 133.08 132.408L67.0779 158.409C62.8217 160.085 58.1193 160.252 53.7549 158.882L14.0076 146.399C5.67169 143.781 0 136.055 0 127.318V20Z"
          />)
          : (<>
            <path
              onClick={onClick}
              d="M0 20C0 8.9543 8.95431 0 20 0H63.5798C71.4441 0 78.5785 4.60885 81.8116 11.7778L82.6884 13.7222C85.9215 20.8912 93.0559 25.5 100.92 25.5H104.5C115.546 25.5 124.5 34.4543 124.5 45.5V74.4853C124.5 79.3298 126.258 84.0096 129.448 87.6554L140.801 100.63C149.911 111.042 145.952 127.337 133.08 132.408L67.0779 158.409C62.8217 160.085 58.1193 160.252 53.7549 158.882L14.0076 146.399C5.67169 143.781 0 136.055 0 127.318V20Z"
              fill="url(#paint0_linear_1410_720)"
            />
            <defs>
              <linearGradient id="paint0_linear_1410_720" x1="79.75" y1="0" x2="79.75" y2="161" gradientUnits="userSpaceOnUse">
                <motion.stop
                  stopColor="#014EFD"
                  variants={withGradientVariants("#014EFD", "#467FFF")}
                />
                <motion.stop
                  variants={withGradientVariants("#48E2B0", "#6CFCCD")}
                  offset="1"
                />
              </linearGradient>
            </defs>
          </>)
      }
    </svg>
  );
}

export default BridgeWalletShape1;