export const createFetchWithTimeout =
  (timeout: number) => async (input: RequestInfo | URL, init?: RequestInit) => {
    if (init?.signal) {
      throw new Error(
        "it looks like graphql-request started using AbortSignal on its own. Please check graphql-request's recent updates",
      );
    }

    const controller = new AbortController();

    const timerId = setTimeout(() => {
      controller.abort();
    }, timeout);

    const data = await fetch(input, { ...init, signal: controller.signal });
    clearTimeout(timerId);
    return data;
  };
