export function ExclamationMarkIcon() {
  return (
    <svg
      width='12'
      height='11'
      viewBox='0 0 12 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.59975 0.685547H5.40135C3.07302 0.685547 1.18555 2.57302 1.18555 4.90135V6.09975C1.18555 8.42807 3.07302 10.3155 5.40135 10.3155H6.59975C8.92807 10.3155 10.8155 8.42807 10.8155 6.09975V4.90135C10.8155 2.57302 8.92807 0.685547 6.59975 0.685547Z'
        stroke='#D15140'
        strokeWidth='1.19'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.00098 3.36133V5.50133'
        stroke='#D15140'
        strokeWidth='1.19'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6.00082 8.17547C6.29629 8.17547 6.53582 7.93594 6.53582 7.64047C6.53582 7.345 6.29629 7.10547 6.00082 7.10547C5.70535 7.10547 5.46582 7.345 5.46582 7.64047C5.46582 7.93594 5.70535 8.17547 6.00082 8.17547Z'
        fill='#D15140'
      />
    </svg>
  );
}
