import { useAnimationControls } from 'framer-motion';
import { useContext, useEffect } from 'react';

import { isProd } from '@/config/envVars';
import { LoaderContext } from '@/contexts/LoaderContextProvider';

export const ONCE = isProd;

export const TRANSITION_VARIANTS = {
  hidden: (props: any) => {
    const defaultProps = {
      reversed: false,
      onlyX: false,
      onlyY: false,
      movePx: 24,
    };
    const {
      reversed = false,
      onlyX = false,
      onlyY = false,
      movePx = 24,
    } = props ?? defaultProps;
    if (onlyX && onlyY) throw new Error('means none animation will occurs');

    return {
      ...(onlyY ? {} : { x: reversed ? movePx : -movePx }),
      ...(onlyX ? {} : { y: movePx / 2 }),
      opacity: 0,
    };
  },
  visible: (props = {}) => {
    const { delay = 0 } = props as any;
    return {
      x: 0,
      y: 0,
      opacity: 1,
      transition: { duration: 0.8, ease: 'easeOut', delay: delay },
    };
  },
};

const useSetVariantOnLoadingEnd = (props = { timeout: 500 }) => {
  const { open } = useContext(LoaderContext);
  const controls = useAnimationControls();

  useEffect(() => {
    if (!open) setTimeout(() => controls.start('visible'), props.timeout);
    return controls.stop;
  }, [open]);

  return {
    controls,
  };
};

export default useSetVariantOnLoadingEnd;
