import classNames from 'classnames';
import { PropsWithChildren } from 'react';

export const Container = ({ children }: PropsWithChildren) => {
  return (
    <div
      className={classNames(
        'border-[2px]',
        'border-[#E7E7F9]',
        'py-[18px]',
        'px-[20px]',
        'rounded-[20px]',
        '[backdrop-filter:blur(2.5px)]',
        '[box-shadow:0px_6px_0px_#D3CFE2,6px_15px_14px_rgba(222,222,236,0.82)]',
        '[background:linear-gradient(75.08deg,#FFFFFF_58.82%,rgba(255,255,255,0.1)_84.93%,rgba(255,255,255,0)_97.88%)]',
        'dark:border-[#2E2C51]',
        'dark:[box-shadow:0px_6px_0px_#171430,_9px_16px_14px_#000000]',
        'dark:[background:linear-gradient(75.08deg,#171430_58.82%,rgba(23,20,48,0.4)_84.93%,rgba(23,20,48,0)_97.88%)]'
      )}
    >
      {children}
    </div>
  );
};
