import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import DefiUtils from 'defi-utils';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import {
  getTotalBorrowUSD,
  getTotalSupplyUSD,
} from '@/store/parsers/landing-parser';
import { htmMarketSelector, wtaoMarketSelector } from '@/store/protocol';

import logger from '@/utils/logger';

export interface LandingState {
  boosterTotalStakedUSD: string;
  totalSupplyUSD: string;
  totalBorrowUSD: string;
  totalValueLockedUSD: string;
}

const initialState: LandingState = {
  boosterTotalStakedUSD: '0',
  totalSupplyUSD: '0',
  totalBorrowUSD: '0',
  totalValueLockedUSD: '0',
};

export const landingSlice = createSlice({
  name: 'landing',
  initialState,
  reducers: {
    setLanding: (state, action: PayloadAction<Partial<LandingState>>) => {
      Object.entries(action.payload).map(([key, value]) => {
        state[key as keyof LandingState] = value;
      });
    },
  },
});

export const { setLanding } = landingSlice.actions;

export const getLandingInfo =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();
      const { markets, liquidStaking, liquidStakingTao, booster } =
        state.protocol;

      const htmMarket = htmMarketSelector(state);
      const wtaoMarket = wtaoMarketSelector(state);

      const totalBorrowUSD = getTotalBorrowUSD(markets);
      const totalSupplyUSD = getTotalSupplyUSD(markets);

      const boosterTotalStakedUSD = new DefiUtils(booster.totalStaked)
        .toFullDecimals(htmMarket.underlying.decimals)
        .toUSD(htmMarket.underlying.priceUSD)
        .toSafeString();

      const taoTokenSupplyUSD = new DefiUtils(liquidStakingTao.tokenSupply)
        .toFullDecimals(wtaoMarket.underlying.decimals)
        .toUSD(wtaoMarket.underlying.priceUSD)
        .toSafeString();

      const totalValueLockedUSD = new DefiUtils(totalSupplyUSD)
        .plus(liquidStaking.totalStakedUSD)
        .plus(liquidStakingTao.totalStakedUSD)
        .plus(taoTokenSupplyUSD)
        .plus(boosterTotalStakedUSD)
        .toString();

      dispatch(
        setLanding({
          boosterTotalStakedUSD,
          totalBorrowUSD,
          totalSupplyUSD,
          totalValueLockedUSD,
        }),
      );
    } catch (error) {
      logger.error('store:getLandingInfo', error?.toString());
    }
  };

export const landingSelector = (state: RootState) => state.landing;

export default landingSlice.reducer;
