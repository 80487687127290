import axios, { AxiosRequestConfig } from 'axios';

const proxy = (baseURL: string, config?: AxiosRequestConfig) => {
  return {
    post: (url: string, body?: any, headers?: any) => {
      return axios.request({
        baseURL: '/api/proxy',
        method: 'post',
        data: {
          url: `${baseURL}${url}`,
          data: body,
          method: 'post',
        },
        headers,
        ...config,
      });
    },
    get: (url: string, headers?: any) => {
      return axios.request({
        baseURL: '/api/proxy',
        method: 'post',
        data: {
          url: `${baseURL}/${url}`,
          method: 'get',
        },
        headers,
        ...config,
      });
    },
  };
};

export default proxy;
