import {
  Address,
  AddressValue,
  BigUIntValue,
  StringValue,
  U64Value,
} from '@multiversx/sdk-core';

import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';

import { useAppSelector } from '@/store/index';
import { htmMarketSelector, protocolSelector } from '@/store/protocol';

enum GOVERNANCE_METHOD {
  UPVOTE = 'upvote',
  DOWNVOTE = 'downvote',
  REDEEM = 'redeem',
  PROPOSE_TRANSFER_EXECUTE = 'proposeTransferExecute',
  PUBLISH_PROPOSAL = 'publishProposal',
}

const useGovernanceInteraction = () => {
  const {
    governance: { address: governanceAddress, voteNftId },
  } = useAppSelector(protocolSelector);
  const { buildTransaction } = useSignMultipleTransactions();

  const htmMarket = useAppSelector(htmMarketSelector);

  const proposeTransferExecute = () => {
    // address where is the endpoint to execute
    const toAddress = governanceAddress;

    const to = new AddressValue(new Address(toAddress));
    const egldAmount = new BigUIntValue(1_000);
    const gasLimit = new U64Value(14_000_000);
    const optEndpointName = new StringValue('poll');

    return buildTransaction({
      smartContractAddress: governanceAddress,
      func: GOVERNANCE_METHOD.PROPOSE_TRANSFER_EXECUTE,
      group: 'governance',
      isEsdtToken: true,
      isPayable: true,
      token: {
        tokenIdentifier: htmMarket.underlying.id,
        amount: '100',
        numDecimals: htmMarket.underlying.decimals,
      },
      args: [to, egldAmount, gasLimit, optEndpointName],
    });
  };

  const publishProposal = () => {
    const proposal_id = new U64Value('7');
    const opt_description = new StringValue(
      `Launching Hatom Modules on the Mainnet_____This proposal is about deciding whether to launch the Lending Protocol and Liquid Staking on the Mainnet between July and August. Both applications have been operating smoothly on the Devnet for months and have undergone extensive security audits, in addition to an upgraded App design.
The lending protocol has undergone thorough audits from renowned firms such as Runtime Verification, Hacken, Certik, Peckshield, Halborn, and Arda; and is currently being audited by ABDk Consulting. Sigma Prime will soon perform an audit as well. Similarly, the Liquid Staking module has been audited by Runtime Verification, CertiK, and Arda. (edited)`
    );

    return buildTransaction({
      smartContractAddress: governanceAddress,
      func: GOVERNANCE_METHOD.PUBLISH_PROPOSAL,
      group: 'governance',
      isPayable: false,
      args: [proposal_id, opt_description],
    });
  };

  const voteFor = ({
    amount,
    proposalId,
  }: {
    amount: string;
    proposalId: string;
  }) => {
    const {
      underlying: { id: underlyingId, decimals: underlyingDecimals },
    } = htmMarket;

    return buildTransaction({
      smartContractAddress: governanceAddress,
      func: GOVERNANCE_METHOD.UPVOTE,
      group: 'governance',
      isEsdtToken: true,
      isPayable: true,
      token: {
        tokenIdentifier: underlyingId,
        numDecimals: underlyingDecimals,
        amount,
      },
      args: [new BigUIntValue(proposalId)],
    });
  };

  const voteAgainst = ({
    amount,
    proposalId,
  }: {
    amount: string;
    proposalId: string;
  }) => {
    const {
      underlying: { id: underlyingId, decimals: underlyingDecimals },
    } = htmMarket;

    return buildTransaction({
      smartContractAddress: governanceAddress,
      func: GOVERNANCE_METHOD.DOWNVOTE,
      group: 'governance',
      isEsdtToken: true,
      isPayable: true,
      token: {
        tokenIdentifier: underlyingId,
        numDecimals: underlyingDecimals,
        amount,
      },
      args: [new BigUIntValue(proposalId)],
    });
  };

  const redeem = ({ nonce }: { nonce: number }) => {
    return buildTransaction({
      smartContractAddress: governanceAddress,
      func: GOVERNANCE_METHOD.REDEEM,
      group: 'governance',
      isEsdtToken: true,
      isPayable: true,
      nft: {
        tokenIdentifier: voteNftId,
        nonce,
      },
    });
  };

  return {
    voteFor,
    voteAgainst,
    redeem,
    proposeTransferExecute,
    publishProposal,
  };
};

export default useGovernanceInteraction;
