import { domAnimation, LazyMotion, m } from 'framer-motion';

import useTailwindBreakpoint from '@/hooks/useTailwindBreakpoint';

import WrappedLottiePlayer from '@/components/WrappedLottiePlayer';

import { TOKEN_LOGO_MAP } from '@/store/protocol';

import clsxm from '@/services/clsxm';

type Props = {
  text: string;
  HTMText: string;
  disabled?: boolean;
  onClick?: () => void;
  isWithBoost?: boolean;
};
const BonusButton = () => {
  return (
    <m.div
      className={clsxm(
        'self-stretch flex justify-center items-center border-[#4F8095]',
        'lg:max-h-[42px] lg:max-w-[78px] lg:w-[78px] lg:rounded-[8px] lg:border p-1.5 lg:-m-[1px]',
        'max-h-[32px] max-w-[62.8px] w-[63.5px] border-l-[0.9px] rounded-[6.21px]',
      )}
      style={{
        background: 'linear-gradient(90.57deg, #98EBD3 3.16%, #59FFB8 91.79%)',
      }}
    >
      <WrappedLottiePlayer
        autoplay
        loop
        src='https://cdn.app.hatom.com/animations/bonus2.json'
        className='w-full h-full'
      />
      {/* <img
        className='w-full h-full'
        src='https://cdn.app.hatom.com/images/pages/lend/bonus.svg'
        alt='bonus' /> */}
    </m.div>
  );
};

const ClaimRewardButton = (props: Props) => {
  const { text, HTMText, isWithBoost, onClick } = props;
  const isLargeScreen = useTailwindBreakpoint('lg');

  return (
    <LazyMotion features={domAnimation}>
      <m.button
        className={clsxm(
          'overflow-hidden',
          'flex',
          'items-center',
          'border-[#1A5CA4]',
          'lg:max-w-[169px]',
          'lg:w-[169px]',
          'lg:h-[42px]',
          'lg:max-h-[42px]',
          'lg:border',
          'lg:rounded-[8px]',
          'max-w-[123px]',
          'w-[123px]',
          'h-[32.6px]',
          'max-h-[32.6px]',
          'border-[0.78px]',
          'rounded-[6.21px]',
          'hover:saturate-150',
          isWithBoost && [
            'justify-between',
            'border-[#4F8095]',
            'lg:min-w-[206px]',
            'lg:gap-[12px]',
            'w-full',
            'w-[160px]',
            'max-w-[160px]',
            'gap-[9.31px]',
          ],
        )}
        style={{
          background: isWithBoost
            ? 'linear-gradient(89.95deg, #76B8BB 19.19%, #74CBB2 33.07%)'
            : 'linear-gradient(271.52deg, #2775CA 6%,#5AA1EE  70.67%)',
        }}
        onClick={onClick}
      >
        <m.div
          className={clsxm(
            'flex-grow flex items-center justify-center overflow-hidden border-[#1A5CA4] bg-[#73B6FF]',
            'lg:h-[42px] lg:py-[8px] lg:border lg:rounded-[8px]',
            'h-[32.6px] py-[6px]  border-r-[0.78px] rounded-[6.21px]',
            isWithBoost
              ? [
                  'bg-[#92D0D2] border-[#4F8095]',
                  'lg:min-w-[40px] lg:-m-[1px] ',
                  'min-w-[30.5px] ',
                ]
              : ['lg:max-w-[42px] lg:-m-[1px] ', 'max-w-[31.87px]'],
          )}
        >
          <img
            src={
              isWithBoost
                ? TOKEN_LOGO_MAP.HTM.normal
                : TOKEN_LOGO_MAP.USDC.normal
            }
            alt='USDC'
            className={clsxm(
              'lg:max-h-[24px] lg:max-w-[24px]',
              'max-h-[18.6px] max-w-[18.6px]',
            )}
          />
        </m.div>

        <div
          className={clsxm(
            'flex-1 font-[600] text-white',
            'lg:text-[14px] lg:leading-[17.5px]',
            'text-[10px] leading-[12.5px]',
            isWithBoost
              ? 'flex justify-center items-start flex-grow text-right lg:pr-[0.5px] lg:py-[4px] lg:pt-[0px] lg:mt-[-1px] '
              : 'text-center',
          )}
        >
          {isWithBoost ? (
            <div>
              <p className='flex items-center'>
                {isWithBoost && (
                  <img
                    width={isLargeScreen ? 7.5 : 4.75}
                    height={isLargeScreen ? 7.5 : 4.75}
                    className={clsxm('lg:mr-[3px]', 'mr-[3px]')}
                    src='https://cdn.app.hatom.com/images/pages/lend/approximation.svg'
                    alt='approximation'
                  />
                )}
                {text}
              </p>
              <p
                className={clsxm(
                  'block opacity-80 text-right',
                  'lg:text-[8px] lg:pr-[0.75px] lg:mt-[3.5px] lg:leading-[0px]',
                  'text-[6px] leading-[7.5px]',
                )}
              >
                {HTMText}
              </p>
            </div>
          ) : (
            <span> {text} </span>
          )}
        </div>

        {isWithBoost && <BonusButton />}
      </m.button>
    </LazyMotion>
  );
};

export default ClaimRewardButton;
