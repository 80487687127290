import classNames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { EXCHANGE_RATE_KEY } from '@/hooks/protocol/useExchangeRate';

import { CollateralBadge } from '@/components/CollateralBadge';
import Hint from '@/components/Hint';
import WrappedImg from '@/components/WrappedImg';

import { useHintProps } from '@/sections/Liquid/App/global';
import { PrettyToken } from '@/sections/Liquid/Common/PrettyToken';
import { formatNumber } from '@/utils/helpers';

type RowProps = {
  token: string;
  accentColor: string;
  secondaryColor?: string;
  percentage: number;
  value: string;
  collateral?: any;
  valueUSD: any;
  hovered: boolean;
};

export default function Row({
  token,
  accentColor,
  secondaryColor,
  percentage,
  value,
  collateral,
  valueUSD,
  hovered,
}: RowProps) {
  const defaultHintProps = useHintProps();

  accentColor = hovered && secondaryColor ? secondaryColor : accentColor;
  const { t } = useTranslation('liquid-app');

  const imageUrl = useMemo(() => {
    const map: Record<string, string> = {
      [EXCHANGE_RATE_KEY.sEGLD]: 'https://cdn.app.hatom.com/images/liquidstaking/app/tokens/segld.svg',
      [EXCHANGE_RATE_KEY.HsEGLD]: 'https://cdn.app.hatom.com/images/liquidstaking/app/tokens/hsegld.png',
      [EXCHANGE_RATE_KEY.sWTAO]: 'https://cdn.app.hatom.com/images/liquidstaking/app/tokens/swtao-circle.svg',
      [EXCHANGE_RATE_KEY.HsWTAO]: 'https://cdn.app.hatom.com/images/liquidstaking/app/tokens/hswtao.svg',
    };

    return map[token];
  }, [token]);

  return (
    <div
      className={classNames('grid grid-cols-[3px,1fr] gap-2 whitespace-nowrap')}
    >
      <div className='relative top-1/2 h-8 -translate-y-1/2'>
        <div
          className='h-full w-full self-center rounded '
          style={{ background: accentColor }}
        />
        <div className='absolute top-0 left-0.5 overflow-hidden'>
          <div
            className={classNames(
              'h-8 w-2 opacity-50 transition-all delay-75 duration-300',
              hovered ? 'translate-x-0' : '-translate-x-full',
            )}
            style={{
              background: `linear-gradient(to right, ${accentColor}, #fff0 80%)`,
            }}
          ></div>
        </div>
      </div>
      <div
        className={classNames(
          'flex flex-col gap-0.5',
          'transition-all delay-75 duration-300',
          {
            'translate-x-1': hovered,
          },
        )}
      >
        <div className='flex items-center gap-1 text-[#3A3C40] dark:text-white'>
          <WrappedImg className='w-4' src={imageUrl} />
          {token} {t(`${collateral ? 'collateral' : 'wallet'}-balance`)}
          {collateral && (
            <Hint
              {...defaultHintProps}
              content={
                <div className='max-w-[200px]'>
                  <Trans
                    i18nKey='tp-19'
                    components={[
                      <PrettyToken token={token} />,
                      <span className='text-green' />,
                    ]}
                    ns='liquid-app'
                  />
                </div>
              }
            >
              <CollateralBadge className='origin-bottom scale-[.8]' on={true} />
            </Hint>
          )}
        </div>
        <div className='grid grid-cols-[80px,1fr] items-center'>
          <span
            className={classNames('text-[18px] transition-all')}
            style={{
              color: accentColor,
            }}
          >
            {formatNumber(percentage, 2)}%
          </span>
          <span
            className={classNames(
              'whitespace-nowrap text-[12px] transition-all',
            )}
          >
            {formatNumber(value, 2)} {token} ≈ ${formatNumber(valueUSD, 2)}
          </span>
        </div>
      </div>
    </div>
  );
}
