import DefiUtils from 'defi-utils';

import { getTokenBorrowLimit } from '@/utils/helpers';

/**
 * Calculates the supply balance for a given market params.
 * It calculates the underlying for collateral and htoken and returns it as USD.
 * @param hTokenAccountBalance string arg for htoken balance
 * @param hTokenExchangeRate string arg for htoken rate
 * @param collateralBalance string arg for collateral balance
 * @param priceUSD string arg for underlying price in USD
 * @param underlyingDecimals number arg for the decimals of the underlying token
 * @returns supply balance as DefiUtils
 */
export const calcSupplyBalanceUSD = (
  hTokenAccountBalance: string,
  hTokenExchangeRate: string,
  collateralBalance: string,
  priceUSD: string,
  underlyingDecimals: number,
): DefiUtils => {
  const htokenBalanceAsUnderlying = new DefiUtils(hTokenAccountBalance)
    .toUnderlying(hTokenExchangeRate)
    .dividedBy(`1e${underlyingDecimals}`);

  const collateralBalanceAsUnderlying = new DefiUtils(collateralBalance)
    .toUnderlying(hTokenExchangeRate)
    .dividedBy(`1e${underlyingDecimals}`);

  const result = htokenBalanceAsUnderlying
    .plus(collateralBalanceAsUnderlying)
    .multipliedBy(priceUSD);

  return result;
};

/**
 * Calculates the balance in usd for a given htoken params
 * @param hTokenAccountBalance string arg for htoken balance
 * @param hTokenExchangeRate string arg for htoken rate
 * @param accountBalance underlying token balance of the account
 * @param priceUSD string arg for underlying price in USD
 * @param underlyingDecimals number arg for the decimals of the underlying token
 * @returns token balance as number
 */
export const calcTokenBalanceUSD = (
  hTokenAccountBalance: string,
  hTokenExchangeRate: string,
  accountBalance: string,
  priceUSD: string,
  underlyingDecimals: number,
): number => {
  const hTokenBalanceInUnderlying = new DefiUtils(
    hTokenAccountBalance ?? '0',
  ).toUnderlying(hTokenExchangeRate);

  const totalUnderlying = new DefiUtils(accountBalance ?? '0').plus(
    hTokenBalanceInUnderlying,
  );

  const roundedBalance = new DefiUtils(totalUnderlying.toString())
    .toFullDecimals(underlyingDecimals)
    .toNumber();

  const priceFactor = new DefiUtils(priceUSD || 0);
  const usdBalance: number = new DefiUtils(roundedBalance)
    .multipliedBy(priceFactor)
    .toNumber();

  return usdBalance;
};

/**
 * Calculates the borrow balance in usd for a given borrow underlying
 * @param underlyingBorrowBalance balance borrowed in underlying token
 * @param priceUSD string arg for underlying price in USD
 * @param underlyingDecimals number arg for the decimals of the underlying token
 * @returns borrow balance as string
 */
export const calcBorrowBalance = (
  underlyingBorrowBalance: string,
  priceUSD: number | string,
  underlyingDecimals: number,
): string => {
  const result = new DefiUtils(underlyingBorrowBalance)
    .multipliedBy(priceUSD)
    .dividedBy(`1e${underlyingDecimals}`)
    .toString();
  return result;
};

/**
 * Calculates the collateral balance in usd for a given collateral underlying
 * @param collateralUnderlyingBalance collateral in underlying token
 * @param priceUSD string arg for underlying price in USD
 * @param underlyingDecimals number arg for the decimals of the underlying token
 * @returns collateral balance as string
 */
export const calcCollateralBalance = (
  collateralUnderlyingBalance: string,
  priceUSD: string,
  underlyingDecimals: number,
): number => {
  const priceFactor = new DefiUtils(priceUSD || 0);

  const collateralBalanceUSD = new DefiUtils(
    collateralUnderlyingBalance ?? '0',
  ).multipliedBy(priceFactor);

  const collateralBalanceNumber = new DefiUtils(collateralBalanceUSD.toString())
    .toFullDecimals(underlyingDecimals)
    .toNumber();

  return collateralBalanceNumber as number;
};

/**
 * Calculates the borrow limit in usd for a given collateral underlying balance and factor
 * @param collateralUnderlyingBalance collateral in underlying token
 * @param collateralFactor collateral factor as string
 * @param priceUSD string arg for underlying price in USD
 * @param underlyingDecimals number arg for the decimals of the underlying token
 * @returns DefiUtils amount of collateral balance number
 */
export const calcBorrowLimit = (
  collateralUnderlyingBalance: string,
  collateralFactor: string,
  priceUSD: string,
  underlyingDecimals: number,
): DefiUtils => {
  const priceFactor = new DefiUtils(priceUSD || 0);

  const borrowLimitAmount = getTokenBorrowLimit(
    collateralUnderlyingBalance ?? '0',
    collateralFactor,
  );
  const collateralBalanceUSD = new DefiUtils(borrowLimitAmount).multipliedBy(
    priceFactor,
  );

  const collateralBalanceNumber = new DefiUtils(
    collateralBalanceUSD,
  ).toFullDecimals(underlyingDecimals);
  // .multipliedBy(supplySimulate);

  return collateralBalanceNumber;
};

export const getSupplyRate = (
  cash: string,
  borrows: string,
  reserves: string,
  reserve_factor: string,
  baseRate: string,
  firstSlope: string,
  optimalUtilization: string,
  lastSlope: string,
) => {
  const utilization = getUtilization(cash, borrows, reserves);
  const borrow_rate = getBorrowRate(
    cash,
    borrows,
    reserves,
    baseRate,
    firstSlope,
    optimalUtilization,
    lastSlope,
  );

  return new DefiUtils(utilization)
    .multipliedBy(borrow_rate)
    .div(DefiUtils.WAD)
    .multipliedBy(new DefiUtils(DefiUtils.WAD).minus(reserve_factor))
    .div(DefiUtils.WAD);
};

export const getBorrowRate = (
  cash: string,
  borrows: string,
  reserves: string,
  baseRate: string,
  firstSlope: string,
  optimalUtilization: string,
  lastSlope: string,
) => {
  const u = getUtilization(cash, borrows, reserves);
  const r0 = baseRate;
  const m1 = firstSlope;
  const uo = optimalUtilization;

  if (new DefiUtils(u).isLessThanOrEqualTo(uo)) {
    return new DefiUtils(m1).multipliedBy(u).div(DefiUtils.WAD).plus(r0);
  } else {
    const r1 = new DefiUtils(m1).multipliedBy(uo).div(DefiUtils.WAD).plus(r0);
    const m2 = lastSlope;
    return new DefiUtils(r1).plus(
      new DefiUtils(m2)
        .multipliedBy(new DefiUtils(u).minus(uo))
        .div(DefiUtils.WAD),
    );
  }
};

export const getUtilization = (
  cash: string,
  borrows: string,
  reserves: string,
) => {
  const zero = new DefiUtils('0');
  const liquidity = new DefiUtils(cash).plus(borrows).minus(reserves);

  if (
    new DefiUtils(borrows).isEqualTo(zero) ||
    new DefiUtils(liquidity).isEqualTo(zero)
  ) {
    return zero;
  }

  return new DefiUtils(borrows).multipliedBy(DefiUtils.WAD).div(liquidity);
};

export const calcExchangeRate = ({
  cash,
  borrows,
  reserves,
  totalSupply,
}: {
  cash: string;
  borrows: string;
  reserves: string;
  totalSupply: string;
}) => {
  const value = new DefiUtils(cash)
    .plus(borrows)
    .minus(reserves)
    .times(1e18)
    .div(totalSupply)
    .toFixed(0);

  return new DefiUtils(value).toSafeString();
};

export const calcRateSimulate = (rate: string, timestamp: string) => {
  const currentDate = new Date();
  const currentDateInSeconds = currentDate.getTime() / 1000;
  const timestampInSeconds = new Date(timestamp).getTime() / 1000;

  return new DefiUtils(rate)
    .multipliedBy(currentDateInSeconds - timestampInSeconds)
    .dividedBy(`1e18`)
    .plus(1)
    .toString();
};

export const calcSimulateExchangeRate = ({
  cash,
  borrows,
  reserves,
  totalSupply,
  rate,
  timestamp,
}: {
  cash: string;
  borrows: string;
  reserves: string;
  totalSupply: string;
  rate: string;
  timestamp: string;
}) => {
  return new DefiUtils(
    calcExchangeRate({ cash, borrows, reserves, totalSupply }),
  )
    .multipliedBy(calcRateSimulate(rate, timestamp))
    .toString();
};

export const calcUnderlyingBorrowBalance = ({
  borrowAmount,
  marketBorrowIndex,
  borrowIndex,
  borrowRatePerSecond,
  timestamp,
}: {
  borrowAmount: string;
  marketBorrowIndex: string;
  borrowIndex: string;
  borrowRatePerSecond: string;
  timestamp: string;
}) => {
  const currentDate = new Date();
  const currentDateInSeconds = currentDate.getTime() / 1000;
  const timestampInSeconds = new Date(timestamp).getTime() / 1000;

  const borrowSimulate = new DefiUtils(borrowRatePerSecond)
    .multipliedBy(currentDateInSeconds - timestampInSeconds)
    .dividedBy(`1e18`)
    .plus(1)
    .toString();

  const value = new DefiUtils(borrowAmount)
    .multipliedBy(marketBorrowIndex)
    .dividedBy(borrowIndex)
    .multipliedBy(borrowSimulate);

  return value.toSafeString();
};
