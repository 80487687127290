import DefiUtils from 'defi-utils';

export const INITIAL_EXCHANGE_RATE = '1000000000000000000';

export const INITIAL_TAO_EXCHANGE_RATE = '1000000000000000000';

export const calcLiquidStakingExchangeRate = (
  cashReserve: string,
  totalShares: string,
) => {
  if (totalShares === '0') {
    return INITIAL_EXCHANGE_RATE;
  }

  return new DefiUtils(
    new DefiUtils(cashReserve)
      .multipliedBy(DefiUtils.WAD)
      .dividedBy(totalShares)
      .toFixed(0, DefiUtils.ROUND_DOWN),
  ).toSafeString();
};

export const calcLiquidStakingAPY = (
  totalFee: string,
  delegationContracts: {
    totalDelegated: string;
    apr: string;
    serviceFee: string;
  }[],
) => {
  const sum1 = delegationContracts
    .reduce((prev, { totalDelegated, apr }) => {
      const aprWithFee = new DefiUtils(apr)
        .multipliedBy(new DefiUtils(100).minus(totalFee))
        .dividedBy(new DefiUtils(100))
        .toString();

      return prev.plus(new DefiUtils(aprWithFee).multipliedBy(totalDelegated));
    }, new DefiUtils('0'))
    .toString();

  const sum2 = delegationContracts
    .reduce(
      (prev, { totalDelegated }) => prev.plus(totalDelegated),
      new DefiUtils('0'),
    )
    .toString();

  return new DefiUtils(sum1)
    .dividedBy(sum2)
    .dividedBy(100)
    .toAPY()
    .multipliedBy(100)
    .toSafeString();
};
