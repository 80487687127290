import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import Loader from '@/components/popups/ConnectWalletPopup/components/Loader';

const AwaitingPermission = () => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'border border-[#D3D0E4] bg-white dark:border-[#2E2C51] dark:bg-[#1C1A35]',
        'mt-12 flex h-[224px] flex-col items-center rounded-[8px] p-5 pt-6 text-center font-semibold leading-tight tracking-normal'
      )}
    >
      <div className='max-w-[184px] opacity-80'>
        <div
          className={classNames(
            'text-lg',
            'leading-[23px] dark:text-indigo-100'
          )}
        >
          {t('unlock-wallet')}
        </div>
        <p
          className={classNames(
            'mt-3.5 text-xs leading-[15px] tracking-wide',
            'text-[#535367] dark:text-[#625E8F]'
          )}
        >
          {t('unlock-wallet-motivation')}
        </p>
      </div>
      <div className='mt-1'>
        <Loader />
      </div>
    </div>
  );
};

export default AwaitingPermission;
