import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { CSSProperties } from 'react';

import { OtherLogos } from '@/components/WalletLogos';

const ScamAdvice = () => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(
        'gradient-border',
        'rounded-[8px]',
        'text-[#1A1A1A]'
      )}
      style={
        {
          ['--border-size']: '1px',
          ['--content-background']: '#fff0',
          ['--linear-gradient']:
            'linear-gradient(160deg, #F372FE -3.81%, #6C9ADF 47.11%, #F09DF6 61.44%, #61E07D 91.1%)',
        } as CSSProperties
      }
    >
      <div
        className={classNames(
          '[background:linear-gradient(111.09deg,#B9EEFF_-3.53%,#CBCAF6_50.14%,#E2D3E9_70.14%,#CFE3D3_94.55%)] dark:[background:linear-gradient(110deg,#BFB8F8_1.98%,#5468FA_34.78%,#3C5AA6_65.95%,#498B96_93.34%)]',
          'rounded-[8px]',
          'h-[52px] p-3',
          'dark:bg-indigo-400/50 dark:bg-none',
          'dark:text-indigo-200',
          'flex flex-col items-center'
        )}
      >
        <span className='flex gap-1.5 text-center text-xs tracking-wide'>
          <span className={classNames('text-[#839EFF] dark:text-[#D2CBFF]')}>
            {OtherLogos.closedLock}
          </span>
          <span
            className='max-w-[280px]'
            dangerouslySetInnerHTML={{
              __html: t('wallet-provider.verification', {
                website_link: 'https://hatom.com',
              }).replace(
                'https://',
                `<span class="${'text-[#4EA87C] dark:text-[#7EEBB6]'}">https://</span>`
              ),
            }}
          ></span>
        </span>
      </div>
    </div>
  );
};

export default ScamAdvice;
