import React from 'react';

type Props = {
  className?: string;
};

const BoosterIcon = (props: Props) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={props.className}
      {...props}
    >
      <path
        d='M0.47037 8.84281C0.47037 8.1858 0.477649 7.52879 0.47037 6.87178C0.466123 6.60546 0.551662 6.48231 0.834364 6.4908C1.3294 6.50536 1.82564 6.50536 2.32007 6.4908C2.61854 6.48231 2.70954 6.61699 2.70347 6.89362C2.69255 7.38865 2.69741 7.88429 2.70347 8.37932C2.70711 8.72451 2.80964 8.79246 3.10629 8.63473C4.79361 7.74051 6.47849 6.84125 8.16096 5.93692C8.25681 5.88536 8.30534 5.74765 8.37571 5.64997C8.26469 5.60144 8.15368 5.51166 8.04144 5.51044C7.01013 5.49952 5.97881 5.50498 4.94749 5.50316C4.56045 5.50316 4.53922 5.48072 4.538 5.0876C4.538 4.61259 4.54164 4.13758 4.538 3.66257C4.53315 3.27795 4.40939 3.20029 4.07573 3.37744C3.02864 3.93152 1.98539 4.48883 0.945988 5.04938C0.555302 5.25807 0.471583 5.21197 0.470976 4.76304C0.470976 3.61039 0.499489 2.45775 0.461876 1.3051C0.430937 0.364782 1.40523 -0.186063 2.20116 0.257403C5.02575 1.83148 7.84913 3.40878 10.6713 4.98933C11.5152 5.46373 11.4976 6.54965 10.6458 7.0283C8.3377 8.32412 6.02573 9.61468 3.70992 10.9C3.22459 11.1706 2.7332 11.4314 2.25394 11.7165C1.45255 12.1946 0.399997 11.665 0.463696 10.6616C0.501916 10.0579 0.47037 9.44947 0.47037 8.84281Z'
        fill='#E04040'
      />
    </svg>
  );
};

export default BoosterIcon;
