import classNames from 'classnames';

export const Snake = () => {
  return (
    <img
      className={classNames(
        'absolute',
        'block',
        'top-[-46px]',
        'right-[-17px]',
        'z-[-1]'
      )}
      alt='Snake'
      src='https://cdn.app.hatom.com/images/governance/balance-snake.svg'
    />
  );
};
