import classNames from 'classnames';
import { Trans } from 'next-i18next';

export const Description = () => {
  return (
    <div
      className={classNames(
        'bg-[#F5F2FF]',
        'dark:bg-[#101015]',
        'dark:border-[#1D1D33]',
        'p-[8px]',
        'rounded-[12px]',
        'text-[#77778B]',
        'dark:text-[#fff]',
        'mr-[-8px]',
        'mb-5',
        'font-normal',
      )}
    >
      <p
        className={classNames(
          'text-[10px]',
          'leading-[13px]',
          'font-normal',
          'tracking-[0.1px]',
        )}
      >
        <Trans
          i18nKey='A <1>Snapshot</1> will be randomly taken within the first 2 days of the proposal creation to define your <0>Voting Power</0>. Keep your <0>HTM</0> tokens staked during this time to ensure your full <0>Voting Power</0>.'
          components={[
            <span className='text-[#38A98A]' />,
            <span className='text-[#F0B90B]' />,
          ]}
        />
      </p>
    </div>
  );
};
