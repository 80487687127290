import { useRef, useState } from 'react';

import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect';

const useImage = (url: string, crossOrigin?: any) => {
  const statusRef = useRef('loading');
  const imageRef = useRef<any>();

  const [_, setStateToken] = useState(0);

  const oldUrl = useRef<any>();
  const oldCrossOrigin = useRef();
  if (oldUrl.current !== url || oldCrossOrigin.current !== crossOrigin) {
    statusRef.current = 'loading';
    imageRef.current = undefined;
    oldUrl.current = url;
    oldCrossOrigin.current = crossOrigin;
  }

  useIsomorphicLayoutEffect(
    function () {
      if (!url) return;
      var img = document.createElement('img');

      function onload() {
        statusRef.current = 'loaded';
        imageRef.current = img;
        setStateToken(Math.random());
      }

      function onerror() {
        statusRef.current = 'failed';
        imageRef.current = undefined;
        setStateToken(Math.random());
      }

      img.addEventListener('load', onload);
      img.addEventListener('error', onerror);
      crossOrigin && (img.crossOrigin = crossOrigin);
      img.src = url;

      return function cleanup() {
        img.removeEventListener('load', onload);
        img.removeEventListener('error', onerror);
      };
    },
    [url, crossOrigin]
  );

  return [imageRef.current, statusRef.current];
};

export default useImage;
