import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/index';
import {
  addOrUpdateTransactionGroup,
  setTransactionProcessingStatus,
  TRANSACTION_GROUPS_STORAGE_KEY,
  TRANSACTION_PROCESSING_STATUS_TYPE,
  TransactionGroup,
  transactionSelector,
} from '@/store/transaction';

import { JSONSafeParse } from '@/utils/helpers';

const useStoreTransactionGroups = () => {
  const dispatch = useAppDispatch();
  const { transactionProcessingStatus } = useAppSelector(transactionSelector);

  useEffect(() => {
    if (
      transactionProcessingStatus !==
      TRANSACTION_PROCESSING_STATUS_TYPE.GETTING_STORED_TXS
    ) {
      return;
    }

    const transactionGroups = JSONSafeParse(
      localStorage.getItem(TRANSACTION_GROUPS_STORAGE_KEY) || '[]',
      []
    ) as TransactionGroup[];

    const transactionGroupsFiltered = transactionGroups.filter(
      ({ transactions }) => {
        const hasPendingTransaction = transactions.some(({ status }) =>
          ['pending', 'signed'].includes(status)
        );
        return hasPendingTransaction;
      }
    );

    const promises = Promise.all(
      transactionGroupsFiltered.map((TransactionGroup) =>
        dispatch(addOrUpdateTransactionGroup(TransactionGroup))
      )
    );

    promises.then(() => {
      dispatch(
        setTransactionProcessingStatus(
          TRANSACTION_PROCESSING_STATUS_TYPE.SENDING_PENDING_TXS
        )
      );
    });
  }, [transactionProcessingStatus]);
};

export default useStoreTransactionGroups;
