const liquidLockingABI = {
  buildInfo: {
    rustc: {
      version: '1.75.0-nightly',
      commitHash: 'df871fbf053de3a855398964cd05fadbe91cf4fd',
      commitDate: '2023-10-24',
      channel: 'Nightly',
      short: 'rustc 1.75.0-nightly (df871fbf0 2023-10-24)',
    },
    contractCrate: {
      name: 'liquid-locking',
      version: '0.0.0',
    },
    framework: {
      name: 'multiversx-sc',
      version: '0.45.2',
    },
  },
  name: 'LiquidLocking',
  constructor: {
    inputs: [
      {
        name: 'unbond_period',
        type: 'u64',
      },
    ],
    outputs: [],
  },
  endpoints: [
    {
      name: 'set_unbond_period',
      onlyOwner: true,
      mutability: 'mutable',
      inputs: [
        {
          name: 'unbond_period',
          type: 'u64',
        },
      ],
      outputs: [],
    },
    {
      name: 'whitelist_token',
      onlyOwner: true,
      mutability: 'mutable',
      inputs: [
        {
          name: 'token',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [],
    },
    {
      name: 'blacklist_token',
      onlyOwner: true,
      mutability: 'mutable',
      inputs: [
        {
          name: 'token',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [],
    },
    {
      name: 'lock',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [],
      outputs: [],
    },
    {
      name: 'unlock',
      mutability: 'mutable',
      inputs: [
        {
          name: 'tokens',
          type: 'List<EsdtTokenPayment>',
        },
      ],
      outputs: [],
    },
    {
      name: 'unbond',
      mutability: 'mutable',
      inputs: [
        {
          name: 'tokens',
          type: 'List<TokenIdentifier>',
        },
      ],
      outputs: [],
    },
    {
      name: 'lockedTokenAmounts',
      mutability: 'readonly',
      inputs: [
        {
          name: 'address',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'List<EsdtTokenPayment>',
        },
      ],
    },
    {
      name: 'unlockedTokenAmounts',
      mutability: 'readonly',
      inputs: [
        {
          name: 'address',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'List<UnlockedToken>',
        },
      ],
    },
    {
      name: 'lockedTokens',
      mutability: 'readonly',
      inputs: [
        {
          name: 'address',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'variadic<TokenIdentifier>',
          multi_result: true,
        },
      ],
    },
    {
      name: 'unlockedTokens',
      mutability: 'readonly',
      inputs: [
        {
          name: 'address',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'variadic<TokenIdentifier>',
          multi_result: true,
        },
      ],
    },
    {
      name: 'whitelistedTokens',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'variadic<TokenIdentifier>',
          multi_result: true,
        },
      ],
    },
    {
      name: 'unbondPeriod',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'u64',
        },
      ],
    },
  ],
  events: [
    {
      identifier: 'lock',
      inputs: [
        {
          name: 'user',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'tokens',
          type: 'List<EsdtTokenPayment>',
        },
      ],
    },
    {
      identifier: 'unlock',
      inputs: [
        {
          name: 'user',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'tokens',
          type: 'List<EsdtTokenPayment>',
        },
      ],
    },
  ],
  esdtAttributes: [],
  hasCallback: false,
  types: {
    EsdtTokenPayment: {
      type: 'struct',
      fields: [
        {
          name: 'token_identifier',
          type: 'TokenIdentifier',
        },
        {
          name: 'token_nonce',
          type: 'u64',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
      ],
    },
    UnlockedToken: {
      type: 'struct',
      fields: [
        {
          name: 'token',
          type: 'EsdtTokenPayment',
        },
        {
          name: 'unbond_epoch',
          type: 'u64',
        },
      ],
    },
  },
};

export default liquidLockingABI;
