import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import React from 'react';

import Hint from '@/components/Hint';
import InfoIcon from '@/components/Icons/InfoIcon';

import { Shimmer } from '@/sections/Liquid/App';
import { useHintProps } from '@/sections/Liquid/App/global';
import { formatNumber, nFormatter } from '@/utils/helpers';

export const Label: React.FC<any> = ({
  label,
  token,
  valueText,
  value = '0',
  valueUSD = '0',
  className = '',
  tooltip,
}) => {
  const defaultHintProps = useHintProps();

  return (
    <div
      className={classNames(
        className,
        'flex items-center justify-between font-hass'
      )}
    >
      <label className='flex items-center gap-1'>
        <span className='leading-[24px]'>{label}</span>

        {tooltip && (
          <Hint {...defaultHintProps} autoReplace={true} content={tooltip}>
            <InfoIcon
              className='dark:text-[#625E8F] inline-block text-[#5C5C5C]'
              width={11}
              height={11}
              strokeWidth='1.5'
            />
          </Hint>
        )}
      </label>

      {valueText && value && (
        <div className='text-[12px] md:text-[14px] font-semibold'>
          <span className='dark:text-[#625E8F] text-[#535367]'>
            {valueText}:
          </span>{' '}
          <Hint
            {...defaultHintProps}
            placement='top-center'
            content={
              <div className='flex flex-col items-end leading-none tracking-normal'>
                <div>
                  {formatNumber(value, new DefiUtils(value).decimalPlaces())}
                </div>
                <div className='dark:text-[#979797] text-[#C4C4C4]'>
                  ≈${formatNumber(valueUSD, 2)}
                </div>
              </div>
            }
          >
            <Shimmer sm>{nFormatter(value, 2)}</Shimmer>
          </Hint>{' '}
          <Shimmer xs>{token}</Shimmer>
        </div>
      )}
    </div>
  );
};
