import DefiUtils from 'defi-utils';

import { WAD } from '@/data/constants';

import { ParsedBoosterAccount } from '@/store/parsers/booster-parser';
import { Market, MARKET_KEY, UserBalance } from '@/store/protocol';

export const getBoosterPenalty = ({
  boosterAccount,
  assetKey,
  accountCollateralHToken,
}: {
  boosterAccount: ParsedBoosterAccount;
  assetKey: string;
  accountCollateralHToken: string;
}) => {
  const booster = boosterAccount[assetKey] ?? {};

  if (!booster) {
    return new DefiUtils('0');
  }

  // Calculate Price Integral Average
  const priceIntegralAverageCalc = new DefiUtils(
    booster.marketBooster?.priceIntegral,
  ).minus(booster.accountMarketBooster?.priceIntegral);

  let priceIntegralAverageCalc2 = new DefiUtils(
    booster.marketBooster?.priceIntegralTimestamp,
  ).minus(booster.accountMarketBooster?.priceIntegralTimestamp);

  priceIntegralAverageCalc2 = priceIntegralAverageCalc2.dividedBy(1000);

  let priceIntegralAverage;

  if (priceIntegralAverageCalc2.isZero()) return new DefiUtils(WAD);
  else
    priceIntegralAverage = priceIntegralAverageCalc.dividedBy(
      priceIntegralAverageCalc2,
    );

  // Calculate HTM Percentage
  const accountStake = new DefiUtils(booster.accountMarketBooster?.staked);
  const accountCollateral = new DefiUtils(accountCollateralHToken);

  let htmPercentage;
  if (accountCollateral.isZero()) htmPercentage = '0';
  else htmPercentage = accountStake.dividedBy(accountCollateral);

  return new DefiUtils(priceIntegralAverage).multipliedBy(htmPercentage);
};

export const calculatePercentageBoost = ({
  htmPrice,
  boosterAccount,
  totalStaked,
  markets,
  userBalances,
  maximumApyBooster,
}: {
  htmPrice: string;
  boosterAccount: ParsedBoosterAccount;
  markets: Record<MARKET_KEY, Market>;
  totalStaked: string;
  userBalances: Record<MARKET_KEY, UserBalance>;
  maximumApyBooster: string;
}) => {
  const marketsData = Object.values(markets)
    .filter((market) => {
      const boosterAccountItem = boosterAccount[market.underlying.symbol];

      return (
        boosterAccountItem &&
        new DefiUtils(boosterAccountItem.speed).isGreaterThan(0) &&
        boosterAccountItem.hasCollateral
      );
    })
    .map((market) => {
      const marketId = market.underlying.symbol;
      const marketSelected = markets[marketId as MARKET_KEY];
      const collateral =
        userBalances[marketId as MARKET_KEY]?.collateralBalance || '0';

      const penalty = getBoosterPenalty({
        assetKey: marketId,
        accountCollateralHToken: collateral,
        boosterAccount,
      });

      const exchangeRate = new DefiUtils(marketSelected.hTokenExchangeRate);
      const underlyingPrice = new DefiUtils(marketSelected.underlying.priceUSD);

      return {
        market: marketId,
        collateral: new DefiUtils(collateral),
        penalty,
        exchangeRate,
        underlyingPrice,
        decimals: marketSelected.underlying.decimals,
      };
    });

  const denominator = marketsData.reduce((prev, current) => {
    const calc = new DefiUtils(current.collateral)
      .multipliedBy(current.exchangeRate)
      .multipliedBy(current.underlyingPrice)
      .toFullDecimals(current.decimals);
    // .dividedBy(WAD);

    return prev.plus(calc);
  }, new DefiUtils(0));

  const numerator = new DefiUtils(totalStaked)
    .multipliedBy(htmPrice)
    .toFullDecimals(markets?.HTM?.underlying?.decimals);
  // .dividedBy(WAD);

  const result = denominator.isZero()
    ? new DefiUtils('0')
    : numerator
        .multipliedBy(WAD)
        .dividedBy(denominator)
        .dividedBy(maximumApyBooster)
        .multipliedBy(100);

  return result.toSafeString();
};

export const getOptimalBoost = ({
  htmPrice,
  boosterAccount,
  markets,
  userBalances,
}: {
  htmPrice: string;
  boosterAccount: ParsedBoosterAccount;
  markets: Record<MARKET_KEY, Market>;
  userBalances: Record<MARKET_KEY, UserBalance>;
}) => {
  const marketsData = Object.values(markets)
    .filter((market) => {
      const boosterAccountItem = boosterAccount[market.underlying.symbol];

      return (
        boosterAccountItem &&
        new DefiUtils(boosterAccountItem.speed).isGreaterThan(0) &&
        boosterAccountItem.hasCollateral
      );
    })
    .map((market) => {
      const marketId = market.underlying.symbol;
      const marketSelected = markets[marketId as MARKET_KEY];
      const collateral =
        userBalances[marketId as MARKET_KEY]?.collateralBalance || '0';

      const penalty = getBoosterPenalty({
        assetKey: marketId,
        accountCollateralHToken: collateral,
        boosterAccount,
      });

      const exchangeRate = new DefiUtils(marketSelected.hTokenExchangeRate);
      const underlyingPrice = new DefiUtils(marketSelected.underlying.priceUSD);

      return {
        market: marketId,
        collateral: new DefiUtils(collateral),
        penalty,
        exchangeRate,
        underlyingPrice,
        decimals: marketSelected.underlying.decimals,
      };
    });

  const denominator = marketsData.reduce((prev, current) => {
    const calc = new DefiUtils(current.collateral)
      .multipliedBy(current.exchangeRate)
      .multipliedBy(current.underlyingPrice)
      .toFullDecimals(current.decimals + 3)
      .dividedBy(WAD);

    return prev.plus(calc);
  }, new DefiUtils(0));

  const calc = new DefiUtils(100.0005).multipliedBy(denominator);

  return calc.dividedBy(htmPrice).multipliedBy(0.5);
};
