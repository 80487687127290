const AproxEquality = () => (
  <svg
    width='388'
    height='197'
    viewBox='0 0 388 197'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className='h-full w-full'
  >
    <rect
      width='393'
      height='200'
      transform='translate(-5 0.998047)'
      fill='#001D44'
    />
    <g>
      <rect x='363' y='127.998' width='2' height='2' fill='white' />
      <rect x='367' y='129.998' width='2' height='2' fill='white' />
      <rect x='369' y='133.998' width='2' height='2' fill='white' />
      <rect x='367' y='137.998' width='2' height='2' fill='white' />
      <rect x='363' y='139.998' width='2' height='2' fill='white' />
      <rect x='359' y='137.998' width='2' height='2' fill='white' />
      <rect x='357' y='133.998' width='2' height='2' fill='white' />
      <rect x='359' y='129.998' width='2' height='2' fill='white' />
    </g>
    <rect x='103' y='181.998' width='2' height='2' fill='white' />
    <rect x='175' y='10.998' width='2' height='2' fill='white' />
    <rect x='104' y='30.998' width='2' height='2' fill='white' />
    <rect x='338' y='84.998' width='2' height='2' fill='white' />
    <rect x='363' y='15.998' width='2' height='2' fill='white' />
    <rect x='320' y='147.998' width='2' height='2' fill='white' />
    <rect x='177' y='171.998' width='2' height='2' fill='white' />
    <g className='animate-fade-in-out-2 delay-200'>
      <rect
        x='310.473'
        y='38.33'
        width='2'
        height='4'
        transform='rotate(-41.8116 310.473 38.33)'
        fill='white'
      />
      <rect
        x='317.139'
        y='45.7834'
        width='2'
        height='4'
        transform='rotate(-41.8116 317.139 45.7834)'
        fill='white'
      />
      <rect
        x='314.473'
        y='42.802'
        width='2'
        height='2'
        transform='rotate(-41.8116 314.473 42.802)'
        fill='white'
      />
      <rect
        x='310'
        y='46.8021'
        width='4'
        height='2'
        transform='rotate(-41.8116 310 46.8021)'
        fill='white'
      />
      <rect
        x='317.453'
        y='40.1353'
        width='4'
        height='2'
        transform='rotate(-41.8116 317.453 40.1353)'
        fill='white'
      />
    </g>
    <g className='animate-pulse'>
      <rect x='70' y='171.998' width='2' height='4' fill='white' />
      <rect x='70' y='177.998' width='2' height='4' fill='white' />
      <rect x='72' y='175.998' width='4' height='2' fill='white' />
      <rect x='66' y='175.998' width='4' height='2' fill='white' />
    </g>
    <g className='animate-fade-in-out'>
      <rect x='300' y='174.998' width='2' height='4' fill='white' />
      <rect x='300' y='180.998' width='2' height='4' fill='white' />
      <rect x='302' y='178.998' width='4' height='2' fill='white' />
      <rect x='296' y='178.998' width='4' height='2' fill='white' />
    </g>
    <rect x='41' y='120.998' width='2' height='2' fill='white' />
    <rect x='41' y='124.998' width='2' height='2' fill='white' />
    <rect x='39' y='122.998' width='2' height='2' fill='white' />
    <rect x='43' y='122.998' width='2' height='2' fill='white' />
    <g className='animate-fade-in-out'>
      <rect x='278' y='14.998' width='2' height='2' fill='white' />
      <rect x='278' y='18.998' width='2' height='2' fill='white' />
      <rect x='276' y='16.998' width='2' height='2' fill='white' />
      <rect x='280' y='16.998' width='2' height='2' fill='white' />
    </g>
    <g className='animate-fade-in-out-1'>
      <rect x='11' y='73.998' width='2' height='2' fill='white' />
      <rect x='11' y='77.998' width='2' height='2' fill='white' />
      <rect x='9' y='75.998' width='2' height='2' fill='white' />
      <rect x='13' y='75.998' width='2' height='2' fill='white' />
    </g>
    <g className='animate-pulse'>
      <rect x='41' y='15.998' width='2' height='2' fill='white' />
      <rect x='35' y='21.998' width='2' height='2' fill='white' />
      <rect x='41' y='27.998' width='2' height='2' fill='white' />
      <rect x='47' y='21.998' width='2' height='2' fill='white' />
    </g>
    <g className='animate-pulse'>
      <rect x='143' y='32.998' width='2' height='4' fill='white' />
      <rect x='143' y='38.998' width='2' height='4' fill='white' />
      <rect x='145' y='36.998' width='4' height='2' fill='white' />
      <rect x='139' y='36.998' width='4' height='2' fill='white' />
    </g>
    <path
      d='M30 117.322V68.2723L30.0999 68.2644L30.1326 68.2747C30.1241 68.4097 30.1084 68.5448 30.1084 68.6798C30.1084 73.8444 30.1084 79.009 30.1084 84.1736C30.1084 84.3237 30.1278 84.4763 30.1381 84.6235C30.1278 84.7888 30.1084 84.9535 30.1078 85.1188C30.1078 90.241 30.1078 95.3636 30.1078 100.487C30.1078 100.652 30.1278 100.817 30.1381 100.982C30.1241 101.164 30.0987 101.342 30.0987 101.522C30.0963 106.623 30.0963 111.724 30.0987 116.826C30.0987 116.991 30.1145 117.157 30.1229 117.322L30.0842 117.35C30.0602 117.331 30.0305 117.321 30 117.322Z'
      fill='#001D44'
    />
    <path
      d='M30 117.324C30.0307 117.323 30.0607 117.333 30.0848 117.352C30.0927 117.518 30.1084 117.683 30.1084 117.848C30.1084 122.928 30.1084 128.007 30.1084 133.087C30.1084 133.283 30.126 133.478 30.135 133.674C30.1241 133.869 30.1036 134.065 30.1036 134.261C30.1011 139.509 30.1001 144.757 30.1005 150.005L30 150.024V117.324Z'
      fill='#AAD9FF'
    />
    <path
      d='M30.0999 68.2708L30 68.2787V52.0195L30.106 52.0335C30.1096 52.1388 30.1169 52.2448 30.1169 52.3502C30.1169 57.5369 30.1169 62.7233 30.1169 67.9093C30.1163 68.0298 30.1054 68.1503 30.0999 68.2708Z'
      fill='#FFA2A0'
    />
    <path
      d='M175.4 155.475H128.16C128.175 155.417 128.186 155.358 128.191 155.298C128.191 153.634 128.191 151.97 128.191 150.305C128.183 150.221 128.167 150.138 128.143 150.056H84.5731V155.475H40.987C40.9971 155.431 41.004 155.387 41.0076 155.342C41.0076 153.663 41.0076 151.985 41.0076 150.306C40.9982 150.214 40.9806 150.122 40.9549 150.033H40.3924C37.143 150.033 33.8931 150.033 30.6429 150.033C30.4613 150.033 30.2796 150.015 30.1016 150.005C30.1016 144.757 30.1026 139.509 30.1046 134.26C30.1046 134.065 30.1252 133.869 30.1361 133.674C30.3177 133.674 30.4994 133.681 30.6811 133.681C33.9341 133.681 37.1874 133.681 40.4408 133.681C40.6158 133.681 40.7902 133.693 40.9937 133.7V136.434C40.9937 136.873 40.9888 137.312 40.9937 137.751C40.9991 138.199 40.9677 138.649 41.0149 139.099H84.5828V133.746C85.1181 133.64 127.809 133.66 128.166 133.766C128.166 134.648 128.166 135.539 128.166 136.431C128.166 137.322 128.162 138.205 128.169 139.13H174.784C174.996 139.13 175.208 139.144 175.418 139.151C175.418 144.41 175.417 149.67 175.413 154.929C175.413 155.112 175.404 155.294 175.4 155.475Z'
      fill='#6942FF'
    />
    <path
      d='M30.1284 133.676C30.1193 133.48 30.1023 133.285 30.1017 133.089C30.1017 128.01 30.1017 122.93 30.1017 117.85C30.1017 117.685 30.086 117.52 30.0781 117.354L30.1126 117.326C30.2943 117.331 30.476 117.339 30.6576 117.34H41.0048V122.767H84.5872V117.362H128.168V122.79H128.716C139.263 122.79 166.809 122.79 177.356 122.79C177.522 122.79 177.688 122.778 177.854 122.772C178.02 122.779 178.186 122.791 178.352 122.791C179.833 122.791 181.314 122.791 182.796 122.791H183.271C183.362 123.786 183.322 133.351 183.22 133.709H177.845V139.133L172.953 139.141C172.771 139.141 172.59 139.15 172.408 139.156C172.197 139.148 171.984 139.134 171.774 139.134H128.161C128.154 138.209 128.158 137.322 128.158 136.435C128.157 135.548 128.158 134.652 128.158 133.77C127.801 133.664 85.1098 133.644 84.5745 133.75V139.103H41.0084C40.9612 138.654 40.9927 138.203 40.9872 137.755C40.9812 137.316 40.9872 136.877 40.9872 136.438V133.704C40.7837 133.697 40.6093 133.685 40.4343 133.685C37.1813 133.685 33.928 133.685 30.6746 133.685C30.4917 133.683 30.3101 133.676 30.1284 133.676Z'
      fill='#6DD1FF'
    />
    <path
      d='M30.1016 68.2648C30.107 68.1437 30.1179 68.0226 30.1179 67.9015C30.1211 62.7163 30.1213 57.5314 30.1185 52.3466C30.1185 52.2412 30.1113 52.1353 30.1076 52.0299C30.2578 52.019 30.4104 51.9984 30.5582 51.9978C33.8713 51.9978 37.1847 51.9978 40.4983 51.9978C40.6449 51.9978 40.7914 52.0105 40.9749 52.019V57.4175H84.6324C84.6621 56.8259 84.6421 56.227 84.6458 55.6293C84.6494 55.0316 84.6458 54.4485 84.6458 53.8587V52.0214H128.145V57.4442H128.733C141.063 57.4442 153.393 57.4442 165.724 57.4442C165.92 57.4442 166.116 57.4575 166.312 57.4642C166.312 62.7062 166.312 67.9481 166.312 73.1898C166.312 73.3715 166.3 73.5532 166.293 73.7348C166.097 73.7348 165.9 73.7312 165.704 73.7312H128.168V71.0116C128.168 70.5575 128.171 70.1033 128.168 69.6497C128.164 69.1962 128.188 68.7547 128.151 68.3127H84.5761V73.6658C84.0263 73.7712 41.3564 73.7494 40.9961 73.6422C40.9961 72.7635 40.9961 71.8727 40.9961 70.982C40.9961 70.0912 40.9961 69.2089 40.9961 68.2757H30.1343L30.1016 68.2648Z'
      fill='#5AF1B0'
    />
    <path
      d='M166.291 73.7348C166.298 73.8856 166.31 74.0376 166.31 74.1872C166.31 81.1309 206.31 88.0746 206.31 95.0183V95.5027H200.867V90.0963H195.424V84.6627H173.641C173.635 84.8153 173.623 84.9739 173.623 85.1332C173.623 86.6164 173.623 88.0992 173.623 89.5816C173.623 89.7475 173.61 89.9129 173.603 90.0788H128.161V84.6972C127.622 84.5852 84.9321 84.6064 84.5767 84.7184V87.3828C84.5767 87.8213 84.5821 88.2603 84.5767 88.6993C84.5712 89.1384 84.6082 89.5974 84.5513 90.0461H40.9852V84.6239H30.139C30.1288 84.4744 30.1094 84.3242 30.1094 84.174C30.1094 79.0098 30.1094 73.8452 30.1094 68.6803C30.1094 68.5452 30.1251 68.4102 30.1336 68.2751H40.9936C40.9936 69.2083 40.9936 70.0948 40.9936 70.9814C40.9936 71.8679 40.9936 72.7629 40.9936 73.6416C41.3539 73.7488 84.0238 73.7706 84.5737 73.6652V68.3121H128.148C128.185 68.7541 128.16 69.2023 128.165 69.6492C128.169 70.0961 128.165 70.5575 128.165 71.011V73.7294H165.699C165.898 73.7312 166.094 73.7336 166.291 73.7348Z'
      fill='#006FFF'
    />
    <path
      d='M139.075 106.425L167.838 106.443H168.504V111.841C168.988 111.942 169.439 111.88 169.884 111.891C170.33 111.901 170.793 111.891 171.246 111.891H173.955V117.335C174.595 117.347 175.193 117.339 175.791 117.341C176.389 117.342 177.002 117.341 177.608 117.341H184.865V122.772C184.699 122.778 184.533 122.791 184.367 122.791C173.821 122.791 139.274 122.791 128.728 122.791H128.179V117.366H84.6004V122.771H41.0179V117.344H30.6708C30.4891 117.344 30.3075 117.335 30.1258 117.33C30.1173 117.165 30.1016 116.999 30.1016 116.834C30.1016 111.732 30.1016 106.631 30.1016 101.529C30.1016 101.348 30.127 101.17 30.1409 100.99L41.0143 100.996V106.391C41.589 106.483 84.2364 106.459 84.6016 106.362V101.045C85.1986 100.951 127.83 100.976 128.189 101.076V106.443H138.619C138.773 106.442 138.924 106.43 139.075 106.425Z'
      fill='#2FBE81'
    />
    <path
      d='M174.071 106.421C173.919 106.427 173.768 106.438 173.618 106.438H128.188V101.072C127.828 100.971 85.1974 100.946 84.6003 101.04V106.357C84.237 106.454 41.5877 106.478 41.013 106.387V100.991L30.1397 100.985C30.1294 100.82 30.1094 100.655 30.1094 100.49C30.1094 95.3677 30.1094 90.2451 30.1094 85.1221C30.1094 84.9568 30.1294 84.792 30.1397 84.6267H40.9852V90.0458H84.55C84.6069 89.5971 84.5688 89.1466 84.5755 88.6991C84.5821 88.2516 84.5755 87.821 84.5755 87.3826V84.7182C84.9309 84.6061 127.621 84.5849 128.16 84.697V90.0785H168.6C168.607 90.2602 168.62 90.4419 168.62 90.6205C168.62 93.899 168.62 97.1775 168.62 100.456V100.976H173.949C174.003 101.018 174.016 101.026 174.026 101.036C174.037 101.046 174.048 101.06 174.049 101.072C174.057 102.856 174.064 104.639 174.071 106.421Z'
      fill='#A682F2'
    />
    <path
      d='M255.4 155.905H236.16C236.175 155.847 236.186 155.788 236.191 155.728C236.191 154.064 236.191 152.399 236.191 150.735C236.183 150.651 236.167 150.567 236.143 150.486H189.573V155.905H120.987C120.997 155.861 121.004 155.816 121.008 155.771C121.008 154.093 121.008 152.414 121.008 150.736C120.998 150.643 120.981 150.552 120.955 150.462H120.392C117.143 150.462 113.893 150.462 110.643 150.462C110.461 150.462 110.28 150.444 110.102 150.435C110.102 145.186 110.103 139.938 110.105 134.69C110.105 134.494 110.125 134.299 110.136 134.103C110.318 134.103 110.499 134.111 110.681 134.111C113.934 134.111 117.187 134.111 120.441 134.111C120.616 134.111 120.79 134.123 120.994 134.13V136.864C120.994 137.302 120.989 137.741 120.994 138.18C120.999 138.629 120.968 139.079 121.015 139.528H164.583V134.176C165.118 134.069 217.809 134.09 218.166 134.196C218.166 135.078 218.166 135.969 218.166 136.86C218.166 137.752 218.162 138.635 218.169 139.56H254.784C254.996 139.56 255.208 139.574 255.418 139.581C255.418 144.84 255.417 150.099 255.413 155.359C255.413 155.542 255.404 155.723 255.4 155.905Z'
      fill='#6942FF'
    />
    <path
      d='M110.128 134.106C110.119 133.91 110.102 133.714 110.102 133.519C110.102 128.439 110.102 123.36 110.102 118.28C110.102 118.115 110.086 117.949 110.078 117.784L110.113 117.756C110.294 117.76 110.476 117.769 110.658 117.77H121.005V123.197H164.587V117.792H208.168V123.22H208.716C219.263 123.22 246.809 123.22 257.356 123.22C257.522 123.22 257.688 123.208 257.854 123.202C258.02 123.209 258.186 123.221 258.352 123.221C259.833 123.221 261.314 123.221 262.796 123.221H263.271C263.362 124.216 263.322 133.781 263.22 134.139H257.845V139.563L252.953 139.571C252.771 139.571 252.59 139.58 252.408 139.585C252.197 139.578 251.984 139.564 251.774 139.564H218.161C218.154 138.639 218.158 137.752 218.158 136.865C218.157 135.977 218.158 135.082 218.158 134.2C217.801 134.094 165.11 134.074 164.574 134.18V139.533H121.008C120.961 139.083 120.993 138.633 120.987 138.185C120.981 137.746 120.987 137.307 120.987 136.868V134.134C120.784 134.127 120.609 134.115 120.434 134.115C117.181 134.115 113.928 134.115 110.675 134.115C110.492 134.112 110.31 134.106 110.128 134.106Z'
      fill='#6DD1FF'
    />
    <path
      d='M110.102 68.6945C110.107 68.5734 110.118 68.4523 110.118 68.3312C110.121 63.146 110.121 57.9611 110.119 52.7763C110.119 52.6709 110.111 52.565 110.108 52.4596C110.258 52.4487 110.41 52.4281 110.558 52.4275C113.871 52.4275 117.185 52.4275 120.498 52.4275C120.645 52.4275 120.791 52.4402 120.975 52.4487V57.8472H164.632C164.662 57.2556 164.642 56.6567 164.646 56.059C164.649 55.4613 164.646 54.8782 164.646 54.2884V52.4511H208.145V57.8739H208.733C221.063 57.8739 233.393 57.8739 245.724 57.8739C245.92 57.8739 246.116 57.8872 246.312 57.8938C246.312 63.1359 246.312 68.3778 246.312 73.6195C246.312 73.8012 246.3 73.9828 246.293 74.1645C246.097 74.1645 245.9 74.1609 245.704 74.1609H208.168V71.4413C208.168 70.9872 208.171 70.533 208.168 70.0794C208.164 69.6259 208.188 69.1844 208.151 68.7424H164.576V74.0955C164.026 74.2008 121.356 74.179 120.996 74.0719C120.996 73.1932 120.996 72.3024 120.996 71.4117C120.996 70.5209 120.996 69.6386 120.996 68.7054H110.134L110.102 68.6945Z'
      fill='#5AF1B0'
    />
    <path
      d='M246.291 74.1645C246.298 74.3153 246.31 74.4673 246.31 74.6169C246.31 81.5606 286.31 88.5043 286.31 95.448V95.9324H280.867V90.526H275.424V85.0924H253.641C253.635 85.245 253.623 85.4036 253.623 85.5629C253.623 87.0461 253.623 88.5289 253.623 90.0113C253.623 90.1772 253.61 90.3425 253.603 90.5085H208.161V85.1269C207.622 85.0149 164.932 85.0361 164.577 85.1481V87.8125C164.577 88.251 164.582 88.69 164.577 89.129C164.571 89.568 164.608 90.0271 164.551 90.4758H120.985V85.0536H110.139C110.129 84.9041 110.109 84.7539 110.109 84.6037C110.109 79.4395 110.109 74.2749 110.109 69.11C110.109 68.9749 110.125 68.8399 110.134 68.7048H120.994C120.994 69.638 120.994 70.5245 120.994 71.4111C120.994 72.2976 120.994 73.1926 120.994 74.0713C121.354 74.1785 164.024 74.2003 164.574 74.0949V68.7418H208.148C208.185 69.1838 208.16 69.6319 208.165 70.0788C208.169 70.5257 208.165 70.9872 208.165 71.4407V74.1591H245.699C245.898 74.1609 246.094 74.1633 246.291 74.1645Z'
      fill='#006FFF'
    />
    <path
      d='M219.075 106.855L247.838 106.873H248.504V112.271C248.988 112.372 249.439 112.309 249.884 112.32C250.33 112.331 250.793 112.32 251.246 112.32H253.955V117.765C254.595 117.776 255.193 117.768 255.791 117.77C256.389 117.772 257.002 117.77 257.608 117.77H264.865V123.202C264.699 123.208 264.533 123.22 264.367 123.22C253.821 123.22 219.274 123.22 208.728 123.22H208.179V117.796H164.6V123.201H121.018V117.774H110.671C110.489 117.774 110.307 117.765 110.126 117.76C110.117 117.595 110.102 117.429 110.102 117.263C110.102 112.162 110.102 107.06 110.102 101.959C110.102 101.777 110.127 101.599 110.141 101.42L121.014 101.426V106.821C121.589 106.913 164.236 106.888 164.602 106.791V101.475C165.199 101.38 207.83 101.406 208.189 101.506V106.873H218.619C218.773 106.871 218.924 106.859 219.075 106.855Z'
      fill='#2FBE81'
    />
    <path
      d='M254.071 106.851C253.919 106.856 253.768 106.867 253.618 106.867H208.188V101.501C207.828 101.401 165.197 101.376 164.6 101.47V106.787C164.237 106.884 121.588 106.908 121.013 106.816V101.421L110.14 101.415C110.129 101.25 110.109 101.085 110.109 100.92C110.109 95.7974 110.109 90.6748 110.109 85.5518C110.109 85.3864 110.129 85.2217 110.14 85.0564H120.985V90.4755H164.55C164.607 90.0268 164.569 89.5763 164.575 89.1288C164.582 88.6813 164.575 88.2507 164.575 87.8123V85.1479C164.931 85.0358 207.621 85.0146 208.16 85.1267V90.5082H248.6C248.607 90.6899 248.62 90.8716 248.62 91.0502C248.62 94.3287 248.62 97.6072 248.62 100.886V101.405H253.949C254.003 101.448 254.016 101.456 254.026 101.466C254.037 101.475 254.048 101.489 254.049 101.501C254.057 103.286 254.064 105.069 254.071 106.851Z'
      fill='#A682F2'
    />
    <g filter='url(#filter0_d_1_24803)'>
      <ellipse cx='260.5' cy='101.998' rx='62.5' ry='63' fill='#172A2D' />
      <circle
        cx='258.5'
        cy='103.498'
        r='60.5'
        fill='url(#paint0_linear_1_24803)'
      />
    </g>
    <g filter='url(#filter1_d_1_24803)'>
      <path
        d='M293.787 90.7519C292.087 103.438 280.316 103.307 274.3 110.631C264.81 122.463 267.863 133.017 260.367 136.274C259.415 136.688 258.319 136.688 257.368 136.273C249.871 133.003 252.925 122.332 243.434 110.501C237.68 103.046 225.778 103.307 224.209 90.4904C222.901 80.8122 228.002 74.7961 232.056 71.0033C232.318 70.7417 229.571 80.2891 233.756 86.1744C237.418 91.1443 247.489 94.0216 252.459 97.8144C258.344 102.523 258.998 107.623 258.998 107.493C259.129 107.493 259.913 102.523 265.537 98.0759C270.507 94.2832 280.578 91.4059 284.24 86.436C288.425 80.6814 285.678 71.1341 285.94 71.3957C290.125 75.1885 295.095 81.2046 293.787 90.7519Z'
        fill='#5AF1B0'
      />
    </g>
    <defs>
      <filter
        id='filter0_d_1_24803'
        x='183'
        y='31.998'
        width='147'
        height='148'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dx='-4' dy='4' />
        <feGaussianBlur stdDeviation='5.5' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.31 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1_24803'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_1_24803'
          result='shape'
        />
      </filter>
      <filter
        id='filter1_d_1_24803'
        x='208.838'
        y='55.8359'
        width='102.94'
        height='98.5262'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dx='1.30786' dy='1.30786' />
        <feGaussianBlur stdDeviation='8.235' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.317647 0 0 0 0 0.764706 0 0 0 0 0.572549 0 0 0 0.46 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1_24803'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_1_24803'
          result='shape'
        />
      </filter>
      <linearGradient
        id='paint0_linear_1_24803'
        x1='258.5'
        y1='42.998'
        x2='258.5'
        y2='163.998'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#121920' />
        <stop offset='1' stopColor='#06080B' />
      </linearGradient>
    </defs>
  </svg>
);

export default AproxEquality;
