const StakedBalanceIcon = ({
  fill,
  ...rest
}: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.7481 15.285H10.6761C10.2215 15.285 9.88091 14.8696 9.96903 14.4249L10.4229 12.1381C10.49 11.801 10.7858 11.5583 11.1292 11.5583H14.2942C14.6383 11.5583 14.9342 11.801 15.0013 12.1381L15.4545 14.4249C15.5432 14.8696 15.202 15.285 14.7481 15.285Z'
        stroke={fill}
        strokeWidth='1.07813'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.32429 15.285H3.2522C2.79765 15.285 2.45708 14.8696 2.5452 14.4249L2.99905 12.1381C3.06618 11.801 3.36199 11.5583 3.70535 11.5583H6.87044C7.2145 11.5583 7.51031 11.801 7.57745 12.1381L8.03062 14.4249C8.11939 14.8696 7.77813 15.285 7.32429 15.285Z'
        stroke={fill}
        strokeWidth='1.07813'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.7715 9.82063H6.69947C6.24492 9.82063 5.90435 9.40526 5.99246 8.9605L6.44632 6.67372C6.51345 6.33665 6.80926 6.09399 7.15262 6.09399H10.3177C10.6618 6.09399 10.9576 6.33665 11.0247 6.67372L11.4779 8.9605C11.5667 9.40526 11.2254 9.82063 10.7715 9.82063Z'
        stroke={fill}
        strokeWidth='1.07813'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.6543 4.90406C3.21934 5.08728 3.66201 5.52994 3.84522 6.09499C4.02844 5.52994 4.4711 5.08728 5.03615 4.90406C4.4711 4.72084 4.02844 4.27818 3.84522 3.71313C3.66201 4.27818 3.21934 4.72084 2.6543 4.90406Z'
        stroke={fill}
        strokeWidth='1.07813'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default StakedBalanceIcon;
