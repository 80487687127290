import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import authReducer from '@/store/auth';
import boosterReducer from '@/store/booster';
import drawerReducer from '@/store/drawer';
import governanceReducer from '@/store/governance';
import indexerReducer from '@/store/indexer';
import landingReducer from '@/store/landing';
import lendAppReducer from '@/store/lend-app';
import liquidLockingAppReducer from '@/store/liquid-locking-app';
import liquidStakingAppReducer from '@/store/liquid-staking-app';
import liquidStakingLandingReducer from '@/store/liquid-staking-landing';
import liquidStakingTaoAppReducer from '@/store/liquid-staking-tao-app';
import loaderReducer from '@/store/loader';
import marketReducer from '@/store/market';
import networkReducer from '@/store/network';
import popupReducer from '@/store/popup';
import priceReducer from '@/store/price';
import protocolReducer from '@/store/protocol';
import queueReducer from '@/store/queue';
import rewardBatchReducer from '@/store/reward-batch';
import sidebarReducer from '@/store/sidebar';
import transactionReducer from '@/store/transaction';

export const store = configureStore({
  reducer: {
    // common
    drawer: drawerReducer,
    loader: loaderReducer,
    network: networkReducer,
    auth: authReducer,
    popup: popupReducer,
    transaction: transactionReducer,
    indexer: indexerReducer,
    sidebar: sidebarReducer,
    rewardBatch: rewardBatchReducer,
    protocol: protocolReducer,
    boosterBatch: boosterReducer,

    // pages
    governance: governanceReducer,
    liquidStakingLanding: liquidStakingLandingReducer,
    liquidStakingApp: liquidStakingAppReducer,
    liquidLockingApp: liquidLockingAppReducer,
    liquidStakingTaoApp: liquidStakingTaoAppReducer,
    queue: queueReducer,
    price: priceReducer,
    market: marketReducer,
    landing: landingReducer,
    lendApp: lendAppReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type GetRootState = () => RootState;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
