import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { Trans, useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useRef, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { useLogin } from '@/hooks/auth/useLogin';
import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';
import useLendInteraction from '@/hooks/interaction/useLendInteraction';
import useMediaQuery from '@/hooks/useMediaQuery';
import useTailwindBreakpoint from '@/hooks/useTailwindBreakpoint';
import useTheme from '@/hooks/useTheme';
import useToggle from '@/hooks/useToggle';

import ClosePopupBtn from '@/components/ClosePopupBtn';
import CurrencyInputButton from '@/components/CurrencyInputButton';
import Hint from '@/components/Hint';
import ApproEqualIcon from '@/components/Icons/ApproEqualIcon';
import FutureStatsRightArrow from '@/components/Icons/FutureStatsRightArrow';
import InfoIcon1 from '@/components/Icons/InfoIcon1';
import LabeledImage from '@/components/LabeledImage';
import BoostStatus from '@/components/popups/HTMBoosterPopup/components/BoostStatus';
import GlobalBoostItem from '@/components/popups/HTMBoosterPopup/components/GlobalBoostItem';
import ProgressItem from '@/components/popups/HTMBoosterPopup/components/ProgressItem';
import PopupBed from '@/components/popups/PopupBed';
import PopupHead from '@/components/popups/PopupBed/components/PopupHead';
import OperationFormBottomAlerts from '@/components/popups/SupplyPopup/OperationFormBottomAlerts';
import Tabber from '@/components/Tabber';
import TransactionButton from '@/components/TransactionButton';
import WrappedLottiePlayer from '@/components/WrappedLottiePlayer';

import { useAppSelector } from '@/store';
import { boosterSelector } from '@/store/booster';
import { lendAppSelector } from '@/store/lend-app';
import { getRouteGroup, popupSelector } from '@/store/popup';
import {
  H_TOKEN_DECIMALS,
  htmMarketSelector,
  MARKET_KEY,
  nativeUserBalanceSelector,
  protocolSelector,
} from '@/store/protocol';
import {
  hasPendingTransactionsSelector,
  TRANSACTION_SUBGROUP_TYPE,
} from '@/store/transaction';

import ClaimsAndRequests from '@/sections/Lend/MyPosition/components/Claims&Requests';
import clsxm from '@/services/clsxm';
import { formatNumber, nFormatter, subtractGasFee } from '@/utils/helpers';
import { calculatePercentageBoost } from '@/utils/math/booster';

enum TABS {
  SUPPLY = 'stake',
  WITHDRAW = 'unstake',
}

const SUPPLY_TABS = [TABS.SUPPLY, TABS.WITHDRAW];

const getTabIndex = (id: TABS) => {
  return SUPPLY_TABS.indexOf(id as TABS);
};
type Props = {
  isLiquid?: boolean;
};
const HTMBoosterPopup = (props: Props) => {
  const router = useRouter();
  const { isLiquid } = props;

  const { data } = useAppSelector(popupSelector);
  const { userBalances, markets } = useAppSelector(protocolSelector);
  const isMdAndAbove = useTailwindBreakpoint('md');

  const { totalStaked, boosterAccount, percentageBoost, maximumApyBooster } =
    useAppSelector(boosterSelector);
  const htmMarket = useAppSelector(htmMarketSelector);

  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const { isDark } = useTheme();
  const { t } = useTranslation();
  const { isLoggedIn } = useLogin();
  const { signTransactions } = useSignMultipleTransactions();
  const { markets: lendMarkets } = useAppSelector(lendAppSelector);
  const htmMarketDecimals = htmMarket.underlying.decimals;

  const [selectedTab, setSelectedTab] = useState<TABS>(data.tab || TABS.SUPPLY);
  const [maxSelected, setMaxSelected] = useState<boolean>(false);
  const [optimalBoost, setOptimalBoost] = useState<boolean>(false);
  const [left, setLeft] = useState(0);
  const [inputValue, setInputValue] = useState<string>('0');
  const [inputValueAsBigNumber, setInputValueAsBigNumber] =
    useState<string>('0');

  const nativeUserBalance = useAppSelector(nativeUserBalanceSelector);
  const { underlyingBalance: accountBalanceEGLD } = nativeUserBalance;

  const isSupplying = selectedTab === TABS.SUPPLY;
  const isWithdrawing = selectedTab === TABS.WITHDRAW;

  const { stakeHtm, unstakeHtm } = useLendInteraction();

  const isRemainingValue = (value: string) =>
    new DefiUtils(value).isLessThanOrEqualTo('0.999999999999999999') &&
    !new DefiUtils(value).isEqualTo('0');

  const onTabToggle = (tabNumber: number) => {
    setSelectedTab(SUPPLY_TABS[tabNumber]);
    setOptimalBoost(false);
  };

  const enoughEGLDBalance = useMemo(
    () => new DefiUtils(subtractGasFee(accountBalanceEGLD)).isGreaterThan(0),
    [accountBalanceEGLD],
  );

  const valueTooLow = useMemo(() => {
    if (isSupplying) {
      return new DefiUtils(inputValueAsBigNumber)
        .toFullDecimals(htmMarketDecimals)
        .isLessThanOrEqualTo(1e-18);
    }
    return false;
  }, [htmMarketDecimals, inputValueAsBigNumber, isSupplying]);

  const { HTM } = userBalances;
  const htmBalance = new DefiUtils(HTM?.underlyingBalance).toFullDecimals(
    htmMarketDecimals,
  );
  const enoughWalletBalance = useMemo(() => {
    if (isSupplying) {
      return htmBalance.isGreaterThanOrEqualTo(inputValue);
    }

    return true;
  }, [htmBalance, inputValue, isSupplying]);

  const enoughStakedBalance = useMemo(() => {
    if (isWithdrawing) {
      return new DefiUtils(totalStaked.totalStaked).isGreaterThanOrEqualTo(
        inputValue,
      );
    }

    return true;
  }, [inputValue, isWithdrawing, totalStaked.totalStaked]);

  const getActionButtonDisabled = (): boolean =>
    !isLoggedIn ||
    new DefiUtils(inputValue).isZero() ||
    !enoughEGLDBalance ||
    valueTooLow ||
    !enoughWalletBalance ||
    !enoughStakedBalance;

  const getActionButtonLabel = (): string => {
    const isValueEqualZero = new DefiUtils(inputValue).isZero();
    if (isSupplying) {
      if (isValueEqualZero) return t('Stake');
      if (valueTooLow) return t('the-amount-is-too-low');
      if (!enoughWalletBalance) return t('Insufficient balance');
      if (!enoughEGLDBalance) return t('No gas available');
      return t('Stake');
    }

    if (isWithdrawing) {
      if (isValueEqualZero) return t('Unstake');
      if (!enoughEGLDBalance) return t('No gas available');
      if (!enoughStakedBalance) return t('Insufficient balance');
      return t('Unstake');
    }

    return t('unknown');
  };
  const [actionButton, setActionButton] = useState({
    disabled: true,
    label: getActionButtonLabel(),
  });

  const calcSafeLeft = (inputValueAsBigNumber: string) => {
    let totalStakedDynamic = new DefiUtils(totalStaked.totalStaked)
      .toBasicUnits(htmMarket.underlying.decimals)
      .plus(inputValueAsBigNumber)
      .toString();

    if (isWithdrawing)
      totalStakedDynamic = new DefiUtils(totalStaked.totalStaked)
        .toBasicUnits(htmMarket.underlying.decimals)
        .minus(inputValueAsBigNumber)
        .toString();

    const percentage = new DefiUtils(
      calculatePercentageBoost({
        boosterAccount,
        htmPrice: htmMarket.underlying.priceUSD,
        totalStaked: totalStakedDynamic,
        markets,
        userBalances,
        maximumApyBooster,
      }),
    );

    return percentage.isGreaterThan(100)
      ? 100
      : percentage.isLessThan(0)
      ? 0
      : percentage.toNumber();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleInputValueChange = (value: any) => {
    const inputValueAsBigNumber = new DefiUtils(value)
      .multipliedBy(`1e${htmMarketDecimals}`)
      .toString();
    setInputValue(value);
    setInputValueAsBigNumber(inputValueAsBigNumber);

    setLeft(calcSafeLeft(inputValueAsBigNumber));
  };

  const getInitialLeft = () => {
    if (new DefiUtils(percentageBoost).isGreaterThan(100)) {
      return 100;
    } else {
      return +new DefiUtils(percentageBoost).toFixed(2);
    }
  };

  useEffect(() => {
    setMaxSelected(false);
    setInputValue('0');
    setInputValueAsBigNumber('0');
    setLeft(getInitialLeft());
    // eslint-disable-next-line
  }, [selectedTab]);

  useEffect(() => {
    setActionButton({
      disabled: getActionButtonDisabled(),
      label: getActionButtonLabel(),
    });
    // eslint-disable-next-line
  }, [selectedTab, inputValueAsBigNumber]);

  const handleButtonClick = async () => {
    if (hasPendingTransactions) {
      return;
    }

    if (selectedTab === TABS.SUPPLY) {
      await signTransactions(
        [
          stakeHtm({
            amountAsBigNumber: inputValueAsBigNumber,
          }),
        ],
        {
          token: MARKET_KEY.HTM,
          group: getRouteGroup(),
          subgroup: TRANSACTION_SUBGROUP_TYPE.STAKE_HTM,
          result: inputValue,
        },
      );
    } else {
      await signTransactions(
        [
          unstakeHtm({
            amountAsBigNumber: inputValueAsBigNumber,
          }),
        ],
        {
          token: MARKET_KEY.HTM,
          group: getRouteGroup(),
          subgroup: TRANSACTION_SUBGROUP_TYPE.UNSTAKE_HTM,
          result: inputValue,
        },
      );
    }
  };

  useEffect(() => {
    if (!maxSelected) {
      setInputValue('0');
      setInputValueAsBigNumber('0');
      setLeft(getInitialLeft());
      return;
    }

    if (isSupplying) {
      setInputValue(
        new DefiUtils(userBalances.HTM?.underlyingBalance)
          .toFullDecimals(htmMarketDecimals)
          .toString(),
      );
      setInputValueAsBigNumber(userBalances.HTM?.underlyingBalance);
      setLeft(calcSafeLeft(userBalances.HTM?.underlyingBalance));
      return;
    }

    setInputValue(totalStaked.totalStaked);
    setInputValueAsBigNumber(
      new DefiUtils(totalStaked.totalStaked)
        .toBasicUnits(htmMarketDecimals)
        .toString(),
    );
    setLeft(
      calcSafeLeft(
        new DefiUtils(totalStaked.totalStaked)
          .toBasicUnits(htmMarketDecimals)
          .toString(),
      ),
    );
  }, [maxSelected]);

  const floatMinValue = new DefiUtils('1')
    .dividedBy(`1e${htmMarketDecimals}`)
    .toString();

  const currencyInputValue =
    htmMarketDecimals < H_TOKEN_DECIMALS &&
    isRemainingValue(inputValueAsBigNumber) &&
    maxSelected
      ? floatMinValue
      : inputValue;

  const md = useMediaQuery('(min-width: 768px)');
  const lg1 = useMediaQuery('(min-width: 1024px)');
  const lg = useMediaQuery('(min-width: 1280px)');

  const [hasFocus, toggleFocus] = useToggle();

  const handleFocus = () => {
    if (maxSelected) setInputValue('0');
    if (optimalBoost) setInputValue('0');
    toggleFocus();
    setOptimalBoost(false);
    setMaxSelected(false);
  };

  const valueInUsd = useMemo(() => {
    return new DefiUtils(inputValue)
      .multipliedBy(htmMarket.underlying.priceUSD)
      .toString();
  }, [inputValue, htmMarket]);

  const totalStakedFormatted = formatNumber(totalStaked.totalStaked, 2);
  const totalStakedUsdFormatted = formatNumber(totalStaked.totalStakedUsd, 2);
  const totalHtmInWallet = formatNumber(
    new DefiUtils(userBalances.HTM?.underlyingBalance)
      .toFullDecimals(htmMarketDecimals)
      .toString(),
    2,
  );

  const futureStats = useMemo(() => {
    let htm = new DefiUtils(totalStaked.totalStaked);
    let usd = new DefiUtils(totalStaked.totalStakedUsd);
    const priceUsd = new DefiUtils(inputValue).multipliedBy(
      htmMarket.underlying.priceUSD,
    );
    if (selectedTab === TABS.SUPPLY) {
      htm = htm.plus(inputValue);
      usd = usd.plus(priceUsd);
    }
    if (selectedTab === TABS.WITHDRAW) {
      htm = htm.minus(inputValue);
      usd = usd.minus(priceUsd);
      usd = usd.isNegative() ? new DefiUtils(0) : usd;
    }

    return {
      usd: formatNumber(usd.toString(), 2),
      htm: formatNumber(htm.toString(), 2),
    };
  }, [
    htmMarket.underlying.priceUSD,
    inputValue,
    selectedTab,
    totalStaked.totalStaked,
    totalStaked.totalStakedUsd,
  ]);

  const dynamicPercentageBoost = useMemo(() => {
    if (new DefiUtils(inputValue).isZero()) {
      return percentageBoost;
    }

    let totalStakedDynamic = new DefiUtils(totalStaked.totalStaked)
      .toBasicUnits(htmMarket.underlying.decimals)
      .plus(inputValueAsBigNumber)
      .toString();

    if (isWithdrawing)
      totalStakedDynamic = new DefiUtils(totalStaked.totalStaked)
        .toBasicUnits(htmMarket.underlying.decimals)
        .minus(inputValueAsBigNumber)
        .toString();

    const percentage = new DefiUtils(
      calculatePercentageBoost({
        boosterAccount,
        htmPrice: htmMarket.underlying.priceUSD,
        totalStaked: totalStakedDynamic,
        markets,
        userBalances,
        maximumApyBooster,
      }),
    ).toString();

    return percentage;
  }, [
    boosterAccount,
    htmMarket.underlying.decimals,
    htmMarket.underlying.priceUSD,
    inputValue,
    inputValueAsBigNumber,
    isWithdrawing,
    markets,
    maximumApyBooster,
    percentageBoost,
    totalStaked.totalStaked,
    userBalances,
  ]);

  const footerNotes = useMemo(() => {
    return {
      [TABS.SUPPLY]: [
        {
          label: t('wallet-balance'),
          value: totalHtmInWallet,
        },
      ],
      [TABS.WITHDRAW]: [
        {
          label: t('Staked Balance (HTM)'),
          value: totalStakedFormatted,
        },
      ],
    };
  }, [t, totalStakedFormatted, totalHtmInWallet]);

  const showFutureStats = useMemo(() => {
    const hasStaked = isSupplying
      ? true
      : !new DefiUtils(totalStaked.totalStaked).isZero();
    return (
      new DefiUtils(inputValue).isGreaterThan(0) &&
      enoughStakedBalance &&
      hasStaked
    );
  }, [isSupplying, totalStaked, inputValue, enoughStakedBalance]);

  const boosterByMoneyMarket = Object.entries(boosterAccount)
    .map((a) => {
      return {
        market: a[0],
        ...a[1],
      };
    })
    .filter(
      (booster) =>
        new DefiUtils(booster.speed).isGreaterThan(0) &&
        !new DefiUtils(
          userBalances[booster.market as MARKET_KEY].collateralBalance,
        ).isZero(),
    );

  const boosterByMoneyMarketWithoutSEgld = useMemo(() => {
    if (!isLiquid) return boosterByMoneyMarket;
    return boosterByMoneyMarket?.filter((b) => b.market !== 'SEGLD');
  }, [boosterByMoneyMarket, isLiquid]);

  const sEgldBoosterMarket = useMemo(() => {
    return boosterByMoneyMarket?.filter((b) => b.market === 'SEGLD');
  }, [boosterByMoneyMarket]);

  const totalStakedDynamic = useMemo(() => {
    if (new DefiUtils(inputValue).isEqualTo(0)) {
      return totalStaked.totalStaked;
    }

    let totalStakedDynamic = new DefiUtils(totalStaked.totalStaked)
      .plus(inputValue)
      .toString();

    if (isWithdrawing)
      totalStakedDynamic = new DefiUtils(totalStaked.totalStaked)
        .minus(inputValue)
        .toString();

    return new DefiUtils(totalStakedDynamic).isLessThan(0)
      ? '0'
      : totalStakedDynamic;
  }, [inputValue, isWithdrawing, totalStaked]);

  const totalCollateralUSD = useMemo(
    () =>
      Object.values(markets)
        .reduce((prev, current) => {
          const marketId = current.underlying.symbol;
          const marketsWithSpeed = boosterByMoneyMarket
            // .filter((moneyMarketItem) =>
            //   new DefiUtils(moneyMarketItem.speed).isGreaterThan(0),
            // )
            .map((moneyMarketItem) => moneyMarketItem.market);

          if (!marketsWithSpeed.includes(marketId)) {
            return prev.plus(0);
          }
          const collateralMM =
            userBalances[current.underlying.symbol as MARKET_KEY]
              ?.collateralBalance;

          const calc = new DefiUtils(collateralMM)
            .toUnderlying(current.hTokenExchangeRate)
            .toUSD(current.underlying.priceUSD)
            .toFullDecimals(current.underlying.decimals);

          return prev.plus(calc);
        }, new DefiUtils(0))
        .toString(),
    [boosterByMoneyMarket, markets, userBalances],
  );

  const getPercentageBoosterTextColor = () => {
    if (
      new DefiUtils(dynamicPercentageBoost).isGreaterThanOrEqualTo(100) &&
      new DefiUtils(dynamicPercentageBoost).isLessThan(150)
    ) {
      return 'text-[#37CE86]'; // green
    }
    if (new DefiUtils(dynamicPercentageBoost).isGreaterThanOrEqualTo(150)) {
      return 'text-[#2B8EE9]'; // blue
    }

    if (!showFutureStats) {
      return 'text-[#9E70FF]';
    }

    if (new DefiUtils(dynamicPercentageBoost).isLessThan(50)) {
      return 'text-[#E04040]';
    }
    if (new DefiUtils(dynamicPercentageBoost).isLessThan(100)) {
      return 'text-[#E4AF08]';
    }
    if (new DefiUtils(dynamicPercentageBoost).isLessThan(150)) {
      return 'text-[#37CE86]';
    }

    return 'text-[#2B8EE9]';
  };

  const getStaticPercentageBoosterTextColor = () => {
    if (
      new DefiUtils(percentageBoost).isGreaterThanOrEqualTo(100) &&
      new DefiUtils(percentageBoost).isLessThan(150)
    ) {
      return 'text-[#37CE86]'; // green
    }
    if (new DefiUtils(percentageBoost).isGreaterThanOrEqualTo(150)) {
      return 'text-[#2B8EE9]'; // blue
    }

    return 'text-[#9E70FF]';
  };

  const getPercentageBoosterBgColor = () => {
    if (
      new DefiUtils(dynamicPercentageBoost).isGreaterThanOrEqualTo(100) &&
      new DefiUtils(dynamicPercentageBoost).isLessThan(150)
    ) {
      return !isDark ? 'bg-[#DDFFF1]' : 'bg-[#346560CC]'; // green
    }
    if (new DefiUtils(dynamicPercentageBoost).isGreaterThanOrEqualTo(150)) {
      return !isDark ? 'bg-[#DDEFFF]' : 'bg-[#2F486F]'; // blue
    }

    if (!showFutureStats) {
      return !isDark ? 'bg-[#ECE3FF]' : 'bg-[#453468]';
    }

    if (new DefiUtils(dynamicPercentageBoost).isLessThan(50)) {
      return !isDark ? 'bg-[#FFE5DD]' : 'bg-[#6E2F2F]'; // red
    }
    if (new DefiUtils(dynamicPercentageBoost).isLessThan(100)) {
      return isDark ? 'bg-[#FFD23E66]' : 'bg-[#FFF8DD]'; // yellow
    }

    return !isDark ? 'bg-[#DDEFFF]' : 'bg-[#2F486F]'; // blue
  };

  const showOptimalButton = useMemo(() => {
    const totalStakedDynamic = new DefiUtils(totalStaked.totalStaked)
      .multipliedBy(1e18)
      .plus(htmBalance.multipliedBy(1e18))
      .toString();

    const percentage = new DefiUtils(
      calculatePercentageBoost({
        boosterAccount,
        htmPrice: htmMarket.underlying.priceUSD,
        totalStaked: totalStakedDynamic,
        markets,
        userBalances,
        maximumApyBooster,
      }),
    ).dividedBy(maximumApyBooster);

    if (isSupplying)
      return (
        percentage.isGreaterThanOrEqualTo(100) &&
        new DefiUtils(percentageBoost).isLessThan(100)
      );
  }, [
    boosterAccount,
    htmBalance,
    htmMarket.underlying.priceUSD,
    isSupplying,
    markets,
    maximumApyBooster,
    percentageBoost,
    totalStaked.totalStaked,
    userBalances,
  ]);

  const showOptimalDecreaseButton = useMemo(() => {
    if (isWithdrawing) return new DefiUtils(percentageBoost).isGreaterThan(100);
  }, [isWithdrawing, percentageBoost]);

  const useOptimalBoost = () => {
    setMaxSelected(false);
    if (optimalBoost) {
      handleInputValueChange(0);
    }
    setOptimalBoost(!optimalBoost);
  };

  useEffect(() => {
    if (optimalBoost) {
      let value = new DefiUtils(totalCollateralUSD)
        .dividedBy(htmMarket.underlying.priceUSD)
        .multipliedBy(maximumApyBooster)
        .multipliedBy(1.00001)
        .minus(totalStaked.totalStaked)
        .toSafeFixed(htmMarket.underlying.decimals);

      if (isWithdrawing) {
        value = new DefiUtils(
          new DefiUtils(totalStaked.totalStaked).toSafeFixed(
            htmMarket.underlying.decimals,
          ),
        )
          .minus(
            new DefiUtils(totalCollateralUSD)
              .dividedBy(htmMarket.underlying.priceUSD)
              .multipliedBy(1.00001)
              .multipliedBy(maximumApyBooster),
          )
          .toString();
      }

      handleInputValueChange(value);
    }
  }, [
    htmMarket.underlying.decimals,
    htmMarket.underlying.priceUSD,
    isWithdrawing,
    optimalBoost,
    totalCollateralUSD,
    totalStaked.totalStaked,
    handleInputValueChange,
    maximumApyBooster,
  ]);

  const showBoostDecrease = useMemo(
    () =>
      isWithdrawing &&
      new DefiUtils(inputValue).isGreaterThan(0) &&
      new DefiUtils(totalStaked.totalStaked).isGreaterThan(0),
    [inputValue, isWithdrawing, totalStaked.totalStaked],
  );

  const totalCollateralInHtm = useMemo(() => {
    return new DefiUtils(totalCollateralUSD)
      .dividedBy(htmMarket.underlying.priceUSD)
      .multipliedBy(maximumApyBooster);
  }, [htmMarket.underlying.priceUSD, maximumApyBooster, totalCollateralUSD]);

  // Draggable Progress Bar

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startLeft, setStartLeft] = useState(22);

  const circleRef = useRef(null);
  const containerRef = useRef(null);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    const clientX = e.type.includes('touch') ? e.touches[0].clientX : e.clientX;
    setStartX(clientX);
    setStartLeft(left);
    setOptimalBoost(false);

    // For touch devices
    document.addEventListener('touchmove', handleMouseMove);
    document.addEventListener('touchend', handleMouseUp);

    // For mouse
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) {
      return;
    }

    const clientX = event.type.includes('touch')
      ? event.touches[0].clientX
      : event.clientX;
    const container = containerRef.current;
    const position =
      startLeft + ((clientX - startX) / container.clientWidth) * 100;

    const safePosition = new DefiUtils(position).isLessThan(0)
      ? '0'
      : new DefiUtils(position).isGreaterThan(100)
      ? '100'
      : position;

    const safePercentageBoost = new DefiUtils(percentageBoost).isLessThan(0)
      ? '0'
      : new DefiUtils(percentageBoost).isGreaterThan(100)
      ? '100'
      : percentageBoost;

    if (isSupplying) {
      if (new DefiUtils(safePosition).isLessThan(safePercentageBoost)) {
        setSelectedTab(TABS.WITHDRAW);
        return;
      }

      // const left = new DefiUtils(safePosition).toNumber();
      const left = new DefiUtils(safePosition).isLessThanOrEqualTo(
        safePercentageBoost,
      )
        ? new DefiUtils(safePercentageBoost).toNumber()
        : new DefiUtils(safePosition).toNumber();

      const maxTotalStaked = new DefiUtils(totalCollateralUSD)
        .dividedBy(htmMarket.underlying.priceUSD)
        .multipliedBy(maximumApyBooster)
        .toSafeFixed(htmMarket.underlying.decimals);

      const inputValue = new DefiUtils(maxTotalStaked)
        .multipliedBy(left)
        .dividedBy(100)
        .minus(totalStaked.totalStaked)
        .multipliedBy(1.00001)
        .toFixed(htmMarket.underlying.decimals);

      if (new DefiUtils(inputValue).isLessThanOrEqualTo(0)) {
        setInputValue('0');
        setInputValueAsBigNumber('0');
        setLeft(+safePercentageBoost);
        return;
      } else {
        setInputValue(inputValue);
        setInputValueAsBigNumber(
          new DefiUtils(inputValue)
            .toBasicUnits(htmMarket.underlying.decimals)
            .toString(),
        );
        setLeft(left);
        return;
      }
    }

    if (isWithdrawing) {
      if (new DefiUtils(safePosition).isGreaterThan(safePercentageBoost)) {
        setSelectedTab(TABS.SUPPLY);
        return;
      }

      const left = new DefiUtils(safePosition).isGreaterThanOrEqualTo(
        percentageBoost,
      )
        ? new DefiUtils(percentageBoost).toNumber()
        : new DefiUtils(safePosition).toNumber();

      const maxTotalStaked = new DefiUtils(totalCollateralUSD)
        .dividedBy(htmMarket.underlying.priceUSD)
        .multipliedBy(1.00001)
        .multipliedBy(maximumApyBooster)
        .toSafeFixed(htmMarket.underlying.decimals);

      const inputValue = new DefiUtils(totalStaked.totalStaked).minus(
        new DefiUtils(maxTotalStaked).multipliedBy(left).dividedBy(100),
      );

      if (inputValue.isLessThanOrEqualTo(0)) {
        setInputValue('0');
        setInputValueAsBigNumber('0');
        setLeft(+safePercentageBoost);
        return;
      } else {
        handleInputValueChange(inputValue.toNumber());
        setLeft(left);
        return;
      }
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);

    document.removeEventListener('touchmove', handleMouseMove);
    document.removeEventListener('touchend', handleMouseUp);

    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('touchmove', handleMouseMove);
      document.addEventListener('touchend', handleMouseUp);

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchend', handleMouseUp);
      document.removeEventListener('touchmove', handleMouseMove);
    };
  }, [isDragging, selectedTab]);

  const showBannerApy = useMemo(() => {
    const marketWithSupply = boosterByMoneyMarket.find((mm) => {
      return new DefiUtils(
        lendMarkets[
          mm.market as MARKET_KEY
        ].accountBalances.underlyingHTokenWallet,
      ).isGreaterThan(0);
    });
    return (
      new DefiUtils(totalStaked.totalStaked).isGreaterThan(0) &&
      marketWithSupply !== undefined
    );
  }, [boosterByMoneyMarket, lendMarkets, totalStaked.totalStaked]);

  return (
    <PopupBed
      close={false}
      className={clsxm(
        !md && '!max-w-[370px] h-[100vh] rounded-none',
        md && !lg1 && '!min-w-[110vw]',
        lg1 && !lg && '!min-w-[80vw]',
      )}
      enableScaling={!lg && md ? true : false}
      width={!md ? '100vw' : 'auto'}
      scaleClassName={clsxm(!lg && md && '!scale-[80%]')}
    >
      <div
        className={clsxm(
          'flex flex-col overflow-x-hidden md:flex-row md:min-h-[544px]',
          isDragging && 'select-none',
        )}
      >
        <div
          className={clsxm(
            'w-full font-semibold leading-tight tracking-normal',
            isLiquid
              ? 'md:min-w-[373px] md:max-w-[373px]'
              : 'md:min-w-[403px] md:max-w-[403px]',
          )}
        >
          <PopupHead
            closeBtn={false}
            className={clsxm(
              'translate-y-[3px]',
              isLiquid && 'translate-x-[0.5px] translate-y-px',
              !md && '-translate-x-[1px] -translate-y-px',
            )}
          >
            <LabeledImage
              name='HTM Booster'
              path='https://cdn.app.hatom.com/images/img42.svg'
              night='https://cdn.app.hatom.com/images/img42.svg'
              imageContainerClassName={clsxm(
                isLiquid && 'w-[37px] h-[37px]',
                !md && 'w-[32px] h-[32px]',
              )}
              className={clsxm('translate-x-px -translate-y-[0.5px] !gap-2')}
              imageClassName={clsxm(
                isLiquid && 'w-[29.64px] h-[29.64px]',
                !md && '!w-[25.6px] !h-[25.6px]',
              )}
              textClassName={clsxm(
                '-translate-y-[2px]',
                isLiquid && 'text-[17px] -translate-y-[1.5px]',
                !md && 'text-[14px] -translate-y-[1px]',
              )}
            />
          </PopupHead>

          <>
            <div
              className={clsxm(
                'relative mx-2.5 md:my-[24px] lg:my-[41px]',
                isLiquid && 'my-[18px] md:my-[30px]',
                !md && 'my-0 mx-1',
              )}
            >
              <div>
                <div className='relative translate-y-[5.5px] h-[62px]'>
                  <input type='text' style={{ display: 'none' }} />
                  <Hint
                    autoReplace
                    unvisible={!maxSelected && !optimalBoost}
                    content={
                      <span className='text-[8px] md:text-[10px]'>
                        {maxSelected
                          ? t('maximum-amount-is-selected')
                          : 'Optimal boost is selected'}
                      </span>
                    }
                    placement='top-center'
                    block
                  >
                    <CurrencyInput
                      data-testid='supplyInput'
                      autoFocus={false}
                      className={classNames(
                        'translate-y-px bg-transparent',
                        'w-full max-w-[442px]',
                        'p-4 px-[70px]',
                        'text-center text-[28px] font-normal leading-tight placeholder:text-[#6A6A8C]  disabled:text-[#6A6A8C] dark:text-indigo-100 placeholder:dark:text-[#625E8F] disabled:dark:text-[#625E8F]',
                        'placeholder:text-current',
                        (maxSelected || optimalBoost) &&
                          '!text-[#6A6A8C] !opacity-100 dark:!text-[#625E8F]',
                        'outline-none',
                        'absolute left-1/2 -translate-x-1/2',
                        !md && '!text-[24px]',
                      )}
                      suffix={
                        !hasFocus && !parseFloat(inputValue) ? ` HTM` : ''
                      }
                      onFocus={handleFocus}
                      onBlur={handleFocus}
                      placeholder='0'
                      value={currencyInputValue}
                      autoComplete='off'
                      aria-autocomplete='none'
                      decimalsLimit={htmMarketDecimals}
                      groupSeparator=','
                      decimalSeparator='.'
                      allowNegativeValue={false}
                      onValueChange={(x) => handleInputValueChange(x ?? '0')}
                      inputMode='text'
                      maxLength={18}
                    />
                  </Hint>
                  <div
                    className={clsxm(
                      'absolute right-[11px] top-[53%] grid -translate-y-[53%] place-items-end',
                      isLiquid &&
                        'right-[16px] top-[60%] grid -translate-y-[55%] md:right-[9px] md:top-[55%]',
                      !md && 'top-[57%]',
                    )}
                  >
                    <Hint
                      content={
                        <span className='text-[8px] md:text-[10px]'>
                          {t('set-maximum')}
                        </span>
                      }
                      placement={md ? 'top-center' : 'top-end'}
                    >
                      <CurrencyInputButton
                        onClick={() => {
                          setMaxSelected(!maxSelected);
                          setOptimalBoost(false);
                        }}
                        className={clsxm(
                          'text-[10px] leading-tight md:text-[14px]',
                          isLiquid &&
                            '!py-1 px-[12px] md:p-[8.5px] md:py-[3px] md:text-[13px]',
                          !md && 'px-[8px] text-[12px]',
                        )}
                        selected={maxSelected}
                      >
                        MAX
                      </CurrencyInputButton>
                    </Hint>
                  </div>
                </div>
                <div
                  className={clsxm(
                    'mt-5 flex translate-y-[16px] lg:translate-y-[33px] translate-x-px justify-between',
                    isLiquid &&
                      'translate-y-[7px] translate-x-0.5 md:translate-y-[27.5px]',
                    !md && '-translate-y-[7.5px] translate-x-[2.5px]',
                  )}
                >
                  <div className='EstimatedPrice'>
                    <div
                      className={clsxm(
                        'text-[9px] font-semibold dark:text-white md:text-xs',
                        isLiquid && 'text-[13px] md:text-[11px]',
                        !md && 'text-[10px]',
                      )}
                    >
                      {t('estimated-price')}
                    </div>
                    <div
                      className={clsxm(
                        'flex -translate-y-px items-center gap-1 text-[8px] text-[#6A6A8C] dark:text-[#625E8F] md:text-xs',
                        isLiquid && 'text-[13px]',
                        !md && 'text-[10px] translate-y-[0.8px]',
                      )}
                    >
                      {`${showFutureStats ? nFormatter(inputValue, 3) : 0} HTM`}
                      <ApproEqualIcon />$
                      {showFutureStats ? nFormatter(valueInUsd, 3) : 0}
                    </div>
                  </div>
                  {showOptimalDecreaseButton && (
                    <button
                      onClick={useOptimalBoost}
                      className={clsxm(
                        'flex h-[27px] w-[116px] -translate-y-2 items-center justify-center rounded-[6px] md:translate-y-[0.5px] ',
                        'text-[12px] text-[#53AE94] dark:text-[#5BE0BA]',
                        optimalBoost
                          ? 'bg-[#b2e9d2] dark:bg-[#53FFB499]  dark:text-[#DDFFF5]'
                          : 'bg-[#BDF9DF] hover:bg-[#B9F4DB] dark:bg-[#5CD9A366] dark:hover:bg-[#83FFC980] dark:hover:text-[#92FFE0]',
                        isLiquid && 'w-[80px] text-[11px] md:w-[116px]',
                        !md &&
                          'text-[10px] px-1.5 h-[21px] w-fit whitespace-nowrap -translate-x-4',
                      )}
                    >
                      {t('optimal-decrease')}
                    </button>
                  )}
                  {showOptimalButton && (
                    <button
                      onClick={useOptimalBoost}
                      className={clsxm(
                        ' h-[27px] w-[106.9px] md:h-[27px] md:w-[98px]',
                        'translate-x-[-5px] -translate-y-4 md:translate-y-[0.5px] md:translate-x-0',
                        'flex items-center justify-center ',
                        'rounded-[5px]  md:rounded-[6px]',
                        'text-[13px] text-[#53AE94] dark:text-[#5BE0BA] md:text-[12px]',
                        optimalBoost
                          ? 'bg-[#b2e9d2] dark:bg-[#53FFB499]  dark:text-[#DDFFF5]'
                          : 'bg-[#BDF9DF] hover:bg-[#B9F4DB] dark:bg-[#5CD9A366] dark:hover:bg-[#83FFC980] dark:hover:text-[#92FFE0]',
                        isLiquid &&
                          'w-[100px] md:h-[25.12px] md:w-[90px] md:translate-y-[1.5px] md:translate-x-[2px] md:text-[11px]',
                        !md &&
                          'text-[10px] px-1.5 h-[21px] w-fit whitespace-nowrap -translate-x-4',
                      )}
                    >
                      {t('optimal-boost')}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div>
              <Tabber
                onChange={onTabToggle}
                tabs={[...SUPPLY_TABS].map((key) => t(key.toLowerCase()))}
                defaultSelected={getTabIndex(selectedTab)}
                customTheme={(tabIdx) => {
                  if (Number.isInteger(tabIdx))
                    return clsxm(
                      'text-[#6431B7] dark:text-[#5F3CAD] border-current dark:border-current translate-y-[0.5px]',
                      isLiquid && 'translate-y-[1.2px]',
                      !md && 'text-[10px]',
                    );
                  else
                    return clsxm(
                      'text-[#6A6A8C] dark:text-[#625E8F] border-[#E2DFFB] dark:border-[#3F3B76] translate-y-[0.5px]',
                      isLiquid && 'translate-y-[1.2px]',
                      !md && 'text-[10px]',
                    );
                }}
              >
                <div
                  className={clsxm(
                    'bg-white pl-[21px] pr-[19px] pt-[24px] pb-2 dark:bg-[#191733]',
                    isLiquid &&
                      'pr-[11px] pl-[12px] pt-[23px] md:pl-[19px] md:pr-[17px]',
                    !md && 'pt-[17.5px] pr-[6px] pl-[6.5px]',
                  )}
                >
                  <div>
                    <div
                      className={clsxm(
                        'rounded-[8px] bg-[#E6E4FF] py-[1px] px-2 dark:bg-[#4A44A3]',
                        isLiquid && 'py-0.5 md:py-[0.5px] md:pb-[3px]',
                        !md && 'pb-0',
                      )}
                    >
                      <div
                        className={clsxm(
                          'flex items-center justify-between',
                          'mt-2 py-2 pt-0 leading-[12.5px] text-[#6A6A8C] dark:border-[#625E8F]/50',
                          'text-[10px] dark:text-white md:text-sm md:leading-tight',
                          isLiquid &&
                            'pt-0 pb-[2px] text-[15px] md:text-[13px]',
                          !md && 'text-[12px] mt-[6px]',
                        )}
                      >
                        <div>{t('htm-staked')}</div>
                        <div className='flex items-center justify-center gap-1'>
                          <div className='flex items-center gap-1 rounded-[8px] bg-[#D5D2FB] py-0.5 px-1 text-dark dark:bg-[#716BE0] dark:text-white'>
                            {totalStakedFormatted}
                            <img
                              src='https://cdn.app.hatom.com/images/img42Bis.svg'
                              alt='Green HTM'
                              className={clsxm(
                                'h-[14px] w-[14px]',
                                !md && 'h-[12px] w-[12px]',
                              )}
                            />
                          </div>
                          {showFutureStats && (
                            <>
                              <FutureStatsRightArrow
                                color={isDark ? '#716BE0' : '#6A6A8C'}
                              />
                            </>
                          )}
                          {showFutureStats && (
                            <div className='flex items-center gap-1 rounded-[8px] bg-[#D5D2FB] py-0.5 px-1 text-dark dark:bg-[#716BE0] dark:text-white'>
                              {`${futureStats.htm}`}
                              <img
                                src='https://cdn.app.hatom.com/images/img42Bis.svg'
                                alt='Green HTM'
                                className={clsxm(
                                  'h-[14px] w-[14px]',
                                  !md && 'h-[12px] w-[12px]',
                                )}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={clsxm(
                          'flex items-center justify-between border-current',
                          'py-2 pt-0 text-[10px] leading-tight text-[#6A6A8C]',
                          'dark:border-[#625E8F]/50 dark:text-white md:text-sm',
                          isLiquid && 'py-2 text-[15px] md:py-1 md:text-[13px]',
                          !md && 'text-[12px]',
                        )}
                      >
                        <div>{t('Value in USD')}</div>
                        <div className='flex items-center justify-center gap-1'>
                          <div className='flex items-center gap-1 text-dark dark:text-white'>
                            ${totalStakedUsdFormatted}
                            {showFutureStats && (
                              <>
                                <FutureStatsRightArrow
                                  color={isDark ? '#716BE0' : '#6A6A8C'}
                                />
                                {`$${futureStats.usd}`}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className={clsxm(
                        'mt-[12px]',
                        'flex',
                        'items-center',
                        'justify-between',
                        'rounded-[8px]',
                        'py-[5px]',
                        'pl-[14px] pr-[8px]',
                        getPercentageBoosterBgColor(),
                        !md && 'pl-[7.5px] py-[2.5px] mt-[10px]',
                      )}
                    >
                      <div className='flex gap-1'>
                        <div
                          className={clsxm(
                            'flex -translate-x-px items-center gap-1 md:gap-[13px]',
                            isLiquid && 'gap-3 md:gap-[12px]',
                            !md && 'gap-1.5',
                          )}
                        >
                          <Hint
                            placement={
                              isMdAndAbove ? 'top-center' : 'right-end'
                            }
                            containerClassName='md:mt-[0px] md:ml-[0px] ml-[-31px] mt-[-20px]'
                            content={
                              <div className='max-w-[279px] text-[8px] md:text-[10px] leading-[12.5px] md:max-w-[350px]'>
                                <Trans
                                  i18nKey='tooltip-4x75o'
                                  components={[
                                    <span className='text-[#E24949]' />,
                                    <span className='text-[#5AF1B0]' />,
                                  ]}
                                  ns='translation'
                                />
                              </div>
                            }
                          >
                            <img
                              src='https://cdn.app.hatom.com/images/pages/lend/htm-booster.svg'
                              alt='Htm Booster'
                              width={26}
                              height={26}
                              className={clsxm(
                                'h-[20px] w-[20px] -translate-y-[0.5px] md:h-[26px] md:w-[26px]',
                                isLiquid &&
                                  '!h-[28px] !w-[28px] -translate-y-px -translate-x-[0.5px] md:h-[24px] md:w-[24px]',
                                !md && '!w-[26px] !h-[26px]',
                              )}
                            />
                          </Hint>
                          <span
                            className={clsxm(
                              '-translate-y-px text-[10px] font-semibold text-[#1F2226] dark:text-[#FFFFFF] md:text-[14px]',
                              isLiquid &&
                                '-translate-y-[0.5px] text-[15px] md:text-[13px]',
                              !md &&
                                'text-[12px] -translate-y-[0.5px] -translate-x-px',
                            )}
                          >
                            {t('percentage-boost')}
                          </span>
                        </div>
                        <Hint
                          placement={isMdAndAbove ? 'top-center' : 'right-end'}
                          containerClassName='md:mt-[0px] md:ml-[0px] ml-[-100px] mt-[-20px]'
                          content={
                            <div className='max-w-[237px] text-[8px] md:text-[10px] leading-[12.5px] md:max-w-[296px]'>
                              <Trans
                                i18nKey='tooltip-4x75f'
                                components={[
                                  <span className='text-[#E24949]' />,
                                ]}
                                ns='translation'
                              />
                            </div>
                          }
                        >
                          <div className='relative inline-block'>
                            <InfoIcon1
                              className={clsxm(
                                'absolute ',
                                'top-0 h-[13px] w-[13px] md:h-[18px] md:w-[18px]',
                                '-translate-x-[4.5px] -translate-y-[13.5px] xs:-translate-y-[11px] md:-translate-y-[12.5px]',
                                isLiquid &&
                                  'top-0 h-[22px] w-[22px] md:h-[18px] md:w-[18px]',
                                !md && 'h-[16px] w-[16px] -translate-y-[11px]',
                              )}
                              darkColor='#fff'
                            />
                          </div>
                        </Hint>
                      </div>
                      <div className='relative'>
                        <div
                          className={clsxm(
                            'text-[15px] md:text-[14px]',
                            'leading-[12.5px] md:leading-[17.5px]',
                            'flex',
                            'items-center',
                            'gap-1',
                            'text-[#A59BEA]',
                            getStaticPercentageBoosterTextColor(),
                            !md && 'text-[12px]',
                          )}
                        >
                          {formatNumber(percentageBoost)}%
                          {showFutureStats && (
                            <>
                              <FutureStatsRightArrow
                                color={isDark ? '#A59BEA' : '#6A6A8C'}
                              />

                              <span className={getPercentageBoosterTextColor()}>
                                {formatNumber(dynamicPercentageBoost)}%
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={clsxm(
                        'mt-[9px] min-h-[28px] w-full',
                        isLiquid && 'mt-[15px] md:mt-[8px]',
                        !md && 'mt-[9px] px-[12px]',
                      )}
                    >
                      <div className='relative w-full' ref={containerRef}>
                        <div
                          className={clsxm(
                            'absolute top-0 left-0 h-2 w-full rounded-[8px] border border-[#A59BEA]',
                            !md && 'h-1.5',
                          )}
                        />
                        <div
                          className={clsxm(
                            !md && 'h-1.5',
                            'absolute top-0 left-0 h-2 rounded-l-[8px] bg-[#A59BEA]',
                            new DefiUtils(dynamicPercentageBoost).isLessThan(
                              100,
                            ) && 'GradientProgressBar',
                            !md && '!bg-[length:100vw_100%]',
                            new DefiUtils(dynamicPercentageBoost).isLessThan(
                              150,
                            ) && 'GreenProgressBar',
                            new DefiUtils(dynamicPercentageBoost).isGreaterThan(
                              150,
                            ) && 'BlueProgressBar',
                          )}
                          style={{
                            width: `${left}%`,
                          }}
                        />
                        <div
                          className={clsxm(
                            'absolute -top-[3px] h-[14px] w-[14px] cursor-grabbing rounded-full bg-[#A59BEA]',
                            !md && 'h-[12px] w-[12px] -top-[2px]',
                            new DefiUtils(dynamicPercentageBoost).isLessThan(15)
                              ? 'bg-[#A59BEA]'
                              : new DefiUtils(
                                  dynamicPercentageBoost,
                                ).isLessThan(75)
                              ? 'bg-[#EF313C]'
                              : new DefiUtils(
                                  dynamicPercentageBoost,
                                ).isLessThan(100)
                              ? 'bg-[#E89414]'
                              : new DefiUtils(
                                  dynamicPercentageBoost,
                                ).isLessThan(150)
                              ? 'bg-[#24b06d]'
                              : new DefiUtils(
                                  dynamicPercentageBoost,
                                ).isGreaterThan(150)
                              ? 'bg-[#1c77cb]'
                              : 'bg-[#1c77cb]',
                          )}
                          style={{
                            left: `${left - 1}%`,
                          }}
                          onMouseDown={handleMouseDown}
                          onTouchStart={handleMouseDown}
                          ref={circleRef}
                        />
                        <div className='absolute top-3 flex w-full justify-between pr-px'>
                          <span
                            className={clsxm(
                              'text-[12px] leading-tight text-[#A59BEA]',
                              isLiquid && 'text-[13px] md:text-[11.12px]',
                              !md && 'text-[10px]',
                            )}
                          >
                            Boost:{' '}
                            <span className='font-bold'>
                              <>
                                {formatNumber(
                                  new DefiUtils(
                                    dynamicPercentageBoost,
                                  ).isLessThan(0)
                                    ? 0
                                    : dynamicPercentageBoost,
                                )}
                                %
                              </>
                            </span>
                          </span>
                          <span
                            className={clsxm(
                              'text-[12px] font-semibold leading-[15px] text-[#727292]',
                              isLiquid && 'text-[13px] md:text-[11.12px]',
                            )}
                          >
                            {`${
                              new DefiUtils(
                                dynamicPercentageBoost,
                              ).isGreaterThan(100.01)
                                ? '>'
                                : ''
                            } 100%`}
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* Action Button */}
                    <div
                      className={clsxm(
                        'mt-[24px] flex gap-1',
                        isLiquid && 'mt-[24px] md:mt-[9.5px]',
                        !md && 'mt-[15px]',
                      )}
                    >
                      <div className='flex-1'>
                        <Hint
                          content={
                            <span className='text-[8px] md:text-[10px]'>
                              {hasPendingTransactions
                                ? t('translation:wait-tx-inprogress')
                                : actionButton.label}
                            </span>
                          }
                          placement={md ? 'top-center' : 'top-end'}
                          className='w-full'
                          unvisible={!hasPendingTransactions}
                        >
                          <TransactionButton
                            onClick={handleButtonClick}
                            text={actionButton.label}
                            disabled={actionButton.disabled}
                            className={clsxm(
                              isLiquid && 'md:h-[38.23px]',
                              !md && 'h-[34px]',
                            )}
                          />
                        </Hint>
                      </div>
                    </div>
                    {footerNotes[selectedTab].map(({ label, value }) => {
                      return (
                        <div
                          key={label}
                          className={clsxm(
                            'dark:text–[#625E8F]  mt-1.5 flex justify-between text-[10px] text-[#6A6A8C] md:text-[12px]',
                            isLiquid && 'text-[13px] md:text-[11px]',
                            !md && 'text-[10px]',
                          )}
                        >
                          <span>{label}</span>
                          <span>{value} HTM</span>
                        </div>
                      );
                    })}

                    <OperationFormBottomAlerts />
                  </div>
                </div>
              </Tabber>
            </div>
          </>
        </div>

        <div
          className={clsxm(
            'w-full bg-[#FCFAFF] px-6  pt-[30px] pb-[18px] dark:bg-[#292651]',
            isLiquid && 'px-3 pt-[36px] md:pl-[22px]',
            !md && 'px-[7px] pt-6',
          )}
        >
          <div
            className={clsxm(
              'absolute right-[9.5px] top-[8.5px] z-10',
              !md && 'top-[2px]',
            )}
          >
            <ClosePopupBtn
              iconClassName={clsxm(
                'w-[10.5px] h-[10.5px]',
                !md && 'w-[8.5px] h-[8.5px]',
              )}
              theme={{ light: '#3C3A40', dark: 'white' }}
            />
          </div>
          <div
            className={clsxm(
              'min-w-full max-w-full flex h-fit w-full overflow-hidden',
              'translate-x-px translate-y-[0.5px]',
              'flex-col justify-center gap-3 rounded-[16px] md:gap-6',
              'border-[1.6px] border-[#D3D6EE] bg-white dark:border-[#3F3B76] dark:bg-[#191733] ',
              'pl-[10.5px] pb-1.5 pr-0 pt-[11px]',
              isLiquid && 'max-w-full pl-[9.5px] pb-0 pr-0  md:pt-[9.5px]',
              !md ? 'mb-3 pl-1' : 'mb-2',
            )}
          >
            {boosterByMoneyMarket.length === 0 && (
              <LazyMotion features={domAnimation}>
                <m.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                  transition={{ duration: 0.3 }}
                  className={classNames(
                    'flex h-[290px] w-full flex-col items-center justify-center pr-3',
                    !md && '!h-[150px]',
                  )}
                >
                  <div className='flex -translate-y-[2px] -translate-x-px flex-col items-center gap-[19px]'>
                    <WrappedLottiePlayer
                      src={
                        isDark
                          ? 'https://cdn.app.hatom.com/animations/rabbit-dark.json'
                          : 'https://cdn.app.hatom.com/animations/rabbit.json'
                      }
                      className={clsxm(
                        'h-full w-full max-w-[181px]',
                        !md && 'max-w-[130px]',
                      )}
                    />
                    <span
                      className={clsxm(
                        'translate-x-[2px] text-[14px] font-semibold leading-[17.5px] text-[#404056]',
                        !md && 'text-[12px]',
                      )}
                    >
                      {t('no-boost-available')}
                    </span>
                  </div>
                </m.div>
              </LazyMotion>
            )}
            {isLoggedIn &&
              new DefiUtils(totalCollateralUSD).isGreaterThan(0) &&
              (new DefiUtils(totalStaked.totalStaked).isGreaterThan(0) ||
                new DefiUtils(totalCollateralUSD).isGreaterThan(0)) && (
                <div
                  className={clsxm(
                    'flex max-h-[316px] flex-grow flex-col gap-3',
                    isLiquid ? 'md:gap-[8px]' : 'md:gap-[22px]',
                  )}
                >
                  <div
                    className={clsxm(
                      'flex flex-col gap-1 pr-3',
                      isLiquid ? 'gap-4 md:gap-[9px]' : 'md:gap-3',
                    )}
                  >
                    <div className={clsxm('flex items-center gap-[14px]')}>
                      <span
                        className={clsxm(
                          'text-[10px] font-semibold leading-[17.5px] text-[#6A6A8C] dark:text-white',
                          isLiquid
                            ? 'text-[15px] md:text-[13px]'
                            : 'md:text-[14px]',
                        )}
                      >
                        {t('global-boost')}{' '}
                        <Hint
                          containerClassName='md:mt-[0px] md:ml-[0px] ml-[-55px] mt-[-20px]'
                          placement={isMdAndAbove ? 'top-center' : 'right-end'}
                          content={
                            <div className='max-w-[248px] text-[8px] md:text-[10px] leading-[12.5px] md:max-w-[264px]'>
                              <Trans
                                i18nKey={
                                  getTabIndex(selectedTab) == 0
                                    ? 'tooltip-4x75k'
                                    : 'tooltip-4x75i'
                                }
                                components={[
                                  <span className='text-[#5AF1B0]' />,
                                  <span className='text-[#E24949]' />,
                                  <span className='text-[#9E70FF]' />,
                                ]}
                                ns='translation'
                              />
                            </div>
                          }
                        >
                          <div className='relative -translate-y-0.5'>
                            {new DefiUtils(
                              dynamicPercentageBoost,
                            ).isGreaterThanOrEqualTo(150) ? (
                              <BoostStatus status={t('superior')} />
                            ) : new DefiUtils(
                                dynamicPercentageBoost,
                              ).isGreaterThanOrEqualTo(100) ? (
                              <BoostStatus status={t('optimal')} />
                            ) : new DefiUtils(
                                dynamicPercentageBoost,
                              ).isGreaterThanOrEqualTo(50) ? (
                              <BoostStatus status={t('moderate')} />
                            ) : new DefiUtils(
                                dynamicPercentageBoost,
                              ).isGreaterThanOrEqualTo(0) ? (
                              <BoostStatus status={t('low')} />
                            ) : null}
                          </div>
                        </Hint>
                      </span>
                    </div>
                    <GlobalBoostItem
                      title={t('all-positions')}
                      totalCollateralUSD={totalCollateralUSD}
                      totalStakedDynamic={totalStakedDynamic}
                      dynamicPercentageBoost={dynamicPercentageBoost}
                      key={boosterByMoneyMarket[0]?.market}
                      market={boosterByMoneyMarket[0]?.market}
                      staked={totalStakedDynamic}
                      inputValue={inputValue}
                      showBoostDecrease={showBoostDecrease}
                      totalCollateralInHtm={totalCollateralInHtm.toString()}
                      isLiquid={isLiquid}
                    />
                  </div>
                  {isLiquid && sEgldBoosterMarket.length > 0 && (
                    <div
                      className={clsxm(
                        'flex flex-col pr-2',
                        isLiquid ? 'md:gap-0.5' : 'md:gap-1',
                      )}
                    >
                      <span
                        className={clsxm(
                          'flex translate-y-[0.5px] items-center gap-2 text-[10px] font-semibold leading-[17.5px] text-[#6A6A8C] dark:text-white',
                          isLiquid
                            ? 'text-[13px] md:text-[13px]'
                            : 'md:text-[14px]',
                        )}
                      >
                        {t('liquid-staking-breakdown')}{' '}
                        <Hint
                          containerClassName='md:mt-[0px] md:ml-[0px] ml-[-90px] mt-[-20px]'
                          placement={isMdAndAbove ? 'top-center' : 'right-end'}
                          content={
                            <div
                              className={clsxm(
                                'max-w-[223px] md:max-w-[230px] text-[8px] md:text-[10px] leading-[12.5px]',
                                isLiquid
                                  ? 'md:max-w-[315px]'
                                  : 'md:max-w-[280px]',
                              )}
                            >
                              <Trans
                                i18nKey={
                                  getTabIndex(selectedTab) == 0
                                    ? 'tooltip-4x75d'
                                    : 'tooltip-4x75d'
                                }
                                components={[
                                  <span className='text-[#006FFF]' />,
                                  <span className='text-[#5AF1B0]' />,
                                  <span className='text-[#E24949]' />,
                                ]}
                                ns='translation'
                              />
                            </div>
                          }
                        >
                          <div className='relative inline-block translate-y-0.5'>
                            <img
                              src={
                                isDark
                                  ? 'https://cdn.app.hatom.com/images/liquidstaking/app/liquid-breakdown-n.svg'
                                  : 'https://cdn.app.hatom.com/images/liquidstaking/app/liquid-breakdown.svg'
                              }
                              alt='liquid breakdown'
                              className={clsxm(
                                'h-[14px] w-[14px]',
                                isLiquid && '-translate-x-1',
                              )}
                            />
                          </div>
                        </Hint>
                      </span>
                      <div
                        className={clsxm(
                          'relative flex max-h-[132px] flex-col gap-6 overflow-hidden md:max-h-[182px]',
                        )}
                      >
                        <div
                          className={clsxm(
                            'pointer-events-none absolute inset-x-0 -top-0.5 z-10 h-3',
                            '[background-image:linear-gradient(to_bottom,var(--start-color),transparent)]',
                            'transition-opacity duration-300 ease-out',
                            '[--start-color:#FFFFFF] dark:opacity-0',
                          )}
                        />
                        <div
                          className={clsxm(
                            'pointer-events-none absolute inset-x-0 top-0 z-10 h-3',
                            '[background-image:linear-gradient(to_bottom,var(--start-color),transparent)]',
                            'dark:oapcity-100 opacity-0 [--start-color:#FFFFFF]',
                            'transition-opacity duration-300 ease-out',
                          )}
                        />
                        <div
                          className={clsxm(
                            'pointer-events-none absolute inset-x-0 -bottom-px z-10 h-3',
                            '[background-image:linear-gradient(to_top,var(--start-color),transparent)]',
                            'transition-opacity duration-300 ease-out',
                            '[--start-color:#FFFFFF] dark:opacity-0',
                          )}
                        />{' '}
                        <div
                          className={clsxm(
                            'relative flex flex-col gap-3 py-2 lg:gap-6',
                          )}
                        >
                          {sEgldBoosterMarket.map((booster) => {
                            return (
                              <ProgressItem
                                selectedTab={selectedTab}
                                key={booster.market}
                                market={booster.market}
                                currentApy={booster.accountBoosterApy}
                                maxApy={booster.maxAccountBoosterApy}
                                totalStaked={totalStakedDynamic}
                                totalCollateralUSD={totalCollateralUSD}
                                percentageBoost={percentageBoost}
                                dynamicPercentageBoost={dynamicPercentageBoost}
                                inputValue={inputValue}
                                accountStaked={new DefiUtils(
                                  booster.accountMarketBooster.staked,
                                )
                                  .toFullDecimals(htmMarket.underlying.decimals)
                                  .toString()}
                                showBoostDecrease={showBoostDecrease}
                                isLiquid={isLiquid}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                  {boosterByMoneyMarketWithoutSEgld.length > 0 && (
                    <div
                      className={clsxm(
                        'flex flex-col pr-2 md:gap-1',
                        isLiquid && '-translate-y-2 md:gap-0.5',
                        sEgldBoosterMarket.length <= 0 && 'mt-4',
                      )}
                    >
                      <span
                        className={clsxm(
                          isLiquid &&
                            'flex items-center !text-[13px] md:text-[13px]',
                          'translate-y-[0.5px] gap-2 text-[10px] font-semibold leading-[17.5px] text-[#6A6A8C] dark:text-white md:text-[13px]',
                        )}
                      >
                        {isLiquid
                          ? t('lending-protocol-breakdown')
                          : t('boosted-positions-breakdown')}{' '}
                        <Hint
                          containerClassName='md:mt-[0px] md:ml-[0px] ml-[-100px] mt-[-20px]'
                          placement={isMdAndAbove ? 'top-center' : 'right-end'}
                          content={
                            <div className='max-w-[230px] text-[8px] md:text-[10px] leading-[12.5px] md:max-w-[280px]'>
                              <Trans
                                i18nKey={
                                  isLiquid
                                    ? 'tooltip-4x75l'
                                    : getTabIndex(selectedTab) == 0
                                    ? 'tooltip-4x75g'
                                    : 'tooltip-4x75u'
                                }
                                components={
                                  isLiquid
                                    ? [
                                        <span className='text-[#E24949]' />,
                                        <span className='text-[#5AF1B0]' />,
                                      ]
                                    : [
                                        <span className='text-[#E24949]' />,
                                        <span className='text-[#5AF1B0]' />,
                                      ]
                                }
                                ns='translation'
                              />
                            </div>
                          }
                        >
                          <div className='relative inline-block translate-y-0.5'>
                            <img
                              src={
                                isDark
                                  ? 'https://cdn.app.hatom.com/images/liquidstaking/app/lending-breakdown-n.svg'
                                  : 'https://cdn.app.hatom.com/images/liquidstaking/app/lending-breakdown.svg'
                              }
                              alt='liquid breakdown'
                              className={clsxm(
                                'h-[14px] w-[14px]',
                                isLiquid && '-translate-x-1',
                              )}
                            />
                          </div>
                        </Hint>
                      </span>
                      <div
                        className={clsxm(
                          'relative flex max-h-[132px] flex-col gap-6 overflow-hidden',
                          isLiquid
                            ? 'max-h-[100px] md:max-h-[100px]'
                            : 'md:max-h-[192px]',
                        )}
                      >
                        <div
                          className={clsxm(
                            'pointer-events-none absolute inset-x-0 -top-0.5 z-10 h-3',
                            '[background-image:linear-gradient(to_bottom,var(--start-color),transparent)]',
                            'transition-opacity duration-300 ease-out',
                            '[--start-color:#FFFFFF] dark:[--start-color:#191733]',
                          )}
                        />
                        <div
                          className={clsxm(
                            'pointer-events-none absolute inset-x-0 top-0 z-10 h-3',
                            '[background-image:linear-gradient(to_bottom,var(--start-color),transparent)]',
                            'dark:oapcity-100 opacity-0 [--start-color:#FFFFFF]',
                            'transition-opacity duration-300 ease-out',
                          )}
                        />
                        <div
                          className={clsxm(
                            'pointer-events-none absolute inset-x-0 -bottom-px z-10 h-3',
                            '[background-image:linear-gradient(to_top,var(--start-color),transparent)]',
                            'transition-opacity duration-300 ease-out',
                            '[--start-color:#FFFFFF] dark:[--start-color:#191733]',
                          )}
                        />{' '}
                        <div
                          className={clsxm(
                            'relative flex flex-col gap-3 py-2 md:gap-6 md:pt-2 lg:pt-2',
                            boosterByMoneyMarket.length > 3 &&
                              'scroll-container1',
                            isLiquid && 'md:gap-1.5 lg:gap-3',
                          )}
                        >
                          {boosterByMoneyMarketWithoutSEgld.map((booster) => {
                            return (
                              <ProgressItem
                                selectedTab={selectedTab}
                                key={booster.market}
                                market={booster.market}
                                currentApy={booster.accountBoosterApy}
                                maxApy={booster.maxAccountBoosterApy}
                                totalStaked={totalStakedDynamic}
                                totalCollateralUSD={totalCollateralUSD}
                                percentageBoost={percentageBoost}
                                dynamicPercentageBoost={dynamicPercentageBoost}
                                inputValue={inputValue}
                                accountStaked={new DefiUtils(
                                  booster.accountMarketBooster.staked,
                                )
                                  .toFullDecimals(htmMarket.underlying.decimals)
                                  .toString()}
                                showBoostDecrease={showBoostDecrease}
                                isLiquid={isLiquid}
                              />
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
          </div>

          {/* Bottom Note */}

          <div
            className={clsxm(
              'mt-1 translate-x-px -translate-y-px space-y-1.5 text-sm text-[#6A6A8C] dark:text-[#625E8F]',
              {
                '!mt-3': boosterByMoneyMarket.length !== 0,
              },
            )}
          >
            <div>
              <ClaimsAndRequests isLiquid={isLiquid} />
              {showBannerApy && (
                <div className='mt-[10px] flex min-h-[27px] items-center justify-center rounded-[6px] bg-[#736FBB]'>
                  <span className='text-[12px] font-semibold leading-[15px] text-white'>
                    {t('only-earn-booster')}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </PopupBed>
  );
};

export default HTMBoosterPopup;
