import classNames from 'classnames';
import { domAnimation, LazyMotion, m } from 'framer-motion';

import { OtherLogos } from '@/components/WalletLogos';

interface WalletProviderButtonProps {
  brand: string;
  svgLogo: string | any;
  onClick: (args: any) => void;
  enabled?: boolean;
}

const WalletProviderButton = ({
  brand,
  svgLogo,
  onClick,
  enabled,
  ...props
}: WalletProviderButtonProps) => {
  return (
    <LazyMotion features={domAnimation}>
      <m.button
        {...props}
        onTap={onClick}
        disabled={!enabled}
        className={classNames(
          'flex items-center gap-6',
          'h-16 sm:h-[62px]',
          'rounded-[8px] px-6',
          'bg-white/70 ring-2 ring-[#D3D0E4] hover:ring-current dark:bg-[#292651] dark:ring-[#2E2C51]'
        )}
      >
        <div style={{ width: 30 }} className='flex justify-end'>
          {svgLogo}
        </div>

        <div
          className={classNames(
            'text-[#535367] dark:text-white',
            'font-semibold tracking-normal sm:text-lg'
          )}
        >
          {brand}
        </div>
        <div
          className={classNames('ml-auto', 'text-[#535367] dark:text-white')}
        >
          {OtherLogos.leftArrow}
        </div>
      </m.button>
    </LazyMotion>
  );
};

export default WalletProviderButton;
