
import clsxm from "@/services/clsxm";

const ConnectWalletStepStateIndicator = ({ className }: { className: string, }) => {

  return (
    <div className={clsxm('flex items-center gap-[6.9px] lg:gap-[10px] h-[4.25px] lg:h-[6px]', className)}>
      <div className='bg-[#9F8FDF] w-[10.6px] lg:w-[15px] h-full rounded-[20px]' />
      <div className='bg-[#DBDBEA] w-[5.7px] lg:w-[8px] h-full rounded-[20px]' />
    </div>

  )
}

export default ConnectWalletStepStateIndicator;