import { SVGProps } from 'react';

const InfoIcon2 = ({
  fill,
  ...props
}: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='8'
      height='8'
      viewBox='0 0 8 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M4.49778 7.59762L3.50222 7.59762C1.79022 7.59762 0.402376 6.20978 0.402376 4.49778L0.402376 3.50222C0.402376 1.79022 1.79022 0.402376 3.50222 0.402376L4.49778 0.402376C6.20978 0.402376 7.59762 1.79022 7.59762 3.50222L7.59762 4.49778C7.59762 6.20978 6.20978 7.59762 4.49778 7.59762Z'
        stroke={fill}
        strokeWidth='0.804752'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4 5.77734V3.99957'
        stroke={fill}
        strokeWidth='0.891908'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.00087 1.77908C3.75541 1.77908 3.55642 1.97806 3.55642 2.22352C3.55642 2.46898 3.75541 2.66797 4.00087 2.66797C4.24633 2.66797 4.44531 2.46898 4.44531 2.22352C4.44531 1.97806 4.24633 1.77908 4.00087 1.77908Z'
        fill={fill}
      />
    </svg>
  );
};

export default InfoIcon2;
