import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';
import { cloneDeep } from 'lodash';

import { AppDispatch, GetRootState, RootState } from '@/store/index';

import { STORAGE_KEYS } from '@/contexts/AuthContext/hooks/useElrondNetworkSync';
import gatewayService from '@/services/gateway';
import multiversxSDK from '@/services/multiversx-sdk';
import logger from '@/utils/logger';

import { TransactionResponseItem } from '@/types/account';
import { LoginMethodsEnum } from '@/types/enums';
import { ASSET_ALT_NAME, OTHER_TOKENS_IDS } from '@/store/protocol';

export interface AccountToken {
  tokenIdentifier: string;
  balance: string;
  symbol: string;
  decimals: number;
  name: string;
  priceUSD?: string;
  attributes?: string;
  nonce?: number;
  logoUrl?: string;
}

interface AccountState extends Record<string, any> {
  address: string;
  nonce: number;
  balance: string;
  transactions: TransactionResponseItem[];
  isGuarded: boolean;
  activeGuardianAddress: string;
  tokens: AccountToken[];
}

const accountInitialState: AccountState = {
  address: '',
  nonce: 0,
  balance: '',
  transactions: [],
  isGuarded: false,
  activeGuardianAddress: '',
  tokens: [],
};

interface LoginInfoState extends Record<string, any> {
  loginMethod: LoginMethodsEnum;
  expires: number;
  loginToken: string;
  signature: string;
}

const loginInfoInitialState: LoginInfoState = {
  loginMethod: LoginMethodsEnum.none,
  expires: 0,
  loginToken: '',
  signature: '',
};

interface LoggingInState extends Record<string, any> {
  pending: boolean;
  error: string;
  loggedIn: boolean;
}

const loggingInInitialState: LoggingInState = {
  pending: true,
  error: '',
  loggedIn: false,
};

export interface AuthState {
  account: AccountState;
  loginInfo: LoginInfoState;
  loggingIn: LoggingInState;
}

const initialState: AuthState = {
  account: cloneDeep(accountInitialState),
  loginInfo: cloneDeep(loginInfoInitialState),
  loggingIn: cloneDeep(loggingInInitialState),
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccountState: (
      state,
      action: PayloadAction<{ key: keyof AccountState; value: any }>,
    ) => {
      state.account[action.payload.key] = action.payload.value;
    },

    clearAccountState: (state) => {
      const resetObj = cloneDeep(accountInitialState);
      Object.keys(resetObj).forEach((key) => {
        state.account[key] = resetObj[key];
      });
    },

    setLoginInfoState: (
      state,
      action: PayloadAction<{ key: keyof LoginInfoState; value: any }>,
    ) => {
      state.loginInfo[action.payload.key] = action.payload.value;
    },

    clearLoginInfoState: (state) => {
      const resetObj = cloneDeep(loginInfoInitialState);
      Object.keys(resetObj).forEach((key) => {
        state.loginInfo[key] = resetObj[key];
      });
    },

    setLoggingInState: (
      state,
      action: PayloadAction<{ key: keyof LoggingInState; value: any }>,
    ) => {
      state.loggingIn[action.payload.key] = action.payload.value;
    },

    clearLoggingInState: (state) => {
      const resetObj = cloneDeep(loggingInInitialState);
      Object.keys(resetObj).forEach((key) => {
        state.loggingIn[key] = resetObj[key];
      });
    },
  },
});

export const { clearAccountState, clearLoginInfoState, clearLoggingInState } =
  authSlice.actions;

// Account info state + persistance

export const setAccountState =
  (key: keyof AccountState, value: any) => (dispatch: AppDispatch) => {
    dispatch(authSlice.actions.setAccountState({ key, value }));
  };

export const updateESDTTokenBalance =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();
      const account: AccountState = state.auth.account;

      if (!account.address) {
        await Promise.all([
          dispatch(setAccountState('tokens', [])),
          dispatch(setAccountState('balance', '0')),
        ]);
        return;
      }

      const markets = state.protocol.markets;

      const [accountDetails, accountGuardian, accountEsdt] = await Promise.all([
        gatewayService.address.getAddressDetails(account.address),
        gatewayService.address.getAddressGuardianData(account.address),
        gatewayService.address.getAddressEsdt(account.address),
      ]);

      const identifiers = [
        ...Object.values(markets)
          .map(({ hToken, underlying }) => [hToken.id, underlying.id])
          .flat(),
        ...OTHER_TOKENS_IDS,
      ];

      const tokensDetails = await multiversxSDK.tokens({
        identifiers: identifiers.join(','),
        fields: 'identifier,ticker,price,assets,name,decimals',
      });

      const accountTokens: AccountToken[] = [
        {
          tokenIdentifier: 'EGLD',
          balance: accountDetails.account.balance.toString(),
          symbol: 'EGLD',
          decimals: 18,
          priceUSD: markets['EGLD'].underlying.priceUSD,
          name: ASSET_ALT_NAME['EGLD'],
        },
        ...Object.values(accountEsdt.esdts).map(
          ({ tokenIdentifier, balance, attributes, nonce }) => {
            const tokenItem = tokensDetails.find(
              (tokenDetailItem) =>
                tokenDetailItem.identifier === tokenIdentifier,
            );
            const marketToken = Object.values(markets)
              .map(({ underlying, hToken }) => [underlying, hToken])
              .flat()
              .find((item) => item.id === tokenIdentifier);
            const symbol = (tokenItem?.ticker || tokenIdentifier || '').split(
              '-',
            )[0];
            const decimals = marketToken?.decimals || tokenItem?.decimals || 1;
            const priceUSD = marketToken?.priceUSD
              ? marketToken?.priceUSD
              : tokenItem?.price
              ? String(tokenItem?.price)
              : undefined;
            const logoUrl = tokenItem?.assets?.svgUrl;
            const name = tokenItem?.name || symbol;

            return {
              tokenIdentifier,
              balance,
              symbol,
              decimals,
              name,
              ...(priceUSD ? { priceUSD } : {}),
              ...(attributes ? { attributes } : {}),
              ...(nonce ? { nonce } : {}),
              ...(logoUrl ? { logoUrl } : {}),
            };
          },
        ),
      ];

      logger.info({
        accountTokens,
        tokensDetails,
        identifiers,
        'accountEsdt.esdts': accountEsdt.esdts,
      });

      await Promise.all([
        dispatch(setAccountState('tokens', accountTokens)),
        dispatch(
          setAccountState('balance', accountDetails.account.balance.toString()),
        ),
        dispatch(
          setAccountState('isGuarded', accountGuardian.guardianData.guarded),
        ),
        dispatch(
          setAccountState(
            'activeGuardianAddress',
            accountGuardian.guardianData.activeGuardian?.address || '',
          ),
        ),
      ]);
    } catch (error) {
      logger.error('store:updateESDTTokenBalance', error);
      captureException(error);
    }
  };

export const updateAccountTxs =
  () => async (dispatch: AppDispatch, getState: any) => {
    try {
      const state = getState();
      const account: AccountState = state.auth.account;

      if (!account.address) {
        dispatch(setAccountState('transactions', []));
        return;
      }

      const transactions: any[] =
        (await multiversxSDK
          .accountTransfers(account.address, {
            from: 0,
            size: 25,
          })
          .catch(() => {})) || [];

      const onlyTransactions =
        transactions && transactions.length > 0
          ? transactions.filter((txData) => txData.type === 'Transaction')
          : [];

      await dispatch(setAccountState('transactions', onlyTransactions));
    } catch (error) {
      logger.error('store:updateAccountTxs', error);
      captureException(error);
    }
  };

export const accountSelector = (state: RootState) => state.auth.account;

// Login info state + persistance

export const setLoginInfoState =
  (key: keyof LoginInfoState, value: any) => (dispatch: AppDispatch) => {
    dispatch(authSlice.actions.setLoginInfoState({ key, value }));
  };

export const loginInfoSelector = (state: RootState) => state.auth.loginInfo;

// Login info state

export const setLoggingInState =
  (key: keyof LoggingInState, value: any) => (dispatch: AppDispatch) => {
    dispatch(authSlice.actions.setLoggingInState({ key, value }));
  };

export const clearAuthStates = () => (dispatch: AppDispatch) => {
  dispatch(clearAccountState());
  dispatch(clearLoginInfoState());
  dispatch(clearLoggingInState());

  localStorage.removeItem(STORAGE_KEYS.ACCOUNT_KEY);
  localStorage.removeItem(STORAGE_KEYS.LOGIN_INFO_KEY);
};

export const loggingInSelector = (state: RootState) => state.auth.loggingIn;

export default authSlice.reducer;
