const interestRateModelABI = {
  buildInfo: {
    rustc: {
      version: '1.70.0-nightly',
      commitHash: '5e1d3299a290026b85787bc9c7e72bcc53ac283f',
      commitDate: '2023-03-31',
      channel: 'Nightly',
      short: 'rustc 1.70.0-nightly (5e1d3299a 2023-03-31)',
    },
    contractCrate: {
      name: 'interest-rate-model',
      version: '0.0.0',
      gitVersion: 'rv-audit-2-111-g8c5cd08',
    },
    framework: {
      name: 'multiversx-sc',
      version: '0.39.8',
    },
  },
  name: 'InterestRateModel',
  constructor: {
    docs: [
      'Initializes the Interest Rate Model smart contract with the given parameters.',
      '',
      'The `r0_y`, `m1_y`, `m2_y`, `uo`, and `r_max` parameters describe the piecewise linear function that determines the',
      'borrow rate. They are provided in per year term basis and are translated to a per second term basis using constants',
      '`WAD` and `SECONDS_PER_YEAR`.',
      '',
      '# Arguments:',
      '',
      '- `r0_y` - The base borrow rate per year.',
      '- `m1_y` - The first slope of the borrow rate function per year.',
      '- `m2_y` - The last slope of the borrow rate function per year.',
      '- `uo` - The optimal utilization.',
      '- `r_max` - The maximum borrow rate per second.',
      '',
    ],
    inputs: [
      {
        name: 'r0_y',
        type: 'BigUint',
      },
      {
        name: 'm1_y',
        type: 'BigUint',
      },
      {
        name: 'm2_y',
        type: 'BigUint',
      },
      {
        name: 'uo',
        type: 'BigUint',
      },
      {
        name: 'r_max',
        type: 'BigUint',
      },
    ],
    outputs: [],
  },
  endpoints: [
    {
      docs: [
        'Returns true to indicate that this contract is an interest rate model.',
      ],
      name: 'isInterestRateModel',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'bool',
        },
      ],
    },
    {
      docs: [
        'Returns the utilization rate for the given amount of borrows and liquidity. Utilization rate is calculated as borrows',
        'divided by liquidity. If borrows are zero, returns zero. If liquidity is zero, returns the utilization rate that',
        'yields the maximum borrow rate.',
        '',
        '# Arguments:',
        '',
        '- `borrows` - The total amount borrowed.',
        '- `liquidity` - The total amount of funds available for borrowing.',
        '',
      ],
      name: 'getUtilization',
      mutability: 'readonly',
      inputs: [
        {
          name: 'borrows',
          type: 'BigUint',
        },
        {
          name: 'liquidity',
          type: 'BigUint',
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: [
        'Returns the current model parameters used to calculate the borrow rate, as a tuple of:',
        '',
        '- Base rate (r0)',
        '- First slope (m1)',
        '- Last slope (m2)',
        '- Optimal utilization (uo)',
        '- Maximum borrow rate (r_max)',
        '',
      ],
      name: 'getModelParameters',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'tuple<BigUint,BigUint,BigUint,BigUint,BigUint>',
        },
      ],
    },
    {
      docs: [
        'Computes the borrow rate per second based on the current state of the model, the total borrows and total liquidity.',
        '',
        '# Arguments:',
        '',
        '- `borrows` - The total amount of borrows in the market.',
        '- `liquidity` - The total amount of cash available to be borrowed or used as collateral.',
        '',
      ],
      name: 'getBorrowRate',
      mutability: 'readonly',
      inputs: [
        {
          name: 'borrows',
          type: 'BigUint',
        },
        {
          name: 'liquidity',
          type: 'BigUint',
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: [
        'Calculates the current supply rate per second given the total amount of borrows, the total amount of underlying',
        'assets, and the current reserve factor.',
        '',
        '# Arguments:',
        '',
        '- `borrows` - The total amount of outstanding borrows of the underlying asset.',
        '- `liquidity` - The total amount of the underlying asset supplied by the users.',
        '- `reserve_factor` - The current reserve factor applied to the market.',
        '',
      ],
      name: 'getSupplyRate',
      mutability: 'readonly',
      inputs: [
        {
          name: 'borrows',
          type: 'BigUint',
        },
        {
          name: 'liquidity',
          type: 'BigUint',
        },
        {
          name: 'reserve_factor',
          type: 'BigUint',
        },
      ],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: [
        '',
        '- `borrows` - The current borrow balance.',
        '- `liquidity` - The current liquidity balance.',
        '- `reserve_factor` - The reserve factor for the asset.',
        '',
      ],
      name: 'getRates',
      mutability: 'readonly',
      inputs: [
        {
          name: 'borrows',
          type: 'BigUint',
        },
        {
          name: 'liquidity',
          type: 'BigUint',
        },
        {
          name: 'reserve_factor',
          type: 'BigUint',
        },
      ],
      outputs: [
        {
          type: 'tuple<BigUint,BigUint>',
        },
      ],
    },
    {
      docs: ['Stores the base rate used in the interest rate calculation.'],
      name: 'getBaseRate',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: [
        'Stores the slope of the borrow rate up to the optimal utilization point.',
      ],
      name: 'getFirstSlope',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: [
        'Stores the slope of the borrow rate after the optimal utilization point.',
      ],
      name: 'getLastSlope',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: [
        'Stores the optimal utilization point for the interest rate calculation.',
      ],
      name: 'getOptimalUtilization',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: [
        'Stores the maximum borrow rate allowed by the interest rate model.',
      ],
      name: 'getMaxBorrowRate',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
  ],
  events: [
    {
      docs: [
        'Emitted when the liquidity is zero and utilization is infinity or when liquidity != 0 and utilization is high.',
      ],
      identifier: 'reached_max_borrow_rate_event',
      inputs: [
        {
          name: 'borrows',
          type: 'BigUint',
          indexed: true,
        },
        {
          name: 'liquidity',
          type: 'BigUint',
          indexed: true,
        },
      ],
    },
  ],
  hasCallback: false,
  types: {},
};

export default interestRateModelABI;
