import DefiUtils from 'defi-utils';
import { useCallback, useMemo } from 'react';

import { useLogin } from '@/hooks/auth/useLogin';
import useLiquidStakingHsEgldCollateral from '@/hooks/liquidStakingApp/useLiquidStakingHsEgldCollateral';
import { EXCHANGE_RATE_KEY } from '@/hooks/protocol/useExchangeRate';

import { useAppSelector } from '@/store/index';
import { RECOMMENDED_REMOVED_COLLATERAL_LIMIT_MARGIN } from '@/store/liquid-staking-app';
import { TOKEN_SOURCE } from '@/store/liquid-staking-app';
import {
  H_TOKEN_DECIMALS,
  hasEnoughEGLDBalanceSelector,
  hasTakenBorrowsSelector,
  sEgldMarketSelector,
} from '@/store/protocol';

export enum MESSAGE_ERROR_KEYS {
  INSUFFICIENT_BALANCE = 'INSUFFICIENT_BALANCE',
  NO_FUNDS_AVAILABLE = 'NO_FUNDS_AVAILABLE',
  AMOUNT_LIMIT = 'AMOUNT_LIMIT',
  GAS_FEE = 'GAS_FEE',
  COLLATERAL = 'COLLATERAL', // 5- Since you have an outstanding borrow, the maximum amount we recommend you to withdraw is: [Recommend] HsEGLD. (Important: this is the only one in yellow)
  REPAY = 'REPAY', //   3- Since you have an outstanding borrow, you must repay your debt to withdraw your collateral.
  LIQUIDATED = 'LIQUIDATED', // 4- Since you have an outstanding borrow, your position will be at risk of being liquidated if you withdraw your collateral.
  RECOMMENDED = 'RECOMMENDED', // 6- Since you have an outstanding borrow, your position will be at risk of being liquidated if you withdraw your collateral, the maximum amount we recommend you to withdraw is: [Recommend] HsEGLD
}

export enum OPERATION_KEYS {
  STAKE = 'stake',
  UNSTAKE = 'unstake',
  MIGRATE = 'migrate',
}

export type LiquidStakingMessageErrorStakeProps = {
  operation: OPERATION_KEYS.STAKE;
  inputValue: string;
  balance: string;
  minValue: string;
  maxValue: string;
};

export type LiquidStakingMessageErrorUnstakeProps = {
  operation: OPERATION_KEYS.UNSTAKE;
  inputValue: string;
  balance: string;
  minValue: string;
  symbol: string;
  source: TOKEN_SOURCE;
};

export type LiquidStakingMessageErrorMigrateProps = {
  operation: OPERATION_KEYS.MIGRATE;
  inputValue: string;
  balance: string;
  minValue: string;
  symbol: string;
  source: TOKEN_SOURCE;
};

export type LiquidStakingMessageErrorProps =
  | LiquidStakingMessageErrorStakeProps
  | LiquidStakingMessageErrorMigrateProps
  | LiquidStakingMessageErrorUnstakeProps;

const useLiquidStakingMessageError = (
  props: LiquidStakingMessageErrorProps
) => {
  const { isLoggedIn } = useLogin();
  const {
    maxRemovableCollateralBalance,
    recommendedMaxRemovableCollateralBalance,
  } = useLiquidStakingHsEgldCollateral();
  const hasEnoughEGLDBalance = useAppSelector(hasEnoughEGLDBalanceSelector);
  const hasTakenBorrows = useAppSelector(hasTakenBorrowsSelector);
  const sEgldMarket = useAppSelector(sEgldMarketSelector);

  const handleStakeError = useCallback(() => {
    {
      if (props.operation !== OPERATION_KEYS.STAKE) {
        return null;
      }

      const { inputValue, balance, minValue, maxValue } = props;

      const inputValueBigNumber = new DefiUtils(inputValue);
      const walletBalanceBigNumber = new DefiUtils(balance);
      const totalAvailableBigNumber = new DefiUtils(maxValue);

      if (inputValueBigNumber.isEqualTo(0)) {
        return null;
      }

      if (!isLoggedIn || walletBalanceBigNumber.isEqualTo(0)) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.NO_FUNDS_AVAILABLE,
        };
      }

      if (
        walletBalanceBigNumber.isLessThan(minValue) &&
        inputValueBigNumber.isLessThanOrEqualTo(walletBalanceBigNumber)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.AMOUNT_LIMIT,
          min: minValue,
          decimalPlaces: new DefiUtils(minValue).decimalPlaces(),
        };
      }

      if (
        walletBalanceBigNumber.isLessThan(minValue) &&
        inputValueBigNumber.isGreaterThan(walletBalanceBigNumber)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.INSUFFICIENT_BALANCE,
        };
      }

      if (
        walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
        inputValueBigNumber.isLessThan(minValue)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.AMOUNT_LIMIT,
          min: minValue,
          decimalPlaces: new DefiUtils(minValue).decimalPlaces(),
        };
      }

      if (
        walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
        inputValueBigNumber.isGreaterThanOrEqualTo(minValue) &&
        inputValueBigNumber.isLessThanOrEqualTo(walletBalanceBigNumber) &&
        totalAvailableBigNumber.isLessThan(minValue)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.GAS_FEE,
        };
      }

      if (
        walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
        inputValueBigNumber.isGreaterThanOrEqualTo(minValue) &&
        inputValueBigNumber.isGreaterThan(walletBalanceBigNumber) &&
        totalAvailableBigNumber.isLessThan(minValue)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.INSUFFICIENT_BALANCE,
        };
      }

      if (
        walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
        inputValueBigNumber.isGreaterThanOrEqualTo(minValue) &&
        inputValueBigNumber.isGreaterThan(totalAvailableBigNumber) &&
        totalAvailableBigNumber.isGreaterThanOrEqualTo(minValue)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.AMOUNT_LIMIT,
          max: totalAvailableBigNumber.toString(),
        };
      }

      return null;
    }
  }, [props, isLoggedIn]);

  const handleUnstakeError = useCallback(() => {
    {
      if (props.operation !== OPERATION_KEYS.UNSTAKE) {
        return null;
      }

      const { inputValue, balance, minValue, symbol, source } = props;
      const inputValueBigNumber = new DefiUtils(inputValue || 0);
      const walletBalanceBigNumber = new DefiUtils(balance);
      const isHsEgldCollateral =
        symbol === EXCHANGE_RATE_KEY.HsEGLD &&
        source === TOKEN_SOURCE.collateral;

      const maxValue = maxRemovableCollateralBalance;
      const maxValueBigNumber = new DefiUtils(maxValue);
      const recommended = recommendedMaxRemovableCollateralBalance;
      const recommendedBigNumber = new DefiUtils(recommended);

      if (inputValueBigNumber.isEqualTo(0)) {
        return null;
      }

      if (!isLoggedIn || walletBalanceBigNumber.isEqualTo(0)) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.NO_FUNDS_AVAILABLE,
        };
      }

      if (!hasEnoughEGLDBalance) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.GAS_FEE,
        };
      }

      const isBalanceEqualToRecommended = new DefiUtils(
        walletBalanceBigNumber
      ).isEqualTo(recommendedBigNumber);

      if (
        !isHsEgldCollateral ||
        (isHsEgldCollateral && !hasTakenBorrows) ||
        (isHsEgldCollateral && isBalanceEqualToRecommended)
      ) {
        if (
          walletBalanceBigNumber.isLessThan(minValue) &&
          inputValueBigNumber.isLessThanOrEqualTo(walletBalanceBigNumber)
        ) {
          return {
            errorKey: MESSAGE_ERROR_KEYS.AMOUNT_LIMIT,
            min: minValue,
          };
        }

        if (
          walletBalanceBigNumber.isLessThan(minValue) &&
          inputValueBigNumber.isGreaterThan(walletBalanceBigNumber)
        ) {
          return {
            errorKey: MESSAGE_ERROR_KEYS.INSUFFICIENT_BALANCE,
          };
        }

        if (
          walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
          inputValueBigNumber.isLessThan(minValue)
        ) {
          return {
            errorKey: MESSAGE_ERROR_KEYS.AMOUNT_LIMIT,
            min: minValue,
          };
        }

        if (
          walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
          inputValueBigNumber.isGreaterThan(walletBalanceBigNumber)
        ) {
          return {
            errorKey: MESSAGE_ERROR_KEYS.AMOUNT_LIMIT,
            max: walletBalanceBigNumber.toString(),
          };
        }

        return null;
      }

      // hsegld collateral conditions
      if (
        recommendedBigNumber.isEqualTo(walletBalanceBigNumber) &&
        inputValueBigNumber.isLessThanOrEqualTo(recommendedBigNumber)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.COLLATERAL,
          max: recommendedBigNumber.toString(),
        };
      }

      // I
      if (
        walletBalanceBigNumber.isLessThan(minValue) &&
        inputValueBigNumber.isLessThanOrEqualTo(walletBalanceBigNumber)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.AMOUNT_LIMIT,
          min: minValue,
        };
      }

      // II
      if (
        walletBalanceBigNumber.isLessThan(minValue) &&
        inputValueBigNumber.isGreaterThan(walletBalanceBigNumber)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.INSUFFICIENT_BALANCE,
        };
      }

      // III
      if (
        walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
        maxValueBigNumber.isLessThan(minValue) &&
        inputValueBigNumber.isLessThanOrEqualTo(minValue)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.AMOUNT_LIMIT,
          min: minValue,
        };
      }

      // IV
      if (
        walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
        maxValueBigNumber.isLessThan(minValue) &&
        inputValueBigNumber.isGreaterThan(minValue) &&
        inputValueBigNumber.isLessThanOrEqualTo(walletBalanceBigNumber)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.REPAY,
        };
      }

      // V
      if (
        walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
        maxValueBigNumber.isLessThan(minValue) &&
        inputValueBigNumber.isGreaterThan(walletBalanceBigNumber)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.INSUFFICIENT_BALANCE,
        };
      }

      // VI
      if (
        walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
        maxValueBigNumber.isGreaterThanOrEqualTo(minValue) &&
        recommendedBigNumber.isLessThanOrEqualTo(minValue) &&
        inputValueBigNumber.isLessThan(minValue)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.AMOUNT_LIMIT,
          min: minValue,
        };
      }

      // VII
      if (
        walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
        maxValueBigNumber.isGreaterThanOrEqualTo(minValue) &&
        recommendedBigNumber.isLessThanOrEqualTo(minValue) &&
        inputValueBigNumber.isGreaterThanOrEqualTo(minValue) &&
        inputValueBigNumber.isLessThan(maxValue)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.LIQUIDATED,
        };
      }

      // VIII
      if (
        walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
        maxValueBigNumber.isGreaterThanOrEqualTo(minValue) &&
        recommendedBigNumber.isLessThanOrEqualTo(minValue) &&
        inputValueBigNumber.isGreaterThanOrEqualTo(maxValue) &&
        inputValueBigNumber.isLessThanOrEqualTo(walletBalanceBigNumber)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.REPAY,
        };
      }

      // IX
      if (
        walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
        maxValueBigNumber.isGreaterThanOrEqualTo(minValue) &&
        recommendedBigNumber.isLessThanOrEqualTo(minValue) &&
        inputValueBigNumber.isGreaterThan(walletBalanceBigNumber)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.INSUFFICIENT_BALANCE,
        };
      }

      // X
      if (
        walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
        maxValueBigNumber.isGreaterThanOrEqualTo(minValue) &&
        recommendedBigNumber.isGreaterThan(minValue) &&
        inputValueBigNumber.isLessThan(minValue)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.AMOUNT_LIMIT,
          min: minValue,
        };
      }

      // XI
      if (
        walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
        maxValueBigNumber.isGreaterThanOrEqualTo(minValue) &&
        recommendedBigNumber.isGreaterThan(minValue) &&
        inputValueBigNumber.isGreaterThanOrEqualTo(minValue) &&
        inputValueBigNumber.isLessThanOrEqualTo(recommended)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.COLLATERAL,
          max: recommendedBigNumber
            .multipliedBy(RECOMMENDED_REMOVED_COLLATERAL_LIMIT_MARGIN)
            .toSafeFixed(H_TOKEN_DECIMALS, DefiUtils.ROUND_DOWN),
        };
      }

      // XII
      if (
        walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
        maxValueBigNumber.isGreaterThanOrEqualTo(minValue) &&
        recommendedBigNumber.isGreaterThan(minValue) &&
        inputValueBigNumber.isGreaterThan(recommended) &&
        inputValueBigNumber.isLessThanOrEqualTo(maxValue)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.RECOMMENDED,
          max: recommendedBigNumber
            .multipliedBy(RECOMMENDED_REMOVED_COLLATERAL_LIMIT_MARGIN)
            .toSafeFixed(H_TOKEN_DECIMALS, DefiUtils.ROUND_DOWN),
        };
      }

      // XIII
      if (
        walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
        maxValueBigNumber.isGreaterThanOrEqualTo(minValue) &&
        recommendedBigNumber.isGreaterThan(minValue) &&
        inputValueBigNumber.isGreaterThan(maxValue) &&
        inputValueBigNumber.isLessThanOrEqualTo(walletBalanceBigNumber)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.REPAY,
        };
      }

      // XIV
      if (
        walletBalanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
        maxValueBigNumber.isGreaterThanOrEqualTo(minValue) &&
        recommendedBigNumber.isGreaterThan(minValue) &&
        inputValueBigNumber.isGreaterThan(walletBalanceBigNumber)
      ) {
        return {
          errorKey: MESSAGE_ERROR_KEYS.INSUFFICIENT_BALANCE,
        };
      }

      return null;
    }
  }, [
    props,
    maxRemovableCollateralBalance,
    recommendedMaxRemovableCollateralBalance,
    isLoggedIn,
    hasEnoughEGLDBalance,
    hasTakenBorrows,
  ]);

  const handleMigrateError = useCallback(() => {
    if (props.operation !== OPERATION_KEYS.MIGRATE) {
      return null;
    }

    const { inputValue, balance, minValue, symbol, source } = props;

    const inputValueBigNumber = new DefiUtils(inputValue);
    const balanceBigNumber = new DefiUtils(balance);
    const recommendedBigNumber = new DefiUtils(
      recommendedMaxRemovableCollateralBalance
    );
    const maxBigNumber = new DefiUtils(maxRemovableCollateralBalance);

    const isHsEgldCollateral =
      symbol === EXCHANGE_RATE_KEY.HsEGLD && source === TOKEN_SOURCE.collateral;

    const isSEgldWallet =
      symbol === EXCHANGE_RATE_KEY.sEGLD && source === TOKEN_SOURCE.wallet;

    if (inputValueBigNumber.isZero()) {
      return null;
    }

    if (!isLoggedIn || balanceBigNumber.isEqualTo(0)) {
      return {
        errorKey: MESSAGE_ERROR_KEYS.NO_FUNDS_AVAILABLE,
      };
    }

    if (!hasEnoughEGLDBalance) {
      return {
        errorKey: MESSAGE_ERROR_KEYS.GAS_FEE,
      };
    }

    if (
      balanceBigNumber.isLessThan(minValue) &&
      inputValueBigNumber.isLessThanOrEqualTo(balanceBigNumber)
    ) {
      return {
        errorKey: MESSAGE_ERROR_KEYS.AMOUNT_LIMIT,
        min: isSEgldWallet
          ? new DefiUtils(minValue)
              .multipliedBy('1.0001')
              .toSafeFixed(
                sEgldMarket.underlying.decimals,
                DefiUtils.ROUND_DOWN
              )
          : minValue,
      };
    }

    if (
      balanceBigNumber.isLessThan(minValue) &&
      inputValueBigNumber.isGreaterThan(balanceBigNumber)
    ) {
      return {
        errorKey: MESSAGE_ERROR_KEYS.INSUFFICIENT_BALANCE,
      };
    }

    if (
      balanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
      inputValueBigNumber.isLessThan(minValue)
    ) {
      return {
        errorKey: MESSAGE_ERROR_KEYS.AMOUNT_LIMIT,
        min: isSEgldWallet
          ? new DefiUtils(minValue)
              .multipliedBy('1.0001')
              .toSafeFixed(
                sEgldMarket.underlying.decimals,
                DefiUtils.ROUND_DOWN
              )
          : minValue,
      };
    }

    const isBalanceEqualToRecommended = new DefiUtils(
      balanceBigNumber
    ).isEqualTo(recommendedBigNumber);

    if (
      (!isHsEgldCollateral ||
        (isHsEgldCollateral && !hasTakenBorrows) ||
        (isHsEgldCollateral && isBalanceEqualToRecommended)) &&
      balanceBigNumber.isGreaterThanOrEqualTo(minValue) &&
      inputValueBigNumber.isGreaterThan(balanceBigNumber)
    ) {
      return {
        errorKey: MESSAGE_ERROR_KEYS.AMOUNT_LIMIT,
        max: balanceBigNumber.toString(),
      };
    }

    if (
      !isHsEgldCollateral ||
      !hasTakenBorrows ||
      isBalanceEqualToRecommended
    ) {
      return null;
    }

    if (
      recommendedBigNumber.isEqualTo(balanceBigNumber) &&
      inputValueBigNumber.isLessThanOrEqualTo(recommendedBigNumber)
    ) {
      return {
        errorKey: MESSAGE_ERROR_KEYS.COLLATERAL,
        max: recommendedBigNumber.toString(),
      };
    }

    // hsegld collateral conditions with borrow

    if (
      recommendedBigNumber.isLessThanOrEqualTo(0) &&
      inputValueBigNumber.isLessThanOrEqualTo(maxBigNumber)
    ) {
      return {
        errorKey: MESSAGE_ERROR_KEYS.LIQUIDATED,
      };
    }

    if (
      recommendedBigNumber.isLessThanOrEqualTo(0) &&
      inputValueBigNumber.isGreaterThan(maxBigNumber) &&
      inputValueBigNumber.isLessThanOrEqualTo(balanceBigNumber)
    ) {
      return {
        errorKey: MESSAGE_ERROR_KEYS.REPAY,
      };
    }

    if (
      recommendedBigNumber.isLessThanOrEqualTo(0) &&
      inputValueBigNumber.isGreaterThan(maxBigNumber)
    ) {
      return {
        errorKey: MESSAGE_ERROR_KEYS.INSUFFICIENT_BALANCE,
      };
    }

    //

    if (
      recommendedBigNumber.isGreaterThan(0) &&
      inputValueBigNumber.isLessThanOrEqualTo(recommendedBigNumber)
    ) {
      return {
        errorKey: MESSAGE_ERROR_KEYS.COLLATERAL,
        max: recommendedBigNumber
          .multipliedBy(RECOMMENDED_REMOVED_COLLATERAL_LIMIT_MARGIN)
          .toSafeFixed(H_TOKEN_DECIMALS, DefiUtils.ROUND_DOWN),
      };
    }

    if (
      recommendedBigNumber.isGreaterThan(0) &&
      inputValueBigNumber.isGreaterThan(recommendedBigNumber) &&
      inputValueBigNumber.isLessThanOrEqualTo(maxBigNumber)
    ) {
      return {
        errorKey: MESSAGE_ERROR_KEYS.RECOMMENDED,
        max: recommendedBigNumber
          .multipliedBy(RECOMMENDED_REMOVED_COLLATERAL_LIMIT_MARGIN)
          .toSafeFixed(H_TOKEN_DECIMALS, DefiUtils.ROUND_DOWN),
      };
    }

    if (
      recommendedBigNumber.isGreaterThan(0) &&
      inputValueBigNumber.isGreaterThan(maxBigNumber) &&
      inputValueBigNumber.isLessThanOrEqualTo(balanceBigNumber)
    ) {
      return {
        errorKey: MESSAGE_ERROR_KEYS.REPAY,
      };
    }

    if (
      recommendedBigNumber.isGreaterThan(0) &&
      inputValueBigNumber.isGreaterThan(balanceBigNumber)
    ) {
      return {
        errorKey: MESSAGE_ERROR_KEYS.INSUFFICIENT_BALANCE,
      };
    }
    return null;
  }, [
    props,
    recommendedMaxRemovableCollateralBalance,
    maxRemovableCollateralBalance,
    isLoggedIn,
    hasEnoughEGLDBalance,
    hasTakenBorrows,
    sEgldMarket.underlying.decimals,
  ]);

  const error = useMemo(() => {
    switch (props.operation) {
      case OPERATION_KEYS.STAKE: {
        return handleStakeError();
      }

      case OPERATION_KEYS.UNSTAKE: {
        return handleUnstakeError();
      }

      case OPERATION_KEYS.MIGRATE: {
        return handleMigrateError();
      }

      default: {
        return null;
      }
    }
  }, [
    handleMigrateError,
    handleStakeError,
    handleUnstakeError,
    props.operation,
  ]);

  return error;
};

export default useLiquidStakingMessageError;
