import { createContext } from 'react';

import useStoreTokens from '@/contexts/LiquidStakingAppContext/hooks/useStoreTokens';
import useStoreUndelegateNfts from '@/contexts/LiquidStakingAppContext/hooks/useStoreUndelegateNfts';

const LiquidStakingAppContext = createContext({});

const LiquidStakingAppConsumer = LiquidStakingAppContext.Consumer;

const LiquidStakingAppProvider = ({ children }: { children: JSX.Element }) => {
  useStoreTokens();
  useStoreUndelegateNfts();

  return (
    <LiquidStakingAppContext.Provider value={{}}>
      {children}
    </LiquidStakingAppContext.Provider>
  );
};

export default LiquidStakingAppContext;

export { LiquidStakingAppConsumer, LiquidStakingAppProvider };
