import classNames from 'classnames';
import React from 'react';

export const SubmitButton: React.FC<any> = ({
  onClick,
  children,
  secondary,
  disabled,
}) => {
  const active = !disabled;
  const primary = !secondary;
  return (
    <button
      data-testid='submitButton'
      onClick={() => {
        if (disabled) return;
        onClick && onClick();
      }}
      className={classNames(
        'h-[56px] w-full rounded-full text-center font-hass capitalize leading-6',
        {
          btnHover: active,
          'bg-[#006FFF] text-white': primary,
          'cursor-not-allowed': disabled,
          'bg-[#006FFF]/70': disabled && primary,
          'bg-[#E4E4F8] text-[#006FFF] dark:bg-[#2C2956]': secondary,
        }
      )}
    >
      {children}
    </button>
  );
};
