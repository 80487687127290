import DefiUtils from 'defi-utils';

import { Market } from '@/store/protocol';

export const getTotalBorrowUSD = (markets: Record<string, Market>) => {
  return Object.values(markets)
    .reduce(
      (prev, current) => prev.plus(current.totalBorrowUSD),
      new DefiUtils(0)
    )
    .toString();
};

export const getTotalSupplyUSD = (markets: Record<string, Market>) => {
  return Object.values(markets)
    .reduce(
      (prev, current) => prev.plus(current.totalSupplyUSD),
      new DefiUtils(0)
    )
    .toString();
};
