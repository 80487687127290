import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import PopupHead from '@/components/popups/PopupBed/components/PopupHead';

export const Header = () => {
  const { t } = useTranslation('governance');
  return (
    <PopupHead
      containerProps={{
        className: classNames('!h-[52px]',),
      }}
    >
      <div
        className={classNames(
          'w-full capitalize',
          'mx-4',
          'relative',
          'text-center',
          'h-full',
          'flex',
          'items-center',
          'justify-center',
        )}
      >
        <div
          className={classNames(
            'text-[16px]',
            'leading-[18px]',
            'tracking-[0.48px]',
            'ml-[-6px]',
            'mb-[3px]'
          )}
        >
          {t('governance', 'Governance')}
        </div>

        <div
          className={classNames(
            'h-[1px]',
            'bg-[#D1D1EA]',
            'dark:bg-[#292651]',
            'w-full',
            'absolute',
            'bottom-[1px]',
            'left-0',
          )}
        />
      </div>
    </PopupHead>
  );
};
