import DefiUtils from 'defi-utils';
import { gql } from 'graphql-request';

import client from '@/services/tao-stats/client';
import { QueryTaoApr } from '@/services/tao-stats/types';
import logger from '@/utils/logger';

export const getTaoApr = async (): Promise<string> => {
  const query = gql`
    query TaoApr {
      validators(
        filter: {
          address: {
            equalTo: "5EhvL1FVkQPpMjZX4MAADcW42i3xPSF1KiCpuaxTYVr28sux"
          }
        }
      ) {
        nodes {
          nominatorReturnPerK
        }
      }
    }
  `;

  const variables = {};

  const queryTaoApr = await client
    .request<QueryTaoApr>(query, variables)
    .catch((error) => logger.error(error?.toString?.()));

  logger.info({
    queryTaoApr,
  });

  const nominatorReturnPerK =
    queryTaoApr?.validators.nodes?.[0].nominatorReturnPerK;

  return nominatorReturnPerK
    ? new DefiUtils(
        new DefiUtils(nominatorReturnPerK || '0')
          .toFullDecimals(9)
          .multipliedBy(365)
          .dividedBy(1000)
          .multipliedBy(100),
      )
        .dividedBy(0.91)
        .multipliedBy(0.82)
        .toSafeString()
    : '17';
};
