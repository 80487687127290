import { ApiNetworkProvider } from '@multiversx/sdk-network-providers';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/store';
import {
  networkStateSelector,
  setNetworkState,
  updateNetworkData,
} from '@/store/network';

import { chainType, networkConfig } from '@/config/network';

const useApiNetworkProviderSync = () => {
  const dispatch = useAppDispatch();
  const apiNetworkProvider = useAppSelector(
    networkStateSelector<ApiNetworkProvider>('apiNetworkProvider'),
  );

  // Proxy provider sync
  useEffect(() => {
    const askForApiNetworkProvider = async () => {
      if (apiNetworkProvider) {
        return;
      }

      const publicApiEndpoint = networkConfig[chainType].apiAddress;

      if (!publicApiEndpoint) {
        throw Error('There is no public api configured! Check env vars');
      }

      const newApiNetworkProvider = new ApiNetworkProvider(publicApiEndpoint, {
        timeout: Number(networkConfig[chainType].apiTimeout),
      });
      dispatch(setNetworkState('apiNetworkProvider', newApiNetworkProvider));
      dispatch(updateNetworkData(newApiNetworkProvider));
    };

    askForApiNetworkProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useApiNetworkProviderSync;
