import { useRouter } from 'next/router';
import { createContext } from 'react';

import FloatTransactions from '@/components/FloatTransactions';

import useLocalStoreTransactionGroups from '@/contexts/TransactionContext/hooks/useLocalStoreTransactionGroups';
import useSendPendingTransactions from '@/contexts/TransactionContext/hooks/useSendPendingTransactions';
import useStoreCurrentTransactionGroup from '@/contexts/TransactionContext/hooks/useStoreCurrentTransactionGroup';
import useStoreTransactionGroups from '@/contexts/TransactionContext/hooks/useStoreTransactionGroups';
import useStoreWebWalletTransactionsSigned from '@/contexts/TransactionContext/hooks/useStoreWebWalletTransactionsSigned';

export const TransactionContext = createContext({});

const TransactionConsumer = TransactionContext.Consumer;

const TransactionProvider = ({ children }: { children: JSX.Element }) => {
  const router = useRouter();

  useStoreCurrentTransactionGroup();
  useStoreWebWalletTransactionsSigned();
  useStoreTransactionGroups();
  useSendPendingTransactions();
  useLocalStoreTransactionGroups();

  return (
    <TransactionContext.Provider value={{}}>
      {children}

      {(router.route.includes('/stake') ||
        router.route.includes('/staking/app') ||
        router.route.includes('/vote') ||
        router.route.includes('/safety/app')) && <FloatTransactions />}
    </TransactionContext.Provider>
  );
};

export default TransactionContext;

export { TransactionConsumer, TransactionProvider };
