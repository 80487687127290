import { INTERACTION_KEY } from '@/components/popups/XLaunchpadSnapshotPopup/types';

import clsxm from '@/services/clsxm';

export interface InteractionSwitchProps {
  value: string;
  className?: string;
  onChange: (value: INTERACTION_KEY) => void;
}

const InteractionSwitch = ({
  className,
  value,
  onChange,
}: InteractionSwitchProps) => {
  return (
    <div
      className={clsxm(
        'flex justify-between w-full',
        className,
      )}
    >
      {[INTERACTION_KEY.STAKE, INTERACTION_KEY.UNSTAKE].map((interaction) => {
        return (
          <div
            key={interaction}
            className={clsxm(
              'text-[12px]',
              'lg:text-[16px]',
              'cursor-pointer',
              'font-semibold',
              'h-[40px] w-[149px]',
              ' lg:h-[56px] lg:w-[208px]',
              'flex',
              'justify-center',
              'items-center',
              'rounded-[11.5px] lg:rounded-[16px]',
              'border-[2px]',
              'border-[#E2E1FF]',
              interaction === value
                ? ['bg-[#E2E1FF] dark:bg-[#676198] dark:border-[#676198] dark:hover:bg-[#837EB3] dark:hover:border-[#837EB3] dark:text-white hover:bg-[#EDEDFF] hover:border-[#EDEDFF]', 'text-[#1F2226]']
                : ['text-[#A9A7D1] hover:bg-[#F9F9FF] hover:border-[#E2E1FF] dark:hover:bg-[#2D2963] dark:border-[#676198]'],
            )}
            onClick={() => {
              onChange(interaction);
            }}
          >
            <span className={clsxm(INTERACTION_KEY.UNSTAKE === interaction && "translate-x-[-0.5px]")} >{interaction}</span>

          </div>
        );
      })}
    </div>
  );
};

export default InteractionSwitch;
