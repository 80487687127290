import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { useMemo } from 'react';

import useTheme from '@/hooks/useTheme';

import getColorAtPosition from '@/utils/getColorAtPosition';

interface SliderInputProps {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
}

const sliderColors = [
  {
    offset: 0,
    color: '#2EBEFF',
  },
  {
    offset: 66.66,
    color: '#6C6EED',
  },
  {
    offset: 100,
    color: '#53FFAC',
  },
];

const ranges = 6;

const maxPercent = 97.5;

export const SliderInput = ({ value, onChange }: SliderInputProps) => {
  const { isDark } = useTheme();

  const progressPercentage = useMemo(() => {
    return new DefiUtils(value || '0')
      .multipliedBy(maxPercent)
      .dividedBy(100)
      .toString();
  }, [value]);

  const progressBulletColor = useMemo(() => {
    return getColorAtPosition(sliderColors, parseInt(progressPercentage));
  }, [progressPercentage]);

  const rangeItems = useMemo(() => {
    return new Array(ranges).fill(0).map((_, index, array) => {
      const position = new DefiUtils(maxPercent)
        .dividedBy(array.length - 1)
        .multipliedBy(index)
        .toNumber();

      const color = new DefiUtils(progressPercentage).isGreaterThanOrEqualTo(
        position
      )
        ? getColorAtPosition(sliderColors, position)
        : isDark
        ? '#32334A'
        : '#E7E7F9';

      return {
        position,
        color,
      };
    });
  }, [isDark, progressPercentage]);

  return (
    <div className={classNames('mb-5', 'block', 'w-full')}>
      <span
        className={classNames(
          'block',
          'text-[#535367]',
          'dark:text-[#625e8f]',
          'text-xs',
          'leading-[15px]',
          'mb-[2px]',
          'text-right'
        )}
      >
        {value}%
      </span>

      <div className={classNames('relative', 'cursor-pointer')}>
        <input
          type='range'
          min='0'
          max='100'
          step='1'
          value={value}
          onChange={(event) => onChange(event?.target.value)}
          className={classNames(
            'block',
            'w-full',
            'opacity-0',
            'h-[9px]',
            'z-[2]',
            'relative',
            'cursor-pointer'
          )}
        />

        {/* progressPercentage color */}
        <div
          style={{
            background: `linear-gradient(to right, ${sliderColors
              .map(({ color, offset }) => `${color} ${offset}%`)
              .join(', ')})`,
          }}
          className={classNames(
            'w-full',
            'h-[4px]',
            'block',
            'absolute',
            'top-[50%]',
            '[transform:translateY(-50%)]',
            'rounded-[8px]',
            'z-[1]'
          )}
        />

        {/* anti-progressPercentage color */}
        <div
          style={{
            width: `${new DefiUtils(100)
              .minus(progressPercentage)
              .toString()}%`,
          }}
          className={classNames(
            'w-full',
            'h-[4px]',
            'block',
            'absolute',
            'top-[50%]',
            '[transform:translateY(-50%)]',
            'bg-[#fbfaff]',
            'dark:bg-[#101015]',
            'rounded-[8px]',
            'z-[1]',
            'right-0',
            'border-[1px]',
            'border-[#E7E7F9]',
            'dark:border-[#32334A]'
          )}
        />

        {/* bullets */}
        <div
          className={classNames(
            'h-full',
            'w-full',
            'absolute',
            'flex',
            'top-0',
            'left-0',
            'z-[1]',
            'justify-between'
          )}
        >
          {rangeItems.map(({ color, position }) => {
            return (
              <div
                style={{
                  borderColor: color,
                }}
                key={position}
                className={classNames(
                  'h-[9px]',
                  'w-[9px]',
                  'border-[2px]',
                  'rounded-full',
                  'bg-white',
                  'dark:bg-[#101015]'
                )}
              />
            );
          })}
        </div>

        {/* current bullet */}
        <div
          style={{
            left: `${progressPercentage}%`,
            borderColor: progressBulletColor,
          }}
          className={classNames(
            'absolute',
            'h-[9px]',
            'w-[9px]',
            'bg-white',
            'dark:bg-[#101015]',
            'border-[2px]',
            'top-[50%]',
            '[transform:translateY(-50%)]',
            'rounded-full',
            'z-[1]'
          )}
        />
      </div>
    </div>
  );
};
