import { AbiRegistry, Address, AddressValue } from '@multiversx/sdk-core/out';

import liquidStakingABI from '@/abis/liquid-staking';
import client from '@/services/blockchain/client';
import { DelegationContract } from '@/services/blockchain/liquid-staking/types';
import {
  MAX_CACHE_TIME,
  queryClient,
  SECOND_IN_MILISECONDS,
} from '@/utils/query';

export const getUndelegateTokenId = async (
  liquidStakingAddress: string,
): Promise<string> => {
  const response = await client({
    address: liquidStakingAddress,
    method: 'getUndelegateTokenId',
    abi: AbiRegistry.create(liquidStakingABI),
  });

  return response?.[0]?.toString() || '0';
};

export const getCashReserve = async (
  liquidStakingAddress: string,
): Promise<string> => {
  const queryKey = [
    'blockchain:controller:getCashReserve',
    liquidStakingAddress,
  ];

  const queryFn = async () => {
    const response = await client({
      address: liquidStakingAddress,
      method: 'getCashReserve',
      abi: AbiRegistry.create(liquidStakingABI),
    });

    return response?.[0]?.toString() || '0';
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  }) as Promise<string>;
};

export const getLsTokenId = async (
  liquidStakingAddress: string,
): Promise<string> => {
  const response = await client({
    address: liquidStakingAddress,
    method: 'getLsTokenId',
    abi: AbiRegistry.create(liquidStakingABI),
  });

  return response?.[0]?.toString() || '0';
};

export const getExchangeRate = async (
  liquidStakingAddress: string,
): Promise<string> => {
  const queryKey = [
    'blockchain:liquid-staking:getExchangeRate',
    liquidStakingAddress,
  ];

  const queryFn = async () => {
    const response = await client({
      address: liquidStakingAddress,
      method: 'getExchangeRate',
      abi: AbiRegistry.create(liquidStakingABI),
    });

    return response?.[0]?.toString() || '0';
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  }) as Promise<string>;
};

export const getUnbondPeriod = async (
  liquidStakingAddress: string,
): Promise<string> => {
  const response = await client({
    address: liquidStakingAddress,
    method: 'getUnbondPeriod',
    abi: AbiRegistry.create(liquidStakingABI),
  });

  return response?.[0]?.toString() || '0';
};

export const getTotalFee = async (
  liquidStakingAddress: string,
): Promise<string> => {
  const response = await client({
    address: liquidStakingAddress,
    method: 'getTotalFee',
    abi: AbiRegistry.create(liquidStakingABI),
  });

  return response?.[0]?.toString() || '0';
};

export const getDelegationContractsList = async (
  liquidStakingAddress: string,
): Promise<string[]> => {
  const queryKey = ['blockchain:liquid-staking:getDelegationContractsList'];

  const queryFn = async () => {
    const response = await client({
      address: liquidStakingAddress,
      method: 'getDelegationContractsList',
      abi: AbiRegistry.create(liquidStakingABI),
    });

    // @ts-ignore
    return response?.[0]?.items.map((item) => item?.value.toString() || '0');
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: SECOND_IN_MILISECONDS * 360,
    staleTime: SECOND_IN_MILISECONDS * 360,
  }) as Promise<string[]>;
};

export const getDelegationContractData = async (
  liquidStakingAddress: string,
  delegationContractAddress: string,
): Promise<DelegationContract> => {
  const response = await client({
    address: liquidStakingAddress,
    method: 'getDelegationContractData',
    abi: AbiRegistry.create(liquidStakingABI),
    args: [new AddressValue(new Address(delegationContractAddress))],
  });

  const fieldsByName = response?.[0].fieldsByName;

  return {
    id: fieldsByName.get('contract').value.value.toString(),
    totalDelegated: fieldsByName.get('total_delegated').value.toString(),
    serviceFee: fieldsByName.get('service_fee').value.toString(),
    apr: fieldsByName.get('apr').value.toString(),
    pendingToDelegate: fieldsByName.get('pending_to_delegate').value.toString(),
  };
};

export const getDelegationContractsData = async (
  liquidStakingAddress: string,
): Promise<DelegationContract[]> => {
  const queryKey = ['blockchain:liquid-staking:getDelegationContractsData'];

  const queryFn = async () => {
    const delegationContractsAddress =
      await getDelegationContractsList(liquidStakingAddress);

    const delegationContracts = await Promise.all(
      delegationContractsAddress.map((delegationContractAddress) =>
        getDelegationContractData(
          liquidStakingAddress,
          delegationContractAddress,
        ),
      ),
    );

    return delegationContracts;
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  }) as Promise<DelegationContract[]>;
};
