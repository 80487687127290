import classNames from 'classnames';
import { domAnimation, LazyMotion, m } from 'framer-motion';

import useTheme from '@/hooks/useTheme';

import { USER_BALANCE_SOURCE } from '@/store/governance';

interface BalanceListProps {
  value: USER_BALANCE_SOURCE;
  onClick: (value: USER_BALANCE_SOURCE) => void;
  items: {
    id: string;
    name: string;
    Icon: any;
  }[];
}

export const BalanceList = ({ items, onClick, value }: BalanceListProps) => {
  const { isDark } = useTheme();

  return (
    <LazyMotion features={domAnimation}>
      <m.ul
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        transition={{ duration: 0.3 }}
        className={classNames(
          'absolute',
          'top-[60%]',
          'left-0',
          'border border-[#E7E7F9] bg-white',
          'dark:bg-[#171430]',
          'rounded-[8px]',
          'p-1.5',
          'w-[173px]',
          'flex',
          'flex-col',
          'gap-1.5',
          'z-[11]'
        )}
      >
        {items.map(({ name, Icon, id }) => (
          <li
            key={name}
            className={classNames(
              'flex',
              'gap-2.5',
              'cursor-pointer',
              'px-1.5',
              'py-1',
              'items-center',
              'rounded-[7.82878px]',
              id === value
                ? [
                    'bg-[#F3F0FF]',
                    'text-[#6A6A8C]',
                    'dark:bg-[#363150]',
                    'dark:text-[#625E8F]',
                  ]
                : ['text-[#9D9DC2]', 'dark:text-[#2C2C4A]']
            )}
            onClick={() => onClick(id  as USER_BALANCE_SOURCE)}
          >
            <div
              className={classNames(
                'border-[1px]',
                'border-[#BCBCE2]',
                'rounded-full',
                'h-[26px]',
                'w-[26px]',
                'flex',
                'items-center',
                'justify-center',
                'bg-[#fff]',
                'relative',
                'dark:border-[#3C3C5A]',
                'dark:bg-[#171430]'
              )}
            >
              <Icon fill={isDark ? '#ffffff' : '#000000'} />
              <img
                src='https://cdn.app.hatom.com/images/tokens/HTM.svg'
                alt='Hatom'
                className={classNames(
                  'w-[14px]',
                  'h-[14px]',
                  'block',
                  'absolute',
                  'bottom-[-2.5px]',
                  'right-[-6px]'
                )}
              />
            </div>

            <span
              className={classNames('text-sm capitalize', 'leading-[18px]')}
            >
              {name}
            </span>
          </li>
        ))}
      </m.ul>
    </LazyMotion>
  );
};
