import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import Hint from '@/components/Hint';
import InfoIcon from '@/components/Icons/InfoIcon';
import { items } from '@/components/popups/StakedHTMLockedSchedulePopup/helpers';
import ScrollBlockTargetElement from '@/components/ScrollBlockTargetElement';

import PopupBed from '../PopupBed';

const StakedHTMLockedSchedulePopup = () => {
  const { t } = useTranslation('staking-app');

  return (
    <PopupBed>
      <div className='p-4 pb-5'>
        <div className='flex items-center gap-1'>
          <div className='flex-xy-center h-[26px] w-[26px] rounded-[10px] bg-[#C6C4C4]'>
            <img className='w-5' src='https://cdn.app.hatom.com/images/img42.png' alt='' />
          </div>
          <div className='flex font-semibold'>
            {t('staked-htm-locked-schedule')}{' '}
            <div className='ml-1 mt-1.5'>
              <Hint
                delayEnter={10}
                backgroundColor='#000'
                offset={12}
                block
                content={
                  <div className='max-w-[185px] tracking-normal'>
                    {t('hint.5')}
                  </div>
                }
              >
                <InfoIcon
                  className='text-[#5C5C5C]'
                  width={10}
                  height={10}
                  strokeWidth='1.5'
                />
              </Hint>
            </div>
          </div>
        </div>

        <ScrollBlockTargetElement className='mt-[28px] -mr-3 h-[184px] overflow-auto pr-3'>
          <ul className='space-y-4'>
            {items.map(({ value, date, time, active = false }, i) => (
              <li
                key={i}
                className={classNames(
                  'flex justify-between gap-4 rounded-[8px] border border-[#BBBBBB] p-1.5 px-1 pr-3 font-semibold',
                  {
                    'text-[#5C5C5C]': !active,
                  }
                )}
              >
                <div className='flex items-center gap-2.5'>
                  <div
                    className={classNames(
                      'h-1.5 w-1.5 rounded-full bg-current',
                      {
                        'bg-[#5258CE]': active,
                      }
                    )}
                  ></div>
                  <div>{Intl.NumberFormat('en-us').format(value)} HTM</div>
                </div>
                <div>
                  {t('unlock-on')} {date}
                </div>
                <div>
                  {t('at')} {time}
                </div>
              </li>
            ))}
          </ul>
        </ScrollBlockTargetElement>
      </div>
    </PopupBed>
  );
};

export default StakedHTMLockedSchedulePopup;
