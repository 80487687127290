import { FC } from 'react';

import { spreadAvailable } from '@/utils/helpers';

const BasicRow: FC<{
  preset?: Array<number>;
  cells: Array<any>;
  className?: string;
  onClick?: () => {};
}> = ({ cells = [], preset = [25], className, onClick }) => {
  preset = spreadAvailable(preset, cells.length);
  return (
    <div onClick={onClick} className={`${className} group relative flex`}>
      {cells.map((cell, i) => {
        return (
          <div
            key={i}
            style={{
              flexBasis: `${preset[i]}%`,
            }}
          >
            {cell}
          </div>
        );
      })}
    </div>
  );
};

export default BasicRow;
