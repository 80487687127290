import { createContext } from 'react';

import useStoreIntervalTimeLeft from '@/contexts/BlockchainContext/hooks/useStoreIntervalTimeLeft';

const BlockchainContext = createContext({});

const BlockchainConsumer = BlockchainContext.Consumer;

const BlockchainProvider = ({ children }: { children: JSX.Element }) => {
  useStoreIntervalTimeLeft();

  return (
    <BlockchainContext.Provider value={{}}>
      {children}
    </BlockchainContext.Provider>
  );
};

export default BlockchainContext;

export { BlockchainConsumer, BlockchainProvider };
