export const styleStore = {
  EGLD: {
    assetImgPath: 'https://cdn.app.hatom.com/images/img1.svg',
    featureGradient:
      'linear-gradient(271.36deg, #1DDAB8 32.94%, #80F8E2 90.76%)',
  },
  sEGLD: {
    assetImgPath: 'https://cdn.app.hatom.com/images/sEGLD.svg',
    featureGradient:
      'linear-gradient(245.33deg, #006FFF 34.26%, #63A7FF 89.62%)',
  },
  HsEGLD: {
    assetImgPath: 'https://cdn.app.hatom.com/images/liquidstaking/app/tokens/hsegld.png',
    featureGradient: 'linear-gradient(287.1deg, #7E19FF 7.71%, #A35EFB 84%)',
  },
};
