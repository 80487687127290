import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { TX_STATUS } from '@/hooks/core/useSignMultipleTransactions';

import ClosePopupBtn from '@/components/ClosePopupBtn';
import TxHashBox from '@/components/popups/LendingPendingTransactionPopup/components/TxHashBox';
import { CheckIcon } from '@/components/popups/LiquidPendingTransactionPopup/components/Icons/CheckIcon';
import { CrossIcon } from '@/components/popups/LiquidPendingTransactionPopup/components/Icons/CrossIcon';
import { ExclamationMarkIcon } from '@/components/popups/LiquidPendingTransactionPopup/components/Icons/ExclamationMarkIcon';
import PendingLoader from '@/components/popups/LiquidPendingTransactionPopup/components/PendingLoader';
import { styleStore } from '@/components/popups/LiquidPendingTransactionPopup/helpers';
import StatusAnimation from '@/components/popups/PendingTransactionPopup/components/StatusAnimation';
import TransactionButton from '@/components/TransactionButton';

import { useAppSelector } from '@/store';
import { protocolSelector } from '@/store/protocol';
import {
  TRANSACTION_SUBGROUP_TYPE,
  transactionSelector,
} from '@/store/transaction';

import { chainType, networkConfig } from '@/config/network';
import { nFormatter } from '@/utils/helpers';

const TxPhase: React.FC<any> = ({ txStatus, statusData }) => {
  const { t } = useTranslation();
  const { currentTransactions, transactionAmount, transactionConfig } =
    useAppSelector(transactionSelector);
  const { liquidStaking, liquidLocking } = useAppSelector(protocolSelector);

  const transactionsSent = useMemo(() => {
    return currentTransactions.filter(({ status }) => status !== 'signed');
  }, [currentTransactions]);

  const operationType = useMemo(() => {
    return transactionConfig?.subgroup;
  }, [transactionConfig?.subgroup]);

  const assetKey = useMemo(() => {
    if (txStatus === TX_STATUS.FAILED) {
      switch (operationType) {
        case TRANSACTION_SUBGROUP_TYPE.STAKE_UNDERLYING:
        case TRANSACTION_SUBGROUP_TYPE.STAKE_TOKEN:
        case TRANSACTION_SUBGROUP_TYPE.CLAIM:
        case TRANSACTION_SUBGROUP_TYPE.STAKE_TOKEN_COLLATERAL: {
          return 'EGLD';
        }

        case TRANSACTION_SUBGROUP_TYPE.UNSTAKE_UNDERLYING:
        case TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_WALLET:
        case TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_COLLATERAL: {
          return 'sEGLD';
        }

        case TRANSACTION_SUBGROUP_TYPE.UNSTAKE_TOKEN_COLLATERAL:
        case TRANSACTION_SUBGROUP_TYPE.UNSTAKE_TOKEN:
        case TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_TOKEN_WALLET:
        case TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_UNDERLYING_WALLET:
        case TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_TOKEN_COLLATERAL:
        case TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_UNDERLYING_WALLET: {
          return 'HsEGLD';
        }

        case TRANSACTION_SUBGROUP_TYPE.LOCK:
        case TRANSACTION_SUBGROUP_TYPE.UNLOCK:
        case TRANSACTION_SUBGROUP_TYPE.UNBOND: {
          return transactionConfig?.token;
        }

        default: {
          return 'EGLD';
        }
      }
    }

    switch (operationType) {
      case TRANSACTION_SUBGROUP_TYPE.UNSTAKE_UNDERLYING:
      case TRANSACTION_SUBGROUP_TYPE.CLAIM:
      case TRANSACTION_SUBGROUP_TYPE.UNSTAKE_TOKEN_COLLATERAL:
      case TRANSACTION_SUBGROUP_TYPE.UNSTAKE_TOKEN: {
        return 'EGLD';
      }

      case TRANSACTION_SUBGROUP_TYPE.STAKE_UNDERLYING:
      case TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_UNDERLYING_WALLET:
      case TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_UNDERLYING_WALLET: {
        return 'sEGLD';
      }

      case TRANSACTION_SUBGROUP_TYPE.STAKE_TOKEN:
      case TRANSACTION_SUBGROUP_TYPE.STAKE_TOKEN_COLLATERAL:
      case TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_TOKEN_WALLET:
      case TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_TOKEN_COLLATERAL:
      case TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_COLLATERAL:
      case TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_WALLET: {
        return 'HsEGLD';
      }

      case TRANSACTION_SUBGROUP_TYPE.LOCK:
      case TRANSACTION_SUBGROUP_TYPE.UNLOCK:
      case TRANSACTION_SUBGROUP_TYPE.UNBOND: {
        return transactionConfig?.token;
      }

      default: {
        return 'EGLD';
      }
    }
  }, [operationType, transactionConfig?.token, txStatus]);

  const transactionValue = useMemo(() => {
    return transactionConfig?.result || '0';
  }, [transactionConfig?.result]);

  const successMsg = useMemo(() => {
    switch (operationType) {
      case TRANSACTION_SUBGROUP_TYPE.STAKE_UNDERLYING: {
        return t(
          'liquid-app:successMsg.stake',
          "It's Staked! You just received the full amount in your wallet.",
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.STAKE_TOKEN: {
        return t(
          'liquid-app:successMsg.stake',
          "It's Staked! You just received the full amount in your wallet.",
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.STAKE_TOKEN_COLLATERAL: {
        return t(
          'liquid-app:successMsg.stake_collateral',
          "It's Staked! You just supplied the full amount as collateral.",
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.UNSTAKE_UNDERLYING: {
        return t(
          'liquid-app:successMsg.unstake',
          "It's Unstaked! You can claim the full amount in ~{{hours}} hours.",
          {
            hours: new DefiUtils(networkConfig[chainType].epochInHours)
              .multipliedBy(liquidStaking.unbondPeriod)
              .toString(),
          },
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.UNSTAKE_TOKEN: {
        return t(
          'liquid-app:successMsg.unstake',
          "It's Unstaked! You can claim the full amount in ~{{hours}} hours.",
          {
            hours: new DefiUtils(networkConfig[chainType].epochInHours)
              .multipliedBy(liquidStaking.unbondPeriod)
              .toString(),
          },
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.UNSTAKE_TOKEN_COLLATERAL: {
        return t(
          'liquid-app:successMsg.unstake',
          "It's Unstaked! You can claim the full amount in ~{{hours}} hours.",
          {
            hours: new DefiUtils(networkConfig[chainType].epochInHours)
              .multipliedBy(liquidStaking.unbondPeriod)
              .toString(),
          },
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.CLAIM: {
        return t(
          'liquid-app:successMsg.claim',
          'Congratulations! You just received the full amount in your wallet.',
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_TOKEN_WALLET: {
        return t(
          'liquid-app:successMsg.claim',
          'Congratulations! You just received the full amount in your wallet.',
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_UNDERLYING_WALLET: {
        return t(
          'liquid-app:successMsg.claim',
          'Congratulations! You just received the full amount in your wallet.',
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_COLLATERAL: {
        return t(
          'liquid-app:successMsg.migrate',
          'Congratulations! You just supplied the full amount as collateral.',
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_WALLET: {
        return t(
          'liquid-app:successMsg.claim',
          'Congratulations! You just received the full amount in your wallet.',
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_UNDERLYING_WALLET: {
        return t(
          'liquid-app:successMsg.claim',
          'Congratulations! You just received the full amount in your wallet.',
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_TOKEN_COLLATERAL: {
        return t(
          'liquid-app:successMsg.migrate',
          'Congratulations! You just supplied the full amount as collateral.',
        );
      }

      case TRANSACTION_SUBGROUP_TYPE.LOCK: {
        return `Congratulations! You just staked the full amount.`;
      }
      case TRANSACTION_SUBGROUP_TYPE.UNLOCK: {
        const days = new DefiUtils(networkConfig[chainType].epochInHours)
          .multipliedBy(liquidLocking.unbondPeriod)
          .dividedBy(24)
          .toFixed(0, DefiUtils.ROUND_CEIL);

        return t(
          'liquid-locking-app:successMsg.unstake',
          "It's Unstaked! You can claim the full amount in ~{{days}} days.",
          {
            days,
          },
        );
      }
      case TRANSACTION_SUBGROUP_TYPE.UNBOND: {
        return t(
          'liquid-locking-app:successMsg.claim',
          'Congratulations! You just received the full amount in your wallet.',
        );
      }
      default: {
        return '';
      }
    }
  }, [
    operationType,
    t,
    liquidStaking.unbondPeriod,
    liquidLocking.unbondPeriod,
  ]);

  const getResultLabel = (status: string) => {
    switch (status.toLowerCase()) {
      case 'success':
        return {
          label: t('success'),
          icon: <CheckIcon />,
        };
      case 'fail':
        return {
          label: t('failed'),
          icon: <CrossIcon />,
        };
      default:
        return {
          label: t('unknown'),
          icon: <ExclamationMarkIcon />,
        };
    }
  };

  const getStatusLabel = () => {
    switch (txStatus) {
      case TX_STATUS.AWAITING_CONFIRMATION:
        return {
          title: t('awaiting-confirmation'),
          message: t('confirm-transaction'),
        };
      case TX_STATUS.SENT:
        return {
          title: t('transaction-sent'),
          message: (
            <>
              {t('awaiting-network-response')}{' '}
              {transactionAmount >= 2 &&
                `(${transactionsSent.length}/${transactionAmount})`}
            </>
          ),
        };
      case TX_STATUS.COMPLETED:
        const ok = statusData == 'success';
        return {
          title: ok ? t('transaction-completed') : 'Transaction failed',
          icon: ok ? <></> : <StatusAnimation status={statusData} />,
          message: (
            <span
              className='text-[14px] font-normal text-[#7575A7]'
              dangerouslySetInnerHTML={{
                __html: ok ? successMsg : 'Your transaction has failed.',
              }}
            />
          ),
        };
      case TX_STATUS.FAILED:
        return {
          title: 'Transaction Canceled',
          message: (
            <span className='mt-4 inline-block text-[14px] font-normal text-[#7575A7]'>
              Your transaction has been canceled.
            </span>
          ),
          icon: <StatusAnimation status={undefined} />,
        };
      default:
        return { title: 'Awaiting user confirmation' };
    }
  };

  const resultLabel = statusData && getResultLabel(statusData);

  const statusTitle = getStatusLabel().title;
  const statusMessage = getStatusLabel().message || null;
  const statusIcon = getStatusLabel().icon || <PendingLoader />;

  const completed = ![TX_STATUS.AWAITING_CONFIRMATION, TX_STATUS.SENT].includes(
    txStatus,
  );

  const success = txStatus == TX_STATUS.COMPLETED && statusData === 'success';
  const badStatus =
    txStatus == TX_STATUS.FAILED ||
    (txStatus == TX_STATUS.COMPLETED && statusData === 'fail');

  const { assetImgPath = '', featureGradient = '' } =
    styleStore[assetKey] || {};

  return (
    <div
      className='text-[#3C3A40] dark:text-[#7575A7]'
      style={{ minHeight: txStatus == TX_STATUS.FAILED ? 'auto' : 348 }}
    >
      <div
        className={classNames(
          'relative h-[56px]',
          'flex items-center justify-center',
          'text-lg font-semibold leading-tight',
        )}
      >
        {completed ? (
          <div className='flex items-center gap-1'>
            <img style={{ width: 22 }} src={assetImgPath} alt='' />
            <div className='text-[14px] text-[#7575A7] dark:text-white'>
              {assetKey}
            </div>
          </div>
        ) : (
          t('transaction-details')
        )}
        <div className='absolute right-5 top-1/2 z-10 -translate-y-1/2'>
          <ClosePopupBtn
            theme={{ light: '#1E1E1E', dark: 'white' }}
            iconClassName='w-[11px]'
          />
        </div>
      </div>

      <div
        className={classNames(
          'text-superxs font-semibold leading-tight tracking-[0.02em]',
          'relative p-5 pr-0',
          'flex flex-col items-center',
        )}
      >
        {/* Pending animation */}
        {!completed && (
          <div className='flex items-start justify-center'>{statusIcon}</div>
        )}

        <div
          className={classNames('text-center pr-5', {
            'mt-5': !completed,
          })}
        >
          <div
            className={classNames('flex items-center justify-center gap-1.5', {
              'text-[12px] text-black dark:text-white': resultLabel,
            })}
            data-testid='txStatus'
          >
            {resultLabel && resultLabel.icon}
            {statusTitle}
          </div>

          {success && (
            <div
              style={
                {
                  [`--gradient`]: featureGradient,
                } as any
              }
              className='gradient-text mt-4 text-[74px] font-normal'
            >
              {new DefiUtils(transactionValue).isLessThanOrEqualTo('0.01')
                ? '< 0.01'
                : nFormatter(transactionValue, 2, DefiUtils.ROUND_FLOOR)}
            </div>
          )}

          {badStatus && (
            <div className='mt-[30px] flex h-12 items-start justify-center'>
              {statusIcon}
            </div>
          )}

          {statusMessage && (
            <div
              className={classNames(
                'mt-3',
                completed ? 'w-[310px]' : 'w-[220px]',
              )}
            >
              {statusMessage}
            </div>
          )}
        </div>

        {!!currentTransactions.length && (
          <div className='txHashes scroll-container2 overflow-scroll-x mt-[34px] max-h-[103px] w-[97%] transition-all'>
            <div className='flex flex-col items-center gap-2 pr-5'>
              {currentTransactions.map(({ hash }, i) => (
                <LazyMotion key={i} features={domAnimation}>
                  <m.div
                    initial={i !== 0 && { height: 0, overflow: 'hidden' }}
                    animate={i !== 0 && { height: 29 }}
                    transition={{ duration: 0.2 }}
                    key={i}
                  >
                    <TxHashBox hash={hash} />
                  </m.div>
                </LazyMotion>
              ))}
            </div>
          </div>
        )}

        {/* Explore  */}
        {currentTransactions?.[currentTransactions.length - 1]?.hash && (
          <a
            href={`${
              networkConfig[chainType].explorerAddress
            }/transactions/${currentTransactions?.[
              currentTransactions.length - 1
            ]?.hash}`}
            target='_blank'
            rel='noreferrer'
            className='mt-5 w-full pr-5'
          >
            <TransactionButton
              customTheme={(disabled: boolean) => {
                if (disabled)
                  return 'text-[#6C7DA6] bg-[#D3DBEE] dark:bg-[#2A3E6C] cursor-not-allowed';
                return ['bg-[#006FFF] text-white'];
              }}
              text={t('view-on-explorer')}
            ></TransactionButton>
          </a>
        )}
      </div>
    </div>
  );
};

export default TxPhase;
