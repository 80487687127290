import DefiUtils from 'defi-utils';

const EXTRA_PERCENTAGE = 1.5;

const addExtraValue = (value: number) => {
  const MAX_GAS_FEE = 600_000_000;

  const newValue = new DefiUtils(value).multipliedBy(EXTRA_PERCENTAGE);

  if (new DefiUtils(newValue).isGreaterThan(MAX_GAS_FEE)) {
    return MAX_GAS_FEE;
  }

  return +newValue.toFixed(0);
};

const gasLimitMap = () => {
  return {
    'governance.proposeTransferExecute': () => 140_000_000,
    'governance.publishProposal': () => 140_000_000,
    'governance.upvote': () => 14_000_000,
    'governance.downvote': () => 14_000_000,
    'governance.redeem': () => 14_000_000,

    'liquid.delegate': () => addExtraValue(30_000_000),
    'liquid.unDelegate': () => addExtraValue(30_000_000),
    'liquid.lendegate': () => addExtraValue(85_000_000),
    'liquid.unlendegate': () => addExtraValue(52_000_000),
    'liquid.withdraw': () => addExtraValue(8_000_000),

    'liquid-tao.delegate': () => addExtraValue(30_000_000),
    'liquid-tao.unDelegate': () => addExtraValue(30_000_000),
    'liquid-tao.lendegate': () => addExtraValue(85_000_000),
    'liquid-tao.unlendegate': () => addExtraValue(52_000_000),

    'market.mint': () => addExtraValue(20_000_000),
    'market.mintAndEnterMarket': () => addExtraValue(55_000_000),
    'market.enterMarkets': () => addExtraValue(80_000_000),
    'market.redeem': () => addExtraValue(13_000_000),
    'market.repayBorrow': () => addExtraValue(33_000_000),
    'market.claimRewards': () => addExtraValue(300_000_000),
    'market.removeAccountMarket': () => addExtraValue(20_000_000),

    'booster.rebalancePortfolio': () => addExtraValue(350_000_000),
    'booster.stake': () => 500_000_000,
    'booster.unstake': () => 500_000_000,
    'booster.claimRewards': () => 600_000_000,
    'booster.claim': () => 100_000_000,
    'token-vesting.claimTokens': () => 15_000_000,

    'market.borrow': (marketAmount: number) => {
      const base = 80_000_000;
      const perMarket = 20_000_000;
      const extraGasLimit = new DefiUtils(perMarket).multipliedBy(marketAmount);

      return addExtraValue(new DefiUtils(base).plus(extraGasLimit).toNumber());
    },
    'market.exitMarket': (marketAmount: number) => {
      const base = 60_000_000;
      const perMarket = 20_000_000;
      const extraGasLimit = new DefiUtils(perMarket).multipliedBy(marketAmount);

      return addExtraValue(new DefiUtils(base).plus(extraGasLimit).toNumber());
    },
  };
};

export default gasLimitMap;
