import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';

import useMediaQuery from '@/hooks/useMediaQuery';

import SearchIcon from '@/components/Icons/SearchIcon';
import StatusBadge from '@/components/popups/AccountDetailsPopup/components/TxHistoryItem/StatusBadge';

import { chainType, networkConfig } from '@/config/network';
import { shortenHash } from '@/utils/shortenHash';

import { TransactionResponseItem } from '@/types/account';

interface TxHistoryItemProps
  extends Pick<TransactionResponseItem, 'txHash' | 'status' | 'timestamp'> {
  isLoading?: boolean;
}

const TxHistoryItem: React.FC<TxHistoryItemProps> = ({
  txHash,
  status,
  isLoading,
}) => {
  const md = useMediaQuery('(min-width: 768px)');
  return (
    <div
      className={classNames(
        'group',
        'flex items-center justify-between gap-3',
        'h-[50px] rounded-[8px] border',
        'p-1.5 pl-4',
        'font-semibold tracking-wide',
        'border-[#D3D0E4] bg-white text-[#535367]',
        'dark:border-current dark:bg-[#2E2C51] dark:text-[#625E8F]',
        'hover:text-black',
        'dark:hover:text-[#A5A3C6]'
      )}
    >
      {isLoading ? (
        <Skeleton height='20px' width={350} />
      ) : (
        <a
          href={`${networkConfig[chainType].explorerAddress}/transactions/${txHash}`}
          target='_blank'
          rel='noreferrer'
          className='group flex items-center gap-3 text-sm'
        >
          {shortenHash(txHash, md ? 18 : 12)} <SearchIcon />
        </a>
      )}
      {isLoading ? (
        <Skeleton width={130} height={30} borderRadius={8} />
      ) : (
        <StatusBadge className='w-[111px]' status={status} />
      )}
    </div>
  );
};

export default TxHistoryItem;
