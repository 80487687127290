import { useEffect, useState } from 'react';

const useIsSafari = (): boolean => {
  const [isSafari, setIsSafari] = useState<boolean>(false);

  useEffect(() => {
    const userAgent = typeof window === 'undefined' ? navigator.userAgent : '';
    const isSafariBrowser =
      userAgent.indexOf('Safari') !== -1 && userAgent.indexOf('Chrome') === -1;

    setIsSafari(isSafariBrowser);
  }, []);

  return isSafari;
};

export default useIsSafari;
