import { ReactElement } from 'react';

import clsxm from '@/services/clsxm';

interface SubmitButtonProps {
  children: ReactElement | string;
  isDisabled: boolean;
  onSubmit: () => void;
  className?: string;
}

const SubmitButton = ({
  children,
  isDisabled,
  onSubmit,
  className,
}: SubmitButtonProps) => {
  return (
    <button
      onClick={onSubmit}
      className={clsxm(
        'bg-[#006FFF] hover:bg-[#2986FF]',
        'h-[40px] lg:h-[56px]',
        'rounded-[11.5px] lg:rounded-[16px]',
        'w-full',
        'font-semibold',
        'text-[12px] lg:text-[16px]',
        'text-white',
        'mb-[36px]',
        isDisabled ? 'cursor-not-allowed opacity-75' : '',
        className,
      )}
    >
      {children}
    </button>
  );
};

export default SubmitButton;
