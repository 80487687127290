const FilterIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  const { fill, ...rest } = props;

  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.5124 12.0997C15.0037 12.0997 16.2109 13.3086 16.2109 14.7991C16.2109 16.2905 15.0037 17.4992 13.5124 17.4992C12.0209 17.4992 10.8125 16.2905 10.8125 14.7991C10.8125 13.3086 12.0209 12.0997 13.5124 12.0997Z'
        stroke={fill}
        strokeWidth='2.05525'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.50455 2.49811C8.99602 2.49811 10.2048 3.70689 10.2048 5.19832C10.2048 6.68886 8.99602 7.89764 7.50455 7.89764C6.01313 7.89764 4.80469 6.68893 4.80469 5.1984C4.80469 3.70697 6.01313 2.49811 7.50455 2.49811Z'
        stroke={fill}
        strokeWidth='2.05525'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.17188 5.20123H4.81609M10.2146 5.20123H18.6139'
        stroke={fill}
        strokeWidth='2.05525'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.17188 14.7989H10.8155M16.2141 14.7989H18.6139'
        stroke={fill}
        strokeWidth='2.05525'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default FilterIcon;
