import { gql } from 'graphql-request';

import client from '@/services/indexer/client';
import {
  formatHTokenBalance,
  formatTokenPrices,
  formatTokenPricesTuple,
  formatTokenPricesV2,
} from '@/services/indexer/common/parsers';
import type {
  QueryHTokenBalance,
  QueryTokenPrices,
  QueryTokenPricesV2,
  ResponseHTokenBalance,
  ResponseTokenPrices,
  ResponseTokenPricesTuple,
  ResponseTokenPricesV2,
} from '@/services/indexer/common/types';

const N_PRICE_AGGREGATOR_TOKENS = 9;

export const getHTokenBalance = async (
  address: string,
): Promise<ResponseHTokenBalance> => {
  if (!address) {
    return {} as any;
  }

  const query = gql`
    query Common_QueryHTokenBalance($address: String!) {
      getAccount(address: $address) {
        address
        markets {
          tokens
          market {
            underlying {
              id
              symbol
            }
          }
        }
      }
    }
  `;

  const variables = {
    address,
  };

  const queryHTokenBalanceResponse = await client.request<QueryHTokenBalance>(
    query,
    variables,
  );

  return formatHTokenBalance(queryHTokenBalanceResponse);
};

export const getTokenPrices = async (
  timestamp?: string,
  liquidStakingExchangeRate?: string,
): Promise<ResponseTokenPrices> => {
  const query = gql`
    query Common_QueryTokenPrices() @cacheControl(maxAge: 60) {
      queryLiquidStaking {
        state {
          cashReserve
          totalShares
        }
      }
      queryToken {
        id
        symbol
        dailyPriceHistory(first: 3, order: { desc: day }) {
          quote {
            priceInEgld
            timestamp
          }
          price {
            price
            timestamp
          }
        }
      }
    }
  `;

  const variables = {};

  const queryTokenPricesResponse = await client.request<QueryTokenPrices>(
    query,
    variables,
  );

  return formatTokenPrices(
    queryTokenPricesResponse,
    timestamp,
    liquidStakingExchangeRate,
  );
};

export const getTokenPricesV2 = async ({
  first = N_PRICE_AGGREGATOR_TOKENS * 2,
  offset = 0,
  liquidStakingExchangeRate,
  liquidStakingTaoExchangeRate,
}: {
  first?: number;
  offset?: number;
  liquidStakingExchangeRate: string;
  liquidStakingTaoExchangeRate: string;
}): Promise<ResponseTokenPricesV2> => {
  const query = gql`
    query Common_QueryTokenPricesV2($first: Int!, $offset: Int!)
    @cacheControl(maxAge: 60) {
      queryAggregatorPrice(
        first: $first
        order: { desc: timestamp }
        offset: $offset
      ) {
        id
        price
        timestamp
        roundId
      }
    }
  `;

  const variables = {
    first,
    offset,
  };

  const queryAggregatorPriceResponse = await client.request<QueryTokenPricesV2>(
    query,
    variables,
  );

  return formatTokenPricesV2(
    queryAggregatorPriceResponse,
    liquidStakingExchangeRate,
    liquidStakingTaoExchangeRate,
  );
};

export const getTokenPricesTuple = async (
  liquidStakingExchangeRate?: string,
): Promise<ResponseTokenPricesTuple> => {
  const todayDate = new Date();
  todayDate.setUTCHours(0, 0, 0, 0);

  const yesterdayDate = new Date();
  yesterdayDate.setUTCHours(0, 0, 0, 0);
  yesterdayDate.setDate(todayDate.getUTCDate() - 1);

  const yesterdayTimestamp = yesterdayDate.toISOString();

  const [todayPricesMap, yesterdayPricesMap] = await Promise.all([
    getTokenPrices(undefined, liquidStakingExchangeRate),
    getTokenPrices(yesterdayTimestamp, liquidStakingExchangeRate),
  ]);

  const hasAnyPriceWithoutVariation = Object.entries(todayPricesMap).some(
    ([key, todayPrice]) => {
      const yesterdayPrice = yesterdayPricesMap[key] || '0';

      return todayPrice === yesterdayPrice;
    },
  );

  const ids = Object.keys({
    ...todayPricesMap,
    ...yesterdayPricesMap,
  });

  if (!hasAnyPriceWithoutVariation) {
    return formatTokenPricesTuple(ids, todayPricesMap, yesterdayPricesMap);
  }

  const afterYesterdayDate = new Date();
  afterYesterdayDate.setUTCHours(0, 0, 0, 0);
  afterYesterdayDate.setDate(todayDate.getUTCDate() - 2);

  const afterYesterdayTimestamp = afterYesterdayDate.toISOString();

  const afterYesterdayPricesMap = await getTokenPrices(
    afterYesterdayTimestamp,
    liquidStakingExchangeRate,
  );

  const hasSamePriceMap = Object.entries(todayPricesMap).reduce(
    (prev, [key, todayPrice]) => {
      const yesterdayPrice = yesterdayPricesMap[key] || '0';

      const hasTheSamePrice = todayPrice === yesterdayPrice;

      return {
        ...prev,
        [key]: hasTheSamePrice,
      };
    },
    {} as Record<string, boolean>,
  );

  const todayPricesMapUpdated = Object.entries(todayPricesMap).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key]: hasSamePriceMap[key] ? yesterdayPricesMap[key] : value,
    }),
    {} as Record<string, string>,
  );

  const yesterdayPricesMapUpdated = Object.entries(yesterdayPricesMap).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key]: hasSamePriceMap[key] ? afterYesterdayPricesMap[key] : value,
    }),
    {} as Record<string, string>,
  );

  return formatTokenPricesTuple(
    ids,
    todayPricesMapUpdated,
    yesterdayPricesMapUpdated,
  );
};

export const getTokenPricesTupleV2 = async ({
  liquidStakingExchangeRate,
  liquidStakingTaoExchangeRate,
}: {
  liquidStakingExchangeRate: string;
  liquidStakingTaoExchangeRate: string;
}): Promise<ResponseTokenPricesTuple> => {
  const [todayPricesMap, yesterdayPricesMap] = await Promise.all([
    getTokenPricesV2({
      liquidStakingExchangeRate,
      liquidStakingTaoExchangeRate,
    }),
    getTokenPricesV2({
      offset: N_PRICE_AGGREGATOR_TOKENS,
      liquidStakingExchangeRate,
      liquidStakingTaoExchangeRate,
    }),
  ]);

  const hasAnyPriceWithoutVariation = Object.entries(todayPricesMap).some(
    ([key, todayPrice]) => {
      const yesterdayPrice = yesterdayPricesMap[key] || '0';

      return todayPrice === yesterdayPrice;
    },
  );

  const ids = Object.keys({
    ...todayPricesMap,
    ...yesterdayPricesMap,
  });

  if (!hasAnyPriceWithoutVariation) {
    return formatTokenPricesTuple(ids, todayPricesMap, yesterdayPricesMap);
  }

  const afterYesterdayPricesMap = await getTokenPricesV2({
    offset: N_PRICE_AGGREGATOR_TOKENS * 2,
    liquidStakingExchangeRate,
    liquidStakingTaoExchangeRate,
  });

  const hasSamePriceMap = Object.entries(todayPricesMap).reduce(
    (prev, [key, todayPrice]) => {
      const yesterdayPrice = yesterdayPricesMap[key] || '0';

      const hasTheSamePrice = todayPrice === yesterdayPrice;

      return {
        ...prev,
        [key]: hasTheSamePrice,
      };
    },
    {} as Record<string, boolean>,
  );

  const todayPricesMapUpdated = Object.entries(todayPricesMap).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key]: hasSamePriceMap[key] ? yesterdayPricesMap[key] : value,
    }),
    {} as Record<string, string>,
  );

  const yesterdayPricesMapUpdated = Object.entries(yesterdayPricesMap).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key]: hasSamePriceMap[key] ? afterYesterdayPricesMap[key] : value,
    }),
    {} as Record<string, string>,
  );

  return formatTokenPricesTuple(
    ids,
    todayPricesMapUpdated,
    yesterdayPricesMapUpdated,
  );
};
