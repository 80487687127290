import DefiUtils from 'defi-utils';

import { QueryDelegationContracts } from '@/services/indexer/liquid-staking-app/types';

export const formatDelegationContracts = (
  queryDelegationContractsResponse: QueryDelegationContracts,
) => {
  return queryDelegationContractsResponse.queryDelegationContract.map(
    ({ id, totalDelegated, apr, serviceFee, pendingToDelegate }) => ({
      id,
      totalDelegated,
      pendingToDelegate,
      serviceFee: new DefiUtils(serviceFee).dividedBy(100).toString(),
      apr: new DefiUtils(apr).dividedBy(100).toString(),
    }),
  );
};
