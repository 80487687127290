import DefiUtils from 'defi-utils';
import { useCallback, useEffect } from 'react';

import { PROPOSAL_STATUS, setGovernance } from '@/store/governance';
import { useAppDispatch } from '@/store/index';

import useVotesPopulated from '@/contexts/GovernanceContext/hooks/useVotesPopulated';

const useStoreHtmEngagedInVoting = () => {
  const dispatch = useAppDispatch();
  const votesPopulated = useVotesPopulated();

  const calcHtmEngagedInVoting = useCallback(() => {
    return votesPopulated
      .filter((voteItem) =>
        [
          PROPOSAL_STATUS.building,
          PROPOSAL_STATUS.pending,
          PROPOSAL_STATUS.active,
          null,
        ].includes(voteItem.proposalStatus as PROPOSAL_STATUS)
      )
      .reduce((prev, current) => prev.plus(current.amount), new DefiUtils(0))
      .toString();
  }, [votesPopulated]);

  useEffect(() => {
    const htmEngagedInVoting = calcHtmEngagedInVoting();

    dispatch(
      setGovernance({
        htmEngagedInVoting,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcHtmEngagedInVoting]);
};

export default useStoreHtmEngagedInVoting;
