import Hint from '@/components/Hint';
import Soon from '@/components/popups/AirdropAnalyticsPopup/components/Icons/Soon';

export const fakeData = [
  {
    id: 1,
    date: '27/11/2023 at 23:35',
    total: '$7,124,512.13',
    totalUSD: '$854,941.45',
    apy: '100.12%',
    collateral: '10.01%',
    isBoosterEnabled: false,
    poolShare: '1.12%',
    boosterEnabledDate: 'To be determined',
  },
  {
    id: 2,
    date: '27/11/2023 at 23:35',
    total: '$7,124,512.13',
    totalUSD: '$854,941.45',
    apy: '12%',
    collateral: '12%',
    isBoosterEnabled: false,
    poolShare: '12%',
    boosterEnabledDate: 'To be determined',
    style: 'px-[16px]',
  },
  {
    id: 3,
    date: '27/11/2023 at 23:35',
    total: '$7,124,512.13',
    totalUSD: '$854,941.45',
    apy: '12%',
    collateral: '12%',
    isBoosterEnabled: false,
    poolShare: '12%',
    boosterEnabledDate: 'To be determined',
  },
  {
    id: 4,
    date: '27/11/2023 at 23:35',
    total: '$7,124,512.13',
    totalUSD: '$854,941.45',
    apy: '12%',
    collateral: '12%',
    isBoosterEnabled: false,
    poolShare: '12%',
    boosterEnabledDate: 'To be determined',
  },
  {
    id: 5,
    date: '27/11/2023 at 23:35',
    total: '$7,124,512.13',
    totalUSD: '$854,941.45',
    apy: '12%',
    collateral: '12%',
    isBoosterEnabled: false,
    poolShare: '12%',
    boosterEnabledDate: 'To be determined',
  },
  {
    id: 6,
    date: '27/11/2023 at 23:35',
    total: '$7,124,512.13',
    totalUSD: '$854,941.45',
    apy: '12%',
    collateral: '12%',
    isBoosterEnabled: false,
    poolShare: '12%',
    boosterEnabledDate: 'To be determined',
  },
  {
    id: 7,
    date: '27/11/2023 at 23:35',
    total: '$7,124,512.13',
    totalUSD: '$854,941.45',
    apy: '12%',
    isBoosterEnabled: false,
    poolShare: '12%',
    boosterEnabledDate: 'To be determined',
  },
  {
    id: 8,
    date: '27/11/2023 at 23:35',
    total: '$7,124,512.13',
    totalUSD: '$854,941.45',
    apy: '12%',
    collateral: '12%',
    isBoosterEnabled: true,
    poolShare: '12%',
    boosterEnabledDate: 'To be determined',
  },
  {
    id: 9,
    date: '27/11/2023 at 23:35',
    total: '$7,124,512.13',
    totalUSD: '$854,941.45',
    apy: '10%',
    collateral: '12%',
    isBoosterEnabled: true,
    poolShare: '12%',
    boosterEnabledDate: 'To be determined',
  },
  {
    id: 10,
    date: '27/11/2023 at 23:35',
    total: '$7,124,512.13',
    totalUSD: '$854,941.45',
    apy: '8%',
    collateral: '12%',
    isBoosterEnabled: true,
    poolShare: '12%',
    boosterEnabledDate: 'To be determined',
  },
  {
    id: 11,
    date: '27/11/2023 at 23:35',
    total: '$7,124,512.13',
    totalUSD: '$854,941.45',
    apy: '6%',
    collateral: '12%',
    isBoosterEnabled: true,
    poolShare: '12%',
    boosterEnabledDate: 'To be determined',
  },
  {
    id: 12,
    date: '27/11/2023 at 23:35',
    total: '$7,124,512.13',
    totalUSD: '$854,941.45',
    apy: '4%',
    collateral: '12%',
    isBoosterEnabled: true,
    poolShare: '12%',
    boosterEnabledDate: 'To be determined',
  },
  {
    id: 13,
    date: '27/11/2023 at 23:35',
    total: '$7,124,512.13',
    totalUSD: '854,941.45',
    apy: '2%',
    collateral: '12%',
    isBoosterEnabled: true,
    poolShare: '12%',
    boosterEnabledDate: 'To be determined',
  },
];

export const fakeData2 = [
  {
    id: 1,
    date: (
      <Hint
        autoReplace
        containerClassName='translate-y-0 md:translate-y-4 lg:translate-y-0'
        content='Coming soon'
      >
        <Soon />
      </Hint>
    ),
    total: (
      <Hint
        autoReplace
        containerClassName='translate-y-0 md:translate-y-4 lg:translate-y-0'
        content='Coming soon'
      >
        <Soon />
      </Hint>
    ),
    totalUSD: (
      <Hint
        autoReplace
        containerClassName='translate-y-0 md:translate-y-4 lg:translate-y-0'
        content='Coming soon'
      >
        <Soon />
      </Hint>
    ),
    apy: (
      <Hint
        autoReplace
        containerClassName='translate-y-0 md:translate-y-4 lg:translate-y-0'
        content='Coming soon'
      >
        <Soon />
      </Hint>
    ),
    collateral: (
      <Hint
        autoReplace
        containerClassName='translate-y-0 md:translate-y-4 lg:translate-y-0'
        content='Coming soon'
      >
        <Soon />
      </Hint>
    ),
    isBoosterEnabled: (
      <Hint
        autoReplace
        containerClassName='translate-y-0 md:translate-y-4 lg:translate-y-0'
        content='Coming soon'
      >
        <Soon />
      </Hint>
    ),
    poolShare: (
      <Hint
        autoReplace
        containerClassName='translate-y-0 md:translate-y-4 lg:translate-y-0'
        content='Coming soon'
      >
        <Soon />
      </Hint>
    ),
    boosterEnabledDate: (
      <Hint
        autoReplace
        containerClassName='translate-y-0 md:translate-y-4 lg:translate-y-0'
        content='Coming soon'
      >
        <Soon />
      </Hint>
    ),
    percentageBoost: (
      <Hint
        autoReplace
        containerClassName='translate-y-0 md:translate-y-4 lg:translate-y-0'
        content='Coming soon'
      >
        <Soon />
      </Hint>
    ),
    collateralPercentage: (
      <Hint
        autoReplace
        containerClassName='translate-y-0 md:translate-y-4 lg:translate-y-0'
        content='Coming soon'
      >
        <Soon />
      </Hint>
    ),
  },
];
