import { createContext } from 'react';

const BoosterContext = createContext({});

const BoosterConsumer = BoosterContext.Consumer;

const BoosterProvider = ({ children }: { children: JSX.Element }) => {
  return (
    <BoosterContext.Provider value={{}}>{children}</BoosterContext.Provider>
  );
};

export default BoosterContext;

export { BoosterConsumer, BoosterProvider };
