import DefiUtils from 'defi-utils';
import { gql } from 'graphql-request';

import client from '@/services/xexchange/client';
import { QueryPair, ResponsePair } from '@/services/xexchange/types';

export const getPairs = (uniquePairsIds: string[]) => {
  return Promise.all(
    uniquePairsIds.map(async (pairsIds) => {
      const [token1, token2] = pairsIds.split('/');

      return {
        pairsIds,
        exchangeRate: await getPair(token1, token2),
      };
    }),
  );
};

export const getPair = async (
  firstTokenID: string,
  secondTokenID: string,
): Promise<ResponsePair> => {
  const query = gql`
    query XEXCHANGE_GET_PAIR($firstTokenID: String!, $secondTokenID: String!) {
      pairs(firstTokenID: $firstTokenID, secondTokenID: $secondTokenID) {
        firstTokenPrice
        secondTokenPrice
        firstToken {
          decimals
        }
        secondToken {
          decimals
        }
        info {
          reserves0
          reserves1
        }
      }
    }
  `;

  const variables = {
    firstTokenID,
    secondTokenID,
  };

  const queryPair = await client.request<QueryPair>(query, variables);

  const reserves0 = queryPair.pairs?.[0]?.info.reserves0 || '0';
  const decimals0 = queryPair.pairs?.[0]?.firstToken.decimals || 0;

  const reserves1 = queryPair.pairs?.[0]?.info.reserves1 || '0';
  const decimals1 = queryPair.pairs?.[0]?.secondToken.decimals || 0;

  const calc1 = new DefiUtils(reserves0).toFullDecimals(decimals0);
  const calc2 = new DefiUtils(reserves1).toFullDecimals(decimals1);

  return calc1.dividedBy(calc2).toSafeString();
};
