import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { useAppDispatch } from '@/store';
import { setAccountState, setLoginInfoState } from '@/store/auth';
import { setNetworkState } from '@/store/network';

import { LoginMethods } from '@/config/walletProviders';
import { webviewProvider } from '@/providers/webviewProvider';
import { getWebviewToken } from '@/utils/account/getWebviewToken';
import { getNewLoginExpiresTimestamp } from '@/utils/expiresAt';
import { decodeNativeAuthToken } from '@/utils/nativeAuth/decodeNativeAuth';

const useLoginWithNativeAuthToken = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const removeQueryParam = (param: string) => {
    const { pathname, query } = router;
    const params = new URLSearchParams(query);
    params.delete(param);
    router.replace({ pathname, query: params.toString() }, undefined, {
      shallow: true,
    });
  };

  useEffect(() => {
    const accessToken = router.query?.accessToken as string;
    const platform = router.query?.accessToken;

    const isWebViewProvider = !!accessToken && !platform;

    if (!isWebViewProvider) {
      return;
    }

    const nativeAuthInfo = decodeNativeAuthToken(accessToken);

    if (nativeAuthInfo == null) {
      return;
    }

    removeQueryParam('accessToken');
    localStorage.clear();

    dispatch(setLoginInfoState('loginMethod', LoginMethods.extra));
    dispatch(setLoginInfoState('loginToken', accessToken));
    dispatch(setLoginInfoState('expires', getNewLoginExpiresTimestamp()));
    dispatch(setLoginInfoState('signature', nativeAuthInfo.signature));
    dispatch(setAccountState('address', nativeAuthInfo.address));
    dispatch(setAccountState('nonce', 0));
    dispatch(setAccountState('balance', 0));
    dispatch(setNetworkState('dappProvider', webviewProvider));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useLoginWithNativeAuthToken;
