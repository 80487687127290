import { createContext } from 'react';

import useStoreTokens from '@/contexts/LiquidStakingTaoAppContext/hooks/useStoreTokens';

const LiquidStakingTaoAppContext = createContext({});

const LiquidStakingTaoAppConsumer = LiquidStakingTaoAppContext.Consumer;

const LiquidStakingTaoAppProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  useStoreTokens();

  return (
    <LiquidStakingTaoAppContext.Provider value={{}}>
      {children}
    </LiquidStakingTaoAppContext.Provider>
  );
};

export default LiquidStakingTaoAppContext;

export { LiquidStakingTaoAppConsumer, LiquidStakingTaoAppProvider };
