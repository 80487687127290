const WalletBalanceIcon = ({
  fill,
  ...rest
}: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M13.3189 10.6775H11.0676C10.2405 10.6775 9.57031 10.0073 9.57031 9.18022C9.57031 8.35378 10.2405 7.68359 11.0676 7.68359H13.3189'
        stroke={fill}
        strokeWidth='0.9375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.9392 9.14844H10.9375'
        stroke={fill}
        strokeWidth='0.9375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.57822 4.35334L10.3856 4.33206C11.9978 4.32536 13.3101 5.62618 13.3174 7.2384L13.3326 10.6744C13.3393 12.2866 12.0385 13.599 10.4263 13.6062L5.61897 13.6269C4.00678 13.6342 2.6938 12.3328 2.68711 10.7212L2.67191 7.28521C2.66461 5.67301 3.96603 4.36064 5.57822 4.35334Z'
        stroke={fill}
        strokeWidth='0.9375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.19531 6.86328H8.19892'
        stroke={fill}
        strokeWidth='0.9375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.50017 5.23065C3.48923 4.11836 4.00371 3.18851 5.13243 3.02187L9.46911 2.39914C10.7036 2.21609 11.51 3.09485 11.693 4.33486L11.7381 4.60791'
        stroke={fill}
        strokeWidth='0.9375'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default WalletBalanceIcon;
