import { useTranslation } from 'next-i18next';

import { PROPOSAL_TAG } from '@/store/governance';

import clsxm from '@/services/clsxm';

type TitleProps = {
  proposalId: number;
  title: string;
  tag: PROPOSAL_TAG;
};

const PROPOSAL_TAG_IMAGES = {
  [PROPOSAL_TAG.liquidStaking]: {
    img: 'https://cdn.app.hatom.com/images/governance/proposal/tags/liquid-staking.svg',
    title: 'Liquid Staking',
  },
  [PROPOSAL_TAG.lendingProtocol]: {
    img: 'https://cdn.app.hatom.com/images/governance/proposal/tags/lending-protocol.svg',
    title: 'Lending Protocol',
  },
  [PROPOSAL_TAG.ushStaking]: {
    img: 'https://cdn.app.hatom.com/images/governance/proposal/tags/ush-staking.svg',
    title: 'USH Staking',
  },
  [PROPOSAL_TAG.isolatedPools]: {
    img: 'https://cdn.app.hatom.com/images/governance/proposal/tags/isolated-pools.svg',
    title: 'Isolated Pools',
  },
};

export const Title = (props: TitleProps) => {
  const { t } = useTranslation('governance');

  return (
    <div className='mt-5 space-y-[9px] px-4'>
      <p
        className={clsxm(
          'text-[rgb(31,34,38)]',
          'dark:text-[#ffffff]',
          'text-[18px]',
          'leading-[125%]',
          'font-semibold',
          'max-w-[368px]'
        )}
      >
        {props.title}
      </p>
      <div className={clsxm('flex', 'gap-1.5', 'items-center')}>
        <p
          className={clsxm(
            'text-[14px]',
            'font-semibold capitalize',
            'text-[#77778B]'
          )}
        >
          {t('proposal', 'Proposal')} #{props.proposalId}
        </p>
        <span className={clsxm('w-1', 'h-1', 'rounded-full', 'bg-[#6E6E85]')} />
        <div className={clsxm('flex', 'items-center', 'gap-1')}>
          <div className=''>
            <img
              src={PROPOSAL_TAG_IMAGES[props.tag]?.img || ''}
              alt={props.tag}
            />
          </div>
          <span
            className={clsxm(
              'text-[#1F2226]',
              'text-[14px]',
              'leading-[125%]',
              'font-semibold',
              'dark:text-[#ffffff]'
            )}
          >
            {PROPOSAL_TAG_IMAGES[props.tag]?.title}
          </span>
        </div>
      </div>
    </div>
  );
};
