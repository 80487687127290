import useMediaQuery from '@/hooks/useMediaQuery';
import DefiUtils from 'defi-utils';
import Lottie from 'lottie-web';
import { useEffect, useMemo, useRef } from 'react';
import { Trans } from 'react-i18next';

import useTailwindBreakpoint from '@/hooks/useTailwindBreakpoint';

import Hint from '@/components/Hint';

import { useAppSelector } from '@/store';
import { boosterSelector } from '@/store/booster';
import { lendAppSelector } from '@/store/lend-app';
import { calculateNetAPY } from '@/store/parsers/lend-app-parser';
import {
  htmMarketSelector,
  MARKET_KEY,
  protocolSelector,
  TOKEN_LOGO_MAP,
} from '@/store/protocol';
import { rewardBatchSelector } from '@/store/reward-batch';

import clsxm from '@/services/clsxm';
import { nFormatter } from '@/utils/helpers';
import { getAccountPenaltyRewardsBoosterAPY } from '@/utils/math/apy';

type Props = {
  title: string;
  market: string;
  staked: string;
  dynamicPercentageBoost: string;
  inputValue: string;
  showBoostDecrease: boolean;
  totalCollateralInHtm: string;
  totalCollateralUSD: string;
  totalStakedDynamic: string;
  isLiquid?: boolean;
};

const GlobalBoostItem = ({
  title,
  market,
  staked,
  dynamicPercentageBoost,
  inputValue,
  showBoostDecrease,
  totalCollateralInHtm,
  totalCollateralUSD,
  totalStakedDynamic,
  isLiquid,
}: Props) => {
  const {
    userBalances,
    markets,
    liquidStaking: { apy: liquidStakingAPY },
    liquidStakingTao: { apy: liquidStakingTaoAPY },
  } = useAppSelector(protocolSelector);
  const {
    boosterAccount,
    percentageBoost,
    totalBoosterCollateralMoneyMarkets,
    maximumApyBooster,
  } = useAppSelector(boosterSelector);
  const {
    markets: marketsLend,
    showLiquidStakingAPY,
    showLiquidStakingTaoAPY,
  } = useAppSelector(lendAppSelector);
  const { markets: marketsRewards } = useAppSelector(rewardBatchSelector);
  const isMdAndAbove = useTailwindBreakpoint('md');
  const md = useMediaQuery('(min-width: 768px)');
  const lg1 = useMediaQuery('(min-width: 1024px)');
  const lg = useMediaQuery('(min-width: 1280px)');

  const htmMarket = useAppSelector(htmMarketSelector);

  const htmLogo = TOKEN_LOGO_MAP[MARKET_KEY.HTM]?.normal;

  const currentAPY = useMemo(() => {
    const accountsBoosterAPYMap = Object.entries(markets).reduce(
      (prev, [assetKey, { hTokenExchangeRate, underlying }]) => {
        if (!boosterAccount[assetKey]) {
          return {
            ...prev,
            [assetKey]: '0',
          };
        }

        const value = getAccountPenaltyRewardsBoosterAPY({
          speed: boosterAccount[assetKey]?.speed || '',
          hTokenExchangeRate,
          totalCollateral: totalBoosterCollateralMoneyMarkets[assetKey],
          marketPrice: underlying.priceUSD,
          rewardsToken: boosterAccount[assetKey]?.reward,
          marketDecimals: underlying.decimals,
          htmStakedUSD: new DefiUtils(
            boosterAccount[assetKey]?.accountMarketBooster.staked,
          )
            .toFullDecimals(htmMarket.underlying.decimals)
            .toUSD(htmMarket.underlying.priceUSD)
            .toString(),
          moneyMarketCollateralUSD: new DefiUtils(
            userBalances[assetKey as MARKET_KEY]?.underlyingCollateralBalance,
          )
            .toFullDecimals(underlying.decimals)
            .toUSD(underlying.priceUSD)
            .toString(),
          maximumApyBooster,
        });

        return {
          ...prev,
          [assetKey]: value.toSafeString(),
        };
      },
      {} as Record<MARKET_KEY, string>,
    );

    return calculateNetAPY({
      markets: marketsLend,
      rewardMarkets: marketsRewards,
      liquidStakingAPY,
      showLiquidStakingAPY,
      liquidStakingTaoAPY,
      showLiquidStakingTaoAPY,
      accountsBoosterAPYMap,
    });
  }, [
    markets,
    marketsLend,
    marketsRewards,
    liquidStakingAPY,
    showLiquidStakingAPY,
    liquidStakingTaoAPY,
    showLiquidStakingTaoAPY,
    boosterAccount,
    totalBoosterCollateralMoneyMarkets,
    htmMarket.underlying.decimals,
    htmMarket.underlying.priceUSD,
    userBalances,
    maximumApyBooster,
  ]);

  const maxAPY = useMemo(() => {
    const accountsBoosterAPYMap = Object.entries(markets).reduce(
      (prev, [assetKey, { hTokenExchangeRate, underlying }]) => {
        const moneyMarketCollateralUSD = new DefiUtils(
          userBalances[assetKey as MARKET_KEY]?.underlyingCollateralBalance,
        )
          .toFullDecimals(underlying.decimals)
          .toUSD(underlying.priceUSD)
          .toString();

        if (!boosterAccount[assetKey]) {
          return {
            ...prev,
            [assetKey]: '0',
          };
        }

        const value = getAccountPenaltyRewardsBoosterAPY({
          speed: boosterAccount[assetKey]?.speed || '',
          hTokenExchangeRate,
          totalCollateral: totalBoosterCollateralMoneyMarkets[assetKey],
          marketPrice: underlying.priceUSD,
          rewardsToken: boosterAccount[assetKey]?.reward,
          marketDecimals: underlying.decimals,
          htmStakedUSD: new DefiUtils(moneyMarketCollateralUSD)
            .multipliedBy(maximumApyBooster)
            .multipliedBy(1.1111) // aca_aca:hotfix
            .toString(),
          moneyMarketCollateralUSD,
          maximumApyBooster,
        });

        return {
          ...prev,
          [assetKey]: value.toSafeString(),
        };
      },
      {} as Record<MARKET_KEY, string>,
    );

    return calculateNetAPY({
      markets: marketsLend,
      rewardMarkets: marketsRewards,
      liquidStakingAPY,
      showLiquidStakingAPY,
      liquidStakingTaoAPY,
      showLiquidStakingTaoAPY,
      accountsBoosterAPYMap,
    });
  }, [
    markets,
    marketsLend,
    marketsRewards,
    liquidStakingAPY,
    showLiquidStakingAPY,
    liquidStakingTaoAPY,
    showLiquidStakingTaoAPY,
    userBalances,
    boosterAccount,
    totalBoosterCollateralMoneyMarkets,
    maximumApyBooster,
  ]);

  const newAPY = useMemo(() => {
    const accountsBoosterAPYMap = Object.entries(markets).reduce(
      (prev, [assetKey, { hTokenExchangeRate, underlying }]) => {
        if (!boosterAccount[assetKey]) {
          return {
            ...prev,
            [assetKey]: '0',
          };
        }

        const moneyMarketCollateralUSD = new DefiUtils(
          userBalances[assetKey as MARKET_KEY]?.underlyingCollateralBalance,
        )
          .toFullDecimals(underlying.decimals)
          .toUSD(underlying.priceUSD)
          .toString();

        const percentage = new DefiUtils(moneyMarketCollateralUSD)
          .multipliedBy(100)
          .dividedBy(totalCollateralUSD)
          .dividedBy(100)
          .toString();

        const htmStakedUSD = new DefiUtils(totalStakedDynamic)
          .multipliedBy(percentage)
          .toUSD(htmMarket.underlying.priceUSD)
          .toString();

        const value = getAccountPenaltyRewardsBoosterAPY({
          speed: boosterAccount[assetKey]?.speed || '',
          hTokenExchangeRate,
          totalCollateral: totalBoosterCollateralMoneyMarkets[assetKey],
          marketPrice: underlying.priceUSD,
          rewardsToken: boosterAccount[assetKey]?.reward,
          marketDecimals: underlying.decimals,
          htmStakedUSD,
          moneyMarketCollateralUSD,
          maximumApyBooster,
        });

        return {
          ...prev,
          [assetKey]: value.toSafeString(),
        };
      },
      {} as Record<MARKET_KEY, string>,
    );

    return calculateNetAPY({
      markets: marketsLend,
      rewardMarkets: marketsRewards,
      liquidStakingAPY,
      showLiquidStakingAPY,
      liquidStakingTaoAPY,
      showLiquidStakingTaoAPY,
      accountsBoosterAPYMap,
    });
  }, [
    markets,
    marketsLend,
    marketsRewards,
    liquidStakingAPY,
    showLiquidStakingAPY,
    liquidStakingTaoAPY,
    showLiquidStakingTaoAPY,
    boosterAccount,
    userBalances,
    totalCollateralUSD,
    totalStakedDynamic,
    htmMarket.underlying.priceUSD,
    totalBoosterCollateralMoneyMarkets,
    maximumApyBooster,
  ]);

  const arrowsRef = useRef<HTMLDivElement>(null);
  const animRef = useRef<any>(null);

  useEffect(() => {
    if (!arrowsRef.current) return;

    const anim = Lottie.loadAnimation({
      container: arrowsRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: false,
      path: 'https://cdn.app.hatom.com/animations/double-arrow.json',
    });

    animRef.current = anim;

    return () => anim.destroy();
  }, []);

  useEffect(() => {
    new DefiUtils(inputValue).isZero()
      ? animRef.current?.stop()
      : animRef.current?.play();
  }, [inputValue]);

  const getProgressColor = () => {
    if (new DefiUtils(dynamicPercentageBoost).isLessThan(50)) {
      return 'text-[#E04040]';
    }
    if (new DefiUtils(dynamicPercentageBoost).isLessThan(100)) {
      return 'text-[#E4AF08]';
    }
    if (new DefiUtils(dynamicPercentageBoost).isLessThan(150)) {
      return 'text-[#37CE86]';
    }

    return 'text-[#2B8EE9]';
  };

  const getProgressMaxColor = () => {
    if (new DefiUtils(dynamicPercentageBoost).isLessThan(150)) {
      return 'text-[#37CE86]';
    }

    return 'text-[#2B8EE9]';
  };

  const getProgressBgColor = () => {
    if (new DefiUtils(dynamicPercentageBoost).isLessThan(50)) {
      return 'RedProgressBar';
    }
    if (new DefiUtils(dynamicPercentageBoost).isLessThan(100)) {
      return 'YellowProgressBar';
    }
    if (new DefiUtils(dynamicPercentageBoost).isLessThan(150)) {
      return 'GreenProgressBar';
    }

    return 'BlueProgressBar';
  };

  const getStaticPercentageBoosterTextColor = () => {
    if (
      new DefiUtils(percentageBoost).isGreaterThanOrEqualTo(100) &&
      new DefiUtils(percentageBoost).isLessThan(150)
    ) {
      return 'text-[#37CE86]'; // green
    }
    if (new DefiUtils(percentageBoost).isGreaterThanOrEqualTo(150)) {
      return 'text-[#2B8EE9]'; // blue
    }

    return 'text-[#9E70FF]';
  };

  const widthProgress = useMemo(() => {
    const result = DefiUtils.min(100, dynamicPercentageBoost);

    if (result.isLessThanOrEqualTo(8)) {
      return '10';
    }

    return result.toString();
  }, [dynamicPercentageBoost]);

  const minProgressSize = useMemo(() => {
    const m = DefiUtils.min(100, percentageBoost);

    if (m.isLessThan(8)) return new DefiUtils(8);

    return m;
  }, [percentageBoost]);

  const defaultColorBar = useMemo(() => {
    const apyDefi = new DefiUtils(percentageBoost);

    if (apyDefi.isGreaterThanOrEqualTo(100) && apyDefi.isLessThan(150)) {
      return 'GreenProgressBar';
    }

    if (apyDefi.isGreaterThanOrEqualTo(150)) {
      return 'BlueProgressBar';
    }

    return 'PurpleProgressBar';
  }, [percentageBoost]);

  return (
    <div
      className={clsxm(
        'flex items-center justify-between gap-2 pr-3 desktop:gap-[18px]',
        isLiquid && 'pr-[2px]',
      )}
    >
      <div
        className={clsxm(
          'flex -translate-y-[0.5px] items-center justify-between gap-1',
          'h-[32.5px] min-w-[140px] ',
          'rounded-[9px]',
          'border border-[#E2DFFB] dark:border-[#3F3B76]',
          'px-1',
          isLiquid
            ? 'p-[5.5px] h-10 min-w-[160px] lg:h-[37.06px] lg:min-w-[178px] lg:rounded-[11px]'
            : 'py-0.5 px-1 lg:h-[40px] lg:min-w-[192px] lg:rounded-[12px]',
          !md && 'min-w-[136px] h-[31px]',
        )}
      >
        <div className='flex w-fit -translate-x-px  -translate-y-[0.5px] gap-1'>
          <span
            className={clsxm(
              'whitespace-nowrap text-[10px] font-semibold leading-tight text-[#1F2226] dark:text-white',
              isLiquid ? 'text-[13px] lg:text-[13px]' : 'lg:text-[14px]',
              !md && 'text-[10px]',
            )}
          >
            {title}
          </span>
        </div>
        <Hint
          containerClassName='md:mt-[0px] md:ml-[0px] ml-[-86px] mt-[-20px]'
          placement={isMdAndAbove ? 'top-center' : 'right-end'}
          content={
            <div className='max-w-[260px] leading-[12.5px] md:max-w-[194px]'>
              <Trans
                i18nKey='tooltip-4x75y'
                components={[<span className='text-[#5AF1B0]' />]}
                ns='translation'
              />
            </div>
          }
        >
          <div
            className={clsxm(
              'flex w-[65px] translate-x-px cursor-default items-center rounded-[6px] bg-[#ECEEFA]  dark:bg-[#3F3B76] lg:w-[90px] ',
              isLiquid
                ? 'px-[3px] py-[3px] w-[62px] md:w-[66px] lg:rounded-[7px] lg:w-[83.38px]'
                : 'px-1  py-1 lg:rounded-[8px]',
              !md && 'w-[64px] gap-0.5',
            )}
          >
            <img
              src={htmLogo}
              alt={market}
              className={clsxm(
                'h-[15px] w-[15px]',
                isLiquid ? 'h-5 w-5 lg:h-[18px] lg:w-[18px]' : 'lg:h-5 lg:w-5',
                !md && 'h-[15.45px] w-[15.45px]',
              )}
            />
            <div
              className={clsxm(
                'w-full text-center text-[10px] font-semibold text-[#6A6A8C] dark:text-[#FFFFFF]',
                isLiquid ? 'text-[10px] lg:text-[13px]' : 'lg:text-[14px]',
                !md && 'text-[10px]',
              )}
            >
              {nFormatter(new DefiUtils(staked).toSafeString())}
            </div>
          </div>
        </Hint>
      </div>
      <div className=''>
        <div
          ref={arrowsRef}
          className={clsxm(
            isLiquid ? 'h-[9px] w-[9px]' : 'h-[11px] w-[11px]',
            !md && 'h-[9px] w-[9px]',
          )}
        />
      </div>
      <div
        className={clsxm(
          'relative w-full -translate-y-[0.5px] translate-x-px !cursor-default',
          isLiquid && '-translate-x-[2px]',
        )}
      >
        <div
          className={clsxm(
            'absolute -top-[12px] md:-top-[12px] flex w-full justify-between text-[6px] font-medium text-[#7B7BA4] dark:text-[#625E8F] md:text-[8.75px] lg:-top-[14px] lg:text-[10px]',
            !md && '-top-[9px]',
          )}
        >
          {showBoostDecrease ? (
            new DefiUtils(newAPY).isLessThan(maxAPY) ? (
              <Hint
                placement={isMdAndAbove ? 'top-center' : 'right-end'}
                content={
                  <div className='max-w-[260px] leading-[12.5px] md:max-w-[272px]'>
                    <Trans
                      i18nKey='tooltip-4x75h'
                      components={[
                        <span className='text-[#5AF1B0]' />,
                        <span className='text-[#E24949]' />,
                      ]}
                      ns='translation'
                    />
                  </div>
                }
              >
                <div
                  className={clsxm(
                    'w-fit font-semibold whitespace-nowrap',
                    !md &&
                      'translate-y-[26px] absolute left-1/2 -translate-x-1/2',
                    md &&
                      !lg &&
                      'translate-y-[32px] absolute left-1/2 -translate-x-1/2',
                  )}
                >
                  <span className='underline'>Boost Decrease:</span>
                  <span className={clsxm(getProgressColor())}>
                    {' '}
                    {nFormatter(
                      newAPY,
                      new DefiUtils(newAPY).isLessThan(1) ? 3 : 2,
                    )}
                    %
                  </span>
                </div>
              </Hint>
            ) : (
              <></>
            )
          ) : (
            <Hint
              placement='top-center'
              content={
                <div className='max-w-[260px] leading-[12.5px] md:max-w-[320px]'>
                  <Trans
                    i18nKey='tooltip-4x75w'
                    components={[
                      <span className='text-[#9E70FF]' />,
                      <span className='text-[#8C80D4]' />,
                      <span className='text-[#8BD3C0]' />,
                    ]}
                    ns='translation'
                  />
                </div>
              }
            >
              <div className='w-fit font-semibold whitespace-nowrap'>
                <span className='underline'>Current Net APY:</span>{' '}
                <span
                  className={clsxm(
                    'text-[#9E70FF]',
                    getStaticPercentageBoosterTextColor(),
                  )}
                >
                  {nFormatter(
                    currentAPY,
                    new DefiUtils(currentAPY).isLessThan(1) ? 3 : 2,
                  )}
                  %
                </span>
              </div>
            </Hint>
          )}

          {showBoostDecrease ? (
            <Hint
              containerClassName='md:mt-[0px] md:ml-[0px] ml-[-230px] mt-[-20px]'
              placement={isMdAndAbove ? 'top-center' : 'right-end'}
              content={
                <div className='max-w-[260px] leading-[12.5px] md:max-w-[320px]'>
                  <Trans
                    i18nKey='tooltip-4x75w'
                    components={[
                      <span className='text-[#9E70FF]' />,
                      <span className='text-[#8C80D4]' />,
                      <span className='text-[#8BD3C0]' />,
                    ]}
                    ns='translation'
                  />
                </div>
              }
            >
              <div
                className={clsxm(
                  'w-fit font-semibold whitespace-nowrap',
                  !md && 'absolute left-0',
                  md && !lg && 'absolute left-0',
                )}
              >
                <span className='underline'>Current Net APY:</span>{' '}
                <span
                  className={clsxm(
                    'text-[#9E70FF]',
                    getStaticPercentageBoosterTextColor(),
                  )}
                >
                  {nFormatter(
                    currentAPY,
                    new DefiUtils(currentAPY).isLessThan(1) ? 3 : 2,
                  )}
                  %
                </span>
              </div>
            </Hint>
          ) : (
            <>
              {newAPY !== '0' && (
                <div className='w-fit font-semibold'>
                  {new DefiUtils(percentageBoost).isLessThanOrEqualTo(100) && (
                    <Hint
                      containerClassName='md:mt-[0px] md:ml-[0px] ml-[-200px] mt-[-20px]'
                      placement={isMdAndAbove ? 'top-center' : 'right-end'}
                      content={
                        <div className='max-w-[260px] leading-[12.5px] md:max-w-[244px]'>
                          <Trans
                            i18nKey='tooltip-4x75n'
                            components={[
                              <span className='text-[#5AF1B0]' />,
                              <span className='text-[#E24949]' />,
                            ]}
                            ns='translation'
                          />
                        </div>
                      }
                    >
                      {new DefiUtils(inputValue).isGreaterThan(0) && (
                        <div
                          className={clsxm(
                            !md &&
                              'translate-y-[26px] whitespace-nowrap absolute left-1/2 -translate-x-1/2',
                            md &&
                              !lg &&
                              'translate-y-[32px] absolute left-1/2 -translate-x-1/2',
                          )}
                        >
                          <span className='underline'>New Boost:</span>
                          <span className={clsxm(getProgressColor())}>
                            {' '}
                            {nFormatter(
                              newAPY,
                              new DefiUtils(newAPY).isLessThan(1) ? 3 : 2,
                            )}
                            %
                          </span>
                        </div>
                      )}
                    </Hint>
                  )}
                </div>
              )}
            </>
          )}

          <div className='w-fit font-semibold'>
            <Hint
              containerClassName='md:mt-[0px] md:ml-[0px] ml-[-280px] mt-[-20px]'
              placement={isMdAndAbove ? 'top-center' : 'right-end'}
              content={
                <div className='max-w-[260px] leading-[12.5px] md:max-w-[260px]'>
                  <Trans
                    i18nKey='tooltip-4x75v'
                    components={[
                      <span className='text-[#5AF1B0]' />,
                      <span className='text-[#37CE86]' />,
                    ]}
                    ns='translation'
                  />
                </div>
              }
            >
              <span className='underline'>Max Net APY:</span>
              <span className={clsxm(getProgressMaxColor())}>
                {' '}
                {nFormatter(
                  maxAPY,
                  new DefiUtils(maxAPY).isLessThan(1) ? 3 : 2,
                )}
                %
              </span>
            </Hint>
          </div>
        </div>
        <div className='absolute -bottom-3 flex w-full justify-end gap-8 text-[6px] font-medium text-[#7B7BA4] md:-bottom-[12px] md:text-[8.75px] lg:-bottom-3 lg:text-[10px]'>
          {newAPY !== '0' && <div className='w-fit'></div>}
          <div className='w-fit font-semibold'>
            {nFormatter(totalCollateralInHtm)} HTM
          </div>
        </div>
        <div
          className={clsxm(
            'relative h-[17px] w-full lg:h-[18px]',
            isLiquid && 'h-[18px] lg:h-[16.68px]',
            !md && 'h-[15.36px] translate-y-[1.5px]',
          )}
        >
          <div
            className={clsxm(
              'GrayProgressBar absolute top-0 left-0 flex h-[17px] w-full items-center justify-end rounded-full bg-[#B7BCE7] px-2 lg:h-[18px]',
              isLiquid && 'h-[18px] lg:h-[16.68px]',
              !md && 'h-[15.36px]',
            )}
          ></div>

          {inputValue !== '0' && (
            <div
              style={{
                width: `${widthProgress}%`,
              }}
              className={clsxm(
                getProgressBgColor(),
                showBoostDecrease || new DefiUtils(widthProgress).isEqualTo(100)
                  ? 'z-[100]'
                  : 'z-[10]',
                'absolute',
                'top-0',
                'left-0',
                'h-[17px] lg:h-[18px]',
                'rounded-full',
                'px-2',
                isLiquid && 'h-[18px] lg:h-[16.68px]',
                !md && 'h-[15.36px]',
              )}
            ></div>
          )}

          <div
            className={clsxm(
              `${defaultColorBar} absolute top-0 left-0 !z-20 flex h-[17px] items-center justify-end rounded-full bg-[#B7BCE7] px-2 lg:h-[18px]`,
              {
                '!rounded-[100%]': minProgressSize.isLessThan(8),
              },
              isLiquid && 'h-[18px] lg:h-[16.68px]',
              !md && 'h-[15.36px]',
            )}
            style={{
              width: `${minProgressSize.toString()}%`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default GlobalBoostItem;
