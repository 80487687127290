import { Transaction, TransactionPayload } from '@multiversx/sdk-core';
import { ApiNetworkProvider } from '@multiversx/sdk-network-providers';
import {
  WALLET_PROVIDER_CALLBACK_PARAM,
  WALLET_PROVIDER_CALLBACK_PARAM_TX_SIGNED,
  WalletProvider,
} from '@multiversx/sdk-web-wallet-provider';
import { PlainSignedTransaction } from '@multiversx/sdk-web-wallet-provider/out/plainSignedTransaction';
import { captureException } from '@sentry/nextjs';
import { useEffect } from 'react';

import { accountSelector } from '@/store/auth';
import { useAppDispatch, useAppSelector } from '@/store/index';
import { networkStateSelector } from '@/store/network';
import { getRouteGroup } from '@/store/popup';
import {
  addOrUpdateTransactionGroup,
  setTransactionProcessingStatus,
  TRANSACTION_GROUPS_STORAGE_KEY,
  TRANSACTION_PROCESSING_STATUS_TYPE,
  TransactionGroup,
  TransactionItem,
  transactionSelector,
} from '@/store/transaction';

import { chainType, DAPP_INIT_ROUTE, networkConfig } from '@/config/network';
import { getParamFromUrl } from '@/utils/getParamFromUrl';
import { JSONSafeParse } from '@/utils/helpers';
import logger from '@/utils/logger';

import { DappProvider } from '@/types/network';

const removeTransactionFromUrl = () => {
  window.history.replaceState(null, '', window.location.pathname);
};

const formatCurrentTransactions = (txsFormatted: Transaction[]) => {
  return txsFormatted.map((transactionItem) => {
    return {
      hash: transactionItem.getHash().toString(),
      status: 'signed',
      transactionSigned: transactionItem.toPlainObject(),
    };
  });
};

const getTransactionFormatted = (txsFromWallet: PlainSignedTransaction[]) => {
  return txsFromWallet.map((tx) => {
    tx.data = getDataPayloadForTransaction(tx.data);

    return Transaction.fromPlainObject(tx);
  }) as Transaction[];
};

const captureErrorDuplicateSignatures = (
  accountAddress: string,
  txsFromWallet: PlainSignedTransaction[],
) => {
  const signatures = txsFromWallet.map((transaction) => transaction.signature);

  const uniqueSignatures = Array.from(new Set(signatures));

  if (signatures.length === uniqueSignatures.length) {
    return;
  }

  captureException(new Error('Duplicate signatures'), {
    user: {
      id: accountAddress,
    },
    level: 'fatal',
    extra: {
      txsFromWallet,
    },
  });
};

function isStringBase64(str: string) {
  try {
    // Try to decode the string and encode it back using base64 functions
    const atobDecoded = atob(str);
    const btoaEncoded = btoa(atobDecoded);
    const bufferFromDecoded = Buffer.from(str, 'base64').toString();
    const bufferFromEncoded = Buffer.from(bufferFromDecoded).toString('base64');

    // If the result is equal to the initial string
    const isEqualToInitialString =
      str === btoaEncoded && str === bufferFromEncoded;

    // or the atob() conversion is equal to the Buffer.from('base64')
    const isAtobEqualToBufferFrom = atobDecoded === bufferFromDecoded;

    if (isEqualToInitialString || isAtobEqualToBufferFrom) {
      // it is a regular base64 string
      return true;
    }
  } catch (e) {
    return false;
  }

  return false;
}

const getDataPayloadForTransaction = (data?: string): TransactionPayload => {
  const defaultData = data ?? '';

  return isStringBase64(defaultData)
    ? TransactionPayload.fromEncoded(defaultData)
    : new TransactionPayload(defaultData);
};

const useStoreWebWalletTransactionsSigned = () => {
  const dispatch = useAppDispatch();
  const { address: accountAddress } = useAppSelector(accountSelector);
  const { transactionProcessingStatus } = useAppSelector(transactionSelector);
  const dappProvider = useAppSelector(
    networkStateSelector<DappProvider>('dappProvider'),
  );
  const apiNetworkProvider = useAppSelector(
    networkStateSelector<ApiNetworkProvider>('apiNetworkProvider'),
  );

  const storeTransactionInfo = async (
    transactions: TransactionItem[],
    amount: number,
  ) => {
    const transactionGroups = JSONSafeParse(
      localStorage.getItem(TRANSACTION_GROUPS_STORAGE_KEY) || '[]',
      [],
    ) as TransactionGroup[];

    const transactionGroupIndex = transactionGroups.findIndex(
      ({ id }) => id === `${accountAddress}_${getRouteGroup()}`,
    );

    if (transactionGroupIndex === -1) {
      return;
    }

    const transactionGroup: TransactionGroup = {
      ...transactionGroups[transactionGroupIndex],
      transactions,
      amount,
    };

    transactionGroups.splice(transactionGroupIndex, 1, transactionGroup);

    localStorage.setItem(
      TRANSACTION_GROUPS_STORAGE_KEY,
      JSON.stringify(transactionGroups),
    );

    await dispatch(addOrUpdateTransactionGroup(transactionGroup));
  };

  const storeWebWalletTransactions = async () => {
    try {
      const txsFromWallet = new WalletProvider(
        `${networkConfig[chainType].walletAddress}${DAPP_INIT_ROUTE}`,
      ).getTransactionsFromWalletUrl();

      if (txsFromWallet.length === 0) {
        dispatch(
          setTransactionProcessingStatus(
            TRANSACTION_PROCESSING_STATUS_TYPE.GETTING_STORED_TXS,
          ),
        );
        removeTransactionFromUrl();
        return;
      }

      const txsFormatted = getTransactionFormatted(txsFromWallet);

      setTimeout(() => {
        removeTransactionFromUrl();
      }, 1000);

      const transactions = formatCurrentTransactions(txsFormatted);
      const amount = txsFormatted.length;

      captureErrorDuplicateSignatures(accountAddress, txsFromWallet);

      await storeTransactionInfo(transactions, amount);
    } catch (error) {
      logger.error(error?.toString());
      captureException(error);
    } finally {
      dispatch(
        setTransactionProcessingStatus(
          TRANSACTION_PROCESSING_STATUS_TYPE.GETTING_STORED_TXS,
        ),
      );
    }
  };

  useEffect(() => {
    if (
      !dappProvider ||
      !apiNetworkProvider ||
      transactionProcessingStatus !==
        TRANSACTION_PROCESSING_STATUS_TYPE.STORING_WEB_WALLET_TXS_SIGNED
    ) {
      return;
    }

    const walletProviderStatus = getParamFromUrl(
      WALLET_PROVIDER_CALLBACK_PARAM,
    );

    const canStoreWebWalletTransactions =
      walletProviderStatus === WALLET_PROVIDER_CALLBACK_PARAM_TX_SIGNED;
    // && 'getTransactionsFromWalletUrl' in dappProvider;

    if (!canStoreWebWalletTransactions) {
      dispatch(
        setTransactionProcessingStatus(
          TRANSACTION_PROCESSING_STATUS_TYPE.GETTING_STORED_TXS,
        ),
      );
      return;
    }

    storeWebWalletTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dappProvider, apiNetworkProvider]);
};

export default useStoreWebWalletTransactionsSigned;
