
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '@/hooks/useMediaQuery';

import AvatarAddress from '@/components/AvatarAddress';
import Hint from '@/components/Hint';
import CheckIconNoBg from '@/components/popups/ConnectBridgeWalletPopup/components/CheckIconNoBg';
import DoubleArrowsRight from '@/components/popups/ConnectBridgeWalletPopup/components/DoubleArrows';
import EyeIcon from '@/components/popups/ConnectBridgeWalletPopup/components/EyeIcon';
import { WalletTypes } from '@/components/popups/ConnectBridgeWalletPopup/components/types';

import clsxm from '@/services/clsxm';


const AddressButton = ({ address, onWalletLogoutClick }: { onWalletLogoutClick: () => void, address: string }) => {
  const { t } = useTranslation()
  const lg = useMediaQuery('(min-width: 1024px )');
  return (
    <>
      <Hint content={t('id-signature')} offset={14} backgroundColor='#030303'>
        <div className='flex-xy-center lg:h-[24px] lg:w-[24px] '>
          <AvatarAddress address={address as string} className='w-[10px] lg:w-[13px]' />
        </div>
      </Hint>
      {/* <span className='ml-[5px]'>{shortenHash(address as string, 6)}</span> */}
      <span className='text-[#9F8FDF] max-w-[120px] overflow-hidden text-[10px] lg:text-[14px] tracking-[0.02em] lg:tracking-[0em] leading-[12.5px] lg:leading-[15px] ml-[4.5px] lg:ml-0'>{address}</span>
      <button
        className="ml-[6.6px]"
        onClick={(e) => {
          e.isPropagationStopped()
          onWalletLogoutClick()
        }}
      >
        <img
          src='https://cdn.app.hatom.com/images/pages/bridge/bridgeWalletLogout.svg'
          alt='logout'
          width={lg ? 18.1 : 14.1}
          className="min-w-[14.1px] lg:min-w-[18.1px]"
          height="auto"
        />
      </button>
    </>
  )
}

type ConnectFooterProps = {
  onConnectWalletClick: () => void,
  onWalletAddressClick: () => void,
  onWalletLogoutClick: () => void,
  isSource: boolean,
  disableNext: boolean,
  type: WalletTypes,
  address: string | undefined,
  className?: string,
  onNextClick: () => void
  onDoneClick: () => void

}


const connectButtonVariants = {
  "inital": {
    scale: 1
  },
  "hover": {
    scale: 1
  },
}
const ConnectStepFooter = ({ isSource, onConnectWalletClick, onWalletAddressClick, onWalletLogoutClick, onDoneClick, disableNext, onNextClick, type, className, address }: ConnectFooterProps) => {
  const lg = useMediaQuery('(min-width: 1024px )');

  const onTrailingButtonClick = () => {
    if (isSource) {
      onNextClick()
    }
    else {
      onDoneClick()
    }
  }

  const isMultiverse = type === "MULTIVERSE_X"
  return (
    <div className={clsxm(
      'h-[29px] lg:h-[42px]',
      'w-full flex justify-center items-center',
      className
    )}>

      <motion.button
        variants={connectButtonVariants}
        initial="initial"
        whileHover="hover"
        onClick={() => { address ? onWalletAddressClick() : onConnectWalletClick() }}
        className={clsxm(
          "group flex items-center h-full border-[#9F8FDF] hover:border-[#C6B9F9]",
          "translate-x-[0.6px] lg:translate-x-0",
          "w-[130px] lg:w-[175px] rounded-[8px] lg:rounded-[12px] border-[1.5px] lg:border-[2px] mr-[8.5px] lg:mr-[12px]",
          address
            ? "pl-[6px] lg:pl-[10px] pr-[5.3px] lg:pr-[8.6px] justify-between"
            : "pl-[19.2px] pr-[18px] lg:pr-[16px] lg:pl-[22.3px]", // Connect Wallet
          type === "FINNEY" && "bg-black",
          isMultiverse && "bg-white",
        )} >

        {
          address
            ? <AddressButton onWalletLogoutClick={onWalletLogoutClick} address={address as string} /> : <>
              <EyeIcon
                fill='#9F8FDF'
                hoverFill='#C6B9F9'
                size={lg ? 16.4 : 11.3}
                className="mr-[5px] lg:mr-[6.9px] h-auto"
              />
              <span className='text-[10px] lg:text-[14px] text-[#9F8FDF] group-hover:text-[#C6B9F9] font-[600]' >Connect Wallet</span>
            </>
        }
      </motion.button>


      <button
        disabled={disableNext}
        onClick={onTrailingButtonClick}
        className={clsxm(
          "flex items-center  h-full ",
          disableNext ? [
            type === "MULTIVERSE_X" ? "bg-[#A6A6C0]" : "bg-[#525252]"
          ]
            : "bg-[#9F8FDF] hover:bg-[#C6B9F9]",
          "w-[54px] lg:w-[75px] rounded-[8px] lg:rounded-[12px]",
          isSource ? "pl-[10.6px] lg:pl-[14px]" : "pl-[8px] lg:pl-[12.1px]"
        )}
      >
        {
          !isSource && <CheckIconNoBg stroke={!isMultiverse ? "black" : "white"} className='w-[8px] lg:w-[11px] mr-[3px] lg:mr-[4.2px]' />
        }

        <span className={clsxm(
          "text-[10px] lg:text-[14px] translate-y-[-0.3px] font-[600]",
          isMultiverse ? "text-white" : "text-black",

        )}>
          {isSource ? "Next" : "Done"}
        </span>
        {
          isSource && (
            <DoubleArrowsRight
              stroke={isMultiverse ? "white" : "black"}
              className="w-[9.2px] lg:w-[13px] h-auto ml-[4.4px] lg:ml-[6px]"
            />
          )
        }
      </button>
    </div >
  )
}

export default ConnectStepFooter;
