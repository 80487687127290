import DefiUtils from 'defi-utils';

import {
  Market,
  MARKET_KEY,
  TOKEN_LOGO_MAP,
  UserBalance,
} from '@/store/protocol';
import {
  RewardBatch,
  AccountControllerReward as RewardBatchAccountControllerReward,
} from '@/store/reward-batch';

import {
  AccountControllerReward,
  ControllerRewardsBatch,
} from '@/services/blockchain/lens/types';
import {
  ResponseAccountMarketsForMarketRewards,
  ResponseAccountRewardBatch,
  ResponseRewardBatch,
} from '@/services/indexer';
import multiversxSDK from '@/services/multiversx-sdk';
import { getRewardTokenAPY } from '@/utils/math/apy';
import { MAX_CACHE_TIME, queryClient } from '@/utils/query';

export const formatUpdatedAccountAccruedRewardsMap = (
  accountAccruedRewards: {
    id: string;
    amount: string;
  }[],
): Record<string, string> => {
  return accountAccruedRewards.reduce(
    (prev, current) => ({
      ...prev,
      [`${current.id}`]: current.amount,
    }),
    {},
  );
};

export const formatSupplySpeedsMap = (
  supplySpeeds: {
    id: string;
    timestamp: string;
    moneyMarketSymbol: string;
    rewardTokenId: string;
    amount: string;
  }[],
): Record<
  string,
  {
    timestamp: string;
    moneyMarketSymbol: string;
    rewardTokenId: string;
    amount: string;
    id: string;
  }
> => {
  return supplySpeeds.reduce(
    (prev, current) => ({
      ...prev,
      [`${current.id}`]: current,
    }),
    {},
  );
};

export const formatBorrowSpeedsMap = (
  borrowSpeeds: {
    timestamp: string;
    moneyMarketSymbol: string;
    rewardTokenId: string;
    amount: string;
    id: string;
  }[],
): Record<
  string,
  {
    timestamp: string;
    moneyMarketSymbol: string;
    rewardTokenId: string;
    amount: string;
    id: string;
  }
> => {
  return borrowSpeeds.reduce(
    (prev, current) => ({
      ...prev,
      [`${current.id}`]: current,
    }),
    {},
  );
};

export const formatRewardsBatchesMap = (
  rewardsBatches: ResponseRewardBatch[],
): Record<string, ResponseRewardBatch> => {
  return rewardsBatches.reduce(
    (prev, current) => ({
      ...prev,
      [`${current.moneyMarket.underlying.symbol}-${current.id}`]: current,
    }),
    {},
  );
};

export const getUpdatedAccountAccruedRewards = (
  rewardsBatches: ResponseRewardBatch[],
  accountRewards: ResponseAccountRewardBatch[],
  accountMarkets: ResponseAccountMarketsForMarketRewards[],
) => {
  const currentDate = new Date();
  const currentDateInSeconds = currentDate.getTime() / 1000;

  const infoMerged = rewardsBatches.map((marketRewardItem) => {
    const {
      index: marketIndex,
      type,
      moneyMarket,
      rewardsToken,
      id,
      endTime,
    } = marketRewardItem;

    const moneyMarketDecimals = moneyMarket.underlying.decimals;
    const rewardsTokenDecimals = rewardsToken.decimals || 0;

    const accountMarketItem = accountMarkets.find((item) =>
      item.id.includes(moneyMarket.address),
    );

    const accountRewardItem = accountRewards.find((item) =>
      item.id.includes(id),
    );

    const amount = accountRewardItem?.amount || '0';
    const accountIndex = accountRewardItem?.index || '0';

    const collateralTokens = new DefiUtils(
      accountMarketItem?.collateralTokens || '0',
    ).toString();

    const storedBorrowBalance = new DefiUtils(
      accountMarketItem?.storedBorrowBalance || '0',
    ).toString();
    const rewardsTokenId = rewardsToken.id;
    const marketTokenId = moneyMarket.underlying.symbol;
    const marketBorrowIndex = moneyMarket.stateHistory?.[0]?.borrowIndex || '0';

    const marketTimestamp =
      marketRewardItem?.lastTime || new Date().toISOString();
    const accountTimestamp =
      accountRewardItem?.timestamp || new Date().toISOString();

    const speed = marketRewardItem.speed;
    const indexerSpeed = marketRewardItem.indexerSpeed;
    const timestamp = marketRewardItem.lastTime;
    const timestampInSeconds = new Date(timestamp).getTime() / 1000;
    const totalCollateral =
      marketRewardItem.moneyMarket?.totalCollateral || '0';
    const totalBorrows =
      marketRewardItem.moneyMarket.stateHistory?.[0]?.borrows || '0';

    return {
      id,
      amount,
      accountIndex:
        accountIndex === '0'
          ? new DefiUtils(marketIndex).toString()
          : accountIndex,
      collateralTokens,
      storedBorrowBalance,
      marketIndex,
      rewardsTokenId,
      marketTokenId,
      type,
      marketBorrowIndex,
      marketTimestamp,
      accountTimestamp,
      moneyMarketDecimals,
      rewardsTokenDecimals,
      speed,
      indexerSpeed,
      timestamp,
      totalCollateral,
      totalBorrows,
      timestampInSeconds,
      endTime,
    };
  });

  const deltaSupplyRewards = infoMerged
    .filter(({ type }) => type === 'Supply')
    .map(
      ({
        marketTokenId,
        rewardsTokenId,
        accountIndex: supplierIndex,
        marketIndex: supplyIndex,
        collateralTokens,
        timestampInSeconds,
        endTime,
        // speed: supplySpeed,
        indexerSpeed: supplySpeed,
        totalCollateral,
        id,
      }) => {
        const endTimeInSeconds = new Date(endTime).getTime() / 1000;

        const rewardsAccrued = new DefiUtils(
          currentDateInSeconds > endTimeInSeconds
            ? 0
            : currentDateInSeconds - timestampInSeconds,
        ).multipliedBy(supplySpeed);

        const deltaIndex = rewardsAccrued
          .multipliedBy(DefiUtils.WAD)
          .multipliedBy(DefiUtils.WAD)
          .dividedBy(totalCollateral)
          .toString();
        // ---------

        const deltaSupplyIndex = new DefiUtils(
          new DefiUtils(supplyIndex).plus(deltaIndex),
        )
          .minus(supplierIndex)
          .toString();

        const deltaSupplyRewards = new DefiUtils(collateralTokens)
          .multipliedBy(deltaSupplyIndex)
          .dividedBy(DefiUtils.WAD_WAD)
          .toString();

        return {
          id,
          marketTokenId,
          rewardsTokenId,
          deltaSupplyRewards,
        };
      },
    );

  const deltaBorrowRewards = infoMerged
    .filter(({ type }) => type === 'Borrow')
    .map(
      ({
        marketTokenId,
        rewardsTokenId,
        accountIndex: borrowerIndex,
        marketIndex: borrowIndex,
        marketBorrowIndex,
        storedBorrowBalance: borrowAmountT,
        timestampInSeconds,
        // speed: borrowSpeed,
        indexerSpeed: borrowSpeed,
        totalBorrows,
        endTime,
        id,
      }: any) => {
        const endTimeInSeconds = new Date(endTime).getTime() / 1000;

        const newBorrowRewards = new DefiUtils(
          Math.min(currentDateInSeconds, endTimeInSeconds),
        )
          .minus(timestampInSeconds)
          .multipliedBy(borrowSpeed)
          .dividedBy(totalBorrows)
          .multipliedBy(borrowIndex)
          .toString();

        const deltaBorrowIndex = new DefiUtils(borrowIndex)
          .minus(borrowerIndex)
          .plus(newBorrowRewards)
          .toString();

        const borrowAmount0 = new DefiUtils(borrowAmountT)
          .multipliedBy(DefiUtils.WAD)
          .dividedBy(marketBorrowIndex)
          .toString();

        const deltaBorrowRewards = new DefiUtils(borrowAmount0)
          .multipliedBy(deltaBorrowIndex)
          .dividedBy(DefiUtils.WAD_WAD)
          .toString();

        return {
          id,
          marketTokenId,
          rewardsTokenId,
          deltaBorrowRewards,
        };
      },
    );

  const currentRewardsMap = infoMerged.reduce(
    (prev, { marketTokenId, rewardsTokenId, amount: currentRewards, id }) => {
      const key = `${marketTokenId}___${rewardsTokenId}___${id}`;

      if (prev[key]) {
        return {
          ...prev,
          [key]: new DefiUtils(prev[key]).plus(currentRewards).toString(),
        };
      }

      return {
        ...prev,
        [key]: new DefiUtils(currentRewards).toString(),
      };
    },
    {} as Record<string, string>,
  );

  const deltaSupplyRewardsMap = deltaSupplyRewards.reduce(
    (prev, { marketTokenId, rewardsTokenId, deltaSupplyRewards, id }) => {
      const key = `${marketTokenId}___${rewardsTokenId}___${id}`;

      if (prev[key]) {
        return {
          ...prev,
          [key]: new DefiUtils(prev[key]).plus(deltaSupplyRewards).toString(),
        };
      }

      return {
        ...prev,
        [key]: new DefiUtils(deltaSupplyRewards).toString(),
      };
    },
    {} as Record<string, string>,
  );

  const deltaBorrowRewardsMap = deltaBorrowRewards.reduce(
    (prev, { marketTokenId, rewardsTokenId, deltaBorrowRewards, id }) => {
      const key = `${marketTokenId}___${rewardsTokenId}___${id}`;

      if (prev[key]) {
        return {
          ...prev,
          [key]: new DefiUtils(prev[key]).plus(deltaBorrowRewards).toString(),
        };
      }

      return {
        ...prev,
        [key]: new DefiUtils(deltaBorrowRewards).toString(),
      };
    },
    {} as Record<string, string>,
  );

  const updatedAccountAccruedRewards = Object.entries(currentRewardsMap).map(
    ([key, currentRewards]) => {
      const deltaSupplyRewards = deltaSupplyRewardsMap[key] || '0';
      const deltaBorrowRewards = deltaBorrowRewardsMap[key] || '0';

      const [moneyMarketSymbol, rewardId, rewardBatchId] = key.split('___');

      const result = new DefiUtils(currentRewards)
        .plus(deltaSupplyRewards)
        .plus(deltaBorrowRewards);

      return {
        // tokenKey,
        // rewardTokenId,
        moneyMarketSymbol,
        rewardId,
        id: rewardBatchId,
        amount: result.toString(),
      };
    },
  );

  return updatedAccountAccruedRewards;
};

const formatMoneyMarketsMap = (
  rewardsBatches: ResponseRewardBatch[],
): Record<string, ResponseRewardBatch> => {
  return rewardsBatches.reduce(
    (prev, current) => ({
      ...prev,
      [`${current.moneyMarket.underlying.symbol}`]: current,
    }),
    {},
  );
};

export const formatRewardsToken = ({
  rewardsBatches,
  rewardsTokenIds,
  supplySpeeds,
  borrowSpeeds,
  pricesMap,
  markets,
  totalCollateralTokensMap,
  userBalances,
}: {
  rewardsBatches: ResponseRewardBatch[];
  rewardsTokenIds: {
    moneyMarketSymbol: string;
    rewardsTokenIds: { rewardBatchId: string; rewardTokenId: string }[];
  }[];
  supplySpeeds: {
    id: string;
    moneyMarketSymbol: string;
    timestamp: string;
    rewardTokenId: string;
    amount: string;
  }[];
  borrowSpeeds: {
    id: string;
    moneyMarketSymbol: string;
    timestamp: string;
    rewardTokenId: string;
    amount: string;
  }[];
  pricesMap: Record<string, string>;
  markets: Record<MARKET_KEY, Market>;
  totalCollateralTokensMap: Record<string, string>;
  userBalances: Record<MARKET_KEY, UserBalance>;
}) => {
  const rewardsBatchesMap = formatRewardsBatchesMap(rewardsBatches);
  const moneyMarketsMap = formatMoneyMarketsMap(rewardsBatches);

  const supplySpeedsMap = formatSupplySpeedsMap(supplySpeeds);
  const borrowSpeedsMap = formatBorrowSpeedsMap(borrowSpeeds);

  return rewardsTokenIds
    .filter((item) => item && item.moneyMarketSymbol)
    .map((item) => {
      const hTokenExchangeRate =
        markets[item.moneyMarketSymbol as MARKET_KEY]?.hTokenExchangeRate ||
        '0';

      const tokenId = item.moneyMarketSymbol;

      const underlying = markets[item.moneyMarketSymbol as MARKET_KEY]
        ?.underlying || {
        id: '',
        symbol: '',
        decimals: 0,
        name: '',
        priceUSD: '',
      };

      const totalBorrows =
        moneyMarketsMap[item.moneyMarketSymbol].moneyMarket.stateHistory?.[0]
          ?.borrows || '0';

      const hTokenId =
        markets[item.moneyMarketSymbol as MARKET_KEY]?.hToken?.id || '';

      const totalCollateralTokens = totalCollateralTokensMap[hTokenId] || '0';

      const { collateralBalance, hTokenBalance } = userBalances[
        item.moneyMarketSymbol as MARKET_KEY
      ] || { collateralBalance: '0', hTokenBalance: '0' };

      const totalHtokenBalance = new DefiUtils(collateralBalance)
        .plus(hTokenBalance)
        .toString();

      const rewards = item.rewardsTokenIds
        .map((rewardTokenIdItem) => {
          const { rewardsToken, type } =
            rewardsBatchesMap[
              `${item.moneyMarketSymbol}-${rewardTokenIdItem.rewardBatchId}`
            ] || {};

          const { decimals = 18, symbol = '', name = '' } = rewardsToken || {};

          const supplySpeed =
            supplySpeedsMap[`${rewardTokenIdItem.rewardBatchId}`]?.amount ||
            '0';

          const borrowSpeed =
            borrowSpeedsMap[`${rewardTokenIdItem.rewardBatchId}`]?.amount ||
            '0';

          const speed = DefiUtils.max(supplySpeed, borrowSpeed).toString();
          const speedPerDay = new DefiUtils(speed)
            .multipliedBy(DefiUtils.SECONDS_PER_DAY)
            .toString();

          const priceUSD = pricesMap[rewardTokenIdItem.rewardTokenId] || '0';
          const distributedDollarAmountPerDay = new DefiUtils(1)
            .multipliedBy(speedPerDay)
            .dividedBy(`1e${decimals}`)
            .multipliedBy(priceUSD)
            .toString();

          const logo =
            TOKEN_LOGO_MAP[symbol]?.normal ||
            'https://cdn.app.hatom.com/images/coin_placeholder.png';

          const totalUSD =
            type === 'Supply'
              ? new DefiUtils(totalCollateralTokens)
                  .toUnderlying(hTokenExchangeRate)
                  .toFullDecimals(underlying.decimals)
                  .toUSD(underlying.priceUSD)
                  .toString()
              : new DefiUtils(totalBorrows)
                  .toFullDecimals(underlying.decimals)
                  .toUSD(underlying.priceUSD)
                  .toString();

          const apy = getRewardTokenAPY(
            distributedDollarAmountPerDay,
            totalUSD,
          );

          const userSupplyApy = new DefiUtils(apy)
            .multipliedBy(
              new DefiUtils(collateralBalance)
                .multipliedBy(100)
                .dividedBy(totalHtokenBalance)
                .dividedBy(100),
            )
            .toSafeString();

          const userApy =
            type === 'Supply' ||
            !new DefiUtils(totalHtokenBalance).isEqualTo(collateralBalance)
              ? userSupplyApy
              : apy;

          return {
            type,
            speed,
            tokenId: rewardTokenIdItem.rewardTokenId,
            batchId: rewardTokenIdItem.rewardBatchId,
            symbol,
            priceUSD,
            logo,
            name,
            decimals: String(decimals),
            apy,
            userApy,
          } as RewardBatch;
        })
        .filter((item) => item.symbol !== '-');

      const totalRewardsBorrowAPY = rewards
        .filter(({ type }) => type === 'Borrow')
        .reduce((prev, current) => prev.plus(current.apy), new DefiUtils('0'))
        .toString();

      const totalRewardsSupplyAPY = rewards
        .filter(({ type }) => type === 'Supply')
        .reduce((prev, current) => prev.plus(current.apy), new DefiUtils('0'))
        .toString();

      const userRewardsBorrowAPY = rewards
        .filter(({ type }) => type === 'Borrow')
        .reduce(
          (prev, current) => prev.plus(current.userApy),
          new DefiUtils('0'),
        )
        .toString();

      const userRewardsSupplyAPY = rewards
        .filter(({ type }) => type === 'Supply')
        .reduce(
          (prev, current) => prev.plus(current.userApy),
          new DefiUtils('0'),
        )
        .toString();

      return {
        tokenId,
        totalRewardsBorrowAPY,
        totalRewardsSupplyAPY,
        userRewardsBorrowAPY,
        userRewardsSupplyAPY,
        rewards,
      };
    })
    .reduce((prev, { tokenId, ...rest }) => {
      return {
        ...prev,
        [tokenId]: { ...rest },
      };
    }, {});
};

export const formatAccountRewards = ({
  rewardsBatches,
  updatedAccountAccruedRewards,
  pricesMap,
}: {
  rewardsBatches: ResponseRewardBatch[];
  updatedAccountAccruedRewards: {
    id: string;
    amount: string;
    rewardId: string;
  }[];
  pricesMap: Record<string, string>;
}): RewardBatchAccountControllerReward[] => {
  const updatedAccountAccruedRewardsMap = updatedAccountAccruedRewards.reduce(
    (prev, current) => ({
      ...prev,
      [current.rewardId]: new DefiUtils(prev[current.rewardId] || '0')
        .plus(current.amount)
        .toString(),
    }),
    {} as Record<string, string>,
  );

  const rewardsTokensMap = rewardsBatches.reduce(
    (prev, { rewardsToken }) => ({
      ...prev,
      [rewardsToken.symbol]: rewardsToken,
    }),
    {} as Record<
      string,
      {
        id: string;
        decimals: number;
        symbol: string;
        name: string;
      }
    >,
  );

  return Object.values(rewardsTokensMap).map(
    ({ id: tokenId, decimals, name, symbol }) => {
      const priceUSD = pricesMap[tokenId] || '0';

      const userRewardBalanceAsBigNumber =
        updatedAccountAccruedRewardsMap?.[`${tokenId}`] || '0';

      const userRewardBalance = new DefiUtils(userRewardBalanceAsBigNumber)
        .toFullDecimals(decimals)
        .toString();

      const userRewardBalanceUSD = new DefiUtils(userRewardBalance)
        .toUSD(priceUSD)
        .toString();

      return {
        tokenId,
        userRewardBalance,
        userRewardBalanceUSD,
        symbol,
        priceUSD,
        logo:
          TOKEN_LOGO_MAP[symbol]?.normal ||
          'https://cdn.app.hatom.com/images/coin_placeholder.png',
        name,
        decimals,
      };
    },
  );
};

export const formatAccountRewardsWithoutIndexer = ({
  protocolMarkets,
  accountControllerRewards,
  pricesMap,
}: {
  protocolMarkets: Record<MARKET_KEY, Market>;
  accountControllerRewards: AccountControllerReward[];
  pricesMap: Record<string, string>;
}): RewardBatchAccountControllerReward[] => {
  const tokensIdsMap = Object.values(protocolMarkets).reduce(
    (prev, current) => ({
      ...prev,
      [current.underlying.id]: current.underlying,
    }),
    {} as Record<
      string,
      { id: string; decimals: number; symbol: string; name: string }
    >,
  );

  return accountControllerRewards.map(({ rewards, tokenId }) => {
    const {
      decimals = 18,
      symbol = '',
      name = '',
    } = tokensIdsMap[tokenId] || {};

    const priceUSD = pricesMap[tokenId] || '0';

    const userRewardBalanceAsBigNumber = rewards;

    const userRewardBalance = new DefiUtils(userRewardBalanceAsBigNumber)
      .toFullDecimals(decimals)
      .toString();
    const userRewardBalanceUSD = new DefiUtils(userRewardBalance)
      .toUSD(priceUSD)
      .toString();

    return {
      tokenId,
      userRewardBalance,
      userRewardBalanceUSD,
      symbol,
      priceUSD,
      logo:
        TOKEN_LOGO_MAP[symbol]?.normal ||
        'https://cdn.app.hatom.com/images/coin_placeholder.png',
      name,
      decimals,
    };
  });
};

export const formatRewardsTokenWithoutIndexer = ({
  controllerRewardsBatches,
  pricesMap,
  protocolMarkets,
  totalCollateralTokensMap,
  userBalances,
}: {
  controllerRewardsBatches: ControllerRewardsBatch[];
  pricesMap: Record<string, string>;
  protocolMarkets: Record<MARKET_KEY, Market>;
  totalCollateralTokensMap: Record<string, string>;
  userBalances: Record<MARKET_KEY, UserBalance>;
}): Record<MARKET_KEY, Market> => {
  const currentTime = Date.now();

  const controllerRewardsBatchesMap = controllerRewardsBatches.reduce(
    (prev, current) => ({
      ...prev,
      [`${current.tokenId}-${current.moneyMarket}-${current.id}`]: {
        ...current,
        speed:
          currentTime >= new Date(current.endTime).getTime()
            ? '0'
            : new DefiUtils(current.speed).toFullDecimals(18).toString(),
      },
    }),
    {} as Record<string, ControllerRewardsBatch>,
  );

  const moneyMarketAddressSymbolMap = Object.values(protocolMarkets).reduce(
    (prev, current) => ({
      ...prev,
      [current.address]: current.underlying.symbol,
    }),
    {} as Record<string, string>,
  );

  const rewardsTokenIds = Object.values(
    controllerRewardsBatches
      .map(({ moneyMarket, id, tokenId }) => {
        return {
          moneyMarketSymbol: moneyMarketAddressSymbolMap[moneyMarket],
          rewardBatchId: `${moneyMarket}-${id}`,
          rewardTokenId: tokenId,
        };
      })
      .reduce(
        (prev, { moneyMarketSymbol, rewardBatchId, rewardTokenId }) => {
          const key = `${moneyMarketSymbol}-${rewardTokenId}`;

          return {
            ...prev,
            [key]: {
              moneyMarketSymbol,
              rewardsTokenIds: [
                ...(prev[key]?.rewardsTokenIds || []),
                {
                  rewardBatchId,
                  rewardTokenId,
                },
              ],
            },
          };
        },
        {} as Record<
          string,
          {
            moneyMarketSymbol: string;
            rewardsTokenIds: {
              rewardBatchId: string;
              rewardTokenId: string;
            }[];
          }
        >,
      ),
  );

  const tokensMap = Object.values(protocolMarkets).reduce(
    (prev, current) => ({
      ...prev,
      [current.underlying.id]: current.underlying,
    }),
    {} as Record<
      string,
      {
        decimals: number;
        symbol: string;
        name: string;
      }
    >,
  );

  return rewardsTokenIds
    .filter((item) => item && item.moneyMarketSymbol)
    .map((item) => {
      const hTokenExchangeRate =
        protocolMarkets[item.moneyMarketSymbol as MARKET_KEY]
          ?.hTokenExchangeRate || '0';

      const tokenId = item.moneyMarketSymbol;

      const underlying = protocolMarkets[item.moneyMarketSymbol as MARKET_KEY]
        ?.underlying || {
        id: '',
        symbol: '',
        decimals: 0,
        name: '',
        priceUSD: '',
      };

      const totalBorrows =
        protocolMarkets[item.moneyMarketSymbol as MARKET_KEY]?.totalBorrow ||
        '0';

      const hTokenId =
        protocolMarkets[item.moneyMarketSymbol as MARKET_KEY]?.hToken?.id || '';

      const totalCollateralTokens = totalCollateralTokensMap[hTokenId] || '0';

      const { collateralBalance, hTokenBalance } = userBalances[
        item.moneyMarketSymbol as MARKET_KEY
      ] || { collateralBalance: '0', hTokenBalance: '0' };

      const totalHtokenBalance = new DefiUtils(collateralBalance)
        .plus(hTokenBalance)
        .toString();

      const rewards = item.rewardsTokenIds
        .map((rewardTokenIdItem) => {
          const {
            decimals = 18,
            symbol = '',
            name = '',
          } = tokensMap[rewardTokenIdItem.rewardTokenId] || {};

          const controllerRewardBatchKey = `${rewardTokenIdItem.rewardTokenId}-${rewardTokenIdItem.rewardBatchId}`;

          const { speed = '0', marketType: type = '' } =
            controllerRewardsBatchesMap[controllerRewardBatchKey] || {};

          const speedPerDay = new DefiUtils(speed)
            .multipliedBy(DefiUtils.SECONDS_PER_DAY)
            .toString();

          const priceUSD = pricesMap[rewardTokenIdItem.rewardTokenId] || '0';
          const distributedDollarAmountPerDay = new DefiUtils(1)
            .multipliedBy(speedPerDay)
            .dividedBy(`1e${decimals}`)
            .multipliedBy(priceUSD)
            .toString();

          const logo =
            TOKEN_LOGO_MAP[symbol]?.normal ||
            'https://cdn.app.hatom.com/images/coin_placeholder.png';

          const totalUSD =
            type === 'Supply'
              ? new DefiUtils(totalCollateralTokens)
                  .toUnderlying(hTokenExchangeRate)
                  .toFullDecimals(underlying.decimals)
                  .toUSD(underlying.priceUSD)
                  .toString()
              : new DefiUtils(totalBorrows)
                  .toFullDecimals(underlying.decimals)
                  .toUSD(underlying.priceUSD)
                  .toString();

          const apy = getRewardTokenAPY(
            distributedDollarAmountPerDay,
            totalUSD,
          );

          const userSupplyApy = new DefiUtils(apy)
            .multipliedBy(
              new DefiUtils(collateralBalance)
                .multipliedBy(100)
                .dividedBy(totalHtokenBalance)
                .dividedBy(100),
            )
            .toSafeString();

          const userApy =
            type === 'Supply' ||
            !new DefiUtils(totalHtokenBalance).isEqualTo(collateralBalance)
              ? userSupplyApy
              : apy;

          return {
            type,
            speed,
            tokenId: rewardTokenIdItem.rewardTokenId,
            batchId: rewardTokenIdItem.rewardBatchId,
            symbol,
            priceUSD,
            logo,
            name,
            decimals: String(decimals),
            apy,
            userApy,
          } as RewardBatch;
        })
        .filter((item) => item.symbol !== '-');

      const totalRewardsBorrowAPY = rewards
        .filter(({ type }) => type === 'Borrow')
        .reduce((prev, current) => prev.plus(current.apy), new DefiUtils('0'))
        .toString();

      const totalRewardsSupplyAPY = rewards
        .filter(({ type }) => type === 'Supply')
        .reduce((prev, current) => prev.plus(current.apy), new DefiUtils('0'))
        .toString();

      const userRewardsBorrowAPY = rewards
        .filter(({ type }) => type === 'Borrow')
        .reduce(
          (prev, current) => prev.plus(current.userApy),
          new DefiUtils('0'),
        )
        .toString();

      const userRewardsSupplyAPY = rewards
        .filter(({ type }) => type === 'Supply')
        .reduce(
          (prev, current) => prev.plus(current.userApy),
          new DefiUtils('0'),
        )
        .toString();

      return {
        tokenId,
        totalRewardsBorrowAPY,
        totalRewardsSupplyAPY,
        userRewardsBorrowAPY,
        userRewardsSupplyAPY,
        rewards,
      };
    })
    .reduce((prev, { tokenId, ...rest }) => {
      return {
        ...prev,
        [tokenId]: { ...rest },
      };
    }, {}) as Record<MARKET_KEY, Market>;
};

export const getRewardsPrices = async (
  markets: Record<MARKET_KEY, Market>,
  identifiers: string[],
) => {
  if (identifiers.length === 0) {
    return {};
  }

  const tokens = await queryClient.fetchQuery({
    queryKey: ['tokens', identifiers.join(',')],
    queryFn: () =>
      multiversxSDK.tokens({
        identifiers: identifiers.join(','),
        fields: 'price,ticker,identifier'
      }),
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  });

  return tokens.reduce(
    (prev, current) => {
      const multiversxPriceUSD = current.price?.toString() || '0';
      const indexerPriceUSD =
        markets[current?.ticker.split('-')[0] as MARKET_KEY]?.underlying
          ?.priceUSD || '0';
      const priceUSD =
        indexerPriceUSD === '0' ? multiversxPriceUSD : indexerPriceUSD;

      return {
        ...prev,
        [current.identifier]: priceUSD,
      };
    },
    {} as Record<string, string>,
  );
};

export const getBalanceByIdentifiers = async (
  controllerAddress: string,
  identifiers: string[],
): Promise<Record<string, string>> => {
  if (!controllerAddress) {
    return {} as Record<string, string>;
  }

  const accountTokens = await queryClient.fetchQuery({
    queryKey: ['accountTokens', controllerAddress, identifiers.join(',')],
    queryFn: () =>
      multiversxSDK.accountTokens(controllerAddress, {
        identifiers: identifiers.join(','),
      }),
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  });

  const hTokensIdsMap = accountTokens.reduce(
    (prev, { name, balance }) => ({
      ...prev,
      [`${name.replace('Hatom', 'H')}-`]: balance,
    }),
    {},
  ) as Record<string, string>;

  const wrappedIdsMap = accountTokens.reduce(
    (prev, { name, balance }) => ({
      ...prev,
      [`${name.replace('Wrapped', '')}-`]: balance,
    }),
    {},
  ) as Record<string, string>;

  const result = identifiers.reduce(
    (prev, currentIdenfitier) => {
      const balanceByIdentifier = accountTokens.find(
        ({ identifier }) => identifier === currentIdenfitier,
      )?.balance;
      const balanceByAsset = accountTokens.find(
        ({ assets }) => assets?.pngUrl?.includes(currentIdenfitier),
      )?.balance;
      const balanceByHToken =
        hTokensIdsMap[`${currentIdenfitier.split('-')?.[0] || ''}-`];

      const balanceByWrapped =
        wrappedIdsMap[`${currentIdenfitier.split('-')?.[0] || ''}-`];

      return {
        ...prev,
        [currentIdenfitier]:
          balanceByIdentifier ||
          balanceByAsset ||
          balanceByHToken ||
          balanceByWrapped ||
          '0',
      };
    },
    {} as Record<string, string>,
  );

  return result;
};

export const getUniqueRewardsTokensIds = (
  rewardsBatches: ResponseRewardBatch[],
) => {
  return [
    // @ts-ignore
    ...new Set(rewardsBatches.map(({ rewardsToken }) => rewardsToken.id)),
  ];
};

export const getAccountMarketsIds = (
  markets: Record<string, Market>,
  accountAddress: string,
) => {
  return accountAddress
    ? [
        // @ts-ignore
        ...new Set(
          Object.values(markets)
            .filter((market) => market.supported)
            .map(({ address }) => `${address}-${accountAddress}`),
        ),
      ]
    : [];
};

export const getSupplySpeeds = (rewardsBatches: ResponseRewardBatch[]) => {
  return rewardsBatches
    .filter((rewardBatch) => rewardBatch.type === 'Supply')
    .map(
      // @ts-ignore
      ({ speed, rewardsToken, moneyMarket, lastTime: timestamp, id }) => ({
        id,
        timestamp,
        amount: speed,
        rewardTokenId: rewardsToken.id,
        moneyMarketSymbol: moneyMarket.underlying.symbol,
      }),
    );
};

export const getBorrowSpeeds = (rewardsBatches: ResponseRewardBatch[]) => {
  return rewardsBatches
    .filter((rewardBatch) => rewardBatch.type === 'Borrow')
    .map(
      // @ts-ignore
      ({ speed, rewardsToken, moneyMarket, lastTime: timestamp, id }) => ({
        id,
        timestamp,
        amount: speed,
        rewardTokenId: rewardsToken.id,
        moneyMarketSymbol: moneyMarket.underlying.symbol,
      }),
    );
};

export const getRewardsTokensIds = (rewardsBatches: ResponseRewardBatch[]) => {
  return Object.values<{
    moneyMarketSymbol: string;
    rewardsTokenIds: {
      rewardBatchId: string;
      rewardTokenId: string;
    }[];
  }>(
    rewardsBatches.reduce(
      (
        prev: { [key: string]: any },
        { rewardsToken, moneyMarket, id: rewardBatchId },
      ) => {
        const rewardTokenId = rewardsToken.id;
        const moneyMarketSymbol = moneyMarket.underlying.symbol;

        if (prev[moneyMarketSymbol]) {
          const canAdd = prev[moneyMarketSymbol].rewardsTokenIds.every(
            (item: any) => item.rewardBatchId !== rewardBatchId,
          );

          if (!canAdd) {
            return prev;
          }

          const rewardsTokenIdsItem = { rewardTokenId, rewardBatchId };

          return {
            ...prev,
            [moneyMarketSymbol]: {
              ...prev[moneyMarketSymbol],
              rewardsTokenIds: [
                ...prev[moneyMarketSymbol].rewardsTokenIds,
                rewardsTokenIdsItem,
              ],
            },
          };
        }

        return {
          ...prev,
          [moneyMarketSymbol]: {
            moneyMarketSymbol,
            rewardsTokenIds: [
              {
                rewardTokenId,
                rewardBatchId,
              },
            ],
          },
        };
      },
      {},
    ),
  );
};

export const getAcumulatorsPairsIds = (
  acumulators: {
    rewardsToken: {
      id: string;
      symbol: string;
    };
    swapPath: {
      id: string;
    }[];
    premium: string;
  }[],
) => {
  return acumulators
    .map(({ rewardsToken, swapPath }) => {
      const tokensIds = [rewardsToken.id, ...swapPath.map(({ id }) => id)];

      return tokensIds
        .slice(0, -1)
        .map((tokenId, index) => `${tokenId}/${tokensIds[index + 1]}`);
    })
    .flat();
};

export const formatAcumulators = ({
  acumulators,
  pairs,
}: {
  acumulators: {
    rewardsToken: {
      id: string;
      symbol: string;
    };
    swapPath: {
      id: string;
    }[];
    premium: string;
  }[];
  pairs: {
    pairsIds: string;
    exchangeRate: string;
  }[];
}) => {
  const pairsMap = pairs.reduce(
    (prev, { pairsIds, exchangeRate }) => ({
      ...prev,
      [pairsIds]: exchangeRate,
    }),
    {} as Record<string, string>,
  );

  const boosterBoosters = acumulators.reduce(
    (prev, { premium, rewardsToken, swapPath }) => {
      const tokensIds = [rewardsToken.id, ...swapPath.map(({ id }) => id)];
      const exchangeRates = tokensIds
        .slice(0, -1)
        .map(
          (tokenId, index) =>
            pairsMap[`${tokenId}/${tokensIds[index + 1]}`] || '0',
        );

      const exchangeRate = exchangeRates
        .reduce((prev, current) => prev.multipliedBy(current), new DefiUtils(1))
        .toString();

      return {
        ...prev,
        [rewardsToken.symbol]: {
          premium,
          exchangeRate,
        },
      };
    },
    {} as Record<string, { premium: string; exchangeRate: string }>,
  );

  return boosterBoosters;
};
