import { persistQueryClient } from '@tanstack/query-persist-client-core';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import type { QueryClient } from '@tanstack/react-query';

import { chainType } from '@/config/network';

const storageKey = `hatom-${chainType}-1683662856727`;

export const makePersistent = (queryClient: QueryClient) => {
  if (typeof window !== 'undefined') {
    const persister = createSyncStoragePersister({
      key: storageKey,
      storage: window.localStorage,
      serialize: JSON.stringify,
      deserialize: JSON.parse,
    });

    persistQueryClient({
      queryClient,
      persister,
      dehydrateOptions: {
        shouldDehydrateQuery: (query: any) =>
          query.cacheTime !== 0 &&
          query.queryHash !== JSON.stringify([{ entity: 'signer' }]),
      },
      buster: storageKey,
    });
  }
};
