import DefiUtils from 'defi-utils';

import { HIDDEN_DELEGATION_CONTRACTS, TAO_SERVICE_FEE } from '@/store/protocol';

import blockchainService from '@/services/blockchain';
import { AccountMarketData } from '@/services/blockchain/lens/types';
import {
  ResponseProtocol,
  ResponseProtocolAccountMarket,
  ResponseProtocolUpdated,
} from '@/services/blockchain/protocol/types';
import multiversxSDK from '@/services/multiversx-sdk';
import { getTaoApr } from '@/services/tao-stats';
import { calcTaoApy } from '@/utils/math/apy';
import {
  calcLiquidStakingAPY,
  INITIAL_TAO_EXCHANGE_RATE,
} from '@/utils/math/liquid-staking';
import { MAX_CACHE_TIME, MIN_CACHE_TIME, queryClient } from '@/utils/query';

const getMarketsV2 = async () => {
  // aca_aca:remove in the future
  const moneyMarketData = (
    await blockchainService.lens.getMarketsData([])
  ).filter(({ underlyingId }) => !underlyingId.includes('MEX-'));

  const identifierIds = moneyMarketData
    .map(({ tokenId, underlyingId }) => [tokenId, underlyingId])
    .flat(1)
    .sort()
    .join(',');

  const tokenData = await queryClient.fetchQuery({
    queryKey: ['tokens', identifierIds],
    queryFn: () =>
      multiversxSDK.tokens({
        identifiers: identifierIds,
        fields: 'name,ticker,identifier,decimals',
      }),
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  });

  const tokenMap = tokenData.reduce(
    (prev, current) => ({
      ...prev,
      [current.identifier]: {
        name: current.name,
        symbol: current.ticker.split('-')?.[0] || '',
        id: current.identifier,
        decimals: current.decimals,
      },
    }),
    {} as Record<
      string,
      {
        name: string;
        symbol: string;
        id: string;
        decimals: number;
      }
    >,
  );

  const markets = moneyMarketData.map((item) => {
    const underlyingSymbol = tokenMap?.[item.underlyingId]?.symbol || 'EGLD';

    const underlying = {
      id: tokenMap?.[item.underlyingId]?.id || 'EGLD',
      name: tokenMap?.[item.underlyingId]?.name || 'MultiversX',
      decimals: tokenMap?.[item.underlyingId]?.decimals || 18,
      symbol: underlyingSymbol,
    };

    const hToken = {
      name: tokenMap?.[item.tokenId]?.name || '',
      symbol: tokenMap?.[item.tokenId]?.symbol || '',
      id: tokenMap?.[item.tokenId]?.id || '',
      decimals: tokenMap?.[item.tokenId]?.decimals || 18,
    };

    const borrowCap = item.borrowCap;
    const liquidityCap = item.liquidityCap;

    return {
      borrowCap: borrowCap === '0' ? null : borrowCap,
      liquidityCap: liquidityCap === '0' ? null : liquidityCap,
      mintStatus: item.isMintAllowed ? 'Active' : 'Paused',
      borrowStatus: item.isBorrowAllowed ? 'Active' : 'Paused',
      supported: true,
      address: item.address,
      collateralFactor: item.collateralFactor,
      liquidationIncentive: item.liquidationIncentive,
      underlying,
      hToken,
      dailyStateHistory: [
        {
          moneyMarketState: {
            cash: item.cash,
            borrows: item.totalBorrows,
            reserves: item.totalReserves,
            totalSupply: item.totalSupply,
            timestamp: new Date(
              new DefiUtils(item.accrualTimestamp)
                .multipliedBy(1000)
                .toNumber(),
            ).toISOString(),
            supplyRatePerSecond: item.supplyRatePerSecond,
            borrowRatePerSecond: item.borrowRatePerSecond,
          },
        },
      ],
    };
  });

  return markets.reduce(
    (prev, current) => ({
      ...prev,
      [current.underlying.symbol]: current,
    }),
    {},
  );
};

export const getProtocol = async (): Promise<ResponseProtocol> => {
  const queryKey = ['blockchain:protocol:getProtocol'];

  const queryFn = async () => {
    const [
      lendegateAddress,
      liquidStakingData,
      delegationContracts,
      boosterData,
      controllerData,
      taoData,
      markets,
      lsTaoApr,
    ] = await Promise.all([
      blockchainService.lens.getLendegate(),
      blockchainService.lens.getLiquidStakingData(),
      blockchainService.lens.getDelegationContractsData(),
      blockchainService.lens.getBoosterData(),
      blockchainService.lens.getControllerData(),
      blockchainService.lens.getTaoData(),
      getMarketsV2(),
      getTaoApr(),
    ]);

    const filteredDelegationContracts = delegationContracts
      .map((delegationContractItem) => ({
        ...delegationContractItem,
        serviceFee: new DefiUtils(delegationContractItem.serviceFee)
          .dividedBy(100)
          .toString(),
        apr: new DefiUtils(delegationContractItem.apr)
          .dividedBy(100)
          .toString(),
      }))
      .filter(
        (delegationContractItem) =>
          !HIDDEN_DELEGATION_CONTRACTS.includes(
            delegationContractItem.contract,
          ),
      );

    const _lsTotalFee = new DefiUtils(liquidStakingData.totalFee)
      .dividedBy(100)
      .toString();

    const lsAPY =
      filteredDelegationContracts.length === 0
        ? '6'
        : calcLiquidStakingAPY(_lsTotalFee, filteredDelegationContracts);

    const lsTotalStaked = filteredDelegationContracts
      .reduce(
        (prev, { totalDelegated, pendingToDelegate }) =>
          prev.plus(
            new DefiUtils(totalDelegated).plus(pendingToDelegate).toString(),
          ),
        new DefiUtils('0'),
      )
      .toString();

    return {
      markets: markets as any,
      booster: {
        address: boosterData.address,
        cooldownPeriod: boosterData.cooldownPeriod,
        stakingRatioThreshold: boosterData.stakingRatioThreshold,
        totalStaked: boosterData.totalStake,
      },
      liquidStaking: {
        address: liquidStakingData.address,
        undelegateTokenId: liquidStakingData.undelegateTokenId,
        lsTokenId: liquidStakingData.lsTokenId,
        zapAddress: lendegateAddress,
        totalFee: _lsTotalFee,
        totalStaked: lsTotalStaked,
        apy: lsAPY,
        unbondPeriod: liquidStakingData.unbondPeriod,
        minEgldToDelegate: '1',
        exchangeRate: liquidStakingData.exchangeRate,
      },
      liquidStakingTao: {
        address: taoData.taoLiquidStakingData.address || '',
        lsTokenId: taoData.taoLiquidStakingData.lsTokenId || '',
        totalFee: TAO_SERVICE_FEE,
        totalStaked: taoData.taoLiquidStakingData.cash || '0',
        exchangeRate:
          taoData.taoLiquidStakingData.currentExchangeRate ||
          INITIAL_TAO_EXCHANGE_RATE,
        apy: calcTaoApy(lsTaoApr),
        zapAddress: taoData.taoLendegate || '',
        tokenSupply: taoData.wrappedTaoData.tokenSupply || '0',
      },
      controller: {
        address: controllerData.address,
        maxMarketsPerAccount: controllerData.maxMarketsPerAccount,
      },
      governance: {
        address: '',
        votingDelayInBlocks: '0',
        quorum: '0',
        votingPeriodInBlocks: '0',
        voteNftId: '',
      },
    };
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  });
};

export const getProtocolUpdated =
  async (): Promise<ResponseProtocolUpdated> => {
    const queryKey = ['blockchain:protocol:getProtocolUpdated'];

    const queryFn = async () => {
      const [markets, liquidStakingData, boosterData, taoData, lsTaoApr] =
        await Promise.all([
          getMarketsV2(),
          blockchainService.lens.getLiquidStakingData(),
          blockchainService.lens.getBoosterData(),
          blockchainService.lens.getTaoData(),
          getTaoApr(),
        ]);

      return {
        markets: markets as any,
        booster: {
          address: boosterData.address,
          cooldownPeriod: boosterData.cooldownPeriod,
          stakingRatioThreshold: boosterData.stakingRatioThreshold,
          totalStaked: boosterData.totalStake,
        },
        liquidStakingTao: {
          totalFee: TAO_SERVICE_FEE,
          totalStaked: taoData.taoLiquidStakingData.cash || '0',
          exchangeRate:
            taoData.taoLiquidStakingData.currentExchangeRate ||
            INITIAL_TAO_EXCHANGE_RATE,
          apy: calcTaoApy(lsTaoApr),
          tokenSupply: taoData.wrappedTaoData.tokenSupply || '0',
        },
        liquidStaking: {
          totalStaked: liquidStakingData.cashReserve,
          exchangeRate: liquidStakingData.exchangeRate,
        },
      };
    };

    return queryClient.fetchQuery({
      queryKey,
      queryFn,
      cacheTime: MIN_CACHE_TIME,
      staleTime: MIN_CACHE_TIME,
    }) as Promise<ResponseProtocolUpdated>;
  };

export const getProtocolAccountMarkets = async (
  accountAddress: string,
  markets: { address: string; symbol: string }[],
): Promise<ResponseProtocolAccountMarket[]> => {
  if (!accountAddress) {
    return [];
  }

  const queryKey = [
    'blockchain:protocol:getProtocolAccountMarkets',
    accountAddress,
  ];

  const queryFn = async () => {
    const marketAddresses = markets.map(({ address }) => address);

    const [accountMarketsData] = await Promise.all([
      blockchainService.lens.getAccountMarketsData(
        accountAddress,
        marketAddresses,
      ),
    ]);

    const accountMarketsDataMap = accountMarketsData.reduce(
      (prev, current) => ({
        ...prev,
        [current.marketData.address]: current,
      }),
      {} as Record<string, AccountMarketData>,
    );

    return markets.map(({ symbol, address }) => {
      const accountMarketItem = accountMarketsDataMap[address];

      return {
        collateralTokens: accountMarketItem?.collateral || '0',
        storedBorrowBalance: accountMarketItem?.borrowAmount || '0',
        accountBorrowIndex: accountMarketItem?.borrowIndex || '0',
        market: {
          address,
          underlying: {
            symbol,
          },
          lastState: {
            borrowIndex: accountMarketItem?.marketData?.borrowIndex || '0',
            timestamp: new Date(
              new DefiUtils(
                accountMarketItem?.marketData?.accrualTimestamp || 0,
              )
                .multipliedBy(1000)
                .toNumber(),
            ).toISOString(),
            borrowRatePerSecond:
              accountMarketItem?.marketData?.borrowRatePerSecond || '0',
          },
        },
      };
    });
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MIN_CACHE_TIME,
    staleTime: MIN_CACHE_TIME,
  });
};
