import { createContext } from 'react';

import useStoreAvailableToRedeem from '@/contexts/GovernanceContext/hooks/useStoreAvailableToRedeem';
import useStoreHtmEngagedInVoting from '@/contexts/GovernanceContext/hooks/useStoreHtmEngagedInVoting';
import useStoreVotesAvailableToRedeem from '@/contexts/GovernanceContext/hooks/useStoreVotesAvailableToRedeem';

const GovernanceContext = createContext({});

const GovernanceConsumer = GovernanceContext.Consumer;

const GovernanceProvider = ({ children }: { children: JSX.Element }) => {
  useStoreHtmEngagedInVoting();
  useStoreVotesAvailableToRedeem();
  useStoreAvailableToRedeem();

  return (
    <GovernanceContext.Provider value={{}}>
      {children}
    </GovernanceContext.Provider>
  );
};

export default GovernanceContext;

export { GovernanceConsumer, GovernanceProvider };
