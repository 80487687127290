import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/index';
import { lendAppSelector, setLendApp } from '@/store/lend-app';

import { queryClient } from '@/utils/query';

const showLiquidStakingAPYKey = 'showLiquidStakingAPY';
const showLiquidStakingTaoAPYKey = 'showLiquidStakingTaoAPY';

const useStoreShowLiquidStakingAPY = () => {
  const dispatch = useAppDispatch();
  const { showLiquidStakingAPY, showLiquidStakingTaoAPY } =
    useAppSelector(lendAppSelector);

  useEffect(() => {
    const _showLiquidStakingAPY = queryClient.getQueryData<boolean | undefined>(
      [showLiquidStakingAPYKey],
    );
    const _showLiquidStakingTaoAPY = queryClient.getQueryData<
      boolean | undefined
    >([showLiquidStakingTaoAPYKey]);

    dispatch(
      setLendApp({
        showLiquidStakingAPY:
          typeof _showLiquidStakingAPY === 'undefined'
            ? true
            : _showLiquidStakingAPY,
        showLiquidStakingTaoAPY:
          typeof _showLiquidStakingTaoAPY === 'undefined'
            ? true
            : _showLiquidStakingTaoAPY,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    queryClient.setQueryData([showLiquidStakingAPYKey], showLiquidStakingAPY);
  }, [showLiquidStakingAPY]);

  useEffect(() => {
    queryClient.setQueryData(
      [showLiquidStakingTaoAPYKey],
      showLiquidStakingTaoAPY,
    );
  }, [showLiquidStakingTaoAPY]);
};

export default useStoreShowLiquidStakingAPY;
