import { SVGProps } from 'react';

export function GriffinHeadIcon(
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 16'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M14.3462 5.60287C14.036 7.91668 11.8889 7.89283 10.7915 9.22864C8.95453 11.5186 9.69409 13.5462 7.9764 13.9994C6.2587 13.5462 6.99826 11.4947 5.16128 9.20478C4.11158 7.84512 1.9406 7.89283 1.65432 5.55516C1.41575 3.78999 2.34617 2.69272 3.08573 2.00096C3.13345 1.95325 2.63245 3.69457 3.39587 4.76799C4.06386 5.67443 5.90085 6.19921 6.80741 6.89097C7.88097 7.74971 8.00025 8.68 8.00025 8.65615C8.02411 8.65615 8.16725 7.74971 9.1931 6.93868C10.0997 6.24692 11.9366 5.72214 12.6046 4.8157C13.3681 3.76614 12.8671 2.02481 12.9148 2.07252C13.6782 2.76428 14.5848 3.86155 14.3462 5.60287Z' />
    </svg>
  );
}
