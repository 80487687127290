import useMediaQuery from '@/hooks/useMediaQuery';
import { useSsr } from 'usehooks-ts';

import { getBreakpointValue } from '../services/tailwind';

type Device = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
type Options = { serverValue?: boolean };

const useTailwindBreakpoint = (device: Device, options?: Options) => {
  const { isServer } = useSsr();
  const { serverValue } = options || {};
  const pxMap = {
    sm: getBreakpointValue('sm'),
    md: getBreakpointValue('md'),
    lg: getBreakpointValue('lg'),
    xl: getBreakpointValue('xl'),
    xxl: getBreakpointValue('2xl'),
  };

  const matches = useMediaQuery(`(min-width: ${pxMap[device]}px)`);

  if (isServer && serverValue) return serverValue;

  return matches;
};

export default useTailwindBreakpoint;
