import { useEffect } from 'react';

import { useAppSelector } from '@/store/index';
import {
  TRANSACTION_GROUPS_STORAGE_KEY,
  TRANSACTION_PROCESSING_STATUS_TYPE,
  transactionSelector,
} from '@/store/transaction';

const useLocalStoreTransactionGroups = () => {
  const { transactionProcessingStatus, transactionGroups } =
    useAppSelector(transactionSelector);

  useEffect(() => {
    if (
      transactionProcessingStatus !==
      TRANSACTION_PROCESSING_STATUS_TYPE.STORING_TXS
    ) {
      return;
    }

    localStorage.setItem(
      TRANSACTION_GROUPS_STORAGE_KEY,
      JSON.stringify(transactionGroups)
    );
  }, [transactionProcessingStatus, transactionGroups]);
};

export default useLocalStoreTransactionGroups;
