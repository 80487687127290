import { useEffect } from 'react';

import { useAppDispatch } from '@/store/index';
import { subtractTimeLeftInSeconds } from '@/store/protocol';

const useStoreIntervalTimeLeft = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(subtractTimeLeftInSeconds());
    }, 1_000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useStoreIntervalTimeLeft;
