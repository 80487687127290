import { SignableMessage, Transaction } from '@multiversx/sdk-core';
import { providerNotInitializedError } from '@multiversx/sdk-dapp-utils/out/helpers/providerNotInitializedError';
import { WebviewProvider } from '@multiversx/sdk-webview-provider/out/WebviewProvider';
import { IDappProvider } from '@/types/dappProvider';

export class ExperimentalWebviewProvider implements IDappProvider {
  private static _instance: ExperimentalWebviewProvider;
  private readonly _provider: WebviewProvider;

  static getInstance() {
    if (!ExperimentalWebviewProvider._instance) {
      ExperimentalWebviewProvider._instance = new ExperimentalWebviewProvider();
    }
    return ExperimentalWebviewProvider._instance;
  }

  constructor() {
    this._provider = WebviewProvider.getInstance();
  }

  init = async () => {
    return await this._provider.init();
  };

  login = async () => {
    await this._provider.login();
    return ''
  };

  logout = async () => {
    const response = await this._provider.logout();
    return response;
  };

  signTransactions = async (
    transactionsToSign: Transaction[]
  ): Promise<Transaction[]> => {
    const response = await this._provider.signTransactions(transactionsToSign);

    if (!response) {
      this._provider.cancelAction();
      return [];
    }

    return response;
  };

  signTransaction = async (transaction: Transaction) => {
    const data =  await this._provider.signTransaction(transaction);

    return data as Transaction
  };

  
  signMessage = async (
    message: SignableMessage
  ): Promise<SignableMessage | null> => {
    return await this._provider.signMessage(message);
  };

  cancelAction = async () => {
    return await this._provider.cancelAction();
  };

  isInitialized = () => {
    return this._provider.isInitialized();
  };

  isConnected = async () => {
    return await this._provider.isConnected();
  };

  sendCustomRequest = async (payload: {
    request: { method: string; params: any };
  }) => {
    this._provider.sendPostMessage({
      type: payload.request.method as any,
      payload: payload.request.params
    });
  };

  getAddress = providerNotInitializedError('getAddress');
}
