import { AnimationItem } from 'lottie-web';
import dynamic from 'next/dynamic';
import React, { useContext, useEffect, useRef } from 'react';

import { LoaderContext } from '@/contexts/LoaderContextProvider';

type WrappedLottiePlayerProps = {
  src: string | Object;
  autoplay?: boolean;
  loop?: boolean;
  alt?: boolean;
  className?: string;
  onEvent?: (eventName: string) => void;
};

const Player = dynamic(() =>
  import('@lottiefiles/react-lottie-player').then((module) => module.Player)
);

const WrappedLottiePlayer: React.FC<WrappedLottiePlayerProps> = ({
  src,
  autoplay = true,
  loop = true,
  className,
  onEvent,
}) => {
  const lottie = useRef<AnimationItem>();
  const { mark } = useContext(LoaderContext);

  useEffect(() => {
    if (autoplay) {
      lottie.current?.play();
    } else {
      lottie.current?.pause();
    }
  }, [autoplay]);

  return (
    <Player
      lottieRef={(lottieRef) => {
        lottie.current = lottieRef;
        const observer = new IntersectionObserver(
          ([entry]) => {
            const inView = entry.isIntersecting;
            if (inView && autoplay) {
              lottieRef.play();
            } else {
              lottieRef.pause();
            }
          },
          {
            rootMargin: '0px',
          }
        );

        // @ts-ignore
        observer.observe(lottieRef.wrapper);
      }}
      className={`loading-resource ${className}`}
      autoplay={autoplay}
      loop={loop}
      src={src}
      onEvent={(e) => {
        if (e === 'load')
          mark?.(
            `LOTTIE: ${
              src &&
              typeof src == 'string' &&
              src.substring(src.lastIndexOf('/')).substring(1)
            }`
          );
        if (onEvent) onEvent(e);
      }}
    ></Player>
  );
};

export default WrappedLottiePlayer;
