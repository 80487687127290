import React from 'react';

import useTheme from '@/hooks/useTheme';

import { useAppSelector } from '@/store';
import { boosterSelector } from '@/store/booster';

import clsxm from '@/services/clsxm';

const ResponsiveHtmBoosterBgImage: React.FC<any> = () => {
  const { isDark } = useTheme();
  const { needRebalance } = useAppSelector(boosterSelector);

  return (
    <div
      className={clsxm(
        'p-0',
        'h-[53.2px] w-[150px] md:h-[97px] md:w-[251px] xl:h-[74px] xl:w-[208px]',
        'dark:h-[54.2px] dark:w-[152px] md:dark:h-[73px] md:dark:w-[224px] xl:dark:h-[74px] xl:dark:w-[208px]',
        'absolute z-[-1]',
        'bottom-[4px] right-[-0.2px]',
        'md:right-[-18.7px] md:bottom-[-29px] xl:right-[0.6px] xl:bottom-[-6px]',
        'md:dark:right-[0.2px] md:dark:bottom-[-7px] xl:dark:bottom-[-5.5px] xl:dark:right-[0.3px] ',
      )}
    >
      <img
        src={
          needRebalance
            ? !isDark
              ? `https://cdn.app.hatom.com/images/liquidstaking/app/htm-booster-bg-sm-danger.svg`
              : `https://cdn.app.hatom.com/images/liquidstaking/app/htm-booster-bg-sm-danger.svg`
            : !isDark
            ? `https://cdn.app.hatom.com/images/liquidstaking/app/htm-booster-bg-sm.svg`
            : `https://cdn.app.hatom.com/images/liquidstaking/app/htm-booster-bg-sm-dark.svg`
        }
        alt='card'
        className='h-full w-full object-fill  block md:hidden'
      />
      <img
        src={
          needRebalance
            ? !isDark
              ? `https://cdn.app.hatom.com/images/liquidstaking/app/htm-booster-bg-md-danger.svg`
              : `https://cdn.app.hatom.com/images/liquidstaking/app/htm-booster-bg-md-danger.svg`
            : !isDark
            ? `https://cdn.app.hatom.com/images/liquidstaking/app/htm-booster-bg-md.svg`
            : `https://cdn.app.hatom.com/images/liquidstaking/app/htm-booster-bg-md-dark.svg`
        }
        alt='card'
        className='h-full w-full object-cover hidden md:block xl:hidden'
      />
      <img
        src={
          needRebalance
            ? !isDark
              ? `https://cdn.app.hatom.com/images/liquidstaking/app/htm-booster-bg-xl-danger.svg`
              : `https://cdn.app.hatom.com/images/liquidstaking/app/htm-booster-bg-xl-danger.svg`
            : !isDark
            ? `https://cdn.app.hatom.com/images/liquidstaking/app/htm-booster-bg-xl.svg`
            : `https://cdn.app.hatom.com/images/liquidstaking/app/htm-booster-bg-xl-dark.svg`
        }
        alt='card'
        className='h-full w-full object-cover hidden xl:block'
      />
    </div>
  );
};

export default ResponsiveHtmBoosterBgImage;
