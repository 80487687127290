interface Colors {
  offset: number;
  color: string;
}

const getColorAtPosition = (colors: Colors[], positionPercentage: number) => {
  if (typeof window === 'undefined') {
    return '';
  }

  // Obtener el elemento del canvas
  const myCanvas = document.createElement('canvas');
  myCanvas.height = 100;
  myCanvas.width = 100;
  // var myCanvas = document.getElementById("myCanvas");
  const ctx = myCanvas.getContext('2d');

  // Crear el gradiente

  const gradient = ctx?.createLinearGradient(0, 0, myCanvas.width, 0);

  colors.forEach(({ offset, color }) => {
    gradient?.addColorStop(offset / 100, color);
  });

  // Dibujar el gradiente en el canvas
  if (ctx && gradient) ctx.fillStyle = gradient;
  if (ctx) ctx.fillRect(0, 0, myCanvas.width, myCanvas.height);

  // Obtener el color en el 80% de la posición
  const position = Math.floor((positionPercentage / 100) * myCanvas.width);
  const pixelData = ctx?.getImageData(position, 0, 1, 1).data;

  // Crear el color RGB a partir de los datos de píxeles
  const color =
    pixelData &&
    'rgb(' + pixelData[0] + ', ' + pixelData[1] + ', ' + pixelData[2] + ')';

  return color;
};

export default getColorAtPosition;
