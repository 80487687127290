import useMediaQuery from '@/hooks/useMediaQuery';
import { motion } from 'framer-motion';
import { useState } from 'react';

import {
    BridgeWalletPopupSteps,
    WalletTypes,
} from '@/components/popups/ConnectBridgeWalletPopup/components/types';
import ConnectWalletStep from '@/components/popups/ConnectBridgeWalletPopup/ConnectStep';
import WalletChoiceStep from '@/components/popups/ConnectBridgeWalletPopup/WalletChoiceStep';
import PopupBed from '@/components/popups/PopupBed';

import { useAppDispatch, useAppSelector } from '@/store';
import { closePopup, popupSelector } from '@/store/popup';

import clsxm from '@/services/clsxm';

const ConnectBridgeWalletPopup = ({
  sourceType,
}: {
  sourceType: WalletTypes;
}) => {
  const {
    data: {
      openedFromBridgeBridgeToLink,
      openedFromConnectWalletButton,
      onDoneCallback,
    },
  } = useAppSelector(popupSelector);
  const dispatch = useAppDispatch();

  const [activeWalletType, setActiveWalletType] =
    useState<WalletTypes>(sourceType);
  const [step, setStep] = useState<BridgeWalletPopupSteps>(
    openedFromBridgeBridgeToLink ? 'ASK_TO_CONNECT' : 'CHOOSE_PROVIDER',
  );
  const [expanded, setExpanded] = useState(false);
  const [sourceAddress, setSourceAddress] = useState<string | undefined>(
    undefined,
  ); // for testing
  const [destinationAddress, setDestinationAddress] = useState<
    string | undefined
  >(undefined); // for testing
  const lg = useMediaQuery('(min-width: 1024px )');

  const handleClick = () => {
    setStep('CONNECTED');
    setExpanded(false);
    if (activeWalletType === sourceType) {
      setSourceAddress(
        activeWalletType === 'MULTIVERSE_X'
          ? 'erd17za...26ghqh'
          : '5HQSJ...AFFK',
      );
    } else {
      setDestinationAddress(
        activeWalletType === 'MULTIVERSE_X'
          ? 'erd17za...26ghqh'
          : '5HQSJ...AFFK',
      );
    }
    if (openedFromConnectWalletButton) dispatch(closePopup());
  };

  return (
    <PopupBed
      className={clsxm(
        'translate-y-[-19.5px] lg:translate-y-[11.6px]',
        activeWalletType === 'FINNEY' && '!border-[#383838]',
        activeWalletType === 'MULTIVERSE_X' && '!border-[#DADAE8]',
        // !expanded ? "lg:translate-y-[11.6px]" : "lg:translate-y-[2.9px]"
      )}
      closeBtnClassName='!right-[14.7px] !top-[11.6px] lg:!right-[19.4px] lg:!top-[20px]'
      closeBtnIconClassName='!w-[7.7px] lg:!w-[11px]'
      maxWidth={362}
      enableScaling={false}
      forcedCloseBtnLightColor={
        activeWalletType === 'FINNEY' ? 'white' : undefined
      }
      forcedCloseBtnDarkColor={
        activeWalletType === 'FINNEY' ? 'white' : 'black'
      }
    >
      <motion.div
        className={clsxm(
          'relative w-[256px] lg:w-[358px] overflow-hidden',
          activeWalletType === 'MULTIVERSE_X' ? 'bg-white' : 'bg-black',
        )}
        animate={{
          height: expanded ? (lg ? 560 : 395) : lg ? 521 : 371.8,
        }}
        transition={{
          duration: 0.2,
          ease: 'circOut',
        }}
      >
        <img
          src='https://cdn.app.hatom.com/images/pages/bridge/wavePattern.svg'
          alt='wave pattern'
          width={470}
          className={clsxm(
            'h-full absolute z-[0] top-[0.6px] left-1/2 -translate-x-1/2 ',
            expanded && 'h-[395px] lg:h-[560px]',
            activeWalletType === 'FINNEY' && 'opacity-10',
          )}
        />
        <div className='relative h-fit w-full z-[3] '>
          {step !== 'CHOOSE_PROVIDER' ? (
            <ConnectWalletStep
              onDoneClick={() => {
                if (onDoneCallback) onDoneCallback();
              }}
              address={
                activeWalletType === sourceType
                  ? sourceAddress
                  : destinationAddress
              }
              setAddress={(value: string | undefined) => {
                if (activeWalletType === sourceType) {
                  setSourceAddress(value);
                } else {
                  setDestinationAddress(value);
                }
              }}
              key={activeWalletType}
              sourceType={sourceType}
              activeType={activeWalletType}
              step={step}
              setStep={setStep}
              setActiveWalletType={setActiveWalletType}
            />
          ) : (
            <WalletChoiceStep expanded={expanded} type={activeWalletType} />
          )}
        </div>
      </motion.div>
    </PopupBed>
  );
};

export default ConnectBridgeWalletPopup;
