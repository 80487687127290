import { AnimationItem } from 'lottie-web';
import dynamic from 'next/dynamic';
import React, { useRef } from 'react';

const Player = dynamic(() =>
  import('@lottiefiles/react-lottie-player').then((module) => module.Player)
);

export const DonutAnim: React.FunctionComponent<any> = (props) => {
  const ref = useRef<AnimationItem>();

  return (
    <div
      onMouseOver={() => {
        ref.current?.pause();
      }}
      onMouseLeave={() => {
        ref.current?.play();
      }}
      {...props}
    >
      <Player
        lottieRef={(lottieRef) => {
          ref.current = lottieRef;
        }}
        className='loading-resource w-[16px]'
        autoplay={true}
        loop={true}
        src='https://cdn.app.hatom.com/animations/donut.json'
      />
    </div>
  );
};
