import safeJsonParse from '@/utils/safeJsonParse';

export const loaderEnabled =
  process.env.NEXT_PUBLIC_BOOTSTRAPPING_LOADER == 'true';
export const isProd = process.env.NODE_ENV == 'production';
export const indexerUrl = process.env.NEXT_PUBLIC_API_URL || '';
export const taoStatsUrl =
  process.env.NEXT_PUBLIC_TAO_STATS_URL ||
  'https://api.subquery.network/sq/TaoStats/bittensor-indexer';

export const gtmId = process.env.NEXT_PUBLIC_GTM_ID || '';
export const indexationEnabled =
  process.env.NEXT_PUBLIC_ENABLE_INDEXATION === 'true';

export const hiddenPages = process.env.NEXT_PUBLIC_HIDDEN_PAGES
  ? process.env.NEXT_PUBLIC_HIDDEN_PAGES.split(',')
  : [];

export const allowedTransactions = process.env.NEXT_PUBLIC_ALLOWED_TRANSACTIONS
  ? process.env.NEXT_PUBLIC_ALLOWED_TRANSACTIONS.split(',')
  : [];

export const walletConnectV2ProjectId =
  process.env.NEXT_PUBLIC_WALLET_CONNECT_V2_PROJECT_ID || '';

export const hTokenBalanceProvider =
  process.env.NEXT_PUBLIC_H_TOKEN_BALANCE_PROVIDER || '';

const NEXT_PUBLIC_HAS_SPLIT_LIQUID_STAKING_STAKE =
  process.env.NEXT_PUBLIC_HAS_SPLIT_LIQUID_STAKING_STAKE || 'true';

export const hasSplitLiquidStakingStake =
  NEXT_PUBLIC_HAS_SPLIT_LIQUID_STAKING_STAKE === 'true';

const NEXT_PUBLIC_HAS_SPLIT_LIQUID_STAKING_UNSTAKE =
  process.env.NEXT_PUBLIC_HAS_SPLIT_LIQUID_STAKING_UNSTAKE || 'true';

export const hasSplitLiquidStakingUnstake =
  NEXT_PUBLIC_HAS_SPLIT_LIQUID_STAKING_UNSTAKE === 'true';

export const whiteListAddress = process.env.NEXT_PUBLIC_WHITE_LIST_ADDRESS
  ? process.env.NEXT_PUBLIC_WHITE_LIST_ADDRESS.split(',')
  : [];

export const ENV_REDIRECTS: {
  source: string;
  destination: string;
  permanent: boolean;
}[] = process.env.NEXT_PUBLIC_ROUTE_REWRITE
  ? process.env.NEXT_PUBLIC_ROUTE_REWRITE.split(',')?.map((e) => {
      const [source, destination] = e.split('<->');
      return {
        source,
        destination,
        permanent: false,
      };
    })
  : [];

export const minutesBeforeToBlockTheUnstake = parseInt(
  process.env.NEXT_PUBLIC_MINUTES_BEFORE_TO_BLOCK_THE_UNSTAKE || '10',
);

export const showNetApyV2 = process.env.NEXT_PUBLIC_SHOW_NET_APY_V2 === 'true';

// this is required for a version isIndexerEnabled = false
export const smartContracts = {
  liquidLockingAddress:
    process.env.NEXT_PUBLIC_SMART_CONTRACTS_LIQUID_LOCKING_ADDRESS || '',
  lensAddress: process.env.NEXT_PUBLIC_SMART_CONTRACTS_LENS_ADDRESS || '',
};

export const accountCollateralTokensList = safeJsonParse(
  process.env.NEXT_PUBLIC_ACCOUNT_COLLATERAL_TOKENS_LIST || '[]',
  [],
);

export const bridgeUrl =
  process.env.NEXT_PUBLIC_BRIDGE_URL ||
  'https://develop-hatom-bridge.vercel.app/bridge';

export const hatomGatewayAddress =
  process.env.NEXT_PUBLIC_HATOM_GATEWAY_ADDRESS;

export const links = {
  devnetURL: 'https://devnet.hatom.com',
  testnetURL: 'https://devnet.hatom.com',
  mainnetURL: 'https://app.hatom.com',
  hatomLabsURL: 'https://hatomlabs.com',
  hatomUrl: 'https://hatom.com',
  esdtURL: 'https://esdt.io',
  blogURL: 'https://mirror.xyz/0xDac8B6141d28C46765255607f3572c73A064383f',
  docURL: 'https://docs.hatom.com',
  discordURL: 'https://discord.com/invite/s5WtQQe3',
  twitterURL: 'https://twitter.com/HatomProtocol',
  telegramURL: 'https://t.me/+tfGNdvZpgcoxNDM0',
  githubURL: 'https://github.com/HatomProtocol',
};

export const tokenVestingAddress = 'erd1qqqqqqqqqqqqqpgq4hl5hdvvxtm987nw5vtczgm8y89ra2hndteshlehgs'

export const tokenVestingIdentifier = 'LKHTM-cd8d6c'