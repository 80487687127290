import React from 'react';

import clsxm from '@/services/clsxm';

type Props = {
  status: string;
};

const BoostStatus = (props: Props) => {
  return (
    <div
      className={clsxm(
        'flex items-center justify-center',
        'rounded-[4px] h-[14px] px-0.5 text-[8px] leading-[10px] font-semibold',
        props.status === 'Optimal'
          ? 'bg-[#BDF9DF] text-[#53AE94] dark:bg-[#2F5657] dark:text-[#37CE86]'
          : props.status === 'Superior'
          ? 'bg-[#BDD8F9] text-[#2B8EE9] dark:bg-[#2F486E] dark:text-[#2B8EE9]'
          : props.status === 'Moderate'
          ? 'bg-[#FFF8DD] text-[#E4AF08]  dark:bg-[#5E4F36] dark:text-[#E4AF08]'
          : 'bg-[#FFE5DD] text-[#E04040] dark:text-[#E04040] dark:bg-[#6E2F2F]',
      )}
    >
      <span>{props.status}</span>
    </div>
  );
};

export default BoostStatus;
