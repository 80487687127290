import { AbiRegistry, Address, AddressValue } from '@multiversx/sdk-core/out';
import DefiUtils from 'defi-utils';

import moneyMarketABI from '@/abis/money-market';
import blockchainService from '@/services/blockchain';
import client from '@/services/blockchain/client';
import {
  InterestRateModelData,
  MarketData,
} from '@/services/blockchain/lens/types';
import {
  ResponseLendAppMarkets,
  ResponseMarkets,
} from '@/services/blockchain/money-market/types';
import { calcExchangeRate } from '@/utils/math/market';
import { MAX_CACHE_TIME, MIN_CACHE_TIME, queryClient } from '@/utils/query';

export const getMarkets = async (
  markets: { address: string; symbol: string }[],
): Promise<ResponseMarkets[]> => {
  const queryKey = ['blockchain:money-market:getMarkets'];

  const queryFn = async () => {
    const [moneyMarketsData, interestRateModels] = await Promise.all([
      blockchainService.lens.getMarketsData([]),
      blockchainService.lens.getInterestRateModelsData(),
    ]);

    const interestRateModelsMap = interestRateModels.reduce(
      (prev, current) => ({
        ...prev,
        [current.market]: current,
      }),
      {} as Record<string, InterestRateModelData>,
    );

    const moneyMarketsDataMap = moneyMarketsData.reduce(
      (prev, current) => ({
        ...prev,
        [current.address]: current,
      }),
      {} as Record<string, MarketData>,
    );

    return markets.map(({ address, symbol }) => {
      const moneyMarketItem = moneyMarketsDataMap[address] || {};

      return {
        collateralFactor: moneyMarketItem?.collateralFactor || '0',
        reserveFactor: moneyMarketItem?.reserveFactor || '0',
        interestRateModel: interestRateModelsMap[address] || {
          baseRate: '0',
          firstSlope: '0',
          lastSlope: '0',
          optimalUtilization: '0',
        },
        underlying: {
          symbol,
        },
        lastState: {
          totalSupply: moneyMarketItem.totalSupply || '0',
          borrows: moneyMarketItem.totalBorrows || '0',
          supplyRatePerSecond: moneyMarketItem.supplyRatePerSecond || '0',
          borrowRatePerSecond: moneyMarketItem.borrowRatePerSecond || '0',
          cash: moneyMarketItem.cash || '0',
          reserves: moneyMarketItem.totalReserves || '0',
        },
      };
    });
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  }) as Promise<ResponseMarkets[]>;
};

export const getLendAppMarkets = async (
  markets: { address: string; id: string }[],
): Promise<ResponseLendAppMarkets[]> => {
  const queryKey = ['blockchain:money-market:getLendAppMarkets'];

  const queryFn = async () => {
    const marketAddresses = markets.map(({ address }) => address);

    const [cashsMap] = await Promise.all([
      blockchainService.moneyMarket.getCashsMap(marketAddresses),
    ]);

    return markets.map(({ address, id }) => {
      return {
        underlying: {
          id,
        },
        dailyStateHistory: [
          {
            moneyMarketState: {
              cash: cashsMap[address] || '0',
            },
          },
        ],
      };
    });
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MIN_CACHE_TIME,
    staleTime: MIN_CACHE_TIME,
  }) as Promise<ResponseLendAppMarkets[]>;
};

export const getTotalSupply = async (
  moneyMarketAddress: string,
): Promise<string> => {
  const response = await client({
    address: moneyMarketAddress,
    method: 'getTotalSupply',
    abi: AbiRegistry.create(moneyMarketABI),
  });

  return response?.[0]?.toString() || '0';
};

export const getCash = async (moneyMarketAddress: string): Promise<string> => {
  const response = await client({
    address: moneyMarketAddress,
    method: 'getCash',
    abi: AbiRegistry.create(moneyMarketABI),
  });

  return response?.[0]?.toString() || '0';
};

export const getCashs = async (moneyMarketAddresses: string[]) => {
  return Promise.all(
    Object.values(moneyMarketAddresses).map(async (address) => {
      const cash = await getCash(address);

      return {
        address,
        cash,
      };
    }),
  );
};

export const getCashsMap = async (moneyMarketAddresses: string[]) => {
  const cashs = await getCashs(moneyMarketAddresses);

  return cashs.reduce(
    (prev, current) => ({
      ...prev,
      [current.address]: current.cash,
    }),
    {} as Record<string, string>,
  );
};

export const getStoredExchangeRate = async (
  moneyMarketAddress: string,
): Promise<string> => {
  const response = await client({
    address: moneyMarketAddress,
    method: 'getStoredExchangeRate',
    abi: AbiRegistry.create(moneyMarketABI),
  });

  return response?.[0]?.toString() || '0';
};

export const getStoredExchangeRates = async (
  moneyMarketAddresses: string[],
) => {
  return Promise.all(
    Object.values(moneyMarketAddresses).map(async (address) => {
      const storedExchangeRate = await getStoredExchangeRate(address);

      return {
        address,
        storedExchangeRate,
      };
    }),
  );
};

export const getStoredExchangeRatesMap = async (
  moneyMarketAddresses: string[],
) => {
  const storedExchangeRates =
    await getStoredExchangeRates(moneyMarketAddresses);

  return storedExchangeRates.reduce(
    (prev, current) => ({
      ...prev,
      [current.address]: current.storedExchangeRate,
    }),
    {} as Record<string, string>,
  );
};

export const getStakingRewards = async (
  moneyMarketAddress: string,
): Promise<string> => {
  const response = await client({
    address: moneyMarketAddress,
    method: 'getStakingRewards',
    abi: AbiRegistry.create(moneyMarketABI),
  });

  return response?.[0]?.toString() || '0';
};

export const getStakingRewardsList = async (moneyMarketAddresses: string[]) => {
  return Promise.all(
    Object.values(moneyMarketAddresses).map(async (address) => {
      const stakingRewards = await getStakingRewards(address);

      return {
        address,
        stakingRewards,
      };
    }),
  );
};

export const getStakingRewardsListMap = async (
  moneyMarketAddresses: string[],
) => {
  const stakingRewardsList = await getStakingRewardsList(moneyMarketAddresses);

  return stakingRewardsList.reduce(
    (prev, current) => ({
      ...prev,
      [current.address]: current.stakingRewards,
    }),
    {} as Record<string, string>,
  );
};

export const getTotalBorrows = async (
  moneyMarketAddress: string,
): Promise<string> => {
  const response = await client({
    address: moneyMarketAddress,
    method: 'getTotalBorrows',
    abi: AbiRegistry.create(moneyMarketABI),
  });

  return response?.[0]?.toString() || '0';
};

export const getTotalBorrowsList = async (moneyMarketAddress: string[]) => {
  return Promise.all(
    Object.values(moneyMarketAddress).map(async (address) => {
      const totalBorrows = await getTotalBorrows(address);

      return {
        address,
        totalBorrows,
      };
    }),
  );
};

export const getTotalBorrowsListMap = async (moneyMarketAddress: string[]) => {
  const totalBorrowsList = await getTotalBorrowsList(moneyMarketAddress);

  return totalBorrowsList.reduce(
    (prev, current) => ({
      ...prev,
      [current.address]: current.totalBorrows,
    }),
    {} as Record<string, string>,
  );
};

export const getTotalReserves = async (
  moneyMarketAddress: string,
): Promise<string> => {
  const response = await client({
    address: moneyMarketAddress,
    method: 'getTotalReserves',
    abi: AbiRegistry.create(moneyMarketABI),
  });

  return response?.[0]?.toString() || '0';
};

export const getTotalReservesList = async (moneyMarketAddresses: string[]) => {
  return Promise.all(
    Object.values(moneyMarketAddresses).map(async (address) => {
      const totalReserves = await getTotalReserves(address);

      return {
        address,
        totalReserves,
      };
    }),
  );
};

export const getTotalReservesListMap = async (
  moneyMarketAddresses: string[],
) => {
  const totalReservesList = await getTotalReservesList(moneyMarketAddresses);

  return totalReservesList.reduce(
    (prev, current) => ({
      ...prev,
      [current.address]: current.totalReserves,
    }),
    {} as Record<string, string>,
  );
};

export const getAccrualTimestamp = async (
  moneyMarketAddress: string,
): Promise<string> => {
  const response = await client({
    address: moneyMarketAddress,
    method: 'getAccrualTimestamp',
    abi: AbiRegistry.create(moneyMarketABI),
  });

  return new Date(
    new DefiUtils(response?.[0]?.toString() || '0')
      .multipliedBy(1000)
      .toNumber(),
  ).toISOString();
};

export const getAccrualTimestamps = async (moneyMarketAddresses: string[]) => {
  return Promise.all(
    Object.values(moneyMarketAddresses).map(async (address) => {
      const accrualTimestamp =
        await blockchainService.moneyMarket.getAccrualTimestamp(address);

      return {
        address,
        accrualTimestamp,
      };
    }),
  );
};

export const getAccrualTimestampsMap = async (
  moneyMarketAddresses: string[],
) => {
  const accrualTimestamps = await getAccrualTimestamps(moneyMarketAddresses);

  return accrualTimestamps.reduce(
    (prev, current) => ({
      ...prev,
      [current.address]: current.accrualTimestamp,
    }),
    {} as Record<string, string>,
  );
};

export const getInterestRateModel = async (
  moneyMarketAddress: string,
): Promise<string> => {
  const response = await client({
    address: moneyMarketAddress,
    method: 'getInterestRateModel',
    abi: AbiRegistry.create(moneyMarketABI),
  });

  return response?.[0].valueOf().toString() || '0';
};

export const getExchangeRate = async (moneyMarketAddress: string) => {
  const [totalSupply, cash, borrows, reserves] = await Promise.all([
    getTotalSupply(moneyMarketAddress),
    getCash(moneyMarketAddress),
    getTotalBorrows(moneyMarketAddress),
    getTotalReserves(moneyMarketAddress),
  ]);

  return calcExchangeRate({ cash, borrows, reserves, totalSupply });
};

export const getSupplyRatePerSecond = async (moneyMarketAddress: string) => {
  const response = await client({
    address: moneyMarketAddress,
    method: 'getSupplyRatePerSecond',
    abi: AbiRegistry.create(moneyMarketABI),
  });

  return response?.[0]?.toString() || '0';
};

export const getSupplyRatesPerSecond = async (
  moneyMarketAddresses: string[],
) => {
  return Promise.all(
    moneyMarketAddresses.map(async (marketAddress) => {
      const supplyRatePerSecond = await getSupplyRatePerSecond(marketAddress);

      return {
        supplyRatePerSecond,
        address: marketAddress,
      };
    }),
  );
};

export const getSupplyRatesPerSecondMap = async (
  moneyMarketAddresses: string[],
): Promise<Record<string, string>> => {
  const queryKey = ['blockchain:money-market:getSupplyRatesPerSecondMap'];

  const queryFn = async () => {
    const supplyRatesPerSecond =
      await getSupplyRatesPerSecond(moneyMarketAddresses);

    return supplyRatesPerSecond.reduce(
      (prev, current) => ({
        ...prev,
        [current.address]: current.supplyRatePerSecond,
      }),
      {} as Record<string, string>,
    );
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  }) as Promise<Record<string, string>>;
};

export const getBorrowRatePerSecond = async (moneyMarketAddress: string) => {
  const response = await client({
    address: moneyMarketAddress,
    method: 'getBorrowRatePerSecond',
    abi: AbiRegistry.create(moneyMarketABI),
  });

  return response?.[0]?.toString() || '0';
};

export const getBorrowRatesPerSecond = async (
  moneyMarketAddresses: string[],
) => {
  return Promise.all(
    moneyMarketAddresses.map(async (marketAddress) => {
      const borrowRatePerSecond = await getBorrowRatePerSecond(marketAddress);

      return {
        borrowRatePerSecond,
        address: marketAddress,
      };
    }),
  );
};

export const getBorrowRatesPerSecondMap = async (
  moneyMarketAddresses: string[],
): Promise<Record<string, string>> => {
  const queryKey = ['blockchain:money-market:getBorrowRatesPerSecondMap'];

  const queryFn = async () => {
    const borrowRatesPerSecond =
      await getBorrowRatesPerSecond(moneyMarketAddresses);

    return borrowRatesPerSecond.reduce(
      (prev, current) => ({
        ...prev,
        [current.address]: current.borrowRatePerSecond,
      }),
      {} as Record<string, string>,
    );
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  }) as Promise<Record<string, string>>;
};

export const getAccountBorrowSnapshot = async (
  moneyMarketAddress: string,
  borrowerAddress: string,
): Promise<{
  borrowAmount: string;
  borrowIndex: string;
}> => {
  try {
    const response = await client({
      address: moneyMarketAddress,
      method: 'getAccountBorrowSnapshot',
      abi: AbiRegistry.create(moneyMarketABI),
      args: [new AddressValue(new Address(borrowerAddress))],
    });

    const fieldsByName = response?.[0].fieldsByName;

    return {
      borrowAmount: fieldsByName.get('borrow_amount').value.toString(),
      borrowIndex: fieldsByName.get('borrow_index').value.toString(),
    };
  } catch (error) {
    return {
      borrowAmount: '0',
      borrowIndex: '0',
    };
  }
};

export const getAccountBorrowSnapshots = async (
  moneyMarketAddresses: string[],
  borrowerAddress: string,
) => {
  return Promise.all(
    moneyMarketAddresses.map(async (marketAddress) => {
      const accountBorrowSnapshot = await getAccountBorrowSnapshot(
        marketAddress,
        borrowerAddress,
      );

      return {
        accountBorrowSnapshot,
        address: marketAddress,
      };
    }),
  );
};

export const getAccountBorrowSnapshotsMap = async (
  moneyMarketAddresses: string[],
  borrowerAddress: string,
) => {
  const accountBorrowSnapshots = await getAccountBorrowSnapshots(
    moneyMarketAddresses,
    borrowerAddress,
  );

  return accountBorrowSnapshots.reduce(
    (prev, current) => ({
      ...prev,
      [current.address]: current.accountBorrowSnapshot,
    }),
    {} as Record<
      string,
      {
        borrowAmount: string;
        borrowIndex: string;
      }
    >,
  );
};

export const getBorrowIndex = async (moneyMarketAddress: string) => {
  const response = await client({
    address: moneyMarketAddress,
    method: 'getBorrowIndex',
    abi: AbiRegistry.create(moneyMarketABI),
  });

  return response?.[0]?.toString() || '0';
};

export const getBorrowIndexList = async (moneyMarketAddresses: string[]) => {
  return Promise.all(
    moneyMarketAddresses.map(async (marketAddress) => {
      const borrowIndex = await getBorrowIndex(marketAddress);

      return {
        borrowIndex,
        address: marketAddress,
      };
    }),
  );
};

export const getBorrowIndexListMap = async (moneyMarketAddresses: string[]) => {
  const borrowIndexList = await getBorrowIndexList(moneyMarketAddresses);

  return borrowIndexList.reduce(
    (prev, current) => ({
      ...prev,
      [current.address]: current.borrowIndex,
    }),
    {} as Record<string, string>,
  );
};

export const getTotalSupplies = async (moneyMarketAddresses: string[]) => {
  return Promise.all(
    moneyMarketAddresses.map(async (marketAddress) => {
      const totalSupply = await getTotalSupply(marketAddress);

      return {
        totalSupply,
        address: marketAddress,
      };
    }),
  );
};

export const getTotalSuppliesMap = async (moneyMarketAddresses: string[]) => {
  const totalSupplies = await getTotalSupplies(moneyMarketAddresses);

  return totalSupplies.reduce(
    (prev, current) => ({
      ...prev,
      [current.address]: current.totalSupply,
    }),
    {} as Record<string, string>,
  );
};

export const getReserveFactor = async (moneyMarketAddress: string) => {
  const response = await client({
    address: moneyMarketAddress,
    method: 'getReserveFactor',
    abi: AbiRegistry.create(moneyMarketABI),
  });

  return response?.[0]?.toString() || '0';
};

export const getReserveFactors = async (moneyMarketAddresses: string[]) => {
  return Promise.all(
    Object.values(moneyMarketAddresses).map(async (address) => {
      const reserveFactor = await getReserveFactor(address);

      return {
        address,
        reserveFactor,
      };
    }),
  );
};

export const getReserveFactorsMap = async (moneyMarketAddresses: string[]) => {
  const reserveFactors = await getReserveFactors(moneyMarketAddresses);

  return reserveFactors.reduce(
    (prev, current) => ({
      ...prev,
      [current.address]: current.reserveFactor,
    }),
    {} as Record<string, string>,
  );
};

export const getLiquidationIncentive = async (moneyMarketAddress: string) => {
  const response = await client({
    address: moneyMarketAddress,
    method: 'getLiquidationIncentive',
    abi: AbiRegistry.create(moneyMarketABI),
  });

  return response?.[0]?.toString() || '0';
};

export const getLiquidationIncentives = async (marketsAddresses: string[]) => {
  return Promise.all(
    Object.values(marketsAddresses).map(async (address) => {
      const liquidationIncentive =
        await blockchainService.moneyMarket.getLiquidationIncentive(address);

      return {
        address,
        liquidationIncentive,
      };
    }),
  );
};
export const getLiquidationIncentivesMap = async (
  marketsAddresses: string[],
) => {
  const queryKey = ['blockchain:money-market:getLiquidationIncentivesMap'];

  const queryFn = async () => {
    const liquidationIncentives =
      await getLiquidationIncentives(marketsAddresses);

    return liquidationIncentives.reduce(
      (prev, current) => ({
        ...prev,
        [current.address]: current.liquidationIncentive,
      }),
      {} as Record<string, string>,
    );
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  }) as Promise<Record<string, string>>;
};
