export function CheckIcon() {
  return (
    <svg
      width='13'
      height='13'
      viewBox='0 0 13 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.10166 8.6236L4.18668 6.94302'
        stroke='#45A373'
        strokeWidth='1.41329'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 5.32227L6.10118 8.62429'
        stroke='#45A373'
        strokeWidth='1.41329'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.17199 1.15039H5.82857C3.24488 1.15039 1.15039 3.24488 1.15039 5.82857V7.17199C1.15039 9.75568 3.24488 11.8502 5.82857 11.8502H7.17199C9.75568 11.8502 11.8502 9.75568 11.8502 7.17199V5.82857C11.8502 3.24488 9.75568 1.15039 7.17199 1.15039Z'
        stroke='#45A373'
        strokeWidth='1.18886'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
