import React from 'react';

export const InputButton: React.FC<any> = ({ onClick, children, ...props }) => {
  return (
    <button
      onClick={onClick}
      className='whitespace-nowrap  px-2.5  py-[2.8px] lg:py-1.5  font-[600] font-hass text-[12px] lg:text-[14px] uppercase text-[#535367] transition-colors hover:bg-[#E0E1FF] hover:text-[#006FFF] dark:text-[#625E8F] dark:hover:bg-[#403C7C] dark:hover:text-[#006FFF] rounded-[7px] lg:rounded-[10px] '
      {...props}
    >
      {children}
    </button>
  );
};
