import classNames from 'classnames';
import { domAnimation, LazyMotion, m } from 'framer-motion';

import styles from './styles.module.css';

import CrossIcon from '@/components/Icons/CrossIcon';

export type CRUDButtonType = {
  label: string;
  action: 'add' | 'remove';
  isActive: boolean;
  onClick: () => void;
};

const CRUDButton = ({
  label = 'ADD',
  action = 'add',
  onClick,
  isActive,
  ...props
}: CRUDButtonType) => {
  const add = action === 'add';
  const remove = action === 'remove';
  return (
    <LazyMotion features={domAnimation}>
      <m.button
        className={classNames(
          'relative flex cursor-pointer items-center gap-1 rounded-[8px] px-1.5 py-[3px] text-sm font-semibold uppercase leading-tight tracking-wide text-white',
          'min-w-[58px]',
          styles.Btn,
          {
            'bg-[#4AAD7C]': add,
            'bg-[#E45059]': remove,
            [`bg-no-repeat ${styles.Active}`]: isActive,
            '[--color:#4AAD7C]': add && isActive,
            '[--color:#E45059]': remove && isActive,
          }
        )}
        onTap={onClick}
        {...props}
      >
        <div className={add ? 'rotate-45' : ''}>
          <CrossIcon></CrossIcon>
        </div>
        {label}
      </m.button>
    </LazyMotion>
  );
};

export default CRUDButton;
