import {
  FetchQueryOptions,
  QueryClient,
  QueryKey,
} from '@tanstack/react-query';

import { makePersistent } from '@/utils/persist';

export const HOUR_IN_MILISECONDS = 3.6e6;
export const MINUTE_IN_MILISECONDS = 60000;
export const SECOND_IN_MILISECONDS = 1000;
export const MAX_CACHE_TIME = MINUTE_IN_MILISECONDS * 5;
export const MID_CACHE_TIME = MINUTE_IN_MILISECONDS * 2.5;
export const MIN_CACHE_TIME = SECOND_IN_MILISECONDS * 10;

interface CustomOptions<T>
  extends FetchQueryOptions<unknown, unknown, unknown, QueryKey> {
  defaultValue: T;
}

class CustomQueryClient extends QueryClient {
  async safeFetchQuery<T>(options: CustomOptions<T>) {
    try {
      const value = await this.fetchQuery(options);

      // this.removeQueries({ queryKey: options.queryKey, exact: true });

      return value as typeof options.defaultValue;
    } catch (error) {
      return options.defaultValue;
    }
  }
}

const queryClient = new CustomQueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 0,
    },
  },
});

makePersistent(queryClient);

export { queryClient };
