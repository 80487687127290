import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { CSSProperties, FC, useEffect } from 'react';

import useIsomorphicLayoutEffect from '@/hooks/useIsomorphicLayoutEffect';
import useScrollBlock from '@/hooks/useScrollBlock';

import { GriffinAnimHardcoded } from '@/components/GriffinAnimHardcoded';

import { useAppDispatch, useAppSelector } from '@/store/index';
import { isLoadingSelector, setIsLoading } from '@/store/loader';

import { isProd } from '@/config/envVars';

function SafetyMessage() {
  const { t } = useTranslation();

  return (
    <div
      className={classNames('gradient-border', 'rounded-lg')}
      style={
        {
          ['--border-size']: '1px',
          ['--content-background']: '#fff0',
          ['--linear-gradient']:
            'linear-gradient(96.89deg, #364D9E 7.13%, #085463 55.39%)',
        } as unknown as CSSProperties
      }
    >
      <div
        className={classNames(
          'rounded-lg',
          'py-3 px-5',
          'flex justify-center gap-1.5',
          '[background-image:linear-gradient(92.24deg,#171944_-11.09%,#0A2B37_106.8%)]',
          'text-center text-xs tracking-wide text-[#BDE5FA]'
        )}
      >
        <span
          className='inline-block max-w-[272px]'
          dangerouslySetInnerHTML={{
            __html: t('wallet-provider.verification', {
              website_link: 'https://hatom.com',
            }).replace(
              'https://',
              `<span class="text-[#357B6C]">https://</span>`
            ),
          }}
        ></span>
      </div>
    </div>
  );
}

const Container: FC<any> = ({ children, className }) => {
  return (
    <div
      className={classNames(
        className,
        'flex flex-col items-center justify-end',
        'space-y-12 p-8 pt-12',
        'md:space-y-20 md:p-10 md:pt-20',
        'gradient-border',
        'rounded-[20px]'
      )}
      style={
        {
          maxWidth: 434,
          ['--border-size']: '1.5px',
          ['--content-background']: '#0C0B21',
          ['--linear-gradient']:
            'linear-gradient(138.77deg, #283771 -7.33%, #0C624D 108.85%)',
        } as unknown as CSSProperties
      }
    >
      {children}
    </div>
  );
};

const Loader = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isLoadingSelector);
  const [blockScroll, allowScroll] = useScrollBlock();

  useEffect(() => {
    const handleRouteChange = (url: string, { shallow }: { shallow: any }) => {
      if (url === window.location.pathname) {
        return false;
      }
      dispatch(setIsLoading(true));

      if (shallow) {
        setTimeout(() => {
          dispatch(setIsLoading(false));
        }, 500);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (isProd) {
      if (isLoading) {
        blockScroll();
      } else {
        setTimeout(() => {
          allowScroll();
        }, 100);
      }
    }
  }, [isLoading]);

  return isLoading ? (
    <div
      className={classNames(
        'Loader',
        'fixed inset-0 z-[999999] h-screen w-screen',
        'flex flex-col items-center justify-center',
        '[background:linear-gradient(147.93deg,#040F36_3.37%,#054536_156.74%)]'
      )}
    >
      <Container className='scale-90 sm:scale-100'>
        <div style={{ height: 140 }} className='sm:scale-90 md:scale-100'>
          <div className='origin-top scale-[1.12]'>
            <GriffinAnimHardcoded />
          </div>
        </div>
        <SafetyMessage />
      </Container>
    </div>
  ) : (
    <></>
  );
};

export default Loader;
