import { lowerCase } from 'lodash';
import { useTranslation } from 'next-i18next';

import { slugify } from '@/utils/helpers';

export function NetAPYTooltipContent({ parts = [] }: { parts: string[] }) {
  const { t } = useTranslation();

  const colors = {
    'liquid-staking': '#006FFF',
    borrow: '#8BD3C0',
    rewards: '#D6A4E9',
    supply: '#8D81D5',
  };

  return (
    <span className='text-[8px] md:text-[10px]'>
      {t('total-of-the', 'Total of the')}{' '}
      {parts.filter(Boolean).map((w, i, arr) => {
        const wHTML = (
          <span style={{ color: colors[slugify(w) as keyof typeof colors] }}>
            {t(slugify(w))}
          </span>
        );
        return i == 0 ? (
          wHTML
        ) : i !== arr.length - 1 ? (
          <>, {wHTML}</>
        ) : (
          <>
            {' '}
            {lowerCase(t('and'))} {wHTML}
          </>
        );
      })}{' '}
      APY.
    </span>
  );
}
