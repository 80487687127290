import classNames from 'classnames';
import { DetailedHTMLProps, HTMLAttributes } from 'react';

import ClosePopupBtn from '@/components/ClosePopupBtn';

interface PopupHeadProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  containerProps?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  closeBtn?: boolean;
}

const PopupHead = ({
  children,
  containerProps,
  closeBtn = true,
  ...props
}: PopupHeadProps) => {
  return (
    <div {...props}>
      <div
        {...containerProps}
        className={classNames(
          'relative h-[56px]',
          'flex items-center justify-center',
          'text-lg font-semibold leading-tight',
          'text-[#3C3A40] dark:text-white',
          containerProps?.className
        )}
      >
        {children}
        {closeBtn && (
          <div className='absolute right-5 top-1/2 z-10 -translate-y-1/3'>
            <ClosePopupBtn theme={{ light: '#3C3A40', dark: 'white' }} iconClassName='w-[11px]' />
          </div>
        )}
      </div>
      <div className={classNames('relative -mt-0.5 h-0.5')}></div>
    </div>
  );
};

export default PopupHead;
