import React from 'react';

const Minus = ({ className }: { className?: string }) => {
  return (
    <svg
      width='8'
      height='8'
      viewBox='0 0 8 8'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 4H6.79573'
        stroke='#EB1261'
        strokeWidth='1.32655'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Minus;
