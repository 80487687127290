import { captureException } from '@sentry/nextjs';
import { useTranslation } from 'next-i18next';
import toast from 'react-hot-toast';

import { useExtensionLogin } from '@/hooks/auth/providers/useExtensionLogin';
import { useLedgerLogin } from '@/hooks/auth/providers/useLedgerLogin';
import {
  InitWalletConnectV2Type,
  useWalletConnectV2Login,
} from '@/hooks/auth/providers/useWalletConnectV2Login';
import { useWebWalletLogin } from '@/hooks/auth/providers/useWebWalletLogin';
import { useLoggingIn } from '@/hooks/auth/useLoggingIn';

import { LoginMethods } from '@/config/walletProviders';

import { Login } from '@/types/account';
import { LoginMethodsEnum } from '@/types/enums';

export const useLogin = (params?: Login) => {
  const { t } = useTranslation();

  const { isLoggedIn, isLoggingIn, error } = useLoggingIn();

  const { login: webLogin } = useWebWalletLogin(params);
  const { login: walletConnectLogin } = useWalletConnectV2Login(
    params as InitWalletConnectV2Type
  );
  const { login: extensionLogin } = useExtensionLogin(params);
  const { login: ledgerLogin } = useLedgerLogin();

  const login = async (type: LoginMethodsEnum) => {
    try {
      if (type === LoginMethods.extension) {
        await extensionLogin();
      }
      if (type === LoginMethods.webwallet) {
        await webLogin();
      }
      if (type === LoginMethods.ledger) {
        ledgerLogin();
      }
      if (type === LoginMethods.maiar) {
        walletConnectLogin();
      }
    } catch (e) {
      captureException(e);
      toast.error(t('login-error'), { duration: 4000 });
    }

    return null;
  };

  return {
    login,
    isLoggedIn,
    isLoggingIn,
    error,
  };
};
