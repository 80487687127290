import classNames from 'classnames';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';

import { formatNumber } from '@/utils/helpers';

import { MaxButton } from './MaxButton';
import { Percent80Limit } from './Percent80Limit';

export const AmountInput: React.FC<any> = ({
  prefix,
  suffix,
  decimalsLimit = 18,
  inputValue = 0,
  estimation = 0,
  // eslint-disable-next-line unused-imports/no-unused-vars
  onChange = (n: number) => {},
  onMax = () => {},
  hasHundredPercentButton = true,
}) => {
  prefix = prefix ?? (
    <div>
      <img
        className='h-[24px] w-[24px] rounded-full'
        src='https://cdn.app.hatom.com/images/img1.svg'
        alt='HTM'
      />
    </div>
  );

  return (
    <div>
      <div className='relative'>
        <div className='absolute left-2.5 top-1/2 z-10 -translate-y-1/2'>
          {prefix}
        </div>
        <div>
          <CurrencyInput
            inputMode='text'
            autoComplete='off'
            className={classNames(
              'px-[44px] pr-20',
              'h-[72px] w-full rounded-[20px] border-[2px] border-[#DCDCE3] bg-[#F9F9FE] placeholder:text-[#5C5C71] dark:border-[#837ED3] dark:bg-[#292651] dark:placeholder:text-white/70',
              'font-hass font-semibold leading-6',
              'focus:outline-none',
              'placeholder:text-current',
              'theme-switch-transition'
            )}
            id='liquid-input'
            name='liquid-input'
            data-testid='liquidInput'
            maxLength={50}
            onValueChange={onChange}
            placeholder='0'
            max={10}
            decimalsLimit={decimalsLimit}
            allowNegativeValue={false}
            groupSeparator=','
            decimalSeparator='.'
            // this code snippet displays placeholder when input is empty
            value={inputValue}
            defaultValue={0}
          />
          <div className='absolute bottom-3 left-[52px] text-[12px] text-[#535367] dark:text-[#625E8F]'>
            ≈ ${formatNumber(estimation.toLocaleString('en-US'), 2)}
          </div>
        </div>
        <div className='absolute right-[18px] top-1/2 z-10 -translate-y-1/2'>
          {!suffix && hasHundredPercentButton && <MaxButton onMax={onMax} />}
          {!suffix && !hasHundredPercentButton && (
            <Percent80Limit onClick={onMax} />
          )}
        </div>
      </div>
    </div>
  );
};
