import {
  AddressDetails,
  AddressEsdt,
  AddressGuardianData,
  AddressNonce,
} from '@/services/gateway/address/types';
import client from '@/services/gateway/client';

export const getAddressNonce = async (
  accountAddress: string,
): Promise<AddressNonce> => {
  const { data } = await client.get(`/address/${accountAddress}/nonce`);

  return data.data;
};

export const getAddressDetails = async (
  accountAddress: string,
): Promise<AddressDetails> => {
  const { data } = await client.get(`/address/${accountAddress}`);

  return data.data;
};

export const getAddressGuardianData = async (
  accountAddress: string,
): Promise<AddressGuardianData> => {
  const { data } = await client.get(`/address/${accountAddress}/guardian-data`);

  return data.data;
};

export const getAddressEsdt = async (
  accountAddress: string,
): Promise<AddressEsdt> => {
  const { data } = await client.get(`/address/${accountAddress}/esdt`);

  return data.data;
};
