import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import Skeleton from 'react-loading-skeleton';

import { useWalletConnectV2Login } from '@/hooks/auth/providers/useWalletConnectV2Login';
import useIsAgora from '@/hooks/useIsAgora';
import useTheme from '@/hooks/useTheme';

import PopupBed from '@/components/popups/PopupBed';
import PopupHead from '@/components/popups/PopupBed/components/PopupHead';

import { chainType, networkConfig } from '@/config/network';

const MaiarLoginPopup = () => {
  const { t } = useTranslation();
  const { isLight } = useTheme();
  const agora = useIsAgora();
  const { initiateLogin, walletConnectUri } = useWalletConnectV2Login();
  const codeContainer = useRef<HTMLDivElement>(null);

  const buildQrCode = async () => {
    if (walletConnectUri && codeContainer.current) {
      const QRCodeStyling = (await import('qr-code-styling')).default;
      
      const qrCode = new QRCodeStyling({
        width: 200,
        height: 200,
        type: 'svg',
        margin: 0,
        data: walletConnectUri,
        dotsOptions: {
          color: isLight ? 'black' : 'white',
        },
        backgroundOptions: {
          color: 'transparent',
        },
      });

      codeContainer.current.innerHTML = '';
      qrCode.append(codeContainer.current);
    }
  };

  useEffect(
    () => {
      initiateLogin();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      buildQrCode();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [walletConnectUri]
  );

  return (
    <PopupBed close={false} width={439}>
      <div className='font-semibold'>
        <PopupHead>
          <div className='flex items-center whitespace-nowrap'>
            {t('login-with', { provider: ' ' })}
            <img
              className='mb-2 ml-1 mr-0.5 h-3'
              src='https://cdn.app.hatom.com/images/x.svg'
              alt='X Portal'
            />
            <div>Portal</div>
          </div>
        </PopupHead>

        <div className='p-5 text-center'>
          {walletConnectUri === '' ? (
            <div className='mx-auto mb-3'>
              <Skeleton width={196} height={196} />
            </div>
          ) : (
            <div ref={codeContainer} className='flex-xy-center mb-3' />
          )}

          <h3 className='mb-2 flex items-center justify-center text-xl dark:text-white'>
            <img
              className='mb-2 mr-0.5 h-3'
              src='https://cdn.app.hatom.com/images/x.svg'
              alt='X Portal'
            />
            {t('maiar-login')}
          </h3>
          <div
            className={classNames(
              'flex items-center justify-center',
              'text-sm',
              agora && isLight && 'text-[#393646]'
            )}
          >
            {t('scan-qrcode-using', { provider: '' })}
            <img
              className='mb-2 ml-1 mr-0.5 h-2'
              src='https://cdn.app.hatom.com/images/x.svg'
              alt='X Portal'
            />
            Portal
          </div>

          {isMobile && (
            <a
              className='mt-2 inline-flex items-center gap-1.5 rounded-md bg-[#1f43f6] px-3 py-2 text-sm text-[#fff]'
              href={`${
                networkConfig[chainType].walletConnectDeepLink
              }?wallet-connect=${encodeURIComponent(walletConnectUri)}`}
              rel='noopener noreferrer nofollow'
              target='_blank'
            >
              <img
                className='h-2 brightness-0 invert'
                src='https://cdn.app.hatom.com/images/x.svg'
                alt=''
              />
              {t('maiar-login')}
            </a>
          )}
        </div>
      </div>
    </PopupBed>
  );
};

export default MaiarLoginPopup;
