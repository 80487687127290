import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useState } from 'react';

import useSignMultipleTransactions, {
  ALLOWED_TRANSACTIONS,
} from '@/hooks/core/useSignMultipleTransactions';
import {
  ONCE,
  TRANSITION_VARIANTS,
} from '@/hooks/framerMotion/useSetVariantOnLoadingEnd';
import useLendInteraction from '@/hooks/interaction/useLendInteraction';
import { useCollapse } from '@/hooks/layout/useCollapse';
import useMediaQuery from '@/hooks/useMediaQuery';
import useTheme from '@/hooks/useTheme';

import Hint from '@/components/Hint';
import WrappedLottiePlayer from '@/components/WrappedLottiePlayer';

import { boosterSelector } from '@/store/booster';
import { useAppSelector } from '@/store/index';
import { getRouteGroup } from '@/store/popup';
import {
  hasEnoughEGLDBalanceSelector,
  htmMarketSelector,
} from '@/store/protocol';
import {
  hasPendingTransactionsSelector,
  TRANSACTION_SUBGROUP_TYPE,
} from '@/store/transaction';

import ClaimButton from '@/sections/Lend/MyPosition/components/Claims&Requests/components/ClaimButton';
import ClaimRow from '@/sections/Lend/MyPosition/components/Claims&Requests/components/ClaimRow';
import TabButton from '@/sections/Lend/MyPosition/components/Claims&Requests/components/TabButton';
import { getDateWithTime } from '@/sections/Lend/MyPosition/helpers';
import { useHintProps } from '@/sections/Liquid/App/global';
import clsxm from '@/services/clsxm';
import { formatNumber } from '@/utils/helpers';

const Tab: React.FC<any> = ({ active, children, onClick, className }) => {
  const md = useMediaQuery('(min-width: 768px)');
  return (
    <div className={classNames(className)}>
      <button
        onClick={onClick}
        className={classNames(
          'inline-block whitespace-nowrap rounded-t-2xl border border-b-0 px-[14px] py-[7px] text-[10px] leading-6 md:text-[10px] lg:px-[23.5px] lg:py-[8px] lg:text-[14px]',
          !md && 'text-[12px]',
          !active
            ? 'border-transparent text-[#535367] dark:text-[#7F73AB]'
            : 'border-[#DCDCE3] bg-[#F9F9FE] text-[#1F2226] dark:border-[#6B5E9C] dark:bg-[#191733]  dark:text-white',
          'md:px-[14px] md:py-[3px] md:rounded-t-[12px]',
        )}
      >
        {children}
      </button>
    </div>
  );
};

const Button: React.FC<any> = ({ className, children, small, ...props }) => {
  return (
    <button
      className={classNames(
        className,
        {
          btnHover: !small,
        },
        'rounded-[8px] text-[12px] capitalize leading-tight',
        small
          ? 'px-2 py-1 text-[8px] hover:opacity-90 md:min-w-[57px] lg:px-3 lg:text-[12px]'
          : 'px-1 py-1 text-[8px] md:max-w-[83px] lg:px-4 lg:text-[14px]',
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export const TimerAnimation: React.FC<any> = () => {
  return (
    <WrappedLottiePlayer className='w-[12px]' src='https://cdn.app.hatom.com/animations/time.json' />
  );
};

const HintableCellBare: React.FC<any> = ({ children, content }) => {
  const defaultHintProps = useHintProps();

  return (
    <Hint
      {...defaultHintProps}
      content={content}
      layerStyle={{ textAlign: 'center', minWidth: 30 }}
    >
      {children}
    </Hint>
  );
};

const HintableCell = React.memo(HintableCellBare);

const ClaimsAndRequests: React.FC<any> = ({ isLiquid = false }) => {
  const { t } = useTranslation('liquid-app');
  const lg = useMediaQuery('(min-width: 1024px)');
  const md = useMediaQuery('(min-width: 768px)');
  const [tab, setTab] = useState(0);
  const { claims } = useAppSelector(boosterSelector);
  const router = useRouter();

  const { signTransactions, isAllowedTransaction, isWhiteListed } =
    useSignMultipleTransactions();
  const hasEnoughEGLDBalance = useAppSelector(hasEnoughEGLDBalanceSelector);
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const { claimBooster } = useLendInteraction();

  const htmMarket = useAppSelector(htmMarketSelector);
  const { isDark } = useTheme();

  const getHtmValue = (htmTokens: string) => {
    return formatNumber(
      new DefiUtils(htmTokens)
        .toFullDecimals(htmMarket.underlying.decimals)
        .toSafeString(),
      2,
    );
  };

  const todayDate = Date.now();

  const activeClaims = useMemo(
    () =>
      claims?.length
        ? claims.filter(
            ({ claimTimestamp }) =>
              new Date(claimTimestamp).getTime() < todayDate,
          ) ?? []
        : [],
    [claims, todayDate],
  );

  const unstakeClaims = useMemo(
    () =>
      claims?.length
        ? claims?.filter(
            ({ claimTimestamp }) =>
              new Date(claimTimestamp).getTime() > todayDate,
          ) ?? []
        : [],
    [claims, todayDate],
  );

  const handleClaim = async (claimId: string) => {
    const claimIdNumber = Number(claimId.split('-')[1]);
    const claim = claims.find((c) => c.id === claimId);

    const htmValue = getHtmValue(claim?.amount ?? '');

    await signTransactions([claimBooster(claimIdNumber)], {
      group: getRouteGroup(),
      subgroup: TRANSACTION_SUBGROUP_TYPE.CLAIM_HTM,
      token: 'HTM',
      result: htmValue,
    });
  };

  const handleAllClaim = async (claims: number[]) => {
    const summatory = activeClaims
      .reduce((prev, { amount }) => prev.plus(amount), new DefiUtils(0))
      .toString();
    const htmValue = getHtmValue(summatory);

    await signTransactions(
      claims.map((id) => claimBooster(id)),
      {
        group: getRouteGroup(),
        subgroup: TRANSACTION_SUBGROUP_TYPE.CLAIM_HTM,
        token: 'HTM',
        result: htmValue,
      },
    );
  };

  const activeTab = useMemo(() => {
    return !activeClaims?.length && unstakeClaims?.length ? 1 : 0;
  }, [activeClaims, unstakeClaims]);

  const panels = useMemo(
    () => [
      {
        caption: t('active-claims', 'Active Claims'),
        th: [t('unstaked-tokens'), t('Estimated Value in USD'), t('')],
        data: activeClaims,
        actions: ({
          all = false,
          key = '',
          id = '',
          lg = false,
          valueHTM = '0',
        }) => {
          const warning = hasPendingTransactions
            ? t('translation:wait-tx-inprogress')
            : !hasEnoughEGLDBalance
            ? t('translation:not-enough-balance')
            : !isAllowedTransaction(ALLOWED_TRANSACTIONS.LIQUID_APP_CLAIM)
            ? t('translation:tx-not-allowed')
            : !isWhiteListed()
            ? t('translation:tx-not-enable-to-sign')
            : false;

          const className = classNames({
            'max-w-[180px]': hasPendingTransactions,
            'max-w-[140px] sm:max-w-[160px]': !hasEnoughEGLDBalance,
          });

          return [
            <Hint
              key={key}
              placement='left-center'
              hidden={!warning}
              content={<div className={className}>{warning}</div>}
            >
              <Button
                className={classNames(
                  'flex items-center justify-center bg-[#4AAD7C] text-white',
                  all
                    ? 'h-[26px] md:!min-w-[57px] lg:min-w-auto text-[10px] lg:text-[14px]'
                    : 'h-[23px] !max-w-[57px]',
                )}
                small={!all}
                onClick={() => {
                  if (warning) return;

                  if (all) {
                    handleAllClaim(
                      claims.map(({ id }) => Number(id.split('-')[1])),
                    );
                  } else {
                    handleClaim(id);
                  }
                }}
              >
                <span className='capitalize'>
                  {all
                    ? t('translation:claim-all', 'Claim all')
                    : t('translation:claim', 'Claim')}
                </span>
              </Button>
            </Hint>,
          ];
        },
      },
      {
        caption: t('unstake-requests', 'Unstake Requests'),
        th: [t('unstaked-tokens'), t('Estimated value'), t('')],
        data: unstakeClaims,
        actions: ({ all = false, key = '', lg = '', id = '' }) => [],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasEnoughEGLDBalance, hasPendingTransactions, t],
  );

  const getAnimationClassNames = (visible = true) => {
    return md
      ? classNames(
          'transition-all ease-in-out',
          visible
            ? 'relative active delay-100 duration-700 opacity-100 scale-100'
            : 'duration-300 opacity-0 scale-[0.98]',
        )
      : 'transition-all ease-in-out max-h-[0] overflow-hidden';
  };

  const [p1Ref, , p1Toggle] = useCollapse<HTMLDivElement>({
    defaultValue: true,
    callback: () => {},
  });

  const [p2Ref, , p2Toggle] = useCollapse({
    defaultValue: false,
    callback: () => {},
  });

  const handleTabClick = (tabI: number) => {
    if (tabI == tab) return;
    setTab(tabI);
    p1Toggle();
    p2Toggle();
  };

  useEffect(() => {
    if (!tab) p1Toggle(true);
    else p2Toggle(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panels, md]);

  useEffect(() => {
    handleTabClick(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const getValueInUsd = (htmTokens: string) => {
    const htmPrice = htmMarket.underlying.priceUSD;

    const valInUsd = new DefiUtils(htmTokens)
      .toFullDecimals(htmMarket.underlying.decimals)
      .multipliedBy(htmPrice)
      .toString();

    return formatNumber(valInUsd, 2);
  };

  const data = tab === 0 ? activeClaims : unstakeClaims;

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        initial='hidden'
        custom={{ onlyY: true, movePx: 12 }}
        variants={TRANSITION_VARIANTS}
        whileInView='visible'
        viewport={{ once: ONCE, amount: 0.3 }}
        className='relative'
      >
        <div
          className={classNames(
            'flex flex-col gap-[4px] lg:gap-[8px]',
            'bg-[#F6F1FC] pl-[6.5px] py-[7px] md:pl-[10.5px] md:py-[11px] font-hass font-semibold dark:bg-[#463B70]',
            'rounded-[12px] border-[1.6px] border-[#D3D6EE] dark:border-[#6B5E9C]',
            'md:max-h-fit',
            'lg:min-w-[655px]',
            data.length >= 2 && 'min-h-[130px] lg:min-h-[159px]',
          )}
        >
          <div className='w-full pr-[6.5px] md:pr-[10.5px]'>
            <div className='flex items-center justify-between border-b border-[#D3D6EE] dark:border-[#6B5E9C]'>
              <div className='flex gap-[2px] md:gap-[4px] lg:gap-[23px]'>
                <TabButton
                  label={t('active-claims', 'Active Claims')}
                  active={tab === 0}
                  onClick={() => handleTabClick(0)}
                />
                <TabButton
                  label={t('unstake-requests', 'Unstake Requests')}
                  active={tab === 1}
                  onClick={() => handleTabClick(1)}
                />
              </div>
              {data.length >= 2 && tab === 0 && (
                <ClaimButton
                  label='Claim All'
                  onClick={() => {
                    if (!data) {
                      return;
                    }

                    const ids = data.map(({ id }) =>
                      parseInt(id.split('-')[1]),
                    );
                    const totalAmount = data
                      .reduce(
                        (prev, { amount }) => prev.plus(amount),
                        new DefiUtils(0),
                      )
                      .toFullDecimals(htmMarket.underlying.decimals)
                      .toString();

                    signTransactions(
                      ids.map((id) => claimBooster(id)),
                      {
                        group: getRouteGroup(),
                        subgroup: TRANSACTION_SUBGROUP_TYPE.CLAIM_HTM,
                        token: 'HTM',
                        result: totalAmount,
                      },
                    );
                  }}
                />
              )}
            </div>
          </div>
          {data.length <= 0 ? (
            <m.div
              className={clsxm(
                'flex items-center justify-center w-full h-[60px] md:h-[50px]',
              )}
            >
              <span className='text-center text-[12px] text-[#2F2F2F] dark:text-white'>
                {tab == 0
                  ? 'You Have No Active Claims'
                  : 'You Have No Unstake Requests'}
              </span>
            </m.div>
          ) : (
            <m.div
              className={clsxm(
                'flex flex-col gap-[3px] max-h-[75px] lg:max-h-[60px] md:gap-[1px] w-full ',
              )}
            >
              <div className='w-full pr-[6.5px] md:pr-[10.5px]'>
                <ClaimRow
                  col1={t('unstaked-tokens', 'Unstaked Tokens')}
                  col2='Estimated Value in USD'
                  claim={false}
                  className='text-[#6A6A8C] dark:text-[#7f73ab]'
                />
              </div>
              <div
                className={clsxm(
                  'relative max-h-[58px] lg:max-h-[62px] w-[99.4%]',
                )}
              >
                <div
                  className={clsxm(
                    'absolute -top-px left-0',
                    'w-full h-1 z-10',
                    isDark
                      ? 'bg-gradient-to-b from-[#463B70] from-20% to-95% to-transparent'
                      : 'bg-gradient-to-b from-[#F6F1FC] from-30% to-90% to-transparent',
                  )}
                />
                <div
                  className={clsxm(
                    'absolute -bottom-px left-0',
                    'w-full h-1 z-10',
                    isDark
                      ? 'bg-gradient-to-t from-[#463B70] from-20% to-95% to-transparent'
                      : 'bg-gradient-to-t from-[#F6F1FC] from-30% to-90% to-transparent',
                  )}
                />
                <div
                  className={clsxm(
                    'flex flex-col gap-[7px] lg:gap-[11px] max-h-[58px] lg:max-h-[62px] pt-0.5 pr-[2.5px] md:pr-[6.5px]',
                    data.length >= 2 &&
                      'overflow-hidden scroll-container1 min-h-[58px] lg:min-h-[62px]',
                  )}
                >
                  {data &&
                    data?.map(({ id, amount, claimTimestamp }) => (
                      <ClaimRow
                        col1={`${getHtmValue(amount)} HTM`}
                        col2={`${getValueInUsd(amount)} USD`}
                        timestamp={getDateWithTime(claimTimestamp, true)}
                        claim
                        tab={tab}
                        amount={new DefiUtils(amount)
                          .toFullDecimals(htmMarket.underlying.decimals)
                          .toString()}
                        img
                        ids={[parseInt(id.split('-')[1])]}
                        className='text-[#2F2F2F] dark:text-white'
                      />
                    ))}
                </div>
              </div>
            </m.div>
          )}
        </div>
      </m.div>
    </LazyMotion>
  );
};

export default ClaimsAndRequests;
