import { useEffect, useState } from 'react';
import { isIOS, isMobile, isSafari, isTablet } from 'react-device-detect';

import useEvent from '@/hooks/useEvent';

const useCanAnimate = () => {
  const canAnimate = () => {
    return (
      typeof window !== 'undefined' &&
      window.innerWidth > 1024 &&
      !isSafari &&
      !isIOS &&
      !isTablet &&
      !isMobile
    );
  };
  const [can, changePermission] = useState<boolean>();

  useEvent('resize', undefined, () => {
    changePermission(() => canAnimate());
  });

  useEffect(() => {
    changePermission(() => canAnimate());
  }, []);

  return can;
};

export default useCanAnimate;
