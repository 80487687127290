import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import {
  formatMarkets,
  getBorrowLimitUSD,
  getBorrowLimitUsedPercent,
  getBorrowTotalBalanceUSD,
  getCollateralBalanceUSD,
  getHTokenByProvider,
  getSupplyTotalBalanceUSD,
  getUnderlyingBalance,
} from '@/store/parsers/lend-app-parser';
import {
  LISTED_MARKETS,
  MARKET_KEY,
  nativeMarketSelector,
} from '@/store/protocol';
import { addAction } from '@/store/queue';

import logger from '@/utils/logger';

import { LogoSet } from '@/types/token';

export interface AccountMarket {
  netAPY: string;
  supplyBalanceUSD: string;
  collateralBalanceUSD: string;
  borrowBalanceUSD: string;
  borrowLimitUSD: string;
  borrowLimitUSDWithoutSimulate: string;
  borrowLimitUsedPercent: string;
}

const defaultMarket: Market = {
  assetKey: '',
  order: 99,
  address: '',
  name: '',
  logo: {
    normal: '',
    nightMode: '',
    hLogo: '',
    alt: '',
    altDelta: '',
  },
  underlying: {
    id: '',
    symbol: '',
    decimals: 0,
    priceUSD: '',
  },
  hToken: {
    id: '',
    symbol: '',
    decimals: 0,
    priceUSD: '',
  },
  accountBalances: {
    hTokenWallet: '0',
    underlyingWallet: '0',
    underlyingHTokenWallet: '0',
    collateral: '0',
    underlyingCollateral: '0',
    underlyingCollateralWithoutSimulate: '0',
    borrow: '0',
  },
  supported: false,
  hTokenExchangeRate: '0',
  collateralFactor: '0',
  supplyAPY: '0',
  borrowAPY: '0',
  totalBorrow: '0',
  cash: '0',
  totalSupplyUSD: '0',
  totalSupply: '0',
  supplyCap: '0',
  borrowCap: '0',
  mintEnabled: true,
  borrowEnabled: true,
};

export interface Market {
  assetKey: string;
  order: number;
  address: string;
  name: string;
  logo: LogoSet;
  underlying: {
    id: string;
    symbol: string;
    decimals: number;
    priceUSD: string;
  };
  hToken: {
    id: string;
    symbol: string;
    decimals: number;
    priceUSD: string;
  };
  accountBalances: {
    hTokenWallet: string; // htoken wallet balance
    underlyingWallet: string; // underlying wallet balance
    underlyingHTokenWallet: string; // underlying (htoken wallet) balance
    collateral: string; // h token collateral balance
    underlyingCollateral: string; // collateral in a market as underlying
    underlyingCollateralWithoutSimulate: string; // collateral in a market as underlying
    borrow: string; // underlying borrow balance
  };
  supported: boolean;
  hTokenExchangeRate: string;
  supplyAPY: string;
  borrowAPY: string;
  collateralFactor: string;
  totalBorrow: string;
  totalSupplyUSD: string;
  totalSupply: string;
  cash: string;
  borrowCap: string;
  supplyCap: string;
  mintEnabled: boolean;
  borrowEnabled: boolean;
}

export interface LendAppState {
  account: AccountMarket;
  markets: Record<string, Market>;
  showLiquidStakingAPY: boolean;
  showLiquidStakingTaoAPY: boolean;
}

const initialState: LendAppState = {
  account: {
    netAPY: '0',
    supplyBalanceUSD: '0',
    collateralBalanceUSD: '0',
    borrowBalanceUSD: '0',
    borrowLimitUSD: '0',
    borrowLimitUSDWithoutSimulate: '0',
    borrowLimitUsedPercent: '0',
  },
  markets: LISTED_MARKETS.reduce(
    (prev, key) => ({
      ...prev,
      [key]: {
        ...defaultMarket,
        assetKey: key,
      } as Market,
    }),
    {} as Record<MARKET_KEY, Market>,
  ),
  showLiquidStakingAPY: false,
  showLiquidStakingTaoAPY: false,
};

export const lendAppSlice = createSlice({
  name: 'lendApp',
  initialState,
  reducers: {
    setLendApp: (state, action: PayloadAction<Partial<LendAppState>>) => {
      Object.entries(action.payload).map(([key, value]) => {
        state[key as keyof LendAppState] = value;
      });
    },

    toggleShowLiquidStakingAPY: (state) => {
      state.showLiquidStakingAPY = !state.showLiquidStakingAPY;
    },

    toggleShowLiquidStakingTaoAPY: (state) => {
      state.showLiquidStakingTaoAPY = !state.showLiquidStakingTaoAPY;
    },

    updateAccountMarketNetAPY: (state, action: PayloadAction<string>) => {
      state.account.netAPY = action.payload;
    },

    updateAccountMarket: (state) => {
      const borrowBalanceUSD = getBorrowTotalBalanceUSD(state);
      const borrowLimitUSD = getBorrowLimitUSD(state);
      const borrowLimitUSDWithoutSimulate = getBorrowLimitUSD(state, false);
      const supplyBalanceUSD = getSupplyTotalBalanceUSD(state);
      const collateralBalanceUSD = getCollateralBalanceUSD(state);
      const borrowLimitUsedPercent = getBorrowLimitUsedPercent(
        borrowBalanceUSD,
        borrowLimitUSD,
      );

      const accountUpdated = {
        supplyBalanceUSD,
        collateralBalanceUSD,
        borrowBalanceUSD,
        borrowLimitUSD,
        borrowLimitUSDWithoutSimulate,
        borrowLimitUsedPercent,
      };

      const account = {
        ...state.account,
        ...accountUpdated,
      };

      state.account = account;
    },
  },
});

export const {
  setLendApp,
  toggleShowLiquidStakingAPY,
  toggleShowLiquidStakingTaoAPY,
  updateAccountMarket,
  updateAccountMarketNetAPY,
} = lendAppSlice.actions;

export const getLendAppMarkets =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();
      const { markets: protocolMarkets, userBalances } = state.protocol;

      const { tokens, balance, address: accountAddress } = state.auth.account;
      const nativeMarket = nativeMarketSelector(state);

      const [hTokenBalances] = await Promise.all([
        getHTokenByProvider(
          'multiversx',
          accountAddress,
          tokens,
          protocolMarkets,
        ),
      ]);

      const underlyingBalances = getUnderlyingBalance(
        tokens,
        Object.values(protocolMarkets),
      );

      const markets = formatMarkets(
        protocolMarkets,
        userBalances,
        hTokenBalances,
        underlyingBalances,
      );

      await dispatch(addAction(setLendApp({ markets })));
    } catch (error) {
      logger.error('store:getLendAppInfo', error);
      captureException(error);
    }
  };

export const lendAppSelector = (state: RootState) => state.lendApp;

export default lendAppSlice.reducer;
