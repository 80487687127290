const CircleXmarkIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <>
      <svg
        width={12}
        height={12}
        viewBox='0 0 12 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <rect width={12} height={12} rx={6} fill='#C13A64' />
        <path
          d='M3.5 8.5L8.5 3.5'
          stroke='white'
          strokeWidth='1.66667'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.5 3.5L8.5 8.5'
          stroke='white'
          strokeWidth='1.66667'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </>
  );
};

export default CircleXmarkIcon;
