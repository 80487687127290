import { motion } from 'framer-motion';

import useMediaQuery from '@/hooks/useMediaQuery';

import BridgeWalletManuelConnectShape from "@/components/popups/ConnectBridgeWalletPopup/components/shapes/BridgeWalletManuelConnectShape";
import { textClassName } from '@/components/popups/ConnectBridgeWalletPopup/components/wallets/constants';

import clsxm from '@/services/clsxm';

const ConfirmButton = ({ onClick }: { onClick: () => void }) => {
  return (
    <button
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
      className='flex items-center h-full w-[49px] lg:w-[80px] pl-[4px] lg:px-[8px] rounded-[4px] lg:rounded-[6px] bg-[#9F8FDF] text-[#8F8FB2]'
    >
      <img
        src="https://cdn.app.hatom.com/images/pages/bridge/confirmIcon.svg"
        alt='confirm icon'
        className="w-[8px] lg:w-[14px] mr-[2.6px] lg:mr-[4px]"
      />
      <p className='text-[8px] lg:text-[12px] leading-[12.5px] lg:leading-[15px] text-black'>Confirm</p>
    </button>
  )
}
const WarningIcon = () => (<img
  src="https://cdn.app.hatom.com/images/pages/bridge/triangleDanger.svg"
  alt='triangle icon'
  className=" w-[10.4px] lg:w-[13.7px] mr-[5.6px] lg:mr-[8px]"
/>
)
const TouchIcon = ({ expanded }: { expanded: boolean }) => {
  const lg = useMediaQuery('(min-width: 1024px )');
  return (
    <motion.svg
      // animate={{
      //   opacity: expanded ? 0 : 1
      // }}
      className='w-[17px] lg:w-[24px] translate-y-[-1.4px] lg:translate-y-[-2px]' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      {/* hand gesture */}
      <motion.path
        // animate={{
        //   scale: [1, 1.2]
        // }}
        // transition={{
        //   duration: 1,
        //   repeat: Infinity
        // }}
        d="M14.2318 9.15675C14.8807 10.9082 14.2423 13.2257 13.1053 14.3626C11.6546 15.8133 7.60045 15.9236 6.27317 14.273C5.43089 13.2257 4.77625 11.9545 4.3636 10.8155C4.14228 10.2046 4.46704 9.54583 5.07367 9.31317C5.61066 9.10715 6.21885 9.29566 6.54525 9.76922L7.36031 10.9517V4.41663C7.36031 3.78742 7.87038 3.27734 8.49962 3.27734C9.122 3.27734 9.62916 3.77682 9.63878 4.39914L9.684 7.34662C11.2784 7.49888 13.5871 7.41661 14.2318 9.15675Z"
        stroke="white"
        strokeWidth="1.07678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      {/* right bracket */}
      <motion.path
        // initial={{
        //   opacity: 0
        // }}
        // animate={{
        //   opacity: [1, 0]
        // }}
        // transition={{
        //   duration: 1,
        //   delay: 0.1,
        //   repeat: Infinity
        // }}
        d="M4.55265 6.04855C4.32709 5.59261 4.2002 5.07926 4.2002 4.53599C4.2002 3.99272 4.32709 3.47937 4.55265 3.02344"
        stroke="white"
        strokeWidth="1.07678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      {/* left bracket */}
      <motion.path
        // initial={{
        //   opacity: 0
        // }}
        // animate={{
        //   opacity: [1, 0]
        // }}
        // transition={{
        //   duration: 1,
        //   delay: 0.1,
        //   repeat: Infinity
        // }}
        d="M12.3811 6.04855C12.6067 5.59261 12.7336 5.07926 12.7336 4.53599C12.7336 3.99272 12.6067 3.47937 12.3811 3.02344"
        stroke="white"
        strokeWidth="1.07678"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </motion.svg>
  )
}
const ManuelConnectWallet = ({ expanded, onManuelConfirmConnectClick, onManuelConnectClick }: { expanded: boolean, onManuelConfirmConnectClick: () => void, onManuelConnectClick: () => void }) => {

  return (
    <div className='relative'>
      <BridgeWalletManuelConnectShape
        onClick={onManuelConnectClick as (() => void)}
        expanded={expanded}
      />
      <div
        onClick={() => onManuelConnectClick()}
        className='flex justify-end'
      >
        <div className='relative z-10 flex flex-col items-end pt-[39.5px] lg:pt-[55px] pr-[12.1px] lg:pr-[19.1px] '>
          <div className='flex items-center mb-[3.3px] lg:mb-[6.3px]'>
            <TouchIcon expanded={expanded} />
            <p className={clsxm(textClassName, ' text-white')}>Manual Connect</p>
          </div>
          {expanded && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.2,
                ease: "easeIn"
              }}
            >
              <div className='h-[18px] lg:h-[29px] flex items-center mb-[3.2px] lg:mb-[8px] lg:translate-x-[0.3px] '>
                <input
                  onClick={(e) => e.stopPropagation()}
                  value="5H9QSJQ9H...JH8HSFK"
                  className={clsxm(
                    'h-full w-[102px] lg:w-[157px] text-[8px] lg:text-[12px] leading-[15px] mr-[4px] lg:mr-[8px] px-[3.5px] lg:px-[7.3px] border rounded-[4px] lg:rounded-[6px]',
                    'border-[#7171A9]  bg-[#0F0F22] text-[#8F8FB2] focus:outline-none',
                  )}
                />
                <ConfirmButton onClick={onManuelConfirmConnectClick} />
              </div>
              <div className='flex items-start justify-end translate-x-[0.6px]  lg:translate-x-[1px]'>
                <WarningIcon />
                <p className='w-[126px] lg:w-[169px] text-[6px] lg:text-[8px] leading-[8px] lg:leading-[10px] text-[#FB8383] text-start font-[600] translate-y-[0.7px] lg:translate-y-0 translate-x-[-0.5px] lg:translate-x-[-0.4px]'>Please double-check your address before transferring funds. Assets sent to the wrong address cannot be retrieved.</p>
              </div>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ManuelConnectWallet;