import classNames from 'classnames';
import React, { useEffect } from 'react';

import useToggle from '@/hooks/useToggle';

export const Checkbox: React.FC<any> = ({
  checked = false,
  onChange,
  disabled,
  className,
  ...props
}) => {
  const [on, toggle, set] = useToggle(checked);

  useEffect(() => {
    set(checked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked]);

  useEffect(() => {
    if (disabled) set(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  return (
    <button
      onClick={() => {
        if (disabled) return;
        onChange && onChange(on);
        toggle();
      }}
      className={classNames(
        className,
        'w-10 rounded-full p-1',
        on ? 'bg-[#5258CE]' : 'bg-[#A4A4AB]'
      )}
      {...props}
    >
      <div
        className={classNames(
          'h-4 w-4 rounded-full bg-white transition-transform',
          {
            'translate-x-4': on,
          }
        )}
      ></div>
    </button>
  );
};
