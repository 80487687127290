import resolveConfig from 'tailwindcss/resolveConfig';

import tailwindConfig from '../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);
const screens = fullConfig?.theme?.screens ?? {};

export const getBreakpointValue = (value) => {
  return +screens[value].slice(0, screens[value].indexOf('px'));
};

export const getCurrentBreakpoint = () => {
  if (typeof window === 'undefined') return;
  let currentBreakpoint = '';
  let biggestBreakpointValue = 0;
  for (const breakpoint of Object.keys(screens)) {
    const breakpointValue = getBreakpointValue(breakpoint);
    if (
      breakpointValue > biggestBreakpointValue &&
      window.innerWidth >= breakpointValue
    ) {
      biggestBreakpointValue = breakpointValue;
      currentBreakpoint = breakpoint;
    }
  }
  return currentBreakpoint;
};
