import classNames from 'classnames';
import React from 'react';

interface TriangleWarningProps {
  className?: string;
}

const TriangleWarning = ({ className }: TriangleWarningProps) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={classNames(className)}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M3.2444 15.454H15.2691C16.5815 15.454 17.4053 14.0366 16.7557 12.8959L10.7814 2.41052C10.1268 1.26234 8.47329 1.25817 7.81292 2.40301L1.76269 12.8884C1.10481 14.0291 1.92779 15.454 3.2444 15.454Z'
        fill='#E04040'
      />
      <path
        d='M9.25 9.7303V6M9.25 12.5948V12.5825'
        stroke='white'
        strokeWidth='1.44527'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default TriangleWarning;
