import WalletLogos from '@/components/WalletLogos';

export const LoginMethods = {
  extension: 'extension',
  appwallet: 'appwallet',
  ledger: 'ledger',
  webwallet: 'webwallet',
  maiar: 'maiar',
  experimentalWebview: 'experimentalWebview',
  extra: 'extra',
};

export const walletProvidersConfig = [
  {
    brand: 'MultiversX Defi Wallet',
    svgLogo: WalletLogos.maiarDefiWallet,
    loginType: LoginMethods.extension,
    enabled: true,
  },
  {
    brand: 'xPortal Mobile Wallet',
    svgLogo: WalletLogos.maiarApp,
    loginType: LoginMethods.maiar,
    enabled: true,
  },
  {
    brand: 'Ledger',
    svgLogo: WalletLogos.ledger,
    loginType: LoginMethods.ledger,
    enabled: true,
  },
  {
    brand: 'MultiversX Web Wallet',
    svgLogo: WalletLogos.elrondWebWallet,
    loginType: LoginMethods.webwallet,
    enabled: true,
  },
];
