import { createContext } from 'react';

import useStoreUserBalances from '@/contexts/ProtocolContext/hooks/useStoreUserBalances';

const ProtocolContext = createContext({});

const ProtocolConsumer = ProtocolContext.Consumer;

const ProtocolProvider = ({ children }: { children: JSX.Element }) => {
  useStoreUserBalances();

  return (
    <ProtocolContext.Provider value={{}}>{children}</ProtocolContext.Provider>
  );
};

export default ProtocolContext;

export { ProtocolConsumer, ProtocolProvider };
