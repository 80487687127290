import { EXCHANGE_RATE_KEY } from '@/hooks/protocol/useExchangeRate';

export const styleStore = {
  [EXCHANGE_RATE_KEY.EGLD]: {
    assetImgPath: 'https://cdn.app.hatom.com/images/img1.svg',
    featureGradient:
      'linear-gradient(271.36deg, #1DDAB8 32.94%, #80F8E2 90.76%)',
  },
  [EXCHANGE_RATE_KEY.sEGLD]: {
    assetImgPath: 'https://cdn.app.hatom.com/images/sEGLD.svg',
    featureGradient:
      'linear-gradient(245.33deg, #006FFF 34.26%, #63A7FF 89.62%)',
  },
  [EXCHANGE_RATE_KEY.HsEGLD]: {
    assetImgPath: 'https://cdn.app.hatom.com/images/liquidstaking/app/tokens/hsegld.png',
    featureGradient: 'linear-gradient(287.1deg, #7E19FF 7.71%, #A35EFB 84%)',
  },

  [EXCHANGE_RATE_KEY.WTAO]: {
    assetImgPath: 'https://cdn.app.hatom.com/images/wtao.svg',
    featureGradient:
      'linear-gradient(271.36deg, #1DDAB8 32.94%, #80F8E2 90.76%)',
  },
  [EXCHANGE_RATE_KEY.sWTAO]: {
    assetImgPath: 'https://cdn.app.hatom.com/images/liquidstaking/app/tokens/swtao-circle.svg',
    featureGradient:
      'linear-gradient(245.33deg, #006FFF 34.26%, #63A7FF 89.62%)',
  },
  [EXCHANGE_RATE_KEY.HsWTAO]: {
    assetImgPath: 'https://cdn.app.hatom.com/images/liquidstaking/app/tokens/hswtao.svg',
    featureGradient: 'linear-gradient(287.1deg, #7E19FF 7.71%, #A35EFB 84%)',
  },
};
