import classNames from 'classnames';

const TitleAndSub: React.FC<any> = ({ title, sub, right }) => {
  return (
    <div
      className={classNames({
        'text-right': right,
      })}
    >
      <div>{title}</div>
      <div
        className={classNames(
          'text-[14px] font-normal',
          {
            'flex justify-end': right,
          },
          'text-[#535367] dark:text-[#625E8F]'
        )}
      >
        {sub}
      </div>
    </div>
  );
};

export default TitleAndSub;
