import DefiUtils from 'defi-utils';
import { createContext, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/index';
import { setTokens, TOKEN_KEY } from '@/store/liquid-staking-landing';
import {
  MARKET_KEY,
  nativeMarketSelector,
  protocolSelector,
  sEgldMarketSelector,
} from '@/store/protocol';
import { rewardBatchSelector } from '@/store/reward-batch';

const LiquidStakingLandingContext = createContext({});

const LiquidStakingLandingConsumer = LiquidStakingLandingContext.Consumer;

type LiquidStakingLandingProviderProps = {
  children: React.ReactNode;
};

const LiquidStakingLandingProvider = ({
  children,
}: LiquidStakingLandingProviderProps) => {
  const dispatch = useAppDispatch();

  const { liquidStaking } = useAppSelector(protocolSelector);
  const { markets: rewardMarkets } = useAppSelector(rewardBatchSelector);
  const nativeMarket = useAppSelector(nativeMarketSelector);
  const sEgldMarket = useAppSelector(sEgldMarketSelector);

  useEffect(() => {
    const rewardsTokensAPY =
      rewardMarkets[sEgldMarket.underlying.symbol as MARKET_KEY]
        ?.totalRewardsSupplyAPY || '0';

    const tokens = {
      [TOKEN_KEY.sEGLD]: {
        staked: new DefiUtils(liquidStaking.totalStaked)
          .toFullDecimals(nativeMarket.underlying.decimals)
          .toString(),
        stakers: liquidStaking.segldStakers,
        apr: liquidStaking.apy,
      },
      [TOKEN_KEY.HsEGLD]: {
        staked: new DefiUtils(sEgldMarket.totalSupply)
          .toUnderlying(sEgldMarket.hTokenExchangeRate)
          .toFullDecimals(sEgldMarket.underlying.decimals)
          .toString(),
        stakers: liquidStaking.hsegldStakers,
        apr: new DefiUtils(liquidStaking.apy)
          .plus(sEgldMarket.supplyAPY)
          .plus(rewardsTokensAPY)
          .toString(),
      },
      [TOKEN_KEY.xEGLD]: {
        staked: '14625391',
        stakers: '792749',
        apr: '185',
      },
    };

    dispatch(setTokens(tokens));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    liquidStaking.apy,
    liquidStaking.segldStakers,
    liquidStaking.totalStaked,
    nativeMarket.underlying.decimals,
    rewardMarkets,
    sEgldMarket.hTokenExchangeRate,
    sEgldMarket.supplyAPY,
    sEgldMarket.totalSupply,
    sEgldMarket.underlying.decimals,
    sEgldMarket.underlying.symbol,
  ]);

  return (
    <LiquidStakingLandingContext.Provider value={{}}>
      {children}
    </LiquidStakingLandingContext.Provider>
  );
};

export default LiquidStakingLandingContext;
export { LiquidStakingLandingConsumer, LiquidStakingLandingProvider };
