import { gql } from 'graphql-request';

import {
  getLiquidStakingData,
  getTaoLiquidStakingData,
} from '@/services/blockchain/lens';
import client from '@/services/indexer/client';
import { getTokenPricesTupleV2 } from '@/services/indexer/common';
import {
  formatExchangeRates,
  formatMarketVariation24hPercent,
} from '@/services/indexer/prices/parsers';
import {
  QueryExchangeRate,
  ResponseExchangeRate,
  ResponseMarketVariation24Percent,
} from '@/services/indexer/prices/types';

export const getExchangeRates = async (): Promise<ResponseExchangeRate> => {
  const query = gql`
    query Prices_ExchangeRates @cacheControl(maxAge: 10) {
      queryMoneyMarket {
        underlying {
          id
          symbol
          decimals
        }
        dailyStateHistory(first: 2, order: { desc: day }) {
          moneyMarketState {
            cash
            borrows
            reserves
            totalSupply
            supplyRatePerSecond
            timestamp
          }
        }
      }
    }
  `;

  const variables = {};

  const responseExchangeRates = await client.request<QueryExchangeRate>(
    query,
    variables,
  );

  return formatExchangeRates(responseExchangeRates);
};

export const getMarketVariation24Percent =
  async (): Promise<ResponseMarketVariation24Percent> => {
    const [liquidStakingTaoData, liquidStakingData] = await Promise.all([
      getTaoLiquidStakingData(),
      getLiquidStakingData(),
    ]);

    const liquidStakingExchangeRate = liquidStakingData.exchangeRate;
    const liquidStakingTaoExchangeRate =
      liquidStakingTaoData.currentExchangeRate;

    const [exchangeRatesMap, tokenPricesTuple] = await Promise.all([
      getExchangeRates(),
      getTokenPricesTupleV2({
        liquidStakingExchangeRate,
        liquidStakingTaoExchangeRate,
      }),
    ]);

    const result = formatMarketVariation24hPercent(
      exchangeRatesMap,
      tokenPricesTuple,
    );

    return result;
  };
