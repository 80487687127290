import DefiUtils from 'defi-utils';
import { gql } from 'graphql-request';

import { REWARDS_DECIMALS } from '@/store/reward-batch';

import { chainType } from '@/config/network';
import client from '@/services/indexer/client';
import {
  QueryAccountMarketsForMarketRewards,
  QueryAccountReward,
  QueryAccountRewardsBatches,
  QueryControllerBoosters,
  QueryRewardsBatches,
  ResponseAccountMarketsForMarketRewards,
  ResponseAccountReward,
  ResponseAccountRewardBatch,
  ResponseControllerBoosters,
  ResponseRewardBatch,
} from '@/services/indexer/reward-batch/types';

export const getRewardsBatches = async (): Promise<ResponseRewardBatch[]> => {
  const query = gql`
    query Reward_QueryRewardsBatches () @cacheControl(maxAge: 10) {
      queryRewardsBatchState {
        id
        type
        endTime
        index
        speed
        lastTime
        rewardsToken {
          id
          decimals
          symbol
          name
        }
        moneyMarket {
          address
          totalCollateral
          underlying {
            id
            decimals
            symbol
            name
          }
          hToken {
            id
            decimals
            symbol
            name
          }
          lastState {
            borrowIndex
            borrows
            totalSupply
          }
        }
      }
    }
  `;

  const variables = {};

  const queryRewardsBatches = await client.request<QueryRewardsBatches>(
    query,
    variables,
  );

  const currentTime = Date.now();

  return queryRewardsBatches.queryRewardsBatchState
    .filter(({ type }) => type === 'Supply')
    .map((rewardBatch) => {
      const endTime = new Date(rewardBatch.endTime).getTime();

      const speed =
        currentTime >= endTime
          ? '0'
          : new DefiUtils(rewardBatch.speed).toFullDecimals(18).toString();
      const indexerSpeed = new DefiUtils(rewardBatch.speed)
        .toFullDecimals(18)
        .toString();

      const { lastState, ...rest } = rewardBatch.moneyMarket;
      const { rewardsToken } = rewardBatch;

      return {
        ...rewardBatch,
        moneyMarket: {
          ...rest,
          stateHistory: [lastState],
        },
        rewardsToken: {
          ...rewardsToken,
          symbol: rewardsToken.id.split('-')[0],
          name: rewardsToken.id.split('-')[0],
          decimals:
            rewardsToken.decimals ||
            REWARDS_DECIMALS[rewardsToken.id.split('-')[0]] ||
            18,
        },
        speed,
        indexerSpeed,
      };
    });
};

export const getAccountRewardsBatches = async (
  address: string,
): Promise<ResponseAccountRewardBatch[]> => {
  if (!address) {
    return [];
  }

  const query = gql`
    query Reward_QueryAccountRewardsBatches($address: String!)
    @cacheControl(maxAge: 10) {
      getAccount(address: $address) {
        rewardsBatches {
          id
          index
          amount
          timestamp
        }
      }
    }
  `;

  const variables = {
    address,
  };

  const queryAccountRewardsBatches =
    await client.request<QueryAccountRewardsBatches>(query, variables);

  return (queryAccountRewardsBatches.getAccount?.rewardsBatches || []).map(
    (item) => {
      const id = item.id.replace(`${address}@`, '');

      return {
        ...item,
        id,
      };
    },
  );
};
export const getAccountMarketsForMarketRewards = async (
  ids: string[],
): Promise<ResponseAccountMarketsForMarketRewards[]> => {
  if (ids.length === 0) {
    return [];
  }

  const query = gql`
    query Reward_QueryAccountMarkets($ids: [String]) @cacheControl(maxAge: 10) {
      queryAccountMarket(filter: { id: { in: $ids } }) {
        id
        collateralTokens
        storedBorrowBalance
        accountBorrowIndex
        market {
          address
          underlying {
            id
            symbol
          }
          lastState {
            borrowIndex
            timestamp
            borrowRatePerSecond
          }
        }
      }
    }
  `;

  const variables = {
    ids,
  };

  const queryAccountMarketsForMarketRewardsResponse =
    await client.request<QueryAccountMarketsForMarketRewards>(query, variables);

  return queryAccountMarketsForMarketRewardsResponse.queryAccountMarket;
};

export const getAccountRewards = async (
  ids: string[],
): Promise<ResponseAccountReward[]> => {
  if (ids.length === 0) {
    return [];
  }

  const query = gql`
    query Reward_QueryAccountRewards($ids: [String]) @cacheControl(maxAge: 10) {
      queryAccountRewards(filter: { id: { in: $ids } }) {
        id
        index
        amount
        timestamp
        marketRewardsState {
          id
          moneyMarket {
            underlying {
              id
            }
          }
        }
      }
    }
  `;

  const variables = {
    ids,
  };

  const queryAccountRewardResponse = await client.request<QueryAccountReward>(
    query,
    variables,
  );

  return queryAccountRewardResponse.queryAccountRewards;
};

export const getControllerBoosters = async (): Promise<
  ResponseControllerBoosters[]
> => {
  const query = gql`
    query QueryControllerBoosters {
      queryControllerBooster {
        rewardsToken {
          id
          symbol
        }
        swapPath {
          id
        }
        premium
      }
    }
  `;

  const queryControllerBoosters =
    await client.request<QueryControllerBoosters>(query);

  return queryControllerBoosters.queryControllerBooster.map(
    (controllerBoosterItem) => ({
      ...controllerBoosterItem,
      swapPath:
        chainType === 'devnet'
          ? controllerBoosterItem.swapPath.reverse()
          : controllerBoosterItem.swapPath,
    }),
  );
};
