import useMediaQuery from '@/hooks/useMediaQuery';

interface Screens {
  [key: string]: string;
}

const useBreakpoint = (viewport = 'sm') => {
  const screens: Screens = {
    sm: '460px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1512px',
  };
  return useMediaQuery(`(min-width: ${screens[viewport] ?? viewport})`);
};

export default useBreakpoint;
