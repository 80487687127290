import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import React from 'react';

import {
  ONCE,
  TRANSITION_VARIANTS,
} from '@/hooks/framerMotion/useSetVariantOnLoadingEnd';

import Hint from '@/components/Hint';
import InfoIcon from '@/components/Icons/InfoIcon';
import { Sonar } from '@/components/popups/HatomValidators';

import { useAppDispatch, useAppSelector } from '@/store/index';
import { liquidStakingAppSelector } from '@/store/liquid-staking-app';
import { openPopup } from '@/store/popup';
import { MARKET_KEY, protocolSelector } from '@/store/protocol';

import { chainType, networkConfig } from '@/config/network';
import { cardClasses, useHintProps } from '@/sections/Liquid/App/global';
import { useShimmerControllerContext } from '@/sections/Liquid/App/Provider';
import Shimmer from '@/sections/Liquid/App/Shimmer';
import { formatNumber, nFormatter } from '@/utils/helpers';

const Journal: React.FC<any> = () => {
  const { t } = useTranslation('liquid-app');
  const defaultHintProps = useHintProps();
  const {
    totalLocked,
    totalLockedUSD,
    address: liquidStakingAddress,
    validatorsCount,
  } = useAppSelector(liquidStakingAppSelector);
  const {
    liquidStaking: { totalStakers },
  } = useAppSelector(protocolSelector);

  const dispatch = useAppDispatch();

  const [shimmerEnabled] = useShimmerControllerContext();

  const items = [
    {
      svg: (
        <svg
          width={14}
          height={14}
          viewBox='0 0 14 14'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M2 12V10'
            stroke='#776BF4'
            strokeWidth='2.4'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M12 12V2'
            stroke='#776BF4'
            strokeWidth='2.4'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M7 12V6'
            stroke='#776BF4'
            strokeWidth='2.4'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
      text: t('total-value-locked'),
      value: {
        key: totalLocked,
        approx: totalLockedUSD,
      },
      clock: true,
      tooltipContent: (
        <div className='max-w-[272px] text-[10px] leading-tight tracking-normal'>
          {t('tp-1')}
        </div>
      ),
    },
    {
      svg: (
        <svg
          width={16}
          height={16}
          viewBox='0 0 16 16'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M1.5 4.75L8 1.5L14.5 4.75'
            stroke='#776BF4'
            strokeWidth='1.625'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M1.5 4.75L8 8L14.5 4.75'
            stroke='#776BF4'
            strokeWidth='1.625'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M1.5 8L8 11.25L14.5 8'
            stroke='#776BF4'
            strokeWidth='1.625'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M1.5 11.25L8 14.5L14.5 11.25'
            stroke='#776BF4'
            strokeWidth='1.625'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ),
      text: t('liquidstaking:stakers'),
      value: formatNumber(totalStakers, 0),
      clock: true,
      tooltipContent: (
        <div className='tracking-normal text-[10px] leading-tight'>
          {t('tp-2')}
        </div>
      ),
    },
  ];

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        initial='hidden'
        variants={TRANSITION_VARIANTS}
        whileInView='visible'
        viewport={{ once: ONCE, amount: 0.3 }}
      >
        <ul className='flex flex-col gap-3 pb-[18px] font-semibold'>
          {items.map(({ svg, text, value, clock, tooltipContent }, i) => (
            <li key={i} className={classNames(cardClasses)}>
              <div className='grid grid-cols-[auto,1fr,auto] items-center gap-2 gap-y-4'>
                <div className='flex-xy-center theme-switch-transition dark:bg-[#3B2C97] h-6 w-6 self-start rounded-full bg-[#E1E1FF]'>
                  {svg}
                </div>
                <div className='max-w-[224px] text-base'>
                  {text}
                  {clock && (
                    <div className='ml-1 inline-block'>
                      <Hint
                        autoReplace
                        {...defaultHintProps}
                        unvisible={!tooltipContent}
                        block
                        content={tooltipContent}
                      >
                        <InfoIcon
                          className='dark:text-[#625E8F] -ml-px -mt-px inline-block text-[12px] text-[#5C5C5C]'
                          strokeWidth='1.5'
                        />
                      </Hint>
                    </div>
                  )}
                </div>

                {typeof value === 'string' ? (
                  <div className='font-hass leading-6'>
                    <Shimmer sm>{value}</Shimmer>
                  </div>
                ) : (
                  <>
                    <a
                      className='-mt-0.5 ml-auto -translate-y-0.5 cursor-pointer text-[#006FFF] hover:opacity-80'
                      href={`${networkConfig[chainType].explorerAddress}/accounts/${liquidStakingAddress}`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <svg
                        width='19'
                        height='19'
                        viewBox='0 0 19 19'
                        fill='currentColor'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path d='M9.50611 18.9991C1.38647 18.9991 0.015625 16.2342 0.015625 10.0288C0.015625 3.82354 1.23884 1.05859 8.45161 1.05859C8.73128 1.05859 8.9995 1.16978 9.19725 1.36769C9.39501 1.5656 9.50611 1.83403 9.50611 2.11392C9.50611 2.39381 9.39501 2.66223 9.19725 2.86014C8.9995 3.05806 8.73128 3.16924 8.45161 3.16924C2.87332 3.16924 2.12462 4.55172 2.12462 10.0288C2.12462 15.506 2.85223 16.8885 9.50611 16.8885C16.16 16.8885 16.8876 15.4954 16.8876 10.0288C16.8876 9.74896 16.9987 9.48053 17.1965 9.28262C17.3942 9.08471 17.6624 8.97352 17.9421 8.97352C18.2218 8.97352 18.49 9.08471 18.6877 9.28262C18.8855 9.48053 18.9966 9.74896 18.9966 10.0288C18.9966 16.2342 17.6257 18.9991 9.50611 18.9991Z' />
                        <path d='M10.5596 9.50223C10.4209 9.50303 10.2833 9.47641 10.1548 9.4239C10.0263 9.37138 9.90947 9.294 9.81094 9.19619C9.7121 9.09808 9.63366 8.98136 9.58012 8.85276C9.52659 8.72416 9.49902 8.58622 9.49902 8.44691C9.49902 8.30759 9.52659 8.16966 9.58012 8.04106C9.63366 7.91245 9.7121 7.79573 9.81094 7.69763L17.1924 0.310362C17.391 0.11164 17.6603 0 17.9411 0C18.2219 0 18.4912 0.11164 18.6898 0.310362C18.8883 0.509083 18.9999 0.778607 18.9999 1.05964C18.9999 1.34068 18.8883 1.6102 18.6898 1.80892L11.3083 9.19619C11.2098 9.294 11.0929 9.37138 10.9645 9.4239C10.836 9.47641 10.6984 9.50303 10.5596 9.50223Z' />
                        <path d='M17.9414 6.33585C17.6617 6.33585 17.3935 6.22466 17.1957 6.02675C16.998 5.82884 16.8869 5.56041 16.8869 5.28052V2.11455H13.7234C13.4438 2.11455 13.1756 2.00337 12.9778 1.80546C12.78 1.60755 12.6689 1.33912 12.6689 1.05923C12.6689 0.779341 12.78 0.510915 12.9778 0.313003C13.1756 0.115092 13.4438 0.00390625 13.7234 0.00390625L17.9414 0.00390625C18.221 0.00390625 18.4893 0.115092 18.687 0.313003C18.8848 0.510915 18.9959 0.779341 18.9959 1.05923V5.28052C18.9959 5.56041 18.8848 5.82884 18.687 6.02675C18.4893 6.22466 18.221 6.33585 17.9414 6.33585Z' />
                      </svg>
                    </a>

                    <div className='col-span-2 text-xl leading-[24px]'>
                      <Hint
                        {...defaultHintProps}
                        content={formatNumber(value.key, 6)}
                        unvisible={new DefiUtils(value.key).isEqualTo(0)}
                      >
                        <Shimmer lg>{nFormatter(value.key, 2)} EGLD</Shimmer>
                      </Hint>
                    </div>
                    <div className='dark:text-[#625E8F] text-[#535367]'>
                      <Hint
                        {...defaultHintProps}
                        content={`$${formatNumber(value.approx, 6)}`}
                        unvisible={new DefiUtils(value.approx).isEqualTo(0)}
                      >
                        <Shimmer md>≈ ${nFormatter(value.approx, 2)}</Shimmer>
                      </Hint>
                    </div>
                  </>
                )}
              </div>
            </li>
          ))}

          <li className={classNames(cardClasses, 'relative')}>
            <div className='grid grid-cols-[1fr,auto]  items-center gap-y-4'>
              <div className='flex items-center'>
                <div className='-m-1.5 ml-0 origin-left scale-[.55]'>
                  <Sonar />
                </div>
                <span className='flex items-center gap-1'>
                  {shimmerEnabled ? <Shimmer w={16} /> : validatorsCount}{' '}
                  {t('x-node-operators', {
                    count: undefined,
                  })}
                </span>
              </div>
              <button
                onClick={() => {
                  dispatch(
                    openPopup({
                      name: 'hatom_validators',
                      data: {
                        liquidStaking: MARKET_KEY.sEGLD,
                      },
                    }),
                  );
                }}
                className='ml-auto leading-6 text-[#006FFF] hover:opacity-80'
              >
                {t('view')}
              </button>
            </div>
          </li>
        </ul>
      </m.div>
    </LazyMotion>
  );
};

export default Journal;
