import { SVGProps } from 'react';

import useTheme from '@/hooks/useTheme';

const InfoIco1 = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement> & { darkColor?: string }) => {
  const { isDark } = useTheme()
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 12 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5.38845 13.5234H6.51467C8.70276 13.5234 10.4766 11.7496 10.4766 9.56155V8.43533C10.4766 6.24723 8.70276 4.47344 6.51467 4.47344H5.38845C3.20036 4.47344 1.42656 6.24723 1.42656 8.43533V9.56155C1.42656 11.7496 3.20036 13.5234 5.38845 13.5234Z'
        stroke={isDark && props.darkColor ? props.darkColor : '#6A6A8C'}
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.94531 11.0117V9.00061'
        stroke={isDark && props.darkColor ? props.darkColor : '#6A6A8C'}
        strokeWidth='1.4'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.95035 6.48663C5.67267 6.48663 5.44757 6.71173 5.44757 6.98941C5.44757 7.26709 5.67267 7.49219 5.95035 7.49219C6.22802 7.49219 6.45312 7.26709 6.45312 6.98941C6.45312 6.71173 6.22802 6.48663 5.95035 6.48663Z'
        fill={isDark && props.darkColor ? props.darkColor : '#6A6A8C'}
      />
    </svg>
  );
};

export default InfoIco1;
