import DefiUtils from 'defi-utils';
import { useCallback, useEffect } from 'react';

import { PROPOSAL_STATUS, setGovernance } from '@/store/governance';
import { useAppDispatch } from '@/store/index';

import useVotesPopulated from '@/contexts/GovernanceContext/hooks/useVotesPopulated';

const useStoreAvailableToRedeem = () => {
  const dispatch = useAppDispatch();
  const votesPopulated = useVotesPopulated();

  const calcAvailableToRedeem = useCallback(() => {
    return votesPopulated
      .filter(({ proposalStatus }) =>
        [
          PROPOSAL_STATUS.succeeded,
          PROPOSAL_STATUS.executed,
          PROPOSAL_STATUS.defeated,
        ].includes((proposalStatus || '') as PROPOSAL_STATUS)
      )
      .reduce((prev, { amount }) => prev.plus(amount), new DefiUtils(0))
      .toString();
  }, [votesPopulated]);

  useEffect(() => {
    const availableToRedeem = calcAvailableToRedeem();

    dispatch(
      setGovernance({
        availableToRedeem,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcAvailableToRedeem]);
};

export default useStoreAvailableToRedeem;
