import { gql } from 'graphql-request';

import client from '@/services/indexer/client';
import { formatDelegationContracts } from '@/services/indexer/liquid-staking-app/parsers';
import {
  QueryDelegationContracts,
  QueryUndelegateTokens,
  ResponseDelegationContract,
  ResponseUndelegateToken,
} from '@/services/indexer/liquid-staking-app/types';

export const getUndelegateTokens = async (
  ids: string[]
): Promise<ResponseUndelegateToken[]> => {
  if (ids.length === 0) {
    return [];
  }

  const query = gql`
    query LiquidStakingApp_QueryUndelegateTokens($ids: [String])
    @cacheControl(maxAge: 10) {
      queryUndelegateToken(filter: { id: { in: $ids } }) {
        id
        source
        hLsTokenAmount
      }
    }
  `;

  const variables = {
    ids,
  };

  const queryUndelegateTokensResponse =
    await client.request<QueryUndelegateTokens>(query, variables);

  return queryUndelegateTokensResponse.queryUndelegateToken || [];
};

export const getDelegationContracts = async (): Promise<
  ResponseDelegationContract[]
> => {
  const query = gql`
    query LiquidStakingApp_QueryDelegationContract () @cacheControl(maxAge: 10) {
      queryDelegationContract {
        id
        totalDelegated
        apr
        serviceFee
        pendingToDelegate
      }
    }
  `;

  const variables = {};

  const queryDelegationContractsResponse =
    await client.request<QueryDelegationContracts>(query, variables);

  return formatDelegationContracts(queryDelegationContractsResponse);
};
