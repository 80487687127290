const QrCodeReaderIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 16 16'
      fill='none'
      stroke='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.23371 2.16016H5.2659C5.94762 2.16016 6.49961 2.7128 6.49961 3.39387V5.42606C6.49961 6.10778 5.94762 6.65977 5.2659 6.65977H3.23371C2.55199 6.65977 2 6.10778 2 5.42606V3.39387C2 2.7128 2.55199 2.16016 3.23371 2.16016Z'
        strokeWidth='1.01923'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.25 4.4082V4.41614'
        strokeWidth='1.01923'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.25 11.9082V11.9161'
        strokeWidth='1.01923'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.23371 9.66016H5.2659C5.94762 9.66016 6.49961 10.2128 6.49961 10.8939V12.926C6.49961 13.6078 5.94762 14.1598 5.2659 14.1598H3.23371C2.55199 14.1598 2 13.6078 2 12.926V10.8939C2 10.2128 2.55199 9.66016 3.23371 9.66016Z'
        strokeWidth='1.01923'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.7337 14.1598H12.7659C13.4476 14.1598 13.9996 13.6071 13.9996 12.926V10.8939C13.9996 10.2122 13.4476 9.66016 12.7659 9.66016H10.7337C10.052 9.66016 9.5 10.2122 9.5 10.8939V12.926C9.5 13.6071 10.052 14.1598 10.7337 14.1598Z'
        strokeWidth='1.01923'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.75 11.9122V11.9043'
        strokeWidth='1.01923'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.5 2.1582V2.16614'
        strokeWidth='1.01923'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.0001 4.41029V3.1409C14.0001 2.59928 13.561 2.16016 13.0201 2.16016H11.75'
        strokeWidth='1.01923'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.0001 6.66016H11.75'
        strokeWidth='1.01923'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.7501 4.41016H10.4807C9.93913 4.41016 9.5 4.84928 9.5 5.39025V6.66029'
        strokeWidth='1.01923'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export default QrCodeReaderIcon;
