import DefiUtils from 'defi-utils';
import { useTranslation } from 'next-i18next';

import { VOTE_TYPE } from '@/store/governance';

import clsxm from '@/services/clsxm';
import { formatNumber } from '@/utils/helpers';

interface RadioInputProps {
  selected: boolean;
  onClick?: () => void;
  count: number;
  action: VOTE_TYPE;
}

const radioInputProps = {
  selected: false,
  count: 0,
  action: VOTE_TYPE.For,
};

export const RadioInput = ({
  selected,
  onClick,
  count,
  action,
}: RadioInputProps = radioInputProps) => {
  const { t } = useTranslation('governance');

  return (
    <button
      type='button'
      onClick={onClick}
      className={clsxm(
        'h-[44px]',
        'w-full',
        'border-[1px]',
        'text-[14px]',
        'p-1.5',
        'px-3',
        'rounded-[8px]',
        'flex',
        'items-center',
        'gap-2',
        'dark:border-[#1D1D33]',
        selected
          ? [
              'border-[#9898BA]',
              'dark:border-[#2E3645]',
              'bg-[#EDE9F8]',
              'dark:bg-[#151C24]',
            ]
          : [
              'border-[#E7E7F9]',
              'dark:border-[#1D1D33]',
              'bg-[#FBFAFF]',
              'dark:bg-[#101015]',
            ]
      )}
    >
      {action == VOTE_TYPE.For ? (
        <svg
          width={20}
          height={21}
          viewBox='0 0 20 21'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect y='0.5' width={20} height={20} rx={10} fill='#64D1BB' />
          <path
            d='M6.38123 10.5688L8.74623 12.9375L13.6187 8.0625'
            stroke='white'
            strokeWidth={2}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      ) : (
        <svg
          width={20}
          height={21}
          viewBox='0 0 20 21'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect y='0.5' width={20} height={20} rx={10} fill='#D16464' />
          <path
            d='M13.2001 7.29363L6.79448 13.6993M13.2082 13.7074L6.79187 7.29102'
            stroke='white'
            strokeWidth={2}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}

      <span>
        {action == VOTE_TYPE.For ? t('for', 'For') : t('against', 'Against')}
      </span>
      <div
        className={clsxm(
          'ml-auto inline-block rounded-[8px] p-1 px-2 text-[12px] capitalize',
          action == VOTE_TYPE.For
            ? 'bg-[#C9EEE8] text-[#7AC7C9]'
            : 'bg-[#EECBC9] text-[#D99793]'
        )}
      >
        {t('current-vote', 'Current Vote')}:{' '}
        {formatNumber(count, 2, DefiUtils.ROUND_FLOOR)}
      </div>
      <div
        className={clsxm(
          'bg-[#ffffff]',
          'dark:bg-[#101015]',
          'p-1',
          'inline-block',
          'rounded-full',
          'ml-2',
          'w-5',
          'h-5',
          selected
            ? ['border-2', 'border-[#9898BA]', 'dark:border-[#2E3645]']
            : ['border', 'border-[#E7E7F9]', 'dark:border-[#1D1D33]']
        )}
      >
        <div
          className={clsxm(
            'rounded-full',
            'bg-[#1F2226]',
            'w-2',
            'aspect-square',
            !selected && 'opacity-0'
          )}
        ></div>
      </div>
    </button>
  );
};
