import useMediaQuery from '@/hooks/useMediaQuery';
import DefiUtils from 'defi-utils';
import { Trans, useTranslation } from 'next-i18next';
import React, { useContext, useMemo, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { useLogin } from '@/hooks/auth/useLogin';
import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';
import useLendInteraction from '@/hooks/interaction/useLendInteraction';
import useLiquidStakingInteraction from '@/hooks/interaction/useLiquidStakingInteraction';
import useLiquidStakingHsEgldCollateral from '@/hooks/liquidStakingApp/useLiquidStakingHsEgldCollateral';
import useLiquidStakingMessageError, {
    OPERATION_KEYS,
} from '@/hooks/liquidStakingApp/useLiquidStakingMessageError';
import useExchangeRate, {
    EXCHANGE_RATE_KEY,
} from '@/hooks/protocol/useExchangeRate';
import useBreakpoint from '@/hooks/useBreakpoint';

import Hint from '@/components/Hint';

import { boosterSelector } from '@/store/booster';
import { useAppDispatch, useAppSelector } from '@/store/index';
import {
    RECOMMENDED_REMOVED_COLLATERAL_LIMIT_MARGIN,
    TOKEN_SOURCE,
    liquidStakingAppSelector,
} from '@/store/liquid-staking-app';
import { openPopup } from '@/store/popup';
import {
    H_TOKEN_DECIMALS,
    MARKET_KEY,
    hasEnoughEGLDBalanceSelector,
    hasTakenBorrowsSelector,
    nextEpochTimeLeftSelector,
    protocolSelector,
    sEgldMarketSelector,
    sEgldUserBalanceSelector,
} from '@/store/protocol';
import {
    TRANSACTION_GROUP_TYPE,
    TRANSACTION_SUBGROUP_TYPE,
    hasPendingTransactionsSelector,
} from '@/store/transaction';

import {
    hasSplitLiquidStakingUnstake,
    minutesBeforeToBlockTheUnstake,
} from '@/config/envVars';
import { chainType, networkConfig } from '@/config/network';
import { FormContext } from '@/contexts/LiquidStakingFormContext';
import {
    AcceptDeal,
    AmountInput,
    AmountLimitErrors,
    ChooseToken,
    Label,
    Notes,
    SubmitButton,
    WalletCollateralBalanceSwitcher,
} from '@/sections/Liquid/App/Form/components';
import {
    ASHSWAP_FORM,
    MAX_AMOUNT,
    SPLIT_AMOUNT,
} from '@/sections/Liquid/App/Form/constants';
import { divideValuePerMax } from '@/sections/Liquid/App/Form/utils';
import { useHintProps } from '@/sections/Liquid/App/global';
import { PrettyToken as ColoredName } from '@/sections/Liquid/Common/PrettyToken';
import { formatNumber } from '@/utils/helpers';

export const UnstakingInputs: React.FC<any> = () => {
  const md = useMediaQuery('(min-width: 768px)');
  const dispatch = useAppDispatch();
  const hasEnoughEGLDBalance = useAppSelector(hasEnoughEGLDBalanceSelector);
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const nextEpochTimeLeft = useAppSelector(nextEpochTimeLeftSelector);
  const sEgldMarket = useAppSelector(sEgldMarketSelector);
  const sEgldUserBalance = useAppSelector(sEgldUserBalanceSelector);
  const {
    unbondPeriod,
    tokens: liquidStakingTokens,
    maxEgldToUndelegate,
  } = useAppSelector(liquidStakingAppSelector);
  const { exchangeRates, blockchain } = useAppSelector(protocolSelector);
  const { isLoggedIn } = useLogin();
  const { unDelegate, unLendegate } = useLiquidStakingInteraction();
  const { removeCollateral, rebalancePortfolio } = useLendInteraction();
  const { hasStaked: hasHtmStaked } = useAppSelector(boosterSelector);
  const { signTransactions } = useSignMultipleTransactions();
  const [selected, setSelected] = useState(0);
  const defaultHintProps = useHintProps();
  const hasTakenBorrows = useAppSelector(hasTakenBorrowsSelector);
  const {
    maxRemovableCollateralBalance,
    recommendedMaxRemovableCollateralBalance,
  } = useLiquidStakingHsEgldCollateral();

  const sEgldToEgld = useExchangeRate(
    EXCHANGE_RATE_KEY.sEGLD,
    EXCHANGE_RATE_KEY.EGLD,
  );

  const hsEgldToEgld = useExchangeRate(
    EXCHANGE_RATE_KEY.HsEGLD,
    EXCHANGE_RATE_KEY.EGLD,
  );

  const egldToSEgld = useExchangeRate(
    EXCHANGE_RATE_KEY.EGLD,
    EXCHANGE_RATE_KEY.sEGLD,
  );
  const egldToHsEgld = useExchangeRate(
    EXCHANGE_RATE_KEY.EGLD,
    EXCHANGE_RATE_KEY.HsEGLD,
  );

  const {
    formsState: {
      unstakingInputs: {
        token,
        changeToken,
        inputValue,
        onChange,
        estimation,
        reset: resetForm,
      },
      operation,
    },
  } = useContext(FormContext);

  const options = useMemo(() => {
    return Object.values(liquidStakingTokens).filter(
      ({ symbol }) => EXCHANGE_RATE_KEY.HsEGLD === symbol,
    );
  }, [liquidStakingTokens]);

  const firstSelected = useMemo(() => {
    return options[selected];
  }, [options, selected]);

  const decimalsLimit = useMemo(() => {
    switch (token) {
      case EXCHANGE_RATE_KEY.sEGLD: {
        return sEgldMarket.underlying.decimals;
      }

      case EXCHANGE_RATE_KEY.HsEGLD: {
        return firstSelected.decimals;
      }

      default: {
        return 0;
      }
    }
  }, [firstSelected.decimals, sEgldMarket.underlying.decimals, token]);

  const minValue = useMemo(() => {
    switch (token) {
      case EXCHANGE_RATE_KEY.sEGLD: {
        const value = new DefiUtils(1.00000001)
          .multipliedBy(egldToSEgld)
          .toSafeFixed(sEgldMarket.underlying.decimals, DefiUtils.ROUND_DOWN);

        return value;
      }

      case EXCHANGE_RATE_KEY.HsEGLD: {
        const value = new DefiUtils(1.00000001)
          .multipliedBy(egldToHsEgld)
          .toSafeFixed(H_TOKEN_DECIMALS, DefiUtils.ROUND_DOWN);

        return value.toString();
      }

      default: {
        return '0';
      }
    }
  }, [egldToHsEgld, egldToSEgld, sEgldMarket.underlying.decimals, token]);

  const tokenValue = useMemo(() => {
    switch (token) {
      case EXCHANGE_RATE_KEY.sEGLD: {
        return new DefiUtils(sEgldUserBalance.underlyingBalance)
          .toFullDecimals(sEgldMarket.underlying.decimals)
          .removeScientificNotation();
      }

      case EXCHANGE_RATE_KEY.HsEGLD: {
        return firstSelected.balance;
      }

      default: {
        return '0';
      }
    }
  }, [
    token,
    sEgldUserBalance.underlyingBalance,
    sEgldMarket.underlying.decimals,
    firstSelected.balance,
  ]);

  const totalAvailable = useMemo(() => {
    switch (token) {
      case EXCHANGE_RATE_KEY.sEGLD: {
        return new DefiUtils(sEgldUserBalance.underlyingBalance)
          .toFullDecimals(sEgldMarket.underlying.decimals)
          .removeScientificNotation();
      }

      case EXCHANGE_RATE_KEY.HsEGLD: {
        return options
          .reduce(
            (prev, current) => prev.plus(current.balance),
            new DefiUtils('0'),
          )
          .toString();
      }

      default: {
        return '0';
      }
    }
  }, [
    token,
    sEgldUserBalance.underlyingBalance,
    sEgldMarket.underlying.decimals,
    options,
  ]);

  const totalAvailableUSD = useMemo(() => {
    switch (token) {
      case EXCHANGE_RATE_KEY.sEGLD: {
        return new DefiUtils(totalAvailable)
          .toUSD(sEgldMarket.underlying.priceUSD)
          .toString();
      }

      case EXCHANGE_RATE_KEY.HsEGLD: {
        return options
          .reduce(
            (prev, current) => prev.plus(current.balanceUSD),
            new DefiUtils('0'),
          )
          .toString();
      }

      default: {
        return '0';
      }
    }
  }, [token, sEgldMarket.underlying.priceUSD, totalAvailable, options]);

  const isBtnDisabled = useMemo(() => {
    switch (token) {
      case EXCHANGE_RATE_KEY.sEGLD: {
        return (
          !hasEnoughEGLDBalance ||
          new DefiUtils(inputValue).isGreaterThan(tokenValue) ||
          !new DefiUtils(inputValue).isGreaterThanOrEqualTo(minValue)
        );
      }

      case EXCHANGE_RATE_KEY.HsEGLD: {
        return (
          !hasEnoughEGLDBalance ||
          new DefiUtils(inputValue).isGreaterThan(tokenValue) ||
          !new DefiUtils(inputValue).isGreaterThanOrEqualTo(minValue) ||
          (firstSelected.source === TOKEN_SOURCE.collateral &&
            new DefiUtils(inputValue).isGreaterThan(
              maxRemovableCollateralBalance,
            ))
        );
      }

      default: {
        return true;
      }
    }
  }, [
    firstSelected.source,
    hasEnoughEGLDBalance,
    inputValue,
    maxRemovableCollateralBalance,
    minValue,
    token,
    tokenValue,
  ]);

  const { t } = useTranslation('liquid-app');

  const receivedValue = useMemo(() => {
    let value = '0';

    if (inputValue && new DefiUtils(inputValue).isGreaterThan(0)) {
      switch (token) {
        case EXCHANGE_RATE_KEY.sEGLD: {
          value = new DefiUtils(inputValue)
            .multipliedBy(sEgldToEgld)
            .toString();
          break;
        }

        case EXCHANGE_RATE_KEY.HsEGLD: {
          value = new DefiUtils(inputValue)
            .multipliedBy(hsEgldToEgld)
            .toString();
          break;
        }
      }
    }

    return {
      value,
      formatted: `${formatNumber(value, 6)} EGLD`,
      nil: new DefiUtils(value).isZero(),
    };
  }, [hsEgldToEgld, inputValue, sEgldToEgld, token]);

  const getHsEGLDExchangeRate = (decimals = 6) => {
    const value = new DefiUtils(1).multipliedBy(hsEgldToEgld);
    return {
      value: value.toString(),
      formatted: value.toFixed(decimals, DefiUtils.ROUND_DOWN),
      nil: new DefiUtils(value).isZero(),
    };
  };

  const getsEGLDExchangeRate = (decimals = 6) => {
    const value = new DefiUtils(1).multipliedBy(sEgldToEgld);
    return {
      value: value.toString(),
      formatted: value.toFixed(decimals, DefiUtils.ROUND_DOWN),
      nil: new DefiUtils(value).isZero(),
    };
  };

  const sm = useBreakpoint('sm');

  const waitTimeValue = useMemo(() => {
    const totalInHours = new DefiUtils(networkConfig[chainType].epochInHours)
      .multipliedBy(unbondPeriod)
      .toNumber();

    const hours = Math.floor(totalInHours % 60);
    const days = Math.floor(totalInHours / 24);

    const list = [
      ...(days !== 0 ? [`${days} ${t('days').toLowerCase()}`] : []),
      ...(hours !== 0 ? [`${hours} ${t('hours').toLowerCase()}`] : []),
    ];

    return `≈ ${list.join(' ')}`;
  }, [t, unbondPeriod]);

  const exchangeRateTooltip = useMemo(() => {
    const totalTime = blockchain.roundsPerEpoch * 6;
    const timeLeftInSeconds = blockchain.timeLeftInSeconds;
    const tenMinutesInSeconds = 60 * 10;

    if (totalTime - timeLeftInSeconds < tenMinutesInSeconds) {
      return t('liquid-app:tp-12-plus');
    }

    if (token == EXCHANGE_RATE_KEY.sEGLD) {
      return (
        <Trans
          i18nKey='tp-12'
          tOptions={{ nextEpoch: nextEpochTimeLeft, token: 'EGLD' }}
          components={[
            <ColoredName token={EXCHANGE_RATE_KEY.sEGLD} />,
            <span className='text-green' />,
          ]}
          ns='liquid-app'
        />
      );
    } else {
      return (
        <Trans
          i18nKey='tp-15'
          components={[
            <ColoredName token={EXCHANGE_RATE_KEY.HsEGLD} />,
            <ColoredName token={EXCHANGE_RATE_KEY.sEGLD} />,
          ]}
          ns='liquid-app'
        />
      );
    }
  }, [
    blockchain.roundsPerEpoch,
    blockchain.timeLeftInSeconds,
    nextEpochTimeLeft,
    t,
    token,
  ]);

  const notes = useMemo(
    () => [
      {
        caption: t('receive'),
        captionTooltip: null,
        value: (
          <Hint
            {...defaultHintProps}
            content={receivedValue.value}
            hidden={receivedValue.nil}
          >
            {receivedValue.formatted}
          </Hint>
        ),
      },
      {
        caption: t('exchange-rate'),
        tooltipMax: md ? 204 : 215,
        captionTooltip: exchangeRateTooltip,
        customIcon: (
          <div className='mt-1px inline-block'>
            <svg
              width={11}
              height={11}
              viewBox='0 0 10 10'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4.99948 6.00009C4.83948 6.00009 4.67948 5.92009 4.58448 5.77509C4.42948 5.54509 4.49448 5.23509 4.72448 5.08009L6.22448 4.08009C6.45448 3.92509 6.76448 3.99009 6.91948 4.22009C7.07448 4.45009 7.00948 4.76009 6.77948 4.91509L5.27948 5.91509C5.19448 5.97009 5.09948 6.00009 5.00448 6.00009H4.99948Z'
                fill='#4AAD7C'
              />
              <path
                d='M4.99947 5.99995C4.84947 5.99995 4.69947 5.92995 4.59947 5.79995L3.09947 3.79995C2.93447 3.57995 2.97947 3.26495 3.19947 3.09995C3.41947 2.93495 3.73447 2.97995 3.89947 3.19995L5.39947 5.19995C5.56447 5.41995 5.51947 5.73495 5.29947 5.89995C5.20947 5.96495 5.10447 5.99995 4.99947 5.99995Z'
                fill='#4AAD7C'
              />
              <path
                d='M5.56 10H4.435C1.99 10 0 8.01 0 5.56V4.435C0 1.99 1.99 0 4.44 0H5.565C8.01 0 10.005 1.99 10.005 4.44V5.565C10.005 8.01 8.015 10.005 5.565 10.005L5.56 10ZM4.44 1C2.545 1 1 2.54 1 4.44V5.565C1 7.46 2.54 9.005 4.44 9.005H5.565C7.46 9.005 9.005 7.465 9.005 5.565V4.44C9.005 2.545 7.465 1 5.565 1H4.44Z'
                fill='#4AAD7C'
              />
            </svg>
          </div>
        ),
        value: (
          <>
            1 {token} ={' '}
            {token === EXCHANGE_RATE_KEY.sEGLD && (
              <>
                <Hint
                  {...defaultHintProps}
                  content={getsEGLDExchangeRate().value}
                  hidden={getsEGLDExchangeRate().nil}
                  className='-mt-1 sm:-mt-0.5'
                >
                  {getsEGLDExchangeRate(sm ? 6 : 4).formatted}
                </Hint>
              </>
            )}
            {token === EXCHANGE_RATE_KEY.HsEGLD && (
              <>
                <Hint
                  className='-mt-1 sm:-mt-0.5'
                  {...defaultHintProps}
                  content={getHsEGLDExchangeRate().value}
                  hidden={getHsEGLDExchangeRate().nil}
                >
                  {getHsEGLDExchangeRate(sm ? 6 : 4).formatted}
                </Hint>
              </>
            )}
            {' EGLD'}
          </>
        ),
      },
      {
        caption: t('unstaking-period'),
        tooltipMax: md ? 212 : 235,
        captionTooltip: t('tp-11', {
          value: networkConfig[chainType].epochInHours,
        }),
        value: `${unbondPeriod} ${t('epochs').toLowerCase()}`,
      },
      {
        caption: t('wait-time'),
        tooltipMax: md ? 209 : 141,
        captionTooltip: (
          <>
            <Trans
              i18nKey='tp-9'
              components={[<ColoredName token={token} />]}
              ns='liquid-app'
            />
          </>
        ),
        value: waitTimeValue,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      defaultHintProps,
      nextEpochTimeLeft,
      receivedValue.formatted,
      receivedValue.nil,
      receivedValue.value,
      t,
      token,
      unbondPeriod,
    ],
  );

  const hasEightyPercentButton = useMemo(() => {
    const isHsEgldCollateral =
      token === EXCHANGE_RATE_KEY.HsEGLD &&
      firstSelected.source === TOKEN_SOURCE.collateral;

    const isBalanceEqualToRecommended = new DefiUtils(
      firstSelected.balance,
    ).isEqualTo(recommendedMaxRemovableCollateralBalance);

    return (
      isHsEgldCollateral && hasTakenBorrows && !isBalanceEqualToRecommended
    );
  }, [
    firstSelected.balance,
    firstSelected.source,
    hasTakenBorrows,
    recommendedMaxRemovableCollateralBalance,
    token,
  ]);

  const splitAmount = useMemo(() => {
    const decimals =
      token === EXCHANGE_RATE_KEY.HsEGLD
        ? H_TOKEN_DECIMALS
        : sEgldMarket.underlying.decimals;

    const maxToUndelegateInSelectedToken =
      new DefiUtils(maxEgldToUndelegate)
        .multipliedBy(
          exchangeRates[`${EXCHANGE_RATE_KEY.EGLD}/${token.toUpperCase()}`] ||
            '0',
        )
        .toSafeFixed(decimals, DefiUtils.ROUND_DOWN) || '0';

    return new DefiUtils(maxToUndelegateInSelectedToken).isGreaterThan(
      SPLIT_AMOUNT,
    )
      ? SPLIT_AMOUNT
      : maxToUndelegateInSelectedToken;
  }, [
    maxEgldToUndelegate,
    exchangeRates,
    token,
    sEgldMarket.underlying.decimals,
  ]);

  const error = useLiquidStakingMessageError({
    operation: OPERATION_KEYS.UNSTAKE,
    inputValue: inputValue || '0',
    symbol: token,
    source: firstSelected.source as TOKEN_SOURCE,
    minValue,
    balance: tokenValue,
  });

  const handleChangeAmountLimit = (value: string) => {
    onChange(value);
  };

  const handleChange = (value: string) => {
    if (!value) {
      onChange(value);
      return;
    }

    const isHsEgld = token === EXCHANGE_RATE_KEY.HsEGLD;
    const maxAmount = isHsEgld
      ? new DefiUtils(MAX_AMOUNT).multipliedBy(50).toString()
      : MAX_AMOUNT;

    if (new DefiUtils(value).isGreaterThan(maxAmount)) {
      return;
    }

    onChange(value);
  };

  const handleMax = () => {
    let maxValue = '0';

    const isHsEgld = token === EXCHANGE_RATE_KEY.HsEGLD;

    const isHsEgldCollateral =
      isHsEgld && firstSelected.source === TOKEN_SOURCE.collateral;

    if (isHsEgldCollateral && hasTakenBorrows) {
      const isBalanceEqualToRecommended = new DefiUtils(tokenValue).isEqualTo(
        recommendedMaxRemovableCollateralBalance,
      );
      maxValue = isBalanceEqualToRecommended
        ? recommendedMaxRemovableCollateralBalance
        : new DefiUtils(recommendedMaxRemovableCollateralBalance)
            .multipliedBy(RECOMMENDED_REMOVED_COLLATERAL_LIMIT_MARGIN)
            .toSafeFixed(H_TOKEN_DECIMALS, DefiUtils.ROUND_DOWN);
    } else if (isHsEgldCollateral && !hasTakenBorrows) {
      maxValue = maxRemovableCollateralBalance;
    } else {
      maxValue = tokenValue;
    }

    const maxAmount = isHsEgld
      ? new DefiUtils(MAX_AMOUNT).multipliedBy(50).toString()
      : MAX_AMOUNT;

    const value = new DefiUtils(
      new DefiUtils(maxValue).isGreaterThan(maxAmount) ? maxAmount : maxValue,
    ).removeScientificNotation();

    onChange(value);
  };

  const isNearToFinishTheEpoch = useMemo(() => {
    const timeLeftInMinutes = blockchain.timeLeftInSeconds / 60;

    return minutesBeforeToBlockTheUnstake >= timeLeftInMinutes;
  }, [blockchain.timeLeftInSeconds]);

  const handleUnstake = async () => {
    if (hasPendingTransactions || isNearToFinishTheEpoch) {
      return;
    }

    switch (token) {
      case EXCHANGE_RATE_KEY.sEGLD: {
        const unDelegatesPerAmount = hasSplitLiquidStakingUnstake
          ? divideValuePerMax(inputValue, splitAmount)
          : [inputValue];

        const unDelegateTxs = unDelegatesPerAmount.map((amount) =>
          unDelegate({ amount, tokenKey: MARKET_KEY.sEGLD }),
        );

        const result = new DefiUtils(inputValue)
          .multipliedBy(sEgldToEgld)
          .toString();

        await signTransactions(unDelegateTxs, {
          group: TRANSACTION_GROUP_TYPE.LIQUID,
          subgroup: TRANSACTION_SUBGROUP_TYPE.UNSTAKE_UNDERLYING,
          result,
        });
        break;
      }

      case EXCHANGE_RATE_KEY.HsEGLD: {
        const unLengedatesPerAmount = hasSplitLiquidStakingUnstake
          ? divideValuePerMax(inputValue, splitAmount)
          : [inputValue];

        const unLendegateTxs = unLengedatesPerAmount.map((amount) =>
          unLendegate({
            amount: new DefiUtils(amount)
              .toBasicUnits(H_TOKEN_DECIMALS)
              .toString(),
            tokenKey: sEgldMarket.underlying.symbol,
          }),
        );

        const result = new DefiUtils(inputValue)
          .multipliedBy(hsEgldToEgld)
          .toString();

        if (firstSelected.source === TOKEN_SOURCE.collateral) {
          await signTransactions(
            [
              removeCollateral({
                tokenKey: sEgldMarket.underlying.symbol,
                amountAsHTokenBigNumber: new DefiUtils(inputValue)
                  .toBasicUnits(H_TOKEN_DECIMALS)
                  .toString(),
                isMax: false,
              }),
              ...unLendegateTxs,
              ...(hasHtmStaked ? [rebalancePortfolio()] : []),
            ],
            {
              isSecuencial: true,
              group: TRANSACTION_GROUP_TYPE.LIQUID,
              subgroup: TRANSACTION_SUBGROUP_TYPE.UNSTAKE_TOKEN_COLLATERAL,
              result,
            },
          );
          return;
        }

        await signTransactions(unLendegateTxs, {
          group: TRANSACTION_GROUP_TYPE.LIQUID,
          subgroup: TRANSACTION_SUBGROUP_TYPE.UNSTAKE_TOKEN,
          result,
        });
        break;
      }
    }
  };

  const handleConnectWallet = () => {
    dispatch(
      openPopup({
        name: 'connect_bridge_multiverse_wallet',
      }),
    );
  };

  const sendRebalance = useMemo(() => {
    return (
      isLoggedIn &&
      token === EXCHANGE_RATE_KEY.HsEGLD &&
      firstSelected.source === TOKEN_SOURCE.collateral
    );
  }, [firstSelected.source, token, isLoggedIn]);

  return (
    <>
      <ChooseToken
        checked={token}
        onChange={(t: any) => {
          resetForm();
          setTimeout(() => {
            changeToken(t);
          }, 0);
        }}
        className='mb-9'
      />

      <div>
        <Label
          label={t('amount')}
          valueText={
            token === EXCHANGE_RATE_KEY.sEGLD
              ? t('wallet-balance')
              : t('total-available')
          }
          token={token}
          value={totalAvailable}
          valueUSD={totalAvailableUSD}
          tooltip={
            <div
              style={{ maxWidth: md ? 365 : 244 }}
              className='text-[8px] md:text-[10px] leading-tight'
            >
              <Trans
                i18nKey='tp-18'
                components={[<ColoredName token={token} />]}
                ns='liquid-app'
                tOptions={{ token: 'EGLD' }}
              />
            </div>
          }
          className='mb-1'
        />

        <AmountInput
          estimation={estimation}
          inputValue={token === EXCHANGE_RATE_KEY.sEGLD ? inputValue : '0'}
          decimalsLimit={decimalsLimit}
          onChange={handleChange}
          onMax={handleMax}
          hasHundredPercentButton={!hasEightyPercentButton}
          prefix={
            token === EXCHANGE_RATE_KEY.sEGLD ? (
              <div className='h-[24px] w-[24px] rounded-full'>
                <img src='https://cdn.app.hatom.com/images/sEGLD.svg' alt='' />
              </div>
            ) : (
              <div className='relative z-10 flex items-end gap-3 pl-0.5 pr-16'>
                <WalletCollateralBalanceSwitcher
                  circular
                  onChange={(optionSelected: any) => {
                    const index = options.findIndex(
                      ({ id }) => id === optionSelected.id,
                    );
                    setSelected(index);
                  }}
                  options={options}
                  select={selected}
                />

                <div>
                  <CurrencyInput
                    inputMode='text'
                    autoComplete='off'
                    id='liquid-unstake-hsegld-input'
                    name='liquid-unstake-hsegld-input'
                    className='w-full bg-transparent font-semibold placeholder:text-current focus:outline-none'
                    placeholder='0'
                    decimalsLimit={decimalsLimit}
                    allowNegativeValue={false}
                    defaultValue={0}
                    value={inputValue}
                    onValueChange={handleChange}
                    groupSeparator=','
                    decimalSeparator='.'
                    data-testid='hsegldInput'
                  />
                  <div className='text-[12px] text-[#535367] dark:text-[#625E8F]'>
                    ≈ $
                    {formatNumber(
                      new DefiUtils(inputValue)
                        .toUSD(firstSelected.priceUSD)
                        .toString(),
                    )}
                  </div>
                </div>
              </div>
            )
          }
        />
      </div>

      {error && (
        <AmountLimitErrors
          {...error}
          operation={operation}
          token={token}
          onChange={handleChangeAmountLimit}
        />
      )}

      {notes.length && <Notes className='mb-9 mt-9' notes={notes} />}

      <AcceptDeal
        className='mb-9'
        iconSrc='https://cdn.app.hatom.com/images/liquidstaking/app/speaker.png'
        asMessage
        message={
          <div>
            <Trans
              i18nKey='unstake-note'
              components={[
                <span className='text-black dark:text-white' />,
                <a
                  href={ASHSWAP_FORM}
                  target='_blank'
                  className='text-[#006FFF] hover:underline'
                  rel='noreferrer'
                />,
              ]}
              ns='liquid-app'
            />
          </div>
        }
        checked={true}
        onChange={() => {}}
      />

      {isLoggedIn ? (
        <Hint
          content={
            hasPendingTransactions
              ? t('translation:wait-tx-inprogress')
              : isNearToFinishTheEpoch
              ? `${t(
                  'translation:close-to-finish-the-epoch',
                )}: ${nextEpochTimeLeft}`
              : t('translation:tx-not-allowed')
          }
          placement='top-center'
          className='w-full'
          unvisible={!(hasPendingTransactions || isNearToFinishTheEpoch)}
        >
          <SubmitButton onClick={handleUnstake} disabled={isBtnDisabled}>
            {t('unstake')}
          </SubmitButton>
        </Hint>
      ) : (
        <SubmitButton secondary onClick={handleConnectWallet}>
          {t('translation:connect-wallet')}
        </SubmitButton>
      )}

      {sendRebalance && (
        <div className='GreenAlert mt-3 flex min-h-[34px] w-full items-center justify-center rounded-[6px] bg-[#BDF9DF] translate-y-1'>
          <div className='flex max-w-[320px] items-start gap-0.5 text-[12px] leading-[15px] text-[#3E9179]'>
            <img
              src='https://cdn.app.hatom.com/images/green-info.svg'
              alt='green-info'
              className='-translate-x-[1px] translate-y-[1.5px]'
            />
            <div className='flex w-full flex-col'>
              <span className='translate-x-[0.3px] text-center'>
                You'll need to sign two transactions to rebalance your
              </span>
              <span className='-translate-x-[6px] text-center'>
                position within the HTM Booster.
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
