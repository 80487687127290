import useMediaQuery from '@/hooks/useMediaQuery';
import DefiUtils from 'defi-utils';
import Lottie from 'lottie-web';
import React, { useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';

import useTailwindBreakpoint from '@/hooks/useTailwindBreakpoint';

import Hint from '@/components/Hint';

import { useAppSelector } from '@/store';
import { boosterSelector } from '@/store/booster';
import { lendAppSelector } from '@/store/lend-app';
import { MARKET_KEY, protocolSelector, TOKEN_LOGO_MAP } from '@/store/protocol';
import { rewardBatchSelector } from '@/store/reward-batch';

import clsxm from '@/services/clsxm';
import { nFormatter } from '@/utils/helpers';

type Props = {
  selectedTab: string;
  market: string;
  currentApy: string;
  maxApy: string;
  totalStaked: string;
  totalCollateralUSD: string;
  dynamicPercentageBoost: string;
  percentageBoost: string;
  inputValue: string;
  showBoostDecrease: boolean;
  accountStaked: string;
  isLiquid: boolean | undefined;
};
const ProgressItem = (props: Props) => {
  const {
    market,
    currentApy,
    maxApy,
    totalStaked,
    totalCollateralUSD,
    dynamicPercentageBoost,
    percentageBoost,
    inputValue,
    showBoostDecrease,
    accountStaked,
    isLiquid,
  } = props;
  const isMdAndAbove = useTailwindBreakpoint('md');
  const logo = TOKEN_LOGO_MAP[market].normal;
  const htmLogo = TOKEN_LOGO_MAP[MARKET_KEY.HTM].normal;
  const { markets, userBalances, liquidStaking, liquidStakingTao } =
    useAppSelector(protocolSelector);
  const { showLiquidStakingAPY, showLiquidStakingTaoAPY } =
    useAppSelector(lendAppSelector);
  const { maximumApyBooster } = useAppSelector(boosterSelector);
  const { markets: marketsRewards } = useAppSelector(rewardBatchSelector);
  const marketSelected = markets[market as MARKET_KEY];
  const marketRewardSelected = marketsRewards[market as MARKET_KEY];
  const userBalance = userBalances[market as MARKET_KEY];
  const arrowsRef = React.useRef<HTMLDivElement>(null);
  const animRef = React.useRef<any>(null);
  const md = useMediaQuery('(min-width: 768px)');
  const lg1 = useMediaQuery('(min-width: 1024px)');
  const lg = useMediaQuery('(min-width: 1280px)');

  useEffect(() => {
    if (!arrowsRef.current) return;

    const anim = Lottie.loadAnimation({
      container: arrowsRef.current,
      renderer: 'svg',
      loop: true,
      autoplay: false,
      path: 'https://cdn.app.hatom.com/animations/double-arrow.json',
    });

    animRef.current = anim;

    return () => anim.destroy();
  }, []);

  useEffect(() => {
    new DefiUtils(inputValue).isZero()
      ? animRef.current?.stop()
      : animRef.current?.play();
  }, [inputValue]);

  const collateralUSD = new DefiUtils(userBalance.collateralBalance)
    .toFullDecimals(marketSelected.hToken.decimals)
    .multipliedBy(marketSelected.hToken.priceUSD);
  const htmMarket = markets.HTM;

  const liquidStakingAPY = useMemo(() => {
    if (market === MARKET_KEY.sEGLD && showLiquidStakingAPY) {
      return liquidStaking.apy;
    }

    if (market === MARKET_KEY.sWTAO && showLiquidStakingTaoAPY) {
      return liquidStakingTao.apy;
    }

    return '0';
  }, [
    liquidStaking.apy,
    liquidStakingTao.apy,
    market,
    showLiquidStakingAPY,
    showLiquidStakingTaoAPY,
  ]);

  const totalMaxApy = useMemo(() => {
    return new DefiUtils(maxApy)
      .plus(marketSelected.supplyAPY)
      .plus(marketRewardSelected?.userRewardsSupplyAPY)
      .plus(liquidStakingAPY)
      .toString();
  }, [
    liquidStakingAPY,
    marketRewardSelected?.userRewardsSupplyAPY,
    marketSelected.supplyAPY,
    maxApy,
  ]);

  const newBoostValue = useMemo(() => {
    if (new DefiUtils(inputValue).isZero()) return new DefiUtils(0);
    let coverage = new DefiUtils(totalStaked)
      .toUSD(htmMarket.underlying.priceUSD)
      .dividedBy(totalCollateralUSD)
      .dividedBy(maximumApyBooster);

    coverage = coverage.isGreaterThan(1) ? new DefiUtils(1) : coverage;

    return coverage.multipliedBy(maxApy).toString();
  }, [
    inputValue,
    totalStaked,
    htmMarket.underlying.priceUSD,
    totalCollateralUSD,
    maximumApyBooster,
    maxApy,
  ]);

  const maxNumberApy = useMemo(() => {
    return collateralUSD
      .multipliedBy(maximumApyBooster)
      .dividedBy(htmMarket.underlying.priceUSD)
      .toString();
  }, [collateralUSD, htmMarket.underlying.priceUSD, maximumApyBooster]);

  const totalCurrentApy = useMemo(() => {
    return new DefiUtils(currentApy)
      .plus(marketSelected.supplyAPY)
      .plus(marketRewardSelected?.userRewardsSupplyAPY)
      .plus(liquidStakingAPY)
      .toString();
  }, [
    currentApy,
    liquidStakingAPY,
    marketRewardSelected?.userRewardsSupplyAPY,
    marketSelected.supplyAPY,
  ]);

  const totalNewBoostValue = useMemo(() => {
    return new DefiUtils(newBoostValue)
      .plus(marketSelected.supplyAPY)
      .plus(marketRewardSelected?.userRewardsSupplyAPY)
      .plus(liquidStakingAPY)
      .toString();
  }, [
    liquidStakingAPY,
    marketRewardSelected?.userRewardsSupplyAPY,
    marketSelected.supplyAPY,
    newBoostValue,
  ]);

  const totalAccountStaked = useMemo(() => {
    if (inputValue === '0') {
      return `${accountStaked}`;
    }

    const percentage = new DefiUtils(collateralUSD)
      .multipliedBy(100)
      .dividedBy(totalCollateralUSD)
      .dividedBy(100)
      .toString();

    return new DefiUtils(totalStaked).multipliedBy(percentage).toString();
  }, [
    inputValue,
    collateralUSD,
    totalCollateralUSD,
    totalStaked,
    accountStaked,
  ]);

  const getProgressColor = () => {
    if (new DefiUtils(dynamicPercentageBoost).isLessThan(50)) {
      return 'text-[#E04040]';
    }
    if (new DefiUtils(dynamicPercentageBoost).isLessThan(100)) {
      return 'text-[#E4AF08]';
    }
    if (new DefiUtils(dynamicPercentageBoost).isLessThan(150)) {
      return 'text-[#37CE86]';
    }

    return 'text-[#2B8EE9]';
  };

  const getStaticPercentageBoosterTextColor = () => {
    if (
      new DefiUtils(percentageBoost).isGreaterThanOrEqualTo(100) &&
      new DefiUtils(percentageBoost).isLessThan(150)
    ) {
      return 'text-[#37CE86]'; // green
    }
    if (new DefiUtils(percentageBoost).isGreaterThanOrEqualTo(150)) {
      return 'text-[#2B8EE9]'; // blue
    }

    return 'text-[#9E70FF]';
  };

  const getProgressMaxColor = () => {
    if (new DefiUtils(dynamicPercentageBoost).isLessThan(150)) {
      return 'text-[#37CE86]';
    }

    return 'text-[#2B8EE9]';
  };

  const getProgressBgColor = () => {
    if (new DefiUtils(dynamicPercentageBoost).isLessThan(50)) {
      return 'RedProgressBar';
    }
    if (new DefiUtils(dynamicPercentageBoost).isLessThan(100)) {
      return 'YellowProgressBar';
    }
    if (new DefiUtils(dynamicPercentageBoost).isLessThan(150)) {
      return 'GreenProgressBar';
    }

    return 'BlueProgressBar';
  };

  const widthProgress = useMemo(() => {
    const result = new DefiUtils(newBoostValue)
      .dividedBy(maxApy)
      .multipliedBy(100);

    if (result.isLessThanOrEqualTo(8)) return '10';
    return result.toString();
  }, [maxApy, newBoostValue]);

  const minProgressSize = useMemo(() => {
    const m = new DefiUtils(currentApy).dividedBy(maxApy).multipliedBy(100);

    if (m.isLessThan(8)) return new DefiUtils(8);
    return m;
  }, [currentApy, maxApy]);

  const defaultColorBar = useMemo(() => {
    const apyDefi = new DefiUtils(percentageBoost);
    if (apyDefi.isGreaterThanOrEqualTo(100) && apyDefi.isLessThan(150))
      return 'GreenProgressBar';
    if (apyDefi.isGreaterThanOrEqualTo(150)) return 'BlueProgressBar';

    return 'PurpleProgressBar';
  }, [percentageBoost]);

  return (
    <div
      className={clsxm(
        'flex items-center justify-between gap-2 pr-3 desktop:gap-[18px]',
        isLiquid && 'pr-0.5',
      )}
    >
      <div
        className={clsxm(
          'flex -translate-y-[0.5px] items-center justify-between gap-1',
          'h-[32.5px] min-w-[140px]',
          'rounded-[9px]',
          'border border-[#E2DFFB] dark:border-[#3F3B76]',
          isLiquid
            ? 'h-10 min-w-[160px] p-[5.5px] lg:h-[37.06px] lg:min-w-[178px] lg:rounded-[11px]'
            : 'py-0.5 px-1 lg:h-[40px] lg:min-w-[192px] lg:rounded-[12px]',
          !md && 'min-w-[136px] h-[30px]',
        )}
      >
        <div className='flex w-fit items-center gap-1'>
          <img
            src={logo}
            alt={market}
            className={clsxm(
              'h-[15px] w-[15px] -translate-x-px',
              isLiquid ? 'h-5 w-5 lg:h-[18px] lg:w-[18px]' : 'lg:h-5 lg:w-5',
              !md && 'h-[15.45px] w-[15.45px]',
            )}
          />
          <div className='-translate-x-px -translate-y-[0.5px]'>
            <span
              className={clsxm(
                'w-full text-center text-[10px] font-semibold text-[#1F2226] dark:text-white',
                isLiquid ? 'text-[10px] lg:text-[13px]' : 'lg:text-[14px]',
                !md && 'text-[10px]',
              )}
            >
              {market}
            </span>
          </div>
        </div>
        <Hint
          containerClassName='md:mt-[0px] md:ml-[0px] ml-[-122px] mt-[-20px]'
          placement={isMdAndAbove ? 'top-center' : 'right-end'}
          content={
            <div className='max-w-[260px] leading-[12.5px] md:max-w-[228px]'>
              <Trans
                i18nKey='tooltip-4x75p'
                components={[<span className='text-[#5AF1B0]' />]}
                ns='translation'
              />
            </div>
          }
        >
          <div
            className={clsxm(
              'flex w-[65px] translate-x-px cursor-default items-center rounded-[6px] bg-[#ECEEFA]  dark:bg-[#3F3B76] lg:w-[90px] ',
              isLiquid
                ? 'md:w-[70px] w-[62px] px-[3px] py-[2px] lg:rounded-[7px] lg:w-[83.38px]'
                : 'px-1 py-1 lg:rounded-[8px]',
              !md && 'w-[64px] gap-0.5',
            )}
          >
            <img
              src={htmLogo}
              alt={market}
              className={clsxm(
                'h-[15px] w-[15px]',
                isLiquid ? 'h-5 w-5 lg:h-[18px] lg:w-[18px]' : 'lg:h-5 lg:w-5',
                !md && 'h-[15.45px] w-[15.45px]',
              )}
            />
            <div
              className={clsxm(
                'w-full text-center text-[10px] font-semibold text-[#6A6A8C] dark:text-[#FFFFFF]',
                isLiquid ? 'text-[10px] lg:text-[13px]' : 'lg:text-[14px]',
                !md && 'text-[10px]',
              )}
            >
              {nFormatter(totalAccountStaked)}
            </div>
          </div>
        </Hint>
      </div>
      <div className=''>
        <div ref={arrowsRef} className='h-[11px] w-[11px]' />
      </div>
      <div
        className={clsxm(
          'relative w-full translate-x-px cursor-default',
          isLiquid && '-translate-x-1',
        )}
      >
        <div
          className={clsxm(
            'absolute -top-[12px] md:-top-[12px] flex w-full justify-between text-[6px] font-medium text-[#7B7BA4] dark:text-[#625E8F] md:text-[8.75px] lg:-top-[14px] lg:text-[10px]',
            !md && '-top-[9px]',
          )}
        >
          {showBoostDecrease ? (
            new DefiUtils(totalNewBoostValue).isLessThan(totalMaxApy) ? (
              <Hint
                placement={isMdAndAbove ? 'top-center' : 'right-end'}
                content={
                  <div className='max-w-[260px] leading-[12.5px] md:max-w-[264px]'>
                    <Trans
                      i18nKey='tooltip-4x75j'
                      components={[
                        <span className='text-[#5AF1B0]' />,
                        <span className='text-[#E24949]' />,
                      ]}
                      ns='translation'
                    />
                  </div>
                }
              >
                <div
                  className={clsxm(
                    'w-fit font-semibold',
                    !md &&
                      'translate-y-[26px] absolute left-1/2 -translate-x-1/2',
                    md &&
                      !lg &&
                      'translate-y-[32px] absolute left-1/2 -translate-x-1/2',
                  )}
                >
                  <span className='underline'>Boost Decrease:</span>
                  <span className={clsxm(getProgressColor())}>
                    {' '}
                    {nFormatter(
                      totalNewBoostValue,
                      new DefiUtils(totalNewBoostValue).isLessThan(1) ? 3 : 2,
                    )}
                    %
                  </span>
                </div>
              </Hint>
            ) : (
              <></>
            )
          ) : (
            <div className='w-fit font-semibold'>
              <Hint
                containerClassName='md:mt-[0px] md:ml-[0px] ml-[-195px] mt-[-20px]'
                placement={isMdAndAbove ? 'top-center' : 'right-end'}
                content={
                  <div className='max-w-[260px] leading-[12.5px] md:max-w-[280px]'>
                    <Trans
                      i18nKey={
                        market == 'SEGLD' && isLiquid
                          ? 'tooltip-4x75e'
                          : 'tooltip-4x75c'
                      }
                      components={
                        market !== 'SEGLD' && isLiquid
                          ? [
                              <span className='text-[#9E70FF]' />,
                              <span className='text-[#8C80D4]' />,
                              <span className='text-[#E24949]' />,
                            ]
                          : [
                              <span className='text-[#9E70FF]' />,
                              <span className='text-[#8C80D4]' />,
                              <span className='text-[#E24949]' />,
                              <span className='text-[#006FFF]' />,
                            ]
                      }
                      ns='translation'
                    />
                  </div>
                }
              >
                <span className='underline'>Current APY:</span>{' '}
                <span
                  className={clsxm(
                    'text-[#9E70FF]',
                    getStaticPercentageBoosterTextColor(),
                  )}
                >
                  {nFormatter(
                    totalCurrentApy,
                    new DefiUtils(totalCurrentApy).isLessThan(1) ? 3 : 2,
                  )}
                  %
                </span>
              </Hint>
            </div>
          )}

          {showBoostDecrease ? (
            <div className='w-fi font-semibold'>
              <Hint
                containerClassName='md:mt-[0px] md:ml-[0px] ml-[-245px] mt-[-20px]'
                placement={isMdAndAbove ? 'top-center' : 'right-end'}
                content={
                  <div className='max-w-[260px] leading-[12.5px] md:max-w-[272px]'>
                    <Trans
                      i18nKey='tooltip-4x75c'
                      components={[
                        <span className='text-[#9E70FF]' />,
                        <span className='text-[#8C80D4]' />,
                        <span className='text-[#E24949]' />,
                      ]}
                      ns='translation'
                    />
                  </div>
                }
              >
                <div
                  className={clsxm(
                    'w-fit font-semibold whitespace-nowrap',
                    !md && 'absolute left-0 top-0',
                    md && !lg && 'absolute left-0 top-0',
                  )}
                >
                  <span>Current APY:</span>{' '}
                  <span
                    className={clsxm(
                      'text-[#9E70FF]',
                      getStaticPercentageBoosterTextColor(),
                    )}
                  >
                    {nFormatter(
                      totalCurrentApy,
                      new DefiUtils(totalCurrentApy).isLessThan(1) ? 3 : 2,
                    )}
                    %
                  </span>
                </div>
              </Hint>
            </div>
          ) : (
            <>
              {new DefiUtils(newBoostValue).isGreaterThan(0) && (
                <div className='w-fit font-semibold'>
                  {new DefiUtils(percentageBoost).isLessThanOrEqualTo(100) && (
                    <Hint
                      containerClassName='md:mt-[0px] md:ml-[0px] ml-[-220px] mt-[-20px]'
                      placement={isMdAndAbove ? 'top-center' : 'right-end'}
                      content={
                        <div className='max-w-[260px] leading-[12.5px] md:max-w-[244px]'>
                          <Trans
                            i18nKey='tooltip-4x75m'
                            components={[
                              <span className='text-[#5AF1B0]' />,
                              <span className='text-[#E24949]' />,
                            ]}
                            ns='translation'
                          />
                        </div>
                      }
                    >
                      {new DefiUtils(inputValue).isGreaterThan(0) && (
                        <div
                          className={clsxm(
                            !md &&
                              'translate-y-[26px]  absolute left-1/2 -translate-x-1/2',
                            lg1 &&
                              !lg &&
                              'translate-y-[32px] absolute left-1/2 -translate-x-1/2',
                          )}
                        >
                          <span className='underline'>New Boost:</span>
                          <span className={clsxm(getProgressColor())}>
                            {' '}
                            {nFormatter(
                              totalNewBoostValue,
                              new DefiUtils(totalNewBoostValue).isLessThan(1)
                                ? 3
                                : 2,
                            )}
                            %
                          </span>
                        </div>
                      )}
                    </Hint>
                  )}
                </div>
              )}
            </>
          )}
          <div className='w-fit font-semibold'>
            <Hint
              containerClassName='md:mt-[0px] md:ml-[0px] ml-[-290px] mt-[-20px]'
              placement={isMdAndAbove ? 'top-center' : 'right-end'}
              content={
                <div className='max-w-[260px] leading-[12.5px] md:max-w-[272px]'>
                  <Trans
                    i18nKey={
                      market == 'SEGLD' && isLiquid
                        ? 'tooltip-4x75r'
                        : 'tooltip-4x75x'
                    }
                    components={
                      market !== 'SEGLD' && isLiquid
                        ? [
                            <span className='text-[#5AF1B0]' />,
                            <span className='text-[#37CE86]' />,
                          ]
                        : [
                            <span className='text-[#37CE86]' />,
                            <span className='text-[#5AF1B0]' />,
                            <span className='text-[#006FFF]' />,
                          ]
                    }
                    ns='translation'
                  />
                </div>
              }
            >
              <span className='underline w-fit'>Max APY:</span>
              <span className={clsxm(getProgressMaxColor())}>
                {' '}
                {nFormatter(
                  totalMaxApy,
                  new DefiUtils(totalMaxApy).isLessThan(1) ? 3 : 2,
                )}
                %
              </span>
            </Hint>
          </div>
        </div>
        <div className='absolute -bottom-3 flex w-full justify-end gap-8 text-[6px] font-medium text-[#7B7BA4] dark:text-[#625E8F] md:-bottom-[12px] md:text-[8.75px] lg:-bottom-3 lg:text-[10px]'>
          {new DefiUtils(totalNewBoostValue).isGreaterThan(0) && (
            <div className='w-fit'></div>
          )}
          <div className='w-fit font-semibold'>
            {nFormatter(maxNumberApy)} HTM
          </div>
        </div>
        <div
          className={clsxm(
            'relative h-[17px] w-full lg:h-[18px]',
            isLiquid && 'h-[18px] lg:h-[16.68px]',
            !md && 'h-[15.36px]',
          )}
        >
          <div
            className={clsxm(
              'GrayProgressBar absolute top-0 left-0 flex h-[17px] w-full items-center justify-end rounded-full bg-[#B7BCE7] px-2 lg:h-[18px]',
              isLiquid && 'h-[18px] lg:h-[16.68px]',
              !md && 'h-[15.36px]',
            )}
          ></div>

          {new DefiUtils(inputValue).isGreaterThan(0) && (
            <div
              style={{
                width: `${widthProgress}%`,
              }}
              className={clsxm(
                getProgressBgColor(),
                showBoostDecrease || new DefiUtils(widthProgress).isEqualTo(100)
                  ? 'z-[100]'
                  : 'z-[10]',
                'absolute',
                'top-0',
                'left-0',
                'h-[17px] lg:h-[18px]',
                'rounded-full',
                'px-2',
                isLiquid && 'h-[18px] lg:h-[16.68px]',
                !md && 'h-[15.36px]',
              )}
            ></div>
          )}

          <div
            className={clsxm(
              `${defaultColorBar} absolute top-0 left-0 !z-20 flex h-[17px] items-center justify-end rounded-full bg-[#B7BCE7] px-2 lg:h-[18px]`,
              {
                '!rounded-[100%]': minProgressSize.isLessThan(8),
              },
              isLiquid && 'h-[18px] lg:h-[16.68px]',
              !md && 'h-[15.36px]',
            )}
            style={{
              width: `${minProgressSize.toString()}%`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressItem;
