export function CrossIcon() {
  return (
    <svg
      width='13'
      height='13'
      viewBox='0 0 13 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.17199 1.15039H5.82857C3.24488 1.15039 1.15039 3.24488 1.15039 5.82857V7.17199C1.15039 9.75568 3.24488 11.8502 5.82857 11.8502H7.17199C9.75568 11.8502 11.8502 9.75568 11.8502 7.17199V5.82857C11.8502 3.24488 9.75568 1.15039 7.17199 1.15039Z'
        stroke='#D1405B'
        strokeWidth='1.18886'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.75 8.5249L8.2749 5'
        stroke='#D1405B'
        strokeWidth='1.17497'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.75 5L8.2749 8.5249'
        stroke='#D1405B'
        strokeWidth='1.17497'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
