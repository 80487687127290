type Callback<T> = () => T;

export async function calcFunctionTime<T>(
  label: string,
  callback: Callback<T>,
) {
  const startTime = new Date();

  const data = await callback();

  const endTime = new Date();

  if (!window.time) {
    window.time = {};
  }

  window.time = {
    ...window.time,
    [label]: `${(endTime.getTime() - startTime.getTime()) / 1000} seconds`,
  };

  return data;
}
