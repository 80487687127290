import { SVGProps } from 'react';

const SearchIcon = (
  props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>
) => (
  <svg
    width={17}
    height={18}
    viewBox='0 0 17 18'
    fill='currentColor'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path d='M8.88 16H7.12C5.2614 15.908 3.50337 15.1283 2.18753 13.8125C0.871685 12.4966 0.0920086 10.7386 0 8.88L0 7.12C0.0920086 5.2614 0.871685 3.50337 2.18753 2.18753C3.50337 0.871685 5.2614 0.0920086 7.12 0L8.88 0C10.7386 0.0920086 12.4966 0.871685 13.8125 2.18753C15.1283 3.50337 15.908 5.2614 16 7.12V8.88C15.908 10.7386 15.1283 12.4966 13.8125 13.8125C12.4966 15.1283 10.7386 15.908 8.88 16ZM7.12 2C5.78919 2.08275 4.53443 2.64874 3.59158 3.59158C2.64874 4.53443 2.08275 5.78919 2 7.12V8.88C2.08275 10.2108 2.64874 11.4656 3.59158 12.4084C4.53443 13.3513 5.78919 13.9173 7.12 14H8.88C10.2108 13.9173 11.4656 13.3513 12.4084 12.4084C13.3513 11.4656 13.9173 10.2108 14 8.88V7.12C13.9173 5.78919 13.3513 4.53443 12.4084 3.59158C11.4656 2.64874 10.2108 2.08275 8.88 2H7.12Z' />
    <path d='M16 18C15.858 17.9998 15.7177 17.9694 15.5884 17.9108C15.4591 17.8522 15.3437 17.7667 15.25 17.66L12.14 14.16C12.0465 14.0627 11.9737 13.9474 11.9259 13.8211C11.8781 13.6949 11.8564 13.5602 11.8621 13.4254C11.8677 13.2905 11.9007 13.1582 11.9588 13.0363C12.017 12.9145 12.0992 12.8058 12.2006 12.7166C12.3019 12.6274 12.4203 12.5597 12.5485 12.5174C12.6767 12.4752 12.8121 12.4594 12.9466 12.4709C13.0811 12.4825 13.2119 12.5211 13.3311 12.5846C13.4502 12.648 13.5553 12.7349 13.64 12.84L16.75 16.34C16.9248 16.539 17.0135 16.7993 16.9966 17.0636C16.9798 17.328 16.8587 17.5748 16.66 17.75C16.4777 17.9108 16.2431 17.9997 16 18Z' />
  </svg>
);

export default SearchIcon;
