const QrCodeDarshboardIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width='27'
      height='27'
      viewBox='0 0 27 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='stroke-[#535367] dark:stroke-[#A5A3C6]'
    >
      <path
        d='M8.70984 3.5H18.2502C21.5848 3.5 23.6604 5.85401 23.6604 9.18529V18.1743C23.6604 21.5056 21.5848 23.8596 18.2492 23.8596H8.70984C5.37526 23.8596 3.30078 21.5056 3.30078 18.1743V9.18529C3.30078 5.85401 5.38517 3.5 8.70984 3.5Z'
        stroke='currentColor'
        strokeWidth='1.89654'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0253 11.8477H9.93472C8.69774 11.8477 7.92188 12.7237 7.92188 13.9629V17.3084C7.92188 18.5477 8.69443 19.4237 9.93472 19.4237H17.0253C18.2668 19.4237 19.0382 18.5477 19.0382 17.3084V13.9629C19.0382 12.7237 18.2668 11.8477 17.0253 11.8477Z'
        stroke='currentColor'
        strokeWidth='1.89654'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.4543 7.9375H8.50781'
        stroke='currentColor'
        strokeWidth='1.89654'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export default QrCodeDarshboardIcon;
