import classNames from 'classnames';
import { useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import useSignMultipleTransactions, {
  TX_STATUS,
} from '@/hooks/core/useSignMultipleTransactions';

import { useAppDispatch, useAppSelector } from '@/store';
import { closePopup, popupSelector } from '@/store/popup';

type OverlayProps = {
  children: React.ReactNode;
  className?: string;
};

export default function Overlay({ className, children }: OverlayProps) {
  const dispatch = useAppDispatch();

  const { data } = useAppSelector(popupSelector);
  const { cancelTransactions } = useSignMultipleTransactions();
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = () => {
    if (data?.txStatus === TX_STATUS.AWAITING_CONFIRMATION) {
      cancelTransactions();
      dispatch(closePopup());
      return;
    }

    dispatch(closePopup());
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div
      className={classNames(
        'fixed inset-0',
        'z-[100]',
        'h-screen w-screen',
        'bg-[#E9E9E93B] dark:bg-[#282741]/30',
        'flex items-center justify-center',
        'p-4 py-8',
        className,
      )}
    >
      <div ref={ref}>{children}</div>
    </div>
  );
}
