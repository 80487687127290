import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import { TxStatus } from '@/types/account';

const StatusBadge = ({
  status,
  className,
}: {
  status: TxStatus;
  className: string;
}) => {
  const { t } = useTranslation();
  const label = status === 'success' ? t('success') : t('fail');

  return (
    <button
      className={classNames(
        className,
        'text-sm font-semibold uppercase leading-tight tracking-wide',
        'rounded-lg border-2',
        'p-2 px-4',
        'text-white',
        {
          'border-[#228D58] bg-[#4AAD7C]': status == 'success',
          'border-[#C93835] bg-[#E45059]': status == 'fail',
        }
      )}
    >
      {label}
    </button>
  );
};

export default StatusBadge;
