import useMediaQuery from "@/hooks/useMediaQuery";

const PolkaWallet = () => {
  const lg = useMediaQuery('(min-width: 1024px )');
  return (
    <div className='flex flex-col items-start w-fit h-fit relative z-3 '>
      <img
        src="https://cdn.app.hatom.com/images/pages/bridge/polkadot.svg"
        alt='polka icon'
        className="w-[45px] lg:w-[63px] mb-[10.2px] lg:mb-[14px]"
      />
      <p className='w-[79px] lg:w-[100px] text-start text-white'>Polkadot-js Wallet</p>
    </div>
  )
}

export default PolkaWallet;