import { AbiRegistry } from '@multiversx/sdk-core/out';
import DefiUtils from 'defi-utils';

import priceAggregatorABI from '@/abis/price-aggregator';
import client from '@/services/blockchain/client';
import { MARKET_KEY } from '@/store/protocol';
import { MAX_CACHE_TIME, MID_CACHE_TIME, queryClient } from '@/utils/query';

export const latestRoundData = async (
  priceAggregatorAddress: string,
): Promise<
  {
    decimals: string;
    from: string;
    price: string;
    roundId: string;
    timestamp: string;
    to: string;
  }[]
> => {
  const response = await client({
    address: priceAggregatorAddress,
    method: 'latestRoundData',
    abi: AbiRegistry.create(priceAggregatorABI),
  });

  return response.length > 0
    ? // @ts-ignore
      response?.[0].items.map((item) => {
        return {
          roundId: item.fieldsByName.get('round_id').value.toString(),
          from: item.fieldsByName.get('from').value.toString(),
          to: item.fieldsByName.get('to').value.toString(),
          timestamp: item.fieldsByName.get('timestamp').value.toString(),
          price: item.fieldsByName.get('price').value.toString(),
          decimals: item.fieldsByName.get('decimals').value.toString(),
        };
      })
    : [];
};

