import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

import CheckmarkIcon from '@/components/Icons/CheckmarkIcon';

import { useAppSelector } from '@/store';
import { accountSelector } from '@/store/auth';


const useWalletAuthenticatedToast = () => {
  const { t } = useTranslation();

  const account = useAppSelector(accountSelector);

  useEffect(() => {
    if (!account.address) {
      return;
    }

    toast(t('wallet-authenticated'), {
      icon: (
        <div
          data-testid='walletAuthenticated'
          className='grid place-content-center rounded bg-green text-white'
          style={{ height: '16px', width: '16px' }}
        >
          <CheckmarkIcon width={10} height={10} />
        </div>
      ),
      id: 'wallet-authenticated',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account.address]);
};

export default useWalletAuthenticatedToast;
