import { createContext, useContext } from 'react';

import useStoreIntervalProtocolInfo from '@/contexts/IndexerContext/hooks/useStoreIntervalProtocolInfo';
import useStoreProtocolInfo from '@/contexts/IndexerContext/hooks/useStoreProtocolInfo';

const IndexerContext = createContext({});

const IndexerConsumer = IndexerContext.Consumer;

const useIndexer = () => useContext(IndexerContext);

type IndexerProviderProps = {
  children: React.ReactNode;
};

const IndexerProvider = ({ children }: IndexerProviderProps) => {
  useStoreProtocolInfo();
  useStoreIntervalProtocolInfo();

  return (
    <IndexerContext.Provider value={{}}>{children}</IndexerContext.Provider>
  );
};

export default IndexerContext;
export { IndexerConsumer, IndexerProvider, useIndexer };
