import React from 'react';

import { useAppDispatch } from '@/store/index';
import { openPopup } from '@/store/popup';
import { MARKET_KEY } from '@/store/protocol';

import { DonutAnim } from '@/sections/Liquid/Common/DonutAnim';

export const TotalStakedDonutBarChart = () => {
  const dispatch = useAppDispatch();

  return (
    <button
      className='h-4 transition-transform hover:scale-110'
      onClick={() => {
        dispatch(
          openPopup({
            name: 'current_position',
            data: {
              liquidStaking: MARKET_KEY.sEGLD,
            },
          })
        );
      }}
    >
      <DonutAnim />
    </button>
  );
};
