import { useContext } from 'react';

import { ThemeContext } from '@/contexts/ThemeContext';

const useTheme = () => {
  const { theme, setTheme } = useContext<any>(ThemeContext);

  const isDark = theme === 'dark';

  return { theme, isDark, isNight: isDark, isLight: !isDark, setTheme };
};

export default useTheme;
