import DefiUtils from 'defi-utils';
import { useMemo } from 'react';

import {
  MAX_BORROW_LIMIT_MARGIN,
  MAX_RECOMMENDED_BORROW_LIMIT_MARGIN,
} from '@/hooks/interaction/useLendInteraction';

import { useAppSelector } from '@/store/index';
import { liquidStakingAppSelector } from '@/store/liquid-staking-app';
import {
  H_TOKEN_DECIMALS,
  sEgldMarketSelector,
  sEgldUserBalanceSelector,
} from '@/store/protocol';

const useLiquidStakingHsEgldCollateral = () => {
  const sEgldMarket = useAppSelector(sEgldMarketSelector);
  const sEgldUserBalance = useAppSelector(sEgldUserBalanceSelector);

  const { borrowBalanceUSD, borrowLimitUSD } = useAppSelector(
    liquidStakingAppSelector
  );

  const collateralUnderlyingBalanceFullDecimals = useMemo(() => {
    return new DefiUtils(sEgldUserBalance.underlyingCollateralBalance)
      .toFullDecimals(sEgldMarket.underlying.decimals)
      .toString();
  }, [
    sEgldUserBalance.underlyingCollateralBalance,
    sEgldMarket.underlying.decimals,
  ]);

  const maxRemovableCollateralBalance = useMemo(() => {
    const maxRemoveCollateralUSD = new DefiUtils(borrowLimitUSD || 0).minus(
      new DefiUtils(borrowBalanceUSD || 0).dividedBy(MAX_BORROW_LIMIT_MARGIN)
    );

    const collateralEquivalentToBorrowLimitUSD = new DefiUtils(
      maxRemoveCollateralUSD.isGreaterThan(0) ? maxRemoveCollateralUSD : 0
    ).dividedBy(
      new DefiUtils(sEgldMarket.collateralFactor).dividedBy(DefiUtils.WAD)
    );

    const maxRemoveCollateralUnderlyingAmount = new DefiUtils(
      collateralEquivalentToBorrowLimitUSD
    )
      .dividedBy(sEgldMarket.underlying.priceUSD)
      .toString();

    const result = new DefiUtils(
      maxRemoveCollateralUnderlyingAmount
    ).isLessThanOrEqualTo(collateralUnderlyingBalanceFullDecimals)
      ? new DefiUtils(maxRemoveCollateralUnderlyingAmount)
          .toBasicUnits(sEgldMarket.underlying.decimals)
          .toTokens(sEgldMarket.hTokenExchangeRate)
          .toFullDecimals(H_TOKEN_DECIMALS)
          .toFixed(H_TOKEN_DECIMALS, DefiUtils.ROUND_DOWN)
      : new DefiUtils(sEgldUserBalance.collateralBalance)
          .toFullDecimals(H_TOKEN_DECIMALS)
          .toString();

    return result;
  }, [
    borrowBalanceUSD,
    borrowLimitUSD,
    collateralUnderlyingBalanceFullDecimals,
    sEgldMarket.collateralFactor,
    sEgldMarket.hTokenExchangeRate,
    sEgldMarket.underlying.priceUSD,
    sEgldMarket.underlying.decimals,
    sEgldUserBalance.collateralBalance,
  ]);

  const recommendedMaxRemovableCollateralBalance = useMemo(() => {
    const maxRemoveCollateralUSD = new DefiUtils(borrowLimitUSD || 0).minus(
      new DefiUtils(borrowBalanceUSD || 0).dividedBy(
        MAX_RECOMMENDED_BORROW_LIMIT_MARGIN
      )
    );

    const collateralEquivalentToBorrowLimitUSD = new DefiUtils(
      maxRemoveCollateralUSD.isGreaterThan(0) ? maxRemoveCollateralUSD : 0
    ).dividedBy(
      new DefiUtils(sEgldMarket.collateralFactor).dividedBy(DefiUtils.WAD)
    );

    const maxRemoveCollateralUnderlyingAmount = new DefiUtils(
      collateralEquivalentToBorrowLimitUSD
    )
      .dividedBy(sEgldMarket.underlying.priceUSD)
      .toString();

    const result = new DefiUtils(
      maxRemoveCollateralUnderlyingAmount
    ).isLessThanOrEqualTo(
      new DefiUtils(collateralUnderlyingBalanceFullDecimals)
    )
      ? new DefiUtils(maxRemoveCollateralUnderlyingAmount)
          .toBasicUnits(sEgldMarket.underlying.decimals)
          .toTokens(sEgldMarket.hTokenExchangeRate)
          .toFullDecimals(H_TOKEN_DECIMALS)
          .toFixed(H_TOKEN_DECIMALS, DefiUtils.ROUND_DOWN)
      : new DefiUtils(sEgldUserBalance.collateralBalance)
          .toFullDecimals(H_TOKEN_DECIMALS)
          .toString();

    return result;
  }, [
    borrowBalanceUSD,
    borrowLimitUSD,
    collateralUnderlyingBalanceFullDecimals,
    sEgldMarket.collateralFactor,
    sEgldMarket.hTokenExchangeRate,
    sEgldMarket.underlying.priceUSD,
    sEgldMarket.underlying.decimals,
    sEgldUserBalance.collateralBalance,
  ]);

  return {
    maxRemovableCollateralBalance,
    recommendedMaxRemovableCollateralBalance,
  };
};

export default useLiquidStakingHsEgldCollateral;
