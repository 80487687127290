import client from '@/services/index/client';

export const getLiquidStakingStakers = async ({
  liquidStakingAddress,
  zapAddress,
  cacheKey,
}: {
  liquidStakingAddress?: string;
  zapAddress?: string;
  cacheKey?: string;
}): Promise<number> => {
  const payload = {
    size: 0,
    query: {
      bool: {
        should: [
          ...(liquidStakingAddress
            ? [
                {
                  bool: {
                    must: [
                      {
                        term: {
                          receiver: liquidStakingAddress,
                        },
                      },
                      {
                        term: {
                          function: 'delegate',
                        },
                      },
                    ],
                  },
                },
              ]
            : []),
          ...(zapAddress
            ? [
                {
                  bool: {
                    must: [
                      {
                        term: {
                          receiver: zapAddress,
                        },
                      },
                      {
                        term: {
                          function: 'lendegate',
                        },
                      },
                    ],
                  },
                },
              ]
            : []),
        ],
      },
    },
    aggs: {
      unique_senders: {
        cardinality: {
          field: 'sender',
        },
      },
    },
  };

  const { data } = await client.post(`/transactions/_search`, payload);

  if (!data) {
    return 0;
  }

  return data?.aggregations?.unique_senders?.value || 0;
};
