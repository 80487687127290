
import useMediaQuery from "@/hooks/useMediaQuery";

const SubWallet = () => {
  const lg = useMediaQuery('(min-width: 1024px )');
  return (<div className='flex flex-col items-start w-fit h-fit relative z-3 '>
    <img
      src="https://cdn.app.hatom.com/images/pages/bridge/subWalletLogo.svg"
      alt='subwallet '
      className='w-[30.5px] lg:w-[42.4] mb-[9.3px] lg:mb-[13.5px]'
    />
    <p className='w-[120px] text-start text-white'>SubWallet</p>
  </div>
  )
}

export default SubWallet;