import { motion } from 'framer-motion'

import { walletItemsAnimationsTransition } from '@/components/popups/ConnectBridgeWalletPopup/components/wallets/constants';

const variants = {
  normal: {
    fill: "#A7A7A7",
  },
  hover: {
    fill: "#C9C9C9",
  },
  transition: walletItemsAnimationsTransition
}


function BridgeXPortalShape({ className, onClick }: { className?: string, onClick: () => void }) {
  return (
    <svg className={className} width="300" height="124" viewBox="0 0 300 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <motion.path
        variants={variants}
        onClick={onClick}
        d="M0.534939 90.2168C0.534923 84.7562 4.14195 79.952 9.38575 78.4285L274.396 1.43205C287.192 -2.28559 299.976 7.31318 299.976 20.6379V103.5C299.976 114.546 291.022 123.5 279.976 123.5H141.556C135.561 123.5 129.882 120.81 126.083 116.172L117.929 106.215C113.95 101.357 107.92 98.6495 101.646 98.9037L13.3077 102.483C6.33871 102.765 0.534959 97.1915 0.534939 90.2168Z"
      />
    </svg>

  );
}

export default BridgeXPortalShape;