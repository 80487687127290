import {
  AbiRegistry,
  Address,
  ArgSerializer,
  BinaryCodec,
  BooleanValue,
  ContractFunction,
  EnumValue,
  List,
  OptionValue,
  ResultsParser,
  SmartContract,
  Struct,
  TypedValue,
  VariadicValue,
} from '@multiversx/sdk-core';
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers';
import { camelCase } from 'lodash';

import { chainType, networkConfig } from '@/config/network';

const convertTypedValue = (value: TypedValue): any => {
  if (value instanceof Struct) {
    return value.getFields().reduce((result: any, field) => {
      result[camelCase(field.name)] = convertTypedValue(field.value);
      return result;
    }, {});
  } else if (value instanceof VariadicValue) {
    const items = value.getItems().map((field: any) => {
      if (field.fields) {
        return (field.fields || []).reduce(
          // @ts-ignore
          (prev, current) => ({
            ...prev,
            [current.name]: convertTypedValue(current.value),
          }),
          {},
        );
      } else {
        return field?.valueOf?.().toString();
      }
    }, {});

    return items;
  } else if (value instanceof List) {
    return value.getItems().map((value) => convertTypedValue(value));
  } else if (value instanceof EnumValue) {
    return value.name;
  } else if (value instanceof OptionValue) {
    return value.isSet() ? convertTypedValue(value.getTypedValue()) : null;
  } else if (value instanceof BooleanValue) {
    return value?.valueOf?.();
  } else {
    return value?.valueOf?.().toString();
  }
};

interface ContractQueryProps {
  address: string;
  method: string;
  abi?: AbiRegistry;
  args?: TypedValue[];
  hasConvertType?: boolean;
}

const client = async ({
  address,
  method,
  abi,
  args,
  hasConvertType,
}: ContractQueryProps) => {
  const networkProvider = new ProxyNetworkProvider(
    networkConfig[chainType].hatomGatewayAddress,
    {
      timeout: 60_000,
    },
  );

  if (typeof window !== 'undefined') {
    window.blockchainCount = (window.blockchainCount || 0) + 1;
  }

  const parser = new ResultsParser();
  const contractAddress = new Address(address);
  const contract = new SmartContract({
    address: contractAddress,
  });

  const query = contract.createQuery({
    func: new ContractFunction(method),
    args: args || [],
  });

  const queryResponse = await networkProvider.queryContract(query);

  const { values } = parser.parseUntypedQueryResponse(queryResponse);

  if (!abi) {
    return values;
  }

  const endpointDefinition = abi.getEndpoint(method);

  const serialized = new ArgSerializer({
    codec: new BinaryCodec(),
  }).buffersToValues(values, endpointDefinition.output);

  if (!hasConvertType) {
    return serialized;
  }

  const result = [];

  for (let i = 0; i < serialized.length; i++) {
    result.push(convertTypedValue(serialized[i]));
  }

  return result?.[0];
};

export default client;
