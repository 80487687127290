import Link from 'next/link';

const CustomLink: React.FC<any> = ({
  href,
  children,
  preventDefault,
  external,
  passHref,
  ...rest
}) => {
  if (preventDefault || !href || href == '#') return children;
  return (
    <Link href={href} passHref={passHref} legacyBehavior>
      <a target={external ? '_blank' : '_self'} {...rest}>
        {children}
      </a>
    </Link>
  );
};

export default CustomLink;
