import { AbiRegistry } from '@multiversx/sdk-core/out';

import interestRateModelABI from '@/abis/interest-rate-model';
import blockchainService from '@/services/blockchain';
import client from '@/services/blockchain/client';

export const getBaseRate = async (
  interestRateModelAddress: string
): Promise<string> => {
  const response = await client({
    address: interestRateModelAddress,
    method: 'getBaseRate',
    abi: AbiRegistry.create(interestRateModelABI),
  });

  return response?.[0].valueOf().toString() || '0';
};

export const getFirstSlope = async (
  interestRateModelAddress: string
): Promise<string> => {
  const response = await client({
    address: interestRateModelAddress,
    method: 'getFirstSlope',
    abi: AbiRegistry.create(interestRateModelABI),
  });

  return response?.[0].valueOf().toString() || '0';
};

export const getOptimalUtilization = async (
  interestRateModelAddress: string
): Promise<string> => {
  const response = await client({
    address: interestRateModelAddress,
    method: 'getOptimalUtilization',
    abi: AbiRegistry.create(interestRateModelABI),
  });

  return response?.[0].valueOf().toString() || '0';
};

export const getLastSlope = async (
  interestRateModelAddress: string
): Promise<string> => {
  const response = await client({
    address: interestRateModelAddress,
    method: 'getLastSlope',
    abi: AbiRegistry.create(interestRateModelABI),
  });

  return response?.[0].valueOf().toString() || '0';
};

export const getInterestRateModel = async (
  interestRateModelAddress: string
) => {
  const [baseRate, firstSlope, optimalUtilization, lastSlope] =
    await Promise.all([
      getBaseRate(interestRateModelAddress),
      getFirstSlope(interestRateModelAddress),
      getOptimalUtilization(interestRateModelAddress),
      getLastSlope(interestRateModelAddress),
    ]);

  return {
    baseRate,
    firstSlope,
    optimalUtilization,
    lastSlope,
  };
};

export const getInterestRateModels = async (marketAddresses: string[]) => {
  return Promise.all(
    Object.values(marketAddresses).map(async (address) => {
      const interestRateModelAddress =
        await blockchainService.moneyMarket.getInterestRateModel(address);

      const interestRateModel =
        await blockchainService.interestRateModel.getInterestRateModel(
          interestRateModelAddress
        );

      return {
        address,
        interestRateModel,
      };
    })
  );
};

export const getInterestRateModelsMap = async (marketAddresses: string[]) => {
  const interestRateModels = await getInterestRateModels(marketAddresses);

  return interestRateModels.reduce(
    (prev, current) => ({
      ...prev,
      [current.address]: current.interestRateModel,
    }),
    {} as Record<
      string,
      {
        baseRate: string;
        firstSlope: string;
        optimalUtilization: string;
        lastSlope: string;
      }
    >
  );
};
