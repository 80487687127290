import { Account, Address } from '@multiversx/sdk-core';
import {
  PairingTypes,
  SessionEventTypes,
  WalletConnectV2Provider,
} from '@multiversx/sdk-wallet-connect-provider';
import { captureException } from '@sentry/nextjs';
import Router from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-hot-toast';

import { Logout } from '@/hooks/auth/useLogout';

import LogoutIcon from '@/components/Icons/Logout';

import {
  clearAuthStates,
  setAccountState,
  setLoggingInState,
  setLoginInfoState,
} from '@/store/auth';
import { useAppDispatch, useAppSelector } from '@/store/index';
import {
  clearDappProvider,
  networkStateSelector,
  setNetworkState,
} from '@/store/network';
import { closePopup, openPopup } from '@/store/popup';

import { walletConnectV2ProjectId } from '@/config/envVars';
import { chainType, networkConfig } from '@/config/network';
import { getNewLoginExpiresTimestamp } from '@/utils/expiresAt';
import { optionalRedirect } from '@/utils/optionalRedirect';

import { DappCoreWCV2CustomMethodsEnum, LoginMethodsEnum } from '@/types/enums';
import { LoginHookGenericStateType, OnProviderLoginType } from '@/types/login';

function getRandomAddressFromNetwork(walletConnectAddresses: string[]) {
  return walletConnectAddresses[
    Math.floor(Math.random() * walletConnectAddresses.length)
  ];
}

const walletConnectV2RelayAddress = getRandomAddressFromNetwork(
  networkConfig[chainType].walletConnectV2RelayAddresses
);

const walletConnectV2Options = {};

const chainId = networkConfig[chainType].shortId;

const walletConnectDeepLink = networkConfig[chainType].walletConnectDeepLink;

export enum WalletConnectV2Error {
  invalidAddress = 'Invalid address',
  invalidConfig = 'Invalid WalletConnect setup',
  invalidTopic = 'Expired connection',
  sessionExpired = 'Unable to connect to existing session',
  connectError = 'Unable to connect',
  userRejected = 'User rejected connection proposal',
  userRejectedExisting = 'User rejected existing connection proposal',
  errorLogout = 'Unable to remove existing pairing',
}

export interface InitWalletConnectV2Type extends OnProviderLoginType {
  logoutRoute?: string;
  events?: string[];
}

export interface WalletConnectV2LoginHookCustomStateType {
  uriDeepLink: string;
  cancelLogin: () => void;
  connectExisting: (pairing: PairingTypes.Struct) => Promise<void>;
  removeExistingPairing: (topic: string) => Promise<void>;
  walletConnectUri?: string;
  wcPairings?: PairingTypes.Struct[];
}

export type WalletConnectV2LoginHookReturnType = [
  (loginProvider?: boolean) => void,
  LoginHookGenericStateType,
  WalletConnectV2LoginHookCustomStateType
];

export const useWalletConnectV2Login = ({
  callbackRoute,
  token: tokenToSign,
}: InitWalletConnectV2Type = {}) => {
  const dispatch = useAppDispatch();
  let token = tokenToSign;
  const { t } = useTranslation();

  const [wcUri, setWcUri] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [wcPairings, setWcPairings] = useState<
    PairingTypes.Struct[] | undefined
  >([]);

  const provider = useAppSelector(
    networkStateSelector<WalletConnectV2Provider>('dappProvider')
  );

  const providerRef = useRef<any>(provider);
  const canLoginRef = useRef<boolean>(true);

  const dappMethods: string[] = [
    DappCoreWCV2CustomMethodsEnum.erd_cancelAction,
    DappCoreWCV2CustomMethodsEnum.multiversx_cancelAction,
  ];

  const uriDeepLink = !isLoading
    ? `${walletConnectDeepLink}?wallet-connect=${encodeURIComponent(wcUri)}`
    : '';

  useEffect(() => {
    if (!tokenToSign) {
      return;
    }

    generateWcUri();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenToSign]);

  useEffect(() => {
    providerRef.current = provider;
  }, [provider]);

  useEffect(() => {
    setIsLoading(!Boolean(wcUri));
  }, [wcUri]);

  const logout = async (params?: Logout) => {
    const provider = params?.dappProvider || providerRef.current;

    if (!provider) {
      dispatch(clearAuthStates());
      dispatch(clearDappProvider());
      return;
    }

    try {
      await dispatch(setLoggingInState('pending', true));
      const topic = provider.session?.topic;

      await removeExistingPairing(topic);

      await dispatch(clearAuthStates());
      await dispatch(clearDappProvider());

      if (params?.callbackRoute) {
        if (typeof params?.redirectFn === 'function') {
          params?.redirectFn(params?.callbackRoute);
        } else if (typeof window !== 'undefined') {
          window.location.href = params?.callbackRoute;
        }
      }

      await dispatch(setLoggingInState('loggedIn', false));
      toast(t('logged-out'), {
        icon: (
          <div className='grid h-[18px] w-[18px] place-content-center rounded bg-[#E45059] text-white'>
            <LogoutIcon height={14} />
          </div>
        ),
        id: 'logged-out',
      });
      Router.reload();
    } catch (e: any) {
      captureException(e);
      toast.error(t('logout-error-retry'), { duration: 4000 });
      await dispatch(setLoggingInState('error', e?.message));
    } finally {
      await dispatch(setLoggingInState('pending', false));
    }
  };

  const handleOnLogout = async () => {
    dispatch(clearAuthStates());
    dispatch(clearDappProvider());
    toast(t('logged-out'), {
      icon: (
        <div className='grid h-[22px] w-[22px] place-content-center rounded bg-red text-white'>
          <LogoutIcon height={16} />
        </div>
      ),
      id: 'logged-out',
    });
  };

  // eslint-disable-next-line unused-imports/no-unused-vars
  const handleOnEvent = (event: SessionEventTypes['event']) => {};

  const cancelLogin = () => {
    canLoginRef.current = false;
  };

  async function handleOnLogin() {
    try {
      const provider = providerRef.current;

      if (provider == null) {
        return;
      }

      if (!canLoginRef.current) {
        try {
          await providerRef.current?.logout();
        } catch {}

        return;
      }

      const address = await provider.getAddress();
      if (!address) {
        return;
      }

      const signature = await provider.getSignature();
      const account = new Account(new Address(address));

      if (signature) {
        dispatch(setLoginInfoState('signature', signature));
      }

      dispatch(setAccountState('address', address));
      dispatch(setAccountState('balance', account.balance.toString()));
      dispatch(setAccountState('nonce', account.nonce.valueOf()));
      dispatch(setLoginInfoState('expires', getNewLoginExpiresTimestamp()));

      dispatch(setLoggingInState('loggedIn', Boolean(address)));
      dispatch(setNetworkState('dappProvider', providerRef.current));
      dispatch(
        setLoginInfoState('loginMethod', LoginMethodsEnum.walletconnect)
      );

      optionalRedirect({
        callbackRoute,
        options: { address, signature },
      });

      dispatch(closePopup());
    } catch (err) {
      dispatch(setLoggingInState('error', WalletConnectV2Error.invalidAddress));
    }
  }

  async function initiateLogin(loginProvider = true) {
    if (!walletConnectV2ProjectId || !walletConnectV2RelayAddress) {
      dispatch(setLoggingInState('error', WalletConnectV2Error.invalidConfig));
      return;
    }

    const isConnected = (await provider?.isConnected()) || false;

    if (isConnected) {
      return;
    }

    const providerHandlers = {
      onClientLogin: handleOnLogin,
      onClientLogout: handleOnLogout,
      onClientEvent: handleOnEvent,
    };

    const newProvider = new WalletConnectV2Provider(
      providerHandlers,
      chainId,
      walletConnectV2RelayAddress,
      walletConnectV2ProjectId,
      {
        metadata: {
          name: 'Hatom',
          description:
            "MultiverX's First Platform for Lending and Borrowing Crypto Assets.",
          url: window?.location?.origin || '',
          icons: [`https://cdn.app.hatom.com/hatom-96x96.png`],
        },
      }
    );

    await newProvider.init();
    canLoginRef.current = true;

    dispatch(setNetworkState('dappProvider', newProvider));
    setWcPairings(newProvider.pairings);

    providerRef.current = newProvider;
    if (loginProvider) {
      generateWcUri();
    }
  }

  async function connectExisting(pairing: PairingTypes.Struct) {
    if (!walletConnectV2RelayAddress || !walletConnectV2ProjectId) {
      dispatch(setLoggingInState('error', WalletConnectV2Error.invalidConfig));
      return;
    }
    if (!pairing || !pairing.topic) {
      dispatch(setLoggingInState('error', WalletConnectV2Error.invalidTopic));
      return;
    }

    try {
      const { approval } = await providerRef.current?.connect({
        topic: pairing.topic,
        methods: dappMethods,
      });

      try {
        await providerRef.current?.login({ approval, token });
      } catch (err) {
        dispatch(
          setLoggingInState('error', WalletConnectV2Error.userRejectedExisting)
        );
        setIsLoading(true);

        await initiateLogin();
      }
    } catch (err) {
      dispatch(setLoggingInState('error', WalletConnectV2Error.sessionExpired));
    } finally {
      setWcPairings(providerRef.current?.pairings);
    }
  }

  async function removeExistingPairing(topic: string) {
    try {
      await providerRef.current?.logout({
        topic,
      });
    } catch (err) {
      dispatch(setLoggingInState('error', WalletConnectV2Error.errorLogout));
    } finally {
      setWcPairings(providerRef.current?.pairings);
    }
  }

  async function generateWcUri() {
    if (!walletConnectV2RelayAddress || !walletConnectV2ProjectId) {
      dispatch(setLoggingInState('error', WalletConnectV2Error.invalidConfig));
      return;
    }

    try {
      const { uri, approval } = await providerRef.current?.connect({
        methods: dappMethods,
      });

      const hasUri = Boolean(uri);

      if (!hasUri) {
        return;
      }

      setWcUri(uri);

      try {
        await providerRef.current?.login({ approval, token });
      } catch (err) {
        dispatch(setLoggingInState('error', WalletConnectV2Error.userRejected));
        setIsLoading(true);

        await initiateLogin();
      }
    } catch (err) {
      dispatch(setLoggingInState('error', WalletConnectV2Error.connectError));
    }
  }

  const login = () => {
    setTimeout(() => {
      dispatch(
        openPopup({
          name: `maiarlogin`,
        })
      );
    }, 100);
  };

  return {
    login,
    logout,
    connectExisting,
    removeExistingPairing,
    uriDeepLink,
    walletConnectUri: wcUri,
    wcPairings,
    initiateLogin,
    cancelLogin,
  };
  // return [
  //   initiateLogin,
  //   {
  //     error,
  //     loginFailed,
  //     isLoading: isLoading && !loginFailed,
  //     isLoggedIn: isLoggedIn && !loginFailed
  //   },
  //   {
  //     uriDeepLink,
  //     walletConnectUri: wcUri,
  //     cancelLogin,
  //     connectExisting,
  //     removeExistingPairing,
  //     wcPairings
  //   }
  // ];
};
