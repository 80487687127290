import React from 'react';

import useTheme from '@/hooks/useTheme';

import clsxm from '@/services/clsxm';


const ResponsiveBgImage: React.FC<any> = () => {
  const { isDark } = useTheme()

  return (
    <div className={clsxm(
      'absolute top-0 -left-[0.05rem] md:-left-1 -z-10 h-fit',
      'w-[100%] md:w-[103.3%] xl:w-[103.8%]',
      'xl:dark:w-[104.1%]'
    )}>
      <img
        src={!isDark
          ? 'https://cdn.app.hatom.com/images/liquidstaking/app/card-bg-sm.svg'
          : 'https://cdn.app.hatom.com/images/liquidstaking/app/card-bg-sm-dark.svg'}
        alt='card'
        className='h-full w-full object-fill block md:hidden'
      />
      <img
        src={!isDark
          ? 'https://cdn.app.hatom.com/images/liquidstaking/app/card-bg.svg'
          : 'https://cdn.app.hatom.com/images/liquidstaking/app/card-bg-md-dark.svg'}
        alt='card'
        className='h-full w-full object-cover hidden md:block xl:hidden'
      />
      <img
        src={!isDark
          ? 'https://cdn.app.hatom.com/images/liquidstaking/app/card-bg-xl.svg'
          : 'https://cdn.app.hatom.com/images/liquidstaking/app/card-bg-xl-dark.svg'}
        alt='card'
        className='h-full w-full object-cover hidden xl:block'
      />
    </div>
  );
};

export default ResponsiveBgImage;
