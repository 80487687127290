import { gql } from 'graphql-request';

import client from '@/services/indexer/client';
import {
  formatProtocol,
  formatProtocolAccountMarketsMap,
  formatProtocolUpdated,
} from '@/services/indexer/protocol/parsers';
import type {
  QueryProtocol,
  QueryProtocolAccountMarket,
  QueryProtocolUpdated,
  ResponseProtocol,
  ResponseProtocolAccountMarket,
  ResponseProtocolUpdated,
} from '@/services/indexer/protocol/types';

export const getProtocol = async (): Promise<ResponseProtocol> => {
  const query = gql`
    query Protocol_QueryProtocol () @cacheControl(maxAge: 180) {
      queryBooster {
        id
        stakingRatioThreshold
        cooldownPeriod
        totalStaked
      }
      queryController {
        id
        maxMarketsPerAccount
      }
      queryMoneyMarket {
        address
        collateralFactor
        supported
        liquidationIncentive
        mintStatus
        borrowStatus
        liquidityCap
        borrowCap
        underlying {
          id
          decimals
          name
          symbol
        }
        hToken {
          id
          decimals
          name
          symbol
        }
        dailyStateHistory(first: 2, order: { desc: day }) {
          moneyMarketState {
            cash
            borrows
            reserves
            totalSupply
            timestamp
            supplyRatePerSecond
            borrowRatePerSecond
          }
        }
      }
      queryLendegate {
        id
      }
      queryTaoLendegate {
        id
      }
      queryDelegationContract {
        id
        totalDelegated
        apr
        serviceFee
      }
      queryLiquidStaking {
        id
        totalFee
        unbondPeriod
        minEgldToDelegate
        lsToken {
          id
        }
        undelegateTokenId {
          id
        }
        state {
          cashReserve
          protocolReserves
          totalShares
        }
      }
      queryWrappedTaoLiquidStaking {
        id
        cash
        fee
        lsTokenSupply
        lsToken {
          id
        }
      }
    }
  `;

  const variables = {};

  const queryProtocolResponse = await client.request<QueryProtocol>(
    query,
    variables,
  );

  return formatProtocol(queryProtocolResponse);
};

export const getProtocolUpdated =
  async (): Promise<ResponseProtocolUpdated> => {
    const query = gql`
    query Protocol_QueryProtocolUpdated () @cacheControl(maxAge: 10) {
      queryBooster {
        id
        stakingRatioThreshold
        cooldownPeriod
        totalStaked
      }
      queryLiquidStaking {
        state {
          cashReserve
          totalShares
        }
      }
      queryMoneyMarket {
        address
        collateralFactor
        supported
        liquidationIncentive
        mintStatus
        borrowStatus
        liquidityCap
        borrowCap
        underlying {
          id
          decimals
          name
          symbol
        }
        hToken {
          id
          decimals
          name
          symbol
        }
        dailyStateHistory(first: 2, order: { desc: day }) {
          moneyMarketState {
            cash
            borrows
            reserves
            totalSupply
            timestamp
            supplyRatePerSecond
            borrowRatePerSecond
          }
        }
      }
      queryWrappedTaoLiquidStaking {
        cash
        fee
        lsTokenSupply
      }
    }
  `;

    const variables = {};

    const queryProtocolUpdatedResponse =
      await client.request<QueryProtocolUpdated>(query, variables);

    return formatProtocolUpdated(queryProtocolUpdatedResponse);
  };

export const getProtocolAccountMarkets = async (
  address: string,
): Promise<ResponseProtocolAccountMarket[]> => {
  const query = gql`
    query Protocol_QueryAccountMarkets($address: String!)
    @cacheControl(maxAge: 10) {
      getAccount(address: $address) {
        markets {
          collateralTokens
          storedBorrowBalance
          accountBorrowIndex
          market {
            address
            underlying {
              symbol
            }
            lastState {
              borrowIndex
              timestamp
              borrowRatePerSecond
            }
          }
        }
      }
    }
  `;

  const variables = {
    address,
  };

  const queryProtocolAccountMarketResponse =
    await client.request<QueryProtocolAccountMarket>(query, variables);

  return formatProtocolAccountMarketsMap(queryProtocolAccountMarketResponse);
};
