import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { Trans } from 'next-i18next';
import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import Hint from '@/components/Hint';

import { useAppDispatch, useAppSelector } from '@/store';
import { boosterSelector } from '@/store/booster';
import { openPopup } from '@/store/popup';

import { getColor } from '@/sections/Lend/MyPosition/helpers';
// import Gauge from '@/sections/Lend/MyPosition/icons/Gauge';
import Minus from '@/sections/Lend/MyPosition/icons/Minus';
import Plus from '@/sections/Lend/MyPosition/icons/Plus';
import TriangleWarning from '@/sections/Lend/MyPosition/icons/TriangleWarning';
import clsxm from '@/services/clsxm';
import { nFormatter } from '@/utils/helpers';

const Gauge = dynamic(() => import('@/sections/Lend/MyPosition/icons/Gauge'), {
  ssr: false,
});
function HtmBoosterLiquid({ isShimmer }: { isShimmer?: boolean }) {
  const dispatch = useAppDispatch();

  const { totalStaked, percentageBoost, needRebalance } =
    useAppSelector(boosterSelector);

  const firePopup = (tab: string) => {
    dispatch(
      openPopup({
        name: 'htm_booster_liquid',
        data: {
          tab,
        },
      }),
    );
  };

  const firePositionBalancerPopup = () => {
    dispatch(
      openPopup({
        name: 'position_balancer',
      }),
    );
  };

  const totalStakedFormatted = nFormatter(totalStaked.totalStaked, 2);
  const totalStakedUsdFormatted = nFormatter(totalStaked.totalStakedUsd, 2);
  const percentageBoostFormatted = useMemo(() => {
    if (new DefiUtils(percentageBoost).isGreaterThan(200)) {
      return '200';
    }

    if (new DefiUtils(percentageBoost).isZero()) {
      return '0';
    }

    if (new DefiUtils(percentageBoost).isLessThanOrEqualTo(1)) {
      return '1';
    }

    return new DefiUtils(percentageBoost).toFixed(0);
  }, [percentageBoost]);

  return (
    <div
      className={clsxm(
        'relative flex items-center justify-between',
        'h-[59px]  md:translate-x-4  xl:translate-x-2.5 xl:translate-y-1  md:mt-2  w-[157px] md:w-[177px] xl:w-[177px] ',
      )}
    >
      {isShimmer ? (
        <Skeleton
          height={50}
          width={210}
          className={clsxm(
            '!w-[136px] !h-[40px] md:!w-[206px] md:!h-[52px] xl:!h-[53px] xl:!w-[191px] xl:!rounded-[9px]',
            ' translate-x-[2.5px] translate-y-[0.5px] md:translate-x-[-5px] md:translate-y-[2px] xl:translate-x-[0px] xl:translate-y-[2px]',
          )}
        />
      ) : (
        <>
          <div className='translate-y-[1.8px] md:translate-y-[3px] translate-x-[4px] sm:translate-x-[1.3px] md:translate-x-px'>
            <div className='relative inline-block'>
              <Hint
                autoReplace
                backgroundColor='#030303'
                className='text-[8px] md:text-[10px] inline-flex'
                content={`$${nFormatter(totalStaked.totalStakedUsd, 16)}`}
                placement='bottom-center'
                unvisible={!totalStakedUsdFormatted}
              >
                <span
                  className={classNames(
                    'cursor-default text-[10px] mb-[0px] sm:mb-[4px] md:mb-0  md:text-[14px] xl:text-[14px] font-semibold text-[#3F3B65]',
                    !needRebalance ? 'dark:text-white' : '',
                  )}
                >
                  ${totalStakedUsdFormatted}
                </span>
              </Hint>

              {needRebalance && (
                <div className='absolute right-[0px] top-[50%] translate-x-[100%] translate-y-[-50%] mt-[4.5px] sm:mt-[2.5px]'>
                  <Hint
                    offset={25}
                    backgroundColor='#030303'
                    autoReplace
                    content={
                      <div className='max-w-[307px] text-[8px] md:text-[10px] leading-[12.5px] md:max-w-[316px]'>
                        <Trans
                          i18nKey='tooltip-htm-booster'
                          components={[
                            <span className='text-[#5AF1B0]' />,
                            <span className='text-[#E24949]' />,
                            <span className='text-[#51C392]' />,
                          ]}
                          ns='translation'
                        />
                      </div>
                    }
                    placement='top-center'
                  >
                    <button onClick={firePositionBalancerPopup}>
                      <TriangleWarning className={classNames('w-[16px]', 'md:w-[18px]')} />
                    </button>
                  </Hint>
                </div>
              )}
            </div>
            <div className='flex translate-y-[-7px] w-[75px] md:w-[96px] md:dark:w-[93px] xl:dark:w-fit  xl:w-fit md:-translate-y-[3.5px] gap-[2.5px]'>
              <Hint
                autoReplace
                backgroundColor='#030303'
                content={`${nFormatter(totalStaked.totalStaked, 16)} HTM`}
                placement='bottom-center'
                unvisible={!totalStakedFormatted}
              >
                <span
                  className={classNames(
                    'cursor-default text-[7px] md:text-[10px] text-[#3F3B65] xl:text-[9px]',
                    !needRebalance ? 'dark:text-white' : '',
                  )}
                >
                  {totalStakedFormatted} HTM
                </span>
              </Hint>
              <Hint
                autoReplace
                content={
                  <div className='max-w-[340px] text-[8px] md:text-[10px] leading-[12.5px] md:max-w-[358px]'>
                    <Trans
                      i18nKey='tooltip-p6mu'
                      components={[
                        <span className='text-[#5AF1B0]' />,
                        <span className='text-[#E24949]' />,
                        <span className='text-[#51C392]' />,
                      ]}
                      ns='translation'
                    />
                  </div>
                }
              >
                <div
                  className={clsxm(
                    'h-[8px] md:h-[10px] translate-y-[9px] md:translate-y-[7px] cursor-default rounded-[3px] px-0.5 text-[6px] md:text-[8px] text-white',
                    getColor(Number(percentageBoost) || 0),
                  )}
                >
                  {nFormatter(percentageBoost, 0)}%
                </div>
              </Hint>
            </div>
          </div>
          <div
            className={classNames(
              'relative scale-75 md:scale-110 xl:scale-100 translate-x-[-8px] md:translate-x-[14px] translate-y-[1px] md:translate-y-[3px]',
            )}
          >
            <Gauge
              className='!w-[84px]'
              percentage={Number(percentageBoostFormatted) || 0}
              onClick={() => firePopup('stake')}
            />

            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
              className={clsxm(
                'absolute  right-[9px] bottom-[2.5px] md:bottom-[5.2px] xl:bottom-[2.3px]',
                'flex items-center justify-center',
                'h-[10.5px] w-[10.5px] rounded-[3.76px] bg-[#E4E4FD]',
                'hover:border-[0.2px] hover:border-[#359FFF]',
              )}
              onClick={() => firePopup('stake')}
            >
              <Plus className='md:w-[7px] xl:w-[8px] md:h-[7px] xl:h-[8.6px]' />
            </motion.button>
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
              className={clsxm(
                'absolute left-[10.2px] md:left-[9.2px] xl:left-[10.2px] bottom-[2.5px] md:bottom-[5.2px] xl:bottom-[2.3px]',
                'flex items-center justify-center',
                'h-[10.5px] w-[10.5px] rounded-[3.76px] bg-[#E4E4FD]',
                'hover:border-[0.2px] hover:border-[#EB1261]',
              )}
              onClick={() => firePopup('unstake')}
            >
              <Minus className='md:w-[7px] xl:w-[8px] md:h-[7px] xl:h-[8.6px]' />
            </motion.button>
          </div>
        </>
      )}
    </div>
  );
}

export default HtmBoosterLiquid;
