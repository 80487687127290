/* eslint-disable react-hooks/exhaustive-deps */
import {
  Address,
  AddressType,
  AddressValue,
  BigIntType,
  BigUIntValue,
  BooleanValue,
  List,
  ListType,
  TokenTransfer,
  U32Value,
} from '@multiversx/sdk-core';
import DefiUtils from 'defi-utils';

import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';

import { useAppSelector } from '@/store/index';
import {
  H_TOKEN_DECIMALS,
  MARKET_KEY,
  nativeMarketSelector,
  protocolSelector,
} from '@/store/protocol';
import { accountSelector } from '@/store/auth';
import logger from '@/utils/logger';

export enum MONEY_MARKET_METHOD {
  MINT = 'mint', // Supply Liquidity and receive H-Tokens in exchange
  REDEEM = 'redeem', // Withdraw Liquidity and receive underlying tokens in exchange of HTokens
  BORROW = 'borrow', // Get Underlying Tokens as collateral provided in H-Tokens
  REPAY_BORROW = 'repayBorrow', // Repay Underlying tokens borrowed,
  MINT_AND_ENTER_MARKET = 'mintAndEnterMarket',
}

export enum CONTROLLER_METHOD {
  CLAIM_REWARDS = 'claimRewards',
  ENTER_MARKETS = 'enterMarkets', // Add Collateral
  EXIT_MARKET = 'exitMarket', // Remove Collateral
  REMOVE_ACCOUNT_MARKET = 'removeAccountMarket',
}

export enum BOOSTER_METHOD {
  STAKE = 'stake',
  UNSTAKE = 'unstake',
  CLAIM_REWARDS = 'claimRewards',
  CLAIM = 'claim',
  REBALANCE_PORTFOLIO = 'rebalancePortfolio',
}

export const MARKET_ACTION_GAS_LIMIT = 200_000_000;

// minimum safe EGLD margin to consider transaction fees
export const MIN_EGLD_FEE = 5_000_000_000_000_000;

export const MAX_RECOMMENDED_BORROW_LIMIT_MARGIN = 0.8;
export const MAX_BORROW_LIMIT_MARGIN = 0.9999;

const useLendInteraction = () => {
  const { controller, markets, marketsInteractedAmount, booster } =
    useAppSelector(protocolSelector);
  const nativeMarket = useAppSelector(nativeMarketSelector);
  const { address: accountAddress } = useAppSelector(accountSelector);

  const { buildTransaction } = useSignMultipleTransactions();

  const claimRewards = (boost: boolean, minBoostedRewardsOut?: string) => {
    return buildTransaction({
      smartContractAddress: controller.address,
      func: CONTROLLER_METHOD.CLAIM_REWARDS,
      group: 'market',
      args: [
        new BooleanValue(boost),
        new BooleanValue(true),
        new BooleanValue(true),
        new List(new ListType(new AddressType()), []),
        new List(new ListType(new AddressType()), []),
        ...(!minBoostedRewardsOut ||
        new DefiUtils(minBoostedRewardsOut).isLessThanOrEqualTo(0)
          ? []
          : [new BigUIntValue(minBoostedRewardsOut)]),
      ],
    });
  };

  const supplyLiquidity = ({
    tokenKey,
    amountAsBigInteger,
  }: {
    tokenKey: string;
    amountAsBigInteger: string;
  }) => {
    const isEsdtToken = tokenKey !== nativeMarket.underlying.symbol;
    const { address, underlying } = markets[tokenKey as MARKET_KEY];

    return buildTransaction({
      smartContractAddress: address,
      func: MONEY_MARKET_METHOD.MINT,
      group: 'market',
      isEsdtToken,
      isPayable: true,
      ...(isEsdtToken
        ? {
            token: {
              tokenIdentifier: underlying.id,
              amount: amountAsBigInteger,
              numDecimals: underlying.decimals,
              isFromBigInteger: true,
            },
          }
        : {
            value: amountAsBigInteger,
            isFromBigInteger: true,
          }),
    });
  };

  const getAmountForAddCollateral = ({
    isMax,
    maxHTokenBalance,
    hTokenEquivalentAmount,
  }: {
    isMax?: boolean;
    maxHTokenBalance: string;
    hTokenEquivalentAmount: string;
  }) => {
    if (isMax) {
      return maxHTokenBalance;
    }

    if (hTokenEquivalentAmount === '0') {
      return '1';
    }

    if (
      new DefiUtils(hTokenEquivalentAmount).isGreaterThanOrEqualTo(
        maxHTokenBalance,
      )
    ) {
      return maxHTokenBalance;
    }

    return hTokenEquivalentAmount;
  };

  const addCollateral = ({
    tokenKey,
    amountAsBigNumber,
    isMax,
    maxHTokenBalance,
    isUnderlyingAmount = true,
  }: {
    tokenKey: string;
    amountAsBigNumber: string;
    isMax?: boolean;
    isUnderlyingAmount?: boolean;
    maxHTokenBalance: string;
  }) => {
    if (isUnderlyingAmount) {
      const { hTokenExchangeRate, hToken } = markets[tokenKey as MARKET_KEY];

      const hTokenEquivalentAmount = new DefiUtils(amountAsBigNumber)
        .toTokens(hTokenExchangeRate)
        .toFixed(0, DefiUtils.ROUND_FLOOR);

      const tokenAmount = getAmountForAddCollateral({
        isMax,
        maxHTokenBalance,
        hTokenEquivalentAmount,
      });

      return buildTransaction({
        smartContractAddress: controller.address,
        func: CONTROLLER_METHOD.ENTER_MARKETS,
        group: 'market',
        isEsdtToken: true,
        isPayable: true,
        token: {
          tokenIdentifier: hToken.id,
          amount: tokenAmount,
          numDecimals: H_TOKEN_DECIMALS,
          isFromBigInteger: true,
        },
      });
    }

    const { hToken } = markets[tokenKey as MARKET_KEY];

    const tokenAmount = getAmountForAddCollateral({
      isMax,
      maxHTokenBalance,
      hTokenEquivalentAmount: amountAsBigNumber,
    });

    return buildTransaction({
      smartContractAddress: controller.address,
      func: CONTROLLER_METHOD.ENTER_MARKETS,
      group: 'market',
      isEsdtToken: true,
      isPayable: true,
      token: {
        tokenIdentifier: hToken.id,
        amount: tokenAmount,
        numDecimals: H_TOKEN_DECIMALS,
        isFromBigInteger: true,
      },
    });
  };

  const removeCollateral = ({
    tokenKey,
    amountAsHTokenBigNumber,
    isMax,
  }: {
    tokenKey: string;
    amountAsHTokenBigNumber: string;
    isMax: boolean;
  }) => {
    const { address } = markets[tokenKey as MARKET_KEY];

    const moneyMarketExitAddress = new Address(address);

    return buildTransaction({
      smartContractAddress: controller.address,
      func: CONTROLLER_METHOD.EXIT_MARKET,
      args: [
        new AddressValue(moneyMarketExitAddress),
        ...(isMax
          ? []
          : [
              new BigUIntValue(
                amountAsHTokenBigNumber === '0' ? '1' : amountAsHTokenBigNumber,
              ),
            ]),
      ],
      group: 'market',
      isEsdtToken: true,
      isPayable: false,
      gasLimitArgs: [marketsInteractedAmount],
    });
  };

  const withdrawLiquidity = ({
    tokenKey,
    amountAsBigNumber,
    isUnderlyingAmount,
  }: {
    tokenKey: string;
    amountAsBigNumber: string;
    isUnderlyingAmount: boolean;
  }) => {
    const { hTokenExchangeRate, hToken, address } =
      markets[tokenKey as MARKET_KEY];

    if (isUnderlyingAmount) {
      const hTokenAmount = new DefiUtils(amountAsBigNumber)
        .toTokens(hTokenExchangeRate)
        .toFixed(0, DefiUtils.ROUND_DOWN);

      return buildTransaction({
        smartContractAddress: address,
        func: MONEY_MARKET_METHOD.REDEEM,
        args: [new BigUIntValue(amountAsBigNumber)],
        group: 'market',
        isEsdtToken: true,
        isPayable: true,
        token: {
          tokenIdentifier: hToken.id,
          amount: hTokenAmount === '0' ? '1' : hTokenAmount,
          numDecimals: H_TOKEN_DECIMALS,
          isFromBigInteger: true,
        },
      });
    }

    return buildTransaction({
      smartContractAddress: address,
      func: MONEY_MARKET_METHOD.REDEEM,
      group: 'market',
      isEsdtToken: true,
      isPayable: true,
      token: {
        tokenIdentifier: hToken.id,
        amount: amountAsBigNumber === '0' ? '1' : amountAsBigNumber,
        numDecimals: H_TOKEN_DECIMALS,
        isFromBigInteger: true,
      },
    });
  };

  const borrow = ({
    tokenKey,
    amountAsBigInteger,
  }: {
    tokenKey: string;
    amountAsBigInteger: string;
  }) => {
    const isEsdtToken = tokenKey !== nativeMarket.underlying.symbol;
    const { underlying, address } = markets[tokenKey as MARKET_KEY];

    const value = isEsdtToken
      ? TokenTransfer.fungibleFromBigInteger(
          underlying.id,
          amountAsBigInteger,
          underlying.decimals,
        ).valueOf()
      : TokenTransfer.egldFromBigInteger(amountAsBigInteger).valueOf();

    return buildTransaction({
      smartContractAddress: address,
      func: MONEY_MARKET_METHOD.BORROW,
      args: [new BigUIntValue(value)],
      group: 'market',
      gasLimitArgs: [marketsInteractedAmount],
      isEsdtToken,
      isPayable: false,
    });
  };

  const repayBorrow = ({
    tokenKey,
    amountAsBigInteger,
  }: {
    tokenKey: string;
    amountAsBigInteger: string;
  }) => {
    const { underlying, address } = markets[tokenKey as MARKET_KEY];
    const isEsdtToken = tokenKey !== nativeMarket.underlying.symbol;

    return buildTransaction({
      smartContractAddress: address,
      func: MONEY_MARKET_METHOD.REPAY_BORROW,
      group: 'market',
      isEsdtToken,
      isPayable: true,
      ...(isEsdtToken
        ? {
            token: {
              tokenIdentifier: underlying.id,
              amount: amountAsBigInteger,
              numDecimals: underlying.decimals,
              isFromBigInteger: true,
            },
          }
        : {
            value: amountAsBigInteger,
            isFromBigInteger: true,
          }),
    });
  };

  const supplyLiquidityAndAddCollateral = ({
    tokenKey,
    amountAsBigNumber,
  }: {
    tokenKey: string;
    amountAsBigNumber: string;
  }) => {
    const { address, underlying } = markets[tokenKey as MARKET_KEY];

    const isEsdtToken = tokenKey !== nativeMarket.underlying.symbol;

    return buildTransaction({
      smartContractAddress: address,
      func: MONEY_MARKET_METHOD.MINT_AND_ENTER_MARKET,
      group: 'market',
      isEsdtToken,
      isPayable: true,
      ...(isEsdtToken
        ? {
            token: {
              tokenIdentifier: underlying.id,
              amount: amountAsBigNumber,
              numDecimals: underlying.decimals,
              isFromBigInteger: true,
            },
          }
        : {
            value: amountAsBigNumber,
            isFromBigInteger: true,
          }),
    });
  };

  const stakeHtm = ({ amountAsBigNumber }: { amountAsBigNumber: string }) => {
    const safeAmountAsBigNumber = new DefiUtils(amountAsBigNumber).toFixed(
      0,
      DefiUtils.ROUND_DOWN,
    );

    return buildTransaction({
      smartContractAddress: booster.address,
      func: BOOSTER_METHOD.STAKE,
      group: 'booster',
      isEsdtToken: true,
      isPayable: true,
      token: {
        tokenIdentifier: markets.HTM.underlying.id,
        amount: safeAmountAsBigNumber,
        numDecimals: markets.HTM.underlying.decimals,
        isFromBigInteger: true,
      },
      args: [
        new List(new ListType(new AddressType()), []),
        new List(new ListType(new BigIntType()), []),
      ],
    });
  };

  const unstakeHtm = ({ amountAsBigNumber }: { amountAsBigNumber: string }) => {
    const safeAmountAsBigNumber = new DefiUtils(amountAsBigNumber).toFixed(
      0,
      DefiUtils.ROUND_DOWN,
    );

    return buildTransaction({
      smartContractAddress: booster.address,
      func: BOOSTER_METHOD.UNSTAKE,
      group: 'booster',
      isEsdtToken: false,
      isPayable: true,
      token: {
        tokenIdentifier: markets.HTM.underlying.id,
        amount: safeAmountAsBigNumber,
        numDecimals: markets.HTM.underlying.decimals,
        isFromBigInteger: true,
      },
      args: [
        new BigUIntValue(safeAmountAsBigNumber),
        new List(new ListType(new AddressType()), []),
        new List(new ListType(new BigIntType()), []),
      ],
    });
  };

  const claimBoosterRewards = (
    boost: boolean,
    moneyMarketsAddressList: string[] = [],
    minBoostedRewardsOut?: string,
  ) => {
    const boostArg = new BooleanValue(boost);
    const moneyMarketsArg = new List(
      new ListType(new AddressType()),
      moneyMarketsAddressList.map(
        (address) => new AddressValue(new Address(address)),
      ),
    );
    const accountsArg = new List(new ListType(new AddressType()), []);
    const minBoostedRewardsOutArg =
      !minBoostedRewardsOut ||
      new DefiUtils(minBoostedRewardsOut).isLessThanOrEqualTo(0)
        ? []
        : [new BigUIntValue(minBoostedRewardsOut)];

    return buildTransaction({
      smartContractAddress: booster.address,
      func: BOOSTER_METHOD.CLAIM_REWARDS,
      group: 'booster',
      args: [
        boostArg,
        moneyMarketsArg,
        accountsArg,
        ...minBoostedRewardsOutArg,
      ],
    });
  };

  const claimBooster = (claimId: number) => {
    return buildTransaction({
      smartContractAddress: booster.address,
      func: BOOSTER_METHOD.CLAIM,
      group: 'booster',
      args: [new U32Value(claimId)],
    });
  };

  const rebalancePortfolio = () => {
    return buildTransaction({
      smartContractAddress: booster.address,
      func: BOOSTER_METHOD.REBALANCE_PORTFOLIO,
      group: 'booster',
      args: [
        new List(new ListType(new AddressType()), []),
        new List(new ListType(new BigIntType()), []),
      ],
    });
  };

  const removeAccountMarket = (moneyMarketAddress: string) => {
    return buildTransaction({
      smartContractAddress: controller.address,
      func: CONTROLLER_METHOD.REMOVE_ACCOUNT_MARKET,
      group: 'market',
      args: [new AddressValue(new Address(moneyMarketAddress))],
    });
  };

  return {
    supplyLiquidityAndAddCollateral,
    supplyLiquidity,
    addCollateral,
    removeCollateral,
    withdrawLiquidity,
    borrow,
    repayBorrow,
    claimRewards,
    stakeHtm,
    unstakeHtm,
    claimBoosterRewards,
    claimBooster,
    rebalancePortfolio,
    removeAccountMarket,
  };
};

export default useLendInteraction;
