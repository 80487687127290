import DefiUtils from 'defi-utils';

import { formatNumber } from '@/utils/helpers';

const parseSmallNumber = (
  val: string | DefiUtils | number,
  isUSD?: boolean
) => {
  const defiVal = new DefiUtils(val).abs();
  if (defiVal.isGreaterThan(0) && defiVal.isLessThanOrEqualTo(0.01)) {
    if (isUSD) return '<$0.01';
    return '<0.01';
  }

  if (isUSD) return `${formatNumber(new DefiUtils(val).abs().toString(), 2)}`;
  return `${formatNumber(new DefiUtils(val).abs().toString(), 2)}`;
};

export { parseSmallNumber };
