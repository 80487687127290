import classNames from 'classnames';
import React from 'react';

import { H_MARKET_KEY, MARKET_KEY } from '@/store/protocol';

export const PrettyToken: React.FC<any> = ({ token }) => {
  return (
    <span
      className={classNames({
        'text-hsegld': [H_MARKET_KEY.HsEGLD, H_MARKET_KEY.HsWTAO].includes(
          token,
        ),
        'text-segld': [MARKET_KEY.sEGLD, MARKET_KEY.sWTAO].includes(token),
      })}
    >
      {token
        .replace(/SW/g, (match: string) => match.toLowerCase())
        .replace(/S/g, (match: string) => match.toLowerCase())}
    </span>
  );
};
