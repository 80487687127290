import React from 'react';

export const InputButton: React.FC<any> = ({ onClick, children, ...props }) => {
  return (
    <button
      onClick={onClick}
      className='whitespace-nowrap rounded-[14px] px-2.5 py-1.5 font-hass text-[14px] capitalize text-[#535367] transition-colors hover:bg-[#E0E1FF] hover:text-[#006FFF] dark:text-[#625E8F] dark:hover:bg-[#403C7C] dark:hover:text-[#006FFF] sm:rounded-[16px] sm:text-[16px]'
      {...props}
    >
      {children}
    </button>
  );
};
