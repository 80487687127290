const priceAggregatorABI = {
  buildInfo: {
    rustc: {
      version: '1.70.0-nightly',
      commitHash: '5e1d3299a290026b85787bc9c7e72bcc53ac283f',
      commitDate: '2023-03-31',
      channel: 'Nightly',
      short: 'rustc 1.70.0-nightly (5e1d3299a 2023-03-31)',
    },
    contractCrate: {
      name: 'price-aggregator',
      version: '0.0.0',
    },
    framework: {
      name: 'multiversx-sc',
      version: '0.39.8',
    },
  },
  name: 'PriceAggregator',
  constructor: {
    inputs: [
      {
        name: 'staking_token',
        type: 'EgldOrEsdtTokenIdentifier',
      },
      {
        name: 'staking_amount',
        type: 'BigUint',
      },
      {
        name: 'slash_amount',
        type: 'BigUint',
      },
      {
        name: 'slash_quorum',
        type: 'u32',
      },
      {
        name: 'min_submissions_per_round',
        type: 'u32',
      },
      {
        name: 'decimals',
        type: 'u8',
      },
      {
        name: 'round_duration',
        type: 'u64',
      },
      {
        name: 'opt_admin',
        type: 'optional<Address>',
        multi_arg: true,
      },
      {
        name: 'oracles',
        type: 'variadic<Address>',
        multi_arg: true,
      },
    ],
    outputs: [],
  },
  endpoints: [
    {
      docs: ['Returns the current admin address.'],
      name: 'getAdmin',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      docs: ['Returns the current pending admin address, if there is one.', ''],
      name: 'getPendingAdmin',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Option<Address>',
        },
      ],
    },
    {
      docs: [
        'Sets the pending admin address to the given address.',
        '',
        '# Arguments:',
        '',
        '- `new_pending_admin` - The new pending admin address.',
        '',
      ],
      name: 'setPendingAdmin',
      mutability: 'mutable',
      inputs: [
        {
          name: 'pending_admin',
          type: 'Address',
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Attempts to accept the pending admin, which must be set first using the `set_pending_admin` endpoint.',
      ],
      name: 'acceptAdmin',
      mutability: 'mutable',
      inputs: [],
      outputs: [],
    },
    {
      name: 'pause',
      mutability: 'mutable',
      inputs: [],
      outputs: [],
    },
    {
      name: 'unpause',
      mutability: 'mutable',
      inputs: [],
      outputs: [],
    },
    {
      name: 'isPaused',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'bool',
        },
      ],
    },
    {
      name: 'stake',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [],
      outputs: [],
    },
    {
      name: 'unstake',
      mutability: 'mutable',
      inputs: [
        {
          name: 'unstake_amount',
          type: 'BigUint',
        },
      ],
      outputs: [],
    },
    {
      name: 'voteSlashMember',
      mutability: 'mutable',
      inputs: [
        {
          name: 'member_to_slash',
          type: 'Address',
        },
      ],
      outputs: [],
    },
    {
      name: 'slashMember',
      mutability: 'mutable',
      inputs: [
        {
          name: 'member_to_slash',
          type: 'Address',
        },
      ],
      outputs: [],
    },
    {
      name: 'submit',
      mutability: 'mutable',
      inputs: [
        {
          name: 'from',
          type: 'bytes',
        },
        {
          name: 'to',
          type: 'bytes',
        },
        {
          name: 'submission_timestamp',
          type: 'u64',
        },
        {
          name: 'price',
          type: 'BigUint',
        },
      ],
      outputs: [],
    },
    {
      name: 'submitBatch',
      mutability: 'mutable',
      inputs: [
        {
          name: 'submissions',
          type: 'variadic<multi<bytes,bytes,u64,BigUint>>',
          multi_arg: true,
        },
      ],
      outputs: [],
    },
    {
      name: 'latestRoundData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'variadic<PriceFeed>',
          multi_result: true,
        },
      ],
    },
    {
      name: 'latestPriceFeed',
      mutability: 'readonly',
      inputs: [
        {
          name: 'from',
          type: 'bytes',
        },
        {
          name: 'to',
          type: 'bytes',
        },
      ],
      outputs: [
        {
          type: 'u32',
        },
        {
          type: 'bytes',
        },
        {
          type: 'bytes',
        },
        {
          type: 'u64',
        },
        {
          type: 'BigUint',
        },
        {
          type: 'u8',
        },
      ],
    },
    {
      name: 'latestPriceFeedOptional',
      mutability: 'readonly',
      inputs: [
        {
          name: 'from',
          type: 'bytes',
        },
        {
          name: 'to',
          type: 'bytes',
        },
      ],
      outputs: [
        {
          type: 'optional<multi<u32,bytes,bytes,u64,BigUint,u8>>',
          multi_result: true,
        },
      ],
    },
    {
      name: 'currentTimestamp',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'u64',
        },
      ],
    },
    {
      name: 'setSubmissionCount',
      mutability: 'mutable',
      inputs: [
        {
          name: 'min_submissions_per_round',
          type: 'u32',
        },
      ],
      outputs: [],
    },
    {
      name: 'setRoundDuration',
      mutability: 'mutable',
      inputs: [
        {
          name: 'round_duration',
          type: 'u64',
        },
      ],
      outputs: [],
    },
    {
      name: 'getSubmissionCount',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'u64',
        },
      ],
    },
    {
      name: 'getRoundDuration',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'u64',
        },
      ],
    },
    {
      name: 'decimals',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'u8',
        },
      ],
    },
    {
      name: 'addOracles',
      mutability: 'mutable',
      inputs: [
        {
          name: 'oracles',
          type: 'variadic<Address>',
          multi_arg: true,
        },
      ],
      outputs: [],
    },
    {
      docs: [
        'Also receives submission count, so the owner does not have to update it manually with setSubmissionCount before this call',
      ],
      name: 'removeOracles',
      mutability: 'mutable',
      inputs: [
        {
          name: 'min_submissions_per_round',
          type: 'u32',
        },
        {
          name: 'oracles',
          type: 'variadic<Address>',
          multi_arg: true,
        },
      ],
      outputs: [],
    },
    {
      name: 'getOracles',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'variadic<Address>',
          multi_result: true,
        },
      ],
    },
  ],
  events: [
    {
      docs: ['Event emitted when the pending admin is updated.'],
      identifier: 'new_pending_admin_event',
      inputs: [
        {
          name: 'pending_admin',
          type: 'Address',
          indexed: true,
        },
      ],
    },
    {
      docs: ['Event emitted when the admin is updated.'],
      identifier: 'new_admin_event',
      inputs: [
        {
          name: 'admin',
          type: 'Address',
          indexed: true,
        },
      ],
    },
    {
      identifier: 'new_submission_event',
      inputs: [
        {
          name: 'token_pair',
          type: 'TokenPair',
          indexed: true,
        },
        {
          name: 'submitter',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'price',
          type: 'BigUint',
          indexed: true,
        },
        {
          name: 'round_id',
          type: 'u64',
          indexed: true,
        },
      ],
    },
    {
      identifier: 'new_price_event',
      inputs: [
        {
          name: 'token_pair',
          type: 'TokenPair',
          indexed: true,
        },
        {
          name: 'price',
          type: 'BigUint',
          indexed: true,
        },
        {
          name: 'round_id',
          type: 'u64',
          indexed: true,
        },
      ],
    },
    {
      identifier: 'new_round_event',
      inputs: [
        {
          name: 'token_pair',
          type: 'TokenPair',
          indexed: true,
        },
        {
          name: 'round_id',
          type: 'u64',
          indexed: true,
        },
      ],
    },
    {
      identifier: 'new_oracle_event',
      inputs: [
        {
          name: 'oracle',
          type: 'Address',
          indexed: true,
        },
      ],
    },
    {
      identifier: 'removed_oracle_event',
      inputs: [
        {
          name: 'oracle',
          type: 'Address',
          indexed: true,
        },
      ],
    },
    {
      identifier: 'new_min_submissions_per_round_event',
      inputs: [
        {
          name: 'min_submissions_per_round',
          type: 'u64',
          indexed: true,
        },
      ],
    },
    {
      identifier: 'new_round_duration_event',
      inputs: [
        {
          name: 'round_duration',
          type: 'u64',
          indexed: true,
        },
      ],
    },
    {
      identifier: 'paused_event',
      inputs: [],
    },
    {
      identifier: 'unpaused_event',
      inputs: [],
    },
  ],
  hasCallback: false,
  types: {
    PriceFeed: {
      type: 'struct',
      fields: [
        {
          name: 'round_id',
          type: 'u32',
        },
        {
          name: 'from',
          type: 'bytes',
        },
        {
          name: 'to',
          type: 'bytes',
        },
        {
          name: 'timestamp',
          type: 'u64',
        },
        {
          name: 'price',
          type: 'BigUint',
        },
        {
          name: 'decimals',
          type: 'u8',
        },
      ],
    },
    TokenPair: {
      type: 'struct',
      fields: [
        {
          name: 'from',
          type: 'bytes',
        },
        {
          name: 'to',
          type: 'bytes',
        },
      ],
    },
  },
};

export default priceAggregatorABI;
