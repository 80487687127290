import { domAnimation, LazyMotion, m } from 'framer-motion';

const LedgerConnectAnimation = () => {
  const headGroupProps = {
    animate: {
      x: [28, 0, 0, 28],
      transition: {
        duration: 3,
        times: [1.25, 0.5, 1.25],
        repeat: Infinity,
        repeatDelay: 2,
        easings: ['easeInOut', 'easeInOut', 'easeInOut'],
      },
    },
  };

  const lampProps = (i = 0, fill = '#8EE7CF', lightFill = '#FFFF00') => ({
    fill,
    animate: {
      fill: lightFill,
      transition: {
        delay: 3 + i * 0.1,
        repeatDelay: 1.9,
        repeat: Infinity,
        repeatType: 'reverse' as any,
      },
    },
  });

  return (
    <svg
      width='231'
      height='98'
      viewBox='0 0 231 99'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='ledger-connect'>
        <LazyMotion features={domAnimation}>
          <m.g {...headGroupProps} id='head'>
            <rect
              id='h5'
              y='13.9995'
              width='65'
              height='2'
              fill='url(#paint14_linear_149_3548)'
            />
            <rect
              id='h4'
              y='17.9995'
              width='65'
              height='2'
              fill='url(#paint15_linear_149_3548)'
            />
            <g id='h3'>
              <rect x='101' y='11.9995' width='10' height='10' fill='white' />
              <rect
                x='101'
                y='11.9995'
                width='10'
                height='10'
                fill='url(#paint16_linear_149_3548)'
              />
              <rect
                x='101'
                y='11.9995'
                width='10'
                height='10'
                fill='url(#paint17_linear_149_3548)'
                fillOpacity='0.2'
              />
              <rect
                x='101'
                y='11.9995'
                width='10'
                height='10'
                fill='url(#paint18_linear_149_3548)'
              />
              <rect
                x='101'
                y='11.9995'
                width='10'
                height='10'
                fill='url(#paint19_linear_149_3548)'
                fillOpacity='0.8'
              />
            </g>
            <rect
              id='h2'
              x='65'
              y='13'
              width='11'
              height='8'
              rx='1'
              fill='url(#paint20_linear_149_3548)'
            />
            <path
              id='h1'
              d='M77 10H100V24H77C75.8954 24 75 23.1046 75 22V12C75 10.8954 75.8954 10 77 10Z'
              stroke='url(#paint21_linear_149_3548)'
              strokeWidth='2'
            />
          </m.g>
        </LazyMotion>
        <g id='body'>
          <rect
            id='b7'
            x='127'
            y='1.00049'
            width='90'
            height='32'
            rx='3'
            fill='url(#paint0_linear_149_3548)'
          />
          <path
            id='b6'
            d='M127 15L213.005 11.1346C214.659 11.0603 216.061 12.3408 216.136 13.9954L216.857 29.8638C216.935 31.5723 215.571 33 213.861 33H130C128.343 33 127 31.6569 127 30V15Z'
            fill='url(#paint1_linear_149_3548)'
          />
          <rect
            id='b5'
            x='143.5'
            y='8.50049'
            width='48'
            height='17'
            rx='1.5'
            fill='url(#paint2_linear_149_3548)'
            stroke='url(#paint3_linear_149_3548)'
          />
          <rect
            id='b4'
            x='154'
            y='15.0005'
            width='27'
            height='5'
            rx='1'
            fill='#375CAB'
          />
          <g id='b3'>
            <path
              d='M164.026 96.1208L145.57 80.0718C144.526 79.1647 144.392 77.6376 145.274 76.6023C150.314 70.6853 164.573 53.9371 177.724 38.4161C184.299 30.6558 190.598 23.2017 195.329 17.5615C197.694 14.7416 199.668 12.3741 201.089 10.6483C201.8 9.78552 202.373 9.08166 202.789 8.56116C203.168 8.08553 203.439 7.7343 203.551 7.56039C206.849 3.37647 211.708 0.5 216 0.5C224.008 0.5 230.5 6.99187 230.5 15C230.5 17.9653 229.61 20.7214 228.084 23.0177C227.503 23.8914 224.085 28.1049 219.083 34.1706C214.095 40.2185 207.562 48.0722 200.783 56.1959C187.224 72.4433 172.681 89.7685 167.545 95.883C166.655 96.9439 165.089 97.0453 164.026 96.1208Z'
              fill='white'
            />
            <path
              d='M164.026 96.1208L145.57 80.0718C144.526 79.1647 144.392 77.6376 145.274 76.6023C150.314 70.6853 164.573 53.9371 177.724 38.4161C184.299 30.6558 190.598 23.2017 195.329 17.5615C197.694 14.7416 199.668 12.3741 201.089 10.6483C201.8 9.78552 202.373 9.08166 202.789 8.56116C203.168 8.08553 203.439 7.7343 203.551 7.56039C206.849 3.37647 211.708 0.5 216 0.5C224.008 0.5 230.5 6.99187 230.5 15C230.5 17.9653 229.61 20.7214 228.084 23.0177C227.503 23.8914 224.085 28.1049 219.083 34.1706C214.095 40.2185 207.562 48.0722 200.783 56.1959C187.224 72.4433 172.681 89.7685 167.545 95.883C166.655 96.9439 165.089 97.0453 164.026 96.1208Z'
              fill='url(#paint4_linear_149_3548)'
            />
            <path
              d='M164.026 96.1208L145.57 80.0718C144.526 79.1647 144.392 77.6376 145.274 76.6023C150.314 70.6853 164.573 53.9371 177.724 38.4161C184.299 30.6558 190.598 23.2017 195.329 17.5615C197.694 14.7416 199.668 12.3741 201.089 10.6483C201.8 9.78552 202.373 9.08166 202.789 8.56116C203.168 8.08553 203.439 7.7343 203.551 7.56039C206.849 3.37647 211.708 0.5 216 0.5C224.008 0.5 230.5 6.99187 230.5 15C230.5 17.9653 229.61 20.7214 228.084 23.0177C227.503 23.8914 224.085 28.1049 219.083 34.1706C214.095 40.2185 207.562 48.0722 200.783 56.1959C187.224 72.4433 172.681 89.7685 167.545 95.883C166.655 96.9439 165.089 97.0453 164.026 96.1208Z'
              fill='url(#paint5_linear_149_3548)'
              fillOpacity='0.2'
            />
            <path
              d='M164.026 96.1208L145.57 80.0718C144.526 79.1647 144.392 77.6376 145.274 76.6023C150.314 70.6853 164.573 53.9371 177.724 38.4161C184.299 30.6558 190.598 23.2017 195.329 17.5615C197.694 14.7416 199.668 12.3741 201.089 10.6483C201.8 9.78552 202.373 9.08166 202.789 8.56116C203.168 8.08553 203.439 7.7343 203.551 7.56039C206.849 3.37647 211.708 0.5 216 0.5C224.008 0.5 230.5 6.99187 230.5 15C230.5 17.9653 229.61 20.7214 228.084 23.0177C227.503 23.8914 224.085 28.1049 219.083 34.1706C214.095 40.2185 207.562 48.0722 200.783 56.1959C187.224 72.4433 172.681 89.7685 167.545 95.883C166.655 96.9439 165.089 97.0453 164.026 96.1208Z'
              fill='url(#paint6_linear_149_3548)'
            />
            <path
              d='M164.026 96.1208L145.57 80.0718C144.526 79.1647 144.392 77.6376 145.274 76.6023C150.314 70.6853 164.573 53.9371 177.724 38.4161C184.299 30.6558 190.598 23.2017 195.329 17.5615C197.694 14.7416 199.668 12.3741 201.089 10.6483C201.8 9.78552 202.373 9.08166 202.789 8.56116C203.168 8.08553 203.439 7.7343 203.551 7.56039C206.849 3.37647 211.708 0.5 216 0.5C224.008 0.5 230.5 6.99187 230.5 15C230.5 17.9653 229.61 20.7214 228.084 23.0177C227.503 23.8914 224.085 28.1049 219.083 34.1706C214.095 40.2185 207.562 48.0722 200.783 56.1959C187.224 72.4433 172.681 89.7685 167.545 95.883C166.655 96.9439 165.089 97.0453 164.026 96.1208Z'
              fill='url(#paint7_linear_149_3548)'
              fillOpacity='0.8'
            />
            <path
              d='M164.026 96.1208L145.57 80.0718C144.526 79.1647 144.392 77.6376 145.274 76.6023C150.314 70.6853 164.573 53.9371 177.724 38.4161C184.299 30.6558 190.598 23.2017 195.329 17.5615C197.694 14.7416 199.668 12.3741 201.089 10.6483C201.8 9.78552 202.373 9.08166 202.789 8.56116C203.168 8.08553 203.439 7.7343 203.551 7.56039C206.849 3.37647 211.708 0.5 216 0.5C224.008 0.5 230.5 6.99187 230.5 15C230.5 17.9653 229.61 20.7214 228.084 23.0177C227.503 23.8914 224.085 28.1049 219.083 34.1706C214.095 40.2185 207.562 48.0722 200.783 56.1959C187.224 72.4433 172.681 89.7685 167.545 95.883C166.655 96.9439 165.089 97.0453 164.026 96.1208Z'
              stroke='url(#paint8_linear_149_3548)'
            />
          </g>
          <circle id='b2' cx='214.5' cy='17' r='10' fill='#8B8DDD' />
          <g id='b1'>
            <circle cx='214.5' cy='17' r='8.5' fill='white' />
            <circle
              cx='214.5'
              cy='17'
              r='8.5'
              fill='url(#paint9_linear_149_3548)'
            />
            <circle
              cx='214.5'
              cy='17'
              r='8.5'
              fill='url(#paint10_linear_149_3548)'
              fillOpacity='0.2'
            />
            <circle
              cx='214.5'
              cy='17'
              r='8.5'
              fill='url(#paint11_linear_149_3548)'
            />
            <circle
              cx='214.5'
              cy='17'
              r='8.5'
              fill='url(#paint12_linear_149_3548)'
              fillOpacity='0.8'
            />
            <circle
              cx='214.5'
              cy='17'
              r='8.5'
              stroke='url(#paint13_linear_149_3548)'
            />
          </g>
        </g>
        <g id='lamps'>
          {Array(8)
            .fill(null)
            .map((_, i) => (
              <LazyMotion key={i} features={domAnimation}>
                <m.circle
                  {...lampProps(i, '#BCC5FE')}
                  key={i}
                  cx={156.7 + i * 3}
                  cy='17.5008'
                  r='0.7'
                />
              </LazyMotion>
            ))}
        </g>
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_149_3548'
          x1='138.452'
          y1='22.0005'
          x2='217.005'
          y2='21.7282'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#70A2FE' />
          <stop offset='1' stopColor='#2E519E' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_149_3548'
          x1='138.452'
          y1='22.0001'
          x2='217.005'
          y2='21.7278'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#7CA7FE' />
          <stop offset='1' stopColor='#2B488A' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_149_3548'
          x1='139.381'
          y1='26.9109'
          x2='162.677'
          y2='-8.73578'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#77A5FE' />
          <stop offset='1' stopColor='#C6C9FD' />
        </linearGradient>
        <linearGradient
          id='paint3_linear_149_3548'
          x1='146.917'
          y1='17.0005'
          x2='192.113'
          y2='17.6069'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3A60B0' />
          <stop offset='1' stopColor='#2D509C' />
        </linearGradient>
        <linearGradient
          id='paint4_linear_149_3548'
          x1='231'
          y1='77.7874'
          x2='155.429'
          y2='58.0877'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5258CE' />
          <stop offset='0.987139' stopColor='#5258CE' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint5_linear_149_3548'
          x1='144.886'
          y1='6.21379'
          x2='235.163'
          y2='18.7166'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.483306' stopColor='#BF86E9' />
          <stop offset='0.77115' stopColor='#BD6CEF' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint6_linear_149_3548'
          x1='187'
          y1='0'
          x2='187'
          y2='98.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8FC1F7' />
          <stop offset='1' stopColor='#8FC1F7' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint7_linear_149_3548'
          x1='231'
          y1='-13.8084'
          x2='183.73'
          y2='6.39496'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#99F1C6' />
          <stop offset='1' stopColor='#B7F7DA' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint8_linear_149_3548'
          x1='154.198'
          y1='64.6407'
          x2='231.006'
          y2='64.5561'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#6B65E7' />
          <stop offset='1' stopColor='#7476BD' />
        </linearGradient>
        <linearGradient
          id='paint9_linear_149_3548'
          x1='223.5'
          y1='22.215'
          x2='208.287'
          y2='17.7761'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5258CE' />
          <stop offset='0.987139' stopColor='#5258CE' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint10_linear_149_3548'
          x1='205.886'
          y1='9.13551'
          x2='224.264'
          y2='11.9845'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.483306' stopColor='#BF86E9' />
          <stop offset='0.77115' stopColor='#BD6CEF' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint11_linear_149_3548'
          x1='214.5'
          y1='8'
          x2='214.5'
          y2='26'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8FC1F7' />
          <stop offset='1' stopColor='#8FC1F7' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint12_linear_149_3548'
          x1='223.5'
          y1='5.47663'
          x2='214.195'
          y2='9.92834'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#99F1C6' />
          <stop offset='1' stopColor='#B7F7DA' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint13_linear_149_3548'
          x1='224.841'
          y1='26'
          x2='203.34'
          y2='25.1609'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#676CD4' />
          <stop offset='1' stopColor='#2C2F69' />
        </linearGradient>
        <linearGradient
          id='paint14_linear_149_3548'
          x1='1'
          y1='15.9995'
          x2='1.35422'
          y2='20.2739'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.184291' stopColor='#B095FF' />
          <stop offset='1' stopColor='#203994' />
        </linearGradient>
        <linearGradient
          id='paint15_linear_149_3548'
          x1='1'
          y1='19.9995'
          x2='1.35422'
          y2='24.2739'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.184291' stopColor='#B095FF' />
          <stop offset='1' stopColor='#203994' />
        </linearGradient>
        <linearGradient
          id='paint16_linear_149_3548'
          x1='111'
          y1='19.8967'
          x2='102.548'
          y2='17.4307'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#5258CE' />
          <stop offset='0.987139' stopColor='#5258CE' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint17_linear_149_3548'
          x1='101.214'
          y1='12.6304'
          x2='111.425'
          y2='14.2131'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.483306' stopColor='#BF86E9' />
          <stop offset='0.77115' stopColor='#BD6CEF' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint18_linear_149_3548'
          x1='106'
          y1='11.9995'
          x2='106'
          y2='21.9995'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#8FC1F7' />
          <stop offset='1' stopColor='#8FC1F7' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint19_linear_149_3548'
          x1='111'
          y1='10.5976'
          x2='105.83'
          y2='13.0708'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#99F1C6' />
          <stop offset='1' stopColor='#B7F7DA' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint20_linear_149_3548'
          x1='76.8197'
          y1='21'
          x2='63.6976'
          y2='20.2959'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#676CD4' />
          <stop offset='1' stopColor='#2C2F69' />
        </linearGradient>
        <linearGradient
          id='paint21_linear_149_3548'
          x1='103.012'
          y1='25'
          x2='70.8499'
          y2='22.882'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#676CD4' />
          <stop offset='1' stopColor='#2C2F69' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LedgerConnectAnimation;
