import { useExtensionLogin } from '@/hooks/auth/providers/useExtensionLogin';
import { useLedgerLogin } from '@/hooks/auth/providers/useLedgerLogin';
import { useWalletConnectV2Login } from '@/hooks/auth/providers/useWalletConnectV2Login';
import useWebviewLogin from '@/hooks/auth/providers/useWebviewLogin';
import { useWebWalletLogin } from '@/hooks/auth/providers/useWebWalletLogin';
import { useEffectOnlyOnUpdate } from '@/hooks/tools/useEffectOnlyOnUpdate';

import { useAppDispatch, useAppSelector } from '@/store';
import { clearAuthStates, loginInfoSelector } from '@/store/auth';
import { clearDappProvider, networkStateSelector } from '@/store/network';

import { isLoginExpired } from '@/utils/expiresAt';

import { LoginMethodsEnum } from '@/types/enums';
import { DappProvider } from '@/types/network';

const useAuthProviderSync = ({
  accountDone,
  loginInfoDone,
}: {
  accountDone: boolean;
  loginInfoDone: boolean;
}) => {
  const dispatch = useAppDispatch();
  const loginInfoSnap = useAppSelector(loginInfoSelector);
  const dappProvider = useAppSelector(
    networkStateSelector<DappProvider>('dappProvider')
  );

  const { initiateLogin: initiateWalletConnectLogin } =
    useWalletConnectV2Login();
  const { initiateLogin: initiateExtensionLogin } = useExtensionLogin();
  const { initiateLogin: initiateLedgerLogin } = useLedgerLogin();
  const { initiateLogin: initiateWebWalletLogin } = useWebWalletLogin();
  const { initiateLogin: webviewLogin } = useWebviewLogin();

  useEffectOnlyOnUpdate(() => {
    const askForDappProvider = async () => {
      const loginMethod = loginInfoSnap.loginMethod;
      const loginExpires = loginInfoSnap.expires;

      if (loginExpires && isLoginExpired(loginExpires)) {
        dispatch(clearAuthStates());
        dispatch(clearDappProvider());
        return;
      }

      if (dappProvider) {
        return;
      }

      switch (loginMethod) {
        case LoginMethodsEnum.extension: {
          await initiateExtensionLogin();
          break;
        }

        case LoginMethodsEnum.walletconnect: {
          await initiateWalletConnectLogin(false);
          break;
        }

        case LoginMethodsEnum.wallet: {
          await initiateWebWalletLogin();
          break;
        }

        case LoginMethodsEnum.ledger: {
          await initiateLedgerLogin();
          break;
        }

        case LoginMethodsEnum.extra: {
          await webviewLogin();
          break;
        }
      }
    };
    askForDappProvider();
  }, [accountDone, loginInfoDone]);
};

export default useAuthProviderSync;
