import { GraphQLClient } from 'graphql-request';

import { chainType, networkConfig } from '@/config/network';
import { createFetchWithTimeout } from '@/utils/fetch-with-timeout';

const client = new GraphQLClient(
  networkConfig[chainType].graphExchangeAddress,
  {
    fetch: createFetchWithTimeout(60_000),
  },
);

export default client;
