import {
  AbiRegistry,
  Address,
  AddressValue,
  U32Value,
} from '@multiversx/sdk-core/out';

import controllerABI from '@/abis/controller';
import client from '@/services/blockchain/client';
import { RewardsBatches } from '@/services/blockchain/controller/types';
import { MAX_CACHE_TIME, queryClient } from '@/utils/query';

export const getAdmin = async (controllerAddress: string): Promise<string> => {
  const queryKey = ['blockchain:controller:getAdmin', controllerAddress];

  const queryFn = async () => {
    const response = await client({
      address: controllerAddress,
      method: 'getAdmin',
      abi: AbiRegistry.create(controllerABI),
    });

    return response?.[0].valueOf().toString();
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  }) as Promise<string>;
};

export const getAccountTokens = async (
  controllerAddress: string,
  moneyMarketAddress: string,
  accountAddress: string,
) => {
  const response = await client({
    address: controllerAddress,
    method: 'getAccountTokens',
    abi: AbiRegistry.create(controllerABI),
    args: [
      new AddressValue(new Address(moneyMarketAddress)),
      new AddressValue(new Address(accountAddress)),
    ],
  });

  return response?.[0].valueOf().toString();
};

export const getAccountTokensList = async (
  controllerAddress: string,
  moneyMarketAddresses: string[],
  accountAddress: string,
) => {
  return Promise.all(
    Object.values(moneyMarketAddresses).map(async (moneyMarketAddress) => {
      const accountTokens = await getAccountTokens(
        controllerAddress,
        moneyMarketAddress,
        accountAddress,
      );

      return {
        address: moneyMarketAddress,
        accountTokens,
      };
    }),
  );
};

export const getAccountTokensListMap = async (
  controllerAddress: string,
  moneyMarketAddresses: string[],
  accountAddress: string,
) => {
  const accountTokensList = await getAccountTokensList(
    controllerAddress,
    moneyMarketAddresses,
    accountAddress,
  );

  return accountTokensList.reduce(
    (prev, current) => ({
      ...prev,
      [current.address]: current.accountTokens,
    }),
    {} as Record<string, string>,
  );
};

export const getTotalCollateralTokens = async (
  controllerAddress: string,
  moneyMarketAddress: string,
) => {
  const response = await client({
    address: controllerAddress,
    method: 'getTotalCollateralTokens',
    abi: AbiRegistry.create(controllerABI),
    args: [new AddressValue(new Address(moneyMarketAddress))],
  });

  return response?.[0].valueOf().toString();
};

export const getTotalCollateralTokensList = async (
  controllerAddress: string,
  moneyMarketAddresses: string[],
) => {
  return Promise.all(
    Object.values(moneyMarketAddresses).map(async (moneyMarketAddress) => {
      const totalCollateralTokens = await getTotalCollateralTokens(
        controllerAddress,
        moneyMarketAddress,
      );

      return {
        address: moneyMarketAddress,
        totalCollateralTokens,
      };
    }),
  );
};

export const getTotalCollateralTokensListMap = async (
  controllerAddress: string,
  moneyMarketAddresses: string[],
) => {
  const totalCollateralTokensList = await getTotalCollateralTokensList(
    controllerAddress,
    moneyMarketAddresses,
  );

  return totalCollateralTokensList.reduce(
    (prev, current) => ({
      ...prev,
      [current.address]: current.totalCollateralTokens,
    }),
    {} as Record<string, string>,
  );
};

export const getCollateralFactor = async (
  controllerAddress: string,
  moneyMarketAddress: string,
): Promise<string> => {
  const response = await client({
    address: controllerAddress,
    method: 'getCollateralFactor',
    abi: AbiRegistry.create(controllerABI),
    args: [new AddressValue(new Address(moneyMarketAddress))],
  });

  return response?.[0].valueOf().toString();
};

export const getCollateralFactors = async (
  controllerAddress: string,
  moneyMarketAddresses: string[],
) => {
  return Promise.all(
    Object.values(moneyMarketAddresses).map(async (address) => {
      const collateralFactor = await getCollateralFactor(
        controllerAddress,
        address,
      );

      return {
        address,
        collateralFactor,
      };
    }),
  );
};

export const getCollateralFactorsMap = async (
  controllerAddress: string,
  moneyMarketAddresses: string[],
) => {
  const queryKey = ['blockchain:controller:getCollateralFactorsMap'];

  const queryFn = async () => {
    const collateralFactors = await getCollateralFactors(
      controllerAddress,
      moneyMarketAddresses,
    );

    return collateralFactors.reduce(
      (prev, current) => ({
        ...prev,
        [current.address]: current.collateralFactor,
      }),
      {} as Record<string, string>,
    );
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  }) as Promise<Record<string, string>>;
};

export const getAccountRewardsIndex = async (
  controllerAddress: string,
  moneyMarketAddress: string,
  batchId: string,
  accountAddress: string,
): Promise<string> => {
  const response = await client({
    address: controllerAddress,
    method: 'getAccountRewardsIndex',
    abi: AbiRegistry.create(controllerABI),
    args: [
      new AddressValue(new Address(moneyMarketAddress)),
      new U32Value(batchId),
      new AddressValue(new Address(accountAddress)),
    ],
  });

  return response?.[0].valueOf().toString();
};

export const getAccountRewardsIndexList = async (
  controllerAddress: string,
  moneyMarketsBatchIds: string[],
  accountAddress: string,
) => {
  return Promise.all(
    moneyMarketsBatchIds.map(async (id) => {
      const [moneyMarketAddress, batchId] = id.split('-');

      const accountRewardsIndex = await getAccountRewardsIndex(
        controllerAddress,
        moneyMarketAddress,
        batchId,
        accountAddress,
      );

      return {
        id: `${accountAddress}-${moneyMarketAddress}-${batchId}`,
        accountRewardsIndex,
      };
    }),
  );
};

export const getAccountRewardsIndexListMap = async (
  controllerAddress: string,
  moneyMarketsBatchIds: string[],
  accountAddress: string,
) => {
  const accountRewardsIndexList = await getAccountRewardsIndexList(
    controllerAddress,
    moneyMarketsBatchIds,
    accountAddress,
  );

  return accountRewardsIndexList.reduce(
    (prev, current) => ({
      ...prev,
      [current.id]: current.accountRewardsIndex,
    }),
    {} as Record<string, string>,
  );
};

export const getMaxMarketsPerAccount = async (
  controllerAddress: string,
): Promise<string> => {
  const response = await client({
    address: controllerAddress,
    method: 'getMaxMarketsPerAccount',
    abi: AbiRegistry.create(controllerABI),
  });

  return response?.[0].valueOf().toString();
};

export const getAccountMarkets = async (
  controllerAddress: string,
  accountAddress: string,
): Promise<string[]> => {
  if (!accountAddress || !controllerAddress) {
    return [];
  }

  const response = await client({
    address: controllerAddress,
    method: 'getAccountMarkets',
    args: [new AddressValue(new Address(accountAddress))],
    abi: AbiRegistry.create(controllerABI),
  });

  return (
    (response?.[0].valueOf().toString().split(',') || []) as string[]
  ).filter((address) => address.length > 0);
};

export const getIdentifiers = (
  controllerAddress: string,
  marketsAddress: string[],
) => {
  const queryKey = ['blockchain:controller:getIdentifiers'];

  const queryFn = async () => {
    return Promise.all(
      marketsAddress.map(async (moneyMarketAddress) => {
        const data = await getIdentifiersByMoneyMarket(
          controllerAddress,
          moneyMarketAddress,
        );

        return {
          ...data,
          address: moneyMarketAddress,
        };
      }),
    );
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  }) as Promise<
    {
      address: string;
      underlyingId: string;
      hTokenId: string;
    }[]
  >;
};

export const getIdentifiersByMoneyMarket = async (
  controllerAddress: string,
  moneyMarketAddress: string,
): Promise<{ underlyingId: string; hTokenId: string }> => {
  const response = await client({
    address: controllerAddress,
    method: 'getIdentifiersByMoneyMarket',
    args: [new AddressValue(new Address(moneyMarketAddress))],
    abi: AbiRegistry.create(controllerABI),
  });

  return {
    underlyingId: response?.[0]?.fieldsByName.get('field0').value.toString(),
    hTokenId: response?.[0]?.fieldsByName.get('field1').value.toString(),
  };
};

export const getRewardsBatches = async (
  controllerAddress: string,
  moneyMarketAddress: string,
): Promise<RewardsBatches[]> => {
  const response = await client({
    address: controllerAddress,
    method: 'getRewardsBatches',
    args: [new AddressValue(new Address(moneyMarketAddress))],
    abi: AbiRegistry.create(controllerABI),
  });

  return response?.[0].items.map(({ fieldsByName }) => {
    return {
      id: fieldsByName.get('id').value.toString(),
      moneyMarket: fieldsByName.get('money_market').value.value.toString(),
      marketType: fieldsByName.get('market_type').value.name,
      tokenId: fieldsByName.get('token_id').value.toString(),
      amount: fieldsByName.get('amount').value.toString(),
      distributedAmount: fieldsByName
        .get('distributed_amount')
        .value.toString(),
      speed: fieldsByName.get('speed').value.toString(),
      index: fieldsByName.get('index').value.toString(),
      lastTime: new Date(
        +fieldsByName.get('last_time').value.toString() * 1000,
      ).toISOString(),
      endTime: new Date(
        +fieldsByName.get('end_time').value.toString() * 1000,
      ).toISOString(),
    };
  });
};

export const getRewardsBatchesList = async (
  controllerAddress: string,
  moneyMarketAddresses: string[],
) => {
  return Promise.all(
    Object.values(moneyMarketAddresses).map(async (address) => {
      const rewardsBatches = await getRewardsBatches(
        controllerAddress,
        address,
      );

      return {
        address,
        rewardsBatches,
      };
    }),
  );
};

export const getRewardsBatchesListMap = async (
  controllerAddress: string,
  moneyMarketAddresses: string[],
) => {
  const rewardsBatchesList = await getRewardsBatchesList(
    controllerAddress,
    moneyMarketAddresses,
  );

  return rewardsBatchesList.reduce(
    (prev, current) => ({
      ...prev,
      [current.address]: current.rewardsBatches,
    }),
    {} as Record<string, RewardsBatches[]>,
  );
};

export const getBorrowCap = async (
  controllerAddress: string,
  moneyMarketAddress: string,
): Promise<string> => {
  const response = await client({
    address: controllerAddress,
    method: 'getBorrowCap',
    abi: AbiRegistry.create(controllerABI),
    args: [new AddressValue(new Address(moneyMarketAddress))],
  });

  return response?.[0].valueOf().toString();
};

export const getBorrowCaps = async (
  controllerAddress: string,
  moneyMarketAddresses: string[],
) => {
  return Promise.all(
    Object.values(moneyMarketAddresses).map(async (address) => {
      const borrowCap = await getBorrowCap(controllerAddress, address);

      return {
        address,
        borrowCap,
      };
    }),
  );
};

export const getBorrowCapsMap = async (
  controllerAddress: string,
  moneyMarketAddresses: string[],
) => {
  const queryKey = ['blockchain:controller:getBorrowCapsMap'];

  const queryFn = async () => {
    const borrowCaps = await getBorrowCaps(
      controllerAddress,
      moneyMarketAddresses,
    );

    return borrowCaps.reduce(
      (prev, current) => ({
        ...prev,
        [current.address]: current.borrowCap,
      }),
      {} as Record<string, string>,
    );
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  }) as Promise<Record<string, string>>;
};

export const getLiquidityCap = async (
  controllerAddress: string,
  moneyMarketAddress: string,
): Promise<string> => {
  const response = await client({
    address: controllerAddress,
    method: 'getLiquidityCap',
    abi: AbiRegistry.create(controllerABI),
    args: [new AddressValue(new Address(moneyMarketAddress))],
  });

  return response?.[0].valueOf().toString();
};

export const getLiquidityCaps = async (
  controllerAddress: string,
  moneyMarketAddresses: string[],
) => {
  return Promise.all(
    Object.values(moneyMarketAddresses).map(async (address) => {
      const liquidityCap = await getLiquidityCap(controllerAddress, address);

      return {
        address,
        liquidityCap,
      };
    }),
  );
};

export const getLiquidityCapsMap = async (
  controllerAddress: string,
  moneyMarketAddresses: string[],
) => {
  const queryKey = ['blockchain:controller:getLiquidityCapsMap'];

  const queryFn = async () => {
    const liquidityCaps = await getLiquidityCaps(
      controllerAddress,
      moneyMarketAddresses,
    );

    return liquidityCaps.reduce(
      (prev, current) => ({
        ...prev,
        [current.address]: current.liquidityCap,
      }),
      {} as Record<string, string>,
    );
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  }) as Promise<Record<string, string>>;
};

export const getMintStatus = async (
  controllerAddress: string,
  moneyMarketAddress: string,
): Promise<string> => {
  const response = await client({
    address: controllerAddress,
    method: 'getMintStatus',
    abi: AbiRegistry.create(controllerABI),
    args: [new AddressValue(new Address(moneyMarketAddress))],
  });

  return response?.[0].name.toString();
};

export const getMintStatusList = async (
  controllerAddress: string,
  moneyMarketAddresses: string[],
) => {
  return Promise.all(
    Object.values(moneyMarketAddresses).map(async (address) => {
      const mintStatus = await getMintStatus(controllerAddress, address);

      return {
        address,
        mintStatus,
      };
    }),
  );
};

export const getMintStatusListMap = async (
  controllerAddress: string,
  moneyMarketAddresses: string[],
) => {
  const queryKey = ['blockchain:controller:getMintStatusListMap'];

  const queryFn = async () => {
    const mintStatusList = await getMintStatusList(
      controllerAddress,
      moneyMarketAddresses,
    );

    return mintStatusList.reduce(
      (prev, current) => ({
        ...prev,
        [current.address]: current.mintStatus,
      }),
      {} as Record<string, string>,
    );
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  }) as Promise<Record<string, string>>;
};

export const getBorrowStatus = async (
  controllerAddress: string,
  moneyMarketAddress: string,
): Promise<string> => {
  const response = await client({
    address: controllerAddress,
    method: 'getBorrowStatus',
    abi: AbiRegistry.create(controllerABI),
    args: [new AddressValue(new Address(moneyMarketAddress))],
  });

  return response?.[0].name.toString();
};

export const getBorrowStatusList = async (
  controllerAddress: string,
  moneyMarketAddresses: string[],
) => {
  return Promise.all(
    Object.values(moneyMarketAddresses).map(async (address) => {
      const borrowStatus = await getBorrowStatus(controllerAddress, address);

      return {
        address,
        borrowStatus,
      };
    }),
  );
};

export const getBorrowStatusListMap = async (
  controllerAddress: string,
  moneyMarketAddresses: string[],
) => {
  const queryKey = ['blockchain:controller:getBorrowStatusListMap'];

  const queryFn = async () => {
    const borrowStatusList = await getBorrowStatusList(
      controllerAddress,
      moneyMarketAddresses,
    );

    return borrowStatusList.reduce(
      (prev, current) => ({
        ...prev,
        [current.address]: current.borrowStatus,
      }),
      {} as Record<string, string>,
    );
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  }) as Promise<Record<string, string>>;
};
