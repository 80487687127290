import classNames from 'classnames';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';

import { TOKEN_KEY } from '@/components/popups/XLaunchpadSnapshotPopup/types';

import clsxm from '@/services/clsxm';
import { formatNumber } from '@/utils/helpers';

import { MaxButton } from './MaxButton';

interface AmountInputProps {
  prefix: any;
  suffix: any;
  token: TOKEN_KEY;
  decimalsLimit: number;
  inputValue: number;
  estimation: number;
  className: string;
  onChange: (n: number) => void;
  onMax: () => void;
}

const imageSrcMap = {
  [TOKEN_KEY.SEGLD]: 'https://cdn.app.hatom.com/images/sEGLD.svg',
  [TOKEN_KEY.HSEGLD]: 'https://cdn.app.hatom.com/images/liquidstaking/app/tokens/hsegld.png',
};

const AmountInput: React.FC<any> = ({
  prefix,
  token,
  suffix,
  decimalsLimit = 18,
  inputValue,
  estimation,
  onChange,
  onMax,
  className,
}: AmountInputProps) => {
  prefix = prefix ?? (
    <div>
      <img
        className='h-[40] w-[26.5px] lg:h-[37px] lg:w-[37px] rounded-full'
        src={imageSrcMap[token]}
        alt={token}
      />
    </div>
  );

  return (
    <div className={clsxm(className)}>
      <div className='relative'>
        <div className='absolute left-[12px] top-1/2 z-10 -translate-y-1/2'>
          {prefix}
        </div>

        <div>
          <CurrencyInput
            inputMode='text'
            autoComplete='off'
            className={classNames(
              'pl-[45.5px] lg:pl-[63px] pr-20',
              'text-[12px] lg:text-[16px]',
              'h-[40px] lg:h-[56px] w-full rounded-[8.5px] md:rounded-[12px] border-[2px] ',
              'border-[#DCDCE3] bg-[#F9F9FE] placeholder:text-[#5C5C71] dark:border-[#504B87] dark:bg-transparent dark:placeholder:text-white/70',
              'placeholder:text-current focus:outline-none font-hass font-semibold leading-6',
              'theme-switch-transition'
            )}
            id='liquid-input'
            name='liquid-input'
            data-testid='liquidInput'
            maxLength={50}
            onValueChange={onChange}
            placeholder='0'
            max={10}
            decimalsLimit={decimalsLimit}
            allowNegativeValue={false}
            groupSeparator=','
            decimalSeparator='.'
            // this code snippet displays placeholder when input is empty
            value={inputValue}
            defaultValue={0}
          />
          <div className='absolute left-[48px] lg:left-[64.75px] bottom-[4.3px] lg:bottom-[8.3px] font-[600] text-[7px] lg:text-[10px] text-[#535367] dark:text-[#625E8F]'>
            ≈ ${formatNumber(estimation, 2)}
          </div>
        </div>
        <div className='absolute right-[12px] top-1/2 z-10 -translate-y-1/2'>
          {!suffix && <MaxButton onMax={onMax} />}
        </div>
      </div>
    </div>
  );
};

export default AmountInput;
