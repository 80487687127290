
import { WalletTypes } from "@/components/popups/ConnectBridgeWalletPopup/components/types";

import clsxm from "@/services/clsxm";

const ScamFishing = ({ type }: { type: WalletTypes }) => {
  return (<div className={clsxm(
    "pl-[3.6px] lg:pl-[5px] pt-[12px] lg:pt-[17px]",
    " flex items-start  relative z-3",
  )}>
    <img
      src='https://cdn.app.hatom.com/images/pages/bridge/lock.svg'
      alt="lock"
      className='w-[10px] lg:w-[14px] mr-[5.4px] lg:mr-[7px]'
    />
    <div className={clsxm(
      'text-[7.5px] lg:text-[10px] leading-[8.9px] lg:leading-[13.1px] tracking-[0.01em] translate-y-[-1px] font-[500]',
      type === "MULTIVERSE_X" && "text-[#53648C]",
      type === "FINNEY" && "text-[#D2D2F3]"
    )}>
      <p>Scam/Phishing verification:</p>
      <p><span className='text-[#45A273CC] tracking-[0.03em] mr-[-0.2px]'>https://</span><span className='font-[600]'>hatom.com</span></p>
      <p>Check the website link </p>
      <p>carefully!</p>
    </div>
  </div>)
}

export default ScamFishing;