import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import { useLogout } from '@/hooks/auth/useLogout';

import BaseButton from '@/components/popups/AccountDetailsPopup/components/ActionButtons/BaseButton';

import { useAppDispatch, useAppSelector } from '@/store';
import { accountSelector } from '@/store/auth';
import { closePopup } from '@/store/popup';

import { chainType, networkConfig } from '@/config/network';

const ActionButtons = () => {
  const dispatch = useAppDispatch();
  const { logout } = useLogout();
  const { address } = useAppSelector(accountSelector);
  const { t } = useTranslation();

  const handleLogout = async () => {
    await logout();
    await dispatch(closePopup());
  };

  return (
    <>
      <BaseButton
        onClick={() =>
          window.open(
            `${networkConfig[chainType].explorerAddress}/address/${address}`,
            '_blank'
          )
        }
        customTheme='text-white bg-[#006FFF] dark:bg-blue'
      >
        <div className='uppercase'>{t('view-on-explorer')}</div>
      </BaseButton>
      <BaseButton
        onClick={handleLogout}
        customTheme={classNames('text-white', 'bg-[#E45059] dark:bg-[#AA354A]')}
      >
        <div className='uppercase'>{t('disconnect')}</div>
      </BaseButton>
    </>
  );
};

export default ActionButtons;
