import { AbiRegistry, Address, AddressValue } from '@multiversx/sdk-core/out';

import boosterABI from '@/abis/booster';
import client from '@/services/blockchain/client';

export const getAccountStake = async (
  boosterAddress: string,
  accountAddress: string,
): Promise<string> => {
  if (accountAddress.length === 0) {
    return '0';
  }

  const response = await client({
    address: boosterAddress,
    method: 'getAccountStake',
    abi: AbiRegistry.create(boosterABI),
    args: [new AddressValue(new Address(accountAddress))],
  });

  return response?.[0].valueOf().toString() ?? '';
};

export const getCooldownPeriod = async (
  boosterAddress: string,
): Promise<string> => {
  const response = await client({
    address: boosterAddress,
    method: 'getCooldownPeriod',
    abi: AbiRegistry.create(boosterABI),
  });

  return response?.[0].valueOf().toString() ?? '';
};

export const getStakingRatioThreshold = async (
  boosterAddress: string,
): Promise<string> => {
  const response = await client({
    address: boosterAddress,
    method: 'getStakingRatioThreshold',
    abi: AbiRegistry.create(boosterABI),
  });

  return response?.[0].valueOf().toString() ?? '';
};

export const getTotalStake = async (
  boosterAddress: string,
): Promise<string> => {
  const response = await client({
    address: boosterAddress,
    method: 'getTotalStake',
    abi: AbiRegistry.create(boosterABI),
  });

  return response?.[0].valueOf().toString() ?? '';
};

export const getStoredTotalCollateralTokens = async (
  boosterAddress: string,
  moneyMarketAddress: string,
): Promise<string> => {
  const response = await client({
    address: boosterAddress,
    method: 'getStoredTotalCollateralTokens',
    abi: AbiRegistry.create(boosterABI),
    args: [new AddressValue(new Address(moneyMarketAddress))],
  });

  return response?.[0].valueOf().toString() ?? '';
};

export const getStoredTotalCollateralTokensList = async (
  boosterAddress: string,
  moneyMarketAddresses: { address: string; tokenSymbol: string }[],
) => {
  return Promise.all(
    moneyMarketAddresses.map(async (moneyMarketAddress) => {
      const totalCollateralTokens = await getStoredTotalCollateralTokens(
        boosterAddress,
        moneyMarketAddress.address,
      );

      return {
        address: moneyMarketAddress,
        totalCollateralTokens,
        tokenSymbol: moneyMarketAddress.tokenSymbol,
      };
    }),
  );
};
