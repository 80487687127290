import clsxm from '@/services/clsxm';

export const Spinner: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      className={clsxm(
        'inline-block h-[1em] w-[1em] rounded-full',
        'animate-spin',
        'motion-reduce:animate-[spin_1.5s_linear_infinite]',
        'border-[0.125em] border-current border-r-transparent',
        'align-[-0.125em]',
        className
      )}
      role='status'
    >
      <span className='sr-only'>Loading...</span>
    </div>
  );
};
