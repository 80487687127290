import { GraphQLClient } from 'graphql-request';

import { taoStatsUrl } from '@/config/envVars';
import { createFetchWithTimeout } from '@/utils/fetch-with-timeout';

const client = new GraphQLClient(taoStatsUrl, {
  fetch: createFetchWithTimeout(10_000),
  method: 'GET',
  jsonSerializer: {
    parse: JSON.parse,
    stringify: JSON.stringify,
  },
});

export default client;
