import { Trans, useTranslation } from 'next-i18next';

import useMediaQuery from '@/hooks/useMediaQuery';

import Hint from '@/components/Hint';
import InfoIcon from '@/components/Icons/InfoIcon';

import clsxm from '@/services/clsxm';

export default function TitleButton({
  className = '',
  borrow = false,
  button = false,
  selected = false,
  ...reset
}) {
  const { t } = useTranslation();
  const md = useMediaQuery('(min-width: 768px)');

  return (
    <h5
      className={clsxm(
        'flex items-center gap-1 text-[12px] tracking-[0.03em] sm:text-[14px]',
        borrow ? 'text-aqua' : 'text-purple',
        button && [
          '-my-3 inline-flex cursor-pointer rounded-full px-5 py-3',
          selected && (borrow ? 'bg-aqua/20' : 'bg-purple/20'),
        ],
        className
      )}
      {...reset}
    >
      {borrow
        ? t('borrow-position', 'Borrow position')
        : t('supply-position', 'Supply position')}
      <Hint
        autoReplace
        content={
          <div
            style={{
              maxWidth: md
                ? 310
                : (borrow
                  ? 319
                  : 325
                ),
              zIndex: 100,
            }}
            className='text-[8px] md:text-[10px] leading-[12.5px] tracking-normal'
          >
            {borrow ? (
              <>
                <Trans
                  i18nKey='tooltip-gvya5'
                  components={[
                    <span className='text-aqua' />,
                    <span className='text-ube' />,
                  ]}
                  ns='translation'
                />
              </>
            ) : (
              <>
                <Trans
                  i18nKey='tooltip-4x77s'
                  components={[
                    <span className='text-[#8C80D4]' />,
                    <span className='text-[#D6A4E9]' />,
                    <span className='text-[#51C392CF]/80' />,
                    <span className='text-[#5AF1B0CF]/80' />,
                    <span className='text-[#E24949]' />,
                  ]}
                  ns='translation'
                />
              </>
            )}
          </div>
        }
      >
        <InfoIcon
          className='text-[#6A6A8C]/80'
          width={12}
          height={12}
          strokeWidth='1.8'
        />
      </Hint>
    </h5>
  );
}
