import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { useToggle } from 'usehooks-ts';

import useTheme from '@/hooks/useTheme';

import Hint from '@/components/Hint';
import InfoIcon from '@/components/Icons/InfoIcon';

import { USER_BALANCE_SOURCE } from '@/store/governance';

import { useHintProps } from '@/sections/Liquid/App/global';
import { formatNumber } from '@/utils/helpers';

interface VoteInputProps {
  balance: string;
  value: USER_BALANCE_SOURCE | undefined;
  onChange: (value: USER_BALANCE_SOURCE | undefined) => void;
  items: {
    id: USER_BALANCE_SOURCE;
    name: string;
    Icon: (
      props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
    ) => JSX.Element;
  }[];
}

export const VoteInput = ({
  balance,
  value,
  onChange,
  items = [],
}: VoteInputProps) => {
  const defaultHintProps = useHintProps();
  const { isDark } = useTheme();
  const [isOpen, toggleIsOpen] = useToggle();
  const { t } = useTranslation('governance');

  const Icon = useMemo(() => {
    const item = items.find(({ id }) => id === value);

    if (!item) {
      return InfoIcon;
    }

    return item.Icon;
  }, [items, value]);

  return (
    <label
      className={classNames(
        'mb-4',
        'block',
        'relative',
        'cursor-pointer',
        'select-none'
      )}
      onClick={toggleIsOpen}
    >
      <span
        className={classNames(
          'text-sm',
          'flex',
          'gap-1',
          'items-center',
          'leading-[18px]',
          'mb-1'
        )}
      >
        {t('voting-balance', 'Voting Balance')}
        <Hint
          {...defaultHintProps}
          autoReplace
          content={t(
            'tooltip-bF9RB',
            'Select the balance that you would like to use for voting.'
          )}
        >
          <InfoIcon width={11} height={11} strokeWidth='1.5' />
        </Hint>
      </span>

      <div
        className={classNames(
          'border-[1px]',
          'border-[#E7E7F9]',
          'dark:border-[#1D1D33]',
          'w-full',
          'block',
          'h-[44px]',
          'rounded-[8px]',
          'py-[9.5px]',
          'px-[11px]',
          'flex',
          'gap-3',
          'bg-[#FBFAFF]',
          'dark:bg-[#101015]',
          'text-sm',
          'leading-[18px]',
          'text-[#77778B]',
          'dark:text-[#8888A4]',
          'items-center',
          'font-normal'
        )}
      >
        {typeof value === 'undefined' ? (
          t('select-balance', 'Select Balance')
        ) : (
          <div className={classNames('flex', 'items-center')}>
            <div
              className={classNames(
                'h-[26px]',
                'w-[26px]',
                'border-[1px]',
                'border-[#BCBCE2]',
                'dark:border-[#3C3C5A]',
                'rounded-full',
                'flex',
                'items-center',
                'justify-center',
                'mr-[14px]',
                'relative'
              )}
            >
              <Icon
                fill={isDark ? '#ffffff' : '#000000'}
                width='15px'
                height='15px'
              />

              <img
                src='https://cdn.app.hatom.com/images/tokens/HTM.svg'
                alt='Hatom'
                className={classNames(
                  'w-[14px]',
                  'h-[14px]',
                  'block',
                  'absolute',
                  'bottom-[-2.5px]',
                  'right-[-6px]'
                )}
              />
            </div>

            <div className={classNames('')}>
              <span
                className={classNames(
                  'block capitalize',
                  'text-[#1F2226]',
                  'dark:text-[#EFEFEF]'
                )}
              >
                {value}
              </span>
              <span className={classNames('block')}>
                {formatNumber(balance, 1)} HTM
              </span>
            </div>
          </div>
        )}
      </div>

      {isOpen && (
        <ul
          className={classNames(
            'absolute',
            'top-[110%]',
            'left-0',
            'border border-[#E7E7F9] bg-[#FFFFFF]',
            'dark:bg-[#171430]',
            'rounded-[8px]',
            'p-1.5',
            'w-full',
            'flex',
            'flex-col',
            'gap-1.5',
            'z-[11]'
          )}
        >
          {items.map(({ name, Icon, id }, index) => (
            <li
              key={name}
              className={classNames(
                'flex',
                'gap-2.5',
                'cursor-pointer',
                'px-1.5',
                'py-1',
                'items-center',
                'rounded-[7.82878px]',
                id === value
                  ? [
                      'bg-[#F3F0FF]',
                      'text-[#6A6A8C]',
                      'dark:bg-[#363150]',
                      'dark:text-[#625E8F]',
                    ]
                  : ['text-[#9D9DC2]', 'dark:text-[#2C2C4A]']
              )}
              onClick={() => onChange(id)}
            >
              <div
                className={classNames(
                  'border-[1px]',
                  'border-[#BCBCE2]',
                  'rounded-full',
                  'h-[26px]',
                  'w-[26px]',
                  'flex',
                  'items-center',
                  'justify-center',
                  'bg-[#fff]',
                  'relative',
                  'dark:border-[#3C3C5A]',
                  'dark:bg-[#171430]'
                )}
              >
                <Icon fill={isDark ? '#ffffff' : '#000000'} />
                <img
                  src='https://cdn.app.hatom.com/images/tokens/HTM.svg'
                  alt='Hatom'
                  className={classNames(
                    'w-[14px]',
                    'h-[14px]',
                    'block',
                    'absolute',
                    'bottom-[-2.5px]',
                    'right-[-6px]'
                  )}
                />
              </div>

              <span
                className={classNames('text-sm capitalize', 'leading-[18px]')}
              >
                {name}
              </span>
            </li>
          ))}
        </ul>
      )}
    </label>
  );
};
