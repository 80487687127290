import classNames from 'classnames';
import { Trans } from 'next-i18next';
import React from 'react';

import useExchangeRate, {
    EXCHANGE_RATE_KEY,
} from '@/hooks/protocol/useExchangeRate';

import Hint from '@/components/Hint';
import InfoIcon from '@/components/Icons/InfoIcon';

import { useHintProps } from '@/sections/Liquid/App/global';
import { formatNumber } from '@/utils/helpers';

export const FeaturedTokenCheckBox: React.FC<{
  poweredEGLD: EXCHANGE_RATE_KEY;
  value: boolean;
  onClick: () => void;
}> = ({
  poweredEGLD = EXCHANGE_RATE_KEY.HsEGLD,
  value = false,
  onClick = () => {},
  ...props
}) => {
  const defaultHintProps = useHintProps();

  const hsegldToEgld = useExchangeRate(
    EXCHANGE_RATE_KEY.HsEGLD,
    EXCHANGE_RATE_KEY.EGLD,
  );

  const segldToEgld = useExchangeRate(
    EXCHANGE_RATE_KEY.sEGLD,
    EXCHANGE_RATE_KEY.EGLD,
  );

  const config = {
    [EXCHANGE_RATE_KEY.HsEGLD]: {
      logo: 'app/tokens/hsegld-diamond.png',
      name: 'HsEGLD',
      info: (
        <Trans
          i18nKey='tp-7'
          tOptions={{
            a: '1',
            b: formatNumber(hsegldToEgld, 6),
            token: 'EGLD',
            tokenB: 'HsEGLD',
          }}
          components={[
            <br />,
            <span className='font-semibold text-[#885FFD]' />,
            <span className='font-semibold text-[#8C80D4]' />,
            <span className='font-semibold text-[#51C392CF]' />,
          ]}
          ns='liquid-app'
        />
      ),
      checkedClasses:
        'bg-[#E2E1FF] border-[#C4C2FE] dark:bg-[#403C7C] dark:border-[#837ED3]',
    },
    [EXCHANGE_RATE_KEY.sEGLD]: {
      logo: 'app/tokens/segld-diamond.png',
      name: 'sEGLD',
      info: (
        <Trans
          i18nKey='tp-6'
          tOptions={{
            a: '1',
            b: formatNumber(segldToEgld, 6),
            token: 'EGLD',
            tokenB: 'sEGLD',
          }}
          components={[
            <br />,
            <span className='font-semibold text-[8px] md:text-[10px] text-[#006FFF]' />,
          ]}
          ns='liquid-app'
        />
      ),
      checkedClasses:
        'bg-[#E1EBFF] border-[#006FFF]/30 dark:bg-[#403C7C] dark:border-[#837ED3]',
    },
  };

  const { logo, name, info, checkedClasses } =
    config[poweredEGLD as keyof typeof config];

  return (
    <button
      className={classNames(
        'flex items-center gap-2 px-2 pr-4 sm:gap-2',
        'h-16 w-full rounded-[20px] border-2 sm:h-[65px]',
        'font-hass font-semibold leading-6',
        value
          ? checkedClasses
          : 'border-[#DCDCE3] bg-[#F9F9FE] dark:border-[#2E2C51] dark:bg-[#292651]',
        'theme-switch-transition',
      )}
      onClick={onClick}
      {...props}
    >
      <img
        className='h-9 sm:h-auto sm:w-[42px]'
        src={`https://cdn.app.hatom.com/images/liquidstaking/${logo.toLowerCase()}`}
        alt=''
      />
      <div className='mr-auto'>{name}</div>
      <Hint
        {...defaultHintProps}
        autoReplace={true}
        content={
          <div
            className={classNames(
              'text-[8px] md:text-[10px] leading-tight',
              poweredEGLD == EXCHANGE_RATE_KEY.HsEGLD
                ? 'max-w-[288px] md:max-w-[240px]'
                : 'max-w-[330px] md:max-w-[210px]',
            )}
          >
            {info}
          </div>
        }
      >
        <InfoIcon
          className='theme-switch-transition inline-block -translate-x-px -translate-y-px text-[18px] text-[#535367] dark:text-[#625E8F]'
          strokeWidth='1.8'
        />
      </Hint>
    </button>
  );
};
