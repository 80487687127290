const governanceABI = {
  buildInfo: {
    rustc: {
      version: '1.62.0-nightly',
      commitHash: 'e7575f9670f3c837def3d186ae09366c75c7632e',
      commitDate: '2022-04-14',
      channel: 'Nightly',
      short: 'rustc 1.62.0-nightly (e7575f967 2022-04-14)',
    },
    contractCrate: {
      name: 'governance',
      version: '0.0.0',
      git_version: 'b2c827b-modified',
    },
    framework: {
      name: 'elrond-wasm',
      version: '0.34.1',
    },
  },
  name: 'Governance',
  constructor: {
    inputs: [
      {
        name: 'quorum',
        type: 'BigUint',
      },
      {
        name: 'voting_delay_in_blocks',
        type: 'u64',
      },
      {
        name: 'voting_period_in_blocks',
        type: 'u64',
      },
      {
        name: 'vote_nft_id',
        type: 'TokenIdentifier',
      },
      {
        name: 'min_amount_for_proposal',
        type: 'BigUint',
      },
      {
        name: 'governance_token_id',
        type: 'TokenIdentifier',
      },
      {
        name: 'admin',
        type: 'Address',
      },
    ],
    outputs: [],
  },
  endpoints: [
    {
      docs: [
        'Propose a transaction in which the contract will perform a transfer-execute call.',
        'Can send EGLD without calling anything.',
        'Can call smart contract endpoints directly.',
        "Doesn't really work with builtin functions.",
      ],
      name: 'proposeTransferExecute',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'to',
          type: 'Address',
        },
        {
          name: 'egld_amount',
          type: 'BigUint',
        },
        {
          name: 'gas_limit',
          type: 'u64',
        },
        {
          name: 'opt_endpoint_name',
          type: 'optional<bytes>',
          multi_arg: true,
        },
        {
          name: 'arguments',
          type: 'variadic<bytes>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'u64',
        },
        {
          type: 'EsdtTokenPayment',
        },
      ],
    },
    {
      docs: [
        'same as `propose_transfer_execute` but must be used when appending the action to an existing proposal',
      ],
      name: 'proposeTransferExecuteAppend',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'proposal_id',
          type: 'u64',
        },
        {
          name: 'to',
          type: 'Address',
        },
        {
          name: 'egld_amount',
          type: 'BigUint',
        },
        {
          name: 'gas_limit',
          type: 'u64',
        },
        {
          name: 'opt_endpoint_name',
          type: 'optional<bytes>',
          multi_arg: true,
        },
        {
          name: 'arguments',
          type: 'variadic<bytes>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'u64',
        },
        {
          type: 'EsdtTokenPayment',
        },
      ],
    },
    {
      docs: [
        'Propose a transaction in which the contract will perform a transfer-execute call.',
        'Can call smart contract endpoints directly.',
        'Can use ESDTTransfer/ESDTNFTTransfer/MultiESDTTransfer to send tokens, while also optionally calling endpoints.',
        'Works well with builtin functions.',
        'Cannot simply send EGLD directly without calling anything.',
      ],
      name: 'proposeAsyncCall',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'to',
          type: 'Address',
        },
        {
          name: 'egld_amount',
          type: 'BigUint',
        },
        {
          name: 'opt_endpoint_name',
          type: 'optional<bytes>',
          multi_arg: true,
        },
        {
          name: 'arguments',
          type: 'variadic<bytes>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'u64',
        },
        {
          type: 'EsdtTokenPayment',
        },
      ],
    },
    {
      docs: [
        'same as `propose_async_call` but must be used when appending the action to an existing proposal. Make sure this is',
        'the last appended action, because transaction execution will end after calling `async_call`',
      ],
      name: 'proposeAsyncCallAppend',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'proposal_id',
          type: 'u64',
        },
        {
          name: 'to',
          type: 'Address',
        },
        {
          name: 'egld_amount',
          type: 'BigUint',
        },
        {
          name: 'opt_endpoint_name',
          type: 'optional<bytes>',
          multi_arg: true,
        },
        {
          name: 'arguments',
          type: 'variadic<bytes>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'u64',
        },
        {
          type: 'EsdtTokenPayment',
        },
      ],
    },
    {
      name: 'proposeSCDeployFromSource',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'gas_limit',
          type: 'u64',
        },
        {
          name: 'egld_amount',
          type: 'BigUint',
        },
        {
          name: 'source',
          type: 'Address',
        },
        {
          name: 'code_metadata',
          type: 'Metadata',
        },
        {
          name: 'arguments',
          type: 'variadic<bytes>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'u64',
        },
        {
          type: 'EsdtTokenPayment',
        },
      ],
    },
    {
      name: 'proposeSCDeployFromSourceAppend',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'proposal_id',
          type: 'u64',
        },
        {
          name: 'gas_limit',
          type: 'u64',
        },
        {
          name: 'egld_amount',
          type: 'BigUint',
        },
        {
          name: 'source',
          type: 'Address',
        },
        {
          name: 'code_metadata',
          type: 'Metadata',
        },
        {
          name: 'arguments',
          type: 'variadic<bytes>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'u64',
        },
        {
          type: 'EsdtTokenPayment',
        },
      ],
    },
    {
      name: 'proposeSCUpgradeFromSource',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'sc_address',
          type: 'Address',
        },
        {
          name: 'gas_limit',
          type: 'u64',
        },
        {
          name: 'egld_amount',
          type: 'BigUint',
        },
        {
          name: 'source',
          type: 'Address',
        },
        {
          name: 'code_metadata',
          type: 'Metadata',
        },
        {
          name: 'arguments',
          type: 'variadic<bytes>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'u64',
        },
        {
          type: 'EsdtTokenPayment',
        },
      ],
    },
    {
      name: 'proposeSCUpgradeFromSourceAppend',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'proposal_id',
          type: 'u64',
        },
        {
          name: 'sc_address',
          type: 'Address',
        },
        {
          name: 'gas_limit',
          type: 'u64',
        },
        {
          name: 'egld_amount',
          type: 'BigUint',
        },
        {
          name: 'source',
          type: 'Address',
        },
        {
          name: 'code_metadata',
          type: 'Metadata',
        },
        {
          name: 'arguments',
          type: 'variadic<bytes>',
          multi_arg: true,
        },
      ],
      outputs: [
        {
          type: 'u64',
        },
        {
          type: 'EsdtTokenPayment',
        },
      ],
    },
    {
      docs: [
        'Proposal can be published once it has been builded, i.e. once all intended actions have been proposed.',
      ],
      name: 'publishProposal',
      mutability: 'mutable',
      inputs: [
        {
          name: 'proposal_id',
          type: 'u64',
        },
        {
          name: 'opt_description',
          type: 'optional<bytes>',
          multi_arg: true,
        },
      ],
      outputs: [],
    },
    {
      name: 'upvote',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'proposal_id',
          type: 'u64',
        },
      ],
      outputs: [
        {
          type: 'EsdtTokenPayment',
        },
      ],
    },
    {
      name: 'downvote',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [
        {
          name: 'proposal_id',
          type: 'u64',
        },
      ],
      outputs: [
        {
          type: 'EsdtTokenPayment',
        },
      ],
    },
    {
      docs: [
        'Proposals can only be executed when reaching the Succeeded state. Proposals can only',
        'be executed by the admin when she exists. Otherwise, anyone can execute.',
      ],
      name: 'execute',
      mutability: 'mutable',
      inputs: [
        {
          name: 'proposal_id',
          type: 'u64',
        },
      ],
      outputs: [
        {
          type: 'List<Address>',
        },
      ],
    },
    {
      name: 'redeem',
      mutability: 'mutable',
      payableInTokens: ['*'],
      inputs: [],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      docs: [
        'Dummy endpoint used for proposals that are only intended for polls.',
      ],
      name: 'poll',
      mutability: 'readonly',
      inputs: [],
      outputs: [],
    },
    {
      name: 'changeAdmin',
      mutability: 'mutable',
      inputs: [
        {
          name: 'new_admin',
          type: 'Address',
        },
      ],
      outputs: [],
    },
    {
      name: 'revokeAdmin',
      mutability: 'mutable',
      inputs: [],
      outputs: [],
    },
    {
      name: 'changeQuorum',
      mutability: 'mutable',
      inputs: [
        {
          name: 'new_value',
          type: 'BigUint',
        },
      ],
      outputs: [],
    },
    {
      name: 'changeMinTokenBalanceForProposing',
      mutability: 'mutable',
      inputs: [
        {
          name: 'new_value',
          type: 'BigUint',
        },
      ],
      outputs: [],
    },
    {
      name: 'changeVotingDelayInBlocks',
      mutability: 'mutable',
      inputs: [
        {
          name: 'new_value',
          type: 'u64',
        },
      ],
      outputs: [],
    },
    {
      name: 'changeVotingPeriodInBlocks',
      mutability: 'mutable',
      inputs: [
        {
          name: 'new_value',
          type: 'u64',
        },
      ],
      outputs: [],
    },
    {
      name: 'changeGovernanceTokenId',
      mutability: 'mutable',
      inputs: [
        {
          name: 'token_id',
          type: 'TokenIdentifier',
        },
      ],
      outputs: [],
    },
    {
      name: 'getAdmin',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getGovernanceTokenId',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'TokenIdentifier',
        },
      ],
    },
    {
      name: 'getQuorum',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      name: 'getMinAmountForProposal',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'BigUint',
        },
      ],
    },
    {
      name: 'getVotingDelayInBlocks',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'u64',
        },
      ],
    },
    {
      name: 'getVotingPeriodInBlocks',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'u64',
        },
      ],
    },
    {
      name: 'getProposal',
      mutability: 'readonly',
      inputs: [
        {
          name: 'id',
          type: 'u64',
        },
      ],
      outputs: [
        {
          type: 'Proposal',
        },
      ],
    },
    {
      name: 'getProposalIdCounter',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'u64',
        },
      ],
    },
    {
      name: 'getVoteNFTId',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'TokenIdentifier',
        },
      ],
    },
    {
      name: 'getProposalStatus',
      mutability: 'readonly',
      inputs: [
        {
          name: 'proposal_id',
          type: 'u64',
        },
      ],
      outputs: [
        {
          type: 'ProposalStatus',
        },
      ],
    },
  ],
  events: [
    {
      identifier: 'propose',
      inputs: [
        {
          name: 'caller',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'proposal',
          type: 'Proposal',
          indexed: true,
        },
        {
          name: 'timestamp',
          type: 'u64',
          indexed: true,
        },
        {
          name: 'epoch',
          type: 'u64',
        },
      ],
    },
    {
      identifier: 'upvote',
      inputs: [
        {
          name: 'caller',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'proposal',
          type: 'Proposal',
          indexed: true,
        },
        {
          name: 'amount',
          type: 'BigUint',
          indexed: true,
        },
        {
          name: 'timestamp',
          type: 'u64',
          indexed: true,
        },
        {
          name: 'epoch',
          type: 'u64',
        },
      ],
    },
    {
      identifier: 'downvote',
      inputs: [
        {
          name: 'caller',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'proposal',
          type: 'Proposal',
          indexed: true,
        },
        {
          name: 'amount',
          type: 'BigUint',
          indexed: true,
        },
        {
          name: 'timestamp',
          type: 'u64',
          indexed: true,
        },
        {
          name: 'epoch',
          type: 'u64',
        },
      ],
    },
    {
      identifier: 'execute',
      inputs: [
        {
          name: 'caller',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'proposal',
          type: 'Proposal',
          indexed: true,
        },
        {
          name: 'timestamp',
          type: 'u64',
          indexed: true,
        },
        {
          name: 'epoch',
          type: 'u64',
        },
      ],
    },
    {
      identifier: 'redeem',
      inputs: [
        {
          name: 'caller',
          type: 'Address',
          indexed: true,
        },
        {
          name: 'proposal',
          type: 'Proposal',
          indexed: true,
        },
        {
          name: 'payment',
          type: 'EsdtTokenPayment',
          indexed: true,
        },
        {
          name: 'vote_attr',
          type: 'VoteNFTAttributes',
          indexed: true,
        },
        {
          name: 'timestamp',
          type: 'u64',
          indexed: true,
        },
        {
          name: 'epoch',
          type: 'u64',
        },
      ],
    },
  ],
  hasCallback: false,
  types: {
    SyncCallData: {
      type: 'struct',
      fields: [
        {
          name: 'to',
          type: 'Address',
        },
        {
          name: 'egld_amount',
          type: 'BigUint',
        },
        {
          name: 'gas_limit',
          type: 'u64',
        },
        {
          name: 'endpoint_name',
          type: 'bytes',
        },
        {
          name: 'arguments',
          type: 'List<bytes>',
        },
      ],
    },
    Metadata: {
      type: 'struct',
      fields: [
        {
          name: 'upgradable',
          type: 'bool',
        },
        {
          name: 'readable',
          type: 'bool',
        },
        {
          name: 'payable',
          type: 'bool',
        },
        {
          name: 'payable_by_sc',
          type: 'bool',
        },
      ],
    },
    UpgradeData: {
      type: 'struct',
      fields: [
        {
          name: 'sc_address',
          type: 'Address',
        },
        {
          name: 'gas_limit',
          type: 'u64',
        },
        {
          name: 'egld_amount',
          type: 'BigUint',
        },
        {
          name: 'source',
          type: 'Address',
        },
        {
          name: 'code_metadata',
          type: 'Metadata',
        },
        {
          name: 'arguments',
          type: 'List<bytes>',
        },
      ],
    },
    Action: {
      type: 'struct',
      fields: [
        {
          name: 'action_type',
          type: 'ActionType',
        },
        {
          name: 'sync_call_data',
          type: 'Option<SyncCallData>',
        },
        {
          name: 'async_call_data',
          type: 'Option<AsyncCallData>',
        },
        {
          name: 'deploy_data',
          type: 'Option<DeployData>',
        },
        {
          name: 'upgrade_data',
          type: 'Option<UpgradeData>',
        },
      ],
    },
    ActionType: {
      type: 'enum',
      variants: [
        {
          name: 'SendTransferExecute',
          discriminant: 0,
        },
        {
          name: 'SendAsyncCall',
          discriminant: 1,
        },
        {
          name: 'SCDeployFromSource',
          discriminant: 2,
        },
        {
          name: 'SCUpgradeFromSource',
          discriminant: 3,
        },
      ],
    },
    AsyncCallData: {
      type: 'struct',
      fields: [
        {
          name: 'to',
          type: 'Address',
        },
        {
          name: 'egld_amount',
          type: 'BigUint',
        },
        {
          name: 'endpoint_name',
          type: 'bytes',
        },
        {
          name: 'arguments',
          type: 'List<bytes>',
        },
      ],
    },
    DeployData: {
      type: 'struct',
      fields: [
        {
          name: 'gas_limit',
          type: 'u64',
        },
        {
          name: 'egld_amount',
          type: 'BigUint',
        },
        {
          name: 'source',
          type: 'Address',
        },
        {
          name: 'code_metadata',
          type: 'Metadata',
        },
        {
          name: 'arguments',
          type: 'List<bytes>',
        },
      ],
    },
    EsdtTokenPayment: {
      type: 'struct',
      fields: [
        {
          name: 'token_identifier',
          type: 'TokenIdentifier',
        },
        {
          name: 'token_nonce',
          type: 'u64',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
      ],
    },
    Proposal: {
      type: 'struct',
      fields: [
        {
          name: 'id',
          type: 'u64',
        },
        {
          name: 'creation_block',
          type: 'u64',
        },
        {
          name: 'published_block',
          type: 'Option<u64>',
        },
        {
          name: 'proposer',
          type: 'Address',
        },
        {
          name: 'description',
          type: 'bytes',
        },
        {
          name: 'published',
          type: 'bool',
        },
        {
          name: 'executed',
          type: 'bool',
        },
        {
          name: 'actions',
          type: 'List<Action>',
        },
        {
          name: 'num_upvotes',
          type: 'BigUint',
        },
        {
          name: 'num_downvotes',
          type: 'BigUint',
        },
        {
          name: 'deployment_addresses',
          type: 'List<Address>',
        },
      ],
    },
    ProposalStatus: {
      type: 'enum',
      variants: [
        {
          name: 'Building',
          discriminant: 0,
        },
        {
          name: 'Pending',
          discriminant: 1,
        },
        {
          name: 'Active',
          discriminant: 2,
        },
        {
          name: 'Defeated',
          discriminant: 3,
        },
        {
          name: 'Succeeded',
          discriminant: 4,
        },
        {
          name: 'Executed',
          discriminant: 5,
        },
      ],
    },
    VoteNFTAttributes: {
      type: 'struct',
      fields: [
        {
          name: 'proposal_id',
          type: 'u64',
        },
        {
          name: 'vote_type',
          type: 'VoteType',
        },
        {
          name: 'voter',
          type: 'Address',
        },
        {
          name: 'payment',
          type: 'EsdtTokenPayment',
        },
      ],
    },
    VoteType: {
      type: 'enum',
      variants: [
        {
          name: 'Upvote',
          discriminant: 0,
        },
        {
          name: 'DownVote',
          discriminant: 1,
        },
      ],
    },
  },
};

export default governanceABI;
