import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { CSSProperties } from 'react';

import useTailwindBreakpoint from '@/hooks/useTailwindBreakpoint';
import useTheme from '@/hooks/useTheme';

import Hint from '@/components/Hint';
import State from '@/components/popups/RewardsPopup/components/Icons/State';

import { useAppDispatch, useAppSelector } from '@/store';
import { boosterSelector } from '@/store/booster';
import { openPopup } from '@/store/popup';

import clsxm from '@/services/clsxm';
import { formatNumber } from '@/utils/helpers';
import { Trans } from 'react-i18next';

const Reward = ({
  symbol,
  name,
  logo,
  decimals,
  amountFullDecimals,
  determined = true,
  badge = '',
  state,
}: {
  symbol: string;
  name: string;
  logo: string;
  decimals: any;
  amountFullDecimals: string;
  determined?: boolean;
  badge?: string;
  state?: boolean;
}) => {
  const { isDark } = useTheme();
  const isLargeScreen = useTailwindBreakpoint('lg');
  const dispatch = useAppDispatch();
  const { percentageBoost } = useAppSelector(boosterSelector);

  const scrollToTop = () => {
    const popupContainer = document.querySelector('.popup-container'); // Replace with your popup container selector
    if (popupContainer) {
      popupContainer.scrollTop = 0;
    }
  };

  const openRewards = () => {
    dispatch(
      openPopup({
        name: 'airdrop_analytics',
      }),
    );
    scrollToTop();
  };

  return (
    <div
      className={classNames(
        'flex items-center font-semibold border-[#DCDCE3] bg-[#F9F9FE] text-[#535367] dark:border-[rgb(46,44,81)] dark:bg-[#34315A] dark:text-[#A5A3C6]',
        'lg:h-fit lg:text-[16px] lg:leading-[24px] lg:border-[1.5px] lg:py-[8.5px] lg:px-[8.6px] lg:rounded-[10px]',
        'h-[44px] text-[10px] leading-[18.62px] rounded-[8px] border p-[7px] relative',
      )}
    >
      <div className={clsxm('relative lg:mr-3', 'mr-2')}>
        {badge && (
          <img
            className={clsxm('absolute top-0 right-0', 'w-[13.5px]')}
            src={badge}
            alt={name}
          />
        )}
        <img
          className={classNames(
            'gradient-border rounded-full dark:border-[#C4CAFF]',
          )}
          style={
            {
              ['--border-size']: isLargeScreen ? '2px' : '1.55px',
              ['--content-background']: '#fff0',
              ['--linear-gradient']: isDark
                ? '#455488'
                : 'linear-gradient(90deg, #C2C7FF 0%, #CED3FF 100%)',
            } as CSSProperties
          }
          width={isLargeScreen ? 36 : 28}
          height={isLargeScreen ? 36 : 28}
          src={logo}
          alt={name}
        />
      </div>
      <span
        className={classNames(
          'flex gap-1 items-center',
          'mr-auto capitalize',
          'lg:text-[16px] lg:mb-[0px]',
          'text-[12px] mb-[-1px]',
          {
            'text-[#3C3A40] dark:text-white': true,
          },
        )}
      >
        {name}
        {state && (
          <Hint
            autoReplace
            content={
              <div className={clsxm('')}>
                <Trans
                  i18nKey='tooltip-429zi'
                  components={[<span className='text-[#6BD1FF]' />]}
                  ns='translation'
                />
              </div>
            }
          >
            <button onClick={openRewards} className='translate-y-0.5'>
              <State />
            </button>
          </Hint>
        )}
      </span>
      <Hint
        autoReplace
        content={
          <div
            className={clsxm('max-w-[258px] md:max-w-[278px] lg:max-w-[322px]')}
          >
            <Trans
              i18nKey='tooltip-429zj'
              components={[
                <span className='text-[#6BD1FF]' />,
                <span className='text-[#E24949]' />,
                <span className='text-[#429E76]' />,
                <span className='text-[#F0B90B]' />,
              ]}
              ns='translation'
            />
          </div>
        }
      >
        <div className='lg:text-[14px] lg:leading-[24px] lg:mt-0 mt-[-0.4px]'>
          {determined ? (
            <>
              <Hint content={formatNumber(amountFullDecimals, decimals)}>
                <span>{formatNumber(amountFullDecimals)}</span>
              </Hint>
              <span className='text-right uppercase lg:ml-[4px] ml-[2.5px]'>
                {symbol}
              </span>
            </>
          ) : (
            <span>To be determined</span>
          )}
        </div>
      </Hint>

      {!determined && (
        <Hint
          autoReplace
          containerClassName='translate-y-[52px] -translate-x-4 translate-y-14 lg:-translate-x-10'
          content={
            <div
              className={clsxm(
                'max-w-[264px] md:max-w-[270px] lg:max-w-[358px]',
              )}
            >
              <Trans
                i18nKey='tooltip-429zh'
                components={[
                  <span className='text-[#6BD1FF]' />,
                  <span className='text-[#F0B90B]' />,
                  <span className='text-[#E24949]' />,
                  <span className='text-[#37CE86]' />,
                  <span className='text-[#E04040]' />,
                ]}
                ns='translation'
              />
            </div>
          }
        >
          <div
            className={classNames(
              'h-[9px]',
              'w-[3px]',
              'absolute',
              'rounded-xl',
              'top-[50%]',
              'left-[3px]',
              'translate-y-[-50%]',
              new DefiUtils(percentageBoost).isGreaterThanOrEqualTo(200)
                ? ['bg-[#51C392]', '[box-shadow:0px_0px_3px_0px_#51C392]']
                : ['bg-[#E24949]', '[box-shadow:0px_0px_2px_#FF813A]'],
            )}
          />
        </Hint>
      )}
    </div>
  );
};

export default Reward;
