import React from 'react';

const Plus = ({ className }: { className?: string }) => {
  return (
    <svg
      width='8'
      height='8'
      viewBox='0 0 8 8'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_10008_14965)'>
        <path
          d='M1.10156 4H6.8973'
          stroke='#359FFF'
          strokeOpacity='0.8'
          strokeWidth='1.32655'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.98438 1.09766V6.89339'
          stroke='#359FFF'
          strokeOpacity='0.8'
          strokeWidth='1.32655'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_10008_14965'>
          <rect width='8' height='8' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Plus;
