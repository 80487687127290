import { useCallback, useEffect } from 'react';

import { PROPOSAL_STATUS, setGovernance } from '@/store/governance';
import { useAppDispatch } from '@/store/index';

import useVotesPopulated from '@/contexts/GovernanceContext/hooks/useVotesPopulated';

const useStoreVotesAvailableToRedeem = () => {
  const dispatch = useAppDispatch();
  const votesPopulated = useVotesPopulated();

  const calcVotesAvailableToRedeem = useCallback(() => {
    return votesPopulated
      .filter(({ proposalStatus }) =>
        [
          PROPOSAL_STATUS.succeeded,
          PROPOSAL_STATUS.executed,
          PROPOSAL_STATUS.defeated,
        ].includes((proposalStatus || '') as PROPOSAL_STATUS)
      )
      .map(({ tokenIdentifier, proposalId }) => ({
        proposalId,
        voteId: parseInt(tokenIdentifier.split('-')[2], 16),
      }));
  }, [votesPopulated]);

  useEffect(() => {
    const votesAvailableToRedeem = calcVotesAvailableToRedeem();

    dispatch(
      setGovernance({
        votesAvailableToRedeem,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calcVotesAvailableToRedeem]);
};

export default useStoreVotesAvailableToRedeem;
