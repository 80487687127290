export const getColor = (percentage: number) => {
  if (percentage >= 0 && percentage < 50) return 'bg-[#E04040]';
  if (percentage >= 50 && percentage < 100) return 'bg-[#FAD03B]';
  if (percentage >= 100 && percentage < 150) return 'bg-[#37CE86]';
  if (percentage >= 150 && percentage < 200) return 'bg-[#2B8EE9]';
  if (percentage >= 200) return 'bg-[#2B8EE9]';
};

export const getRotateDeg = (percentage: number) => {
  percentage == 100 && (percentage += 0.5);
  percentage == 0 && (percentage -= 0.5);
  const startDeg = -144.4;
  const endDeg = 0;
  return startDeg + (endDeg - startDeg) * (percentage / 200.0);
};

export const getDateWithTime = (date: string, at: boolean = false) => {
  const dateParsed = new Date(date);

  const datePart = dateParsed.toLocaleDateString();
  const hour = dateParsed.getHours();
  const minutes = dateParsed.getMinutes() < 10 ? `0${dateParsed.getMinutes()}` : dateParsed.getMinutes();

  return `${datePart} ${at ? 'at': ''} ${hour}:${minutes}`;
};
