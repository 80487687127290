import { useEffect, useRef } from 'react';

import useToggle from '@/hooks/useToggle';

export function useCollapse<T extends HTMLElement>({
  defaultValue,
  callback,
}: any) {
  const [isExpanded, _toggle, set] = useToggle(defaultValue);

  useEffect(() => {
    set(defaultValue);
  }, [defaultValue]);

  const ref = useRef<T>(null);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.classList.add(
      'max-h-[0px]',
      'transition-all',
      'overflow-hidden'
    );
  }, [ref]);

  const hide = () => {
    if (!ref.current) return;
    ref.current.style.maxHeight = '';
  };

  const show = () => {
    if (!ref.current) return;
    ref.current.style.maxHeight = ref?.current?.scrollHeight + 'px';
  };

  useEffect(() => {
    if (isExpanded) show();
    else hide();
  }, [isExpanded]);

  const toggle = (value?: boolean) => {
    if (value) {
      if (value) show();
      else hide();
    } else _toggle();
    callback && callback();
  };

  return [ref, isExpanded, toggle] as const;
}
