import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import { EXCHANGE_RATE_KEY } from '@/hooks/protocol/useExchangeRate';

import { FeaturedTokenCheckBox } from '@/sections/Liquid/App/Form/components/FeaturedTokenCheckBox';
import { Label } from '@/sections/Liquid/App/Form/components/Label';

export const ChooseToken: React.FC<any> = ({
  checked = EXCHANGE_RATE_KEY.sEGLD,
  onChange,
  className,
}) => {
  const [selectedToken, setSelectedToken] = useState(checked);
  const { t } = useTranslation('liquid-app');

  useEffect(() => {
    onChange && onChange(selectedToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedToken]);

  return (
    <div className={classNames(className)}>
      <Label label={t('safety-app:choose-token')} className='mb-4' />

      <div className='flex gap-3 sm:gap-4'>
        <FeaturedTokenCheckBox
          poweredEGLD={EXCHANGE_RATE_KEY.sEGLD}
          value={selectedToken === EXCHANGE_RATE_KEY.sEGLD}
          onClick={() => {
            setSelectedToken(EXCHANGE_RATE_KEY.sEGLD);
          }}
          data-testid='token:segld'
        />
        <FeaturedTokenCheckBox
          poweredEGLD={EXCHANGE_RATE_KEY.HsEGLD}
          value={selectedToken === EXCHANGE_RATE_KEY.HsEGLD}
          onClick={() => {
            setSelectedToken(EXCHANGE_RATE_KEY.HsEGLD);
          }}
          data-testid='token:hsegld'
        />
      </div>
    </div>
  );
};
