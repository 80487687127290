import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { Trans, useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import Hint from '@/components/Hint';
import InfoIcon from '@/components/Icons/InfoIcon';

import { governanceSelector } from '@/store/governance';
import { useAppSelector } from '@/store/index';

import { useHintProps } from '@/sections/Liquid/App/global';
import { formatNumber } from '@/utils/helpers';

export const Header = () => {
  const defaultHintProps = useHintProps();
  const { t } = useTranslation('governance');

  const { userBalances } = useAppSelector(governanceSelector);

  const formattedVotes = useMemo(() => {
    const total = Object.values(userBalances)
      .reduce((prev, current) => prev.plus(current.amount), new DefiUtils(0))
      .toString();

    return formatNumber(total, 4, DefiUtils.ROUND_FLOOR);
  }, [userBalances]);

  return (
    <div className={classNames('mb-10')}>
      <div
        className={classNames(
          'flex',
          'items-center',
          'gap-1',
          'mb-0.5',
          'leading-[24px]',
          'text-sm',
          'text-[#7264C9]',
        )}
      >
        {t('voting-power', 'Voting Power')}
        <Hint
          {...defaultHintProps}
          autoReplace={true}
          content={
            <div
              className={classNames(
                'max-w-[400px] tracking-[0.01em]',
              )}
            >
              <Trans
                i18nKey={`Your <0>Voting Power</0> is determined by the amount of <0>HTM</0> tokens you have staked in Governance. Your <0>Voting Power</0> depends on your staked <0>HTM</0> tokens in Governance and applies to the upcoming proposal, as long as you don't unstake tokens before the <1>Snapshot</1>. You can use this Voting Power for multiple proposals simultaneously. You can vote on multiple proposals at the same time using your <0>Voting Power</0>.`}
                components={[
                  <span className='text-[#38A98A]' />,
                  <span className='text-[#F0B90B]' />,
                ]}
              />
            </div>
          }
        >
          <InfoIcon width={11} height={11} strokeWidth='1.5' />
        </Hint>
      </div>

      <div className={classNames('text-[32px]', 'leading-[40px]', 'font-bold')}>
        <span>{formattedVotes.split('.')[0]}</span>
        <span className={classNames('text-[#535367]', 'dark:text-[#625e8f]')}>
          .{formattedVotes.split('.')[1]}
        </span>
      </div>
    </div>
  );
};
