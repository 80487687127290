import React from 'react';

const TransactionIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='3em'
      height='3em'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill='none'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M21 6H3m18 0l-4 4m4-4l-4-4M3 18h18M3 18l4 4m-4-4l4-4'
      />
    </svg>
  );
};

export default TransactionIcon;
