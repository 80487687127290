import classNames from 'classnames';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import React, { ReactNode, useCallback, useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import ClosePopupBtn from '@/components/ClosePopupBtn';
import GenericWrapper from '@/components/popups/GenericWrapper';
import ScrollBlockTargetElement from '@/components/ScrollBlockTargetElement';

import { useAppDispatch } from '@/store/index';
import { closePopup } from '@/store/popup';

import clsxm from '@/services/clsxm';

const PopupBed: React.FC<{
  children: ReactNode;
  width?: number | string | 'auto';
  enableScaling?: boolean;
  secondaryPopupContent?: ReactNode;
  close?: boolean;
  maxWidth?: number;
  overlayClassName?: string;
  scaleClassName?: string;
  className?: string;
}> = ({
  children,
  secondaryPopupContent,
  width = 454,
  maxWidth,
  close = true,
  enableScaling = true,
  overlayClassName = '',
  scaleClassName = '',
  className = '',
}) => {
    const ref = useCallback((node: any) => {
      if (!node) return;

      const focusableSelector =
        'button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]), details:not([disabled]), summary:not(:disabled)';
      const selection = [...node.querySelectorAll(focusableSelector)];
      const firstElement = selection[1];
      firstElement?.focus();
    }, []);

    const dispatch = useAppDispatch();
    const refPop = useRef<HTMLDivElement>(null);
    useOnClickOutside(refPop, () => {
      dispatch(closePopup());
    });

    return (
      <GenericWrapper overlayProps={{ className: overlayClassName }}>
        <LazyMotion features={domAnimation}>
          <m.div
            className='relative'
            initial={{ y: 4, opacity: 0.5, scale: 0.99 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            transition={{ duration: 0.2, ease: 'linear' }}
          >
            <div
              ref={refPop}
              className={clsxm(
                enableScaling && 'scale-75 sm:scale-90 md:scale-100',
                scaleClassName,
              )}
            >
              {!!secondaryPopupContent && (
                <m.div
                  initial={{ y: 4, opacity: 0.5, scale: 0.99 }}
                  animate={{ y: 0, opacity: 1, scale: 1 }}
                  transition={{ duration: 0.1, ease: 'linear' }}
                  className={clsxm(
                    'absolute z-[9999] w-fit h-fit overflow-hidden',
                    'bg-white dark:bg-[#191733] border-[#E7E7F9] dark:border-[#2c2b4c]',
                    'lg:left-[calc(100%+6px)] lg:top-[0px] lg:border-2 lg:rounded-[16px]', //top-0 bring this up to design team and ahmed
                    'md:left-[calc(100%+7px)] md:top-0 border-[1.7px]  md:rounded-[10.5px]',
                    'bottom-[calc(100%+5.25px)] right-0 border-[1.7px] rounded-[12px]',
                  )}
                >
                  {secondaryPopupContent}
                </m.div>
              )}

              <ScrollBlockTargetElement
                className={classNames(
                  'overflow-scroll hideScrollbar border-[#E7E7F9]  dark:border-[#2c2b4c] bg-white dark:bg-[#191733]',
                  'lg:rounded-[19px] lg:border-[2px]',
                  'rounded-[12px] md:border-[1px]',
                  'rounded-[14px] border-[1.75px]',
                  className,
                )}
                style={{
                  ...(maxWidth ? { maxWidth } : { minWidth: width }),
                }}
              >
                <div
                  ref={ref}
                  className={classNames(
                    'relative',
                    'md:max-h-[calc(100vh-2rem)] ',
                    'min-h-[10rem]',
                    'min-w-[10rem]',
                    'leading-tight tracking-normal',
                    'text-[#3C3A40] dark:text-white',
                  )}
                >
                  {close && (
                    <div className='absolute right-4 top-5 z-10'>
                      <ClosePopupBtn
                        theme={{ dark: 'white', light: '#1A1A1A' }}
                        iconClassName='w-[11px]'
                      />
                    </div>
                  )}

                  {children}
                </div>
              </ScrollBlockTargetElement>
            </div>
          </m.div>
        </LazyMotion>
      </GenericWrapper>
    );
  };

export default PopupBed;
