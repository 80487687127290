import TransactionIcon from '@/components/Icons/TransactionIcon';

const NoTransaction = () => {
  return (
    <div className='mt-20 flex flex-col items-center gap-4'>
      <TransactionIcon />
      <p className='text-xl font-semibold opacity-50'>No Transactions</p>
    </div>
  );
};

export default NoTransaction;
