import { motion } from 'framer-motion'

import { walletItemsAnimationsTransition } from '@/components/popups/ConnectBridgeWalletPopup/components/wallets/constants';


function BridgeWalletShape2({ className, fill, hoverFill, onClick }: { className?: string, fill: string, hoverFill: string, onClick: () => void }) {

  const variants = {
    normal: {
      fill,
    },
    hover: {
      fill: hoverFill,
    },
    transition: walletItemsAnimationsTransition
  }

  return (<svg className={className} width="188" height="217" viewBox="0 0 188 217" fill="none" xmlns="http://www.w3.org/2000/svg">
    <motion.path
      onClick={onClick}
      variants={variants}
      d="M0 56.0226C0 43.0403 12.1765 33.4986 24.7823 36.6028L54.2935 43.8702C58.5846 44.9269 63.1048 44.5326 67.1481 42.7489L159.782 1.88397C173.002 -3.94803 187.854 5.73317 187.854 20.1825V150.781C187.854 159.536 182.16 167.273 173.801 169.876L25.9465 215.92C13.0719 219.929 0 210.309 0 196.824V56.0226Z"
      fill={fill}
    />
  </svg>
  );

}

export default BridgeWalletShape2;