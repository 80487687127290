import { Dispatch, SetStateAction, useEffect } from 'react';

import { useEffectOnlyOnUpdate } from '@/hooks/tools/useEffectOnlyOnUpdate';

import { useAppDispatch, useAppSelector } from '@/store';
import { loginInfoSelector, setLoginInfoState } from '@/store/auth';

import useApiNetworkProviderSync from '@/contexts/AuthContext/hooks/useApiNetworkProviderSync';
import { STORAGE_KEYS } from '@/contexts/AuthContext/hooks/useElrondNetworkSync';
import { JSONSafeParse } from '@/utils/helpers';

const useLoginInfoSync = ({
  setLoginInfoDone,
}: {
  setLoginInfoDone: Dispatch<SetStateAction<boolean>>;
}) => {
  useApiNetworkProviderSync();

  const dispatch = useAppDispatch();
  const loginInfoSnap = useAppSelector(loginInfoSelector);

  useEffect(() => {
    const loginInfoStorage = localStorage.getItem(STORAGE_KEYS.LOGIN_INFO_KEY);

    const parsedStorage = JSONSafeParse(loginInfoStorage || 'null', null);

    if (
      parsedStorage &&
      parsedStorage?.loginMethod !== '' &&
      typeof parsedStorage?.expires === 'number' &&
      parsedStorage?.expires !== 0
    ) {
      dispatch(setLoginInfoState('loginMethod', parsedStorage.loginMethod));
      dispatch(setLoginInfoState('expires', parsedStorage.expires));
      dispatch(setLoginInfoState('loginToken', parsedStorage.loginToken));
      dispatch(setLoginInfoState('signature', parsedStorage.signature));
      setLoginInfoDone(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectOnlyOnUpdate(() => {
    localStorage.setItem(
      STORAGE_KEYS.LOGIN_INFO_KEY,
      JSON.stringify(loginInfoSnap)
    );
  }, [
    loginInfoSnap.loginMethod,
    loginInfoSnap.expires,
    loginInfoSnap.loginToken,
    loginInfoSnap.signature,
  ]);
};

export default useLoginInfoSync;
