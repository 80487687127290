import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useMemo, useState } from 'react';

import useMediaQuery from '@/hooks/useMediaQuery';

import ClosePopupBtn from '@/components/ClosePopupBtn';
import PositionBlock from '@/components/popups/LendingCurrentPositionPopup/components/PositionBlock';
import TitleButton from '@/components/popups/LendingCurrentPositionPopup/components/TitleButton';
import PopupBed from '@/components/popups/PopupBed';

import { boosterSelector } from '@/store/booster';
import { useAppDispatch, useAppSelector } from '@/store/index';
import { lendAppSelector } from '@/store/lend-app';
import { closePopup } from '@/store/popup';
import {
  htmMarketSelector,
  MARKET_KEY,
  nativeMarketSelector,
  protocolSelector,
  wtaoMarketSelector,
} from '@/store/protocol';
import { rewardBatchSelector } from '@/store/reward-batch';

import { formatNumber } from '@/utils/helpers';

const accentColorMap = {
  [MARKET_KEY.EGLD]: `#22F6DD`,
  [MARKET_KEY.MEX]: `#3555F7`,
  [MARKET_KEY.HTM]: `#5AF1B0`,
  [MARKET_KEY.RIDE]: `#68017E`,
  [MARKET_KEY.USDC]: `#2775CA`,
  [MARKET_KEY.USDT]: `#53AE94`,
  [MARKET_KEY.BUSD]: `#F0B90B`,
  [MARKET_KEY.UTK]: `#6932D4`,
  [MARKET_KEY.sEGLD]: `#006FFF`,
  [MARKET_KEY.WBTC]: `#E39652`,
  [MARKET_KEY.WETH]: `#687DE3`,
} as Record<string, string>;

const LendingCurrentPositionPopup = () => {
  const dispatch = useAppDispatch();
  const { markets } = useAppSelector(lendAppSelector);
  const { showLiquidStakingAPY, showLiquidStakingTaoAPY } =
    useAppSelector(lendAppSelector);
  const {
    liquidStaking: { apy: liquidStakingAPY },
    liquidStakingTao: { apy: liquidStakingTaoAPY },
  } = useAppSelector(protocolSelector);
  const htmMarket = useAppSelector(htmMarketSelector);
  const { markets: rewardMarkets } = useAppSelector(rewardBatchSelector);
  const { userBalances } = useAppSelector(protocolSelector);
  const { boosterAccount, totalBoosterCollateralMoneyMarkets } =
    useAppSelector(boosterSelector);
  const nativeMarket = useAppSelector(nativeMarketSelector);
  const wtaoMarket = useAppSelector(wtaoMarketSelector);

  const { t } = useTranslation();

  const supplyTotalPositionUSD = useMemo(() => {
    return Object.values(markets)
      .reduce((prev, { accountBalances, hTokenExchangeRate, underlying }) => {
        const totalSupplyUSD = new DefiUtils(accountBalances.collateral)
          .plus(accountBalances.hTokenWallet)
          .toUnderlying(hTokenExchangeRate)
          .toFullDecimals(underlying.decimals)
          .toUSD(underlying.priceUSD)
          .toString();

        return prev.plus(totalSupplyUSD);
      }, new DefiUtils(0))
      .toString();
  }, [markets]);

  const getLiquidItem = ({
    assetKey,
    amountUSD,
  }: {
    assetKey: string;
    amountUSD: string;
  }) => {
    if (assetKey !== MARKET_KEY.sEGLD) {
      return null;
    }

    const liquidAnnualReturnUSD = new DefiUtils(amountUSD)
      .multipliedBy(liquidStakingAPY)
      .dividedBy(100)
      .toString();

    const liquidAnnualReturn = new DefiUtils(liquidAnnualReturnUSD)
      .dividedBy(nativeMarket.underlying.priceUSD)
      .toString();

    return {
      token: nativeMarket.underlying.symbol,
      value: liquidAnnualReturn,
      valueUSD: liquidAnnualReturnUSD,
      liquidStakingAPY: true,
    };
  };

  const getLiquidTaoItem = ({
    assetKey,
    amountUSD,
  }: {
    assetKey: string;
    amountUSD: string;
  }) => {
    if (assetKey !== MARKET_KEY.sWTAO) {
      return null;
    }

    const liquidAnnualReturnUSD = new DefiUtils(amountUSD)
      .multipliedBy(liquidStakingTaoAPY)
      .dividedBy(100)
      .toString();

    const liquidAnnualReturn = new DefiUtils(liquidAnnualReturnUSD)
      .dividedBy(wtaoMarket.underlying.priceUSD)
      .toString();

    return {
      token: wtaoMarket.underlying.symbol,
      value: liquidAnnualReturn,
      valueUSD: liquidAnnualReturnUSD,
      liquidStakingAPY: true,
    };
  };

  const getMarketItem = ({
    assetKey,
    amount,
    marketAPY,
    priceUSD,
  }: {
    assetKey: string;
    amount: string;
    marketAPY: string;
    priceUSD: string;
  }) => {
    const marketAnnualReturn = new DefiUtils(amount)
      .multipliedBy(marketAPY)
      .dividedBy(100);
    const marketAnnualReturnUSD = new DefiUtils(
      marketAnnualReturn,
    ).multipliedBy(priceUSD);

    return {
      token: assetKey,
      value: marketAnnualReturn.toString(),
      valueUSD: marketAnnualReturnUSD.toString(),
    };
  };

  const getBoostersRewardsItems = ({
    accountBoosterAPY,
    underlyingCollateralBalanceUSD,
    rewardSymbol,
    marketPriceUSD,
  }: {
    accountBoosterAPY: string;
    underlyingCollateralBalanceUSD: string;
    rewardSymbol: string;
    marketPriceUSD: string;
  }) => {
    const rewardAnnualReturnUSD = new DefiUtils(underlyingCollateralBalanceUSD)
      .multipliedBy(accountBoosterAPY)
      .dividedBy(100);

    const rewardAnnualReturn = rewardAnnualReturnUSD.fromUSD(marketPriceUSD);

    return [
      {
        token: rewardSymbol,
        value: rewardAnnualReturn.toString(),
        valueUSD: rewardAnnualReturnUSD.toString(),
      },
    ];
  };

  const getControllerRewardsItems = (
    rewardsToken: { marketAPY: string; symbol: string; priceUSD: string }[],
    balanceUSD: string,
  ) => {
    return rewardsToken.map(({ marketAPY, symbol, priceUSD }) => {
      const rewardAnnualReturnUSD = new DefiUtils(balanceUSD)
        .multipliedBy(marketAPY)
        .dividedBy(100);
      const rewardAnnualReturn = rewardAnnualReturnUSD.dividedBy(priceUSD);

      return {
        token: symbol,
        value: rewardAnnualReturn.toSafeString(),
        valueUSD: rewardAnnualReturnUSD.toSafeString(),
      };
    });
  };

  const supplyData = useMemo(() => {
    return Object.values(markets)
      .filter(
        ({ accountBalances }) =>
          !new DefiUtils(accountBalances.collateral)
            .plus(accountBalances.hTokenWallet)
            .isZero(),
      )
      .map(
        ({
          assetKey,
          accountBalances,
          hTokenExchangeRate,
          underlying,
          supplyAPY,
          logo,
        }) => {
          const rewardsSupplyToken = (
            rewardMarkets?.[assetKey as MARKET_KEY]?.rewards || []
          )
            .filter(({ type }) => type === 'Supply')
            .filter(({ speed }) => new DefiUtils(speed).isGreaterThan('0'));

          const underlyingCollateralBalance = new DefiUtils(
            accountBalances.collateral,
          )
            .toUnderlying(hTokenExchangeRate)
            .toFullDecimals(underlying.decimals)
            .toString();

          const underlyingCollateralBalanceUSD = new DefiUtils(
            underlyingCollateralBalance,
          )
            .toUSD(underlying.priceUSD)
            .toString();

          const underlyingTokenAccountBalance = new DefiUtils(
            accountBalances.hTokenWallet,
          )
            .toUnderlying(hTokenExchangeRate)
            .toFullDecimals(underlying.decimals)
            .toString();

          const totalSupply = new DefiUtils(underlyingCollateralBalance)
            .plus(underlyingTokenAccountBalance)
            .toString();

          const totalSupplyUSD = new DefiUtils(totalSupply).multipliedBy(
            underlying.priceUSD,
          );

          const percentage = new DefiUtils(totalSupplyUSD)
            .multipliedBy(100)
            .dividedBy(supplyTotalPositionUSD)
            .toNumber();

          const liquidItem = getLiquidItem({
            assetKey,
            amountUSD: totalSupplyUSD.toString(),
          });

          const liquidTaoItem = getLiquidTaoItem({
            assetKey,
            amountUSD: totalSupplyUSD.toString(),
          });

          const marketItem = getMarketItem({
            assetKey,
            amount: totalSupply,
            marketAPY: supplyAPY,
            priceUSD: underlying.priceUSD,
          });

          const controllerRewardsItems = getControllerRewardsItems(
            rewardsSupplyToken.map(({ apy, symbol, priceUSD }) => ({
              marketAPY: apy,
              symbol,
              priceUSD,
            })),
            underlyingCollateralBalanceUSD,
          );

          const accountBoosterAPY =
            boosterAccount[assetKey]?.accountBoosterApy || '0';

          const boostersRewardsItems =
            accountBoosterAPY === '0'
              ? []
              : getBoostersRewardsItems({
                  accountBoosterAPY,
                  underlyingCollateralBalanceUSD,
                  rewardSymbol: boosterAccount[assetKey]?.reward.symbol,
                  marketPriceUSD: boosterAccount[assetKey]?.reward.price || '0',
                });

          const controllerRewardsItemsMap = controllerRewardsItems.reduce(
            (prev, current) => ({
              ...prev,
              [current.token]: current,
            }),
            {} as Record<
              string,
              { token: string; value: string; valueUSD: string }
            >,
          );

          const boostersRewardsItemsMap = boostersRewardsItems.reduce(
            (prev, current) => ({
              ...prev,
              [current.token]: current,
            }),
            {} as Record<
              string,
              { token: string; value: string; valueUSD: string }
            >,
          );

          const rewardsItems = [
            ...new Set([
              ...Object.keys(controllerRewardsItemsMap),
              ...Object.keys(boostersRewardsItemsMap),
            ]),
          ].map((token) => {
            const controllerRewardsItem = controllerRewardsItemsMap[token];
            const boostersRewardsItem = boostersRewardsItemsMap[token];

            return {
              token,
              value: new DefiUtils(controllerRewardsItem?.value || 0)
                .plus(boostersRewardsItem?.value || 0)
                .toString(),
              valueUSD: new DefiUtils(controllerRewardsItem?.valueUSD || 0)
                .plus(boostersRewardsItem?.valueUSD || 0)
                .toString(),
            };
          });

          return {
            id: assetKey,
            token: assetKey,
            tokenImg: logo.normal,
            accentColor: accentColorMap[assetKey] || '#22F6DD',
            value: totalSupply.toString(),
            valueUSD: totalSupplyUSD.toString(),
            percentage,
            returns: [
              ...(liquidItem && showLiquidStakingAPY ? [liquidItem] : []),
              ...(liquidTaoItem && showLiquidStakingTaoAPY
                ? [liquidTaoItem]
                : []),
              marketItem,
              ...(!new DefiUtils(underlyingCollateralBalance).isZero()
                ? rewardsItems
                : []),
            ],
            debts: [],
          };
        },
      )
      .sort((a, b) => b.percentage - a.percentage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    markets,
    rewardMarkets,
    supplyTotalPositionUSD,
    boosterAccount,
    totalBoosterCollateralMoneyMarkets,
    htmMarket.underlying.decimals,
    htmMarket.underlying.priceUSD,
    userBalances,
    showLiquidStakingAPY,
  ]);

  const supplyTotalExpectedAnnualReturnUSD = useMemo(() => {
    return supplyData
      .reduce((prev, { returns }) => {
        const totalUSD = returns
          .reduce((prev, { valueUSD }) => prev.plus(valueUSD), new DefiUtils(0))
          .toString();

        return prev.plus(totalUSD);
      }, new DefiUtils(0))
      .toString();
  }, [supplyData]);

  const borrowTotalPositionUSD = useMemo(() => {
    return Object.values(markets)
      .reduce((prev, { underlying, accountBalances }) => {
        const valueUSD = new DefiUtils(accountBalances.borrow)
          .toFullDecimals(underlying.decimals)
          .toUSD(underlying.priceUSD)
          .toString();

        return prev.plus(valueUSD);
      }, new DefiUtils(0))
      .toString();
  }, [markets]);

  const borrowData = useMemo(() => {
    return Object.values(markets)
      .filter(
        ({ accountBalances }) =>
          !new DefiUtils(accountBalances.borrow).isZero(),
      )
      .map(({ accountBalances, underlying, assetKey, borrowAPY, logo }) => {
        const rewardsBorrowToken = (
          rewardMarkets[assetKey as MARKET_KEY]?.rewards || []
        )
          .filter(({ type }) => type === 'Borrow')
          .filter(({ speed }) => new DefiUtils(speed).isGreaterThan('0'));

        const underlyingBorrowBalance = new DefiUtils(accountBalances.borrow)
          .toFullDecimals(underlying.decimals)
          .toString();

        const underlyingBorrowBalanceUSD = new DefiUtils(
          underlyingBorrowBalance,
        )
          .toUSD(underlying.priceUSD)
          .toString();

        const percentage = new DefiUtils(underlyingBorrowBalanceUSD)
          .multipliedBy(100)
          .dividedBy(borrowTotalPositionUSD)
          .toNumber();

        const marketItem = getMarketItem({
          assetKey,
          amount: underlyingBorrowBalance,
          marketAPY: borrowAPY,
          priceUSD: underlying.priceUSD,
        });

        const liquidItem = getLiquidItem({
          assetKey,
          amountUSD: underlyingBorrowBalanceUSD,
        });

        const rewardsTokenItems = getControllerRewardsItems(
          rewardsBorrowToken.map(({ apy, symbol, priceUSD }) => ({
            marketAPY: apy,
            symbol,
            priceUSD,
          })),
          underlyingBorrowBalanceUSD,
        );

        return {
          id: assetKey,
          token: assetKey,
          tokenImg: logo.normal,
          accentColor: accentColorMap[assetKey] || '#22F6DD',
          value: underlyingBorrowBalance,
          valueUSD: underlyingBorrowBalanceUSD,
          percentage,
          returns: [...rewardsTokenItems],
          debts: [
            ...(liquidItem && showLiquidStakingAPY ? [liquidItem] : []),
            marketItem,
          ],
        };
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markets, rewardMarkets, borrowTotalPositionUSD, showLiquidStakingAPY]);

  const borrowTotalExpectedAnnualReturnUSD = useMemo(() => {
    return borrowData
      .reduce((prev, { returns, debts }) => {
        const totalDebtsUSD = debts
          .reduce((prev, { valueUSD }) => prev.plus(valueUSD), new DefiUtils(0))
          .toString();

        const totalReturnsUSD = returns
          .reduce((prev, { valueUSD }) => prev.plus(valueUSD), new DefiUtils(0))
          .toString();

        const totalUSD = new DefiUtils(totalDebtsUSD)
          .minus(totalReturnsUSD)
          .toString();

        return prev.minus(totalUSD);
      }, new DefiUtils(0))
      .toString();
  }, [borrowData]);

  const totalExpectedAnnualReturnUSD = useMemo(() => {
    return new DefiUtils(supplyTotalExpectedAnnualReturnUSD)
      .plus(borrowTotalExpectedAnnualReturnUSD)
      .toString();
  }, [supplyTotalExpectedAnnualReturnUSD, borrowTotalExpectedAnnualReturnUSD]);

  const lg = useMediaQuery('(min-width: 1024px )');
  const md = useMediaQuery('(min-width: 768px )');

  const [selectedTab, setSelectedTab] = useState<0 | 1 | null>(0);

  useEffect(() => {
    setSelectedTab(md ? null : 0);
  }, [md]);

  return (
    <PopupBed enableScaling={false} close={false} width='auto'>
      <div
        className={classNames(
          'p-5 font-semibold leading-tight shadow-xl sm:min-w-[495px]',
          'md:min-w-[738px]',
        )}
      >
        <div className='flex items-center justify-between text-black dark:text-white'>
          <h5 className='text-[16px] capitalize tracking-wide'>
            {t('current-position', 'Current position')}
          </h5>
          <button
            className='transition-all hover:scale-105 hover:opacity-80'
            onClick={() => {
              dispatch(closePopup());
            }}
          >
            <ClosePopupBtn
              theme={{ light: '#3C3A40', dark: 'white' }}
              iconClassName='w-[11px]'
            />
          </button>
        </div>
        <div className='mt-4 space-y-4'>
          <div className='flex-xy-center pt-4 sm:pb-4 md:hidden'>
            <TitleButton
              borrow={false}
              button
              selected={selectedTab == 0}
              onClick={() => setSelectedTab(0)}
            />
            <TitleButton
              borrow
              button
              selected={selectedTab == 1}
              onClick={() => setSelectedTab(1)}
            />
          </div>
          {((md && selectedTab == null) || (!md && selectedTab == 0)) && (
            <PositionBlock
              data={supplyData}
              totalExpectedAnnualReturnUSD={supplyTotalExpectedAnnualReturnUSD}
            />
          )}
          <hr className='hidden border-t-2 border-[#D1D1EA] md:block' />
          {((md && selectedTab == null) || (!md && selectedTab == 1)) && (
            <PositionBlock
              borrow
              data={borrowData}
              totalExpectedAnnualReturnUSD={borrowTotalExpectedAnnualReturnUSD}
            />
          )}
          <hr className='hidden border-t-2 border-[#D1D1EA] md:block' />
          <div className='rounded-[12px] bg-[#535367] p-2 text-center text-[10px] text-white sm:p-3 sm:text-[12px]'>
            {t(
              'estimation-based-projection',
              'Based on our current estimates, your position is projected to generate an annual revenue of',
            )}{' '}
            <span
              className={classNames(
                new DefiUtils(totalExpectedAnnualReturnUSD).isNegative()
                  ? 'text-[#E45059]'
                  : 'text-[#4FD1A6]',
              )}
            >
              ≈{' '}
              {new DefiUtils(totalExpectedAnnualReturnUSD).isNegative() && '-'}$
              {formatNumber(
                new DefiUtils(totalExpectedAnnualReturnUSD).abs().toString(),
                2,
              )}
            </span>
          </div>
        </div>
      </div>
    </PopupBed>
  );
};

export default LendingCurrentPositionPopup;
