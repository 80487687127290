const SafetyBalanceIcon = ({
  fill,
  ...rest
}: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.4129 2.91821H5.58782C3.95169 2.91821 2.625 4.24421 2.625 5.88035V11.1465C2.625 12.7826 3.95169 14.1093 5.58782 14.1093H12.4122C14.049 14.1093 15.375 12.7826 15.375 11.1465V5.88035C15.375 4.24421 14.049 2.91821 12.4129 2.91821Z'
        stroke={fill}
        strokeWidth='1.08'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5739 8.51415C10.5739 9.38321 9.86952 10.0869 9.00118 10.0869C8.13212 10.0869 7.42773 9.38321 7.42773 8.51415C7.42773 7.6451 8.13212 6.94141 9.00118 6.94141C9.86952 6.94141 10.5739 7.6451 10.5739 8.51415Z'
        stroke={fill}
        strokeWidth='1.08'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 11.4003V10.0874'
        stroke={fill}
        strokeWidth='1.08'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 6.94475V5.62427'
        stroke={fill}
        strokeWidth='1.08'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.42423 8.51294H6.11133'
        stroke={fill}
        strokeWidth='1.08'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.891 8.51294H10.5781'
        stroke={fill}
        strokeWidth='1.08'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.95508 15.0814V14.0718'
        stroke={fill}
        strokeWidth='1.08'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.0508 15.0814V14.0718'
        stroke={fill}
        strokeWidth='1.08'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SafetyBalanceIcon;
