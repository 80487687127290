import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import {
  formatHistoryPoints,
  formatMarkets,
  formatTotalBorrowUSD,
  formatTotalSupplyUSD,
} from '@/store/parsers/market-parser';
import { addAction } from '@/store/queue';

import blockchainService from '@/services/blockchain';
import * as indexerService from '@/services/indexer';
import logger from '@/utils/logger';
import { MAX_CACHE_TIME, queryClient } from '@/utils/query';

export interface MarketReward {}

export interface RateItem {
  x: string;
  y: string;
}

export interface Market {
  name: string;
  underlying: {
    id: string;
    symbol: string;
    decimals: number;
  };
  totalSupply: string;
  supplyCap: string;
  totalBorrowing: string;
  borrowCap: string;
  totalSupplyUSD: string;
  totalBorrowingUSD: string;
  supplyAPY: string;
  borrowAPY: string;
  supplyRewards: MarketReward[];
  borrowRewards: MarketReward[];
  collateralFactor: string;
  oraclePriceUSD: string;
  reserveFactor: string;
  liquidationThreshold: string;
  liquidationPenalty: string;
  exchangeRate: string;
  utilization: string;
  logo: string;
  supported: boolean;
  borrowRates: RateItem[];
  supplyRates: RateItem[];
}

export interface HistoryPoint {
  date: string;
  totalBorrowAPY: string;
  totalBorrowUSD: string;
  totalSupplyAPY: string;
  totalSupplyUSD: string;
}

export interface MarketState {
  totalSupplyUSD: string | any;
  totalBorrowUSD: string | any;
  historyPoints: HistoryPoint[];
  markets: Market[];
}

const initialState: MarketState = {
  totalSupplyUSD: '0',
  totalBorrowUSD: '0',
  markets: [],
  historyPoints: [],
};

export const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    setMarket: (state, action: PayloadAction<Partial<MarketState>>) => {
      Object.entries(action.payload).map(([key, value]) => {
        state[key as keyof MarketState] = value;
      });
    },
  },
});

export const { setMarket } = marketSlice.actions;

export const MAX_RATES_LENGTH = 100;

export const getMarkets =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();

      const responseMarkets = await blockchainService.moneyMarket.getMarkets(
        Object.values(state.protocol.markets).map(
          ({ address, underlying }) => ({
            address,
            symbol: underlying.symbol,
          }),
        ),
      );
      const markets = formatMarkets(responseMarkets, state.protocol);
      const totalSupplyUSD = formatTotalSupplyUSD(markets);
      const totalBorrowUSD = formatTotalBorrowUSD(markets);

      // aca_aca:rerun if change interest rate model or new mm
      // console.log(
      //   JSON.stringify(
      //     Object.entries(markets).reduce(
      //       (prev, [, { borrowRates, supplyRates, underlying }]) => ({
      //         ...prev,
      //         [underlying.symbol]: {
      //           borrowRates,
      //           supplyRates,
      //         },
      //       }),
      //       {},
      //     ),
      //   ),
      // );

      await dispatch(
        addAction(
          setMarket({
            markets,
            totalSupplyUSD,
            totalBorrowUSD,
          }),
        ),
      );
    } catch (error) {
      logger.error('store:getMarkets', error);
      captureException(error);
    }
  };

export const getHistoryPoints =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();
      const { markets } = state.protocol;

      if (state.market.historyPoints.length !== 0) {
        return;
      }

      const marketHistoryPoints = await queryClient.safeFetchQuery({
        queryKey: ['marketHistoryPoints'],
        queryFn: () =>
          Promise.all(
            Object.values(markets)
              .filter(({ supported }) => supported)
              .map(({ address, underlying }) =>
                indexerService.getHistoryPoints(address, underlying.symbol, 30),
              ),
          ),
        cacheTime: MAX_CACHE_TIME,
        staleTime: MAX_CACHE_TIME,
        defaultValue: [] as indexerService.ResponseHistoryPoint[],
      });

      const historyPoints = formatHistoryPoints(marketHistoryPoints, markets);

      await dispatch(addAction(setMarket({ historyPoints })));
    } catch (error) {
      logger.error('store:getHistoryPoints', error);
      captureException(error);
    }
  };

export const marketSelector = (state: RootState) => state.market;

export default marketSlice.reducer;
