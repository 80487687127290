import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppDispatch, GetRootState, RootState } from '@/store/index';

export interface IndexerState {
  isLoadingFirstInfo: boolean;
  isLoadingInfo: boolean;
  hasFirstInfo: boolean;
  hasFirstAccountInfo: boolean;
  hasProtocolInfo: boolean;
}

const initialState: IndexerState = {
  isLoadingFirstInfo: true,
  isLoadingInfo: false,
  hasFirstInfo: false,
  hasFirstAccountInfo: false,
  hasProtocolInfo: false,
};

export const indexerSlice = createSlice({
  name: 'indexer',
  initialState,
  reducers: {
    setIndexer: (state, action: PayloadAction<Partial<IndexerState>>) => {
      Object.entries(action.payload).map(([key, value]) => {
        state[key as keyof IndexerState] = value;
      });
    },
  },
});

export const { setIndexer } = indexerSlice.actions;

export const getIsLoadingInfo =
  () => (dispatch: AppDispatch, getState: GetRootState) => {
    const state = getState();
    return state.indexer.isLoadingInfo;
  };

export const indexerSelector = (state: RootState) => state.indexer;

export default indexerSlice.reducer;
