import useMutationObserver from '@rooks/use-mutation-observer';
import classNames from 'classnames';
import { useMemo, useRef } from 'react';

import { TX_STATUS } from '@/hooks/core/useSignMultipleTransactions';

import TxPhase from '@/components/popups/LiquidPendingTransactionPopup/components/TxPhase';
import PopupBed from '@/components/popups/PopupBed';

import { useAppSelector } from '@/store';
import { popupSelector } from '@/store/popup';
import { transactionSelector } from '@/store/transaction';

const LiquidPendingTransactionPopup = () => {
  const { data } = useAppSelector(popupSelector);
  const { currentTransactions } = useAppSelector(transactionSelector);

  const txStatus = useMemo(() => {
    return (data?.txStatus || TX_STATUS.AWAITING_CONFIRMATION) as TX_STATUS;
  }, [data?.txStatus]);

  const statusData = useMemo(() => {
    switch (txStatus) {
      case TX_STATUS.COMPLETED: {
        const hasFailTxs = currentTransactions.every(({ status }) =>
          ['fail', 'invalid'].includes(status)
        );

        return hasFailTxs ? 'fail' : 'success';
      }
      default: {
        return null;
      }
    }
  }, [currentTransactions, txStatus]);

  const isVisible = (_txStatus: TX_STATUS, _statusData: any) => {
    return txStatus == _txStatus && statusData == _statusData;
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const recalculateContainerHeight = () => {
    if (!containerRef.current) return;
    const activePanel = containerRef.current.querySelector('.active-panel');
    if (!activePanel) return;

    const firstChild = activePanel.firstChild as HTMLElement | null;
    if (!firstChild) return;

    const h = firstChild.scrollHeight;
    containerRef.current.style.height = `${h}px`;
    containerRef.current?.scrollTo(0, 0);
  };

  useMutationObserver(containerRef, recalculateContainerHeight);

  const getAnimationClassNames = (visible = true) => {
    return classNames(
      'transition-all ease-in-out',
      visible
        ? 'active-panel z-10 duration-700 opacity-100 scale-100'
        : 'duration-300  opacity-0 scale-[0.98]'
    );
  };

  return (
    <PopupBed close={false}>
      <div
        ref={containerRef}
        className='relative isolate overflow-hidden transition-all duration-700 ease-in-out'
      >
        {[
          [TX_STATUS.AWAITING_CONFIRMATION, null],
          [TX_STATUS.SENT, null],
          [TX_STATUS.COMPLETED, 'success'],
          [TX_STATUS.COMPLETED, 'fail'],
          [TX_STATUS.FAILED, null],
        ].map(([txStatus, statusData], i) => (
          <div
            key={i}
            data-testid={`txPopup:${txStatus?.toLowerCase()}:${statusData?.toLowerCase()}`}
            className={classNames(
              'absolute inset-0',
              getAnimationClassNames(
                isVisible(txStatus as TX_STATUS, statusData)
              )
            )}
          >
            <TxPhase txStatus={txStatus} statusData={statusData} />
          </div>
        ))}
      </div>
    </PopupBed>
  );
};

export default LiquidPendingTransactionPopup;
