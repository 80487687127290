import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { AnimatePresence, domAnimation, LazyMotion, m } from 'framer-motion';
import { kebabCase } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useMemo, useRef } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useOnClickOutside, useToggle } from 'usehooks-ts';

import ProposalItem, { ProposalItemProps } from '@/components/ProposalItem';
import WrappedImg from '@/components/WrappedImg';

import {
    governanceSelector,
    PROPOSAL_TAG,
} from '@/store/governance';
import { useAppSelector } from '@/store/index';
import { indexerSelector } from '@/store/indexer';
import { protocolSelector } from '@/store/protocol';

import { proposalTags } from '@/sections/Governance';
import {
    FilterButton,
    FilterTags,
} from '@/sections/Governance/ActiveProposals/components';

export const ActiveProposals = ({
  selectedFilterTag,
  setSelectedFilterTag,
}: {
  selectedFilterTag: string;
  setSelectedFilterTag: (tag: string) => void;
}) => {
  const {
    governance: { blockNonce, votingDelayInBlocks, votingPeriodInBlocks },
  } = useAppSelector(protocolSelector);
  const { proposals } = useAppSelector(governanceSelector);
  const [isFilterOpen, toggleIsFilterOpen] = useToggle(false);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('governance');

  useOnClickOutside(ref, () => {
    if (isFilterOpen) toggleIsFilterOpen();
  });

  const activeProposals = useMemo(() => {
    return proposals
      // .filter(({ status }) => status === PROPOSAL_STATUS.active)
      .map(
        ({
          id,
          title,
          status,
          description,
          downvotes,
          upvotes,
          percentageDownVotes,
          percentageUpVotes,
          publishedBlock,
        }) => {
          const votingStart = new DefiUtils(publishedBlock).plus(
            votingDelayInBlocks,
          );
          const votingEnd = votingStart.plus(votingPeriodInBlocks);
          const votingEndInMiliseconds = new DefiUtils(votingEnd)
            .minus(blockNonce)
            .multipliedBy(6)
            .dividedBy(3600)
            .multipliedBy('3.6e+6')
            .toString();

          const voteEndTimestamp = new Date(
            new Date().getTime() + parseFloat(votingEndInMiliseconds),
          ).toISOString();

          return {
            id,
            tag: PROPOSAL_TAG.lendingProtocol,
            title,
            status,
            voteEndTimestamp,
            finishTimestamp: voteEndTimestamp,
            upvotes,
            percentageUpVotes,
            downvotes,
            percentageDownVotes,
            description,
          };
        },
      ) as ProposalItemProps[];
  }, [blockNonce, proposals, votingDelayInBlocks, votingPeriodInBlocks]);

  const { isLoadingFirstInfo } = useAppSelector(indexerSelector);

  const filteredActiveProposals = useMemo(() => {
    if (selectedFilterTag === '') {
      return activeProposals;
    }

    return activeProposals.filter(
      (proposalItem) => selectedFilterTag === (proposalItem?.tag || ''),
    );
  }, [activeProposals, selectedFilterTag]);

  return (
    <div className={classNames('mb-2')}>
      <div className={classNames('flex', 'justify-between', 'mb-2')}>
        <div
          className={classNames('text-[#006FFF]', 'text-[16px] font-semibold')}
        >
          {t('active-proposals', 'Active Proposals')}
        </div>

        {/* filter */}
        <div className={classNames('relative', 'z-10 mt-1')} ref={ref}>
          <FilterButton
            selectedFilterTag={selectedFilterTag}
            onClick={toggleIsFilterOpen}
          />

          <AnimatePresence>
            {isFilterOpen && (
              <LazyMotion features={domAnimation}>
                <m.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.3 }}
                >
                  <FilterTags
                    items={proposalTags.map((e) => ({
                      ...e,
                      name: t(kebabCase(e.name).toLowerCase(), e.name),
                    }))}
                    value={selectedFilterTag}
                    onClick={(id) => {
                      setSelectedFilterTag(id === selectedFilterTag ? '' : id);
                      toggleIsFilterOpen();
                    }}
                  />
                </m.div>
              </LazyMotion>
            )}
          </AnimatePresence>
        </div>
      </div>

      {/* proposals */}
      <div className={classNames('flex', 'flex-col', 'mb-6 gap-6')}>
        {isLoadingFirstInfo ? (
          <Skeleton width='100%' height={100} className='!rounded-[20px]' />
        ) : (
          <>
            {filteredActiveProposals.length === 0 && (
              <LazyMotion features={domAnimation}>
                <m.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 10 }}
                  transition={{ duration: 0.3 }}
                  className={classNames('flex w-full justify-center')}
                >
                  <WrappedImg
                    src='https://cdn.app.hatom.com/images/governance/proposal/no-active.svg'
                    alt='no active proposals'
                    className='h-full w-full max-w-[329px]'
                  />
                </m.div>
              </LazyMotion>
            )}
            {filteredActiveProposals.map((proposal) => (
              <ProposalItem key={proposal.id} {...proposal} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};
