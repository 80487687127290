export function GriffinAnimHardcoded() {
  return (
    <>
      <style jsx>{`
        @keyframes circleMaskAnim {
          0% {
            r: 0%;
          }
          100% {
            r: 70%;
          }
        }
      `}</style>
      <svg
        width='109'
        height='114'
        viewBox='0 0 109 114'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <mask
          id='circleMask'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
        >
          <circle
            style={{ animation: 'circleMaskAnim 2s ease-in-out infinite' }}
            cx='50%'
            cy='50%'
            r='50%'
            stroke='black'
            strokeWidth='20'
          />
        </mask>
        <g style={{ opacity: 0.3 }}>
          <path
            d='M98.514 64.7162C112.014 47.5162 108.514 23.1162 102.814 5.01624C100.814 -0.283758 95.014 -0.783758 95.214 6.21624C96.414 13.9162 97.114 23.2162 96.514 30.7162'
            stroke='#3D906A'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
          <path
            d='M102.113 59.0166C111.113 41.8166 107.713 20.4166 102.613 4.5166C101.913 2.8166 100.213 1.0166 98.1133 1.0166C100.513 11.8166 102.213 27.8166 100.513 39.8166'
            stroke='#3D906A'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
          <path
            d='M86.7139 71.7166C93.3139 69.2166 101.214 64.9166 102.014 58.4166C104.714 33.3166 88.7139 21.5166 88.7139 21.5166C88.7139 21.5166 90.8139 32.5166 89.7139 41.2166'
            stroke='#3D906A'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
          <path
            d='M87.4141 71.4166C93.8141 68.9166 101.214 64.6166 101.914 58.3166C104.714 33.3166 88.7141 21.5166 88.7141 21.5166C88.7141 21.5166 98.6141 42.2166 95.6141 51.3166C95.2141 52.7166 95.3141 52.4166 94.6141 53.8166'
            stroke='#3D906A'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
          <path
            d='M10.2137 64.7161C-3.18627 47.5161 0.413731 23.0161 6.11373 5.01606C8.11373 -0.283938 13.9137 -0.683939 13.7137 6.21606C12.5137 13.9161 11.7137 23.2161 12.3137 30.7161'
            stroke='#3D906A'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
          <path
            d='M6.71362 59.0166C-2.18638 41.7166 1.21362 20.4166 6.41362 4.5166C7.11362 2.8166 8.81362 1.0166 10.9136 1.0166C8.51362 11.8166 6.71362 27.8166 8.41362 39.8166'
            stroke='#3D906A'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
          <path
            d='M22.6134 71.8166C16.0134 69.3166 7.6134 64.9166 6.9134 58.4166C4.2134 33.3166 20.2134 21.5166 20.2134 21.5166C20.2134 21.5166 18.0134 31.1166 19.2134 39.9166'
            stroke='#3D906A'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
          <path
            d='M22.0134 71.5166C15.6134 68.9166 7.5134 64.6166 6.9134 58.4166C4.2134 33.3166 20.2134 21.5166 20.2134 21.5166C20.2134 21.5166 10.2134 42.2166 13.2134 51.3166C13.6134 52.7166 13.4134 52.4166 14.2134 53.8166'
            stroke='#3D906A'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
          <path
            d='M85.0142 35.216C84.5142 34.616 87.6142 47.216 82.7142 53.116C70.5142 66.816 60.5142 59.016 54.1142 78.116C54.0142 78.516 53.4142 72.416 46.6142 66.916C41.0142 62.316 29.4142 59.016 25.3142 53.116C20.5142 46.216 23.6142 34.916 23.3142 35.216C18.7142 39.616 12.9142 46.816 14.4142 58.216C16.3142 73.316 29.8142 73.116 36.6142 81.916C48.1142 96.816 43.4142 110.016 54.2142 113.016C57.1142 112.816 60.5142 109.016 62.3142 103.516C64.2142 97.716 65.2142 91.316 70.7142 83.316C76.9142 74.216 84.8142 74.316 89.7142 68.916C97.6142 60.016 96.2142 47.616 85.0142 35.216Z'
            stroke='#3D906A'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
        </g>

        <g mask='url(#circleMask)'>
          <path
            d='M98.514 64.7162C112.014 47.5162 108.514 23.1162 102.814 5.01624C100.814 -0.283758 95.014 -0.783758 95.214 6.21624C96.414 13.9162 97.114 23.2162 96.514 30.7162'
            stroke='url(#paint0_linear_167_1609)'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
          <path
            d='M102.113 59.0166C111.113 41.8166 107.713 20.4166 102.613 4.5166C101.913 2.8166 100.213 1.0166 98.1133 1.0166C100.513 11.8166 102.213 27.8166 100.513 39.8166'
            stroke='url(#paint1_linear_167_1609)'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
          <path
            d='M86.7139 71.7166C93.3139 69.2166 101.214 64.9166 102.014 58.4166C104.714 33.3166 88.7139 21.5166 88.7139 21.5166C88.7139 21.5166 90.8139 32.5166 89.7139 41.2166'
            stroke='url(#paint2_linear_167_1609)'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
          <path
            d='M87.4141 71.4166C93.8141 68.9166 101.214 64.6166 101.914 58.3166C104.714 33.3166 88.7141 21.5166 88.7141 21.5166C88.7141 21.5166 98.6141 42.2166 95.6141 51.3166C95.2141 52.7166 95.3141 52.4166 94.6141 53.8166'
            stroke='url(#paint3_linear_167_1609)'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
          <path
            d='M10.2137 64.7161C-3.18627 47.5161 0.413731 23.0161 6.11373 5.01606C8.11373 -0.283938 13.9137 -0.683939 13.7137 6.21606C12.5137 13.9161 11.7137 23.2161 12.3137 30.7161'
            stroke='url(#paint4_linear_167_1609)'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
          <path
            d='M6.71362 59.0166C-2.18638 41.7166 1.21362 20.4166 6.41362 4.5166C7.11362 2.8166 8.81362 1.0166 10.9136 1.0166C8.51362 11.8166 6.71362 27.8166 8.41362 39.8166'
            stroke='url(#paint5_linear_167_1609)'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
          <path
            d='M22.6134 71.8166C16.0134 69.3166 7.6134 64.9166 6.9134 58.4166C4.2134 33.3166 20.2134 21.5166 20.2134 21.5166C20.2134 21.5166 18.0134 31.1166 19.2134 39.9166'
            stroke='url(#paint6_linear_167_1609)'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
          <path
            d='M22.0134 71.5166C15.6134 68.9166 7.5134 64.6166 6.9134 58.4166C4.2134 33.3166 20.2134 21.5166 20.2134 21.5166C20.2134 21.5166 10.2134 42.2166 13.2134 51.3166C13.6134 52.7166 13.4134 52.4166 14.2134 53.8166'
            stroke='url(#paint7_linear_167_1609)'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
          <path
            d='M85.0142 35.216C84.5142 34.616 87.6142 47.216 82.7142 53.116C70.5142 66.816 60.5142 59.016 54.1142 78.116C54.0142 78.516 53.4142 72.416 46.6142 66.916C41.0142 62.316 29.4142 59.016 25.3142 53.116C20.5142 46.216 23.6142 34.916 23.3142 35.216C18.7142 39.616 12.9142 46.816 14.4142 58.216C16.3142 73.316 29.8142 73.116 36.6142 81.916C48.1142 96.816 43.4142 110.016 54.2142 113.016C57.1142 112.816 60.5142 109.016 62.3142 103.516C64.2142 97.716 65.2142 91.316 70.7142 83.316C76.9142 74.216 84.8142 74.316 89.7142 68.916C97.6142 60.016 96.2142 47.616 85.0142 35.216Z'
            stroke='url(#paint8_linear_167_1609)'
            strokeWidth='0.8504'
            strokeMiterlimit='10'
          />
        </g>

        <defs>
          <linearGradient
            id='paint0_linear_167_1609'
            x1='101.552'
            y1='64.9497'
            x2='101.552'
            y2='0.590071'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#2B4A9F' />
            <stop offset='1' stopColor='#44A474' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_167_1609'
            x1='102.851'
            y1='59.1868'
            x2='102.851'
            y2='0.600194'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#2B4A9F' />
            <stop offset='1' stopColor='#44A474' />
          </linearGradient>
          <linearGradient
            id='paint2_linear_167_1609'
            x1='104.572'
            y1='56.2018'
            x2='76.7921'
            y2='40.1633'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#1D166F' />
            <stop offset='1' stopColor='#B5D2FF' />
          </linearGradient>
          <linearGradient
            id='paint3_linear_167_1609'
            x1='104.735'
            y1='55.9072'
            x2='76.4251'
            y2='39.5625'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#1D166F' />
            <stop offset='1' stopColor='#B5D2FF' />
          </linearGradient>
          <linearGradient
            id='paint4_linear_167_1609'
            x1='0.582675'
            y1='32.7642'
            x2='14.1902'
            y2='32.7642'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#2B4A9F' />
            <stop offset='1' stopColor='#44A474' />
          </linearGradient>
          <linearGradient
            id='paint5_linear_167_1609'
            x1='0.773019'
            y1='29.8825'
            x2='11.4211'
            y2='29.8825'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#2B4A9F' />
            <stop offset='1' stopColor='#44A474' />
          </linearGradient>
          <linearGradient
            id='paint6_linear_167_1609'
            x1='6.1624'
            y1='46.3512'
            x2='22.7515'
            y2='46.3512'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#1D166F' />
            <stop offset='1' stopColor='#B5D2FF' />
          </linearGradient>
          <linearGradient
            id='paint7_linear_167_1609'
            x1='6.1556'
            y1='46.0389'
            x2='22.1996'
            y2='46.0389'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#1D166F' />
            <stop offset='1' stopColor='#B5D2FF' />
          </linearGradient>
          <linearGradient
            id='paint8_linear_167_1609'
            x1='95.1349'
            y1='74.1208'
            x2='13.7453'
            y2='74.1208'
            gradientUnits='userSpaceOnUse'
          >
            <stop stopColor='#2B4A9F' />
            <stop offset='1' stopColor='#44A474' />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}
