import { captureException } from '@sentry/nextjs';

import { useAppDispatch } from '@/store';
import { clearAuthStates } from '@/store/auth';
import { clearDappProvider, setNetworkState } from '@/store/network';

import { webviewProvider } from '@/providers/webviewProvider';

const useWebviewLogin = () => {
  const dispatch = useAppDispatch();

  const logout = async () => {
    await webviewProvider.logout();
    dispatch(clearAuthStates());
    dispatch(clearDappProvider());
  };

  const initiateLogin = async () => {
    try {
      dispatch(setNetworkState('dappProvider', webviewProvider));
    } catch (e) {
      captureException(e);
    }
  };

  return {
    logout,
    initiateLogin,
  };
};

export default useWebviewLogin;
