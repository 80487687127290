import dynamic from 'next/dynamic';
import { createContext, FC, useEffect } from 'react';

import { LiquidTaoPendingTransactionPopup } from '@/components/popups';
import HTMStakePopup from '@/components/popups/HTMStakePopup';
import PositionBalancerPopup from '@/components/popups/PositionBalancerPopup';

import { useAppSelector } from '@/store';
import { popupSelector } from '@/store/popup';

interface PopupContextProviderProps {
  children: Array<JSX.Element> | JSX.Element;
}

const HTMBoosterPopup = dynamic(
  () => import('@/components/popups/HTMBoosterPopup'),
  { ssr: false },
);
const MaiarLoginPopup = dynamic(
  () => import('@/components/popups/MaiarLoginPopup'),
  { ssr: false },
);
const AccountDetailsPopup = dynamic(
  () => import('@/components/popups/AccountDetailsPopup'),
  { ssr: false },
);
const BorrowPopup = dynamic(() => import('@/components/popups/BorrowPopup'), {
  ssr: false,
});
const ConnectWalletPopup = dynamic(
  () => import('@/components/popups/ConnectWalletPopup'),
  { ssr: false },
);
const CurrentPositionPopup = dynamic(
  () => import('@/components/popups/CurrentPositionPopup'),
  { ssr: false },
);
const HatomValidators = dynamic(
  () => import('@/components/popups/HatomValidators'),
  { ssr: false },
);
const LedgerAccountsPopup = dynamic(
  () => import('@/components/popups/LedgerAccountsPopup'),
  { ssr: false },
);
const LendingCurrentPositionPopup = dynamic(
  () => import('@/components/popups/LendingCurrentPositionPopup'),
  { ssr: false },
);
const LendingPendingTransactionPopup = dynamic(
  () => import('@/components/popups/LendingPendingTransactionPopup'),
  { ssr: false },
);
const LiquidPendingTransactionPopup = dynamic(
  () => import('@/components/popups/LiquidPendingTransactionPopup'),
  { ssr: false },
);
const PendingTransactionPopup = dynamic(
  () => import('@/components/popups/PendingTransactionPopup'),
  { ssr: false },
);
const RewardsPopup = dynamic(() => import('@/components/popups/RewardsPopup'), {
  ssr: false,
});
const XLaunchpadSnapshotPopup = dynamic(
  () => import('@/components/popups/XLaunchpadSnapshotPopup'),
  {
    ssr: false,
  },
);

const StakedHTMLockedSchedulePopup = dynamic(
  () => import('@/components/popups/StakedHTMLockedSchedulePopup'),
  { ssr: false },
);
const SupplyPopup = dynamic(() => import('@/components/popups/SupplyPopup'), {
  ssr: false,
});
const VoteV2Popup = dynamic(() => import('@/components/popups/VoteV2Popup'), {
  ssr: false,
});

const AirdropAnalyticsPopup = dynamic(
  () => import('@/components/popups/AirdropAnalyticsPopup'),
  {
    ssr: false,
  },
);
const ConnectBridgeWalletPopup = dynamic(
  () => import('@/components/popups/ConnectBridgeWalletPopup'),
  {
    ssr: false,
  },
);

const popups: Record<string, JSX.Element> = {
  borrow: <BorrowPopup />,
  supply: <SupplyPopup />,
  connectwallet: <ConnectWalletPopup />,
  maiarlogin: <MaiarLoginPopup />,
  accountdetails: <AccountDetailsPopup />,
  connect_bridge_multiverse_wallet: (
    <ConnectBridgeWalletPopup sourceType='MULTIVERSE_X' />
  ),
  pendingtransaction: <PendingTransactionPopup />,
  liquidpendingtransaction: <LiquidPendingTransactionPopup />,
  liquidtaopendingtransaction: <LiquidTaoPendingTransactionPopup />,
  lendingpendingtransaction: <LendingPendingTransactionPopup />,
  rewards: <RewardsPopup />,
  x_launchpad_snapshot: <XLaunchpadSnapshotPopup />,
  position_balancer: <PositionBalancerPopup />,
  ledgeraccounts: <LedgerAccountsPopup />,
  votev2: <VoteV2Popup />,
  htm_stake: <HTMStakePopup />,
  staked_htm_locked_schedule_popup: <StakedHTMLockedSchedulePopup />,
  hatom_validators: <HatomValidators />,
  current_position: <CurrentPositionPopup />,
  lending_current_position: <LendingCurrentPositionPopup />,
  htm_booster: <HTMBoosterPopup />,
  htm_booster_liquid: <HTMBoosterPopup isLiquid />,
  airdrop_analytics: <AirdropAnalyticsPopup />,
};

export const PopupContext = createContext({});

const PopupContextProvider: FC<PopupContextProviderProps> = (
  props: PopupContextProviderProps,
) => {
  const popup = useAppSelector(popupSelector);

  useEffect(() => {
    const feedbackEl = document.querySelector(
      '[class*="_feedback_container"]',
    ) as HTMLElement | null;

    if (!feedbackEl) {
      return;
    }

    if (popup.isOpen) {
      feedbackEl.style.display = 'none';
    } else {
      feedbackEl.style.display = 'block';
    }
  }, [popup.isOpen]);

  return (
    <PopupContext.Provider value={{}}>
      {props.children}

      {popup.isOpen && popup.name && popups[popup.name]}
    </PopupContext.Provider>
  );
};
export default PopupContextProvider;
