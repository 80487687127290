import classNames from 'classnames';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import React, { CSSProperties, ReactNode } from 'react';
import { isMobile } from 'react-device-detect';
import { Arrow, useHover, useLayer } from 'react-laag';
import { PlacementType } from 'react-laag/dist/PlacementType';

type HintType = {
  children: ReactNode;
  content: ReactNode | string;
  backgroundColor?: string;
  textColor?: string;
  placement?: PlacementType;
  unvisible?: boolean;
  hidden?: boolean;
  alwaysOpen?: boolean;
  delayEnter?: number;
  delayLeave?: number;
  layerStyle?: CSSProperties;
  block?: boolean;
  arrow?: boolean;
  offset?: number;
  interactive?: boolean;
  className?: string;
  containerClassName?: string;
  roundness?: number;
  autoReplace?: boolean;
};

const Hint = ({
  className,
  containerClassName,
  children,
  content,
  backgroundColor,
  textColor,
  placement = 'bottom-center',
  unvisible,
  hidden,
  alwaysOpen = false,
  delayEnter = 40,
  delayLeave = 80,
  layerStyle = {},
  block = false,
  arrow = false,
  offset = 10,
  interactive = false,
  roundness = 0,
  autoReplace = false,
}: HintType) => {
  const [isOver, hoverProps] = useHover({
    delayEnter,
    delayLeave,
  });

  if (unvisible) hidden = unvisible;
  if (hidden) unvisible = hidden;

  backgroundColor = backgroundColor ?? '#000';

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: alwaysOpen ? true : isOver,
    placement: placement,
    triggerOffset: offset,
    ...(autoReplace
      ? { auto: true, overflowContainer: false, arrowOffset: 16 }
      : {}),
  });

  const center = typeof content === 'string' && content.length < 2;

  return (
    <>
      <div
        {...triggerProps}
        {...hoverProps}
        className={classNames(
          '!cursor-default',
          {
            'inline-block': !block,
          },
          className
        )}
        onClick={(e) => isMobile && e.stopPropagation()}
      >
        {children}
      </div>

      {(alwaysOpen ? true : isOver) &&
        renderLayer(
          <LazyMotion features={domAnimation}>
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.1 }}
              className={classNames({
                'pointer-events-auto': interactive,
              })}
            >
              <div
                className={classNames(
                  'z-[99] min-w-[28px] rounded-md px-2 py-1.5 tracking-wide text-[8px] md:text-[10px]',
                  unvisible && 'opacity-0',
                  center && 'text-center',
                  containerClassName
                )}
                {...layerProps}
                style={{
                  ...layerProps.style,
                  backgroundColor,
                  color: textColor ?? '#fff',
                  ...layerStyle,
                }}
              >
                {content}
                {arrow && (
                  <Arrow
                    className='pointer-events-none'
                    {...arrowProps}
                    backgroundColor={backgroundColor}
                    roundness={roundness}
                  />
                )}
              </div>
            </m.div>
          </LazyMotion>
        )}
    </>
  );
};

export default Hint;
