import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { Trans, useTranslation } from 'next-i18next';
import CurrencyInput from 'react-currency-input-field';

import Hint from '@/components/Hint';
import InfoIcon from '@/components/Icons/InfoIcon';

import { useHintProps } from '@/sections/Liquid/App/global';

interface AmountInputProps {
  disabled?: boolean;
  max: string;
  value: string | undefined;
  onChange: (value: string) => void;
}

export const AmountInput = ({
  disabled,
  max,
  value,
  onChange,
}: AmountInputProps) => {
  const defaultHintProps = useHintProps();
  const { t } = useTranslation('governance');

  return (
    <label className={classNames('mb-[20px]', 'block')}>
      <span
        className={classNames(
          'text-sm',
          'flex',
          'gap-1',
          'items-center',
          'leading-[18px]',
          'mb-[20px]',
        )}
      >
        {t('voting-power', 'Voting Power')}

        <Hint
          {...defaultHintProps}
          autoReplace={true}
          content={
            <div className={classNames('max-w-[232px] tracking-[0.01em]')}>
              <Trans
                i18nKey={`Your <0>Voting Power</0> for a proposal is based on the <0>HTM</0> tokens you've staked in Governance during that proposal's <1>Snapshot</1>.`}
                components={[
                  <span className='text-[#38A98A]' />,
                  <span className='text-[#F0B90B]' />,
                ]}
              />
            </div>
          }
        >
          <InfoIcon width={11} height={11} strokeWidth='1.5' />
        </Hint>
      </span>

      <div
        className={classNames(
          'border-[1px]',
          'border-[#E7E7F9]',
          'dark:border-[#2E2C51]',
          'w-full',
          'block',
          'h-[49px]',
          'rounded-[8px]',
          'overflow-hidden',
          'py-[9.5px]',
          'px-[11px]',
          'flex',
          'gap-3',
          'bg-[#FBFAFF]',
          'dark:bg-[#292651]',
        )}
      >
        <img
          className={classNames('block', 'h-[28px]', 'w-[28px]')}
          src='https://cdn.app.hatom.com/images/tokens/HTM.svg'
          alt='Hatom'
        />

        <CurrencyInput
          autoFocus={false}
          disabled={disabled}
          className={classNames(
            'border-[0px]',
            'w-full',
            'block',
            'h-full',
            'outline-none',
            'text-sm',
            'leading-[18px]',
            'text-[#77778B]',
            'dark:text-[#8888A4]',
            'bg-transparent',
            'font-normal',
          )}
          maxLength={50}
          onValueChange={(value) => {
            if (new DefiUtils(value || '0').isGreaterThan(max)) {
              return;
            }

            onChange(value);
          }}
          placeholder='0.00'
          decimalsLimit={18}
          allowNegativeValue={false}
          autoComplete='off'
          groupSeparator=','
          decimalSeparator='.'
          value={value}
        />
      </div>
    </label>
  );
};
