import DefiUtils from 'defi-utils';

import { SECONDS_PER_DAY, WAD } from '@/data/constants';

import { H_TOKEN_DECIMALS, MARKET_KEY } from '@/store/protocol';
import { RewardBatch } from '@/store/reward-batch';

/**
 * Calculates the APY enumerator for a given token.
 * It substracts the borrow APY to the supply APY
 * @param liquidStakingAPY
 * @param showLiquidStakingAPY
 * @param liquidStakingTaoAPY
 * @param showLiquidStakingTaoAPY
 * @param tokenKey
 * @param underlyingTokenSupplyBalance
 * @param collateralUnderlyingBalance
 * @param priceUSD
 * @param supplyAPY
 * @param underlyingBorrowBalance
 * @param borrowAPY
 * @param rewardsToken
 * @param totalCollateralTokens
 * @param totalBorrows
 * @param hTokenExchangeRate
 * @param underlyingDecimals
 * @param accountBalance
 * @param hTokenAccountBalance
 * @param collateralBalance
 * @returns token APY as DefiUtils
 */
export const calcTokenApyEnumerator = (
  liquidStakingAPY: string,
  showLiquidStakingAPY: boolean,
  liquidStakingTaoAPY: string,
  showLiquidStakingTaoAPY: boolean,
  tokenKey: string,
  underlyingTokenSupplyBalance: string,
  collateralUnderlyingBalance: string,
  priceUSD: string,
  supplyAPY: string,
  underlyingBorrowBalance: string,
  borrowAPY: string,
  rewardsToken: RewardBatch[],
  hTokenExchangeRate: string,
  underlyingDecimals: number,
  accountBalance: string,
  hTokenAccountBalance: string,
  collateralBalance: string,
  accountBoosterAPY: string,
) => {
  const totalUnderlyingSupply = new DefiUtils(
    underlyingTokenSupplyBalance,
  ).plus(collateralUnderlyingBalance);

  const totalSupplyUSD = new DefiUtils(totalUnderlyingSupply)
    .toFullDecimals(underlyingDecimals)
    .toUSD(priceUSD)
    .toSafeFixed(underlyingDecimals);

  const totalSupplyUnderlying = new DefiUtils(collateralUnderlyingBalance)
    .plus(new DefiUtils(underlyingTokenSupplyBalance))
    .toString();

  const supplyRewardsTokensAPY = rewardsToken
    .filter(({ type }) => type === 'Supply')
    .reduce((prev, current) => prev.plus(current.apy), new DefiUtils('0'));

  let totalSupplyAPY = '0';

  if (tokenKey === MARKET_KEY.sEGLD && showLiquidStakingAPY) {
    totalSupplyAPY = getTotalSupplyAPYByMarketLiquidity({
      accountBalance,
      hTokenExchangeRate,
      hTokenAccountBalance,
      collateralBalance,
      liquidStakingAPY,
      supplyAPY,
      rewardsTokensAPY: supplyRewardsTokensAPY
        .plus(accountBoosterAPY)
        .toString(),
    });
  } else if (tokenKey === MARKET_KEY.sWTAO && showLiquidStakingTaoAPY) {
    totalSupplyAPY = getTotalSupplyAPYByMarketLiquidity({
      accountBalance,
      hTokenExchangeRate,
      hTokenAccountBalance,
      collateralBalance,
      liquidStakingAPY: liquidStakingTaoAPY,
      supplyAPY,
      rewardsTokensAPY: supplyRewardsTokensAPY
        .plus(accountBoosterAPY)
        .toString(),
    });
  } else {
    totalSupplyAPY = getTotalSupplyAPYByMarket({
      totalSupplyUnderlying: new DefiUtils(totalSupplyUnderlying)
        .toFullDecimals(underlyingDecimals)
        .toString(),
      collateralUnderlyingBalance: new DefiUtils(collateralUnderlyingBalance)
        .toFullDecimals(underlyingDecimals)
        .toString(),
      supplyAPY: String(supplyAPY),
      rewardsTokensAPY: supplyRewardsTokensAPY
        .plus(accountBoosterAPY)
        .toString(),
    }).toString();
  }

  const tokenSupplyAPY = new DefiUtils(totalSupplyUSD).multipliedBy(
    totalSupplyAPY.toString(),
  );

  const totalBorrowUSD = new DefiUtils(underlyingBorrowBalance)
    .toFullDecimals(underlyingDecimals)
    .toUSD(priceUSD)
    .toNumber();

  const borrowRewardsTokensAPY = rewardsToken
    .filter(({ type }) => type === 'Borrow')
    .reduce((prev, current) => prev.plus(current.apy), new DefiUtils('0'))
    .toString();

  let valueTotalBorrowAPYByMarket = '0';

  if (tokenKey === MARKET_KEY.sEGLD && showLiquidStakingAPY) {
    valueTotalBorrowAPYByMarket = getTotalBorrowAPYByMarketLiquidity({
      liquidStakingAPY,
      borrowAPY,
      rewardsTokensAPY: borrowRewardsTokensAPY,
    }).toString();
  } else if (tokenKey === MARKET_KEY.sWTAO && showLiquidStakingTaoAPY) {
    valueTotalBorrowAPYByMarket = getTotalBorrowAPYByMarketLiquidity({
      liquidStakingAPY: liquidStakingTaoAPY,
      borrowAPY,
      rewardsTokensAPY: borrowRewardsTokensAPY,
    }).toString();
  } else {
    valueTotalBorrowAPYByMarket = getTotalBorrowAPYByMarket({
      borrowAPY,
      rewardsTokensAPY: borrowRewardsTokensAPY,
    }).toString();
  }

  const tokenBorrowAPY = new DefiUtils(totalBorrowUSD).multipliedBy(
    valueTotalBorrowAPYByMarket,
  );

  const tokenAPYNumerator = new DefiUtils(tokenSupplyAPY).minus(tokenBorrowAPY);

  return tokenAPYNumerator;
};

/**
 * Function to calculate the market APY.
 * @param {string} marketRate the market rate.
 * @returns {DefiUtils} the market apy, with max 5 decimal places.
 */
export const calcMarketAPY = (marketRate: string) => {
  const SECONDS_PER_DAY = new DefiUtils(24 * 3600);
  const DAYS_PER_YEAR = new DefiUtils(365);
  const calc1 = new DefiUtils(marketRate)
    .multipliedBy(SECONDS_PER_DAY)
    .dividedBy(1e18)
    .toString();
  const calc2 = new DefiUtils(calc1).plus(new DefiUtils(1));
  const calc3 = calc2.exponentiatedBy(DAYS_PER_YEAR).toString();
  const calc4 = new DefiUtils(calc3).minus(1);
  const marketAPY = new DefiUtils(calc4).multipliedBy(100);

  const decimalPlaces = marketAPY.decimalPlaces() || 0;
  // .toString();

  return marketAPY.toFixed(
    decimalPlaces > 5 ? 5 : decimalPlaces,
    DefiUtils.ROUND_FLOOR,
  );
};

/**
 * Function to get the APY for the rewards token
 * @param {string} distributedDollarAmountPerDay
 * @param {string} totalInUSD
 * @returns {string} the APY of the reward token
 */
export const getRewardTokenAPY = (
  distributedDollarAmountPerDay: string,
  totalInUSD: string,
) => {
  if (totalInUSD === '0') {
    return '0';
  }

  return new DefiUtils(distributedDollarAmountPerDay)
    .dividedBy(totalInUSD)
    .multipliedBy(36500)
    .toString();
};

/**
 * Function to get the total borrow APY based on the market liquidity
 * All arguments wrapped in a single object as properties
 * @returns {DefiUtils} the total borrow APY
 */
export const getTotalBorrowAPYByMarketLiquidity = ({
  borrowAPY,
  rewardsTokensAPY,
  liquidStakingAPY,
}: {
  borrowAPY: string;
  rewardsTokensAPY: string;
  liquidStakingAPY: string;
}) => {
  return new DefiUtils(borrowAPY)
    .plus(liquidStakingAPY)
    .minus(rewardsTokensAPY);
};

export const getTotalBorrowAPYByMarket = ({
  borrowAPY,
  rewardsTokensAPY,
}: {
  borrowAPY: string;
  rewardsTokensAPY: string;
}) => {
  return new DefiUtils(borrowAPY).minus(rewardsTokensAPY);
};

/**
 * Function to get the total supply APY based on the market liquidity
 * All arguments wrapped in a single object as properties
 * @returns {string} total supply APY
 */
export const getTotalSupplyAPYByMarketLiquidity = ({
  accountBalance,
  hTokenExchangeRate,
  hTokenAccountBalance,
  collateralBalance,
  liquidStakingAPY,
  supplyAPY,
  rewardsTokensAPY,
}: {
  accountBalance: string;
  hTokenExchangeRate: string;
  hTokenAccountBalance: string;
  collateralBalance: string;
  liquidStakingAPY: string;
  supplyAPY: string;
  rewardsTokensAPY: string;
}) => {
  const underlyingHTokens = new DefiUtils(accountBalance)
    .toTokens(hTokenExchangeRate)
    .toFullDecimals(H_TOKEN_DECIMALS)
    .toString();
  const hTokenBalance = new DefiUtils(hTokenAccountBalance)
    .toFullDecimals(H_TOKEN_DECIMALS)
    .toString();
  const colateralBalance = new DefiUtils(collateralBalance)
    .toFullDecimals(H_TOKEN_DECIMALS)
    .toString();

  if (new DefiUtils(hTokenBalance).plus(colateralBalance).isZero()) {
    return new DefiUtils(liquidStakingAPY)
      .plus(supplyAPY)
      .plus(rewardsTokensAPY)
      .toString();
  }

  const cond1 = new DefiUtils(underlyingHTokens).multipliedBy(liquidStakingAPY);
  const cond2 = new DefiUtils(hTokenBalance).multipliedBy(
    new DefiUtils(liquidStakingAPY).plus(supplyAPY),
  );
  const cond3 = new DefiUtils(colateralBalance).multipliedBy(
    new DefiUtils(liquidStakingAPY).plus(supplyAPY).plus(rewardsTokensAPY),
  );

  const result = cond1
    .plus(cond2)
    .plus(cond3)
    .dividedBy(
      new DefiUtils(underlyingHTokens)
        .plus(hTokenBalance)
        .plus(colateralBalance),
    );

  return result.toSafeString();
};

export const getMarketAccountApy = ({}: {}) => {
  return;
};

/** Function to get the total supply APY by market
 * All arguments wrapped in a single object as properties
 * @returns {DefiUtils} total supply APY
 */
export const getTotalSupplyAPYByMarket = ({
  totalSupplyUnderlying,
  collateralUnderlyingBalance,
  supplyAPY,
  rewardsTokensAPY,
}: {
  totalSupplyUnderlying: string;
  collateralUnderlyingBalance: string;
  supplyAPY: string;
  rewardsTokensAPY: string;
}) => {
  const bnTotalSupplyUnder = new DefiUtils(totalSupplyUnderlying);
  const bnSupplyAPY = new DefiUtils(supplyAPY);
  const bnCollateralUnderBalance = new DefiUtils(collateralUnderlyingBalance);
  const bnRewardsTokensAPY = new DefiUtils(rewardsTokensAPY);

  if (bnTotalSupplyUnder.isEqualTo('0')) {
    return bnSupplyAPY.plus(bnRewardsTokensAPY);
  }

  if (bnRewardsTokensAPY.isEqualTo('0')) {
    return bnSupplyAPY;
  }

  const calc1 = bnTotalSupplyUnder.multipliedBy(bnSupplyAPY);
  const calc2 = bnCollateralUnderBalance.multipliedBy(bnRewardsTokensAPY);
  const calc3 = calc1.plus(calc2);
  const result = calc3.dividedBy(bnTotalSupplyUnder);

  return result;
};

export const getTotalRewardBorrowAPY = (
  rewardsToken: { borrowAPY: string }[],
) => {
  return rewardsToken
    .reduce((prev, { borrowAPY }) => prev.plus(borrowAPY), new DefiUtils(0))
    .toString();
};

export const getTotalBoosterAPY = ({
  speed,
  hTokenExchangeRate,
  totalCollateral,
  marketPrice,
  rewardsToken,
  marketDecimals,
}: {
  speed: string;
  totalCollateral: string;
  hTokenExchangeRate: string;
  marketPrice: string;
  rewardsToken: {
    price: string;
    decimals: number;
    token: string;
  };
  marketDecimals: number;
}) => {
  const DAYS_PER_YEAR = new DefiUtils(365);
  const secondsInAYear = new DefiUtils(SECONDS_PER_DAY).multipliedBy(
    DAYS_PER_YEAR,
  );

  const sp = new DefiUtils(speed).toFullDecimals(18 + rewardsToken?.decimals);

  const calc1 = sp
    .multipliedBy(rewardsToken?.price)
    .multipliedBy(secondsInAYear);

  const calc2 = new DefiUtils(totalCollateral)
    .toUnderlying(hTokenExchangeRate)
    .toFullDecimals(marketDecimals)
    .multipliedBy(marketPrice);

  if (calc2.isEqualTo(0)) {
    return '0';
  }

  const result = calc1.dividedBy(calc2).multipliedBy(100);

  return result.toSafeString();
};

export const getAccountBoosterAPY = ({
  speed,
  hTokenExchangeRate,
  totalCollateral,
  marketPrice,
  rewardsToken,
  marketDecimals,
  htmStakedUSD,
  moneyMarketCollateralUSD,
  hTokenBalance,
  maximumApyBooster,
}: {
  speed: string;
  totalCollateral: string;
  hTokenExchangeRate: string;
  marketPrice: string;
  rewardsToken: {
    price: string;
    decimals: number;
    token: string;
  };
  marketDecimals: number;
  htmStakedUSD: string;
  moneyMarketCollateralUSD: string;
  hTokenBalance: string;
  maximumApyBooster: string;
}) => {
  const DAYS_PER_YEAR = new DefiUtils(365);
  const secondsInAYear = new DefiUtils(SECONDS_PER_DAY).multipliedBy(
    DAYS_PER_YEAR,
  );

  const sp = new DefiUtils(speed).toFullDecimals(18 + rewardsToken?.decimals);

  const calc1 = sp
    .multipliedBy(rewardsToken?.price)
    .multipliedBy(secondsInAYear);

  const calc2 = new DefiUtils(totalCollateral)
    .toUnderlying(hTokenExchangeRate)
    .toFullDecimals(marketDecimals)
    .multipliedBy(marketPrice);

  if (calc2.isEqualTo(0)) {
    return '0';
  }

  let calc3 = new DefiUtils(htmStakedUSD)
    .dividedBy(moneyMarketCollateralUSD)
    .dividedBy(maximumApyBooster);

  if (calc3.isGreaterThanOrEqualTo(1)) {
    calc3 = new DefiUtils(1);
  }

  const hTokenSum = new DefiUtils(hTokenBalance)
    .toUSD(marketPrice)
    .multipliedBy(hTokenExchangeRate)
    .dividedBy(WAD)
    .toFullDecimals(marketDecimals)
    .plus(moneyMarketCollateralUSD);

  const calc4 = calc1.dividedBy(calc2).multipliedBy(100).multipliedBy(calc3);

  const result = calc4
    .multipliedBy(moneyMarketCollateralUSD)
    .dividedBy(hTokenSum);

  return result.toSafeString();
};

export const getAccountBoosterAPYWithPenalty = ({
  speed,
  hTokenExchangeRate,
  totalCollateral,
  marketPrice,
  rewardsToken,
  marketDecimals,
  htmStakedUSD,
  moneyMarketCollateralUSD,
  maximumApyBooster,
}: {
  speed: string;
  totalCollateral: string;
  hTokenExchangeRate: string;
  marketPrice: string;
  rewardsToken: {
    price: string;
    decimals: number;
    token: string;
  };
  marketDecimals: number;
  htmStakedUSD: string;
  moneyMarketCollateralUSD: string;
  maximumApyBooster: string;
}) => {
  const DAYS_PER_YEAR = new DefiUtils(365);
  const secondsInAYear = new DefiUtils(SECONDS_PER_DAY).multipliedBy(
    DAYS_PER_YEAR,
  );

  const sp = new DefiUtils(speed).toFullDecimals(18 + rewardsToken?.decimals);

  const calc1 = sp
    .multipliedBy(rewardsToken?.price)
    .multipliedBy(secondsInAYear);

  const calc2 = new DefiUtils(totalCollateral)
    .toUnderlying(hTokenExchangeRate)
    .toFullDecimals(marketDecimals)
    .multipliedBy(marketPrice);

  if (calc2.isEqualTo(0)) {
    return '0';
  }

  let calc3 = new DefiUtils(htmStakedUSD)
    .dividedBy(moneyMarketCollateralUSD)
    .dividedBy(maximumApyBooster);

  if (calc3.isGreaterThanOrEqualTo(1)) {
    calc3 = new DefiUtils(1);
  }

  const calc4 = calc1.dividedBy(calc2).multipliedBy(100).multipliedBy(calc3);

  return calc4.toSafeString();
};

export const getAccountPenaltyRewardsBoosterAPY = ({
  speed,
  hTokenExchangeRate,
  totalCollateral,
  marketPrice,
  rewardsToken,
  marketDecimals,
  htmStakedUSD,
  moneyMarketCollateralUSD,
  maximumApyBooster,
}: {
  speed: string;
  totalCollateral: string;
  hTokenExchangeRate: string;
  marketPrice: string;
  rewardsToken: {
    price: string;
    decimals: number;
    token: string;
  };
  marketDecimals: number;
  htmStakedUSD: string;
  moneyMarketCollateralUSD: string;
  maximumApyBooster: string;
}) => {
  const DAYS_PER_YEAR = new DefiUtils(365);
  const secondsInAYear = new DefiUtils(SECONDS_PER_DAY).multipliedBy(
    DAYS_PER_YEAR,
  );

  const sp = new DefiUtils(speed).toFullDecimals(18 + rewardsToken?.decimals);

  const calc1 = sp
    .multipliedBy(rewardsToken?.price)
    .multipliedBy(secondsInAYear);

  const calc2 = new DefiUtils(totalCollateral)
    .toUnderlying(hTokenExchangeRate)
    .toFullDecimals(marketDecimals)
    .multipliedBy(marketPrice);

  if (calc2.isEqualTo(0)) {
    return new DefiUtils(0);
  }

  let calc3 = new DefiUtils(htmStakedUSD)
    .dividedBy(moneyMarketCollateralUSD)
    .dividedBy(maximumApyBooster);

  if (calc3.isGreaterThanOrEqualTo(1)) {
    calc3 = new DefiUtils(1);
  }

  const calc4 = calc1.dividedBy(calc2).multipliedBy(100).multipliedBy(calc3);

  return calc4;
};

export const calcTaoApy = (apr: string) => {
  const n = 365;

  const calc1 = new DefiUtils(apr).dividedBy(100).dividedBy(n);

  const base = new DefiUtils(1).plus(calc1);

  const exponent = new DefiUtils(n);

  const result = base.pow(exponent).minus(1).multipliedBy(100);

  return result.toSafeFixed(18);
};
