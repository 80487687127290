import client from '@/services/airdrop/client';
import { AccountSnapshotByAddress, Snapshot } from '@/services/airdrop/types';
export * from '@/services/airdrop/types';

export const getAccountByAddress = async (
  address: string,
): Promise<AccountSnapshotByAddress[]> => {
  const response = await client.get(
    `/airdrop/snapshots/accounts/${address}`,
    {},
  );
  return response.data;
};

export const getAllSnapshots = async (): Promise<Snapshot[]> => {
  const response = await client.get('/airdrop/snapshots', {});
  return response.data;
};
