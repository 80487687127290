import useMediaQuery from "@/hooks/useMediaQuery";

const NovaWallet = () => {
  const lg = useMediaQuery('(min-width: 1024px )');
  return (
    <div className='w-fit h-fit relative z-3 pl-[16.9px] lg:pl-[23px]'>
      <img
        src="https://cdn.app.hatom.com/images/pages/bridge/nova.svg"
        alt='nova logo'
        width={lg ? 56 : 40}
        height="auto"
        className="mb-[10px] lg:mb-[14px]"
      />
      <p className='pl-[6.4px] lg:pl-[8.9px] text-start text-[#D4DAE2]'>Nova</p>
      <p className='pl-[5px] lg:pl-[7px] text-start text-[#D4DAE2]'>Wallet</p>
    </div>
  )
}

export default NovaWallet 