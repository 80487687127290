import classNames from 'classnames';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

const Footer = ({ className = '', ...props }) => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        'mt-10 pt-0.5 text-center text-sm leading-tight tracking-wide',
        className
      )}
      {...props}
    >
      <div>{t('wallet-provider.new-to-elrond')}</div>

      <Link legacyBehavior href='https://wallet.multiversx.com/create'>
        <a
          className='block font-semibold leading-[17.5px] underline'
          target='_blank'
        >
          {t('wallet-provider.wallet-setup')}
        </a>
      </Link>
    </div>
  );
};

export default Footer;
