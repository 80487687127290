import SafetyBalanceIcon from '@/components/Icons/SafetyBalanceIcon';
import StakedBalanceIcon from '@/components/Icons/StakedBalanceIcon';
import WalletBalanceIcon from '@/components/Icons/WalletBalanceIcon';

import { PROPOSAL_TAG, USER_BALANCE_SOURCE } from '@/store/governance';

export const proposalTags = [
  {
    id: PROPOSAL_TAG.lendingProtocol,
    name: 'Lending Protocol',
    image: 'https://cdn.app.hatom.com/images/governance/proposal/tags/lending-protocol.svg',
  },
  {
    id: PROPOSAL_TAG.liquidStaking,
    name: 'Liquid Staking',
    image: 'https://cdn.app.hatom.com/images/governance/proposal/tags/liquid-staking.svg',
  },
  {
    id: PROPOSAL_TAG.ushStaking,
    name: 'Hatom USD',
    image: 'https://cdn.app.hatom.com/images/governance/proposal/tags/ush-staking.svg',
  },
  {
    id: PROPOSAL_TAG.isolatedPools,
    name: 'Isolated Pools',
    image: 'https://cdn.app.hatom.com/images/governance/proposal/tags/isolated-pools.svg',
  },
];

export const balancesTypesMap = {
  [USER_BALANCE_SOURCE.wallet]: WalletBalanceIcon,
  [USER_BALANCE_SOURCE.staked]: StakedBalanceIcon,
  [USER_BALANCE_SOURCE.safety]: SafetyBalanceIcon,
};
