import React from 'react';

type Props = {
  color?: string;
  className?: string;
};

const ArrowLeft = (props: Props) => {
  return (
    <svg
      width='6'
      height='9'
      viewBox='0 0 6 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={props.className}
    >
      <path
        d='M5.00325 0.84375L1.4375 4.40952L5.00325 7.97524'
        stroke={props.color || '#535367'}
        strokeWidth='1.3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ArrowLeft;
