import { loggingInSelector } from '@/store/auth';
import { useAppSelector } from '@/store/index';

export const useLoggingIn = () => {
  const loggingInSnap = useAppSelector(loggingInSelector);

  return {
    isLoggingIn: loggingInSnap.pending,
    error: loggingInSnap.error,
    isLoggedIn: loggingInSnap.loggedIn,
  };
};
