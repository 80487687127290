import { LoginMethods } from '@/config/walletProviders';
import { ExperimentalWebviewProvider } from '@/services/experimentalWebViewProvider';
import { decodeNativeAuthToken } from '@/services/nativeAuth/helpers';
import { useAppDispatch } from '@/store';
import { setAccountState, setLoginInfoState } from '@/store/auth';
import { setNetworkState } from '@/store/network';
import { getNewLoginExpiresTimestamp } from '@/utils/expiresAt';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const useLoginExperimentalWebview = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const removeQueryParam = (param: string) => {
    const { pathname, query } = router;
    const params = new URLSearchParams(query);
    params.delete(param);
    router.replace({ pathname, query: params.toString() }, undefined, {
      shallow: true,
    });
  };

  const handleInit = async () => {
    const accessToken = router.query?.accessToken as string;
    const platform = router.query?.accessToken;

    const isExperimentalWebview = !!accessToken && !!platform;

    if (!isExperimentalWebview) {
      return;
    }

    const nativeAuthInfo = decodeNativeAuthToken(accessToken);

    if (nativeAuthInfo == null) {
      return;
    }

    removeQueryParam('accessToken');
    removeQueryParam('platform');
    localStorage.clear();

    const providerInstance = ExperimentalWebviewProvider.getInstance();
    await providerInstance.init();

    dispatch(
      setLoginInfoState('loginMethod', LoginMethods.experimentalWebview),
    );
    dispatch(setLoginInfoState('loginToken', accessToken));
    dispatch(setLoginInfoState('expires', getNewLoginExpiresTimestamp()));
    dispatch(setLoginInfoState('signature', nativeAuthInfo.signature));
    dispatch(setAccountState('address', nativeAuthInfo.address));
    dispatch(setAccountState('nonce', 0));
    dispatch(setAccountState('balance', 0));
    dispatch(setNetworkState('dappProvider', providerInstance));
  };

  useEffect(() => {
    handleInit();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useLoginExperimentalWebview;
