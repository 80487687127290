import { captureException } from '@sentry/nextjs';

import { chainType, networkConfig } from '@/config/network';

export const calcUnbondDate = ({
  roundsPerEpoch,
  roundsPassed,
  unbondEpoch,
  epoch,
}: {
  roundsPerEpoch: number;
  roundsPassed: number;
  unbondEpoch: number;
  epoch: number;
}) => {
  let timeLeftToRedeem;

  try {
    const roundsToSeconds = (roundsPerEpoch - roundsPassed) * 6;
    const epochToSeconds =
      (unbondEpoch - epoch - 1) *
      parseInt(networkConfig[chainType].epochInHours) *
      60 *
      60;

    timeLeftToRedeem = (roundsToSeconds + epochToSeconds) * 1000;

    return new Date(new Date().getTime() + timeLeftToRedeem).toISOString();
  } catch (error) {
    captureException(error, {
      extra: {
        timeLeftToRedeem,
        roundsPerEpoch,
        roundsPassed,
        unbondEpoch,
        epoch,
        epochInHours: networkConfig[chainType].epochInHours,
      },
    });
    return new Date().toISOString();
  }
};
