import classNames from 'classnames';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

const Header = () => {
  const { t } = useTranslation();
  return (
    <div className='flex flex-col items-center tracking-wide'>
      <Image
        src='https://cdn.app.hatom.com/logo.svg'
        alt='Logo'
        className='h-[65px]'
        width={65}
        height={65}
      />
      <h3
        className={classNames(
          'mt-5 text-lg font-semibold leading-[23px]',
          'dark:text-white'
        )}
      >
        {t('connect-wallet')}
      </h3>
      <div
        className={classNames(
          'mt-2 leading-[20px]',
          'text-[#5C5C5C] dark:text-[#625E8F]'
        )}
      >
        {t('wallet-provider.hint')}
      </div>
    </div>
  );
};

export default Header;
