import classNames from 'classnames';
import { cloneElement } from 'react';

const CustomToast: React.FC<any> = ({ children, icon }) => {
  return (
    <div className={classNames('overflow-hidden', 'shadow-2xl')}>
      <div
        className={classNames(
          'flex items-center gap-2',
          'bg-[#FFFFFF] text-sm font-semibold text-[#202327] dark:bg-[#000000] dark:text-white',
          'rounded-md border-[3px] border-[#DADAE8] p-3 py-2.5 dark:border-[#55527D]'
        )}
      >
        {icon}
        {cloneElement(children, { style: { margin: 0 } })}
      </div>
    </div>
  );
};

export default CustomToast;
