import axios from 'axios';

import { chainType, networkConfig } from '@/config/network';

const client = axios.create({
  baseURL: networkConfig[chainType].hatomGatewayAddress,
  timeout: 60_000,
});

client.interceptors.request.use((config) => {
  window.gatewayCount = (window.gatewayCount || 0) + 1;
  return config;
});

export default client;
