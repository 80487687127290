import DefiUtils from 'defi-utils';

import { STATIC_VALIDATORS_INFO, Validator } from '@/store/liquid-staking-app';

import { chainType, networkConfig } from '@/config/network';

export const formatValidators = (
  delegationContracts: {
    totalDelegated: string;
    id?: string;
    contract?: string;
    serviceFee: string;
    pendingToDelegate: string;
  }[],
  nativeMarketUnderlyingDecimals: number,
): Validator[] => {
  return delegationContracts.map(
    ({ totalDelegated, id, contract, serviceFee, pendingToDelegate }) => {
      const address = id || contract || '';

      return {
        serviceFee,
        logo: {
          light: STATIC_VALIDATORS_INFO[address]?.logo?.light || '',
          dark:
            STATIC_VALIDATORS_INFO[address]?.logo?.dark ||
            STATIC_VALIDATORS_INFO[address]?.logo?.light ||
            '',
        },
        website: `${networkConfig[chainType].explorerAddress}/providers/${address}`,
        name: STATIC_VALIDATORS_INFO[address]?.name || 'Unknown',
        staked: new DefiUtils(totalDelegated)
          .plus(pendingToDelegate)
          .toFullDecimals(nativeMarketUnderlyingDecimals)
          .toString(),
        address,
      };
    },
  );
};
