import { domAnimation, LazyMotion, m } from 'framer-motion';
import React, { ReactNode } from 'react';

import clsxm from '@/services/clsxm';

const Tabber: React.FC<{
  onChange: Function;
  tabs: Array<string>;
  defaultSelected?: number;
  secondary?: boolean;
  children?: ReactNode;
  customTheme?: (selectedTabIndex: number | null) => string;
}> = ({
  onChange,
  tabs = ['Tab1'],
  defaultSelected,
  children,
  customTheme,
}) => {
  const act = (index: any) => {
    onChange(index);
  };

  return (
    <div>
      <div className='flex'>
        {tabs.map((t, i) => (
          <LazyMotion features={domAnimation} key={i}>
            <m.button
              data-testid={`tab:${t.toLowerCase()}`}
              className={clsxm(
                'h-[39px] flex-1 py-2 pt-[12px] pl-[1px]',
                'text-center text-xs font-semibold uppercase leading-tight tracking-wide',
                'border-b-[4px]',
                defaultSelected === i
                  ? 'border-[#006FFF] text-[#006FFF]'
                  : 'border-current text-[#535367] dark:text-[#625E8F]',
                customTheme && customTheme(defaultSelected === i ? i : null),
              )}
              onTap={() => act(i)}
            >
              {t}
            </m.button>
          </LazyMotion>
        ))}
      </div>

      {children}
    </div>
  );
};

export default Tabber;
