import DefiUtils from 'defi-utils';

import {
  TRANSACTION_SUBGROUP_TYPE,
  TransactionConfig,
} from '@/store/transaction';

export const divideValuePerMax = (value: string, max: string) => {
  const length = parseInt(
    new DefiUtils(value).dividedBy(max).toFixed(0, DefiUtils.ROUND_FLOOR),
  );

  const remainingAmount = new DefiUtils(value).modulo(max).toString();

  const hasRemaining = remainingAmount !== '0';

  return [
    ...new Array(length).fill(0).map(() => max),
    ...(hasRemaining ? [remainingAmount] : []),
  ];
};

export const getLiquidTransactionGradient = (name: string) => {
  const map = {
    [TRANSACTION_SUBGROUP_TYPE.REBALANCE_PORTFOLIO]: ['#ff4040', '#ff6363'],
    [TRANSACTION_SUBGROUP_TYPE.STAKE_HTM]: ['#ff4040', '#ff6363'],
    [TRANSACTION_SUBGROUP_TYPE.UNLOCK]: ['#ff4040', '#ff6363'],
    [TRANSACTION_SUBGROUP_TYPE.LOCK]: ['#ff4040', '#ff6363'],
    [TRANSACTION_SUBGROUP_TYPE.UNBOND]: ['#ff4040', '#ff6363'],
    [TRANSACTION_SUBGROUP_TYPE.UNBOND_ALL]: ['#ff4040', '#ff6363'],
    [TRANSACTION_SUBGROUP_TYPE.UNSTAKE_HTM]: ['#ff4040', '#ff6363'],
    [TRANSACTION_SUBGROUP_TYPE.CLAIM_HTM]: ['#ff4040', '#ff6363'],

    [TRANSACTION_SUBGROUP_TYPE.STAKE_UNDERLYING]: ['#5097FF', '#44CEFF'],
    [TRANSACTION_SUBGROUP_TYPE.STAKE_TOKEN]: ['#5097FF', '#44CEFF'],
    [TRANSACTION_SUBGROUP_TYPE.STAKE_TOKEN_COLLATERAL]: ['#5097FF', '#44CEFF'],
    [TRANSACTION_SUBGROUP_TYPE.UNSTAKE_TOKEN_COLLATERAL]: [
      '#7167E3',
      '#978FF5',
    ],
    [TRANSACTION_SUBGROUP_TYPE.UNSTAKE_TOKEN]: ['#7167E3', '#978FF5'],
    [TRANSACTION_SUBGROUP_TYPE.UNSTAKE_UNDERLYING]: ['#7167E3', '#978FF5'],
    [TRANSACTION_SUBGROUP_TYPE.CLAIM]: ['#E25F00', '#FF9C55'],
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_TOKEN_WALLET]: [
      '#1FC6A8',
      '#1EEBC6',
    ],
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_UNDERLYING_WALLET]: [
      '#1FC6A8',
      '#1EEBC6',
    ],
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_COLLATERAL]: [
      '#1FC6A8',
      '#1EEBC6',
    ],
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_WALLET]: [
      '#1FC6A8',
      '#1EEBC6',
    ],
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_UNDERLYING_WALLET]: [
      '#1FC6A8',
      '#1EEBC6',
    ],
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_TOKEN_COLLATERAL]: [
      '#1FC6A8',
      '#1EEBC6',
    ],
    [TRANSACTION_SUBGROUP_TYPE.CLAIM_REWARDS]: ['#F38FF5', '#D267E3'],
  } as Record<string, string[]>;

  return map[name] || [];
};

export const getLiquidTransactionName = (config: TransactionConfig) => {
  const map = {
    [TRANSACTION_SUBGROUP_TYPE.LOCK]: `Staking ${config.token}`,
    [TRANSACTION_SUBGROUP_TYPE.UNLOCK]: `Unstaking ${config.token}`,
    [TRANSACTION_SUBGROUP_TYPE.UNBOND]: `Claiming ${config.token}`,
    [TRANSACTION_SUBGROUP_TYPE.UNBOND_ALL]: 'Claiming',
    [TRANSACTION_SUBGROUP_TYPE.REBALANCE_PORTFOLIO]: 'Rebalancing Booster',
    [TRANSACTION_SUBGROUP_TYPE.STAKE_HTM]: 'Staking HTM',
    [TRANSACTION_SUBGROUP_TYPE.UNSTAKE_HTM]: 'Unstaking HTM',
    [TRANSACTION_SUBGROUP_TYPE.CLAIM_HTM]: 'Claiming HTM',
    [TRANSACTION_SUBGROUP_TYPE.STAKE_UNDERLYING]: 'Staking EGLD',
    [TRANSACTION_SUBGROUP_TYPE.STAKE_TOKEN]: 'Staking EGLD',
    [TRANSACTION_SUBGROUP_TYPE.STAKE_TOKEN_COLLATERAL]: 'Staking EGLD',
    [TRANSACTION_SUBGROUP_TYPE.UNSTAKE_TOKEN_COLLATERAL]: 'Unstaking EGLD',
    [TRANSACTION_SUBGROUP_TYPE.UNSTAKE_TOKEN]: 'Unstaking EGLD',
    [TRANSACTION_SUBGROUP_TYPE.UNSTAKE_UNDERLYING]: 'Unstaking EGLD',
    [TRANSACTION_SUBGROUP_TYPE.CLAIM]: 'Claiming EGLD',
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_TOKEN_WALLET]:
      'Migrating EGLD',
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_COLLATERAL_TO_UNDERLYING_WALLET]:
      'Migrating EGLD',
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_COLLATERAL]:
      'Migrating EGLD',
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_UNDERLYING_WALLET_TO_TOKEN_WALLET]:
      'Migrating EGLD',
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_UNDERLYING_WALLET]:
      'Migrating EGLD',
    [TRANSACTION_SUBGROUP_TYPE.MIGRATE_TOKEN_WALLET_TO_TOKEN_COLLATERAL]:
      'Migrating EGLD',
    [TRANSACTION_SUBGROUP_TYPE.CLAIM_REWARDS]: 'Claiming Rewards',
  } as Record<string, string>;

  return map[config.subgroup] || '';
};
