import { useAppDispatch, useAppSelector } from '@/store';
import { clearAuthStates } from '@/store/auth';
import { clearDappProvider, networkStateSelector } from '@/store/network';
import { ExtensionProvider } from '@multiversx/sdk-extension-provider/out';

const useExperimentalWebviewLogin = () => {
  const provider = useAppSelector(
    networkStateSelector<ExtensionProvider>('dappProvider'),
  );

  const dispatch = useAppDispatch();

  const logout = async () => {
    await provider.logout();
    dispatch(clearAuthStates());
    dispatch(clearDappProvider());
  };

  return {
    logout,
  };
};

export default useExperimentalWebviewLogin;
