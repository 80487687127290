import { useMemo } from 'react';

import { governanceSelector } from '@/store/governance';
import { useAppSelector } from '@/store/index';

const useVotesPopulated = () => {
  const { proposals, votes } = useAppSelector(governanceSelector);

  const proposalsStatusMap = useMemo(
    () =>
      proposals.reduce(
        (prev, current) => ({ ...prev, [current.id]: current.status }),
        {} as Record<string, string>
      ),
    [proposals]
  );

  const votesPopulated = useMemo(
    () =>
      votes.map((voteItem) => ({
        ...voteItem,
        proposalStatus: proposalsStatusMap[voteItem.proposalId] || '',
      })),
    [votes, proposalsStatusMap]
  );

  return votesPopulated;
};

export default useVotesPopulated;
