import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { TX_STATUS } from '@/hooks/core/useSignMultipleTransactions';
import useIsApp from '@/hooks/useIsApp';

import CheckCircleIcon from '@/components/Icons/CheckCircleIcon';
import CircleXmarkIcon from '@/components/Icons/CircleXmarkIcon';
import LendingPendingLoader from '@/components/popups/LendingPendingTransactionPopup/components/PendingLoader';
import TxHashBox from '@/components/popups/LendingPendingTransactionPopup/components/TxHashBox';
import LiquidPendingLoader from '@/components/popups/LiquidPendingTransactionPopup/components/PendingLoader';
import StatusAnimation from '@/components/popups/PendingTransactionPopup/components/StatusAnimation';
import PopupHead from '@/components/popups/PopupBed/components/PopupHead';
import TransactionButton from '@/components/TransactionButton';

import { useAppSelector } from '@/store';
import {
  TRANSACTION_SUBGROUP_TYPE,
  transactionSelector,
} from '@/store/transaction';

import { chainType, networkConfig } from '@/config/network';

const TxPhase: React.FC<any> = ({ txStatus, statusData }) => {
  const { t } = useTranslation();
  const isLend = useIsApp();
  const { currentTransactions, transactionAmount, transactionConfig } =
    useAppSelector(transactionSelector);

  const [txHistoryCompletedLength, setTxHistoryCompletedLength] = useState(0);

  useEffect(() => {
    const completedTransactions = currentTransactions.filter(
      ({ status }) => status !== 'pending',
    );

    setTxHistoryCompletedLength(completedTransactions.length);
  }, [currentTransactions]);

  const getResultLabel = (status: string) => {
    switch (status.toLowerCase()) {
      case 'success':
        return {
          label: t('success'),
          icon: <CheckCircleIcon />,
          color: '#47A57E',
        };
      case 'fail':
        return {
          label: t('failed'),
          icon: <CircleXmarkIcon />,
          color: '#C13A64',
        };
      default:
        return {
          label: t('unknown'),
          icon: <FontAwesomeIcon icon={faQuestionCircle} width='12px' />,
        };
    }
  };

  const getStatusLabel = useCallback(() => {
    switch (txStatus) {
      case TX_STATUS.AWAITING_CONFIRMATION:
        return {
          title: t('awaiting-confirmation'),
          message: t('confirm-transaction'),
        };
      case TX_STATUS.SENT:
        return {
          title: t('transaction-sent'),
          message: (
            <>
              {t('awaiting-network-response')}
              {transactionAmount >= 2 &&
                `(${txHistoryCompletedLength}/${transactionAmount})`}
            </>
          ),
        };
      case TX_STATUS.COMPLETED:
        return {
          title: (
            <span className='text-[12px]'>{t('transaction-completed')}</span>
          ),
          icon: <StatusAnimation className='mt-4' status={statusData} />,
        };
      case TX_STATUS.FAILED:
        return {
          title: (
            <span className='text-[12px]'>{t('canceled-transaction')}</span>
          ),
          icon: <StatusAnimation className='mt-4' status={undefined} />,
        };
      default:
        return { title: t('transaction-broadcast') };
    }
  }, [statusData, t, transactionAmount, txHistoryCompletedLength, txStatus]);

  const resultLabel = statusData && getResultLabel(statusData);

  const statusTitle = getStatusLabel().title;
  const statusMessage = getStatusLabel().message || null;
  const statusIcon =
    getStatusLabel().icon ||
    (isLend ? <LendingPendingLoader /> : <LiquidPendingLoader />);

  const completed = ![TX_STATUS.AWAITING_CONFIRMATION, TX_STATUS.SENT].includes(
    txStatus,
  );

  const message = useMemo(() => {
    if (
      transactionConfig.subgroup ==
      TRANSACTION_SUBGROUP_TYPE.REBALANCE_PORTFOLIO
    ) {
      return (
        <>
          Congratulations! You just rebalanced <br /> your position.
        </>
      );
    }

    return null;
  }, [transactionConfig.subgroup]);

  return (
    <div
      className='text-[#3C3A40] dark:text-[#7575A7]'
      style={{ minHeight: txStatus == TX_STATUS.FAILED ? 'auto' : 348 }}
    >
      <PopupHead>{t('transaction-details')}</PopupHead>

      <div
        className={classNames(
          'text-superxs font-semibold leading-tight tracking-[0.02em]',
          'relative p-5',
          'flex flex-col items-center',
        )}
      >
        <div
          className={classNames(
            {
              'mb-5': !completed,
              'h-24': completed,
            },
            'flex items-start justify-center',
          )}
        >
          {statusIcon}
        </div>

        <div className='text-center' data-testid='txStatus'>
          <div>{statusTitle}</div>

          {statusMessage && (
            <div className={classNames('mt-3', 'w-[310px]')}>
              {statusMessage}
            </div>
          )}
        </div>

        {/* Status */}
        {resultLabel && (
          <div className='mt-5 flex items-center gap-1.5'>
            <div>
              {t('status')}:{' '}
              <span style={{ color: resultLabel?.color }}>
                {resultLabel.label}
              </span>
            </div>

            <div style={{ color: resultLabel?.color }}>{resultLabel.icon}</div>
          </div>
        )}

        {txStatus === TX_STATUS.COMPLETED && message && (
          <div className='mt-3 -mb-4 w-[390px] text-center'>
            <span className='text-[14px] font-normal text-[#7575A7]'>
              {message}
            </span>
          </div>
        )}

        {/* Tx Hash */}
        {currentTransactions.length >= 1 && (
          <div
            className={classNames('mb-5 flex flex-col items-center gap-2', {
              'mt-[34px]': currentTransactions.length == 1,
              'mt-4': currentTransactions.length > 1,
            })}
          >
            {currentTransactions.map(({ hash }, i) => (
              <TxHashBox key={i} hash={hash} />
            ))}
          </div>
        )}

        {/* Explore  */}
        {currentTransactions?.[currentTransactions.length - 1]?.hash && (
          <a
            href={`${
              networkConfig[chainType].explorerAddress
            }/transactions/${currentTransactions?.[
              currentTransactions.length - 1
            ]?.hash}`}
            target='_blank'
            rel='noreferrer'
            className='w-[340px]'
          >
            <TransactionButton text={t('view-on-explorer')} />
          </a>
        )}
      </div>
    </div>
  );
};

export default TxPhase;
