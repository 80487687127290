import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';

const RewardSkeleton = () => {
  return (
    <div className='flex flex-col text-sm font-semibold leading-4'>
      <div
        className={classNames(
          'rounded-lg p-2.5 ',
          'flex items-center',
          'border',
          'border-[#DCDCE3] bg-[#F9F9FE] dark:border-[#2E2C51] dark:bg-[#34315A]'
        )}
      >
        <div className='mr-3'>
          <Skeleton width={34} height={34} circle className='aspect-square' />
        </div>
        <span className='mr-auto'>
          <Skeleton width={80} />
        </span>
        <Skeleton width={60} />
        <span className='w-[60px] text-right'>
          <Skeleton className='text-right' width={30} />
        </span>
      </div>
    </div>
  );
};

export default RewardSkeleton;
