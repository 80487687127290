import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { kebabCase } from 'lodash';
import { useTranslation } from 'next-i18next';
import { FormEvent, useEffect, useMemo, useState } from 'react';

import { useLogin } from '@/hooks/auth/useLogin';
import useSignMultipleTransactions, {
  ALLOWED_TRANSACTIONS,
} from '@/hooks/core/useSignMultipleTransactions';
import useGovernanceInteraction from '@/hooks/interaction/useGovernanceInteraction';
import useMediaQuery from '@/hooks/useMediaQuery';

import Hint from '@/components/Hint';
import PopupBed from '@/components/popups/PopupBed';
import {
  AmountInput,
  Description,
  Header,
  SliderInput,
  Title,
  VoteInput,
  VoteIntentRadio,
} from '@/components/popups/VoteV2Popup/components';
import TransactionButton from '@/components/TransactionButton';

import {
  governanceSelector,
  USER_BALANCE_SOURCE,
  VOTE_TYPE,
} from '@/store/governance';
import { useAppDispatch, useAppSelector } from '@/store/index';
import { closePopup, openPopup, popupSelector } from '@/store/popup';
import { hasEnoughEGLDBalanceSelector } from '@/store/protocol';
import { hasPendingTransactionsSelector } from '@/store/transaction';

import { balancesTypesMap } from '@/sections/Governance';

const VoteV2Popup = () => {
  const {
    data: { proposalId, title, tag, forCount, againstCount },
  } = useAppSelector(popupSelector);
  const hasEnoughEGLDBalance = useAppSelector(hasEnoughEGLDBalanceSelector);
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const { t } = useTranslation('governance');
  const { signTransactions, isAllowedTransaction, isWhiteListed } =
    useSignMultipleTransactions();
  const { voteFor: governanceVoteFor, voteAgainst: governanceVoteAgainst } =
    useGovernanceInteraction();

  const [amount, setAmount] = useState<string>();
  const [voteIntent, setVoteIntent] = useState<VOTE_TYPE>(VOTE_TYPE.For);
  const [slider, setSlider] = useState<string | undefined>('0');
  const [userBalanceSource, setUserBalanceSource] =
    useState<USER_BALANCE_SOURCE>();

  const md = useMediaQuery('(min-width: 768px)', window.innerWidth > 768);

  const { userBalances } = useAppSelector(governanceSelector);

  const balance = useMemo(() => {
    return (
      userBalances?.[userBalanceSource as USER_BALANCE_SOURCE]?.amount || '0'
    );
  }, [userBalances, userBalanceSource]);

  const isBtnValid = useMemo(
    () =>
      typeof amount !== 'undefined' &&
      hasEnoughEGLDBalance &&
      !new DefiUtils(amount).isZero() &&
      new DefiUtils(amount).isLessThan(balance),
    [amount, hasEnoughEGLDBalance, balance]
  );

  const handleSlider = (value: string | undefined) => {
    setSlider(value);
  };

  const handleVoteIntent = (value: VOTE_TYPE) => {
    setVoteIntent(value);
  };

  const handleVote = async (event: FormEvent) => {
    event.preventDefault();

    if (
      hasPendingTransactions ||
      !isAllowedTransaction(ALLOWED_TRANSACTIONS.GOVERNANCE_VOTE)
    ) {
      return;
    }

    const interactions = {
      [`${USER_BALANCE_SOURCE.wallet}_${true}`]: governanceVoteFor,
      [`${USER_BALANCE_SOURCE.wallet}_${false}`]: governanceVoteAgainst,
    };

    const interaction =
      interactions[`${userBalanceSource}_${voteIntent === VOTE_TYPE.For}`];

    if (!interaction) {
      return;
    }

    await signTransactions([
      interaction({
        proposalId,
        amount: amount || '0',
      }),
    ]);
  };

  useEffect(() => {
    const amount = new DefiUtils(balance || 0)
      .multipliedBy(slider || 0)
      .dividedBy(100)
      .toSafeFixed(18, DefiUtils.ROUND_FLOOR);
    setAmount(amount);
  }, [balance, slider]);

  const { isLoggedIn } = useLogin();
  const dispatch = useAppDispatch();

  const handleConnectWallet = () => {
    dispatch(closePopup());
    setTimeout(() => {
      dispatch(
        openPopup({
          name: 'connect_bridge_multiverse_wallet',
        })
      );
    }, 100);
  };

  return (
    <PopupBed width={md ? 400 : 350} close={false}>
      <Header />
      <Title
        proposalId={proposalId}
        title={t(kebabCase(title).toLowerCase(), title)}
        tag={tag}
      />
      <form
        onSubmit={handleVote}
        className={classNames(
          md ? 'max-w-[400px]' : 'max-w-[350px]',
          'px-4',
          'pt-4',
          'pb-4',
          'font-semibold'
        )}
      >
        <VoteIntentRadio
          forCount={forCount}
          againstCount={againstCount}
          voteIntent={voteIntent}
          onClick={handleVoteIntent}
        />
        <VoteInput
          balance={balance}
          value={userBalanceSource}
          onChange={setUserBalanceSource}
          items={Object.values(userBalances).map(({ source, amount }) => ({
            id: source,
            name: source,
            Icon: balancesTypesMap[source] || <></>,
          }))}
        />
        <AmountInput
          disabled={typeof userBalanceSource === 'undefined'}
          max={balance}
          value={amount}
          onChange={setAmount}
        />
        <SliderInput value={slider} onChange={handleSlider} />
        <Description />

        {!isLoggedIn ? (
          <TransactionButton
            onClick={handleConnectWallet}
            className='h-[34px] capitalize'
            text={t('translation:connect-wallet')}
          />
        ) : (
          <Hint
            content={
              hasPendingTransactions
                ? t('translation:wait-tx-inprogress')
                : !isWhiteListed()
                ? t('translation:tx-not-enable-to-sign')
                : t('translation:tx-not-allowed')
            }
            placement='top-center'
            className='w-full'
            unvisible={
              !(
                hasPendingTransactions ||
                !isAllowedTransaction(ALLOWED_TRANSACTIONS.GOVERNANCE_VOTE) ||
                !isWhiteListed()
              )
            }
          >
            <TransactionButton
              className='h-[34px] normal-case'
              text={t('set-up-voting', 'Set up voting')}
              disabled={!isBtnValid}
              type='submit'
            />
          </Hint>
        )}
      </form>
    </PopupBed>
  );
};

export default VoteV2Popup;
