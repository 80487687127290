import { captureException } from '@sentry/nextjs';
import React, { useState } from 'react';

import { useLoggingIn } from '@/hooks/auth/useLoggingIn';
import { useLogin } from '@/hooks/auth/useLogin';

import AwaitingPermission from '@/components/popups/ConnectWalletPopup/components/AwaitingPermission';
import Footer from '@/components/popups/ConnectWalletPopup/components/Footer';
import Header from '@/components/popups/ConnectWalletPopup/components/Header';
import WalletProviderButton from '@/components/popups/ConnectWalletPopup/components/WalletProviderButton';
import PopupBed from '@/components/popups/PopupBed';
import ScamAdvice from '@/components/ScamAdvice';

import { useAppDispatch } from '@/store/index';
import { closePopup } from '@/store/popup';

import { walletProvidersConfig } from '@/config/walletProviders';

const ConnectWalletPopup = () => {
  const dispatch = useAppDispatch();
  const { login, error } = useLogin();
  const { isLoggingIn } = useLoggingIn();
  const [awaitingUser, setAwaitingUser] = useState(false);

  const handleLogin = async (type: any) => {
    setAwaitingUser(true);
    try {
      await login(type);
    } catch (e) {
      setAwaitingUser(false);
      captureException(e);
    } finally {
      setAwaitingUser(false);
      dispatch(closePopup());
    }
  };

  if (error) return <div>{error}</div>;

  return (
    <PopupBed width={417}>
      <div className='overflow-hidden p-5 pb-2.5 pt-10'>
        <Header />
        <div className='mx-2 mt-[20px]'>
          <ScamAdvice />
        </div>

        {!(awaitingUser || isLoggingIn) ? (
          <div className='mt-5 flex flex-col gap-4'>
            {walletProvidersConfig.map(
              ({ brand, svgLogo, loginType, enabled }) => (
                <WalletProviderButton
                  key={loginType}
                  brand={brand}
                  svgLogo={svgLogo}
                  onClick={() => handleLogin(loginType)}
                  enabled={enabled}
                  data-testid={`connectWallet:${loginType}`}
                />
              )
            )}
          </div>
        ) : (
          <AwaitingPermission />
        )}

        <Footer
          className={
            !(awaitingUser || isLoggingIn) ? '' : 'mb-[46px] mt-[38px]'
          }
        />
      </div>
    </PopupBed>
  );
};

export default ConnectWalletPopup;
