import classNames from 'classnames';
import { domAnimation, LazyMotion, m, useInView } from 'framer-motion';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';

import { ONCE } from '@/hooks/framerMotion/useSetVariantOnLoadingEnd';

import WrappedLottiePlayer from '@/components/WrappedLottiePlayer';

import { cardClasses } from '../global';

const Join: React.FC<any> = () => {
  const { t } = useTranslation('liquid-app');

  const ref = useRef(null);
  const isInView = useInView(ref, { once: ONCE, amount: 0.5 });

  return (
    <div
      ref={ref}
      className={classNames(
        cardClasses,
        'overflow-hidden',
        'rounded-[16px] !px-4 !py-[13px]',
        'flex flex-col',
        'items-center text-center'
      )}
      style={{
        background:
          'linear-gradient(180deg, rgba(255, 241, 211, 0.3) 0%, rgba(255, 232, 183, 0) 100%), linear-gradient(276.19deg, rgba(255, 46, 46, 0.46) 16.09%, rgba(255, 7, 7, 0) 60.43%), linear-gradient(91.95deg, #FAAB9E 0.29%, #FBE3B6 33.28%)',
      }}
    >
      {[
        <WrappedLottiePlayer
          className='h-5 w-5'
          src='https://cdn.app.hatom.com/animations/abstract.json'
        />,
        <h3 className='scale-105 font-semibold leading-6 text-[#1F2226]'>
          {t('become-a-node-operator')}
        </h3>,
        <div className='mt-3'>
          <Link legacyBehavior href='/liquid/become-an-operator'>
            <a
              className={classNames(
                'btnHover',
                'bg-[#2C2C2D] text-white',
                'text-[14px] leading-6',
                'inline-block rounded-xl p-1.5 px-3 font-semibold'
              )}
            >
              <span>{t('liquidstaking:section8.button')}</span>
            </a>
          </Link>
        </div>,
      ].map((e, i) => (
        <LazyMotion features={domAnimation} key={i}>
          <m.div
            key={i}
            initial={{ y: i === 0 ? -100 : 160, opacity: 0 }}
            animate={isInView ? { y: 0, opacity: 1 } : {}}
            transition={{
              delay: (i + 1) * 0.1,
              type: 'tween',
              ease: 'easeOut',
            }}
          >
            {e}
          </m.div>
        </LazyMotion>
      ))}
    </div>
  );
};

export default Join;
