import { motion, Variants } from 'framer-motion'

import useMediaQuery from '@/hooks/useMediaQuery';

import { walletItemsAnimationsTransition } from '@/components/popups/ConnectBridgeWalletPopup/components/wallets/constants';


const fillVariants: Variants = {
  normal: {
    fill: "#454578",
  },
  hover: {
    fill: "#5F5F93",
  },
  transition: walletItemsAnimationsTransition
}
const bgVariants: Variants = {
  normal: {
    background: "#454578",
  },
  hover: {
    background: "#5F5F93",
  },
  transition: walletItemsAnimationsTransition
}



const ExpandableDiv = ({ height, expanded, className }: { height: number, expanded: boolean, className: string }) => (
  <motion.div
    initial={{
      height: 0,
    }}
    animate={{
      height: expanded ? height : 0,
    }}
    transition={{
      duration: 0.2,
      ease: "easeOut"
    }}
    className={className}
  >
    <motion.div
      className='w-[299.7px] h-full'
      variants={bgVariants}
    />
  </motion.div>
)
function BridgeWalletManuelConnectShape({ className, expanded, onClick }: { className?: string, expanded: boolean, onClick: () => void }) {
  const lg = useMediaQuery('(min-width: 1024px )');

  const divClassName = 'absolute z-0 top-0  flex flex-col h-fit w-full'




  if (lg)
    return (
      <div onClick={onClick} className={divClassName}>
        <svg width="300" height="92" viewBox="0 0 300 92" fill="none" xmlns="http://www.w3.org/2000/svg">
          <motion.path
            d="M0.0035598 87.5861C0.00145433 82.212 3.57253 77.4919 8.74458 76.0324L274.015 1.17648C286.777 -2.4248 299.446 7.16437 299.446 20.4248V91.5H0.00509322L0.0035598 87.5861Z"
            variants={fillVariants}
          />
        </svg>
        <ExpandableDiv className='w-[299.7px] translate-y-[-1.5px] ' expanded={expanded} height={40} />
        <motion.svg
          className="translate-y-[-2px]"
          width="300"
          height="33"
          viewBox="0 0 300 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <motion.path
            d="M299.444 0V12.25C299.444 23.2957 290.489 32.25 279.444 32.25H123.392C118.352 32.25 113.498 30.3474 109.801 26.9227L100.251 18.0773C96.5541 14.6526 91.7002 12.75 86.6605 12.75H12.0001C5.37366 12.75 0.00147381 7.37888 0.00015028 0.752399L0 0H299.444Z"
            variants={fillVariants}
          />
        </motion.svg>
      </div>
    );

  return (
    <div className={divClassName}>
      <svg width="216" height="66" viewBox="0 0 216 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <motion.path
          d="M0.96052 64.2686C0.958743 60.4303 3.49662 57.0539 7.18411 55.9888L197.574 0.991557C206.754 -1.66039 215.915 5.22873 215.915 14.7846V65.6838H0.961176L0.96052 64.2686Z"
          variants={fillVariants}
        />
      </svg>

      <ExpandableDiv className='w-[215.2px] translate-x-[0.9px] translate-y-[-0.7px] ' expanded={expanded} height={25} />
      <motion.svg
        className="translate-y-[-3px] "
        width="216"
        height="24"
        viewBox="0 0 216 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          d="M215.913 0.683594V9.47727C215.913 17.4064 209.485 23.8343 201.556 23.8343H83.5343C79.9165 23.8343 76.4322 22.4685 73.7781 20.0101L66.923 13.6604C64.2689 11.202 60.7845 9.83619 57.1668 9.83619H9.57182C4.815 9.83619 0.958578 5.98052 0.957627 1.2237L0.95752 0.683594H215.913Z"
          variants={fillVariants}
        />
      </motion.svg>
    </div>


  )
}

export default BridgeWalletManuelConnectShape;