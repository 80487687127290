import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { isValidElement } from 'react';

import useTheme from '@/hooks/useTheme';

import ApproEqualIcon from '@/components/Icons/ApproEqualIcon';
import TitleAndSub from '@/components/popups/AccountDetailsPopup/components/TokenRow/TitleAndSub';

import { ASSET_ALT_NAME } from '@/store/protocol';

import { formatNumber } from '@/utils/helpers';

const TokenRow: React.FC<any> = ({
  img,
  night,
  name,
  currency,
  balance = 0,
  price = 0,
}) => {
  const { isDark } = useTheme();
  const balanceUSD = new DefiUtils(balance)
    .toUSD(price)
    .toSafeFixed(6, DefiUtils.ROUND_DOWN);

  return (
    <div
      className={classNames(
        'flex justify-between rounded-xl border p-2 py-2.5 text-lg font-semibold leading-tight tracking-normal',
        'dark:border-[#2E2C51] dark:bg-[#34315A] border-[#D3D0E4] bg-white'
      )}
    >
      <figure className='flex items-start gap-2'>
        {isValidElement(img) ? (
          img
        ) : (
          <div
            className={classNames(
              'rounded-full p-0.5',
              'dark:bg-[#625E8F] bg-[#D4D4D4]'
            )}
          >
            <img
              className='h-8 w-8 overflow-hidden rounded-full'
              alt={`${name} currency logo`}
              src={isDark && night ? night : img}
            />
          </div>
        )}
        <TitleAndSub
          title={
            <div className='text-sm'>{ASSET_ALT_NAME[currency] ?? name}</div>
          }
          sub={`$${new DefiUtils(price).toSafeFixed(6, DefiUtils.ROUND_DOWN)}`}
        />
      </figure>

      <TitleAndSub
        title={
          <div className='text-sm'>
            {`${formatNumber(
              balance,
              new DefiUtils(balance).isEqualTo('0') ? 2 : 6
            )} ${currency}`}
          </div>
        }
        sub={
          <div className='flex items-center gap-1'>
            <ApproEqualIcon /> <span>${formatNumber(balanceUSD, 3)}</span>
          </div>
        }
        right
      />
    </div>
  );
};

export default TokenRow;
