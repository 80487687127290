import { createContext } from 'react';

import useStoreAccountMarket from '@/contexts/LendAppContext/hooks/useStoreAccountMarket';
import useStoreShowLiquidStakingAPY from '@/contexts/LendAppContext/hooks/useStoreShowLiquidStakingAPY';

const LendAppContext = createContext({});

const LendAppConsumer = LendAppContext.Consumer;

type LendAppProviderProps = {
  children: React.ReactNode;
};

const LendAppProvider = ({ children }: LendAppProviderProps) => {
  useStoreShowLiquidStakingAPY();
  useStoreAccountMarket();

  return (
    <LendAppContext.Provider value={{}}>{children}</LendAppContext.Provider>
  );
};

export default LendAppContext;
export { LendAppConsumer, LendAppProvider };
