import classNames from 'classnames';
import format from 'date-fns/format';
import DefiUtils from 'defi-utils';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import SimpleBar from 'simplebar-react';

import useSignMultipleTransactions, {
  ALLOWED_TRANSACTIONS,
} from '@/hooks/core/useSignMultipleTransactions';
import {
  ONCE,
  TRANSITION_VARIANTS,
} from '@/hooks/framerMotion/useSetVariantOnLoadingEnd';
import useLiquidStakingInteraction from '@/hooks/interaction/useLiquidStakingInteraction';
import { useCollapse } from '@/hooks/layout/useCollapse';
import useMediaQuery from '@/hooks/useMediaQuery';

import Hint from '@/components/Hint';
import WrappedLottiePlayer from '@/components/WrappedLottiePlayer';

import { useAppSelector } from '@/store/index';
import { liquidStakingAppSelector } from '@/store/liquid-staking-app';
import { hasEnoughEGLDBalanceSelector } from '@/store/protocol';
import {
  hasPendingTransactionsSelector,
  TRANSACTION_GROUP_TYPE,
  TRANSACTION_SUBGROUP_TYPE,
} from '@/store/transaction';

import { cardClasses, useHintProps } from '@/sections/Liquid/App/global';
import { useShimmerControllerContext } from '@/sections/Liquid/App/Provider';
import Shimmer from '@/sections/Liquid/App/Shimmer';
import { formatNumber, nFormatter } from '@/utils/helpers';

const Tab: React.FC<any> = ({ active, children, onClick, className }) => {
  return (
    <div className={classNames(className)}>
      <button
        onClick={onClick}
        className={classNames(
          'inline-block whitespace-nowrap rounded-t-2xl border border-b-0 p-2.5 px-5 pb-3 text-[14px] leading-6 md:text-[16px]',
          !active
            ? 'border-transparent text-[#535367] dark:text-[#625E8F]'
            : 'border-[#DCDCE3] bg-[#F9F9FE] dark:border-[#625E8F] dark:bg-[#292651]',
        )}
      >
        {children}
      </button>
      <hr
        className={classNames(
          'border-t border-[#DCDCE3] dark:border-[#625E8F]',
        )}
      />
    </div>
  );
};

const Button: React.FC<any> = ({ className, children, small, ...props }) => {
  return (
    <button
      className={classNames(
        className,
        {
          btnHover: !small,
        },
        'rounded-[8px] text-[12px] capitalize leading-tight',
        small
          ? 'px-2 py-1 hover:opacity-90 md:min-w-[64px] lg:px-4 lg:text-[14px]'
          : 'px-2 py-1.5 lg:px-5 lg:text-[16px]',
      )}
      {...props}
    >
      {children}
    </button>
  );
};

const EstimatedValueText: React.FunctionComponent<any> = ({
  currency = 'USD',
}) => {
  const xl = useMediaQuery('(min-width: 1280px)');
  const { t } = useTranslation('liquid-app');

  return <>{t(`estimated-value-by-x${xl ? '' : '-short'}`, { currency })}</>;
};

const ClaimsAndRequestsShimmer: React.FC<any> = () => {
  return (
    <Fragment>
      {Array(3)
        .fill(null)
        .map((_, i) => (
          <Fragment key={i}>
            <div className='flex-xy-center gap-1.5 place-self-start'>
              <Shimmer circular w={15} />
              <Shimmer sm />
            </div>
            <Shimmer />
            <Shimmer />
            <div className='flex-xy-center justify-end gap-2'>
              <Shimmer sm />
              <Shimmer sm />
            </div>
          </Fragment>
        ))}
    </Fragment>
  );
};

export const TimerAnimation: React.FC<any> = () => {
  return (
    <WrappedLottiePlayer className='w-[12px]' src='https://cdn.app.hatom.com/animations/clock.json' />
  );
};

const HintableCellBare: React.FC<any> = ({ children, content }) => {
  const defaultHintProps = useHintProps();

  return (
    <Hint
      {...defaultHintProps}
      content={content}
      layerStyle={{ textAlign: 'center', minWidth: 30 }}
    >
      {children}
    </Hint>
  );
};

const HintableCell = React.memo(HintableCellBare);

const ClaimsAndRequests: React.FC<any> = () => {
  const { t } = useTranslation('liquid-app');
  const md = useMediaQuery('(min-width: 768px)');
  const [tab, setTab] = useState(0);
  const { undelegateNfts } = useAppSelector(liquidStakingAppSelector);

  const { unbond } = useLiquidStakingInteraction();
  const { signTransactions, isAllowedTransaction, isWhiteListed } =
    useSignMultipleTransactions();
  const hasEnoughEGLDBalance = useAppSelector(hasEnoughEGLDBalanceSelector);
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);

  const tokenImgMap = {
    sEGLD: 'https://cdn.app.hatom.com/images/sEGLD.svg',
    HsEGLD: 'https://cdn.app.hatom.com/images/liquidstaking/app/tokens/hsegld.png',
  };

  const handleAllUnbond = (nonces: number[], result: string) => {
    signTransactions(
      nonces.map((nonce) => unbond({ nonce })),
      {
        group: TRANSACTION_GROUP_TYPE.LIQUID,
        subgroup: TRANSACTION_SUBGROUP_TYPE.CLAIM,
        result,
      },
    );
  };

  const handleUnbond = (nonce: number, result: string) => {
    signTransactions([unbond({ nonce })], {
      group: TRANSACTION_GROUP_TYPE.LIQUID,
      subgroup: TRANSACTION_SUBGROUP_TYPE.CLAIM,
      result,
    });
  };

  const undelegateNftsPopulated = useMemo(() => {
    return undelegateNfts.map(
      ({
        segldAmount,
        hsegldAmount,
        egldAmount: valueEGLD,
        amountUsd: valueUSD,
        tokenNonce,
        source,
        availableToRedeemDate,
        canRedeem,
      }) => {
        const token = source === 'LiquidStaking' ? 'sEGLD' : 'HsEGLD';
        const value = source === 'LiquidStaking' ? segldAmount : hsegldAmount;
        const expireIn = !canRedeem ? new Date(availableToRedeemDate) : null;

        return {
          token,
          value,
          valueEGLD,
          valueUSD,
          tokenNonce,
          source,
          expireIn,
          canRedeem,
        };
      },
    );
  }, [undelegateNfts]);

  const activeClaimsNfts = useMemo(
    () => undelegateNftsPopulated.filter(({ canRedeem }) => canRedeem),
    [undelegateNftsPopulated],
  );

  const unstakeRequestNfts = useMemo(
    () => undelegateNftsPopulated.filter(({ canRedeem }) => !canRedeem),
    [undelegateNftsPopulated],
  );

  const activeTab = useMemo(() => {
    return !activeClaimsNfts.length && unstakeRequestNfts.length ? 1 : 0;
  }, [activeClaimsNfts, unstakeRequestNfts]);

  const panels = useMemo(
    () => [
      {
        caption: t('active-claims'),
        th: [
          t('unstaked-tokens'),
          <EstimatedValueText currency='EGLD' />,
          <EstimatedValueText />,
        ],
        data: activeClaimsNfts,
        actions: ({
          all = false,
          lg = false,
          key = '',
          nonce = 0,
          valueEGLD = '0',
        }) => {
          const warning = hasPendingTransactions
            ? t('translation:wait-tx-inprogress')
            : !hasEnoughEGLDBalance
            ? t('translation:not-enough-balance')
            : !isAllowedTransaction(ALLOWED_TRANSACTIONS.LIQUID_APP_CLAIM)
            ? t('translation:tx-not-allowed')
            : !isWhiteListed()
            ? t('translation:tx-not-enable-to-sign')
            : false;

          const className = classNames({
            'max-w-[180px]': hasPendingTransactions,
            'max-w-[140px] sm:max-w-[160px]': !hasEnoughEGLDBalance,
          });

          return [
            <Hint
              key={key}
              placement='left-center'
              hidden={!warning}
              content={<div className={className}>{warning}</div>}
            >
              <Button
                className={classNames(
                  'flex items-center bg-[#4AAD7C] text-white',
                  all ? 'h-[30px]' : 'h-[26px]',
                )}
                small={!all}
                onClick={() => {
                  if (warning) return;

                  if (all) {
                    handleAllUnbond(
                      activeClaimsNfts.map(({ tokenNonce }) => tokenNonce),
                      activeClaimsNfts
                        .reduce(
                          (prev, { valueEGLD }) => prev.plus(valueEGLD),
                          new DefiUtils('0'),
                        )
                        .toString(),
                    );
                  } else {
                    handleUnbond(nonce, valueEGLD);
                  }
                }}
              >
                <span className='capitalize'>
                  {all
                    ? t('translation:claim-all', 'Claim all')
                    : t('translation:claim', 'Claim')}
                </span>
              </Button>
            </Hint>,
          ];
        },
      },
      {
        caption: t('unstake-requests'),
        th: [
          t('unstaked-tokens'),
          <EstimatedValueText currency='EGLD' />,
          <EstimatedValueText />,
          t('redeemable-at'),
        ],
        data: unstakeRequestNfts,
        actions: ({ all = false, lg = false }) => [],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      activeClaimsNfts,
      hasEnoughEGLDBalance,
      hasPendingTransactions,
      t,
      unstakeRequestNfts,
    ],
  );

  const getAnimationClassNames = (visible = true) => {
    return md
      ? classNames(
          'transition-all ease-in-out',
          visible
            ? 'relative active z-20 duration-700 opacity-100 scale-100'
            : 'duration-300  opacity-0 scale-[0.98]',
        )
      : 'transition-all ease-in-out max-h-[0] overflow-hidden';
  };

  const [p1Ref, , p1Toggle] = useCollapse<HTMLDivElement>({
    defaultValue: true,
    callback: () => {},
  });

  const [p2Ref, , p2Toggle] = useCollapse({
    defaultValue: false,
    callback: () => {},
  });

  const handleTabClick = (tabI: number) => {
    if (tabI == tab) return;
    setTab(tabI);
    p1Toggle();
    p2Toggle();
  };

  useEffect(() => {
    if (!tab) p1Toggle(true);
    else p2Toggle(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panels, md]);

  useEffect(() => {
    handleTabClick(activeTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const [shimmerEnabled] = useShimmerControllerContext();

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        initial='hidden'
        custom={{ onlyY: true, movePx: 12 }}
        variants={TRANSITION_VARIANTS}
        whileInView='visible'
        viewport={{ once: ONCE, amount: 0.3 }}
        className='relative'
      >
        <div
          className={classNames(
            cardClasses,
            'font-hass font-semibold',
            'w-full whitespace-nowrap text-[8px] sm:text-[12px] md:text-[14px] lg:mx-auto lg:w-[600px] xl:w-full',
          )}
        >
          <div className='relative w-full '>
            <hr
              className={classNames(
                'absolute top-[47px] hidden w-full border-t border-[#DCDCE3] dark:border-[#625E8F] md:block',
              )}
            />
            <div className='grid gap-5 md:grid-cols-[repeat(3,auto)] md:gap-3 md:gap-x-0 lg:place-items-start lg:gap-x-7 xl:grid-cols-[repeat(3,auto),1fr]'>
              {panels.map(({ caption, th, data, actions }, i) => {
                return (
                  <Fragment key={i}>
                    {data.length >= 2 && (
                      <div
                        className={classNames(
                          // show only when viewport > lg
                          'right-0 top-1.5 hidden justify-center gap-3 lg:absolute lg:flex',
                          getAnimationClassNames(tab === i),
                        )}
                      >
                        {actions({
                          all: true,
                          lg: true,
                          key: 'action-all-2',
                        }).map((e, i) => e)}
                      </div>
                    )}
                    <div className='relative'>
                      <Tab
                        className={classNames({
                          'text-center': i === 1,
                        })}
                        onClick={() => handleTabClick(i)}
                        active={tab === i}
                      >
                        {caption}
                      </Tab>
                      {!!data.length && (
                        <div
                          className={classNames(
                            'absolute bottom-2 right-0 justify-center gap-2 md:hidden',
                            tab === i ? 'flex' : 'hidden',
                          )}
                        >
                          {actions({
                            all: true,
                            lg: true,
                            key: 'actions-all',
                          }).map((e, i) => e)}
                        </div>
                      )}
                    </div>
                    {!shimmerEnabled && !data.length ? (
                      <m.div
                        ref={(current) => {
                          // @ts-ignore
                          if (i === 0) p1Ref.current = current;
                          // @ts-ignore
                          else p2Ref.current = current;
                        }}
                        className={classNames(
                          'place-self-center text-center text-[16px] md:col-span-full md:row-start-2',
                          {
                            'pointer-events-none': tab !== i,
                          },
                          getAnimationClassNames(tab === i),
                        )}
                      >
                        {t('you-have-no-x', { topic: caption.toLowerCase() })}
                      </m.div>
                    ) : (
                      <SimpleBar
                        autoHide={false}
                        className={classNames(
                          'h-full transition-all',
                          {
                            'md:h-[0px]': tab !== i,
                            'pointer-events-none': tab !== i,
                            'max-h-[136px] lg:max-h-[138px]': i == 0,
                            'max-h-[100px] md:max-h-[109px] lg:max-h-[138px]':
                              i == 1,
                          },
                          'col-span-full col-start-1 w-full transition-opacity md:row-start-2',
                        )}
                      >
                        <style jsx global>
                          {`
                            .simplebar-track.simplebar-vertical {
                              right: -13px;
                              width: 8px;
                            }

                            @media screen and (min-width: 768px) {
                              .simplebar-track.simplebar-vertical {
                                right: -12px;
                              }
                            }

                            @media screen and (min-width: 1024px) {
                              .simplebar-track.simplebar-vertical {
                                right: -16px;
                                width: 10px;
                              }
                            }
                          `}
                        </style>
                        <m.div
                          ref={(current) => {
                            // @ts-ignore
                            if (i === 0) p1Ref.current = current;
                            // @ts-ignore
                            else p2Ref.current = current;
                          }}
                          className={classNames(
                            'grid w-full place-items-end items-start gap-x-1.5 gap-y-3 sm:items-center xl:gap-x-4',
                            data.length >= 2 && 'min-h-[136px] !w-full',
                            i == 0
                              ? 'grid-cols-[repeat(3,1fr)]'
                              : 'grid-cols-[repeat(4,1fr)]',
                            `md:grid-cols-[repeat(4,1fr)] xl:grid-cols-[repeat(3,1fr),350px]`,
                            getAnimationClassNames(tab === i),
                          )}
                          key={`panel-${i}`}
                        >
                          {shimmerEnabled ? (
                            <ClaimsAndRequestsShimmer />
                          ) : (
                            <Fragment>
                              {th.map((e, i) => (
                                <div
                                  key={i}
                                  className={classNames(
                                    {
                                      'place-self-start': i == 0,
                                    },
                                    'self-start text-[10px] leading-tight text-[#606060] dark:text-[#FFFFFF] sm:[font-size:inherit]',
                                  )}
                                >
                                  {e}
                                </div>
                              ))}

                              {md &&
                              actions({ all: false, lg: false }).length ? (
                                <div></div>
                              ) : (
                                <></>
                              )}

                              {data &&
                                data.map(
                                  (
                                    {
                                      token,
                                      value,
                                      valueEGLD,
                                      valueUSD,
                                      expireIn,
                                      tokenNonce,
                                      source,
                                    },
                                    ri,
                                  ) => (
                                    <div
                                      key={tokenNonce}
                                      className={classNames(
                                        'col-span-full w-full text-[#2F2F2F] dark:text-[#625E8F]',
                                        'grid place-items-end items-start gap-x-1.5 gap-y-3 sm:items-center xl:gap-x-4',
                                        i == 0
                                          ? 'grid-cols-[repeat(3,1fr)]'
                                          : 'grid-cols-[repeat(4,1fr)]',
                                        `md:grid-cols-[repeat(4,1fr)] xl:grid-cols-[repeat(3,1fr),350px]`,
                                      )}
                                    >
                                      <div
                                        className={classNames(
                                          'flex items-center gap-1 justify-self-start ',
                                          {
                                            'lg:h-[26px]': true,
                                          },
                                        )}
                                      >
                                        <div className='w-[18px] rounded-full'>
                                          <img
                                            src={
                                              tokenImgMap[
                                                token as keyof typeof tokenImgMap
                                              ]
                                            }
                                            alt=''
                                          />
                                        </div>
                                        <HintableCell
                                          content={`${formatNumber(
                                            value,
                                            new DefiUtils(
                                              value,
                                            ).decimalPlaces(),
                                          )}`}
                                        >
                                          {nFormatter(value)} {token}
                                        </HintableCell>
                                      </div>

                                      <div className='flex items-center gap-1'>
                                        <div className='w-[18px] rounded-full'>
                                          <img src='https://cdn.app.hatom.com/images/img1.svg' alt='' />
                                        </div>
                                        <HintableCell content={`${valueEGLD}`}>
                                          {nFormatter(valueEGLD, 2)}{' '}
                                          <span className='hidden lg:inline'>
                                            EGLD
                                          </span>
                                        </HintableCell>
                                      </div>

                                      <div className='flex items-center gap-1 md:gap-3'>
                                        <HintableCell
                                          content={`$${formatNumber(
                                            valueUSD,
                                            new DefiUtils(
                                              valueUSD,
                                            ).decimalPlaces() > 6
                                              ? 6
                                              : new DefiUtils(
                                                  valueUSD,
                                                ).decimalPlaces(),
                                          )}`}
                                        >
                                          {nFormatter(valueUSD)}{' '}
                                          <span className='hidden lg:inline'>
                                            USD
                                          </span>
                                        </HintableCell>
                                      </div>

                                      {expireIn && (
                                        <div className='flex gap-1 sm:items-center'>
                                          <TimerAnimation />
                                          <div className='text-end sm:text-start'>
                                            {format(expireIn, 'dd/MM/yy')}
                                            <span className='hidden xl:inline'>
                                              {' at '}
                                              {format(expireIn, 'hh:mm a')}
                                            </span>
                                          </div>
                                        </div>
                                      )}

                                      {actions({ all: false, lg: false })
                                        .length ? (
                                        <div className='col-span-full -mt-2 flex items-end justify-end gap-1 xs:items-start sm:gap-2 md:col-span-1 md:mt-0 lg:gap-3'>
                                          {actions({
                                            all: false,
                                            lg: false,
                                            key: tokenNonce.toString(),
                                            nonce: tokenNonce,
                                            valueEGLD,
                                          }).map((button) => button)}
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  ),
                                )}
                            </Fragment>
                          )}
                        </m.div>
                        {data.length >= 2 && (
                          <div
                            className={classNames(
                              // show only when viewport between md and lg
                              'my-3 hidden justify-center gap-3 md:flex lg:hidden',
                              getAnimationClassNames(tab === i),
                            )}
                          >
                            {actions({
                              all: true,
                              lg: true,
                              key: 'action-all-2',
                            }).map((e, i) => e)}
                          </div>
                        )}
                      </SimpleBar>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </m.div>
    </LazyMotion>
  );
};

export default ClaimsAndRequests;
