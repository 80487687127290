import { ApiNetworkProvider } from '@multiversx/sdk-network-providers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';

// import toast from 'react-hot-toast';
// import i18n from 'next-i18next';
import { AppDispatch, RootState } from '@/store/index';

import logger from '@/utils/logger';

export interface NetworkState<T> {
  dappProvider: T;
  apiNetworkProvider: T;
  networkData: any;
  statusOk: boolean;
  isLoading: boolean;
  error: any;
}

const initialState: NetworkState<any> = {
  dappProvider: null,
  apiNetworkProvider: null,
  networkData: null,
  statusOk: false,
  isLoading: false,
  error: null,
};

export const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    setNetworkState: (
      state,
      action: PayloadAction<{ key: keyof NetworkState<any>; value: any }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },

    clearNetworkState: (state) => {
      state.dappProvider = null;
      state.apiNetworkProvider = null;
      state.networkData = null;
      state.statusOk = false;
      state.isLoading = false;
      state.error = null;
    },

    clearDappProvider: (state) => {
      state.dappProvider = null;
    },
  },
});

export const { clearNetworkState, clearDappProvider } = networkSlice.actions;

export const setNetworkState =
  (key: keyof NetworkState<any>, value: any) =>
  async (dispatch: AppDispatch) => {
    dispatch(networkSlice.actions.setNetworkState({ key, value }));
  };

export const updateNetworkData =
  (provider: ApiNetworkProvider) => async (dispatch: AppDispatch) => {
    dispatch(setNetworkState('isLoading', true));

    try {
      const data = await provider.getNetworkConfig();
      if (!!data?.ChainID) {
        dispatch(setNetworkState('networkData', data));
        dispatch(setNetworkState('statusOk', true));
        dispatch(setNetworkState('error', null));
      }
    } catch (e) {
      logger.error('store:updateNetworkData', e);
      captureException(e);
      dispatch(setNetworkState('networkData', null));
      dispatch(setNetworkState('statusOk', false));
      dispatch(setNetworkState('error', null));
    } finally {
      dispatch(setNetworkState('isLoading', false));
    }
  };

export const networkSelector = (state: RootState) => state.network;

export function networkStateSelector<T>(key: keyof NetworkState<T>) {
  return function (state: RootState): NetworkState<T>[keyof NetworkState<T>] {
    return state.network[key];
  };
}

export default networkSlice.reducer;
