import classNames from 'classnames';

type BaseButtonProps = {
  children: React.ReactNode;
  customTheme?: string;
  onClick?: () => void;
};

function BaseButton({
  children,
  customTheme = '',
  onClick = () => {},
}: BaseButtonProps) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        'btnHover',
        'block w-full cursor-pointer rounded-full p-3',
        'text-center text-sm font-semibold leading-tight',
        customTheme
          ? customTheme
          : 'text-white [background:linear-gradient(90deg,#2B4A9F_0%,#44A474_100%)]'
      )}
    >
      {children}
    </button>
  );
}

export default BaseButton;
