import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Router from 'next/router';

import {
  POPUP_VARIATION,
  POPUP_VARIATIONS,
  TX_STATUS,
} from '@/hooks/core/useSignMultipleTransactions';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import { TRANSACTION_GROUP_TYPE } from '@/store/transaction';

import { ROUTES } from '@/types/enums';

export interface PopupPayload {
  name: string;
  data?: any;
}

export interface PopupState {
  name: string;
  isOpen: boolean;
  data: any;
}

const initialState: PopupState = {
  name: '',
  isOpen: false,
  data: {},
};

export const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    openPopup: (state, action: PayloadAction<PopupPayload>) => {
      state.isOpen = true;
      state.name = action.payload.name;
      state.data = action.payload.data || {};
    },

    closePopup: (state) => {
      state.name = '';
      state.isOpen = false;
      state.data = {};
    },
  },
});

export const { openPopup, closePopup } = popupSlice.actions;

export const getRouteGroup = () => {
  switch (Router.route) {
    case ROUTES.LEND: {
      return TRANSACTION_GROUP_TYPE.LENDING;
    }
    case ROUTES.LIQUID_APP:
    case ROUTES.LIQUID_MULTIVERSX_APP: {
      return TRANSACTION_GROUP_TYPE.LIQUID;
    }
    case ROUTES.LIQUID_TAO_APP: {
      return TRANSACTION_GROUP_TYPE.LIQUID_TAO;
    }
    default: {
      return TRANSACTION_GROUP_TYPE.DEFAULT;
    }
  }
};

interface OpenTransactionPopupPayload {
  name: string;
  data: any;
}

export const openTransactionPopup =
  ({ name, data }: OpenTransactionPopupPayload) =>
  (dispatch: AppDispatch, getState: GetRootState) => {
    const state = getState();
    const { address: accountAddress } = state.auth.account;
    const { silentModeActive, transactionGroups } = state.transaction;

    const transactionGroupId = `${accountAddress}_${getRouteGroup()}`;
    const currentTransactionGroup = transactionGroups.find(
      (transactionGroup) => transactionGroup.id === transactionGroupId,
    );

    const isFromSameAccount =
      accountAddress === currentTransactionGroup?.config.accountAddress;
    const isFromSameGroup =
      currentTransactionGroup?.config.group === getRouteGroup();

    const showPopup =
      isFromSameAccount &&
      isFromSameGroup &&
      (!silentModeActive ||
        (POPUP_VARIATIONS.includes(name as POPUP_VARIATION) &&
          [TX_STATUS.COMPLETED, TX_STATUS.FAILED].includes(data?.txStatus)));

    if (!showPopup) {
      return;
    }

    return dispatch(
      openPopup({
        name,
        data,
      }),
    );
  };

export const popupSelector = (state: RootState) => state.popup;

export default popupSlice.reducer;
