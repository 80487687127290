import classNames from 'classnames';
import React from 'react';

import AnnualEstimation, {
  IEstimationAnnual,
} from '@/components/popups/LendingCurrentPositionPopup/components/AnnualEstimation';
import WrappedImg from '@/components/WrappedImg';

import { MARKET_KEY } from '@/store/protocol';

import { parseSmallNumber } from '@/utils/parseSmallNumber';

interface IPosition<ValueType> {
  id: string | number;
  token: MARKET_KEY;
  tokenImg: string;
  accentColor: `#${string}`;
  percentage: number;
  value: ValueType;
  valueUSD: ValueType;
  returns: Array<IEstimationAnnual<ValueType>>;
  debts: Array<IEstimationAnnual<ValueType>>;
}

const DataRow = React.forwardRef<
  HTMLInputElement,
  IPosition<number> & { secondaryColor: string; hovered: boolean }
>(
  (
    {
      token,
      tokenImg,
      accentColor,
      secondaryColor,
      percentage,
      value,
      valueUSD,
      hovered,
      returns = [],
      debts = [],
    },
    ref
  ) => {
    accentColor = (
      hovered && secondaryColor ? secondaryColor : accentColor
    ) as any;

    return (
      <div
        ref={ref}
        className={classNames(
          'grid grid-cols-[3px,1fr] gap-2 whitespace-nowrap text-[10px] text-[#535367] sm:text-[12px]'
        )}
      >
        {/* BAR */}
        <div className='relative top-0 h-6 sm:h-8'>
          <div
            className='h-full w-full self-center rounded '
            style={{ background: accentColor }}
          />
          <div className='absolute top-0 left-0.5 overflow-hidden'>
            <div
              className={classNames(
                'h-8 w-2 opacity-50 transition-all delay-75 duration-300',
                hovered ? 'translate-x-0' : '-translate-x-full'
              )}
              style={{
                background: `linear-gradient(to right, ${accentColor}, #fff0 80%)`,
              }}
            ></div>
          </div>
        </div>
        <div
          className={classNames(
            'grid items-start',
            'grid-cols-[5fr,4fr,50%] sm:grid-cols-[120px,1fr,212px]',
            'transition-all delay-75 duration-300',
            {
              'translate-x-1': hovered,
            }
          )}
        >
          {/* TOKEN */}
          <div className='inline-grid grid-cols-[28px,auto] gap-x-2 pt-0.5 text-[#3A3C40] dark:text-white'>
            <WrappedImg className='row-span-2 w-6 sm:w-7' src={tokenImg} />
            {token}
            <span
              style={{
                color: accentColor,
              }}
            >
              {parseSmallNumber(percentage)}%
            </span>
          </div>
          {/* EARNING */}
          <div className='flex flex-col'>
            <span>
              {parseSmallNumber(value)} {token}
            </span>
            <span>≈ {parseSmallNumber(valueUSD, true)}</span>
          </div>
          {/* Details */}
          <div className='space-y-1.5'>
            {!!debts.length && <AnnualEstimation debts data={debts} />}
            {!!returns.length && <AnnualEstimation data={returns} />}
          </div>
        </div>
      </div>
    );
  }
);

export default DataRow;
