import multiversxSDK from 'multiversx-sdk';
export * from 'multiversx-sdk/dist/types';

import { chainType, networkConfig } from '@/config/network';

multiversxSDK.configure({
  customBaseURL: networkConfig[chainType].blastApiAddress,
  retry: {
    retryTime: 10,
    wait: 1000,
  },
});

export default multiversxSDK;
