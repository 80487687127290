import { gql } from 'graphql-request';

import { chainType } from '@/config/network';
import {
  QueryBoosterAccount,
  QueryBoosterBoosters,
  QueryBoosters,
  ResponseBoosterBoosters,
  ResponseBoosters,
} from '@/services/indexer/booster/types';
import client from '@/services/indexer/client';

export const getBoosterAccount = async (
  boosterAddress: string,
  accountAddress: string,
): Promise<QueryBoosterAccount> => {
  const id = `${boosterAddress}-${accountAddress}`;

  const query = gql`
    query BoosterAccount($id: String!) {
      getBoosterAccount(id: $id) {
        id
        totalStaked
        marketBoosters {
          staked
          priceIntegral
          priceIntegralTimestamp
          moneyMarket {
            underlying {
              symbol
            }
          }
        }
        claims {
          id
          claimTimestamp
          claimed
          amount
        }
        boostedRewardsBatches {
          id
          amount
          penaltyAmount
          timestamp
          index
          boostedRewardsBatchState {
            index
            lastTime
            speed
            totalAmount
            rewardsToken {
              name
              id
            }
            marketBooster {
              priceIntegral
              priceIntegralTimestamp
              moneyMarket {
                address
                underlying {
                  symbol
                  id
                }
              }
            }
          }
        }
      }
    }
  `;

  const variables = { id };

  const queryBoosterAccount = await client.request<QueryBoosterAccount>(
    query,
    variables,
  );

  return queryBoosterAccount;
};

export const getBoosters = async (): Promise<ResponseBoosters[]> => {
  const query = gql`
    query QueryBoosters {
      queryBoostedRewardsBatchState {
        index
        lastTime
        speed
        totalAmount
        endTime
        rewardsToken {
          name
          id
          symbol
          decimals
        }
        marketBooster {
          priceIntegral
          priceIntegralTimestamp
          moneyMarket {
            address
            underlying {
              symbol
              id
            }
          }
        }
      }
    }
  `;

  const queryBoosterAccount = await client.request<QueryBoosters>(query);

  const currentTime = new Date().getTime();

  return queryBoosterAccount.queryBoostedRewardsBatchState.map(
    (boostedRewardsBatchStateItem) => ({
      ...boostedRewardsBatchStateItem,
      speed:
        currentTime > new Date(boostedRewardsBatchStateItem.endTime).getTime()
          ? '0'
          : boostedRewardsBatchStateItem.speed,
    }),
  );
};

export const getBoosterBoosters = async (): Promise<
  ResponseBoosterBoosters[]
> => {
  const query = gql`
    query QueryBoosterBoosters {
      queryBoosterBooster {
        rewardsToken {
          id
          symbol
        }
        swapPath {
          id
        }
        premium
      }
    }
  `;

  const queryBoosterAccount = await client.request<QueryBoosterBoosters>(query);

  return queryBoosterAccount.queryBoosterBooster.map((boosterBoosterItem) => ({
    ...boosterBoosterItem,
    swapPath:
      chainType === 'devnet'
        ? boosterBoosterItem.swapPath.reverse()
        : boosterBoosterItem.swapPath,
  }));
};
