import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import {
    formatHatomTokenPrices,
    formatMarketTokenPrices,
} from '@/store/parsers/price-parser';
import { MARKET_KEY } from '@/store/protocol';
import { addAction } from '@/store/queue';

import { chainType } from '@/config/network';
import * as indexerService from '@/services/indexer';
import { ResponseMarketVariation24Percent } from '@/services/indexer/prices/types';
import logger from '@/utils/logger';
import { MAX_CACHE_TIME, queryClient } from '@/utils/query';

export interface PriceItem {
  id: string;
  url: string;
  symbol: string;
  priceUSD: string;
  variation24hPercent: string;
  reporterId: string;
  image: string;
  isSupported: boolean;
}

export enum REPORTER_KEY {
  chainlink = 'chainlink',
  xExchange = 'x-exchange',
  ashSwap = 'ash-swap',
  liquidStaking = 'liquid-staking',
  lending = 'lending',
}

export const MARKET_TOKENS_REPORTER_MAP = {
  [MARKET_KEY.EGLD]: REPORTER_KEY.chainlink,
  [MARKET_KEY.sEGLD]: REPORTER_KEY.liquidStaking,
  [MARKET_KEY.WTAO]: REPORTER_KEY.chainlink,
  [MARKET_KEY.sWTAO]: REPORTER_KEY.liquidStaking,
  [MARKET_KEY.HTM]: REPORTER_KEY.chainlink,
  [MARKET_KEY.USH]: REPORTER_KEY.lending,
  [MARKET_KEY.USDC]: REPORTER_KEY.chainlink,
  [MARKET_KEY.USDT]: REPORTER_KEY.chainlink,
  [MARKET_KEY.BUSD]: REPORTER_KEY.chainlink,
  [MARKET_KEY.MEX]: REPORTER_KEY.xExchange,
  [MARKET_KEY.UTK]: REPORTER_KEY.chainlink,
  [MARKET_KEY.RIDE]: REPORTER_KEY.xExchange,
  [MARKET_KEY.WBTC]: REPORTER_KEY.chainlink,
  [MARKET_KEY.WETH]: REPORTER_KEY.chainlink,
};

export const HATOM_TOKENS_REPORTER_MAP = {
  [MARKET_KEY.EGLD]: REPORTER_KEY.lending,
  [MARKET_KEY.sEGLD]: REPORTER_KEY.lending,
  [MARKET_KEY.WTAO]: REPORTER_KEY.lending,
  [MARKET_KEY.sWTAO]: REPORTER_KEY.lending,
  [MARKET_KEY.HTM]: REPORTER_KEY.lending,
  [MARKET_KEY.USH]: REPORTER_KEY.lending,
  [MARKET_KEY.USDC]: REPORTER_KEY.lending,
  [MARKET_KEY.USDT]: REPORTER_KEY.lending,
  [MARKET_KEY.BUSD]: REPORTER_KEY.lending,
  [MARKET_KEY.MEX]: REPORTER_KEY.lending,
  [MARKET_KEY.UTK]: REPORTER_KEY.lending,
  [MARKET_KEY.RIDE]: REPORTER_KEY.lending,
  [MARKET_KEY.WBTC]: REPORTER_KEY.lending,
  [MARKET_KEY.WETH]: REPORTER_KEY.lending,
};

export interface MarketToken extends PriceItem {}

export interface HatomToken extends PriceItem {}

export interface Reporter {
  id: string;
  name: string;
  image: string;
  url: string;
}

export interface PriceState {
  marketTokens: MarketToken[];
  hatomTokens: HatomToken[];
  reporters: Reporter[];
}

const initialState: PriceState = {
  marketTokens: [],
  hatomTokens: [],
  reporters: [
    {
      id: REPORTER_KEY.chainlink,
      name: 'Chainlink-like',
      image: 'https://cdn.app.hatom.com/images/pages/prices/chainlink.svg',
      url: 'https://chain.link/',
    },
    {
      id: REPORTER_KEY.xExchange,
      name: 'xExchange',
      image: 'https://cdn.app.hatom.com/images/pages/prices/xexchange.svg',
      url: 'https://xexchange.com/',
    },
    {
      id: REPORTER_KEY.ashSwap,
      name: 'AshSwap',
      image: 'https://cdn.app.hatom.com/images/pages/prices/ashswap.svg',
      url: 'https://ashswap.io/',
    },
    {
      id: REPORTER_KEY.liquidStaking,
      name: 'Liquid Staking',
      image: 'https://cdn.app.hatom.com/images/pages/prices/liquid.svg',
      url:
        chainType === 'mainnet'
          ? 'https://app.hatom.com/liquid'
          : 'https://devnet.hatom.com/liquid',
    },
    {
      id: REPORTER_KEY.lending,
      name: 'Lending Protocol',
      image: 'https://cdn.app.hatom.com/images/pages/prices/lending.svg',
      url:
        chainType === 'mainnet'
          ? 'https://app.hatom.com/lend'
          : 'https://devnet.hatom.com/lend',
    },
  ],
};

export const priceSlice = createSlice({
  name: 'price',
  initialState,
  reducers: {
    setPrice: (state, action: PayloadAction<Partial<PriceState>>) => {
      Object.entries(action.payload).map(([key, value]) => {
        state[key as keyof PriceState] = value;
      });
    },
  },
});

export const { setPrice } = priceSlice.actions;

export const getPrices =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const [underlyingVariation24hPercentMap, hTokenVariation24hPercentMap] =
        await queryClient.safeFetchQuery({
          queryKey: ['prices:getMarketVariation24Percent'],
          queryFn: indexerService.getMarketVariation24Percent,
          cacheTime: MAX_CACHE_TIME,
          staleTime: MAX_CACHE_TIME,
          defaultValue: [{}, {}] as ResponseMarketVariation24Percent,
        });

      await Promise.all([
        dispatch(
          getMarketTokens({
            variation24hPercentMap: underlyingVariation24hPercentMap,
          }),
        ),
        dispatch(
          getHatomTokens({
            variation24hPercentMap: hTokenVariation24hPercentMap,
          }),
        ),
      ]);
    } catch (error) {
      logger.error('store:getMarketTokens', error);
      captureException(error);
    }
  };

export const getMarketTokens =
  ({
    variation24hPercentMap,
  }: {
    variation24hPercentMap: Record<string, string>;
  }) =>
  async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();
      const { markets } = state.protocol;

      const formattedMarketTokensPrices = formatMarketTokenPrices(
        markets,
        variation24hPercentMap,
      );

      await dispatch(
        addAction(setPrice({ marketTokens: formattedMarketTokensPrices })),
      );
    } catch (error) {
      logger.error('store:getMarketTokens', error);
      captureException(error);
    }
  };

export const getHatomTokens =
  ({
    variation24hPercentMap,
  }: {
    variation24hPercentMap: Record<string, string>;
  }) =>
  async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();
      const { markets } = state.protocol;

      const formattedHatomTokensPrices = formatHatomTokenPrices(
        markets,
        variation24hPercentMap,
      );

      await dispatch(
        addAction(setPrice({ hatomTokens: formattedHatomTokensPrices })),
      );
    } catch (error) {
      logger.error('store:getHatomTokens', error);
      captureException(error);
    }
  };

export const priceSelector = (state: RootState) => state.price;

export default priceSlice.reducer;
