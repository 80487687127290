import useMediaQuery from "@/hooks/useMediaQuery";

const LedgerWallet = () => {
  const lg = useMediaQuery('(min-width: 1024px )');
  return (
    <div className='w-fit h-fit relative z-3 lg:translate-y-[-3px] lg:translate-x-[20px]'>
      <img
        src="https://cdn.app.hatom.com/images/pages/bridge/ledgerBox.svg"
        alt='ledger box'
        className="w-[40.8px] lg:w-[57px] mb-[14px] lg:mb-[20px]"
      />
      <p className=' w-full text-start pl-[3.6px] lg:pl-[4.8px] text-[#D4DAE2]'>Ledger</p>
    </div>
  )
}

export default LedgerWallet