import React from 'react';

import clsxm from '@/services/clsxm';

type Props = {
  label: string;
  active: boolean;
  onClick?: () => void;
};

const TabButton = (props: Props) => {
  return (
    <div
      className={clsxm(
        'pt-1.5 md:pt-2.5 pb-[7px] lg:pb-[9px] px-[12px] lg:px-[23.5px] rounded-t-[16px] text-[12px] lg:text-[14px] border cursor-pointer',
        props.active
          ? 'bg-[#FCFAFF] border-[#D3D6EE] border-b-0 text-[#1F2226] dark:bg-[#191733] dark:text-white dark:border-[#6B5E9C]'
          : 'bg-transparent border-transparent text-[#9494A7] dark:text-[#7f73ab]',
      )}
      onClick={props.onClick}
    >
      {props.label}
    </div>
  );
};

export default TabButton;
