import { ResponseTokenPricesTuple } from '@/services/indexer/common/types';
import {
  QueryExchangeRate,
  ResponseExchangeRate,
} from '@/services/indexer/prices/types';
import { H_TOKEN_DECIMALS } from '@/store/protocol';
import { calcSimulateExchangeRate } from '@/utils/math/market';
import DefiUtils from 'defi-utils';

export const formatMarketVariation24hPercent = (
  exchangeRatesMap: ResponseExchangeRate,
  tokenPricesTuple: ResponseTokenPricesTuple,
) => {
  const [todayTokenPricesMap, yesterdayTokenPricesMap, variation24hPercentMap] =
    tokenPricesTuple;

  const symbols = Object.keys(todayTokenPricesMap);

  const [
    todayExchangeRatesMap,
    yesterdayExchangeRatesMap,
    underlyingDecimalsMap,
  ] = exchangeRatesMap;

  const variation24hPercentHtokenMap = symbols.reduce(
    (prev, symbol) => {
      const underlyingTodayPrice = todayTokenPricesMap[symbol] || '0';
      const underlyingYesterdayPrice = yesterdayTokenPricesMap[symbol] || '0';
      const todayExchangeRate = todayExchangeRatesMap[symbol] || '0';
      const yesterdayExchangeRate = yesterdayExchangeRatesMap[symbol] || '0';
      const underlyingDecimals = underlyingDecimalsMap[symbol] || 1;

      const todayHPriceUSD = new DefiUtils(1)
        .toBasicUnits(H_TOKEN_DECIMALS)
        .toUnderlying(todayExchangeRate)
        .toFullDecimals(underlyingDecimals)
        .toUSD(underlyingTodayPrice)
        .toSafeFixed(underlyingDecimals);

      const yesterdayHPriceUSD = new DefiUtils(1)
        .toBasicUnits(H_TOKEN_DECIMALS)
        .toUnderlying(yesterdayExchangeRate)
        .toFullDecimals(underlyingDecimals)
        .toUSD(underlyingYesterdayPrice)
        .toSafeFixed(underlyingDecimals);

      const variation24hPercent = new DefiUtils(todayHPriceUSD)
        .multipliedBy(100)
        .dividedBy(yesterdayHPriceUSD)
        .minus(100)
        .toSafeString();

      return {
        ...prev,
        [symbol]: variation24hPercent,
      };
    },
    {} as Record<string, string>,
  );

  return [variation24hPercentMap, variation24hPercentHtokenMap] as [
    Record<string, string>,
    Record<string, string>,
  ];
};

export const formatExchangeRates = ({
  queryMoneyMarket,
}: QueryExchangeRate): ResponseExchangeRate => {
  const todayExchangeRate =
    queryMoneyMarket?.reduce(
      (prev, { dailyStateHistory, underlying }) => {
        return {
          ...prev,
          [underlying.symbol]: calcSimulateExchangeRate({
            cash: dailyStateHistory?.[0]?.moneyMarketState?.cash || '0',
            borrows: dailyStateHistory?.[0]?.moneyMarketState?.borrows || '0',
            reserves: dailyStateHistory?.[0]?.moneyMarketState?.reserves || '0',
            totalSupply:
              dailyStateHistory?.[0]?.moneyMarketState?.totalSupply || '0',
            rate:
              dailyStateHistory?.[0]?.moneyMarketState?.supplyRatePerSecond ||
              '0',
            timestamp:
              dailyStateHistory?.[0]?.moneyMarketState?.timestamp ||
              new Date().toISOString(),
          }),
        };
      },
      {} as Record<string, string>,
    ) || {};

  const yesterdayExchangeRate =
    queryMoneyMarket?.reduce(
      (prev, { dailyStateHistory, underlying }) => {
        return {
          ...prev,
          [underlying.symbol]: calcSimulateExchangeRate({
            cash: dailyStateHistory?.[1]?.moneyMarketState?.cash || '0',
            borrows: dailyStateHistory?.[1]?.moneyMarketState?.borrows || '0',
            reserves: dailyStateHistory?.[1]?.moneyMarketState?.reserves || '0',
            totalSupply:
              dailyStateHistory?.[1]?.moneyMarketState?.totalSupply || '0',
            rate:
              dailyStateHistory?.[1]?.moneyMarketState?.supplyRatePerSecond ||
              '0',
            timestamp:
              dailyStateHistory?.[1]?.moneyMarketState?.timestamp ||
              new Date().toISOString(),
          }),
        };
      },
      {} as Record<string, string>,
    ) || {};

  const decimalsMap =
    queryMoneyMarket?.reduce(
      (prev, { underlying }) => {
        return {
          ...prev,
          [underlying.symbol]: underlying.decimals,
        };
      },
      {} as Record<string, number>,
    ) || {};

  return [todayExchangeRate, yesterdayExchangeRate, decimalsMap];
};
