
const MultiVerseXDefiWallet = () => {
  return (
    <div className='w-fit h-fit relative z-3 lg:translate-y-[17.4px] lg:translate-x-[30.9px] '>
      <img
        src="https://cdn.app.hatom.com/images/pages/bridge/multiverseXNoBg.svg"
        alt='multivers x'
        className="w-[41.9px] lg:w-[59.3px] mb-[9.8px] lg:mb-[13.3px] lg:translate-x-[-0.3px]  lg:translate-y-[-0.3px]"
      />
      <p className='w-[76px] lg:w-[114px] text-start text-white '>MultiversX Defi Wallet</p>
    </div>
  )
}

export default MultiVerseXDefiWallet