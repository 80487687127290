import { useMemo } from 'react';

import { useAppSelector } from '@/store/index';
import { H_MARKET_KEY, MARKET_KEY, protocolSelector } from '@/store/protocol';

export enum EXCHANGE_RATE_KEY {
  EGLD = MARKET_KEY.EGLD,
  MEX = MARKET_KEY.MEX,
  HTM = MARKET_KEY.HTM,
  RIDE = MARKET_KEY.RIDE,
  USDC = MARKET_KEY.USDC,
  USDT = MARKET_KEY.USDT,
  BUSD = MARKET_KEY.BUSD,
  UTK = MARKET_KEY.UTK,
  sEGLD = MARKET_KEY.sEGLD,
  WTAO = MARKET_KEY.WTAO,
  sWTAO = MARKET_KEY.sWTAO,
  USH = MARKET_KEY.USH,
  HsWTAO = H_MARKET_KEY.HsWTAO,
  HEGLD = H_MARKET_KEY.HEGLD,
  HMEX = H_MARKET_KEY.HMEX,
  HHTM = H_MARKET_KEY.HHTM,
  HRIDE = H_MARKET_KEY.HRIDE,
  HUSDC = H_MARKET_KEY.HUSDC,
  HUSDT = H_MARKET_KEY.HUSDT,
  HBUSD = H_MARKET_KEY.HBUSD,
  HUTK = H_MARKET_KEY.HUTK,
  HsEGLD = H_MARKET_KEY.HsEGLD,
  HUSH = H_MARKET_KEY.HUSH,
}

const useExchangeRate = (from: EXCHANGE_RATE_KEY, to: EXCHANGE_RATE_KEY) => {
  const { exchangeRates } = useAppSelector(protocolSelector);

  const exchangeRate = useMemo(() => {
    return (
      exchangeRates[
        `${from.toString().toUpperCase()}/${to.toString().toUpperCase()}`
      ] || '0'
    );
  }, [exchangeRates, from, to]);

  return exchangeRate;
};

export default useExchangeRate;
