import React from 'react';

import ConnectBridgeWalletStepHeader from '@/components/popups/ConnectBridgeWalletPopup/components/ConnectBridgeWalletStepHeader';
import ConnectBridgeWalletStepLogo from '@/components/popups/ConnectBridgeWalletPopup/components/ConnectBridgeWalletStepLogo';
import ConnectStepFooter from '@/components/popups/ConnectBridgeWalletPopup/components/ConnectStepFooter';
import ConnectWalletStepStateIndicator from '@/components/popups/ConnectBridgeWalletPopup/components/ConnectWalletStepStateIndicator';
import GoBackBtn from '@/components/popups/ConnectBridgeWalletPopup/components/GoBackButton';
import {
  BridgeWalletPopupSteps,
  WalletTypes,
} from '@/components/popups/ConnectBridgeWalletPopup/components/types';

import clsxm from '@/services/clsxm';

type ConnectWalletStepProps = {
  activeType: WalletTypes;
  sourceType: WalletTypes;
  setActiveWalletType: (value: WalletTypes) => void;
  step: BridgeWalletPopupSteps;
  address: string | undefined;
  setAddress: (value: string | undefined) => void;
  setStep: (value: BridgeWalletPopupSteps) => void;
  onDoneClick: () => void;
};

const ConnectWalletStep = ({
  activeType,
  setStep,
  setActiveWalletType,
  onDoneClick,
  sourceType,
  address,
  setAddress,
}: ConnectWalletStepProps) => {
  const isSource = activeType === sourceType;

  return (
    <div
      className={clsxm(
        'pt-[28.2px] lg:pt-[40px]',
        'w-full h-full flex flex-col justify-between items-center',
      )}
    >
      {!isSource && (
        <GoBackBtn
          type={activeType}
          onClick={() => setActiveWalletType(sourceType)}
        />
      )}

      <ConnectBridgeWalletStepHeader
        className={clsxm(
          'mb-[47.5px]',
          activeType === 'MULTIVERSE_X' ? 'lg:mb-[70px]' : 'lg:mb-[69.8px]',
        )}
        type={activeType}
        isSource={isSource}
      />
      <ConnectBridgeWalletStepLogo
        className='mb-[12.3px] lg:mb-[21px]'
        type={activeType}
      />
      <ConnectWalletStepStateIndicator
        className={clsxm(
          'mb-[31.2px] lg:mb-[43px]',
          !isSource && 'flex-row-reverse',
        )}
      />
      <ConnectStepFooter
        disableNext={!address}
        address={address}
        type={activeType}
        isSource={isSource}
        onConnectWalletClick={() => {
          setStep('CHOOSE_PROVIDER');
        }}
        onWalletAddressClick={() => {}}
        onWalletLogoutClick={() => {
          setAddress(undefined);
        }}
        onNextClick={() => {
          setActiveWalletType(
            sourceType === 'MULTIVERSE_X' ? 'FINNEY' : 'MULTIVERSE_X',
          );
        }}
        onDoneClick={onDoneClick}
      />
    </div>
  );
};

export default ConnectWalletStep;
