import DefiUtils from 'defi-utils';

import { MARKET_KEY } from '@/store/protocol';

import {
  QueryHTokenBalance,
  QueryTokenPrices,
  QueryTokenPricesV2,
  ResponseHTokenBalance,
  ResponseTokenPrices,
  ResponseTokenPricesTuple,
} from '@/services/indexer/common/types';
import { calcLiquidStakingExchangeRate } from '@/utils/math/liquid-staking';

export const formatHTokenBalance = ({
  getAccount,
}: QueryHTokenBalance): ResponseHTokenBalance => {
  return (getAccount?.markets || []).reduce(
    (prev, current) => ({
      ...prev,
      [current.market.underlying.symbol]: current.tokens,
    }),
    {},
  );
};

export const formatTokenPrices = (
  { queryToken = [], queryLiquidStaking = [] }: QueryTokenPrices,
  searchTimestamp?: string,
  liquidStakingExchangeRate?: string,
): ResponseTokenPrices => {
  const _liquidStakingExchangeRate =
    liquidStakingExchangeRate ||
    calcLiquidStakingExchangeRate(
      queryLiquidStaking?.[0]?.state?.cashReserve,
      queryLiquidStaking?.[0]?.state?.totalShares,
    );

  const queryTokenPopulated = queryToken
    .filter(
      ({ dailyPriceHistory, symbol }) =>
        dailyPriceHistory.length > 0 || symbol === MARKET_KEY.EGLD,
    )
    .map((tokenItem) => {
      const filteredToken = searchTimestamp
        ? tokenItem.dailyPriceHistory.filter(({ quote, price }) => {
            const quoteTime = new Date(
              quote?.timestamp || price?.timestamp || 0,
            ).getTime();
            const searchTime = new Date(searchTimestamp).getTime();

            return searchTime >= quoteTime;
          })
        : tokenItem.dailyPriceHistory;

      const priceEgld = filteredToken?.[0]?.quote?.priceInEgld || '0';

      let dailyPriceInEgld = '0';

      if (tokenItem.symbol == MARKET_KEY.EGLD) {
        dailyPriceInEgld = '1';
      } else if (tokenItem.symbol == MARKET_KEY.sEGLD) {
        dailyPriceInEgld = new DefiUtils(1)
          .toUnderlying(_liquidStakingExchangeRate)
          .toString();
      } else {
        dailyPriceInEgld = priceEgld;
      }

      const dailyPriceInUSD = filteredToken?.[0]?.price?.price || '0';

      return {
        ...tokenItem,
        dailyPriceInEgld,
        dailyPriceInUSD,
      };
    });

  const itemEgldInUSD = queryTokenPopulated.find(
    ({ symbol }) => symbol === MARKET_KEY.EGLD,
  );
  const itemEgldInUSDC = queryTokenPopulated.find(
    ({ symbol }) => symbol === MARKET_KEY.USDC,
  );

  const agregatorEGLDInUSD = new DefiUtils(
    itemEgldInUSD?.dailyPriceInUSD || '0',
  )
    .toFullDecimals(18)
    .toString();

  const priceHistoryEGLDInUSDC =
    new DefiUtils(1)
      .dividedBy(itemEgldInUSDC?.dailyPriceInEgld || 0)
      .toString() || '0';

  const usdcPriceInEgld = new DefiUtils(agregatorEGLDInUSD).isZero()
    ? priceHistoryEGLDInUSDC
    : agregatorEGLDInUSD;

  const egldInUsdc = usdcPriceInEgld !== '0' ? usdcPriceInEgld : '0';

  return queryTokenPopulated.reduce(
    (prev, { symbol, id, dailyPriceInEgld, dailyPriceInUSD }) => {
      const priceUSD =
        !new DefiUtils(egldInUsdc).isEqualTo('0') ||
        !new DefiUtils(dailyPriceInEgld).isEqualTo('0')
          ? new DefiUtils(egldInUsdc).multipliedBy(dailyPriceInEgld).toString()
          : '0';

      const value = !new DefiUtils(dailyPriceInUSD).isZero()
        ? new DefiUtils(dailyPriceInUSD).toFullDecimals(18).toString()
        : priceUSD;

      return {
        ...prev,
        [id]: value,
        [symbol]: value,
      };
    },
    {},
  );
};

export const formatTokenPricesV2 = (
  { queryAggregatorPrice }: QueryTokenPricesV2,
  liquidStakingExchangeRate: string,
  liquidStakingTaoExchangeRate: string,
) => {
  const formattedAggregatorPrices = queryAggregatorPrice.map(
    ({ id, price }) => {
      const symbol = id.split('-')?.[0] || '-';

      return {
        symbol,
        price: new DefiUtils(price).toFullDecimals(18).toString(),
      };
    },
  );

  const tuppleAggregatorPricesMap = formattedAggregatorPrices.reduce(
    (prev, { symbol, price }) => ({
      ...prev,
      [symbol]: [...(prev[symbol] || []), price],
    }),
    {} as Record<string, string[]>,
  );

  const aggregatorPrices = Object.entries(tuppleAggregatorPricesMap).map(
    ([symbol, prices]) => ({
      symbol,
      price: prices.find((price) => price !== '0') || '0',
    }),
  );

  const aggregatorPricesEGLD = aggregatorPrices.find(
    ({ symbol }) => symbol === 'EGLD',
  );

  const aggregatorPricesWTAO = aggregatorPrices.find(
    ({ symbol }) => symbol === 'WTAO',
  );

  return [
    ...aggregatorPrices,
    {
      symbol: MARKET_KEY.sEGLD,
      price: new DefiUtils(1)
        .toUnderlying(liquidStakingExchangeRate)
        .toUSD(aggregatorPricesEGLD?.price || '0')
        .toSafeString(),
    },
    {
      symbol: MARKET_KEY.sWTAO,
      price: new DefiUtils(1)
        .toUnderlying(liquidStakingTaoExchangeRate)
        .toUSD(aggregatorPricesWTAO?.price || '0')
        .toSafeString(),
    },
  ].reduce(
    (prev, current) => ({
      ...prev,
      [current.symbol]: current.price,
    }),
    {} as Record<string, string>,
  );
};

export const formatTokenPricesTuple = (
  ids: string[],
  todayPricesMap: Record<string, string>,
  yesterdayPricesMap: Record<string, string>,
): ResponseTokenPricesTuple => {
  const variation24hPercentMap = ids.reduce(
    (prev, current) => {
      const todayPriceUSD = todayPricesMap[current] || '0';
      const yesterdayPriceUSD = yesterdayPricesMap[current] || '0';

      const variation = new DefiUtils(todayPriceUSD)
        .multipliedBy(100)
        .dividedBy(yesterdayPriceUSD)
        .minus(100);

      return {
        ...prev,
        [current]: new DefiUtils(
          variation.toSafeString(),
        ).removeScientificNotation(),
      };
    },
    {} as Record<string, string>,
  );

  return [todayPricesMap, yesterdayPricesMap, variation24hPercentMap];
};
