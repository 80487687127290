import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { useLogin } from '@/hooks/auth/useLogin';
import useSignMultipleTransactions, {
  ALLOWED_TRANSACTIONS,
} from '@/hooks/core/useSignMultipleTransactions';
import useLendInteraction, {
  MAX_BORROW_LIMIT_MARGIN,
  MAX_RECOMMENDED_BORROW_LIMIT_MARGIN,
} from '@/hooks/interaction/useLendInteraction';
import useMediaQuery from '@/hooks/useMediaQuery';
import useToggle from '@/hooks/useToggle';

import CurrencyInputButton from '@/components/CurrencyInputButton';
import Hint from '@/components/Hint';
import ApproEqualIcon from '@/components/Icons/ApproEqualIcon';
import FutureStatsRightArrow from '@/components/Icons/FutureStatsRightArrow';
import InfoIcon from '@/components/Icons/InfoIcon';
import LabeledImage from '@/components/LabeledImage';
import { NetAPYTooltipContent } from '@/components/Lending/NetAPYTooltipContent';
import { SEGLDLiquidStakingApyPercentage } from '@/components/Lending/SEGLDLiquidStakingApyPercentage';
import RepayDustCheckbox from '@/components/popups/BorrowPopup/components/RepayDustCheckbox';
import PopupBed from '@/components/popups/PopupBed';
import PopupHead from '@/components/popups/PopupBed/components/PopupHead';
import OperationFormBottomAlerts from '@/components/popups/SupplyPopup/OperationFormBottomAlerts';
import Tabber from '@/components/Tabber';
import TransactionButton from '@/components/TransactionButton';

import { useAppSelector } from '@/store';
import { lendAppSelector } from '@/store/lend-app';
import { popupSelector } from '@/store/popup';
import {
  ASSET_ALT_NAME,
  calcHasMaxMarketPerAccount,
  MARKET_KEY,
  nativeMarketSelector,
  nativeUserBalanceSelector,
  protocolSelector,
} from '@/store/protocol';
import { rewardBatchSelector } from '@/store/reward-batch';
import {
  hasPendingTransactionsSelector,
  TRANSACTION_GROUP_TYPE,
  TRANSACTION_SUBGROUP_TYPE,
} from '@/store/transaction';

import { PrettyToken } from '@/sections/Liquid/Common/PrettyToken';
import {
  formatNumber,
  getBorrowLimitUsedPercent,
  subtractGasFee,
  toFormattedDecimalNum,
} from '@/utils/helpers';
import { getTotalBorrowAPYByMarket } from '@/utils/math/apy';

enum TABS {
  BORROW = 'borrow',
  REPAY_BORROW = 'repay',
}

const BORROW_TABS = [TABS.BORROW, TABS.REPAY_BORROW];

const estimateDailyAccruedInterest = (
  underlyingAmount: string | number,
): DefiUtils => {
  return new DefiUtils(underlyingAmount).multipliedBy(0.0001);
};

const BorrowPopup = () => {
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const {
    liquidStaking: { apy: liquidStakingAPY },
    liquidStakingTao: { apy: liquidStakingTaoAPY },
    userBalances,
    controller,
    marketsInteracted,
    marketsInteractedAmount,
  } = useAppSelector(protocolSelector);

  const { markets } = useAppSelector(lendAppSelector);
  const {
    showLiquidStakingAPY: showLiquidStakingAPYRaw,
    showLiquidStakingTaoAPY: showLiquidStakingTaoAPYRaw,
    account: { borrowBalanceUSD, borrowLimitUSDWithoutSimulate },
  } = useAppSelector(lendAppSelector);
  const { markets: rewardMarkets } = useAppSelector(rewardBatchSelector);

  const {
    data: { assetKey },
  } = useAppSelector(popupSelector);

  const hasMaxMarketPerAccount = useMemo(
    () =>
      calcHasMaxMarketPerAccount({
        userBalances,
        controller,
        token: assetKey,
      }),
    [assetKey, controller, userBalances],
  );
  const { isLoggedIn } = useLogin();
  const { t } = useTranslation();

  const showLiquidStakingAPY: boolean = useMemo(
    () => assetKey === MARKET_KEY.sEGLD && showLiquidStakingAPYRaw,
    [showLiquidStakingAPYRaw, assetKey],
  );

  const showLiquidStakingTaoAPY: boolean = useMemo(
    () => assetKey === MARKET_KEY.sWTAO && showLiquidStakingTaoAPYRaw,
    [showLiquidStakingTaoAPYRaw, assetKey],
  );

  const {
    accountBalances,
    underlying: { priceUSD, decimals },
    cash,
    borrowAPY,
    totalBorrow,
    assetKey: currency,
    logo,
    name,
    borrowCap,
    borrowEnabled,
    address,
  } = markets[assetKey as MARKET_KEY];
  const nativeMarket = useAppSelector(nativeMarketSelector);
  const nativeUserBalance = useAppSelector(nativeUserBalanceSelector);
  const { underlyingBalance: accountBalanceEGLD } = nativeUserBalance;

  const rewardsBorrowToken = useMemo(() => {
    const rewards = rewardMarkets[assetKey as MARKET_KEY]?.rewards || [];

    return rewards
      .filter(({ type }) => type === 'Borrow')
      .filter(({ speed }) => new DefiUtils(speed).isGreaterThan('0'));
  }, [assetKey, rewardMarkets]);

  const [selectedTab, setSelectedTab] = useState<TABS>(TABS.BORROW);
  const [maxSelected, setMaxSelected] = useState<boolean>(false);
  const [repayDailyInterestSelected, setRepayDailyInterestSelected] =
    useState<boolean>(false);

  const isBorrowTab = selectedTab === TABS.BORROW;
  const isRepayTab = selectedTab === TABS.REPAY_BORROW;

  const [inputValue, setInputValue] = useState<string>('0');
  const [inputValueAsBigNumber, setInputValueAsBigNumber] =
    useState<string>('0');

  const { signTransactions, isAllowedTransaction, isWhiteListed } =
    useSignMultipleTransactions();
  const { borrow, repayBorrow, removeAccountMarket } = useLendInteraction();

  const [hasFocus, toggleFocus] = useToggle();

  const md = useMediaQuery('(min-width: 768px)');

  const isValidInput = useMemo(
    () =>
      inputValue && new DefiUtils(inputValue).isGreaterThan(0) ? true : false,
    [inputValue],
  );

  const maxMarketReservesToBorrow = useMemo<string>(() => {
    const value = new DefiUtils(cash).minus(
      new DefiUtils(totalBorrow).multipliedBy('0.0001'),
    );

    return value.isLessThanOrEqualTo(0)
      ? '0'
      : value.toFixed(0, DefiUtils.ROUND_FLOOR);
  }, [cash, totalBorrow]);

  const underlyingBorrowBalanceFullDecimals = useMemo(
    () =>
      new DefiUtils(accountBalances.borrow).toFullDecimals(decimals).toNumber(),
    [accountBalances.borrow, decimals],
  );

  // includes estimated daily interest to repay -> Borrow Balance + 1%
  const borrowIncludeDailyInterestFullDecimals = useMemo(
    () =>
      new DefiUtils(underlyingBorrowBalanceFullDecimals)
        .plus(estimateDailyAccruedInterest(underlyingBorrowBalanceFullDecimals))
        .toNumber(),
    [underlyingBorrowBalanceFullDecimals],
  );

  const maxAccountBalanceAsBigNumber = useMemo(
    () =>
      assetKey === nativeMarket.underlying.symbol
        ? subtractGasFee(accountBalances.underlyingWallet)
        : accountBalances.underlyingWallet,
    [
      accountBalances.underlyingWallet,
      assetKey,
      nativeMarket.underlying.symbol,
    ],
  );

  const accountBalanceFullDecimals = useMemo(
    () =>
      new DefiUtils(accountBalances.underlyingWallet)
        .toFullDecimals(decimals)
        .toNumber(),
    [accountBalances.underlyingWallet, decimals],
  );

  const borrowTokenBalance = useMemo(
    () =>
      repayDailyInterestSelected
        ? borrowIncludeDailyInterestFullDecimals
        : underlyingBorrowBalanceFullDecimals,
    [
      borrowIncludeDailyInterestFullDecimals,
      repayDailyInterestSelected,
      underlyingBorrowBalanceFullDecimals,
    ],
  );

  const borrowTokenBalanceAsBigNumber = useMemo(
    () =>
      new DefiUtils(new DefiUtils(borrowTokenBalance).toFixed(decimals))
        .multipliedBy(`1e${decimals}`)
        .toString(),
    [borrowTokenBalance, decimals],
  );

  const maxRepayAmountAsBigNumber = useMemo(
    () =>
      new DefiUtils(maxAccountBalanceAsBigNumber).isGreaterThanOrEqualTo(
        new DefiUtils(borrowTokenBalanceAsBigNumber),
      )
        ? borrowTokenBalanceAsBigNumber
        : maxAccountBalanceAsBigNumber,
    [borrowTokenBalanceAsBigNumber, maxAccountBalanceAsBigNumber],
  );

  const getRemainingTokenLimitAsBigNumber = () => {
    const remainingLimitUSD = new DefiUtils(borrowLimitUSDWithoutSimulate)
      .multipliedBy(MAX_BORROW_LIMIT_MARGIN)
      .minus(borrowBalanceUSD);

    const remainingTokenLimitAsBigNumber = new DefiUtils(remainingLimitUSD)
      .fromUSD(priceUSD)
      .multipliedBy(`1e${decimals}`)
      .toFixed(0, DefiUtils.ROUND_FLOOR);

    return remainingTokenLimitAsBigNumber;
  };

  const getRemainingTokenLimitAsBigNumber2 = () => {
    const remainingLimitUSD = new DefiUtils(borrowLimitUSDWithoutSimulate)
      .multipliedBy(MAX_RECOMMENDED_BORROW_LIMIT_MARGIN)
      .minus(borrowBalanceUSD);

    const remainingTokenLimitAsBigNumber = new DefiUtils(remainingLimitUSD)
      .fromUSD(priceUSD)
      .multipliedBy(`1e${decimals}`)
      .toFixed(0, DefiUtils.ROUND_FLOOR);

    return remainingTokenLimitAsBigNumber;
  };

  const getRemainingTokenBorrowAmountAsBigNumber = (): string => {
    if (
      new DefiUtils(borrowLimitUSDWithoutSimulate).isGreaterThanOrEqualTo(
        new DefiUtils(borrowBalanceUSD),
      )
    ) {
      const remainingTokenLimitAsBigNumber =
        getRemainingTokenLimitAsBigNumber();

      return new DefiUtils(remainingTokenLimitAsBigNumber).isLessThan(
        maxMarketReservesToBorrow,
      )
        ? remainingTokenLimitAsBigNumber
        : maxMarketReservesToBorrow;
    }

    return '0';
  };

  const getRemainingTokenBorrowAmountAsBigNumber2 = (): string => {
    if (
      new DefiUtils(borrowLimitUSDWithoutSimulate).isGreaterThanOrEqualTo(
        borrowBalanceUSD,
      )
    ) {
      const remainingTokenLimitAsBigNumber =
        getRemainingTokenLimitAsBigNumber2();

      return new DefiUtils(remainingTokenLimitAsBigNumber).isLessThan(
        maxMarketReservesToBorrow,
      )
        ? remainingTokenLimitAsBigNumber
        : maxMarketReservesToBorrow;
    }

    return '0';
  };

  const estimatedUnderlyingDailyInterestAsBigNumber = useMemo(
    () => estimateDailyAccruedInterest(accountBalances.borrow).toNumber(),
    [accountBalances.borrow],
  );

  const remainingTokenLimitAsBigNumber = getRemainingTokenLimitAsBigNumber();

  const isMaxMarketReservesToBorrowGreatherThanRemainingTokenLimit = useMemo(
    () =>
      new DefiUtils(maxMarketReservesToBorrow).isGreaterThan(
        remainingTokenLimitAsBigNumber,
      ),
    [maxMarketReservesToBorrow, remainingTokenLimitAsBigNumber],
  );

  const tokenBorrowLimitAsBigNumber =
    getRemainingTokenBorrowAmountAsBigNumber();

  const hasEnoughMarketReserves = useMemo(
    () =>
      new DefiUtils(maxMarketReservesToBorrow).isGreaterThanOrEqualTo(
        new DefiUtils(inputValueAsBigNumber || 0),
      ),
    [inputValueAsBigNumber, maxMarketReservesToBorrow],
  );

  const hasEnoughCollateral = useMemo(
    () =>
      new DefiUtils(remainingTokenLimitAsBigNumber).isGreaterThanOrEqualTo(
        inputValueAsBigNumber,
      ),
    [inputValueAsBigNumber, remainingTokenLimitAsBigNumber],
  );

  const remainingTokenBorrowLimitAsBigNumber = useMemo(
    () =>
      new DefiUtils(tokenBorrowLimitAsBigNumber)
        .minus(estimatedUnderlyingDailyInterestAsBigNumber)
        .toFixed(0, DefiUtils.ROUND_FLOOR),
    [estimatedUnderlyingDailyInterestAsBigNumber, tokenBorrowLimitAsBigNumber],
  );

  const suggestedTokenBorrowLimitAsBigNumber =
    getRemainingTokenBorrowAmountAsBigNumber2();

  const repaySetMaxValueAsBigNumber = maxRepayAmountAsBigNumber;

  const repayLimitAsBigNumber = useMemo(
    () =>
      new DefiUtils(maxAccountBalanceAsBigNumber).isGreaterThanOrEqualTo(
        new DefiUtils(accountBalances.borrow),
      )
        ? borrowTokenBalanceAsBigNumber
        : maxAccountBalanceAsBigNumber,
    [
      accountBalances.borrow,
      borrowTokenBalanceAsBigNumber,
      maxAccountBalanceAsBigNumber,
    ],
  );

  const borrowLimitAsBigNumber = useMemo(
    () =>
      new DefiUtils(maxMarketReservesToBorrow).isLessThanOrEqualTo(
        tokenBorrowLimitAsBigNumber,
      )
        ? maxMarketReservesToBorrow
        : new DefiUtils(remainingTokenBorrowLimitAsBigNumber).isGreaterThan('0')
        ? new DefiUtils(remainingTokenBorrowLimitAsBigNumber).toFixed(
            0,
            DefiUtils.ROUND_FLOOR,
          )
        : new DefiUtils(tokenBorrowLimitAsBigNumber).toFixed(
            0,
            DefiUtils.ROUND_FLOOR,
          ),
    [
      maxMarketReservesToBorrow,
      remainingTokenBorrowLimitAsBigNumber,
      tokenBorrowLimitAsBigNumber,
    ],
  );

  const borrowSetMaxValueAsBigNumber = suggestedTokenBorrowLimitAsBigNumber;

  const maxRequestAmounts = useMemo(() => {
    return {
      [TABS.BORROW]: {
        setMaxValueAsBigNumber: borrowSetMaxValueAsBigNumber,
        limitAsBigNumber: borrowLimitAsBigNumber,
      },
      [TABS.REPAY_BORROW]: {
        setMaxValueAsBigNumber: repaySetMaxValueAsBigNumber,
        limitAsBigNumber: repayLimitAsBigNumber,
      },
    };
  }, [
    borrowLimitAsBigNumber,
    borrowSetMaxValueAsBigNumber,
    repayLimitAsBigNumber,
    repaySetMaxValueAsBigNumber,
  ]);

  const getTabIndex = (id: TABS) => {
    return BORROW_TABS.indexOf(id as TABS);
  };

  const footerNotes = useMemo(() => {
    return {
      [TABS.BORROW]: [
        {
          label: t('currently-borrowing'),
          value: underlyingBorrowBalanceFullDecimals,
        },
        // { label: "Token Borrow Limit", value: remainingTokenBorrowLimit },
      ],
      [TABS.REPAY_BORROW]: [
        {
          label: t('owed-amount'),
          value: underlyingBorrowBalanceFullDecimals,
        },
        { label: t('wallet-balance'), value: accountBalanceFullDecimals },
      ],
    };
  }, [accountBalanceFullDecimals, t, underlyingBorrowBalanceFullDecimals]);

  const enoughEGLDBalance = useMemo(
    () => new DefiUtils(subtractGasFee(accountBalanceEGLD)).isGreaterThan(0),
    [accountBalanceEGLD],
  );

  const hasFunds = useMemo(
    () =>
      new DefiUtils(
        maxRequestAmounts[selectedTab].limitAsBigNumber,
      ).isGreaterThan(0),
    [maxRequestAmounts, selectedTab],
  );

  const isValidAmountRequest = useMemo(
    () =>
      isValidInput
        ? new DefiUtils(inputValueAsBigNumber).isLessThanOrEqualTo(
            maxRequestAmounts[selectedTab].limitAsBigNumber,
          )
        : false,
    [inputValueAsBigNumber, isValidInput, maxRequestAmounts, selectedTab],
  );

  const inputPriceUSD = useMemo(
    () =>
      isValidAmountRequest
        ? new DefiUtils(inputValue).toUSD(priceUSD).toNumber()
        : 0,
    [inputValue, isValidAmountRequest, priceUSD],
  );

  const calcNextValue = () => {
    if (isBorrowTab) {
      return new DefiUtils(borrowBalanceUSD).plus(inputPriceUSD).toNumber();
    }

    const result = new DefiUtils(borrowBalanceUSD)
      .minus(inputPriceUSD)
      .toNumber();

    return result > 0 ? result : 0;
  };

  const nextBorrowAmountUSD = isValidAmountRequest ? calcNextValue() : 0;

  const nextBorrowLimitUsedPercentage =
    isValidAmountRequest &&
    nextBorrowAmountUSD &&
    getBorrowLimitUsedPercent(
      nextBorrowAmountUSD,
      borrowLimitUSDWithoutSimulate,
    );

  const onTabToggle = (tabNumber: number) => {
    setSelectedTab(BORROW_TABS[tabNumber]);
  };

  const getActionButtonLabel = (): string => {
    if (isRepayTab) {
      if (!isLoggedIn || !hasFunds) return t('no-balance-to-repay');
      if (!isValidInput && hasFunds) return t('repay-borrow');
      if (isValidInput && isValidAmountRequest) return t('repay-borrow');
      if (isValidInput && !isValidAmountRequest)
        return t('no-balance-to-repay');
      return t('repay-borrow');
    }

    if (isBorrowTab) {
      if (!isLoggedIn) return t('no-funds-available');

      if (!borrowEnabled) return t('borrow-not-enabled', 'Borrow not enabled');
      if (isValidInput && isMaxBorrowCap) return t('amount-exceeds-borrow-cap');
      if (isValidInput && hasMaxMarketPerAccount)
        return t('limit-money-market-reached');
      if (
        isMaxMarketReservesToBorrowGreatherThanRemainingTokenLimit &&
        !hasEnoughMarketReserves
      )
        return t('insufficient-market-liquidity');

      if (!hasEnoughCollateral) return t('insufficient-collateral');
      if (!hasEnoughMarketReserves) return t('insufficient-market-liquidity');
      if (isValidInput && !isValidAmountRequest)
        return t('insufficient-collateral');
      return t('borrow');
    }

    return t('unknown');
  };

  const isMaxBorrowCap = useMemo(() => {
    if (!isBorrowTab || borrowCap === 'Infinity') {
      return false;
    }

    const totalBorrowUnderlying = new DefiUtils(totalBorrow)
      .toFullDecimals(decimals)
      .toString();

    const futureBorrowCap = new DefiUtils(totalBorrowUnderlying)
      .plus(inputValue)
      .toString();

    return new DefiUtils(futureBorrowCap).isGreaterThanOrEqualTo(borrowCap);
  }, [totalBorrow, decimals, isBorrowTab, inputValue, borrowCap]);

  const getActionButtonDisabled = (): boolean => {
    return (
      !isLoggedIn ||
      !isValidInput ||
      !isValidAmountRequest ||
      isMaxBorrowCap ||
      !hasFunds ||
      !enoughEGLDBalance ||
      (isBorrowTab && !hasEnoughMarketReserves) ||
      (isBorrowTab && !hasEnoughCollateral) ||
      (isBorrowTab && hasMaxMarketPerAccount) ||
      (isBorrowTab && !borrowEnabled)
    );
  };

  const [actionButton, setActionButton] = useState({
    disabled: true,
    label: getActionButtonLabel(),
  });

  const handleSetMaxAmount = () => {
    if (maxSelected) {
      const value = new DefiUtils(
        maxRequestAmounts[selectedTab].setMaxValueAsBigNumber,
      )
        .dividedBy(`1e${decimals}`)
        .toFixed(decimals);
      const decimalsPlaces = new DefiUtils(value).decimalPlaces();
      const valueWithMaxDecimalPlaces = new DefiUtils(value).toFixed(
        decimalsPlaces,
      );

      const valueAsBigNumber =
        maxRequestAmounts[selectedTab].setMaxValueAsBigNumber;

      if (
        parseFloat(valueWithMaxDecimalPlaces) <= 0 ||
        parseFloat(valueAsBigNumber) <= 0
      ) {
        setInputValue('0');
        setInputValueAsBigNumber('0');
      } else {
        setInputValue(valueWithMaxDecimalPlaces);
        setInputValueAsBigNumber(valueAsBigNumber);
      }
    }
  };

  const handleInputValueChange = (value: string) => {
    setInputValue(value);
    setInputValueAsBigNumber(
      new DefiUtils(value).multipliedBy(`1e${decimals}`).toString(),
    );
  };

  useEffect(() => {
    handleSetMaxAmount();
    if (!maxSelected) {
      setInputValue('0');
      setInputValueAsBigNumber('0');
      setRepayDailyInterestSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxSelected, repayDailyInterestSelected]);

  useEffect(() => {
    setMaxSelected(false);
    // eslint-disable-next-line
  }, [selectedTab]);

  useEffect(() => {
    setActionButton({
      disabled: getActionButtonDisabled(),
      label: getActionButtonLabel(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, inputValue]);

  const handleButtonClick = async () => {
    // VALIDATIONS
    if (
      hasPendingTransactions ||
      !isAllowedTransaction(
        isBorrowTab
          ? ALLOWED_TRANSACTIONS.LEND_BORROW
          : isRepayTab
          ? ALLOWED_TRANSACTIONS.LEND_REPAY_BORROW
          : '',
      )
    ) {
      return;
    }

    if (isBorrowTab) {
      const marketInteractedAvaiableToRemove = marketsInteracted.find(
        ({ canRemove }) => canRemove,
      );

      const hasMaxMarketPerAccountByController =
        marketsInteractedAmount >= +controller.maxMarketsPerAccount &&
        !marketsInteracted.map(({ address }) => address).includes(address) &&
        (marketInteractedAvaiableToRemove?.address.length || 0) > 0;

      await signTransactions(
        [
          ...(hasMaxMarketPerAccountByController
            ? [
                removeAccountMarket(
                  marketInteractedAvaiableToRemove?.address || '',
                ),
              ]
            : []),
          borrow({
            tokenKey: assetKey,
            amountAsBigInteger: inputValueAsBigNumber,
          }),
        ],
        {
          token: assetKey,
          group: TRANSACTION_GROUP_TYPE.LENDING,
          subgroup: TRANSACTION_SUBGROUP_TYPE.BORROW,
          result: inputValue,
          isSecuencial: hasMaxMarketPerAccountByController,
        },
      );
    }

    if (isRepayTab) {
      await signTransactions(
        [
          repayBorrow({
            tokenKey: assetKey,
            amountAsBigInteger: inputValueAsBigNumber,
          }),
        ],
        {
          token: assetKey,
          group: TRANSACTION_GROUP_TYPE.LENDING,
          subgroup: TRANSACTION_SUBGROUP_TYPE.REPAY_BORROW,
          result: inputValue,
        },
      );
    }
  };

  const totalBorrowAPY = useMemo(() => {
    const total = getTotalBorrowAPYByMarket({
      borrowAPY,
      rewardsTokensAPY: rewardsBorrowToken
        .reduce((prev, current) => prev.plus(current.apy), new DefiUtils('0'))
        .toString(),
    });

    return total
      .plus(
        showLiquidStakingAPY
          ? liquidStakingAPY
          : showLiquidStakingTaoAPY
          ? liquidStakingTaoAPY
          : '0',
      )
      .toString();
  }, [
    borrowAPY,
    rewardsBorrowToken,
    showLiquidStakingAPY,
    liquidStakingAPY,
    showLiquidStakingTaoAPY,
    liquidStakingTaoAPY,
  ]);

  const showMaxButton = isBorrowTab;

  return (
    <PopupBed close={false}>
      <div className='font-semibold leading-tight tracking-normal'>
        <PopupHead>
          <LabeledImage
            name={ASSET_ALT_NAME[assetKey] ?? name}
            path={logo.normal}
            night={logo.nightMode}
          />
        </PopupHead>

        {/* Body */}

        <>
          <div className='relative mx-2.5 my-2'>
            <div>
              <div className='relative'>
                <input type='text' style={{ display: 'none' }} />
                <Hint
                  unvisible={!maxSelected}
                  autoReplace={!md}
                  content={t('maximum-amount-is-selected')}
                  placement='top-center'
                  block
                >
                  <CurrencyInput
                    className={classNames(
                      'w-full max-w-[442px]',
                      'p-4 px-[70px]',
                      'text-center text-[28px] font-normal leading-tight placeholder:text-[#6A6A8C] disabled:text-[#6A6A8C] dark:text-indigo-100 placeholder:dark:text-[#6A6A8C] disabled:dark:text-[#6A6A8C]',
                      maxSelected &&
                        'pointer-events-none !cursor-not-allowed !text-[#6A6A8C] !opacity-100',
                      'placeholder:text-current',
                      'outline-none',
                      'bg-transparent',
                    )}
                    placeholder='0'
                    onFocus={toggleFocus}
                    onBlur={toggleFocus}
                    suffix={
                      !hasFocus && !parseFloat(inputValue) ? ` ${currency}` : ''
                    }
                    value={inputValue}
                    autoComplete='off'
                    aria-autocomplete='none'
                    decimalsLimit={decimals}
                    groupSeparator=','
                    decimalSeparator='.'
                    allowNegativeValue={false}
                    // disabled={maxSelected}
                    onValueChange={(x) => handleInputValueChange(x ?? '0')}
                    inputMode='text'
                    data-testid='borrowInput'
                  />
                </Hint>
                <div className='absolute right-3 top-1/2 grid -translate-y-1/2 place-items-end'>
                  <Hint
                    autoReplace={!md}
                    content={
                      isBorrowTab
                        ? t('set-maximum-recommended-by-the-protocol')
                        : t('set-maximum')
                    }
                    placement={md ? 'top-center' : 'right-end'}
                  >
                    <CurrencyInputButton
                      onClick={() => setMaxSelected(!maxSelected)}
                      selected={maxSelected}
                      borderLess={showMaxButton && !maxSelected}
                    >
                      {showMaxButton ? (
                        <div>
                          80% <br />
                          {t('limit')}
                        </div>
                      ) : (
                        <span>{t('max')}</span>
                      )}
                    </CurrencyInputButton>
                  </Hint>
                </div>
              </div>
              <div className='mt-3 flex justify-between'>
                <div>
                  <div className='text-xs font-semibold dark:text-white'>
                    {t('estimated-price')}
                  </div>
                  <div className='flex items-center gap-1 text-xs text-[#6A6A8C] dark:text-[#625E8F]'>
                    <ApproEqualIcon className='mt-0.5' />
                    {`$${formatNumber(inputPriceUSD)}`}
                  </div>
                </div>
                {!isBorrowTab && (
                  <Hint
                    autoReplace={!md}
                    content={t('include-daily-accrued-interest-repay')}
                    placement='bottom-center'
                  >
                    <RepayDustCheckbox
                      checked={maxSelected}
                      disabled={!maxSelected}
                      onChange={(checked) =>
                        setRepayDailyInterestSelected(checked)
                      }
                    />
                  </Hint>
                )}
              </div>
            </div>
          </div>
        </>

        <>
          <div>
            <Tabber
              onChange={onTabToggle}
              tabs={[...BORROW_TABS].map((key) => t(key.toLowerCase()))}
              defaultSelected={getTabIndex(selectedTab)}
              customTheme={(tabIdx) => {
                if (Number.isInteger(tabIdx))
                  return 'text-[#45A373] border-current';
                else
                  return 'text-[#6A6A8C] dark:text-[#625E8F] border-[#E2DFFB] dark:border-[#3F3B76]';
              }}
            >
              <div className='bg-[#FCFAFF] p-5 pb-3 dark:bg-[#292651]'>
                {/* Borrow Rate */}
                <>
                  <div className='pb-4'>
                    <div className='text-xs dark:text-indigo-100'>
                      {t('borrow-rates')}
                    </div>
                    <div className='mt-2 flex items-center justify-between px-1 dark:text-white'>
                      <div className='flex items-center gap-2'>
                        <div>
                          <img className='h-[36px]' src={logo.normal} alt='' />
                        </div>
                        <div className='text-lg'>{t('borrow-apy')}</div>
                      </div>
                      <div>{`${new DefiUtils(borrowAPY)
                        .multipliedBy('-1')
                        .toNumber()}%`}</div>
                    </div>
                  </div>
                </>

                {/* Rewards */}
                <div className='space-y-2 '>
                  {(showLiquidStakingAPY || showLiquidStakingTaoAPY) && (
                    <SEGLDLiquidStakingApyPercentage
                      token={
                        showLiquidStakingAPY
                          ? MARKET_KEY.sEGLD
                          : MARKET_KEY.sWTAO
                      }
                      tooltipContent={
                        <div className='max-w-[394px] text-[10px] leading-tight'>
                          The{' '}
                          <span className='text-[#1679F9]'>
                            Liquid Staking APY
                          </span>{' '}
                          represents the value generated by{' '}
                          <PrettyToken token={assetKey} /> from staking
                          EGLD with validators. You need to be cautious when
                          borrowing <PrettyToken token={assetKey} /> as
                          its value will constantly accrue over time following
                          the{' '}
                          <span className='text-[#1679F9]'>
                            Liquid Staking APY
                          </span>
                          .
                        </div>
                      }
                      apy={formatNumber(
                        new DefiUtils(
                          showLiquidStakingAPY
                            ? liquidStakingAPY
                            : showLiquidStakingTaoAPY
                            ? liquidStakingTaoAPY
                            : '0',
                        )
                          .multipliedBy('-1')
                          .toNumber(),
                        3,
                      )}
                    />
                  )}

                  {rewardsBorrowToken.length > 0 ||
                  showLiquidStakingAPY ||
                  showLiquidStakingTaoAPY ? (
                    <div className='-mx-1 flex flex-col rounded-[8px] bg-[#E0F3E9] p-2 pt-1 text-sm dark:bg-[#2A6362] dark:text-white'>
                      <div className='pl-2'>
                        {rewardsBorrowToken.map((rewardTokenItem) => (
                          <div
                            key={rewardTokenItem.batchId}
                            className='flex items-center gap-3.5 py-[3px]'
                          >
                            <div className='rounded-full border border-[#9097D9] dark:border-[#45A387]'>
                              <img
                                src={rewardTokenItem.logo}
                                alt={`Logo of ${rewardTokenItem.tokenId}`}
                                className='h-5 w-5'
                              />
                            </div>
                            <span>{t('rewards-apy')}</span>
                            <span className='ml-auto'>
                              {formatNumber(rewardTokenItem.apy)}%
                            </span>
                          </div>
                        ))}
                      </div>
                      <div className='ml-11 flex h-7 items-center'>
                        <div className='flex items-center gap-1'>
                          {t('net-apy')}
                          <Hint
                            delayEnter={0}
                            placement='top-center'
                            content={
                              <NetAPYTooltipContent
                                parts={[
                                  ...(showLiquidStakingAPY ||
                                  showLiquidStakingTaoAPY
                                    ? ['Liquid Staking']
                                    : []),
                                  'Borrow',
                                  (rewardsBorrowToken.length > 0 &&
                                    'Rewards') as any,
                                ]}
                              />
                            }
                          >
                            <InfoIcon width={12} />
                          </Hint>
                        </div>
                        <span className='grow'></span>
                        <span>
                          {formatNumber(
                            new DefiUtils(totalBorrowAPY)
                              .multipliedBy('-1')
                              .toNumber(),
                            3,
                          )}
                          %
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className='py-5'></div>
                  )}
                </div>

                {/* Borrow Limit */}
                <>
                  <div className='mt-3'>
                    <span className='text-xs dark:text-indigo-100'>
                      {t('borrow-limits')}
                    </span>
                    <div className='mt-2 flex items-center justify-between border-b-2 border-current py-2 pt-0 text-sm leading-tight text-[#6A6A8C] dark:border-[#625E8F]/50 dark:text-[#625E8F]'>
                      <div>{t('borrow-balance')}</div>
                      <div className='flex items-center justify-center gap-1'>
                        <div className='flex items-center gap-1'>
                          {`$${formatNumber(borrowBalanceUSD, 2)}`}
                          {isValidAmountRequest && (
                            <>
                              <FutureStatsRightArrow />
                              {`$${formatNumber(nextBorrowAmountUSD, 2)}`}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='flex items-center justify-between py-2 text-sm leading-tight text-[#6A6A8C] dark:text-[#625E8F]'>
                      <div>{t('borrow-limit-used')}</div>
                      <div className='flex items-center gap-1'>
                        {`${formatNumber(
                          getBorrowLimitUsedPercent(
                            borrowBalanceUSD,
                            borrowLimitUSDWithoutSimulate,
                          ),
                          2,
                        )}%`}
                        {isValidAmountRequest && (
                          <>
                            <FutureStatsRightArrow />
                            {`${formatNumber(
                              nextBorrowLimitUsedPercentage as number,
                              2,
                            )}%`}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>

                {/* Action Button */}
                <div className='mt-3'>
                  <Hint
                    content={
                      hasPendingTransactions
                        ? t('translation:wait-tx-inprogress')
                        : !isWhiteListed()
                        ? t('translation:tx-not-enable-to-sign')
                        : t('translation:tx-not-allowed')
                    }
                    placement='top-center'
                    className='w-full'
                    unvisible={
                      !(
                        hasPendingTransactions ||
                        !isAllowedTransaction(
                          isBorrowTab
                            ? ALLOWED_TRANSACTIONS.LEND_BORROW
                            : isRepayTab
                            ? ALLOWED_TRANSACTIONS.LEND_REPAY_BORROW
                            : '',
                        ) ||
                        !isWhiteListed()
                      )
                    }
                  >
                    <TransactionButton
                      onClick={handleButtonClick}
                      text={actionButton.label}
                      disabled={actionButton.disabled}
                    />
                  </Hint>
                </div>

                {/* Bottom Note */}
                <div className='mt-3.5 space-y-1.5 text-sm text-[#6A6A8C] dark:text-[#625E8F]'>
                  {footerNotes[selectedTab].map(({ label, value }) => {
                    return (
                      <div key={label} className='flex justify-between'>
                        <span>{label}</span>
                        <span>{`${toFormattedDecimalNum(
                          +(value as number),
                          value == 0 ? 2 : decimals,
                        )} ${currency}`}</span>
                      </div>
                    );
                  })}

                  <OperationFormBottomAlerts />
                </div>
              </div>
            </Tabber>
          </div>
        </>
      </div>
    </PopupBed>
  );
};

export default BorrowPopup;
