import DefiUtils from 'defi-utils';

import { HIDDEN_DELEGATION_CONTRACTS, TAO_SERVICE_FEE } from '@/store/protocol';

import blockchainService from '@/services/blockchain';
import {
  QueryProtocol,
  QueryProtocolAccountMarket,
  QueryProtocolUpdated,
  ResponseProtocol,
  ResponseProtocolAccountMarket,
  ResponseProtocolUpdated,
} from '@/services/indexer/protocol/types';
import {
  calcLiquidStakingAPY,
  calcLiquidStakingExchangeRate,
} from '@/utils/math/liquid-staking';

export const formatProtocol = ({
  queryController,
  queryGovernance,
  queryLiquidStaking,
  queryLendegate,
  queryMoneyMarket,
  queryDelegationContract,
  queryBooster,
  queryWrappedTaoLiquidStaking,
  queryTaoLendegate,
}: QueryProtocol): ResponseProtocol => {
  const liquidStakingTotalFee = new DefiUtils(
    queryLiquidStaking?.[0]?.totalFee || '0',
  )
    .dividedBy(100)
    .toString();

  const filteredDelegationContracts = queryDelegationContract
    .map((delegationContractItem) => ({
      ...delegationContractItem,
      serviceFee: new DefiUtils(delegationContractItem.serviceFee)
        .dividedBy(100)
        .toString(),
      apr: new DefiUtils(delegationContractItem.apr).dividedBy(100).toString(),
    }))
    .filter(
      (delegationContractItem) =>
        !HIDDEN_DELEGATION_CONTRACTS.includes(delegationContractItem.id),
    );

  const liquidStakingAPY = calcLiquidStakingAPY(
    liquidStakingTotalFee,
    filteredDelegationContracts,
  );

  return {
    markets: queryMoneyMarket
      .filter((moneyMarket) => moneyMarket.underlying !== null)
      .map((moneyMarket) =>
        moneyMarket.underlying.symbol === null
          ? {
              ...moneyMarket,
              underlying: {
                ...moneyMarket.underlying,
                symbol: moneyMarket.underlying.id.split('-')?.[0] || '',
                name: moneyMarket.underlying.id.split('-')?.[0] || '',
                decimals: 18,
              },
            }
          : moneyMarket,
      )
      .reduce(
        (prev, current) => ({ ...prev, [current.underlying.symbol]: current }),
        {},
      ) as any,
    controller: {
      address: queryController?.[0]?.id || '',
      maxMarketsPerAccount: queryController?.[0]?.maxMarketsPerAccount || '0',
    },
    governance: {
      address: queryGovernance?.[0]?.id || '',
      votingDelayInBlocks: queryGovernance?.[0]?.votingDelay || '0',
      quorum: queryGovernance?.[0]?.quorum || '0',
      voteNftId: '',
      votingPeriodInBlocks: queryGovernance?.[0]?.votingPeriod || '0',
    },
    liquidStaking: {
      address: queryLiquidStaking?.[0]?.id || '',
      undelegateTokenId: queryLiquidStaking?.[0]?.undelegateTokenId.id || '',
      lsTokenId: queryLiquidStaking?.[0]?.lsToken.id || '',
      zapAddress: queryLendegate?.[0]?.id || '',
      totalFee: liquidStakingTotalFee,
      totalStaked: queryLiquidStaking?.[0]?.state?.cashReserve || '0',
      exchangeRate: calcLiquidStakingExchangeRate(
        queryLiquidStaking?.[0]?.state?.cashReserve,
        queryLiquidStaking?.[0]?.state?.totalShares,
      ),
      unbondPeriod: queryLiquidStaking?.[0]?.unbondPeriod || '0',
      minEgldToDelegate: '1',
      apy: liquidStakingAPY === '0' ? '41' : liquidStakingAPY, // delete this when we have a secondary source
    },
    liquidStakingTao: {
      address: queryWrappedTaoLiquidStaking?.[0]?.id || '',
      lsTokenId: queryWrappedTaoLiquidStaking?.[0]?.lsToken.id || '',
      totalFee: TAO_SERVICE_FEE,
      totalStaked: queryWrappedTaoLiquidStaking?.[0]?.cash || '0',
      exchangeRate: calcLiquidStakingExchangeRate(
        queryWrappedTaoLiquidStaking?.[0]?.cash,
        queryWrappedTaoLiquidStaking?.[0]?.lsTokenSupply,
      ),
      tokenSupply: queryWrappedTaoLiquidStaking?.[0]?.lsTokenSupply || '0',
      apy: '0',
      zapAddress: queryTaoLendegate?.[0]?.id || '',
    },
    booster: {
      address: queryBooster?.[0].id ?? '',
      stakingRatioThreshold: queryBooster?.[0].stakingRatioThreshold ?? '0',
      cooldownPeriod: queryBooster?.[0].cooldownPeriod ?? '0',
      totalStaked: queryBooster?.[0].totalStaked ?? '0',
    },
  };
};

export const formatProtocolUpdated = async ({
  queryBooster,
  queryMoneyMarket,
  queryLiquidStaking,
  queryWrappedTaoLiquidStaking,
}: QueryProtocolUpdated): Promise<ResponseProtocolUpdated> => {
  return {
    booster: {
      address: queryBooster?.[0].id ?? '',
      stakingRatioThreshold: queryBooster?.[0].stakingRatioThreshold ?? '0',
      cooldownPeriod: queryBooster?.[0].cooldownPeriod ?? '0',
      totalStaked: await blockchainService.boosterBatch.getTotalStake(
        queryBooster?.[0].id || '',
      ),
    },
    liquidStaking: {
      totalStaked: queryLiquidStaking?.[0]?.state?.cashReserve || '0',
      exchangeRate: calcLiquidStakingExchangeRate(
        queryLiquidStaking?.[0]?.state?.cashReserve,
        queryLiquidStaking?.[0]?.state?.totalShares,
      ),
    },
    liquidStakingTao: {
      totalFee: TAO_SERVICE_FEE,
      totalStaked: queryWrappedTaoLiquidStaking?.[0]?.cash || '0',
      exchangeRate: calcLiquidStakingExchangeRate(
        queryWrappedTaoLiquidStaking?.[0]?.cash,
        queryWrappedTaoLiquidStaking?.[0]?.lsTokenSupply,
      ),
      tokenSupply: queryWrappedTaoLiquidStaking?.[0]?.lsTokenSupply || '0',
    },
    markets: queryMoneyMarket
      .filter((moneyMarket) => moneyMarket.underlying !== null)
      .map(({ mintStatus, borrowStatus, ...moneyMarket }) => {
        return {
          ...moneyMarket,
          mintStatus,
          borrowStatus,
          underlying:
            moneyMarket.underlying.symbol === null
              ? {
                  ...moneyMarket.underlying,
                  symbol: moneyMarket.underlying.id.split('-')?.[0] || '',
                  name: moneyMarket.underlying.id.split('-')?.[0] || '',
                  decimals: 18,
                }
              : moneyMarket.underlying,
        };
      })
      .reduce(
        (prev, current) => ({ ...prev, [current.underlying.symbol]: current }),
        {},
      ) as any,
  };
};

export const formatProtocolAccountMarketsMap = ({
  getAccount,
}: QueryProtocolAccountMarket): ResponseProtocolAccountMarket[] => {
  return getAccount?.markets || [];
};
