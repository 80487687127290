import { HWProvider } from '@multiversx/sdk-hw-provider';
import { captureException } from '@sentry/nextjs';
import DefiUtils from 'defi-utils';
import { useTranslation } from 'next-i18next';
import toast from 'react-hot-toast';

import { Logout } from '@/hooks/auth/useLogout';

import LogoutIcon from '@/components/Icons/Logout';

import { useAppDispatch, useAppSelector } from '@/store';
import {
  clearAuthStates,
  setAccountState,
  setLoggingInState,
  setLoginInfoState,
} from '@/store/auth';
import {
  clearDappProvider,
  networkStateSelector,
  setNetworkState,
} from '@/store/network';
import { closePopup, openPopup } from '@/store/popup';
import { nativeMarketSelector } from '@/store/protocol';

import gatewayService from '@/services/gateway';
import { getNewLoginExpiresTimestamp } from '@/utils/expiresAt';
import logger from '@/utils/logger';

import { LoginMethodsEnum } from '@/types/enums';

const ledgerErrorCodes = {
  0x9000: {
    code: 'codeSuccess',
    message: 'Success',
  },
  0x6985: {
    code: 'ERR_USER_DENIED',
    message: 'Rejected by user',
  },
  0x6d00: {
    code: 'ERR_UNKNOWN_INSTRUCTION',
    message: 'Unknown instruction',
  },
  0x6e00: {
    code: 'ERR_WRONG_CLA',
    message: 'Wrong CLA',
  },
  0x6e01: {
    code: 'ERR_INVALID_ARGUMENTS',
    message: 'Invalid arguments',
  },
  0x6e02: {
    code: 'ERR_INVALID_MESSAGE',
    message: 'Invalid message',
  },
  0x6e03: {
    code: 'ERR_INVALID_P1',
    message: 'Invalid P1',
  },
  0x6e04: {
    code: 'ERR_MESSAGE_TOO_LONG',
    message: 'Message too long',
  },
  0x6e05: {
    code: 'ERR_RECEIVER_TOO_LONG',
    message: 'Receiver too long',
  },
  0x6e06: {
    code: 'ERR_AMOUNT_TOO_LONG',
    message: 'Amount too long',
  },
  0x6e07: {
    code: 'ERR_CONTRACT_DATA_DISABLED',
    message: 'Contract data disabled in app options',
  },
  0x6e08: {
    code: 'ERR_MESSAGE_INCOMPLETE',
    message: 'Message incomplete',
  },
  0x6e10: {
    code: 'ERR_SIGNATURE_FAILED',
    message: 'Signature failed',
  },
  0x6e09: {
    code: 'ERR_WRONG_TX_VERSION',
    message: 'Wrong TX version',
  },
  0x6e0a: {
    code: 'ERR_NONCE_TOO_LONG',
    message: 'Nonce too long',
  },
  0x6e0b: {
    code: 'ERR_INVALID_AMOUNT',
    message: 'Invalid amount',
  },
  0x6e0c: {
    code: 'ERR_INVALID_FEE',
    message: 'Invalid fee',
  },
  0x6e0d: {
    code: 'ERR_PRETTY_FAILED',
    message: 'Pretty failed',
  },
  0x6e0e: {
    code: 'ERR_DATA_TOO_LONG',
    message: 'Data too long',
  },
  0x6e0f: {
    code: 'ERR_WRONG_TX_OPTIONS',
    message: 'Invalid transaction options',
  },
  0x6e11: {
    code: 'ERR_SIGN_TX_DEPRECATED',
    message:
      'Regular transaction signing is deprecated in this version. Use hash signing.',
  },
};

const ledgerAppErrorText = 'Check if MultiversX app is open on Ledger';

const notConnectedCode = 0x6e01;
const wrongClaCode = 0x6e00;
const inactiveAppCodes = [notConnectedCode, wrongClaCode];

export function getLedgerErrorCodes(err?: any) {
  let errorMessage: string | null = null;
  if (err?.statusCode in ledgerErrorCodes) {
    const statusCode: keyof typeof ledgerErrorCodes = err?.statusCode;
    const { message } = ledgerErrorCodes[statusCode];
    errorMessage = inactiveAppCodes.includes(statusCode)
      ? ledgerAppErrorText
      : message;
  }
  return {
    errorMessage,
    defaultErrorMessage: ledgerAppErrorText,
  };
}

export const useLedgerLogin = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const dappProviderInstance = useAppSelector(
    networkStateSelector<HWProvider>('dappProvider'),
  );
  const nativeMarket = useAppSelector(nativeMarketSelector);

  const getInitializedHwWalletProvider = async () => {
    const providerInstance = new HWProvider();
    let isInitialized = providerInstance.isInitialized();

    if (!isInitialized) {
      isInitialized = await providerInstance.init();
    }

    if (!isInitialized) {
      return;
    }

    await providerInstance.setAddressIndex(
      parseInt(localStorage.getItem('ledger__account_index') || '0'),
    );

    return providerInstance;
  };

  const getAccounts = async (startIndex: number, addressesPerPage: number) => {
    const providerInstance = new HWProvider();

    await providerInstance.init();

    const accounts = await providerInstance.getAccounts(
      startIndex,
      addressesPerPage,
    );

    const balances = await Promise.all(
      accounts.map((address) =>
        gatewayService.address.getAddressDetails(address),
      ),
    );

    return accounts.map((address, index) => ({
      address,
      balance: new DefiUtils(balances[index]?.account?.balance || '0')
        .toFullDecimals(nativeMarket.underlying.decimals)
        .toString(),
      index: String(
        index + 1 + (startIndex === 0 ? 0 : startIndex * addressesPerPage) - 1,
      ),
    }));
  };

  const initiateLogin = async () => {
    try {
      const dappProvider = await getInitializedHwWalletProvider();
      if (!dappProvider) {
        return;
      }
      dispatch(setNetworkState('dappProvider', dappProvider));
      const address = await dappProvider.getAddress();
      dispatch(setAccountState('address', address));
    } catch (error) {
      logger.error(error?.toString());
      captureException(error);
      logout();
    }
  };

  const login = () => {
    setTimeout(
      () =>
        dispatch(
          openPopup({
            name: `ledgeraccounts`,
          }),
        ),
      100,
    );
  };

  const loginAccount = async (addressIndex: number = 0) => {
    try {
      dispatch(setLoggingInState('pending', true));
      const providerInstance = new HWProvider();
      const isInitialized = providerInstance.isInitialized();

      if (!isInitialized) {
        await providerInstance.init();
      }

      const address = await providerInstance.login({
        addressIndex,
      });

      localStorage.setItem('ledger__account_index', String(addressIndex));
      dispatch(setLoginInfoState('loginMethod', LoginMethodsEnum.ledger));
      dispatch(setLoginInfoState('expires', getNewLoginExpiresTimestamp()));
      // dispatch(setNetworkState('dappProvider', providerInstance));
      // dispatch(setAccountState('address', address));
      location.reload();
    } catch (error) {
      logger.error(error);
      captureException(error);
      const data = getLedgerErrorCodes(error);
      toast.error(data?.errorMessage || data?.defaultErrorMessage);
      dispatch(closePopup());
    } finally {
      dispatch(setLoggingInState('pending', false));
    }
  };

  const logout = async (params?: Logout) => {
    const provider = params?.dappProvider || dappProviderInstance;

    if (!provider) {
      dispatch(clearAuthStates());
      dispatch(clearDappProvider());
      return;
    }

    try {
      dispatch(setLoggingInState('pending', true));
      await provider.logout();

      dispatch(clearAuthStates());
      dispatch(clearDappProvider());

      if (params?.callbackRoute) {
        if (typeof params?.redirectFn === 'function') {
          params?.redirectFn(params?.callbackRoute);
        } else if (typeof window !== 'undefined') {
          window.location.href = params?.callbackRoute;
        }
      }

      dispatch(setLoggingInState('loggedIn', false));
      localStorage.removeItem('ledger__account_index');
      toast(t('logged-out'), {
        icon: (
          <div className='grid h-[18px] w-[18px] place-content-center rounded bg-[#E45059] text-white'>
            <LogoutIcon height={14} />
          </div>
        ),
        id: 'logged-out',
      });
    } catch (e: any) {
      captureException(e);
      toast.error(t('logout-error-retry'), { duration: 4000 });
      dispatch(setLoggingInState('error', e?.message));
    } finally {
      dispatch(setLoggingInState('pending', false));
    }
  };

  return {
    login,
    loginAccount,
    logout,
    initiateLogin,
    getAccounts,
  };
};
