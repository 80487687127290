import { hatomGatewayAddress } from '@/config/envVars';
import { NetworkType } from '@/types/network';

// Default Elrond network configuration (constants).
// Change if you need, but by default, you shouldn't have to do that.

export const DEFAULT_MIN_GAS_LIMIT = 50_000;

export const DAPP_CONFIG_ENDPOINT: string = '/dapp/config';
export const DAPP_INIT_ROUTE: string = '/dapp/init';

export const NETWORKS = {
  mainnet: 'mainnet',
  testnet: 'testnet',
  devnet: 'devnet',
};

export type chainIdType = 'mainnet' | 'testnet' | 'devnet';

export const chainType: chainIdType =
  (process.env.NEXT_PUBLIC_ELROND_CHAIN as chainIdType) || 'devnet';

export const isMainnet: boolean = chainType === 'mainnet';

export const networkConfig: Record<string, NetworkType> = {
  devnet: {
    id: 'devnet',
    shortId: 'D',
    name: 'Devnet',
    egldLabel: 'dEGLD',
    egldDenomination: '18',
    decimals: '4',
    epochInHours: '2',
    gasPerDataByte: '1500',
    walletConnectDeepLink:
      'https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://maiar.com/',
    walletConnectBridgeAddresses: ['https://bridge.walletconnect.org'],
    walletConnectV2RelayAddresses: ['wss://relay.walletconnect.com'],
    walletAddress: 'https://devnet-wallet.multiversx.com',
    apiAddress: 'https://devnet-api.multiversx.com',
    gatewayAddress: 'https://devnet-gateway.multiversx.com',
    hatomGatewayAddress: hatomGatewayAddress || 'https://dev-gateway.hatom.com',
    blastApiAddress: 'https://devnet-api.multiversx.com',
    explorerAddress: 'https://devnet-explorer.multiversx.com',
    proxy: 'https://devnet-gateway.multiversx.com',
    apiTimeout: '60000',
    maiarExchangeApi: 'https://devnet-graphql.maiar.exchange/graphql',
    ashswapAddress: 'https://devnet.ashswap.io',
    indexAddress: 'https://devnet-index.multiversx.com',
    taoAddress: 'https://taostats.io',
    graphExchangeAddress: 'https://devnet-graph.xexchange.com/graphql',
  },

  testnet: {
    id: 'testnet',
    shortId: 'T',
    name: 'Testnet',
    egldLabel: 'xEGLD',
    egldDenomination: '18',
    decimals: '4',
    epochInHours: '2',
    gasPerDataByte: '1500',
    walletConnectDeepLink:
      'https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://maiar.com/',
    walletConnectBridgeAddresses: ['https://bridge.walletconnect.org'],
    walletConnectV2RelayAddresses: ['wss://relay.walletconnect.com'],
    walletAddress: 'https://testnet-wallet.multiversx.com',
    apiAddress: 'https://testnet-api.multiversx.com',
    gatewayAddress: 'https://testnet-gateway.multiversx.com',
    hatomGatewayAddress:
      hatomGatewayAddress || 'https://testnet-gateway.multiversx.com',
    blastApiAddress: 'https://testnet-api.multiversx.com',
    explorerAddress: 'https://testnet-explorer.multiversx.com',
    proxy: 'https://testnet-gateway.multiversx.com',
    apiTimeout: '60000',
    maiarExchangeApi: 'https://testnet-graphql.maiar.exchange/graphql',
    ashswapAddress: 'https://testnet.ashswap.io',
    indexAddress: 'https://testnet-index.multiversx.com',
    taoAddress: 'https://taostats.io',
    graphExchangeAddress: 'https://testnet-graph.xexchange.com/graphql',
  },

  mainnet: {
    id: 'mainnet',
    shortId: '1',
    name: 'Mainnet',
    egldLabel: 'EGLD',
    egldDenomination: '18',
    decimals: '4',
    epochInHours: '24',
    gasPerDataByte: '1500',
    walletConnectDeepLink:
      'https://maiar.page.link/?apn=com.elrond.maiar.wallet&isi=1519405832&ibi=com.elrond.maiar.wallet&link=https://maiar.com/',
    walletConnectBridgeAddresses: ['https://bridge.walletconnect.org'],
    walletConnectV2RelayAddresses: ['wss://relay.walletconnect.com'],
    walletAddress: 'https://wallet.multiversx.com',
    apiAddress: 'https://api.multiversx.com',
    gatewayAddress: 'https://gateway.multiversx.com',
    hatomGatewayAddress: hatomGatewayAddress || 'https://gateway.hatom.com',
    blastApiAddress: 'https://api.multiversx.com',
    explorerAddress: 'https://explorer.multiversx.com',
    proxy: 'https://gateway.multiversx.com',
    apiTimeout: '60000',
    maiarExchangeApi: 'https://graphql.maiar.exchange/graphql',
    ashswapAddress: 'https://app.ashswap.io',
    indexAddress: 'https://index.multiversx.com',
    taoAddress: 'https://taostats.io',
    graphExchangeAddress: 'https://graph.xexchange.com/graphql',
  },
};
