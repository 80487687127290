import { Trans, useTranslation } from 'next-i18next';

import useMediaQuery from '@/hooks/useMediaQuery';

import Hint from '@/components/Hint';
import InfoIcon from '@/components/Icons/InfoIcon';

import { MARKET_KEY } from '@/store/protocol';

import { PrettyToken } from '@/sections/Liquid/Common/PrettyToken';
import { formatNumber } from '@/utils/helpers';

export const SEGLDLiquidStakingApyPercentage: React.FC<any> = ({
  apy = '0',
  tooltipContent,
  token,
}) => {
  const { t } = useTranslation();
  const md = useMediaQuery('(min-width: 768px)');
  return (
    <div className='-mx-1 flex flex-col rounded-[8px] bg-[#DEE7FF] p-2 text-sm dark:bg-[#5071B2] dark:text-white'>
      <div className='flex items-center gap-3.5 pl-2'>
        <img
          src='https://cdn.app.hatom.com/images/liquid-staking-apy.svg'
          alt=''
          className='h-5 w-5 scale-105'
        />
        <div className='flex items-center gap-1'>
          <span className='capitalize'>
            {t('liquid-staking-apy', 'Liquid Staking APY')}
          </span>
          <Hint
            autoReplace
            delayEnter={0}
            placement={md ? 'top-center' : 'left-start'}
            content={
              tooltipContent ?? (
                <div className='max-w-[398px] text-[10px] leading-tight'>
                  <Trans
                    i18nKey='tooltip-nh3nj'
                    components={[
                      <span className='text-[#1679F9]' />,
                      <PrettyToken token={token} />,
                      <PrettyToken
                        token={
                          token === MARKET_KEY.sEGLD
                            ? MARKET_KEY.EGLD
                            : MARKET_KEY.WTAO
                        }
                      />,
                    ]}
                    ns='translation'
                  />
                </div>
              )
            }
          >
            <InfoIcon className='text-[#5C709F]' strokeWidth={1.3} width={12} />
          </Hint>
        </div>
        <span className='ml-auto'>{formatNumber(apy, 3)}%</span>
      </div>
    </div>
  );
};
