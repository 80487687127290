import { WalletTypes } from "@/components/popups/ConnectBridgeWalletPopup/components/types";

import clsxm from "@/services/clsxm";

type Props = { isSource: boolean, type: WalletTypes, className?: string }

const CircleLabel = ({ type, isSource }: Props) => (
  <div className={clsxm(
    "mb-[6px] lg:mb-[8px] w-[24.8px] lg:w-[35px] h-[24.8px] lg:h-[35px] translate-x-[0.6px] lg:translate-x-0",
    "flex justify-center items-center rounded-full",
    type === "MULTIVERSE_X" && "bg-[#1B1B1B]",
    type === "FINNEY" && "bg-white",
  )}>
    <div className="w-[35px] h-[35px] bg-zinc-900 rounded-[58.33px] justify-center items-center gap-[14.58px] flex">
      <div className={clsxm(
        "text-[12.5px] lg:text-[17.5px] font-bold",
        type === "MULTIVERSE_X" && "text-white ",
        type === "FINNEY" && "text-black",
      )}>{isSource ? "1" : "2"}</div>
    </div>
  </div>
)

const ConnectBridgeWalletStepHeader = ({ isSource, type, className }: Props) => (
  <div className={clsxm('flex flex-col items-center', className)}>
    <CircleLabel {...{ isSource, type, className }} />

    <p className={clsxm(
      'text-[16px] lg:text-[20px] leading-[20px] lg:leading-[25px] translate-x-[0.6px] lg:translate-x-0 text-center font-[600]',
      type === "MULTIVERSE_X" && "text-[#1B1B1B]",
      type === "FINNEY" && "text-white ",
      isSource ? "w-[171px] lg:w-[216px]" : "w-[201px] lg:w-[246px]"
    )} >
      {isSource ? "Connect Your Source Wallet" : "Connect Your Destination Wallet"}
    </p>
  </div>
)


export default ConnectBridgeWalletStepHeader;