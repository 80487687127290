import classNames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';

export const Right = () => {
  const { t } = useTranslation('governance');

  return (
    <div
      className={classNames(
        'py-[36.5px]',
        'px-[18px]',
        'border-[2px]',
        'border-t-0',
        'xl:border-t-[2px]',
        'xl:border-l-0',
        'border-[#E7E7F9]',
        'dark:border-[#2E2C51]',
        'rounded-br-[20px]',
        'rounded-bl-[20px]',
        'lg:rounded-tr-[20px]',
        'lg:rounded-bl-[0px]'
      )}
    >
      <h4 className={classNames('mb-5', 'text-lg', 'leading-[23px]')}>
        {t(
          'participate-in-hatom-governance',
          'Participate in Hatom Governance'
        )}
      </h4>
      <p
        className={classNames(
          'text-xs',
          'leading-[125%]',
          'text-[#77778B]',
          'mb-5',
          'dark:text-[#625E8F]'
        )}
      >
        <Trans
          i18nKey='paragraph-m98Ru'
          defaults='Cast your vote now by leveraging your <0>HTM</0> tokens to influence the future of critical DeFi protocols on the MultiversX Blockchain.'
          ns='governance'
          components={[<span className={classNames('text-[#38a98a]')} />]}
        />
      </p>
      <button
        className={classNames(
          'flex items-center gap-1',
          'cursor-pointer',
          'text-[#006FFF]',
          'text-xs',
          'leading-[125%]',
          'block',
          'hover:opacity-80'
        )}
      >
        {t('translation:get-started')}
      </button>
    </div>
  );
};
