import React from 'react';

import clsxm from '@/services/clsxm';

type Props = {
  label: string;
  small?: boolean;
  className?: string;
  onClick?: () => void;
};

const ClaimButton = (props: Props) => {
  return (
    <button
      onClick={props.onClick}
      className={clsxm(
        'rounded-[8px] bg-[#4AAD7C] text-white cursor-pointer',
        props.small
          ? 'text-[10px] lg:text-[12px] px-[8px] py-px md:px-[12.5px] md:py-[1.5px]'
          : 'text-[10px] lg:text-[14px] px-[8px] py-px md:px-[12.5px] md:py-[3px]',
        props.className,
      )}
    >
      {props.label}
    </button>
  );
};

export default ClaimButton;
