import classNames from 'classnames';

import useTheme from '@/hooks/useTheme';

import { formatNumber } from '@/utils/helpers';

interface HTMStakedProps {
  balance: string;
  nextBalance?: string;
}

const StakeItem = ({ balance }: { balance: string }) => {
  return (
    <div
      className={classNames(
        'inline-flex',
        'items-center',
        'bg-[#D5D2FB]',
        'dark:bg-[#6B66AF]',
        'rounded-[8px]',
        'h-[22px]',
        'px-[4px]',
        'font-semibold',
        'gap-[4px]',
      )}
    >
      <span>{formatNumber(balance)}</span>

      <img
        className={classNames('block w-[14px] h-[14px]')}
        alt='Hatom'
        src='https://cdn.app.hatom.com/images/tokens/HTM.svg'
      ></img>
    </div>
  );
};

const HTMStaked = ({ balance, nextBalance }: HTMStakedProps) => {
  const { isDark } = useTheme();
  return (
    <div
      className={classNames(
        'flex',
        'items-center',
        'justify-between',
        'text-[14px]',
        'leading-[14px]',
        'bg-[#E6E4FF]',
        'dark:bg-[#444082]',
        'h-[38px]',
        'px-[8px]',
        'rounded-[8px]',
        'mb-[22px]',
      )}
    >
      <span>HTM Staked</span>

      <div className={classNames('flex', 'items-center', 'gap-[4px]')}>
        <StakeItem balance={balance} />

        {nextBalance && (
          <>
            <svg
              width='12'
              height='8'
              viewBox='0 0 12 8'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M10.8 5H1.2C0.88174 5 0.576516 4.89464 0.351472 4.70711C0.126428 4.51957 0 4.26522 0 4C0 3.73478 0.126428 3.48043 0.351472 3.29289C0.576516 3.10536 0.88174 3 1.2 3H10.8C11.1183 3 11.4235 3.10536 11.6485 3.29289C11.8736 3.48043 12 3.73478 12 4C12 4.26522 11.8736 4.51957 11.6485 4.70711C11.4235 4.89464 11.1183 5 10.8 5Z'
                fill={isDark ? 'white' : '#6A6A8C'}
              />
              <path
                d='M7.99799 8.00019C7.86638 8.00095 7.73592 7.97572 7.61408 7.92596C7.49224 7.87619 7.38143 7.80287 7.28799 7.71019C7.19426 7.61722 7.11986 7.50662 7.06909 7.38476C7.01833 7.2629 6.99219 7.1322 6.99219 7.00019C6.99219 6.86817 7.01833 6.73747 7.06909 6.61561C7.11986 6.49375 7.19426 6.38315 7.28799 6.29019L9.58799 4.00019L7.28799 1.71019C7.19475 1.61695 7.12079 1.50626 7.07033 1.38443C7.01987 1.26261 6.99389 1.13204 6.99389 1.00019C6.99389 0.733884 7.09968 0.478489 7.28799 0.290185C7.47629 0.101882 7.73168 -0.00390625 7.99799 -0.00390625C8.12985 -0.00390625 8.26041 0.0220653 8.38224 0.0725257C8.50406 0.122986 8.61475 0.196947 8.70799 0.290185L11.708 3.29019C11.8017 3.38315 11.8761 3.49375 11.9269 3.61561C11.9776 3.73747 12.0038 3.86817 12.0038 4.00019C12.0038 4.1322 11.9776 4.2629 11.9269 4.38476C11.8761 4.50662 11.8017 4.61722 11.708 4.71019L8.70799 7.71019C8.61455 7.80287 8.50373 7.87619 8.38189 7.92596C8.26005 7.97572 8.12959 8.00095 7.99799 8.00019Z'
                fill={isDark ? 'white' : '#6A6A8C'}
              />
            </svg>

            <StakeItem balance={nextBalance} />
          </>
        )}
      </div>
    </div>
  );
};

export default HTMStaked;
