import Ledger from './components/Ledger';

const WalletLogos = {
  maiarDefiWallet: (
    <svg
      width={33}
      height={32}
      viewBox='0 0 33 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.0329218 8.16488C0.0329218 8.05431 0.0260817 7.94657 0.0179277 7.8363C-0.0481708 6.94241 -0.146066 2.66553 4.03292 2.66553H8.69959H28.5C29.3227 4.24274 28.1908 6.13287 26.412 6.15216L7.36752 6.35866C6.85581 6.36421 6.4741 6.83188 6.57118 7.33433C6.64573 7.72014 6.98346 7.99886 7.37641 7.99886H29.3663C30.839 7.99886 32.0329 9.19277 32.0329 10.6655V26.6655C32.0329 28.1383 30.839 29.3322 29.3663 29.3322H2.69959C1.22683 29.3322 0.0329218 28.1383 0.0329218 26.6655V8.16488Z'
        fill='black'
      />
      <path
        d='M8.99988 22.0071L14.0732 19.2866L9.00505 16.5661C9.26678 16.0588 9.50574 15.5691 9.77853 15.0956C9.81455 15.0338 10.0403 15.0287 10.1462 15.0713C11.5586 15.6286 12.9622 16.2073 14.3762 16.7624C14.5665 16.8297 14.7741 16.8297 14.9644 16.7624C16.3901 16.2066 17.8084 15.6308 19.229 15.0537C19.3995 14.9838 19.5231 14.9522 19.6194 15.1507C19.8473 15.6095 20.0863 16.0632 20.3458 16.5661L15.2762 19.2866L20.3495 22.0071C20.0877 22.5107 19.8466 23.0012 19.5716 23.471C19.5356 23.532 19.3069 23.5313 19.1951 23.4879C18.1459 23.0784 17.1026 22.6527 16.0577 22.2328C15.9638 22.2051 15.8725 22.1692 15.785 22.1255C15.0379 21.6512 14.3291 21.7394 13.5446 22.1145C12.4645 22.6291 11.3248 23.0225 10.2168 23.4835C9.93663 23.5997 9.77559 23.5681 9.64692 23.2732C9.45281 22.8519 9.22487 22.4483 8.99988 22.0071Z'
        fill='#22F6DD'
      />
      <rect
        x='25.3656'
        y='17.3325'
        width='2.66667'
        height={4}
        rx='1.33333'
        fill='#FBFBFC'
      />
    </svg>
  ),
  maiarApp: (
    <svg
      width={24}
      height={32}
      viewBox='0 0 24 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.0472851 2.96271C0.0212932 1.33389 1.33455 -0.000488281 2.96358 -0.000488281H20.6189C22.2116 -0.000488281 23.5098 1.27716 23.5352 2.86964L23.9527 29.0363C23.9787 30.6651 22.6655 31.9995 21.0364 31.9995H3.38113C1.78844 31.9995 0.49025 30.7219 0.464838 29.1294L0.0472851 2.96271Z'
        fill='black'
      />
      <rect
        x='10.7083'
        y='24.2075'
        width='2.91667'
        height='4.375'
        rx='1.45833'
        fill='#FBFBFC'
      />
      <path
        d='M6 17.0066L11.0734 14.2861L6.00517 11.5656C6.2669 11.0583 6.50586 10.5686 6.77866 10.0951C6.81467 10.0333 7.04043 10.0282 7.14628 10.0708C8.55874 10.6282 9.96236 11.2068 11.3763 11.7619C11.5666 11.8292 11.7742 11.8292 11.9645 11.7619C13.3902 11.2061 14.8085 10.6303 16.2291 10.0532C16.3996 9.98333 16.5232 9.95168 16.6195 10.1502C16.8475 10.609 17.0864 11.0627 17.3459 11.5656L12.2763 14.2861L17.3496 17.0066C17.0879 17.5103 16.8467 18.0007 16.5717 18.4705C16.5357 18.5316 16.307 18.5308 16.1952 18.4874C15.146 18.0779 14.1027 17.6522 13.0578 17.2323C12.964 17.2046 12.8727 17.1687 12.7851 17.125C12.038 16.6507 11.3292 16.739 10.5447 17.114C9.46461 17.6286 8.32495 18.022 7.2169 18.483C6.93675 18.5992 6.77571 18.5676 6.64704 18.2727C6.45294 17.8514 6.22499 17.4478 6 17.0066Z'
        fill='#22F6DD'
      />
    </svg>
  ),
  ledger: <Ledger fill='black' />,
  elrondWebWallet: (
    <svg
      width={32}
      height={33}
      viewBox='0 0 32 33'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.6314 0.917969H3.36823C2.01526 0.917969 0.918457 2.01477 0.918457 3.36774V21.6309C0.918457 22.9839 2.01526 24.0807 3.36823 24.0807H28.6314C29.9844 24.0807 31.0812 22.9839 31.0812 21.6309V3.36774C31.0812 2.01477 29.9844 0.917969 28.6314 0.917969Z'
        fill='black'
        stroke='black'
        strokeWidth='1.83732'
      />
      <path d='M19 25H13V29H19V25Z' fill='black' />
      <path
        d='M26.0813 29H5.91866C5.4113 29 5 29.4113 5 29.9187V31.0813C5 31.5887 5.4113 32 5.91866 32H26.0813C26.5887 32 27 31.5887 27 31.0813V29.9187C27 29.4113 26.5887 29 26.0813 29Z'
        fill='black'
      />
      <path
        d='M10.3198 15.0071L15.3932 12.2866L10.325 9.56612C10.5867 9.05876 10.8257 8.56909 11.0985 8.09557C11.1345 8.0338 11.3602 8.02867 11.4661 8.0713C12.8785 8.62864 14.2822 9.20729 15.6961 9.76241C15.8864 9.82969 16.094 9.82969 16.2843 9.76241C17.71 9.20657 19.1284 8.63082 20.5489 8.05366C20.7195 7.98382 20.843 7.95216 20.9393 8.15072C21.1673 8.6095 21.4062 9.06316 21.6657 9.56612L16.5961 12.2866L21.6695 15.0071C21.4077 15.5107 21.1665 16.0012 20.8915 16.471C20.8555 16.532 20.6268 16.5313 20.5151 16.4879C19.4658 16.0784 18.4225 15.6527 17.3777 15.2328C17.2838 15.2051 17.1925 15.1692 17.1049 15.1255C16.3579 14.6512 15.649 14.7394 14.8645 15.1145C13.7844 15.6291 12.6448 16.0225 11.5367 16.4835C11.2566 16.5997 11.0955 16.5681 10.9669 16.2732C10.7727 15.8519 10.5448 15.4483 10.3198 15.0071Z'
        fill='#23F7DD'
      />
    </svg>
  ),
};

export const OtherLogos = {
  leftArrow: (
    <svg
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M15.8735 7.46421H1.84271C1.37756 7.46421 0.931467 7.31023 0.602557 7.03614C0.273647 6.76205 0.0888672 6.3903 0.0888672 6.00268C0.0888672 5.61505 0.273647 5.2433 0.602557 4.96921C0.931467 4.69512 1.37756 4.54114 1.84271 4.54114H15.8735C16.3386 4.54114 16.7847 4.69512 17.1136 4.96921C17.4425 5.2433 17.6273 5.61505 17.6273 6.00268C17.6273 6.3903 17.4425 6.76205 17.1136 7.03614C16.7847 7.31023 16.3386 7.46421 15.8735 7.46421Z' />
      <path d='M12.8771 11.118C12.7088 11.119 12.5419 11.0867 12.3861 11.0231C12.2303 10.9594 12.0886 10.8657 11.9691 10.7471C11.8492 10.6282 11.7541 10.4868 11.6892 10.331C11.6242 10.1751 11.5908 10.008 11.5908 9.83914C11.5908 9.67032 11.6242 9.50317 11.6892 9.34733C11.7541 9.19149 11.8492 9.05005 11.9691 8.93116L14.9104 6.00261L11.9691 3.07405C11.8499 2.95481 11.7553 2.81325 11.6907 2.65746C11.6262 2.50167 11.593 2.33469 11.593 2.16607C11.593 1.82551 11.7283 1.4989 11.9691 1.25809C12.2099 1.01727 12.5365 0.881989 12.8771 0.881989C13.0457 0.881989 13.2127 0.915202 13.3685 0.979733C13.5243 1.04426 13.6658 1.13885 13.7851 1.25809L17.6216 5.09462C17.7415 5.21351 17.8366 5.35495 17.9015 5.51079C17.9665 5.66663 17.9999 5.83378 17.9999 6.00261C17.9999 6.17143 17.9665 6.33858 17.9015 6.49442C17.8366 6.65026 17.7415 6.7917 17.6216 6.91059L13.7851 10.7471C13.6656 10.8657 13.5239 10.9594 13.368 11.0231C13.2122 11.0867 13.0454 11.119 12.8771 11.118Z' />
    </svg>
  ),
  closedLock: (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='14'
      fill='none'
      viewBox='0 0 11 14'
    >
      <g clipPath='url(#clip0_821_2519)'>
        <path
          fill='currentColor'
          d='M9.865 5.13v-.77a4.37 4.37 0 00-8.73 0v.77a1.62 1.62 0 00-1.09 1.51v5.72A1.72 1.72 0 001.865 14h7.27a1.72 1.72 0 001.82-1.64V6.64a1.63 1.63 0 00-1.09-1.51zm-4.37-2.95a2.19 2.19 0 012.19 2.18V5h-4.37v-.64a2.19 2.19 0 012.18-2.18z'
        ></path>
      </g>
      <defs>
        <clipPath id='clip0_821_2519'>
          <path
            fill='#fff'
            d='M0 0H10.91V14H0z'
            transform='translate(.045)'
          ></path>
        </clipPath>
      </defs>
    </svg>
  ),
};

export default WalletLogos;
