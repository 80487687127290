import classNames from 'classnames';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import React from 'react';
const statusType = ['fail', undefined, 'success'] as const;

const StatusAnimation: React.FC<{
  status: (typeof statusType)[number];
  className?: string;
}> = ({ status, className }) => {
  const i = statusType.indexOf(status) ?? 1;

  const particalsProps = {
    initial: { scale: 0 },
    animate: { scale: 1, transition: { delay: 0.5 } },
  };

  const getMainIconAnimationProps = ({ offset = [-30, 0] }) => {
    const [start, end] = offset;
    return {
      initial: {
        strokeDasharray: 50,
        strokeDashoffset: start,
      },
      animate: {
        strokeDashoffset: end,
      },
      transition: {
        delay: 0.6,
        type: 'spring',
        bounce: 0,
        duration: 0.5,
      },
    };
  };

  return (
    <>
      <LazyMotion features={domAnimation}>
        <m.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          className={classNames(
            className,
            'relative grid aspect-square h-12 w-12 place-content-center rounded-full',
            ['text-red', 'text-[#2A3E6C]', 'text-green'][i]
          )}
          style={{
            backgroundImage: [
              'linear-gradient(95.32deg, #C13A64 -4.82%, #8C1F41 100.9%)',
              'linear-gradient(#D3DBEE, #D3DBEE)',
              'linear-gradient(146.74deg, #46A37F 2.83%, #3E8788 89.2%)',
            ][i],
          }}
        >
          {/* Outer arc */}
          <div className='absolute-xy-center'>
            <m.div
              initial={{ scale: 0, rotate: -180 }}
              animate={{ scale: 1, rotate: 0 }}
              transition={{
                delay: 0.3,
              }}
            >
              <svg
                width={57}
                height={57}
                viewBox='0 0 57 57'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M5.67795 12.5C2.72243 16.9381 1 22.2679 1 28C1 40.8662 9.67792 51.7055 21.5 54.9842'
                  stroke='currentColor'
                  strokeLinecap='round'
                />
                <path
                  d='M29 56.0003C27.4682 56.0003 25.965 55.8773 24.5 55.6406'
                  stroke='currentColor'
                  strokeLinecap='round'
                />
              </svg>
            </m.div>
          </div>
          {/* Inner arc arc */}
          <div className='absolute-xy-center'>
            <m.div
              initial={{ scale: 0, rotate: 180 }}
              animate={{ scale: 1, rotate: 0 }}
              transition={{
                delay: 0.3,
              }}
            >
              <svg
                width={49}
                height={49}
                viewBox='0 0 49 49'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M5.41394 28.5036C5.51639 28.9894 5.63804 29.475 5.77935 29.9595C7.25666 35.0249 10.6087 39.0336 14.8598 41.4506'
                  stroke='#2A3E6C'
                  strokeLinecap='round'
                />
                <path
                  d='M19.041 5.7793C29.3798 2.764 40.2055 8.70089 43.2208 19.0397C43.4579 19.8529 43.6397 20.669 43.7683 21.4842'
                  stroke='#2A3E6C'
                  strokeLinecap='round'
                />
                <circle
                  cx='43.7928'
                  cy='23.1443'
                  r='0.6'
                  transform='rotate(-16.2592 43.7928 23.1443)'
                  fill='#2A3E6C'
                />
              </svg>
            </m.div>
          </div>

          {
            [
              <svg
                width={23}
                height={22}
                viewBox='0 0 23 22'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <m.path
                  {...getMainIconAnimationProps({ offset: [-30, 0] })}
                  d='M3.48047 19.1006L19.6816 2.89941'
                  stroke='white'
                  strokeWidth='5.40039'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <m.path
                  {...getMainIconAnimationProps({ offset: [250, 210] })}
                  d='M3.48047 2.89941L19.0005 18.4194'
                  stroke='white'
                  strokeWidth='5.40039'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <m.path
                  {...particalsProps}
                  d='M7.92773 11.3887L3.44466 7.11127'
                  stroke='#2A3E6C'
                  strokeLinecap='round'
                />
                <m.path
                  {...particalsProps}
                  d='M15.0801 10.6719L22.036 4.1015'
                  stroke='#2A3E6C'
                  strokeLinecap='round'
                />
              </svg>,
              <svg
                width={20}
                height={24}
                viewBox='0 0 20 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <m.path
                  {...getMainIconAnimationProps({ offset: [-30, 0] })}
                  d='M2 20.4775L18 4.47754'
                  stroke='#2A3E6C'
                  strokeWidth='3.71745'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <m.path
                  {...getMainIconAnimationProps({ offset: [250, 210] })}
                  d='M2 4.47754L18 20.4775'
                  stroke='#2A3E6C'
                  strokeWidth='3.71745'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <m.path
                  {...particalsProps}
                  d='M16.4707 23.3125L10.1417 16.7804'
                  stroke='white'
                  strokeLinecap='round'
                />
                <m.path
                  {...particalsProps}
                  d='M10.7324 8.96582L4.40345 2.43373'
                  stroke='white'
                  strokeLinecap='round'
                />
                <m.path
                  {...particalsProps}
                  d='M11.4355 4.37793L14.9913 1.14271'
                  stroke='#2A3E6C'
                  strokeLinecap='round'
                />
              </svg>,
              <svg
                className='mr-0.5 mt-1.5'
                width={30}
                height={29}
                viewBox='0 0 30 29'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <m.path
                  {...getMainIconAnimationProps({ offset: [250, 210] })}
                  d='M3.0957 13L12.0245 22.3008L26.9057 3.69922'
                  stroke='white'
                  strokeWidth='5.51157'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <m.path
                  {...particalsProps}
                  d='M10.2344 16.5L5.97466 12.0001'
                  stroke='#2A3E6C'
                  strokeLinecap='round'
                />
                <m.path
                  {...particalsProps}
                  d='M14.5 23.3926L22.4354 14.0891'
                  stroke='#2A3E6C'
                  strokeLinecap='round'
                />
              </svg>,
            ][i]
          }
        </m.div>
      </LazyMotion>
    </>
  );
};

export default StatusAnimation;
