import classNames from 'classnames';
import { FC, useMemo } from 'react';

import useMediaQuery from '@/hooks/useMediaQuery';

import { spreadAvailable } from '@/utils/helpers';

interface TableHeaderProps {
  className?: string;
  headers: Array<string>;
  themeless?: boolean;
  preset: Array<number>;
}

const TableHeader: FC<TableHeaderProps> = ({
  className,
  headers,
  themeless = false,
  preset = [25],
}: TableHeaderProps) => {
  preset = spreadAvailable(preset, headers.length);
  const mqMatch = useMediaQuery('(max-width: 1024px)');

  const headersFiltered = useMemo(() => {
    return headers.filter((_, index) => !(mqMatch && index === 1));
  }, [headers, mqMatch]);

  return (
    <div
      className={classNames(
        className,
        'flex px-3 pt-[15.5px] pb-[16.5px] text-[12px] font-semibold leading-none text-opacity-95 sm:px-4',
        !themeless && 'text-[#6A6A8C] dark:text-[#4C4973]'
      )}
    >
      {headersFiltered.map((e, i) => (
        <div
          key={e}
          style={{ flexBasis: `${preset[i]}%` }}
          className={`${i !== 0 && 'ml-auto text-right'}`}
        >
          {e}
        </div>
      ))}
    </div>
  );
};

export default TableHeader;
