import DoubleArrowsRight from '@/components/popups/ConnectBridgeWalletPopup/components/DoubleArrows';
import { WalletTypes } from '@/components/popups/ConnectBridgeWalletPopup/components/types';

import clsxm from '@/services/clsxm';

const GoBackBtn = ({
  onClick,
  type,
}: {
  onClick: () => void;
  type: WalletTypes;
}) => {
  return (
    <button
      onClick={onClick}
      className='absolute z-[9] left-[11.8px] top-[12.4px] lg:top-[19px] lg:left-[15.6px] flex items-center'
    >
      <DoubleArrowsRight
        stroke={type === 'FINNEY' ? 'white' : 'black'}
        className='w-[9px] lg:w-[13px] h-auto mr-[2.5px] lg:mr-[3.5px] transform rotate-[180deg]'
      />

      <span
        className={clsxm(
          'text-[10px] lg:text-[14px] font-[600] text-white translate-y-[-0.4px] lg:translate-y-0',
          type === 'FINNEY' ? 'text-white' : 'text-black',
        )}
      >
        Go Back
      </span>
    </button>
  );
};

export default GoBackBtn;
