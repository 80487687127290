import classNames from 'classnames';

import ParticipateHatom from '@/components/Icons/ParticipateHatom';

export const Left = () => {
  return (
    <div
      className={classNames(
        'border-[2px] bg-[#001D44]',
        'border-[#234a7f]',
        'rounded-tr-[20px]',
        'rounded-tl-[20px]',
        'xl:rounded-tr-[0px]',
        'xl:rounded-bl-[20px]',
        'overflow-hidden'
      )}
    >
      {/* <img
        src="https://cdn.app.hatom.com/images/governance/participate-in-hatom.svg"
        alt="Participate in hatom"
        className={classNames('w-full object-fill', 'h-full', 'block')}
      /> */}
      <ParticipateHatom />
    </div>
  );
};
