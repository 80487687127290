import { createContext, ReactElement, useEffect, useState } from 'react';

import useIsomorphicLayoutEffect from '@/hooks/useIsomorphicLayoutEffect';

const getInitialTheme = () => {
  if (typeof localStorage !== 'undefined' && window.localStorage) {
    const storedPrefs = window.localStorage.getItem('color-theme');
    if (typeof storedPrefs === 'string') {
      return storedPrefs;
    }
  }

  return 'light';
};

export type ThemeMode = 'light' | 'dark';

export const ThemeContext = createContext<{
  theme: ThemeMode;
  setTheme: (mode: ThemeMode) => void;
}>({
  theme: 'light',
  setTheme: (_: ThemeMode) => {},
});

export const ThemeProvider: React.FC<{
  initialTheme?: ThemeMode;
  children: ReactElement;
}> = ({ initialTheme, children }) => {
  const [theme, setTheme] = useState<ThemeMode>('light');

  useIsomorphicLayoutEffect(() => {
    setTheme((_) => getInitialTheme() as ThemeMode);
  });

  const rawSetTheme = (rawTheme: ThemeMode) => {
    const root = window.document.documentElement;
    const isDark = rawTheme === 'dark';

    root.classList.remove(isDark ? 'light' : 'dark');
    root.classList.add(rawTheme);

    localStorage.setItem('color-theme', rawTheme);
  };

  if (initialTheme) {
    rawSetTheme(initialTheme);
  }

  useEffect(() => {
    rawSetTheme(theme);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
