import classNames from 'classnames';
import {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    format,
} from 'date-fns';
import DefiUtils from 'defi-utils';
import { AnimatePresence, domAnimation, LazyMotion, m } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { useToggle } from 'usehooks-ts';

import {
    governanceSelector,
    PROPOSAL_STATUS,
    PROPOSAL_TAG,
    VOTE_TYPE,
} from '@/store/governance';
import { useAppDispatch, useAppSelector } from '@/store/index';
import { openPopup } from '@/store/popup';

import { formatNumber } from '@/utils/helpers';

const PROPOSAL_TAG_IMAGES = {
  [PROPOSAL_TAG.liquidStaking]:
    'https://cdn.app.hatom.com/images/governance/proposal/tags/liquid-staking.svg',
  [PROPOSAL_TAG.lendingProtocol]:
    'https://cdn.app.hatom.com/images/governance/proposal/tags/lending-protocol.svg',
  [PROPOSAL_TAG.ushStaking]: 'https://cdn.app.hatom.com/images/governance/proposal/tags/ush-staking.svg',
  [PROPOSAL_TAG.isolatedPools]:
    'https://cdn.app.hatom.com/images/governance/proposal/tags/isolated-pools.svg',
};

export enum VOTE_ACTION {
  up = 'up',
  down = 'down',
}

export interface ProposalItemProps {
  id: string;
  tag: PROPOSAL_TAG;
  title: string;
  status: PROPOSAL_STATUS;
  voteEndTimestamp: string;
  finishTimestamp: string;
  upvotes: string;
  percentageUpVotes: string;
  downvotes: string;
  percentageDownVotes: string;
  description: string;
}

const statusNamesMap: Record<string, string> = {
  [PROPOSAL_STATUS.active]: 'active',
  [PROPOSAL_STATUS.succeeded]: 'passed',
  [PROPOSAL_STATUS.executed]: 'executed',
  [PROPOSAL_STATUS.defeated]: 'failed',
  [PROPOSAL_STATUS.snapshot]: 'snapshot',
};

const ProposalItem = ({
  id,
  tag,
  title,
  status,
  voteEndTimestamp,
  finishTimestamp,
  upvotes,
  percentageUpVotes,
  downvotes,
  percentageDownVotes,
  description,
}: ProposalItemProps) => {
  const [isOpen, toggleIsOpen] = useToggle(false);
  const dispatch = useAppDispatch();
  const { votes } = useAppSelector(governanceSelector);
  const { t } = useTranslation('governance');

  const statusClasses = useMemo(() => {
    switch (status) {
      case PROPOSAL_STATUS.active: {
        return {
          button: [
            'border-[#006FFF]',
            'text-[#006FFF]',
            'bg-[#F3F8FF]',
            'dark:bg-[#142445]',
          ],
          icon: ['bg-[#006FFF]', 'shadow-[0px_0px_5px_#006FFF] animate-pulse'],
        };
      }

      case PROPOSAL_STATUS.succeeded:
      case PROPOSAL_STATUS.executed: {
        return {
          button: [
            'border-[#2FBE81]',
            'text-[#2FBE81]',
            'bg-[#F3FFF9]',
            'dark:bg-[#182937]',
          ],
          icon: ['bg-[#2FBE81]', 'shadow-[0px_0px_5px_#2FBE81]'],
        };
      }

      case PROPOSAL_STATUS.defeated: {
        return {
          button: [
            'border-[#E45059]',
            'text-[#E45059]',
            'bg-[#FFF3F3]',
            'dark:bg-[#291328]',
          ],
          icon: ['bg-[#E45059]', 'shadow-[0px_0px_5px_#E45059]'],
        };
      }

      case PROPOSAL_STATUS.snapshot: {
        return {
          button: [
            'border-[#F0B90B]',
            'text-[#F0B90B]',
            'bg-[#FFFCE5]',
            'dark:bg-[#FDF4A8]',
          ],
          icon: ['bg-[#F0B90B]', 'shadow-[0px_0px_5px_#F0B90B]'],
        };
      }

      default: {
        return {
          button: [],
          icon: [],
        };
      }
    }
  }, [status]);

  const voteEndTime = useMemo(() => {
    const voteEndDate = new Date(voteEndTimestamp);
    const now = new Date();

    const diffDays = differenceInDays(voteEndDate, now);
    const diffHours = differenceInHours(voteEndDate, now) % 24;
    const diffMinutes = differenceInMinutes(voteEndDate, now) % 60;

    return t('until-voting', '{{duration}} until voting', {
      duration: [
        ...(diffDays === 0 ? [] : [`${diffDays} ${t('days', 'days')}`]),
        ...(diffHours === 0 ? [] : [`${diffHours} ${t('hrs', 'hrs')}`]),
        ...(diffMinutes === 0 ? [] : [`${diffMinutes} ${t('mins', 'mins')}`]),
      ].join(', '),
    });
  }, [t, voteEndTimestamp]);

  const finishTime = useMemo(() => {
    return `${
      status === PROPOSAL_STATUS.executed
        ? t('executed', 'Executed')
        : t('finished', 'Finished')
    } ${format(new Date(finishTimestamp), 'MMMM do, yyyy')}`;
  }, [finishTimestamp, status, t]);

  const votePercentages = useMemo(() => {
    return [
      {
        action: VOTE_ACTION.up,
        value: upvotes,
        percentage: percentageUpVotes,
        hasVotes: !new DefiUtils(percentageUpVotes).isZero(),
      },
      {
        action: VOTE_ACTION.down,
        value: downvotes,
        percentage: percentageDownVotes,
        hasVotes: !new DefiUtils(percentageDownVotes).isZero(),
      },
    ];
  }, [upvotes, downvotes, percentageUpVotes, percentageDownVotes]);

  const handleVote = () => {
    if (status !== PROPOSAL_STATUS.active) {
      return;
    }

    toggleIsOpen();

    const forCount = votes
      .filter(
        (voteItem) =>
          voteItem.proposalId === id && voteItem.voteType === VOTE_TYPE.For,
      )
      .reduce((prev, current) => prev.plus(current.amount), new DefiUtils(0))
      .toString();

    const againstCount = votes
      .filter(
        (voteItem) =>
          voteItem.proposalId === id && voteItem.voteType === VOTE_TYPE.Against,
      )
      .reduce((prev, current) => prev.plus(current.amount), new DefiUtils(0))
      .toString();

    dispatch(
      openPopup({
        name: 'votev2',
        data: {
          proposalId: id,
          title,
          tag,
          forCount,
          againstCount,
        },
      }),
    );
  };

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
        className={classNames(
          'bg-[#ffffff]',
          'pt-[18px]',
          'pb-[11.5px]',
          'px-[22px]',
          'shadow-[0px_6px_0px_#D3CFE2,9px_16px_14px_#DEDEEC]',
          'border-[2px]',
          'border-[#E7E7F9]',
          'rounded-[20px]',
          'font-semibold',
          'cursor-pointer',
          'select-none',
          'dark:bg-[#171430]',
          'dark:border-[#2E2C51]',
          'dark:shadow-[0px_6px_0px_#171430,9px_16px_14px_#000000]',
        )}
        onClick={toggleIsOpen}
      >
        <div
          className={classNames(
            'relative',
            'grid',
            'grid-cols-1',
            'gap-x-3.5',
            // isOpen &&'gap-y-[13.5px]',
            'xl:grid-cols-[1fr,195px]',
            'pb-[13.5px]',
          )}
        >
          {/* left */}
          {/* <div className={classNames('')}> */}
          <div className={classNames('flex', 'gap-2', 'items-center', 'mb-4')}>
            <img
              className={classNames('h-[20px] w-[20px]')}
              src={PROPOSAL_TAG_IMAGES[tag] || ''}
              alt={tag}
            />

            <h3
              className={classNames(
                'text-[10px] sm:text-base md:text-lg',
                'leading-[23px]',
              )}
            >
              {title}
            </h3>

            <div
              onClick={() => handleVote()}
              className={classNames(
                'h-[19px]',
                'px-4',
                'rounded-[10px]',
                'text-xs',
                'flex',
                'items-center',
                'text-white',
                'dark:text-[#171430]',
                status === PROPOSAL_STATUS.active
                  ? 'bg-[#2FBE81]'
                  : 'bg-[#A682F2]',
              )}
            >
              {status === PROPOSAL_STATUS.active
                ? t('vote', 'Vote')
                : status === PROPOSAL_STATUS.snapshot
                ? t('stake', 'Stake')
                : t('review', 'Review')}
            </div>
          </div>

          <div
            className={classNames(
              'flex',
              'gap-4',
              'text-xs',
              'leading-[15px]',
              'items-center',
              'order-3 mb-1 md:mb-0',
            )}
          >
            <div
              className={classNames(
                'capitalize',
                'border-[1px]',
                'h-[23px]',
                'rounded-md',
                'flex',
                'items-center',
                'px-4',
                'gap-[7px]',
                statusClasses.button,
              )}
            >
              <div
                className={classNames(
                  'h-[4.5px]',
                  'w-[4.5px]',
                  'rounded-full capitalize',
                  statusClasses.icon,
                )}
              />

              {t(statusNamesMap[status] || '')}
            </div>

            <div
              className={classNames(
                'text-[#77778B]',
                'flex',
                'gap-2.5',
                'items-end',
                'dark:text-[#625E8F]',
              )}
            >
              <span>{id}</span>

              <span
                className={classNames(
                  'block',
                  'h-[4px]',
                  'w-[4px]',
                  'rounded-full',
                  'bg-[#6E6E85]',
                  'mb-1',
                  'dark:bg-[#625E8F]',
                )}
              />

              <span>
                {status === PROPOSAL_STATUS.active ||
                status === PROPOSAL_STATUS.snapshot
                  ? voteEndTime
                  : finishTime}
              </span>
            </div>
          </div>
          {/* </div> */}
          {/* right */}
          <div
            className={classNames(
              'flex',
              'flex-col',
              'gap-2.5 xl:mt-0',
              'order-2',
              'mb-4 xl:mb-0',
            )}
          >
            {votePercentages.map(({ action, value, percentage, hasVotes }) => (
              <div
                key={action}
                className={classNames({
                  'text-[#ADADBA]': !hasVotes,
                })}
                onClick={() => handleVote()}
              >
                <div className={classNames('text-xs', 'flex', 'mb-0.5')}>
                  <div className={classNames('mr-2 uppercase')}>
                    {action === VOTE_ACTION.up
                      ? t('yes', 'YES')
                      : t('no', 'NO')}
                  </div>
                  <div className={classNames('mr-0.5')}>
                    {formatNumber(value, 2, DefiUtils.ROUND_FLOOR)}
                  </div>
                  <div
                    className={classNames(
                      hasVotes
                        ? ['text-[#77778B]', 'dark:text-[#625E8F]']
                        : ['text-[#ADADBA]', 'dark:text-[#625E8F]'],
                    )}
                  >
                    HTM
                  </div>
                  <div className={classNames('grow')} />
                  <div
                    className={classNames(
                      hasVotes
                        ? ['text-[#77778B]', 'dark:text-[#625E8F]']
                        : ['text-[#ADADBA]', 'dark:text-[#625E8F]'],
                    )}
                  >
                    {new DefiUtils(percentage).toSafeFixed(
                      2,
                      DefiUtils.ROUND_FLOOR,
                    )}
                    %
                  </div>
                </div>

                <div
                  className={classNames(
                    'h-[3px]',
                    'bg-[#ADADBA]',
                    'rounded-[20px]',
                    'relative',
                    'dark:bg-[#625E8F]',
                  )}
                >
                  <div
                    style={{ width: `${percentage}%` }}
                    className={classNames(
                      'h-[100%]',
                      'rounded-[20px]',
                      action === VOTE_ACTION.up
                        ? 'bg-[#2FBE81]'
                        : 'bg-[#E45059]',
                    )}
                  ></div>
                </div>
              </div>
            ))}
          </div>
          {/* footer */}
          <AnimatePresence>
            {isOpen && (
              <m.div
                initial={{ height: 0, y: 0 }}
                animate={{ height: 'auto' }}
                exit={{ height: 0 }}
                transition={{ duration: 0.2 }}
                className={classNames(
                  'xl:col-span-2',
                  'order-4 overflow-hidden',
                  // isOpen ? 'relative' : 'absolute'
                )}
              >
                <m.p
                  initial={{ opacity: 0, y: 6 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.2 }}
                  exit={{ opacity: 0, y: 13.5 }}
                  className={classNames(
                    'text-xs',
                    'mt-[13.5px]',
                    'text-[#77778B]',
                    'leading-[125%]',
                    'dark:text-[#625E8F]',
                    'whitespace-pre-line',
                  )}
                >
                  {description.trim()}
                </m.p>
              </m.div>
            )}
          </AnimatePresence>
          {/* bar */}
          <div
            className={classNames(
              'xl:col-span-2',
              'absolute',
              'bottom-0',
              'left-0',
              'w-[100%]',
              'grid',
              'grid-cols-[1fr,auto,1fr]',
              'items-center',
              'gap-1',
            )}
          >
            <span
              className={classNames(
                'h-px',
                'bg-[#9D9DB4]',
                'dark:bg-[#625E8F]',
              )}
            ></span>
            <span
              className={classNames(
                'block',
                'text-[#9D9DB4]',
                'font-bold',
                'w-[10px]',
                'h-[2px]',
                'flex',
                'items-center',
                'justify-center',
                '-translate-y-[2px]',
                'dark:text-[#625E8F]',
              )}
            >
              {isOpen ? '-' : '+'}
            </span>
            <span
              className={classNames(
                'h-px',
                'bg-[#9D9DB4]',
                'dark:bg-[#625E8F]',
              )}
            ></span>
          </div>
        </div>
      </m.div>
    </LazyMotion>
  );
};

export default ProposalItem;
