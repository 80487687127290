import { useTranslation } from 'next-i18next';
import React from 'react';

import { InputButton } from './InputButton';

export const MaxButton: React.FC<any> = ({ onMax }) => {
  const { t } = useTranslation('staking-app');
  return (
    <InputButton onClick={onMax} data-testid='maxButton'>
      {t('max')}
    </InputButton>
  );
};
