import DefiUtils from 'defi-utils';

import { REWARDS_DECIMALS } from '@/store/reward-batch';

import blockchainService from '@/services/blockchain';
import {
  ResponseAccountMarketsForMarketRewards,
  ResponseAccountRewardBatch,
  ResponseRewardBatch,
} from '@/services/blockchain/reward-batch/types';
import multiversxSDK from '@/services/multiversx-sdk';
import { MAX_CACHE_TIME, queryClient } from '@/utils/query';

export const getAccountMarketsForMarketRewards = async (
  controllerAddress: string,
  accountAddress: string,
  moneyMarkets: {
    address: string;
    underlying: {
      id: string;
      symbol: string;
    };
  }[],
): Promise<ResponseAccountMarketsForMarketRewards[]> => {
  if (!accountAddress) {
    return [];
  }

  const moneyMarketAddresses = moneyMarkets.map(({ address }) => address);

  const [
    borrowIndexListMap,
    accrualTimestampsMap,
    borrowRatesPerSecondMap,
    accountTokensMap,
    accountBorrowSnapshotMap,
  ] = await Promise.all([
    blockchainService.moneyMarket.getBorrowIndexListMap(moneyMarketAddresses),
    blockchainService.moneyMarket.getAccrualTimestampsMap(moneyMarketAddresses),
    blockchainService.moneyMarket.getBorrowRatesPerSecondMap(
      moneyMarketAddresses,
    ),
    blockchainService.controller.getAccountTokensListMap(
      controllerAddress,
      moneyMarketAddresses,
      accountAddress,
    ),
    blockchainService.moneyMarket.getAccountBorrowSnapshotsMap(
      moneyMarketAddresses,
      accountAddress,
    ),
  ]);

  return moneyMarkets.map(({ address, underlying }) => {
    return {
      id: `${address}-${accountAddress}`,
      collateralTokens: accountTokensMap[address] || '0',
      storedBorrowBalance:
        accountBorrowSnapshotMap?.[address].borrowAmount || '0',
      accountBorrowIndex:
        accountBorrowSnapshotMap?.[address].borrowIndex || '0',
      market: {
        address: address,
        underlying: underlying,
        lastState: {
          borrowIndex: borrowIndexListMap[address] || '0',
          timestamp: accrualTimestampsMap[address] || new Date().toISOString(),
          borrowRatePerSecond: borrowRatesPerSecondMap[address] || '0',
        },
      },
    };
  });
};

export const getAccountRewardsBatches = async (
  controllerAddress: string,
  accountAddress: string,
  moneyMarketsBatchIds: string[],
): Promise<ResponseAccountRewardBatch[]> => {
  if (!accountAddress) {
    return [];
  }

  const [accountRewardsIndexListMap] = await Promise.all([
    blockchainService.controller.getAccountRewardsIndexListMap(
      controllerAddress,
      moneyMarketsBatchIds,
      accountAddress,
    ),
  ]);

  return Object.entries(accountRewardsIndexListMap).map(([id, index]) => {
    return {
      id,
      index,
      amount: '0',
      timestamp: new Date().toISOString(),
    };
  });
};

export const getRewardsBatches = async (
  controllerAddress: string,
  moneyMarketsMap: Record<
    string,
    {
      address: string;
      underlying: {
        id: string;
        symbol: string;
        decimals: number;
        name: string;
      };
      hToken: {
        id: string;
        symbol: string;
        decimals: number;
        name: string;
      };
    }
  >,
): Promise<ResponseRewardBatch[]> => {
  const queryKey = ['blockchain:reward:getRewardsBatches'];

  const queryFn = async () => {
    const moneyMarketAddresses = Object.keys(moneyMarketsMap);

    const [
      rewardsBatchesListMap,
      borrowIndexListMap,
      totalBorrowsListMap,
      totalSuppliesMap,
      totalCollateralTokensListMap,
    ] = await Promise.all([
      blockchainService.controller.getRewardsBatchesListMap(
        controllerAddress,
        moneyMarketAddresses,
      ),
      blockchainService.moneyMarket.getBorrowIndexListMap(moneyMarketAddresses),
      blockchainService.moneyMarket.getTotalBorrowsListMap(
        moneyMarketAddresses,
      ),
      blockchainService.moneyMarket.getTotalSuppliesMap(moneyMarketAddresses),
      blockchainService.controller.getTotalCollateralTokensListMap(
        controllerAddress,
        moneyMarketAddresses,
      ),
    ]);

    const rewardData = await multiversxSDK.tokens({
      identifiers: Object.values(rewardsBatchesListMap)
        .map((rewardsBatches) => rewardsBatches.map(({ tokenId }) => tokenId))
        .flat(1)
        .join(','),
      fields: 'name,ticker,identifier,decimals',
    });

    const rewardMap = rewardData.reduce(
      (prev, current) => ({
        ...prev,
        [current.identifier]: {
          name: current.name,
          symbol: current.ticker,
          id: current.identifier,
          decimals: current.decimals,
        },
      }),
      {} as Record<
        string,
        {
          name: string;
          symbol: string;
          id: string;
          decimals: number;
        }
      >,
    );

    const populatedRewardBatches = Object.entries(rewardsBatchesListMap)
      .map(([address, items]) => {
        return items.map(
          ({ id, marketType, endTime, index, speed, lastTime, tokenId }) => {
            return {
              id: `${address}-${id}`,
              type: marketType,
              endTime,
              index,
              speed,
              lastTime,
              rewardsToken: {
                id: tokenId,
                decimals: rewardMap[tokenId].decimals || 18,
                symbol: rewardMap[tokenId].symbol || tokenId,
                name: rewardMap[tokenId].name || tokenId,
              },
              moneyMarket: {
                address,
                totalCollateral: totalCollateralTokensListMap[address] || '0',
                underlying: moneyMarketsMap[address].underlying || {
                  id: '',
                  decimals: '',
                  symbol: '',
                  name: '',
                },
                hToken: moneyMarketsMap[address].hToken || {
                  id: '',
                  decimals: '',
                  symbol: '',
                  name: '',
                },
                dailyStateHistory: [
                  {
                    moneyMarketState: {
                      borrowIndex: borrowIndexListMap[address] || '0',
                      borrows: totalBorrowsListMap[address] || '0',
                      totalSupply: totalSuppliesMap[address] || '0',
                    },
                  },
                ],
              },
            };
          },
        );
      })
      .flat(1);

    const currentTime = Date.now();

    return populatedRewardBatches.map((rewardBatch) => {
      const endTime = new Date(rewardBatch.endTime).getTime();

      const speed =
        currentTime >= endTime
          ? '0'
          : new DefiUtils(rewardBatch.speed).toFullDecimals(18).toString();
      const indexerSpeed = new DefiUtils(rewardBatch.speed)
        .toFullDecimals(18)
        .toString();

      const { dailyStateHistory, ...rest } = rewardBatch.moneyMarket;
      const { rewardsToken } = rewardBatch;

      return {
        ...rewardBatch,
        moneyMarket: {
          ...rest,
          stateHistory: dailyStateHistory.map((item) => item.moneyMarketState),
        },
        rewardsToken: {
          ...rewardsToken,
          symbol: rewardsToken.id.split('-')[0],
          name: rewardsToken.id.split('-')[0],
          decimals:
            rewardsToken.decimals ||
            REWARDS_DECIMALS[rewardsToken.id.split('-')[0]] ||
            18,
        },
        speed,
        indexerSpeed,
      };
    });
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MAX_CACHE_TIME,
    staleTime: MAX_CACHE_TIME,
  }) as Promise<ResponseRewardBatch[]>;
};
