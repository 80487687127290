import classNames from 'classnames';
import { useTranslation } from 'next-i18next';

import PopupHead from '@/components/popups/PopupBed/components/PopupHead';

export const Header = () => {
  const { t } = useTranslation('governance');
  return (
    <PopupHead
      containerProps={{
        className: classNames('h-[52px]'),
      }}
    >
      <div
        className={classNames(
          'w-full capitalize',
          'mx-4',
          'relative',
          'text-center',
          'h-full',
          'flex',
          'items-center',
          'justify-center'
        )}
      >
        <div className={classNames('text-base', 'leading-[18px]')}>
          {t('vote', 'Vote')}
        </div>

        <div
          className={classNames(
            'h-[1px]',
            'bg-[#D1D1EA]',
            'w-full',
            'absolute',
            'bottom-0',
            'left-0'
          )}
        />
      </div>
    </PopupHead>
  );
};
