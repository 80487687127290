import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { captureException } from '@sentry/nextjs';
import DefiUtils from 'defi-utils';

import { EXCHANGE_RATE_KEY } from '@/hooks/protocol/useExchangeRate';

import { AppDispatch, GetRootState, RootState } from '@/store/index';
import { formatValidators } from '@/store/parsers/liquid-staking-app-parser';
import {
  H_TOKEN_DECIMALS,
  HIDDEN_DELEGATION_CONTRACTS,
  MARKET_KEY,
  nativeMarketSelector,
} from '@/store/protocol';
import { addAction } from '@/store/queue';

import blockchainService from '@/services/blockchain';
import logger from '@/utils/logger';
import { calcBorrowLimit } from '@/utils/math/market';

export const RECOMMENDED_REMOVED_COLLATERAL_LIMIT_MARGIN = 0.99999;

export const STATIC_VALIDATORS_INFO = {
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqy8lllls62y8s5: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/the-palm-three-network.jpg',
    },
    name: 'The Palm Tree Network',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqq8hlllls7a6h85: {
    logo: {
      light: 'https://cdn.app.hatom.com/images/node-operators/meria.jpg',
    },
    name: 'Meria',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqrhlllls062tu4: {
    logo: {
      light: 'https://cdn.app.hatom.com/images/node-operators/iverse1.svg',
      dark: 'https://cdn.app.hatom.com/images/node-operators/iverse2.png',
    },
    name: 'iVerse Vision',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqq90llllslwfcr3: {
    logo: {
      light: 'https://cdn.app.hatom.com/images/node-operators/valid-blocks.png',
    },
    name: 'Valid Blocks',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqzhllllsp9wvyl: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/trust-staking.jpg',
    },
    name: 'Trust Staking',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqz8llllsh6u4jp: {
    logo: {
      light: 'https://cdn.app.hatom.com/images/node-operators/arc-stake.jpg',
    },
    name: 'ARC Stake',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqx0llllsdx93z0: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/helios-staking.png',
    },
    name: 'Helios Staking',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqpqhllllsqdxn4p: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/runtime-verification.jpg',
    },
    name: 'Runtime Verification',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqq9lllllsf3mp40: {
    logo: {
      light: 'https://cdn.app.hatom.com/images/node-operators/mgstaking.jpg',
    },
    name: 'MGStaking',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqr8llllse9cj2t: {
    logo: {
      light: 'https://cdn.app.hatom.com/images/node-operators/titan-stake.jpg',
    },
    name: 'Titan Stake',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqj0lllls2a8xw2: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/moonlorian-stake.jpg',
    },
    name: 'Moonlorian Stake',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqpq8llllskj52rl: {
    logo: {
      light: 'https://cdn.app.hatom.com/images/node-operators/astrarizon.jpg',
    },
    name: 'Astrarizon',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqq00llllsghg898: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/inception-network.png',
      dark: 'https://cdn.app.hatom.com/images/node-operators/inception-network-n.png',
    },
    name: 'Inception Network',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqyllllls8wlxd9: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/disruptive-digital.png',
      dark: 'https://cdn.app.hatom.com/images/node-operators/disruptive-digitaln.png',
    },
    name: 'Disruptive Digital',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqs0llllsk20gh7: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/ofero-staking.jpg',
    },
    name: 'Ofero Staking',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqg8llllsqra25h: {
    logo: {
      light: 'https://cdn.app.hatom.com/images/node-operators/xoxno.jpg',
    },
    name: 'XOXNO',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqdhllllsfymgpz: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/tortuga-staking.png',
    },
    name: 'Tortuga Staking',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqehllllswleld8: {
    logo: {
      light: 'https://cdn.app.hatom.com/images/Vapor.png',
    },
    name: 'Vapor Republic',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqu0llllsvrk9na: {
    logo: {
      light: 'https://cdn.app.hatom.com/images/Valuestaking.png',
    },
    name: 'Valuestaking',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqfhllllscrt56r: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/alchemist-stake.png',
    },
    name: 'Alchemist Stake',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqe8llllscqtxme: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/woodstock-fund.jpg',
    },
    name: 'Woodstock Fund',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqq50lllls8dl5vl: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/parasco-staking.png',
    },
    name: 'Parasco Staking',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqq08llllsrvplwg: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/trust-staking-us.jpg',
    },
    name: 'Trust Staking US',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqql0lllls7t6vjr: {
    logo: {
      light: 'https://cdn.app.hatom.com/images/node-operators/entity.png',
    },
    name: 'Entity',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqjhllllsheu8nm: {
    logo: {
      light: 'https://cdn.app.hatom.com/images/node-operators/rohanstake.jpg',
    },
    name: 'Rohanstake',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqphllllsndz99p: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/chain-state-labs.jpg',
    },
    name: 'Chain State Labs',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqmhllllsjg335n: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/aurentum-gmbh.jpg',
    },
    name: 'Aurentum Gmbh',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqm0lllls0v2sfz: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/d1fferent-capital.png',
    },
    name: 'D1fferent Capital',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqc8llllskl0prn: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/smart-chain-connection.jpg',
    },
    name: 'Smart Chain Connection',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqq70llllss57t2f: {
    logo: {
      light:
        'https://cdn.app.hatom.com/images/node-operators/rarity-market-nft-marketplace.jpg',
    },
    name: 'Rarity.market NFT marketplace',
  },
  erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqd0lllls5qqfun: {
    logo: {
      light: 'https://cdn.app.hatom.com/images/node-operators/wavenode.png',
    },
    name: 'Wavenode',
  },
} as Record<string, { logo?: { light?: string; dark?: string }; name: string }>;

const defaultToken: Token = {
  id: '0',
  balance: '0',
  priceUSD: '0',
  symbol: '',
  source: '',
  balanceUSD: '0',
  decimals: 0,
  index: 0,
};

export interface Validator {
  address: string;
  name: string;
  logo: { dark: string; light: string };
  staked: string;
  website?: string;
}

export interface UndelegateNft {
  hsegldAmount: string;
  segldAmount: string;
  egldAmount: string;
  source: string;
  amountUsd: string;
  availableToRedeemDate: string;
  canRedeem: boolean;
  tokenNonce: number;
  tokenIdentifier: string;
}

export interface Token {
  id: string;
  symbol: string;
  priceUSD: string;
  balance: string;
  source: string;
  balanceUSD: string;
  decimals: number;
  index: number;
}

export interface LiquidStakingAppState {
  address: string;
  apy: string;
  stakers: string;
  minEgldToDelegate: string;
  maxEgldToUndelegate: string;
  unbondPeriod: string;
  totalFee: string;
  totalLocked: string;
  totalLockedUSD: string;
  borrowBalanceUSD: string;
  borrowLimitUSD: string;
  validatorsCount: string;
  validators: Validator[];
  undelegateNfts: UndelegateNft[];
  tokens: Record<string, Token>;
}

export enum TOKEN_SOURCE {
  wallet = 'wallet',
  collateral = 'collateral',
}

const initialState: LiquidStakingAppState = {
  address: '',
  apy: '0',
  stakers: '0',
  minEgldToDelegate: '0',
  maxEgldToUndelegate: '0',
  unbondPeriod: '0',
  totalFee: '0',
  totalLocked: '0',
  totalLockedUSD: '0',
  borrowBalanceUSD: '0',
  borrowLimitUSD: '0',
  validatorsCount: '0',
  validators: [],
  undelegateNfts: [],
  tokens: {
    [`${EXCHANGE_RATE_KEY.sEGLD}-${TOKEN_SOURCE.wallet}`]: {
      ...defaultToken,
      id: `${EXCHANGE_RATE_KEY.sEGLD}-${TOKEN_SOURCE.wallet}`,
      symbol: EXCHANGE_RATE_KEY.sEGLD,
      source: TOKEN_SOURCE.wallet,
      decimals: 18,
      index: 0,
    },
    [`${EXCHANGE_RATE_KEY.HsEGLD}-${TOKEN_SOURCE.wallet}`]: {
      ...defaultToken,
      id: `${EXCHANGE_RATE_KEY.HsEGLD}-${TOKEN_SOURCE.wallet}`,
      symbol: EXCHANGE_RATE_KEY.HsEGLD,
      source: TOKEN_SOURCE.wallet,
      decimals: H_TOKEN_DECIMALS,
      index: 1,
    },
    [`${EXCHANGE_RATE_KEY.HsEGLD}-${TOKEN_SOURCE.collateral}`]: {
      ...defaultToken,
      id: `${EXCHANGE_RATE_KEY.HsEGLD}-${TOKEN_SOURCE.collateral}`,
      symbol: EXCHANGE_RATE_KEY.HsEGLD,
      source: TOKEN_SOURCE.collateral,
      decimals: H_TOKEN_DECIMALS,
      index: 2,
    },
  },
};

export const liquidStakingAppSlice = createSlice({
  name: 'liquidStakingApp',
  initialState,
  reducers: {
    setLiquidStakingApp: (
      state,
      action: PayloadAction<Partial<LiquidStakingAppState>>,
    ) => {
      Object.entries(action.payload).map(([key, value]) => {
        state[key as keyof LiquidStakingAppState] = value;
      });
    },
  },
});

export const { setLiquidStakingApp } = liquidStakingAppSlice.actions;

export const getLiquidStakingAppInfo =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();
      const { liquidStaking, userBalances, markets } = state.protocol;

      const {
        apy,
        totalStakers,
        address,
        minEgldToDelegate,
        totalFee,
        unbondPeriod,
      } = liquidStaking;

      const borrowBalanceUSD = Object.entries(userBalances)
        .reduce((prev, [tokenKey, { borrowBalance }]) => {
          const market = markets[tokenKey as MARKET_KEY];

          const result = new DefiUtils(borrowBalance)
            .toFullDecimals(market.underlying.decimals)
            .toUSD(market.underlying.priceUSD)
            .toString();

          return prev.plus(result);
        }, new DefiUtils('0'))
        .toString();

      const borrowLimitUSD = Object.entries(userBalances)
        .reduce((acc, [tokenKey, { underlyingCollateralBalance }]) => {
          const market = markets[tokenKey as MARKET_KEY];

          const collateralBalance = calcBorrowLimit(
            underlyingCollateralBalance,
            market.collateralFactor,
            market.underlying.priceUSD,
            market.underlying.decimals,
          );

          return acc.plus(collateralBalance);
        }, new DefiUtils('0'))
        .toString();

      await dispatch(
        addAction(
          setLiquidStakingApp({
            apy,
            address,
            stakers: totalStakers,
            minEgldToDelegate,
            unbondPeriod,
            totalFee,
            borrowBalanceUSD,
            borrowLimitUSD,
          }),
        ),
      );
    } catch (error) {
      logger.error('store:getLiquidStakingAppInfo', error);
      captureException(error);
    }
  };

export const getValidators =
  () => async (dispatch: AppDispatch, getState: GetRootState) => {
    try {
      const state = getState();

      const delegationContracts =
        await blockchainService.lens.getDelegationContractsData();

      const nativeMarket = nativeMarketSelector(state);

      const delegationContractsFiltered = delegationContracts.filter(
        (delegationContractItem) =>
          !HIDDEN_DELEGATION_CONTRACTS.includes(
            delegationContractItem.contract,
          ),
      );

      const validators = formatValidators(delegationContractsFiltered, 18);

      const totalLocked = validators
        .reduce(
          (prev, current) => prev.plus(current.staked),
          new DefiUtils('0'),
        )
        .toString();

      const totalLockedUSD = new DefiUtils(totalLocked)
        .toUSD(nativeMarket.underlying.priceUSD)
        .toString();

      const maxEgldToUndelegate = new DefiUtils(
        DefiUtils.max(
          ...delegationContractsFiltered.map(
            ({ totalDelegated }) => totalDelegated,
          ),
        ).dividedBy(2),
      )
        .toFullDecimals(18)
        .toString();

      await dispatch(
        addAction(
          setLiquidStakingApp({
            validators,
            validatorsCount: String(validators.length),
            totalLocked,
            totalLockedUSD,
            maxEgldToUndelegate,
          }),
        ),
      );
    } catch (error) {
      logger.error('store:getValidators', error);
      captureException(error);
    }
  };

export const liquidStakingAppSelector = (state: RootState) =>
  state.liquidStakingApp;

export default liquidStakingAppSlice.reducer;
