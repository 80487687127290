import format from 'date-fns/format';
import parse from 'date-fns/parse';
import DefiUtils from 'defi-utils';

import {
  ASSET_ALT_NAME,
  MARKET_KEY,
  MARKET_ORDER_MAP,
  Market as MarketProtocol,
  ProtocolState,
  TOKEN_LOGO_MAP,
} from '@/store/protocol';

import { ResponseHistoryPoint, ResponseMarkets } from '@/services/indexer';
import { getHistoryPointFilled } from '@/utils/market';
import { calcMarketAPY } from '@/utils/math/apy';

const MAX_RATES_LENGTH = 100;

const MARKET_RATES: Record<
  string,
  {
    borrowRates: { x: string; y: string }[];
    supplyRates: { x: string; y: string }[];
  }
> = {
  EGLD: {
    borrowRates: [
      { x: '0.01', y: '0.06920' },
      { x: '0.02', y: '0.13846' },
      { x: '0.03', y: '0.20776' },
      { x: '0.04', y: '0.27712' },
      { x: '0.05', y: '0.34652' },
      { x: '0.06', y: '0.41596' },
      { x: '0.07', y: '0.48546' },
      { x: '0.08', y: '0.55500' },
      { x: '0.09', y: '0.62460' },
      { x: '0.1', y: '0.69424' },
      { x: '0.11', y: '0.76392' },
      { x: '0.12', y: '0.83366' },
      { x: '0.13', y: '0.90344' },
      { x: '0.14', y: '0.97328' },
      { x: '0.15', y: '1.04316' },
      { x: '0.16', y: '1.11309' },
      { x: '0.17', y: '1.18306' },
      { x: '0.18', y: '1.25309' },
      { x: '0.19', y: '1.32316' },
      { x: '0.2', y: '1.39328' },
      { x: '0.21', y: '1.46345' },
      { x: '0.22', y: '1.53367' },
      { x: '0.23', y: '1.60394' },
      { x: '0.24', y: '1.67426' },
      { x: '0.25', y: '1.74462' },
      { x: '0.26', y: '1.81503' },
      { x: '0.27', y: '1.88549' },
      { x: '0.28', y: '1.95600' },
      { x: '0.29', y: '2.02656' },
      { x: '0.3', y: '2.09717' },
      { x: '0.31', y: '2.16783' },
      { x: '0.32', y: '2.23853' },
      { x: '0.33', y: '2.30928' },
      { x: '0.34', y: '2.38009' },
      { x: '0.35', y: '2.45094' },
      { x: '0.36', y: '2.52184' },
      { x: '0.37', y: '2.59279' },
      { x: '0.38', y: '2.66379' },
      { x: '0.39', y: '2.73483' },
      { x: '0.4', y: '2.80593' },
      { x: '0.41', y: '2.87707' },
      { x: '0.42', y: '2.94827' },
      { x: '0.43', y: '3.01951' },
      { x: '0.44', y: '3.09081' },
      { x: '0.45', y: '3.16215' },
      { x: '0.46', y: '3.23354' },
      { x: '0.47', y: '3.30498' },
      { x: '0.48', y: '3.37647' },
      { x: '0.49', y: '3.44801' },
      { x: '0.5', y: '3.51960' },
      { x: '0.51', y: '3.59123' },
      { x: '0.52', y: '3.66292' },
      { x: '0.53', y: '3.73466' },
      { x: '0.54', y: '3.80644' },
      { x: '0.55', y: '3.87828' },
      { x: '0.56', y: '3.95017' },
      { x: '0.57', y: '4.02210' },
      { x: '0.58', y: '4.09408' },
      { x: '0.59', y: '4.16612' },
      { x: '0.6', y: '4.23820' },
      { x: '0.61', y: '4.31034' },
      { x: '0.62', y: '4.38252' },
      { x: '0.63', y: '4.45475' },
      { x: '0.64', y: '4.52704' },
      { x: '0.65', y: '4.59937' },
      { x: '0.66', y: '7.01575' },
      { x: '0.67', y: '9.48779' },
      { x: '0.68', y: '12.01677' },
      { x: '0.69', y: '14.60401' },
      { x: '0.7', y: '17.25084' },
      { x: '0.71', y: '19.95862' },
      { x: '0.72', y: '22.72876' },
      { x: '0.73', y: '25.56269' },
      { x: '0.74', y: '28.46188' },
      { x: '0.75', y: '31.42782' },
      { x: '0.76', y: '34.46205' },
      { x: '0.77', y: '37.56613' },
      { x: '0.78', y: '40.74167' },
      { x: '0.79', y: '43.99031' },
      { x: '0.8', y: '47.31372' },
      { x: '0.81', y: '50.71362' },
      { x: '0.82', y: '54.19177' },
      { x: '0.83', y: '57.74997' },
      { x: '0.84', y: '61.39004' },
      { x: '0.85', y: '65.11388' },
      { x: '0.86', y: '68.92339' },
      { x: '0.87', y: '72.82055' },
      { x: '0.88', y: '76.80737' },
      { x: '0.89', y: '80.88591' },
      { x: '0.9', y: '85.05826' },
      { x: '0.91', y: '89.32659' },
      { x: '0.92', y: '93.69308' },
      { x: '0.93', y: '98.16000' },
      { x: '0.94', y: '102.72965' },
      { x: '0.95', y: '107.40438' },
      { x: '0.96', y: '112.18660' },
      { x: '0.97', y: '117.07878' },
      { x: '0.98', y: '122.08343' },
      { x: '0.99', y: '127.20315' },
      { x: '1', y: '132.44055' },
    ],
    supplyRates: [
      { x: '0.01', y: '0.00044' },
      { x: '0.02', y: '0.00179' },
      { x: '0.03', y: '0.00404' },
      { x: '0.04', y: '0.00719' },
      { x: '0.05', y: '0.01124' },
      { x: '0.06', y: '0.01619' },
      { x: '0.07', y: '0.02203' },
      { x: '0.08', y: '0.02878' },
      { x: '0.09', y: '0.03643' },
      { x: '0.1', y: '0.04498' },
      { x: '0.11', y: '0.05442' },
      { x: '0.12', y: '0.06477' },
      { x: '0.13', y: '0.07602' },
      { x: '0.14', y: '0.08818' },
      { x: '0.15', y: '0.10123' },
      { x: '0.16', y: '0.11518' },
      { x: '0.17', y: '0.13004' },
      { x: '0.18', y: '0.14580' },
      { x: '0.19', y: '0.16247' },
      { x: '0.2', y: '0.18004' },
      { x: '0.21', y: '0.19851' },
      { x: '0.22', y: '0.21789' },
      { x: '0.23', y: '0.23817' },
      { x: '0.24', y: '0.25936' },
      { x: '0.25', y: '0.28145' },
      { x: '0.26', y: '0.30445' },
      { x: '0.27', y: '0.32836' },
      { x: '0.28', y: '0.35318' },
      { x: '0.29', y: '0.37891' },
      { x: '0.3', y: '0.40554' },
      { x: '0.31', y: '0.43309' },
      { x: '0.32', y: '0.46155' },
      { x: '0.33', y: '0.49092' },
      { x: '0.34', y: '0.52120' },
      { x: '0.35', y: '0.55240' },
      { x: '0.36', y: '0.58451' },
      { x: '0.37', y: '0.61753' },
      { x: '0.38', y: '0.65147' },
      { x: '0.39', y: '0.68633' },
      { x: '0.4', y: '0.72211' },
      { x: '0.41', y: '0.75880' },
      { x: '0.42', y: '0.79641' },
      { x: '0.43', y: '0.83495' },
      { x: '0.44', y: '0.87441' },
      { x: '0.45', y: '0.91479' },
      { x: '0.46', y: '0.95609' },
      { x: '0.47', y: '0.99832' },
      { x: '0.48', y: '1.04148' },
      { x: '0.49', y: '1.08556' },
      { x: '0.5', y: '1.13057' },
      { x: '0.51', y: '1.17652' },
      { x: '0.52', y: '1.22339' },
      { x: '0.53', y: '1.27120' },
      { x: '0.54', y: '1.31994' },
      { x: '0.55', y: '1.36961' },
      { x: '0.56', y: '1.42022' },
      { x: '0.57', y: '1.47177' },
      { x: '0.58', y: '1.52426' },
      { x: '0.59', y: '1.57769' },
      { x: '0.6', y: '1.63206' },
      { x: '0.61', y: '1.68737' },
      { x: '0.62', y: '1.74363' },
      { x: '0.63', y: '1.80084' },
      { x: '0.64', y: '1.85899' },
      { x: '0.65', y: '1.91810' },
      { x: '0.66', y: '2.95175' },
      { x: '0.67', y: '4.02673' },
      { x: '0.68', y: '5.14412' },
      { x: '0.69', y: '6.30504' },
      { x: '0.7', y: '7.51065' },
      { x: '0.71', y: '8.76218' },
      { x: '0.72', y: '10.06091' },
      { x: '0.73', y: '11.40817' },
      { x: '0.74', y: '12.80536' },
      { x: '0.75', y: '14.25393' },
      { x: '0.76', y: '15.75541' },
      { x: '0.77', y: '17.31138' },
      { x: '0.78', y: '18.92350' },
      { x: '0.79', y: '20.59349' },
      { x: '0.8', y: '22.32317' },
      { x: '0.81', y: '24.11442' },
      { x: '0.82', y: '25.96920' },
      { x: '0.83', y: '27.88957' },
      { x: '0.84', y: '29.87767' },
      { x: '0.85', y: '31.93572' },
      { x: '0.86', y: '34.06608' },
      { x: '0.87', y: '36.27115' },
      { x: '0.88', y: '38.55350' },
      { x: '0.89', y: '40.91577' },
      { x: '0.9', y: '43.36072' },
      { x: '0.91', y: '45.89124' },
      { x: '0.92', y: '48.51035' },
      { x: '0.93', y: '51.22120' },
      { x: '0.94', y: '54.02707' },
      { x: '0.95', y: '56.93139' },
      { x: '0.96', y: '59.93775' },
      { x: '0.97', y: '63.04988' },
      { x: '0.98', y: '66.27170' },
      { x: '0.99', y: '69.60729' },
      { x: '1', y: '73.06092' },
    ],
  },
  SEGLD: {
    borrowRates: [
      { x: '0.01', y: '0.08886' },
      { x: '0.02', y: '0.17781' },
      { x: '0.03', y: '0.26684' },
      { x: '0.04', y: '0.35595' },
      { x: '0.05', y: '0.44513' },
      { x: '0.06', y: '0.53439' },
      { x: '0.07', y: '0.62374' },
      { x: '0.08', y: '0.71316' },
      { x: '0.09', y: '0.80266' },
      { x: '0.1', y: '0.89224' },
      { x: '0.11', y: '0.98190' },
      { x: '0.12', y: '1.07164' },
      { x: '0.13', y: '1.16146' },
      { x: '0.14', y: '1.25136' },
      { x: '0.15', y: '1.34134' },
      { x: '0.16', y: '1.43139' },
      { x: '0.17', y: '1.52153' },
      { x: '0.18', y: '1.61175' },
      { x: '0.19', y: '1.70205' },
      { x: '0.2', y: '1.79243' },
      { x: '0.21', y: '1.88288' },
      { x: '0.22', y: '1.97342' },
      { x: '0.23', y: '2.06404' },
      { x: '0.24', y: '2.15474' },
      { x: '0.25', y: '2.24552' },
      { x: '0.26', y: '2.33638' },
      { x: '0.27', y: '2.42732' },
      { x: '0.28', y: '2.51834' },
      { x: '0.29', y: '2.60944' },
      { x: '0.3', y: '2.70062' },
      { x: '0.31', y: '2.79188' },
      { x: '0.32', y: '2.88323' },
      { x: '0.33', y: '2.97465' },
      { x: '0.34', y: '3.06616' },
      { x: '0.35', y: '3.15774' },
      { x: '0.36', y: '3.24941' },
      { x: '0.37', y: '3.34116' },
      { x: '0.38', y: '3.43299' },
      { x: '0.39', y: '3.52490' },
      { x: '0.4', y: '3.61689' },
      { x: '0.41', y: '3.70897' },
      { x: '0.42', y: '3.80112' },
      { x: '0.43', y: '3.89336' },
      { x: '0.44', y: '3.98568' },
      { x: '0.45', y: '4.07808' },
      { x: '0.46', y: '9.90767' },
      { x: '0.47', y: '16.06284' },
      { x: '0.48', y: '22.56172' },
      { x: '0.49', y: '29.42345' },
      { x: '0.5', y: '36.66823' },
      { x: '0.51', y: '44.31738' },
      { x: '0.52', y: '52.39341' },
      { x: '0.53', y: '60.92006' },
      { x: '0.54', y: '69.92242' },
      { x: '0.55', y: '79.42693' },
      { x: '0.56', y: '89.46154' },
      { x: '0.57', y: '100.05572' },
      { x: '0.58', y: '111.24059' },
      { x: '0.59', y: '123.04898' },
      { x: '0.6', y: '135.51555' },
      { x: '0.61', y: '148.67689' },
      { x: '0.62', y: '162.57159' },
      { x: '0.63', y: '177.24041' },
      { x: '0.64', y: '192.72634' },
      { x: '0.65', y: '209.07478' },
      { x: '0.66', y: '226.33363' },
      { x: '0.67', y: '244.55342' },
      { x: '0.68', y: '263.78752' },
      { x: '0.69', y: '284.09222' },
      { x: '0.7', y: '305.52695' },
      { x: '0.71', y: '328.15441' },
      { x: '0.72', y: '352.04077' },
      { x: '0.73', y: '377.25589' },
      { x: '0.74', y: '403.87345' },
      { x: '0.75', y: '431.97124' },
      { x: '0.76', y: '461.63133' },
      { x: '0.77', y: '492.94034' },
      { x: '0.78', y: '525.98967' },
      { x: '0.79', y: '560.87579' },
      { x: '0.8', y: '597.70046' },
      { x: '0.81', y: '636.57112' },
      { x: '0.82', y: '677.60109' },
      { x: '0.83', y: '720.90999' },
      { x: '0.84', y: '766.62403' },
      { x: '0.85', y: '814.87639' },
      { x: '0.86', y: '865.80760' },
      { x: '0.87', y: '919.56596' },
      { x: '0.88', y: '976.30794' },
      { x: '0.89', y: '1036.19866' },
      { x: '0.9', y: '1099.41234' },
      { x: '0.91', y: '1166.13281' },
      { x: '0.92', y: '1236.55406' },
      { x: '0.93', y: '1310.88075' },
      { x: '0.94', y: '1389.32885' },
      { x: '0.95', y: '1472.12623' },
      { x: '0.96', y: '1559.51332' },
      { x: '0.97', y: '1651.74381' },
      { x: '0.98', y: '1749.08535' },
      { x: '0.99', y: '1851.82037' },
      { x: '1', y: '1960.24684' },
    ],
    supplyRates: [
      { x: '0.01', y: '0.00071' },
      { x: '0.02', y: '0.00284' },
      { x: '0.03', y: '0.00639' },
      { x: '0.04', y: '0.01137' },
      { x: '0.05', y: '0.01776' },
      { x: '0.06', y: '0.02558' },
      { x: '0.07', y: '0.03482' },
      { x: '0.08', y: '0.04549' },
      { x: '0.09', y: '0.05757' },
      { x: '0.1', y: '0.07108' },
      { x: '0.11', y: '0.08602' },
      { x: '0.12', y: '0.10238' },
      { x: '0.13', y: '0.12017' },
      { x: '0.14', y: '0.13938' },
      { x: '0.15', y: '0.16002' },
      { x: '0.16', y: '0.18208' },
      { x: '0.17', y: '0.20558' },
      { x: '0.18', y: '0.23051' },
      { x: '0.19', y: '0.25686' },
      { x: '0.2', y: '0.28465' },
      { x: '0.21', y: '0.31388' },
      { x: '0.22', y: '0.34454' },
      { x: '0.23', y: '0.37663' },
      { x: '0.24', y: '0.41016' },
      { x: '0.25', y: '0.44513' },
      { x: '0.26', y: '0.48154' },
      { x: '0.27', y: '0.51939' },
      { x: '0.28', y: '0.55869' },
      { x: '0.29', y: '0.59943' },
      { x: '0.3', y: '0.64161' },
      { x: '0.31', y: '0.68525' },
      { x: '0.32', y: '0.73034' },
      { x: '0.33', y: '0.77688' },
      { x: '0.34', y: '0.82487' },
      { x: '0.35', y: '0.87432' },
      { x: '0.36', y: '0.92523' },
      { x: '0.37', y: '0.97760' },
      { x: '0.38', y: '1.03143' },
      { x: '0.39', y: '1.08672' },
      { x: '0.4', y: '1.14349' },
      { x: '0.41', y: '1.20172' },
      { x: '0.42', y: '1.26143' },
      { x: '0.43', y: '1.32261' },
      { x: '0.44', y: '1.38527' },
      { x: '0.45', y: '1.44942' },
      { x: '0.46', y: '3.53794' },
      { x: '0.47', y: '5.76153' },
      { x: '0.48', y: '8.12698' },
      { x: '0.49', y: '10.64162' },
      { x: '0.5', y: '13.31332' },
      { x: '0.51', y: '16.15057' },
      { x: '0.52', y: '19.16249' },
      { x: '0.53', y: '22.35891' },
      { x: '0.54', y: '25.75038' },
      { x: '0.55', y: '29.34828' },
      { x: '0.56', y: '33.16482' },
      { x: '0.57', y: '37.21317' },
      { x: '0.58', y: '41.50749' },
      { x: '0.59', y: '46.06304' },
      { x: '0.6', y: '50.89622' },
      { x: '0.61', y: '56.02473' },
      { x: '0.62', y: '61.46763' },
      { x: '0.63', y: '67.24545' },
      { x: '0.64', y: '73.38037' },
      { x: '0.65', y: '79.89625' },
      { x: '0.66', y: '86.81891' },
      { x: '0.67', y: '94.17617' },
      { x: '0.68', y: '101.99810' },
      { x: '0.69', y: '110.31720' },
      { x: '0.7', y: '119.16859' },
      { x: '0.71', y: '128.59026' },
      { x: '0.72', y: '138.62333' },
      { x: '0.73', y: '149.31230' },
      { x: '0.74', y: '160.70538' },
      { x: '0.75', y: '172.85483' },
      { x: '0.76', y: '185.81730' },
      { x: '0.77', y: '199.65425' },
      { x: '0.78', y: '214.43240' },
      { x: '0.79', y: '230.22422' },
      { x: '0.8', y: '247.10844' },
      { x: '0.81', y: '265.17068' },
      { x: '0.82', y: '284.50407' },
      { x: '0.83', y: '305.20998' },
      { x: '0.84', y: '327.39882' },
      { x: '0.85', y: '351.19089' },
      { x: '0.86', y: '376.71740' },
      { x: '0.87', y: '404.12144' },
      { x: '0.88', y: '433.55924' },
      { x: '0.89', y: '465.20146' },
      { x: '0.9', y: '499.23459' },
      { x: '0.91', y: '535.86257' },
      { x: '0.92', y: '575.30855' },
      { x: '0.93', y: '617.81687' },
      { x: '0.94', y: '663.65518' },
      { x: '0.95', y: '713.11690' },
      { x: '0.96', y: '766.52386' },
      { x: '0.97', y: '824.22926' },
      { x: '0.98', y: '886.62102' },
      { x: '0.99', y: '954.12537' },
      { x: '1', y: '1027.21102' },
    ],
  },
  WTAO: {
    borrowRates: [
      { x: '0.01', y: '0.07497' },
      { x: '0.02', y: '0.15001' },
      { x: '0.03', y: '0.22510' },
      { x: '0.04', y: '0.30024' },
      { x: '0.05', y: '0.37545' },
      { x: '0.06', y: '0.45071' },
      { x: '0.07', y: '0.52602' },
      { x: '0.08', y: '0.60139' },
      { x: '0.09', y: '0.67682' },
      { x: '0.1', y: '0.75231' },
      { x: '0.11', y: '0.82785' },
      { x: '0.12', y: '0.90344' },
      { x: '0.13', y: '0.97910' },
      { x: '0.14', y: '1.05481' },
      { x: '0.15', y: '1.13057' },
      { x: '0.16', y: '1.20640' },
      { x: '0.17', y: '1.28228' },
      { x: '0.18', y: '1.35822' },
      { x: '0.19', y: '1.43421' },
      { x: '0.2', y: '1.51026' },
      { x: '0.21', y: '1.58637' },
      { x: '0.22', y: '1.66253' },
      { x: '0.23', y: '1.73875' },
      { x: '0.24', y: '1.81503' },
      { x: '0.25', y: '1.89137' },
      { x: '0.26', y: '1.96776' },
      { x: '0.27', y: '2.04421' },
      { x: '0.28', y: '2.12072' },
      { x: '0.29', y: '2.19728' },
      { x: '0.3', y: '2.27390' },
      { x: '0.31', y: '2.35058' },
      { x: '0.32', y: '2.42732' },
      { x: '0.33', y: '2.50411' },
      { x: '0.34', y: '2.58096' },
      { x: '0.35', y: '2.65787' },
      { x: '0.36', y: '2.73483' },
      { x: '0.37', y: '2.81186' },
      { x: '0.38', y: '2.88894' },
      { x: '0.39', y: '2.96608' },
      { x: '0.4', y: '3.04327' },
      { x: '0.41', y: '3.12053' },
      { x: '0.42', y: '3.19784' },
      { x: '0.43', y: '3.27521' },
      { x: '0.44', y: '3.35263' },
      { x: '0.45', y: '3.43012' },
      { x: '0.46', y: '3.50766' },
      { x: '0.47', y: '3.58526' },
      { x: '0.48', y: '3.66292' },
      { x: '0.49', y: '3.74064' },
      { x: '0.5', y: '3.81841' },
      { x: '0.51', y: '3.89625' },
      { x: '0.52', y: '3.97414' },
      { x: '0.53', y: '4.05209' },
      { x: '0.54', y: '4.13010' },
      { x: '0.55', y: '4.20816' },
      { x: '0.56', y: '4.28629' },
      { x: '0.57', y: '4.36447' },
      { x: '0.58', y: '4.44271' },
      { x: '0.59', y: '4.52101' },
      { x: '0.6', y: '4.59937' },
      { x: '0.61', y: '4.67779' },
      { x: '0.62', y: '4.75626' },
      { x: '0.63', y: '4.83480' },
      { x: '0.64', y: '4.91339' },
      { x: '0.65', y: '4.99204' },
      { x: '0.66', y: '5.07075' },
      { x: '0.67', y: '5.14952' },
      { x: '0.68', y: '5.22835' },
      { x: '0.69', y: '5.30724' },
      { x: '0.7', y: '5.38618' },
      { x: '0.71', y: '5.46519' },
      { x: '0.72', y: '5.54425' },
      { x: '0.73', y: '5.62338' },
      { x: '0.74', y: '5.70256' },
      { x: '0.75', y: '5.78180' },
      { x: '0.76', y: '5.86110' },
      { x: '0.77', y: '5.94046' },
      { x: '0.78', y: '6.01988' },
      { x: '0.79', y: '6.09936' },
      { x: '0.8', y: '6.17890' },
      { x: '0.81', y: '10.50825' },
      { x: '0.82', y: '15.01361' },
      { x: '0.83', y: '19.70214' },
      { x: '0.84', y: '24.58124' },
      { x: '0.85', y: '29.65866' },
      { x: '0.86', y: '34.94242' },
      { x: '0.87', y: '40.44088' },
      { x: '0.88', y: '46.16275' },
      { x: '0.89', y: '52.11708' },
      { x: '0.9', y: '58.31328' },
      { x: '0.91', y: '64.76116' },
      { x: '0.92', y: '71.47089' },
      { x: '0.93', y: '78.45310' },
      { x: '0.94', y: '85.71881' },
      { x: '0.95', y: '93.27949' },
      { x: '0.96', y: '101.14710' },
      { x: '0.97', y: '109.33405' },
      { x: '0.98', y: '117.85328' },
      { x: '0.99', y: '126.71822' },
      { x: '1', y: '135.94286' },
    ],
    supplyRates: [
      { x: '0.01', y: '0.00048' },
      { x: '0.02', y: '0.00194' },
      { x: '0.03', y: '0.00438' },
      { x: '0.04', y: '0.00779' },
      { x: '0.05', y: '0.01218' },
      { x: '0.06', y: '0.01753' },
      { x: '0.07', y: '0.02387' },
      { x: '0.08', y: '0.03118' },
      { x: '0.09', y: '0.03946' },
      { x: '0.1', y: '0.04872' },
      { x: '0.11', y: '0.05896' },
      { x: '0.12', y: '0.07017' },
      { x: '0.13', y: '0.08236' },
      { x: '0.14', y: '0.09553' },
      { x: '0.15', y: '0.10967' },
      { x: '0.16', y: '0.12479' },
      { x: '0.17', y: '0.14089' },
      { x: '0.18', y: '0.15796' },
      { x: '0.19', y: '0.17602' },
      { x: '0.2', y: '0.19506' },
      { x: '0.21', y: '0.21507' },
      { x: '0.22', y: '0.23607' },
      { x: '0.23', y: '0.25804' },
      { x: '0.24', y: '0.28100' },
      { x: '0.25', y: '0.30494' },
      { x: '0.26', y: '0.32987' },
      { x: '0.27', y: '0.35578' },
      { x: '0.28', y: '0.38267' },
      { x: '0.29', y: '0.41055' },
      { x: '0.3', y: '0.43941' },
      { x: '0.31', y: '0.46927' },
      { x: '0.32', y: '0.50011' },
      { x: '0.33', y: '0.53194' },
      { x: '0.34', y: '0.56476' },
      { x: '0.35', y: '0.59857' },
      { x: '0.36', y: '0.63337' },
      { x: '0.37', y: '0.66916' },
      { x: '0.38', y: '0.70595' },
      { x: '0.39', y: '0.74374' },
      { x: '0.4', y: '0.78252' },
      { x: '0.41', y: '0.82229' },
      { x: '0.42', y: '0.86307' },
      { x: '0.43', y: '0.90484' },
      { x: '0.44', y: '0.94762' },
      { x: '0.45', y: '0.99140' },
      { x: '0.46', y: '1.03618' },
      { x: '0.47', y: '1.08196' },
      { x: '0.48', y: '1.12876' },
      { x: '0.49', y: '1.17656' },
      { x: '0.5', y: '1.22536' },
      { x: '0.51', y: '1.27518' },
      { x: '0.52', y: '1.32601' },
      { x: '0.53', y: '1.37785' },
      { x: '0.54', y: '1.43071' },
      { x: '0.55', y: '1.48459' },
      { x: '0.56', y: '1.53948' },
      { x: '0.57', y: '1.59539' },
      { x: '0.58', y: '1.65232' },
      { x: '0.59', y: '1.71028' },
      { x: '0.6', y: '1.76926' },
      { x: '0.61', y: '1.82926' },
      { x: '0.62', y: '1.89030' },
      { x: '0.63', y: '1.95236' },
      { x: '0.64', y: '2.01546' },
      { x: '0.65', y: '2.07959' },
      { x: '0.66', y: '2.14475' },
      { x: '0.67', y: '2.21095' },
      { x: '0.68', y: '2.27819' },
      { x: '0.69', y: '2.34648' },
      { x: '0.7', y: '2.41580' },
      { x: '0.71', y: '2.48617' },
      { x: '0.72', y: '2.55759' },
      { x: '0.73', y: '2.63006' },
      { x: '0.74', y: '2.70358' },
      { x: '0.75', y: '2.77815' },
      { x: '0.76', y: '2.85378' },
      { x: '0.77', y: '2.93047' },
      { x: '0.78', y: '3.00822' },
      { x: '0.79', y: '3.08703' },
      { x: '0.8', y: '3.16691' },
      { x: '0.81', y: '5.40198' },
      { x: '0.82', y: '7.74131' },
      { x: '0.83', y: '10.18964' },
      { x: '0.84', y: '12.75200' },
      { x: '0.85', y: '15.43374' },
      { x: '0.86', y: '18.24048' },
      { x: '0.87', y: '21.17819' },
      { x: '0.88', y: '24.25320' },
      { x: '0.89', y: '27.47220' },
      { x: '0.9', y: '30.84229' },
      { x: '0.91', y: '34.37097' },
      { x: '0.92', y: '38.06622' },
      { x: '0.93', y: '41.93647' },
      { x: '0.94', y: '45.99068' },
      { x: '0.95', y: '50.23832' },
      { x: '0.96', y: '54.68947' },
      { x: '0.97', y: '59.35481' },
      { x: '0.98', y: '64.24566' },
      { x: '0.99', y: '69.37407' },
      { x: '1', y: '74.75280' },
    ],
  },
  SWTAO: {
    borrowRates: [
      { x: '0.01', y: '2.10938' },
      { x: '0.02', y: '2.20012' },
      { x: '0.03', y: '2.29094' },
      { x: '0.04', y: '2.38184' },
      { x: '0.05', y: '2.47282' },
      { x: '0.06', y: '2.56388' },
      { x: '0.07', y: '2.65502' },
      { x: '0.08', y: '2.74624' },
      { x: '0.09', y: '2.83754' },
      { x: '0.1', y: '2.92893' },
      { x: '0.11', y: '3.02039' },
      { x: '0.12', y: '3.11194' },
      { x: '0.13', y: '3.20357' },
      { x: '0.14', y: '3.29527' },
      { x: '0.15', y: '3.38706' },
      { x: '0.16', y: '3.47893' },
      { x: '0.17', y: '3.57089' },
      { x: '0.18', y: '3.66292' },
      { x: '0.19', y: '3.75504' },
      { x: '0.2', y: '3.84723' },
      { x: '0.21', y: '3.93951' },
      { x: '0.22', y: '4.03187' },
      { x: '0.23', y: '4.12432' },
      { x: '0.24', y: '4.21684' },
      { x: '0.25', y: '4.30945' },
      { x: '0.26', y: '4.40213' },
      { x: '0.27', y: '4.49490' },
      { x: '0.28', y: '4.58776' },
      { x: '0.29', y: '4.68069' },
      { x: '0.3', y: '4.77371' },
      { x: '0.31', y: '4.86681' },
      { x: '0.32', y: '4.95999' },
      { x: '0.33', y: '5.05326' },
      { x: '0.34', y: '5.14660' },
      { x: '0.35', y: '5.24003' },
      { x: '0.36', y: '5.33355' },
      { x: '0.37', y: '5.42714' },
      { x: '0.38', y: '5.52082' },
      { x: '0.39', y: '5.61458' },
      { x: '0.4', y: '5.70843' },
      { x: '0.41', y: '5.80236' },
      { x: '0.42', y: '5.89637' },
      { x: '0.43', y: '5.99046' },
      { x: '0.44', y: '6.08464' },
      { x: '0.45', y: '6.17890' },
      { x: '0.46', y: '12.12583' },
      { x: '0.47', y: '18.40487' },
      { x: '0.48', y: '25.03452' },
      { x: '0.49', y: '32.03429' },
      { x: '0.5', y: '39.42481' },
      { x: '0.51', y: '47.22780' },
      { x: '0.52', y: '55.46623' },
      { x: '0.53', y: '64.16432' },
      { x: '0.54', y: '73.34765' },
      { x: '0.55', y: '83.04322' },
      { x: '0.56', y: '93.27949' },
      { x: '0.57', y: '104.08656' },
      { x: '0.58', y: '115.49614' },
      { x: '0.59', y: '127.54174' },
      { x: '0.6', y: '140.25871' },
      { x: '0.61', y: '153.68435' },
      { x: '0.62', y: '167.85805' },
      { x: '0.63', y: '182.82136' },
      { x: '0.64', y: '198.61815' },
      { x: '0.65', y: '215.29470' },
      { x: '0.66', y: '232.89988' },
      { x: '0.67', y: '251.48524' },
      { x: '0.68', y: '271.10519' },
      { x: '0.69', y: '291.81717' },
      { x: '0.7', y: '313.68177' },
      { x: '0.71', y: '336.76296' },
      { x: '0.72', y: '361.12822' },
      { x: '0.73', y: '386.84880' },
      { x: '0.74', y: '413.99986' },
      { x: '0.75', y: '442.66073' },
      { x: '0.76', y: '472.91512' },
      { x: '0.77', y: '504.85137' },
      { x: '0.78', y: '538.56271' },
      { x: '0.79', y: '574.14752' },
      { x: '0.8', y: '611.70961' },
      { x: '0.81', y: '651.35853' },
      { x: '0.82', y: '693.20988' },
      { x: '0.83', y: '737.38565' },
      { x: '0.84', y: '784.01457' },
      { x: '0.85', y: '833.23245' },
      { x: '0.86', y: '885.18264' },
      { x: '0.87', y: '940.01638' },
      { x: '0.88', y: '997.89326' },
      { x: '0.89', y: '1058.98166' },
      { x: '0.9', y: '1123.45930' },
      { x: '0.91', y: '1191.51364' },
      { x: '0.92', y: '1263.34254' },
      { x: '0.93', y: '1339.15472' },
      { x: '0.94', y: '1419.17045' },
      { x: '0.95', y: '1503.62212' },
      { x: '0.96', y: '1592.75494' },
      { x: '0.97', y: '1686.82764' },
      { x: '0.98', y: '1786.11320' },
      { x: '0.99', y: '1890.89963' },
      { x: '1', y: '2001.49084' },
    ],
    supplyRates: [
      { x: '0.01', y: '0.01670' },
      { x: '0.02', y: '0.03482' },
      { x: '0.03', y: '0.05437' },
      { x: '0.04', y: '0.07535' },
      { x: '0.05', y: '0.09776' },
      { x: '0.06', y: '0.12159' },
      { x: '0.07', y: '0.14685' },
      { x: '0.08', y: '0.17354' },
      { x: '0.09', y: '0.20166' },
      { x: '0.1', y: '0.23122' },
      { x: '0.11', y: '0.26221' },
      { x: '0.12', y: '0.29463' },
      { x: '0.13', y: '0.32849' },
      { x: '0.14', y: '0.36379' },
      { x: '0.15', y: '0.40053' },
      { x: '0.16', y: '0.43871' },
      { x: '0.17', y: '0.47833' },
      { x: '0.18', y: '0.51939' },
      { x: '0.19', y: '0.56190' },
      { x: '0.2', y: '0.60586' },
      { x: '0.21', y: '0.65127' },
      { x: '0.22', y: '0.69813' },
      { x: '0.23', y: '0.74644' },
      { x: '0.24', y: '0.79621' },
      { x: '0.25', y: '0.84744' },
      { x: '0.26', y: '0.90013' },
      { x: '0.27', y: '0.95428' },
      { x: '0.28', y: '1.00989' },
      { x: '0.29', y: '1.06697' },
      { x: '0.3', y: '1.12552' },
      { x: '0.31', y: '1.18554' },
      { x: '0.32', y: '1.24704' },
      { x: '0.33', y: '1.31001' },
      { x: '0.34', y: '1.37447' },
      { x: '0.35', y: '1.44040' },
      { x: '0.36', y: '1.50783' },
      { x: '0.37', y: '1.57674' },
      { x: '0.38', y: '1.64714' },
      { x: '0.39', y: '1.71903' },
      { x: '0.4', y: '1.79243' },
      { x: '0.41', y: '1.86732' },
      { x: '0.42', y: '1.94372' },
      { x: '0.43', y: '2.02162' },
      { x: '0.44', y: '2.10103' },
      { x: '0.45', y: '2.18196' },
      { x: '0.46', y: '4.30220' },
      { x: '0.47', y: '6.55919' },
      { x: '0.48', y: '8.95985' },
      { x: '0.49', y: '11.51162' },
      { x: '0.5', y: '14.22253' },
      { x: '0.51', y: '17.10120' },
      { x: '0.52', y: '20.15691' },
      { x: '0.53', y: '23.39964' },
      { x: '0.54', y: '26.84015' },
      { x: '0.55', y: '30.48998' },
      { x: '0.56', y: '34.36158' },
      { x: '0.57', y: '38.46833' },
      { x: '0.58', y: '42.82464' },
      { x: '0.59', y: '47.44602' },
      { x: '0.6', y: '52.34916' },
      { x: '0.61', y: '57.55207' },
      { x: '0.62', y: '63.07414' },
      { x: '0.63', y: '68.93627' },
      { x: '0.64', y: '75.16099' },
      { x: '0.65', y: '81.77262' },
      { x: '0.66', y: '88.79741' },
      { x: '0.67', y: '96.26369' },
      { x: '0.68', y: '104.20206' },
      { x: '0.69', y: '112.64560' },
      { x: '0.7', y: '121.63006' },
      { x: '0.71', y: '131.19413' },
      { x: '0.72', y: '141.37966' },
      { x: '0.73', y: '152.23198' },
      { x: '0.74', y: '163.80017' },
      { x: '0.75', y: '176.13747' },
      { x: '0.76', y: '189.30156' },
      { x: '0.77', y: '203.35507' },
      { x: '0.78', y: '218.36596' },
      { x: '0.79', y: '234.40807' },
      { x: '0.8', y: '251.56162' },
      { x: '0.81', y: '269.91386' },
      { x: '0.82', y: '289.55971' },
      { x: '0.83', y: '310.60249' },
      { x: '0.84', y: '333.15476' },
      { x: '0.85', y: '357.33916' },
      { x: '0.86', y: '383.28946' },
      { x: '0.87', y: '411.15158' },
      { x: '0.88', y: '441.08485' },
      { x: '0.89', y: '473.26332' },
      { x: '0.9', y: '507.87721' },
      { x: '0.91', y: '545.13459' },
      { x: '0.92', y: '585.26311' },
      { x: '0.93', y: '628.51209' },
      { x: '0.94', y: '675.15468' },
      { x: '0.95', y: '725.49032' },
      { x: '0.96', y: '779.84750' },
      { x: '0.97', y: '838.58680' },
      { x: '0.98', y: '902.10423' },
      { x: '0.99', y: '970.83501' },
      { x: '1', y: '1045.25777' },
    ],
  },
  HTM: {
    borrowRates: [
      { x: '0.01', y: '0.08886' },
      { x: '0.02', y: '0.17781' },
      { x: '0.03', y: '0.26684' },
      { x: '0.04', y: '0.35595' },
      { x: '0.05', y: '0.44513' },
      { x: '0.06', y: '0.53439' },
      { x: '0.07', y: '0.62374' },
      { x: '0.08', y: '0.71316' },
      { x: '0.09', y: '0.80266' },
      { x: '0.1', y: '0.89224' },
      { x: '0.11', y: '0.98190' },
      { x: '0.12', y: '1.07164' },
      { x: '0.13', y: '1.16146' },
      { x: '0.14', y: '1.25136' },
      { x: '0.15', y: '1.34134' },
      { x: '0.16', y: '1.43139' },
      { x: '0.17', y: '1.52153' },
      { x: '0.18', y: '1.61175' },
      { x: '0.19', y: '1.70205' },
      { x: '0.2', y: '1.79243' },
      { x: '0.21', y: '1.88288' },
      { x: '0.22', y: '1.97342' },
      { x: '0.23', y: '2.06404' },
      { x: '0.24', y: '2.15474' },
      { x: '0.25', y: '2.24552' },
      { x: '0.26', y: '2.33638' },
      { x: '0.27', y: '2.42732' },
      { x: '0.28', y: '2.51834' },
      { x: '0.29', y: '2.60944' },
      { x: '0.3', y: '2.70062' },
      { x: '0.31', y: '2.79188' },
      { x: '0.32', y: '2.88323' },
      { x: '0.33', y: '2.97465' },
      { x: '0.34', y: '3.06616' },
      { x: '0.35', y: '3.15774' },
      { x: '0.36', y: '3.24941' },
      { x: '0.37', y: '3.34116' },
      { x: '0.38', y: '3.43299' },
      { x: '0.39', y: '3.52490' },
      { x: '0.4', y: '3.61689' },
      { x: '0.41', y: '3.70897' },
      { x: '0.42', y: '3.80112' },
      { x: '0.43', y: '3.89336' },
      { x: '0.44', y: '3.98568' },
      { x: '0.45', y: '4.07808' },
      { x: '0.46', y: '9.90767' },
      { x: '0.47', y: '16.06284' },
      { x: '0.48', y: '22.56172' },
      { x: '0.49', y: '29.42345' },
      { x: '0.5', y: '36.66823' },
      { x: '0.51', y: '44.31738' },
      { x: '0.52', y: '52.39341' },
      { x: '0.53', y: '60.92006' },
      { x: '0.54', y: '69.92242' },
      { x: '0.55', y: '79.42693' },
      { x: '0.56', y: '89.46154' },
      { x: '0.57', y: '100.05572' },
      { x: '0.58', y: '111.24059' },
      { x: '0.59', y: '123.04898' },
      { x: '0.6', y: '135.51555' },
      { x: '0.61', y: '148.67689' },
      { x: '0.62', y: '162.57159' },
      { x: '0.63', y: '177.24041' },
      { x: '0.64', y: '192.72634' },
      { x: '0.65', y: '209.07478' },
      { x: '0.66', y: '226.33363' },
      { x: '0.67', y: '244.55342' },
      { x: '0.68', y: '263.78752' },
      { x: '0.69', y: '284.09222' },
      { x: '0.7', y: '305.52695' },
      { x: '0.71', y: '328.15441' },
      { x: '0.72', y: '352.04077' },
      { x: '0.73', y: '377.25589' },
      { x: '0.74', y: '403.87345' },
      { x: '0.75', y: '431.97124' },
      { x: '0.76', y: '461.63133' },
      { x: '0.77', y: '492.94034' },
      { x: '0.78', y: '525.98967' },
      { x: '0.79', y: '560.87579' },
      { x: '0.8', y: '597.70046' },
      { x: '0.81', y: '636.57112' },
      { x: '0.82', y: '677.60109' },
      { x: '0.83', y: '720.90999' },
      { x: '0.84', y: '766.62403' },
      { x: '0.85', y: '814.87639' },
      { x: '0.86', y: '865.80760' },
      { x: '0.87', y: '919.56596' },
      { x: '0.88', y: '976.30794' },
      { x: '0.89', y: '1036.19866' },
      { x: '0.9', y: '1099.41234' },
      { x: '0.91', y: '1166.13281' },
      { x: '0.92', y: '1236.55406' },
      { x: '0.93', y: '1310.88075' },
      { x: '0.94', y: '1389.32885' },
      { x: '0.95', y: '1472.12623' },
      { x: '0.96', y: '1559.51332' },
      { x: '0.97', y: '1651.74381' },
      { x: '0.98', y: '1749.08535' },
      { x: '0.99', y: '1851.82037' },
      { x: '1', y: '1960.24684' },
    ],
    supplyRates: [
      { x: '0.01', y: '0.00071' },
      { x: '0.02', y: '0.00284' },
      { x: '0.03', y: '0.00639' },
      { x: '0.04', y: '0.01137' },
      { x: '0.05', y: '0.01776' },
      { x: '0.06', y: '0.02558' },
      { x: '0.07', y: '0.03482' },
      { x: '0.08', y: '0.04549' },
      { x: '0.09', y: '0.05757' },
      { x: '0.1', y: '0.07108' },
      { x: '0.11', y: '0.08602' },
      { x: '0.12', y: '0.10238' },
      { x: '0.13', y: '0.12017' },
      { x: '0.14', y: '0.13938' },
      { x: '0.15', y: '0.16002' },
      { x: '0.16', y: '0.18208' },
      { x: '0.17', y: '0.20558' },
      { x: '0.18', y: '0.23051' },
      { x: '0.19', y: '0.25686' },
      { x: '0.2', y: '0.28465' },
      { x: '0.21', y: '0.31388' },
      { x: '0.22', y: '0.34454' },
      { x: '0.23', y: '0.37663' },
      { x: '0.24', y: '0.41016' },
      { x: '0.25', y: '0.44513' },
      { x: '0.26', y: '0.48154' },
      { x: '0.27', y: '0.51939' },
      { x: '0.28', y: '0.55869' },
      { x: '0.29', y: '0.59943' },
      { x: '0.3', y: '0.64161' },
      { x: '0.31', y: '0.68525' },
      { x: '0.32', y: '0.73034' },
      { x: '0.33', y: '0.77688' },
      { x: '0.34', y: '0.82487' },
      { x: '0.35', y: '0.87432' },
      { x: '0.36', y: '0.92523' },
      { x: '0.37', y: '0.97760' },
      { x: '0.38', y: '1.03143' },
      { x: '0.39', y: '1.08672' },
      { x: '0.4', y: '1.14349' },
      { x: '0.41', y: '1.20172' },
      { x: '0.42', y: '1.26143' },
      { x: '0.43', y: '1.32261' },
      { x: '0.44', y: '1.38527' },
      { x: '0.45', y: '1.44942' },
      { x: '0.46', y: '3.53794' },
      { x: '0.47', y: '5.76153' },
      { x: '0.48', y: '8.12698' },
      { x: '0.49', y: '10.64162' },
      { x: '0.5', y: '13.31332' },
      { x: '0.51', y: '16.15057' },
      { x: '0.52', y: '19.16249' },
      { x: '0.53', y: '22.35891' },
      { x: '0.54', y: '25.75038' },
      { x: '0.55', y: '29.34828' },
      { x: '0.56', y: '33.16482' },
      { x: '0.57', y: '37.21317' },
      { x: '0.58', y: '41.50749' },
      { x: '0.59', y: '46.06304' },
      { x: '0.6', y: '50.89622' },
      { x: '0.61', y: '56.02473' },
      { x: '0.62', y: '61.46763' },
      { x: '0.63', y: '67.24545' },
      { x: '0.64', y: '73.38037' },
      { x: '0.65', y: '79.89625' },
      { x: '0.66', y: '86.81891' },
      { x: '0.67', y: '94.17617' },
      { x: '0.68', y: '101.99810' },
      { x: '0.69', y: '110.31720' },
      { x: '0.7', y: '119.16859' },
      { x: '0.71', y: '128.59026' },
      { x: '0.72', y: '138.62333' },
      { x: '0.73', y: '149.31230' },
      { x: '0.74', y: '160.70538' },
      { x: '0.75', y: '172.85483' },
      { x: '0.76', y: '185.81730' },
      { x: '0.77', y: '199.65425' },
      { x: '0.78', y: '214.43240' },
      { x: '0.79', y: '230.22422' },
      { x: '0.8', y: '247.10844' },
      { x: '0.81', y: '265.17068' },
      { x: '0.82', y: '284.50407' },
      { x: '0.83', y: '305.20998' },
      { x: '0.84', y: '327.39882' },
      { x: '0.85', y: '351.19089' },
      { x: '0.86', y: '376.71740' },
      { x: '0.87', y: '404.12144' },
      { x: '0.88', y: '433.55924' },
      { x: '0.89', y: '465.20146' },
      { x: '0.9', y: '499.23459' },
      { x: '0.91', y: '535.86257' },
      { x: '0.92', y: '575.30855' },
      { x: '0.93', y: '617.81687' },
      { x: '0.94', y: '663.65518' },
      { x: '0.95', y: '713.11690' },
      { x: '0.96', y: '766.52386' },
      { x: '0.97', y: '824.22926' },
      { x: '0.98', y: '886.62102' },
      { x: '0.99', y: '954.12537' },
      { x: '1', y: '1027.21102' },
    ],
  },
  USDC: {
    borrowRates: [
      { x: '0.01', y: '0.04997' },
      { x: '0.02', y: '0.09998' },
      { x: '0.03', y: '0.15001' },
      { x: '0.04', y: '0.20006' },
      { x: '0.05', y: '0.25014' },
      { x: '0.06', y: '0.30024' },
      { x: '0.07', y: '0.35037' },
      { x: '0.08', y: '0.40053' },
      { x: '0.09', y: '0.45071' },
      { x: '0.1', y: '0.50091' },
      { x: '0.11', y: '0.55114' },
      { x: '0.12', y: '0.60139' },
      { x: '0.13', y: '0.65167' },
      { x: '0.14', y: '0.70198' },
      { x: '0.15', y: '0.75231' },
      { x: '0.16', y: '0.80266' },
      { x: '0.17', y: '0.85304' },
      { x: '0.18', y: '0.90344' },
      { x: '0.19', y: '0.95387' },
      { x: '0.2', y: '1.00433' },
      { x: '0.21', y: '1.05481' },
      { x: '0.22', y: '1.10531' },
      { x: '0.23', y: '1.15584' },
      { x: '0.24', y: '1.20640' },
      { x: '0.25', y: '1.25698' },
      { x: '0.26', y: '1.30758' },
      { x: '0.27', y: '1.35822' },
      { x: '0.28', y: '1.40887' },
      { x: '0.29', y: '1.45955' },
      { x: '0.3', y: '1.51026' },
      { x: '0.31', y: '1.56099' },
      { x: '0.32', y: '1.61175' },
      { x: '0.33', y: '1.66253' },
      { x: '0.34', y: '1.71334' },
      { x: '0.35', y: '1.76417' },
      { x: '0.36', y: '1.81503' },
      { x: '0.37', y: '1.86592' },
      { x: '0.38', y: '1.91683' },
      { x: '0.39', y: '1.96776' },
      { x: '0.4', y: '2.01872' },
      { x: '0.41', y: '2.06971' },
      { x: '0.42', y: '2.12072' },
      { x: '0.43', y: '2.17175' },
      { x: '0.44', y: '2.22281' },
      { x: '0.45', y: '2.27390' },
      { x: '0.46', y: '2.32501' },
      { x: '0.47', y: '2.37615' },
      { x: '0.48', y: '2.42732' },
      { x: '0.49', y: '2.47851' },
      { x: '0.5', y: '2.52972' },
      { x: '0.51', y: '2.58096' },
      { x: '0.52', y: '2.63223' },
      { x: '0.53', y: '2.68352' },
      { x: '0.54', y: '2.73483' },
      { x: '0.55', y: '2.78618' },
      { x: '0.56', y: '2.83754' },
      { x: '0.57', y: '2.88894' },
      { x: '0.58', y: '2.94036' },
      { x: '0.59', y: '2.99180' },
      { x: '0.6', y: '3.04327' },
      { x: '0.61', y: '3.09477' },
      { x: '0.62', y: '3.14629' },
      { x: '0.63', y: '3.19784' },
      { x: '0.64', y: '3.24941' },
      { x: '0.65', y: '3.30101' },
      { x: '0.66', y: '3.35263' },
      { x: '0.67', y: '3.40428' },
      { x: '0.68', y: '3.45596' },
      { x: '0.69', y: '3.50766' },
      { x: '0.7', y: '3.55939' },
      { x: '0.71', y: '3.61114' },
      { x: '0.72', y: '3.66292' },
      { x: '0.73', y: '3.71473' },
      { x: '0.74', y: '3.76656' },
      { x: '0.75', y: '3.81841' },
      { x: '0.76', y: '3.87030' },
      { x: '0.77', y: '3.92220' },
      { x: '0.78', y: '3.97414' },
      { x: '0.79', y: '4.02610' },
      { x: '0.8', y: '4.07808' },
      { x: '0.81', y: '8.05178' },
      { x: '0.82', y: '12.17675' },
      { x: '0.83', y: '16.45876' },
      { x: '0.84', y: '20.90375' },
      { x: '0.85', y: '25.51792' },
      { x: '0.86', y: '30.30768' },
      { x: '0.87', y: '35.27969' },
      { x: '0.88', y: '40.44088' },
      { x: '0.89', y: '45.79842' },
      { x: '0.9', y: '51.35976' },
      { x: '0.91', y: '57.13263' },
      { x: '0.92', y: '63.12504' },
      { x: '0.93', y: '69.34534' },
      { x: '0.94', y: '75.80215' },
      { x: '0.95', y: '82.50445' },
      { x: '0.96', y: '89.46154' },
      { x: '0.97', y: '96.68309' },
      { x: '0.98', y: '104.17910' },
      { x: '0.99', y: '111.96000' },
      { x: '1', y: '120.03656' },
    ],
    supplyRates: [
      { x: '0.01', y: '0.00032' },
      { x: '0.02', y: '0.00129' },
      { x: '0.03', y: '0.00292' },
      { x: '0.04', y: '0.00519' },
      { x: '0.05', y: '0.00811' },
      { x: '0.06', y: '0.01169' },
      { x: '0.07', y: '0.01591' },
      { x: '0.08', y: '0.02078' },
      { x: '0.09', y: '0.02631' },
      { x: '0.1', y: '0.03248' },
      { x: '0.11', y: '0.03930' },
      { x: '0.12', y: '0.04677' },
      { x: '0.13', y: '0.05490' },
      { x: '0.14', y: '0.06367' },
      { x: '0.15', y: '0.07310' },
      { x: '0.16', y: '0.08317' },
      { x: '0.17', y: '0.09390' },
      { x: '0.18', y: '0.10528' },
      { x: '0.19', y: '0.11731' },
      { x: '0.2', y: '0.12999' },
      { x: '0.21', y: '0.14333' },
      { x: '0.22', y: '0.15731' },
      { x: '0.23', y: '0.17195' },
      { x: '0.24', y: '0.18725' },
      { x: '0.25', y: '0.20319' },
      { x: '0.26', y: '0.21979' },
      { x: '0.27', y: '0.23704' },
      { x: '0.28', y: '0.25495' },
      { x: '0.29', y: '0.27351' },
      { x: '0.3', y: '0.29273' },
      { x: '0.31', y: '0.31260' },
      { x: '0.32', y: '0.33313' },
      { x: '0.33', y: '0.35431' },
      { x: '0.34', y: '0.37615' },
      { x: '0.35', y: '0.39865' },
      { x: '0.36', y: '0.42180' },
      { x: '0.37', y: '0.44561' },
      { x: '0.38', y: '0.47008' },
      { x: '0.39', y: '0.49521' },
      { x: '0.4', y: '0.52100' },
      { x: '0.41', y: '0.54745' },
      { x: '0.42', y: '0.57455' },
      { x: '0.43', y: '0.60232' },
      { x: '0.44', y: '0.63075' },
      { x: '0.45', y: '0.65985' },
      { x: '0.46', y: '0.68960' },
      { x: '0.47', y: '0.72002' },
      { x: '0.48', y: '0.75110' },
      { x: '0.49', y: '0.78284' },
      { x: '0.5', y: '0.81525' },
      { x: '0.51', y: '0.84833' },
      { x: '0.52', y: '0.88207' },
      { x: '0.53', y: '0.91648' },
      { x: '0.54', y: '0.95155' },
      { x: '0.55', y: '0.98730' },
      { x: '0.56', y: '1.02371' },
      { x: '0.57', y: '1.06079' },
      { x: '0.58', y: '1.09854' },
      { x: '0.59', y: '1.13697' },
      { x: '0.6', y: '1.17606' },
      { x: '0.61', y: '1.21583' },
      { x: '0.62', y: '1.25627' },
      { x: '0.63', y: '1.29739' },
      { x: '0.64', y: '1.33918' },
      { x: '0.65', y: '1.38164' },
      { x: '0.66', y: '1.42478' },
      { x: '0.67', y: '1.46860' },
      { x: '0.68', y: '1.51310' },
      { x: '0.69', y: '1.55828' },
      { x: '0.7', y: '1.60413' },
      { x: '0.71', y: '1.65067' },
      { x: '0.72', y: '1.69789' },
      { x: '0.73', y: '1.74579' },
      { x: '0.74', y: '1.79438' },
      { x: '0.75', y: '1.84365' },
      { x: '0.76', y: '1.89361' },
      { x: '0.77', y: '1.94425' },
      { x: '0.78', y: '1.99558' },
      { x: '0.79', y: '2.04760' },
      { x: '0.8', y: '2.10031' },
      { x: '0.81', y: '4.16171' },
      { x: '0.82', y: '6.31638' },
      { x: '0.83', y: '8.56837' },
      { x: '0.84', y: '10.92193' },
      { x: '0.85', y: '13.38158' },
      { x: '0.86', y: '15.95207' },
      { x: '0.87', y: '18.63843' },
      { x: '0.88', y: '21.44597' },
      { x: '0.89', y: '24.38031' },
      { x: '0.9', y: '27.44738' },
      { x: '0.91', y: '30.65346' },
      { x: '0.92', y: '34.00518' },
      { x: '0.93', y: '37.50956' },
      { x: '0.94', y: '41.17402' },
      { x: '0.95', y: '45.00641' },
      { x: '0.96', y: '49.01505' },
      { x: '0.97', y: '53.20873' },
      { x: '0.98', y: '57.59675' },
      { x: '0.99', y: '62.18900' },
      { x: '1', y: '66.99590' },
    ],
  },
  USDT: {
    borrowRates: [
      { x: '0.01', y: '0.04997' },
      { x: '0.02', y: '0.09998' },
      { x: '0.03', y: '0.15001' },
      { x: '0.04', y: '0.20006' },
      { x: '0.05', y: '0.25014' },
      { x: '0.06', y: '0.30024' },
      { x: '0.07', y: '0.35037' },
      { x: '0.08', y: '0.40053' },
      { x: '0.09', y: '0.45071' },
      { x: '0.1', y: '0.50091' },
      { x: '0.11', y: '0.55114' },
      { x: '0.12', y: '0.60139' },
      { x: '0.13', y: '0.65167' },
      { x: '0.14', y: '0.70198' },
      { x: '0.15', y: '0.75231' },
      { x: '0.16', y: '0.80266' },
      { x: '0.17', y: '0.85304' },
      { x: '0.18', y: '0.90344' },
      { x: '0.19', y: '0.95387' },
      { x: '0.2', y: '1.00433' },
      { x: '0.21', y: '1.05481' },
      { x: '0.22', y: '1.10531' },
      { x: '0.23', y: '1.15584' },
      { x: '0.24', y: '1.20640' },
      { x: '0.25', y: '1.25698' },
      { x: '0.26', y: '1.30758' },
      { x: '0.27', y: '1.35822' },
      { x: '0.28', y: '1.40887' },
      { x: '0.29', y: '1.45955' },
      { x: '0.3', y: '1.51026' },
      { x: '0.31', y: '1.56099' },
      { x: '0.32', y: '1.61175' },
      { x: '0.33', y: '1.66253' },
      { x: '0.34', y: '1.71334' },
      { x: '0.35', y: '1.76417' },
      { x: '0.36', y: '1.81503' },
      { x: '0.37', y: '1.86592' },
      { x: '0.38', y: '1.91683' },
      { x: '0.39', y: '1.96776' },
      { x: '0.4', y: '2.01872' },
      { x: '0.41', y: '2.06971' },
      { x: '0.42', y: '2.12072' },
      { x: '0.43', y: '2.17175' },
      { x: '0.44', y: '2.22281' },
      { x: '0.45', y: '2.27390' },
      { x: '0.46', y: '2.32501' },
      { x: '0.47', y: '2.37615' },
      { x: '0.48', y: '2.42732' },
      { x: '0.49', y: '2.47851' },
      { x: '0.5', y: '2.52972' },
      { x: '0.51', y: '2.58096' },
      { x: '0.52', y: '2.63223' },
      { x: '0.53', y: '2.68352' },
      { x: '0.54', y: '2.73483' },
      { x: '0.55', y: '2.78618' },
      { x: '0.56', y: '2.83754' },
      { x: '0.57', y: '2.88894' },
      { x: '0.58', y: '2.94036' },
      { x: '0.59', y: '2.99180' },
      { x: '0.6', y: '3.04327' },
      { x: '0.61', y: '3.09477' },
      { x: '0.62', y: '3.14629' },
      { x: '0.63', y: '3.19784' },
      { x: '0.64', y: '3.24941' },
      { x: '0.65', y: '3.30101' },
      { x: '0.66', y: '3.35263' },
      { x: '0.67', y: '3.40428' },
      { x: '0.68', y: '3.45596' },
      { x: '0.69', y: '3.50766' },
      { x: '0.7', y: '3.55939' },
      { x: '0.71', y: '3.61114' },
      { x: '0.72', y: '3.66292' },
      { x: '0.73', y: '3.71473' },
      { x: '0.74', y: '3.76656' },
      { x: '0.75', y: '3.81841' },
      { x: '0.76', y: '3.87030' },
      { x: '0.77', y: '3.92220' },
      { x: '0.78', y: '3.97414' },
      { x: '0.79', y: '4.02610' },
      { x: '0.8', y: '4.07808' },
      { x: '0.81', y: '8.05178' },
      { x: '0.82', y: '12.17675' },
      { x: '0.83', y: '16.45876' },
      { x: '0.84', y: '20.90375' },
      { x: '0.85', y: '25.51792' },
      { x: '0.86', y: '30.30768' },
      { x: '0.87', y: '35.27969' },
      { x: '0.88', y: '40.44088' },
      { x: '0.89', y: '45.79842' },
      { x: '0.9', y: '51.35976' },
      { x: '0.91', y: '57.13263' },
      { x: '0.92', y: '63.12504' },
      { x: '0.93', y: '69.34534' },
      { x: '0.94', y: '75.80215' },
      { x: '0.95', y: '82.50445' },
      { x: '0.96', y: '89.46154' },
      { x: '0.97', y: '96.68309' },
      { x: '0.98', y: '104.17910' },
      { x: '0.99', y: '111.96000' },
      { x: '1', y: '120.03656' },
    ],
    supplyRates: [
      { x: '0.01', y: '0.00032' },
      { x: '0.02', y: '0.00129' },
      { x: '0.03', y: '0.00292' },
      { x: '0.04', y: '0.00519' },
      { x: '0.05', y: '0.00811' },
      { x: '0.06', y: '0.01169' },
      { x: '0.07', y: '0.01591' },
      { x: '0.08', y: '0.02078' },
      { x: '0.09', y: '0.02631' },
      { x: '0.1', y: '0.03248' },
      { x: '0.11', y: '0.03930' },
      { x: '0.12', y: '0.04677' },
      { x: '0.13', y: '0.05490' },
      { x: '0.14', y: '0.06367' },
      { x: '0.15', y: '0.07310' },
      { x: '0.16', y: '0.08317' },
      { x: '0.17', y: '0.09390' },
      { x: '0.18', y: '0.10528' },
      { x: '0.19', y: '0.11731' },
      { x: '0.2', y: '0.12999' },
      { x: '0.21', y: '0.14333' },
      { x: '0.22', y: '0.15731' },
      { x: '0.23', y: '0.17195' },
      { x: '0.24', y: '0.18725' },
      { x: '0.25', y: '0.20319' },
      { x: '0.26', y: '0.21979' },
      { x: '0.27', y: '0.23704' },
      { x: '0.28', y: '0.25495' },
      { x: '0.29', y: '0.27351' },
      { x: '0.3', y: '0.29273' },
      { x: '0.31', y: '0.31260' },
      { x: '0.32', y: '0.33313' },
      { x: '0.33', y: '0.35431' },
      { x: '0.34', y: '0.37615' },
      { x: '0.35', y: '0.39865' },
      { x: '0.36', y: '0.42180' },
      { x: '0.37', y: '0.44561' },
      { x: '0.38', y: '0.47008' },
      { x: '0.39', y: '0.49521' },
      { x: '0.4', y: '0.52100' },
      { x: '0.41', y: '0.54745' },
      { x: '0.42', y: '0.57455' },
      { x: '0.43', y: '0.60232' },
      { x: '0.44', y: '0.63075' },
      { x: '0.45', y: '0.65985' },
      { x: '0.46', y: '0.68960' },
      { x: '0.47', y: '0.72002' },
      { x: '0.48', y: '0.75110' },
      { x: '0.49', y: '0.78284' },
      { x: '0.5', y: '0.81525' },
      { x: '0.51', y: '0.84833' },
      { x: '0.52', y: '0.88207' },
      { x: '0.53', y: '0.91648' },
      { x: '0.54', y: '0.95155' },
      { x: '0.55', y: '0.98730' },
      { x: '0.56', y: '1.02371' },
      { x: '0.57', y: '1.06079' },
      { x: '0.58', y: '1.09854' },
      { x: '0.59', y: '1.13697' },
      { x: '0.6', y: '1.17606' },
      { x: '0.61', y: '1.21583' },
      { x: '0.62', y: '1.25627' },
      { x: '0.63', y: '1.29739' },
      { x: '0.64', y: '1.33918' },
      { x: '0.65', y: '1.38164' },
      { x: '0.66', y: '1.42478' },
      { x: '0.67', y: '1.46860' },
      { x: '0.68', y: '1.51310' },
      { x: '0.69', y: '1.55828' },
      { x: '0.7', y: '1.60413' },
      { x: '0.71', y: '1.65067' },
      { x: '0.72', y: '1.69789' },
      { x: '0.73', y: '1.74579' },
      { x: '0.74', y: '1.79438' },
      { x: '0.75', y: '1.84365' },
      { x: '0.76', y: '1.89361' },
      { x: '0.77', y: '1.94425' },
      { x: '0.78', y: '1.99558' },
      { x: '0.79', y: '2.04760' },
      { x: '0.8', y: '2.10031' },
      { x: '0.81', y: '4.16171' },
      { x: '0.82', y: '6.31638' },
      { x: '0.83', y: '8.56837' },
      { x: '0.84', y: '10.92193' },
      { x: '0.85', y: '13.38158' },
      { x: '0.86', y: '15.95207' },
      { x: '0.87', y: '18.63843' },
      { x: '0.88', y: '21.44597' },
      { x: '0.89', y: '24.38031' },
      { x: '0.9', y: '27.44738' },
      { x: '0.91', y: '30.65346' },
      { x: '0.92', y: '34.00518' },
      { x: '0.93', y: '37.50956' },
      { x: '0.94', y: '41.17402' },
      { x: '0.95', y: '45.00641' },
      { x: '0.96', y: '49.01505' },
      { x: '0.97', y: '53.20873' },
      { x: '0.98', y: '57.59675' },
      { x: '0.99', y: '62.18900' },
      { x: '1', y: '66.99590' },
    ],
  },
  UTK: {
    borrowRates: [
      { x: '0.01', y: '0.08886' },
      { x: '0.02', y: '0.17781' },
      { x: '0.03', y: '0.26684' },
      { x: '0.04', y: '0.35595' },
      { x: '0.05', y: '0.44513' },
      { x: '0.06', y: '0.53439' },
      { x: '0.07', y: '0.62374' },
      { x: '0.08', y: '0.71316' },
      { x: '0.09', y: '0.80266' },
      { x: '0.1', y: '0.89224' },
      { x: '0.11', y: '0.98190' },
      { x: '0.12', y: '1.07164' },
      { x: '0.13', y: '1.16146' },
      { x: '0.14', y: '1.25136' },
      { x: '0.15', y: '1.34134' },
      { x: '0.16', y: '1.43139' },
      { x: '0.17', y: '1.52153' },
      { x: '0.18', y: '1.61175' },
      { x: '0.19', y: '1.70205' },
      { x: '0.2', y: '1.79243' },
      { x: '0.21', y: '1.88288' },
      { x: '0.22', y: '1.97342' },
      { x: '0.23', y: '2.06404' },
      { x: '0.24', y: '2.15474' },
      { x: '0.25', y: '2.24552' },
      { x: '0.26', y: '2.33638' },
      { x: '0.27', y: '2.42732' },
      { x: '0.28', y: '2.51834' },
      { x: '0.29', y: '2.60944' },
      { x: '0.3', y: '2.70062' },
      { x: '0.31', y: '2.79188' },
      { x: '0.32', y: '2.88323' },
      { x: '0.33', y: '2.97465' },
      { x: '0.34', y: '3.06616' },
      { x: '0.35', y: '3.15774' },
      { x: '0.36', y: '3.24941' },
      { x: '0.37', y: '3.34116' },
      { x: '0.38', y: '3.43299' },
      { x: '0.39', y: '3.52490' },
      { x: '0.4', y: '3.61689' },
      { x: '0.41', y: '3.70897' },
      { x: '0.42', y: '3.80112' },
      { x: '0.43', y: '3.89336' },
      { x: '0.44', y: '3.98568' },
      { x: '0.45', y: '4.07808' },
      { x: '0.46', y: '9.90767' },
      { x: '0.47', y: '16.06284' },
      { x: '0.48', y: '22.56172' },
      { x: '0.49', y: '29.42345' },
      { x: '0.5', y: '36.66823' },
      { x: '0.51', y: '44.31738' },
      { x: '0.52', y: '52.39341' },
      { x: '0.53', y: '60.92006' },
      { x: '0.54', y: '69.92242' },
      { x: '0.55', y: '79.42693' },
      { x: '0.56', y: '89.46154' },
      { x: '0.57', y: '100.05572' },
      { x: '0.58', y: '111.24059' },
      { x: '0.59', y: '123.04898' },
      { x: '0.6', y: '135.51555' },
      { x: '0.61', y: '148.67689' },
      { x: '0.62', y: '162.57159' },
      { x: '0.63', y: '177.24041' },
      { x: '0.64', y: '192.72634' },
      { x: '0.65', y: '209.07478' },
      { x: '0.66', y: '226.33363' },
      { x: '0.67', y: '244.55342' },
      { x: '0.68', y: '263.78752' },
      { x: '0.69', y: '284.09222' },
      { x: '0.7', y: '305.52695' },
      { x: '0.71', y: '328.15441' },
      { x: '0.72', y: '352.04077' },
      { x: '0.73', y: '377.25589' },
      { x: '0.74', y: '403.87345' },
      { x: '0.75', y: '431.97124' },
      { x: '0.76', y: '461.63133' },
      { x: '0.77', y: '492.94034' },
      { x: '0.78', y: '525.98967' },
      { x: '0.79', y: '560.87579' },
      { x: '0.8', y: '597.70046' },
      { x: '0.81', y: '636.57112' },
      { x: '0.82', y: '677.60109' },
      { x: '0.83', y: '720.90999' },
      { x: '0.84', y: '766.62403' },
      { x: '0.85', y: '814.87639' },
      { x: '0.86', y: '865.80760' },
      { x: '0.87', y: '919.56596' },
      { x: '0.88', y: '976.30794' },
      { x: '0.89', y: '1036.19866' },
      { x: '0.9', y: '1099.41234' },
      { x: '0.91', y: '1166.13281' },
      { x: '0.92', y: '1236.55406' },
      { x: '0.93', y: '1310.88075' },
      { x: '0.94', y: '1389.32885' },
      { x: '0.95', y: '1472.12623' },
      { x: '0.96', y: '1559.51332' },
      { x: '0.97', y: '1651.74381' },
      { x: '0.98', y: '1749.08535' },
      { x: '0.99', y: '1851.82037' },
      { x: '1', y: '1960.24684' },
    ],
    supplyRates: [
      { x: '0.01', y: '0.00071' },
      { x: '0.02', y: '0.00284' },
      { x: '0.03', y: '0.00639' },
      { x: '0.04', y: '0.01137' },
      { x: '0.05', y: '0.01776' },
      { x: '0.06', y: '0.02558' },
      { x: '0.07', y: '0.03482' },
      { x: '0.08', y: '0.04549' },
      { x: '0.09', y: '0.05757' },
      { x: '0.1', y: '0.07108' },
      { x: '0.11', y: '0.08602' },
      { x: '0.12', y: '0.10238' },
      { x: '0.13', y: '0.12017' },
      { x: '0.14', y: '0.13938' },
      { x: '0.15', y: '0.16002' },
      { x: '0.16', y: '0.18208' },
      { x: '0.17', y: '0.20558' },
      { x: '0.18', y: '0.23051' },
      { x: '0.19', y: '0.25686' },
      { x: '0.2', y: '0.28465' },
      { x: '0.21', y: '0.31388' },
      { x: '0.22', y: '0.34454' },
      { x: '0.23', y: '0.37663' },
      { x: '0.24', y: '0.41016' },
      { x: '0.25', y: '0.44513' },
      { x: '0.26', y: '0.48154' },
      { x: '0.27', y: '0.51939' },
      { x: '0.28', y: '0.55869' },
      { x: '0.29', y: '0.59943' },
      { x: '0.3', y: '0.64161' },
      { x: '0.31', y: '0.68525' },
      { x: '0.32', y: '0.73034' },
      { x: '0.33', y: '0.77688' },
      { x: '0.34', y: '0.82487' },
      { x: '0.35', y: '0.87432' },
      { x: '0.36', y: '0.92523' },
      { x: '0.37', y: '0.97760' },
      { x: '0.38', y: '1.03143' },
      { x: '0.39', y: '1.08672' },
      { x: '0.4', y: '1.14349' },
      { x: '0.41', y: '1.20172' },
      { x: '0.42', y: '1.26143' },
      { x: '0.43', y: '1.32261' },
      { x: '0.44', y: '1.38527' },
      { x: '0.45', y: '1.44942' },
      { x: '0.46', y: '3.53794' },
      { x: '0.47', y: '5.76153' },
      { x: '0.48', y: '8.12698' },
      { x: '0.49', y: '10.64162' },
      { x: '0.5', y: '13.31332' },
      { x: '0.51', y: '16.15057' },
      { x: '0.52', y: '19.16249' },
      { x: '0.53', y: '22.35891' },
      { x: '0.54', y: '25.75038' },
      { x: '0.55', y: '29.34828' },
      { x: '0.56', y: '33.16482' },
      { x: '0.57', y: '37.21317' },
      { x: '0.58', y: '41.50749' },
      { x: '0.59', y: '46.06304' },
      { x: '0.6', y: '50.89622' },
      { x: '0.61', y: '56.02473' },
      { x: '0.62', y: '61.46763' },
      { x: '0.63', y: '67.24545' },
      { x: '0.64', y: '73.38037' },
      { x: '0.65', y: '79.89625' },
      { x: '0.66', y: '86.81891' },
      { x: '0.67', y: '94.17617' },
      { x: '0.68', y: '101.99810' },
      { x: '0.69', y: '110.31720' },
      { x: '0.7', y: '119.16859' },
      { x: '0.71', y: '128.59026' },
      { x: '0.72', y: '138.62333' },
      { x: '0.73', y: '149.31230' },
      { x: '0.74', y: '160.70538' },
      { x: '0.75', y: '172.85483' },
      { x: '0.76', y: '185.81730' },
      { x: '0.77', y: '199.65425' },
      { x: '0.78', y: '214.43240' },
      { x: '0.79', y: '230.22422' },
      { x: '0.8', y: '247.10844' },
      { x: '0.81', y: '265.17068' },
      { x: '0.82', y: '284.50407' },
      { x: '0.83', y: '305.20998' },
      { x: '0.84', y: '327.39882' },
      { x: '0.85', y: '351.19089' },
      { x: '0.86', y: '376.71740' },
      { x: '0.87', y: '404.12144' },
      { x: '0.88', y: '433.55924' },
      { x: '0.89', y: '465.20146' },
      { x: '0.9', y: '499.23459' },
      { x: '0.91', y: '535.86257' },
      { x: '0.92', y: '575.30855' },
      { x: '0.93', y: '617.81687' },
      { x: '0.94', y: '663.65518' },
      { x: '0.95', y: '713.11690' },
      { x: '0.96', y: '766.52386' },
      { x: '0.97', y: '824.22926' },
      { x: '0.98', y: '886.62102' },
      { x: '0.99', y: '954.12537' },
      { x: '1', y: '1027.21102' },
    ],
  },
  WETH: {
    borrowRates: [
      { x: '0.01', y: '0.08886' },
      { x: '0.02', y: '0.17781' },
      { x: '0.03', y: '0.26684' },
      { x: '0.04', y: '0.35595' },
      { x: '0.05', y: '0.44513' },
      { x: '0.06', y: '0.53439' },
      { x: '0.07', y: '0.62374' },
      { x: '0.08', y: '0.71316' },
      { x: '0.09', y: '0.80266' },
      { x: '0.1', y: '0.89224' },
      { x: '0.11', y: '0.98190' },
      { x: '0.12', y: '1.07164' },
      { x: '0.13', y: '1.16146' },
      { x: '0.14', y: '1.25136' },
      { x: '0.15', y: '1.34134' },
      { x: '0.16', y: '1.43139' },
      { x: '0.17', y: '1.52153' },
      { x: '0.18', y: '1.61175' },
      { x: '0.19', y: '1.70205' },
      { x: '0.2', y: '1.79243' },
      { x: '0.21', y: '1.88288' },
      { x: '0.22', y: '1.97342' },
      { x: '0.23', y: '2.06404' },
      { x: '0.24', y: '2.15474' },
      { x: '0.25', y: '2.24552' },
      { x: '0.26', y: '2.33638' },
      { x: '0.27', y: '2.42732' },
      { x: '0.28', y: '2.51834' },
      { x: '0.29', y: '2.60944' },
      { x: '0.3', y: '2.70062' },
      { x: '0.31', y: '2.79188' },
      { x: '0.32', y: '2.88323' },
      { x: '0.33', y: '2.97465' },
      { x: '0.34', y: '3.06616' },
      { x: '0.35', y: '3.15774' },
      { x: '0.36', y: '3.24941' },
      { x: '0.37', y: '3.34116' },
      { x: '0.38', y: '3.43299' },
      { x: '0.39', y: '3.52490' },
      { x: '0.4', y: '3.61689' },
      { x: '0.41', y: '3.70897' },
      { x: '0.42', y: '3.80112' },
      { x: '0.43', y: '3.89336' },
      { x: '0.44', y: '3.98568' },
      { x: '0.45', y: '4.07808' },
      { x: '0.46', y: '9.90767' },
      { x: '0.47', y: '16.06284' },
      { x: '0.48', y: '22.56172' },
      { x: '0.49', y: '29.42345' },
      { x: '0.5', y: '36.66823' },
      { x: '0.51', y: '44.31738' },
      { x: '0.52', y: '52.39341' },
      { x: '0.53', y: '60.92006' },
      { x: '0.54', y: '69.92242' },
      { x: '0.55', y: '79.42693' },
      { x: '0.56', y: '89.46154' },
      { x: '0.57', y: '100.05572' },
      { x: '0.58', y: '111.24059' },
      { x: '0.59', y: '123.04898' },
      { x: '0.6', y: '135.51555' },
      { x: '0.61', y: '148.67689' },
      { x: '0.62', y: '162.57159' },
      { x: '0.63', y: '177.24041' },
      { x: '0.64', y: '192.72634' },
      { x: '0.65', y: '209.07478' },
      { x: '0.66', y: '226.33363' },
      { x: '0.67', y: '244.55342' },
      { x: '0.68', y: '263.78752' },
      { x: '0.69', y: '284.09222' },
      { x: '0.7', y: '305.52695' },
      { x: '0.71', y: '328.15441' },
      { x: '0.72', y: '352.04077' },
      { x: '0.73', y: '377.25589' },
      { x: '0.74', y: '403.87345' },
      { x: '0.75', y: '431.97124' },
      { x: '0.76', y: '461.63133' },
      { x: '0.77', y: '492.94034' },
      { x: '0.78', y: '525.98967' },
      { x: '0.79', y: '560.87579' },
      { x: '0.8', y: '597.70046' },
      { x: '0.81', y: '636.57112' },
      { x: '0.82', y: '677.60109' },
      { x: '0.83', y: '720.90999' },
      { x: '0.84', y: '766.62403' },
      { x: '0.85', y: '814.87639' },
      { x: '0.86', y: '865.80760' },
      { x: '0.87', y: '919.56596' },
      { x: '0.88', y: '976.30794' },
      { x: '0.89', y: '1036.19866' },
      { x: '0.9', y: '1099.41234' },
      { x: '0.91', y: '1166.13281' },
      { x: '0.92', y: '1236.55406' },
      { x: '0.93', y: '1310.88075' },
      { x: '0.94', y: '1389.32885' },
      { x: '0.95', y: '1472.12623' },
      { x: '0.96', y: '1559.51332' },
      { x: '0.97', y: '1651.74381' },
      { x: '0.98', y: '1749.08535' },
      { x: '0.99', y: '1851.82037' },
      { x: '1', y: '1960.24684' },
    ],
    supplyRates: [
      { x: '0.01', y: '0.00071' },
      { x: '0.02', y: '0.00284' },
      { x: '0.03', y: '0.00639' },
      { x: '0.04', y: '0.01137' },
      { x: '0.05', y: '0.01776' },
      { x: '0.06', y: '0.02558' },
      { x: '0.07', y: '0.03482' },
      { x: '0.08', y: '0.04549' },
      { x: '0.09', y: '0.05757' },
      { x: '0.1', y: '0.07108' },
      { x: '0.11', y: '0.08602' },
      { x: '0.12', y: '0.10238' },
      { x: '0.13', y: '0.12017' },
      { x: '0.14', y: '0.13938' },
      { x: '0.15', y: '0.16002' },
      { x: '0.16', y: '0.18208' },
      { x: '0.17', y: '0.20558' },
      { x: '0.18', y: '0.23051' },
      { x: '0.19', y: '0.25686' },
      { x: '0.2', y: '0.28465' },
      { x: '0.21', y: '0.31388' },
      { x: '0.22', y: '0.34454' },
      { x: '0.23', y: '0.37663' },
      { x: '0.24', y: '0.41016' },
      { x: '0.25', y: '0.44513' },
      { x: '0.26', y: '0.48154' },
      { x: '0.27', y: '0.51939' },
      { x: '0.28', y: '0.55869' },
      { x: '0.29', y: '0.59943' },
      { x: '0.3', y: '0.64161' },
      { x: '0.31', y: '0.68525' },
      { x: '0.32', y: '0.73034' },
      { x: '0.33', y: '0.77688' },
      { x: '0.34', y: '0.82487' },
      { x: '0.35', y: '0.87432' },
      { x: '0.36', y: '0.92523' },
      { x: '0.37', y: '0.97760' },
      { x: '0.38', y: '1.03143' },
      { x: '0.39', y: '1.08672' },
      { x: '0.4', y: '1.14349' },
      { x: '0.41', y: '1.20172' },
      { x: '0.42', y: '1.26143' },
      { x: '0.43', y: '1.32261' },
      { x: '0.44', y: '1.38527' },
      { x: '0.45', y: '1.44942' },
      { x: '0.46', y: '3.53794' },
      { x: '0.47', y: '5.76153' },
      { x: '0.48', y: '8.12698' },
      { x: '0.49', y: '10.64162' },
      { x: '0.5', y: '13.31332' },
      { x: '0.51', y: '16.15057' },
      { x: '0.52', y: '19.16249' },
      { x: '0.53', y: '22.35891' },
      { x: '0.54', y: '25.75038' },
      { x: '0.55', y: '29.34828' },
      { x: '0.56', y: '33.16482' },
      { x: '0.57', y: '37.21317' },
      { x: '0.58', y: '41.50749' },
      { x: '0.59', y: '46.06304' },
      { x: '0.6', y: '50.89622' },
      { x: '0.61', y: '56.02473' },
      { x: '0.62', y: '61.46763' },
      { x: '0.63', y: '67.24545' },
      { x: '0.64', y: '73.38037' },
      { x: '0.65', y: '79.89625' },
      { x: '0.66', y: '86.81891' },
      { x: '0.67', y: '94.17617' },
      { x: '0.68', y: '101.99810' },
      { x: '0.69', y: '110.31720' },
      { x: '0.7', y: '119.16859' },
      { x: '0.71', y: '128.59026' },
      { x: '0.72', y: '138.62333' },
      { x: '0.73', y: '149.31230' },
      { x: '0.74', y: '160.70538' },
      { x: '0.75', y: '172.85483' },
      { x: '0.76', y: '185.81730' },
      { x: '0.77', y: '199.65425' },
      { x: '0.78', y: '214.43240' },
      { x: '0.79', y: '230.22422' },
      { x: '0.8', y: '247.10844' },
      { x: '0.81', y: '265.17068' },
      { x: '0.82', y: '284.50407' },
      { x: '0.83', y: '305.20998' },
      { x: '0.84', y: '327.39882' },
      { x: '0.85', y: '351.19089' },
      { x: '0.86', y: '376.71740' },
      { x: '0.87', y: '404.12144' },
      { x: '0.88', y: '433.55924' },
      { x: '0.89', y: '465.20146' },
      { x: '0.9', y: '499.23459' },
      { x: '0.91', y: '535.86257' },
      { x: '0.92', y: '575.30855' },
      { x: '0.93', y: '617.81687' },
      { x: '0.94', y: '663.65518' },
      { x: '0.95', y: '713.11690' },
      { x: '0.96', y: '766.52386' },
      { x: '0.97', y: '824.22926' },
      { x: '0.98', y: '886.62102' },
      { x: '0.99', y: '954.12537' },
      { x: '1', y: '1027.21102' },
    ],
  },
  WBTC: {
    borrowRates: [
      { x: '0.01', y: '0.08886' },
      { x: '0.02', y: '0.17781' },
      { x: '0.03', y: '0.26684' },
      { x: '0.04', y: '0.35595' },
      { x: '0.05', y: '0.44513' },
      { x: '0.06', y: '0.53439' },
      { x: '0.07', y: '0.62374' },
      { x: '0.08', y: '0.71316' },
      { x: '0.09', y: '0.80266' },
      { x: '0.1', y: '0.89224' },
      { x: '0.11', y: '0.98190' },
      { x: '0.12', y: '1.07164' },
      { x: '0.13', y: '1.16146' },
      { x: '0.14', y: '1.25136' },
      { x: '0.15', y: '1.34134' },
      { x: '0.16', y: '1.43139' },
      { x: '0.17', y: '1.52153' },
      { x: '0.18', y: '1.61175' },
      { x: '0.19', y: '1.70205' },
      { x: '0.2', y: '1.79243' },
      { x: '0.21', y: '1.88288' },
      { x: '0.22', y: '1.97342' },
      { x: '0.23', y: '2.06404' },
      { x: '0.24', y: '2.15474' },
      { x: '0.25', y: '2.24552' },
      { x: '0.26', y: '2.33638' },
      { x: '0.27', y: '2.42732' },
      { x: '0.28', y: '2.51834' },
      { x: '0.29', y: '2.60944' },
      { x: '0.3', y: '2.70062' },
      { x: '0.31', y: '2.79188' },
      { x: '0.32', y: '2.88323' },
      { x: '0.33', y: '2.97465' },
      { x: '0.34', y: '3.06616' },
      { x: '0.35', y: '3.15774' },
      { x: '0.36', y: '3.24941' },
      { x: '0.37', y: '3.34116' },
      { x: '0.38', y: '3.43299' },
      { x: '0.39', y: '3.52490' },
      { x: '0.4', y: '3.61689' },
      { x: '0.41', y: '3.70897' },
      { x: '0.42', y: '3.80112' },
      { x: '0.43', y: '3.89336' },
      { x: '0.44', y: '3.98568' },
      { x: '0.45', y: '4.07808' },
      { x: '0.46', y: '9.90767' },
      { x: '0.47', y: '16.06284' },
      { x: '0.48', y: '22.56172' },
      { x: '0.49', y: '29.42345' },
      { x: '0.5', y: '36.66823' },
      { x: '0.51', y: '44.31738' },
      { x: '0.52', y: '52.39341' },
      { x: '0.53', y: '60.92006' },
      { x: '0.54', y: '69.92242' },
      { x: '0.55', y: '79.42693' },
      { x: '0.56', y: '89.46154' },
      { x: '0.57', y: '100.05572' },
      { x: '0.58', y: '111.24059' },
      { x: '0.59', y: '123.04898' },
      { x: '0.6', y: '135.51555' },
      { x: '0.61', y: '148.67689' },
      { x: '0.62', y: '162.57159' },
      { x: '0.63', y: '177.24041' },
      { x: '0.64', y: '192.72634' },
      { x: '0.65', y: '209.07478' },
      { x: '0.66', y: '226.33363' },
      { x: '0.67', y: '244.55342' },
      { x: '0.68', y: '263.78752' },
      { x: '0.69', y: '284.09222' },
      { x: '0.7', y: '305.52695' },
      { x: '0.71', y: '328.15441' },
      { x: '0.72', y: '352.04077' },
      { x: '0.73', y: '377.25589' },
      { x: '0.74', y: '403.87345' },
      { x: '0.75', y: '431.97124' },
      { x: '0.76', y: '461.63133' },
      { x: '0.77', y: '492.94034' },
      { x: '0.78', y: '525.98967' },
      { x: '0.79', y: '560.87579' },
      { x: '0.8', y: '597.70046' },
      { x: '0.81', y: '636.57112' },
      { x: '0.82', y: '677.60109' },
      { x: '0.83', y: '720.90999' },
      { x: '0.84', y: '766.62403' },
      { x: '0.85', y: '814.87639' },
      { x: '0.86', y: '865.80760' },
      { x: '0.87', y: '919.56596' },
      { x: '0.88', y: '976.30794' },
      { x: '0.89', y: '1036.19866' },
      { x: '0.9', y: '1099.41234' },
      { x: '0.91', y: '1166.13281' },
      { x: '0.92', y: '1236.55406' },
      { x: '0.93', y: '1310.88075' },
      { x: '0.94', y: '1389.32885' },
      { x: '0.95', y: '1472.12623' },
      { x: '0.96', y: '1559.51332' },
      { x: '0.97', y: '1651.74381' },
      { x: '0.98', y: '1749.08535' },
      { x: '0.99', y: '1851.82037' },
      { x: '1', y: '1960.24684' },
    ],
    supplyRates: [
      { x: '0.01', y: '0.00071' },
      { x: '0.02', y: '0.00284' },
      { x: '0.03', y: '0.00639' },
      { x: '0.04', y: '0.01137' },
      { x: '0.05', y: '0.01776' },
      { x: '0.06', y: '0.02558' },
      { x: '0.07', y: '0.03482' },
      { x: '0.08', y: '0.04549' },
      { x: '0.09', y: '0.05757' },
      { x: '0.1', y: '0.07108' },
      { x: '0.11', y: '0.08602' },
      { x: '0.12', y: '0.10238' },
      { x: '0.13', y: '0.12017' },
      { x: '0.14', y: '0.13938' },
      { x: '0.15', y: '0.16002' },
      { x: '0.16', y: '0.18208' },
      { x: '0.17', y: '0.20558' },
      { x: '0.18', y: '0.23051' },
      { x: '0.19', y: '0.25686' },
      { x: '0.2', y: '0.28465' },
      { x: '0.21', y: '0.31388' },
      { x: '0.22', y: '0.34454' },
      { x: '0.23', y: '0.37663' },
      { x: '0.24', y: '0.41016' },
      { x: '0.25', y: '0.44513' },
      { x: '0.26', y: '0.48154' },
      { x: '0.27', y: '0.51939' },
      { x: '0.28', y: '0.55869' },
      { x: '0.29', y: '0.59943' },
      { x: '0.3', y: '0.64161' },
      { x: '0.31', y: '0.68525' },
      { x: '0.32', y: '0.73034' },
      { x: '0.33', y: '0.77688' },
      { x: '0.34', y: '0.82487' },
      { x: '0.35', y: '0.87432' },
      { x: '0.36', y: '0.92523' },
      { x: '0.37', y: '0.97760' },
      { x: '0.38', y: '1.03143' },
      { x: '0.39', y: '1.08672' },
      { x: '0.4', y: '1.14349' },
      { x: '0.41', y: '1.20172' },
      { x: '0.42', y: '1.26143' },
      { x: '0.43', y: '1.32261' },
      { x: '0.44', y: '1.38527' },
      { x: '0.45', y: '1.44942' },
      { x: '0.46', y: '3.53794' },
      { x: '0.47', y: '5.76153' },
      { x: '0.48', y: '8.12698' },
      { x: '0.49', y: '10.64162' },
      { x: '0.5', y: '13.31332' },
      { x: '0.51', y: '16.15057' },
      { x: '0.52', y: '19.16249' },
      { x: '0.53', y: '22.35891' },
      { x: '0.54', y: '25.75038' },
      { x: '0.55', y: '29.34828' },
      { x: '0.56', y: '33.16482' },
      { x: '0.57', y: '37.21317' },
      { x: '0.58', y: '41.50749' },
      { x: '0.59', y: '46.06304' },
      { x: '0.6', y: '50.89622' },
      { x: '0.61', y: '56.02473' },
      { x: '0.62', y: '61.46763' },
      { x: '0.63', y: '67.24545' },
      { x: '0.64', y: '73.38037' },
      { x: '0.65', y: '79.89625' },
      { x: '0.66', y: '86.81891' },
      { x: '0.67', y: '94.17617' },
      { x: '0.68', y: '101.99810' },
      { x: '0.69', y: '110.31720' },
      { x: '0.7', y: '119.16859' },
      { x: '0.71', y: '128.59026' },
      { x: '0.72', y: '138.62333' },
      { x: '0.73', y: '149.31230' },
      { x: '0.74', y: '160.70538' },
      { x: '0.75', y: '172.85483' },
      { x: '0.76', y: '185.81730' },
      { x: '0.77', y: '199.65425' },
      { x: '0.78', y: '214.43240' },
      { x: '0.79', y: '230.22422' },
      { x: '0.8', y: '247.10844' },
      { x: '0.81', y: '265.17068' },
      { x: '0.82', y: '284.50407' },
      { x: '0.83', y: '305.20998' },
      { x: '0.84', y: '327.39882' },
      { x: '0.85', y: '351.19089' },
      { x: '0.86', y: '376.71740' },
      { x: '0.87', y: '404.12144' },
      { x: '0.88', y: '433.55924' },
      { x: '0.89', y: '465.20146' },
      { x: '0.9', y: '499.23459' },
      { x: '0.91', y: '535.86257' },
      { x: '0.92', y: '575.30855' },
      { x: '0.93', y: '617.81687' },
      { x: '0.94', y: '663.65518' },
      { x: '0.95', y: '713.11690' },
      { x: '0.96', y: '766.52386' },
      { x: '0.97', y: '824.22926' },
      { x: '0.98', y: '886.62102' },
      { x: '0.99', y: '954.12537' },
      { x: '1', y: '1027.21102' },
    ],
  },
};

export const formatMarkets = (
  queryMoneyMarket: ResponseMarkets[],
  protocol: ProtocolState,
) => {
  const queryMoneyMarketMap = queryMoneyMarket.reduce(
    (prev, current) => ({
      ...prev,
      [current.underlying.symbol]: current,
    }),
    {} as Record<string, (typeof queryMoneyMarket)[0]>,
  );

  return Object.values(protocol.markets)
    .map(
      ({
        underlying,
        hToken,
        supported,
        hTokenExchangeRate,
        borrowCap,
        supplyCap,
      }) => {
        const queryMoneyMarketItem =
          queryMoneyMarketMap[underlying.symbol] || {};

        const liquidationPenalty = '8';

        const {
          lastState,
          reserveFactor = '0',
          collateralFactor = '0',
          interestRateModel = undefined,
        } = queryMoneyMarketItem;

        const {
          totalSupply = '0',
          borrows = '0',
          supplyRatePerSecond = '0',
          borrowRatePerSecond = '0',
        } = lastState || {};

        const supplyAPY = calcMarketAPY(supplyRatePerSecond);
        const borrowAPY = calcMarketAPY(borrowRatePerSecond);

        return {
          underlying,
          borrowCap,
          supplyCap,
          order: MARKET_ORDER_MAP[underlying.symbol as MARKET_KEY] || 999,
          name: ASSET_ALT_NAME[underlying.symbol] || underlying.name,
          totalSupply: new DefiUtils(totalSupply)
            .toUnderlying(hTokenExchangeRate)
            .toFullDecimals(underlying.decimals)
            .toString(),
          totalSupplyUSD: new DefiUtils(totalSupply)
            .toUnderlying(hTokenExchangeRate)
            .toFullDecimals(underlying.decimals)
            .toUSD(underlying.priceUSD)
            .toSafeFixed(10),
          totalBorrowingUSD: new DefiUtils(borrows)
            .toFullDecimals(underlying.decimals)
            .toUSD(underlying.priceUSD)
            .toSafeFixed(10),
          totalBorrowing: new DefiUtils(borrows)
            .toFullDecimals(underlying.decimals)
            .toString(),
          reserveFactor: new DefiUtils(reserveFactor)
            .dividedBy(DefiUtils.WAD)
            .multipliedBy(100)
            .toString(),
          liquidationThreshold: new DefiUtils(collateralFactor)
            .dividedBy(DefiUtils.WAD)
            .multipliedBy(100)
            .toString(),
          liquidationPenalty,
          exchangeRate: new DefiUtils(1)
            .toBasicUnits(hToken.decimals)
            .toUnderlying(hTokenExchangeRate)
            .toFullDecimals(underlying.decimals)
            .toSafeFixed(underlying.decimals),
          collateralFactor: new DefiUtils(collateralFactor)
            .dividedBy(DefiUtils.WAD)
            .multipliedBy(100)
            .toString(),
          oraclePriceUSD: underlying.priceUSD,
          logo: TOKEN_LOGO_MAP[underlying.symbol as MARKET_KEY]?.normal || '',
          supplyAPY,
          borrowAPY,
          supported,
          supplyRewards: [],
          borrowRewards: [],
          supplyRates: MARKET_RATES[underlying.symbol]?.supplyRates || [],
          borrowRates: MARKET_RATES[underlying.symbol]?.borrowRates || [],
          // aca_aca:rerun if change interest rate model or new mm
          // supplyRates: new Array(MAX_RATES_LENGTH).fill(0).map((_, index) => {
          //   const x = new DefiUtils(index)
          //     .plus(1)
          //     .dividedBy(MAX_RATES_LENGTH)
          //     .toString();

          //   return {
          //     x,
          //     y: calcMarketAPY(
          //       getSupplyRate(
          //         new DefiUtils(1).minus(x).toString(),
          //         x,
          //         '0',
          //         reserveFactor,
          //         interestRateModel?.baseRate || '0',
          //         interestRateModel?.firstSlope || '0',
          //         interestRateModel?.optimalUtilization || '0',
          //         interestRateModel?.lastSlope || '0',
          //       ).toString(),
          //     ),
          //   };
          // }),
          // borrowRates: new Array(MAX_RATES_LENGTH).fill(0).map((_, index) => {
          //   const x = new DefiUtils(index)
          //     .plus(1)
          //     .dividedBy(MAX_RATES_LENGTH)
          //     .toString();

          //   return {
          //     x,
          //     y: calcMarketAPY(
          //       getBorrowRate(
          //         new DefiUtils(1).minus(x).toString(),
          //         x,
          //         '0',
          //         interestRateModel?.baseRate || '0',
          //         interestRateModel?.firstSlope || '0',
          //         interestRateModel?.optimalUtilization || '0',
          //         interestRateModel?.lastSlope || '0',
          //       ).toString(),
          //     ),
          //   };
          // }),
          utilization: new DefiUtils(lastState?.borrows || '0')
            .dividedBy(
              new DefiUtils(lastState?.cash || '0')
                .plus(lastState?.borrows || '0')
                .minus(lastState?.reserves || '0'),
            )
            .multipliedBy(100)
            .toSafeString(),
        };
      },
    )
    .sort((a, b) => (a.order - b.order > 0 ? 1 : -1))
    .filter(({ underlying }) => underlying.symbol !== MARKET_KEY.BUSD);
};

export const formatTotalSupplyUSD = (markets: { totalSupplyUSD: string }[]) => {
  return markets
    .reduce(
      (prev, current) => prev.plus(current.totalSupplyUSD),
      new DefiUtils(0),
    )
    .toString();
};

export const formatTotalBorrowUSD = (
  markets: { totalBorrowingUSD: string }[],
) => {
  return markets
    .reduce(
      (prev, current) => prev.plus(current.totalBorrowingUSD),
      new DefiUtils(0),
    )
    .toString();
};

export const formatHistoryPoints = (
  marketHistoryPoints: ResponseHistoryPoint[],
  markets: Record<MARKET_KEY, MarketProtocol>,
) => {
  const marketHistoryPointsMap = marketHistoryPoints.reduce(
    (prev, current) => ({
      ...prev,
      [current.assetKey]: current.historyPoints
        .slice()
        .sort(
          (a, b) =>
            new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime(),
        ),
    }),
    {},
  ) as Record<
    string,
    {
      totalBorrows: string;
      supplyRate: string;
      borrowRate: string;
      exchangeRate: string;
      totalSupply: string;
      borrowAPY: string;
      supplyAPY: string;
      priceInEgld: string;
      timestamp: string;
    }[]
  >;

  const priceEgldInUsdcMap: Record<string, string> =
    Object.values(marketHistoryPoints)
      .find(({ assetKey }) => assetKey.includes('USDC'))
      ?.historyPoints.reduce(
        (prev, current) => ({
          ...prev,
          [current.timestamp]:
            current.priceInEgld === '0'
              ? '0'
              : new DefiUtils(1).dividedBy(current.priceInEgld).toString(),
        }),
        {},
      ) || {};

  const populatedHistoryPointsByMarkets = Object.entries(
    marketHistoryPointsMap,
  ).map(([tokenSymbol, historyPoints]) => {
    return historyPoints
      .map((historyPointItem) => {
        const {
          totalSupply,
          exchangeRate,
          totalBorrows,
          timestamp,
          priceInEgld,
        } = historyPointItem;

        const decimals =
          markets[tokenSymbol as MARKET_KEY]?.underlying.decimals || 0;

        const priceEgldInUsdc = priceEgldInUsdcMap[timestamp] || '0';

        const priceUSD =
          new DefiUtils(priceEgldInUsdc).isEqualTo('0') ||
          new DefiUtils(priceInEgld).isEqualTo('0')
            ? 0
            : new DefiUtils(priceEgldInUsdc)
                .multipliedBy(priceInEgld)
                .toNumber();

        const totalSupplyUSD = new DefiUtils(totalSupply)
          .toUnderlying(exchangeRate)
          .toFullDecimals(decimals)
          .toUSD(priceUSD)
          .toString();

        const totalBorrowUSD = new DefiUtils(totalBorrows)
          .toFullDecimals(decimals)
          .toUSD(priceUSD)
          .toString();

        return {
          ...historyPointItem,
          totalSupplyUSD,
          totalBorrowUSD,
        };
      })
      .reverse()
      .map((historyPointItem, historyPointIndex, historyPoints) => {
        const historyPointFilled = getHistoryPointFilled(
          historyPointItem,
          historyPoints.slice(historyPointIndex + 1),
        );

        return {
          ...historyPointItem,
          ...historyPointFilled,
        };
      })
      .reverse();
  });

  const historyPoints = populatedHistoryPointsByMarkets?.[0]?.map(
    (historyPointItem, index) => {
      const { timestamp } = historyPointItem;

      const [year, month, day] = timestamp.split('T')[0].split('-');
      const parsedTimestamp = parse(
        `${month}-${day}-${year.slice(-2)}`,
        'MM-dd-yy',
        new Date(),
      );
      const date = format(parsedTimestamp, 'LLLL d');

      const totalSupplyUSD = populatedHistoryPointsByMarkets
        .map((historyPoints) => historyPoints[index].totalSupplyUSD)
        .reduce((prev, current) => prev.plus(current), new DefiUtils('0'))
        .toString();

      const totalBorrowUSD = populatedHistoryPointsByMarkets
        .map((historyPoints) => historyPoints[index].totalBorrowUSD)
        .reduce((prev, current) => prev.plus(current), new DefiUtils('0'))
        .toString();

      const sumatorySupplyAPY = populatedHistoryPointsByMarkets
        .map((historyPoints) => {
          const { supplyAPY, totalSupplyUSD } = historyPoints[index];

          return new DefiUtils(supplyAPY)
            .multipliedBy(totalSupplyUSD)
            .toString();
        })
        .reduce((prev, current) => prev.plus(current), new DefiUtils('0'))
        .toString();

      const sumatoryBorrowAPY = populatedHistoryPointsByMarkets
        .map((historyPoints) => {
          const { borrowAPY, totalBorrowUSD } = historyPoints[index];

          return new DefiUtils(borrowAPY)
            .multipliedBy(totalBorrowUSD)
            .toString();
        })
        .reduce((prev, current) => prev.plus(current), new DefiUtils('0'))
        .toString();

      const totalSupplyAPY =
        new DefiUtils(sumatorySupplyAPY).isEqualTo('0') ||
        new DefiUtils(totalSupplyUSD).isEqualTo('0')
          ? '0'
          : new DefiUtils(sumatorySupplyAPY)
              .dividedBy(totalSupplyUSD)
              .toString();

      const totalBorrowAPY =
        new DefiUtils(sumatoryBorrowAPY).isEqualTo('0') ||
        new DefiUtils(totalBorrowUSD).isEqualTo('0')
          ? '0'
          : new DefiUtils(sumatoryBorrowAPY)
              .dividedBy(totalBorrowUSD)
              .toString();

      return {
        totalSupplyUSD,
        totalSupplyAPY,
        totalBorrowUSD,
        totalBorrowAPY,
        date,
      };
    },
  );

  return historyPoints || [];
};
