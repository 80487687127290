import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/store/index';

import { loaderEnabled } from '@/config/envVars';

export interface LoaderState {
  isLoading: boolean;
}

const initialState: LoaderState = {
  isLoading: loaderEnabled,
};

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    toggleIsLoading: (state) => {
      state.isLoading = !state.isLoading;
    },
  },
});

export const { setIsLoading, toggleIsLoading } = loaderSlice.actions;

export const isLoadingSelector = (state: RootState) => state.loader.isLoading;

export default loaderSlice.reducer;
