import { ApiNetworkProvider } from '@multiversx/sdk-network-providers';
import { captureException } from '@sentry/nextjs';

import { useEffectOnlyOnUpdate } from '@/hooks/tools/useEffectOnlyOnUpdate';

import { useAppDispatch, useAppSelector } from '@/store';
import {
  accountSelector,
  loginInfoSelector,
  setAccountState,
  setLoggingInState,
} from '@/store/auth';
import { networkStateSelector, setNetworkState } from '@/store/network';

import { isLoginExpired } from '@/utils/expiresAt';

const useAccountNetworkSync = () => {
  const dispatch = useAppDispatch();
  const accountSnap = useAppSelector(accountSelector);
  const loginInfoSnap = useAppSelector(loginInfoSelector);
  const apiNetworkProvider = useAppSelector(
    networkStateSelector<ApiNetworkProvider>('apiNetworkProvider')
  );

  useEffectOnlyOnUpdate(() => {
    const askForAccount = async () => {
      const address = accountSnap?.address;
      const loginExpires = loginInfoSnap.expires;
      const loginExpired = loginExpires && isLoginExpired(loginExpires);

      if (!loginExpired && address && apiNetworkProvider) {
        try {
          dispatch(setNetworkState('isLoading', true));
          dispatch(setAccountState('nonce', 0));
          dispatch(setAccountState('balance', '0'));
          dispatch(setLoggingInState('loggedIn', Boolean(address)));
        } catch (e) {
          captureException(e);
        } finally {
          dispatch(setNetworkState('isLoading', false));
        }
      }
      dispatch(setLoggingInState('pending', false));
    };

    askForAccount();
  }, [accountSnap?.address]);
};

export default useAccountNetworkSync;
