const ApproEqualIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width={5}
      height={5}
      viewBox='0 0 5 5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M3.59649 2C3.40156 2 3.2115 1.96899 3.02632 1.90698C2.85088 1.83463 2.67544 1.75711 2.5 1.67442C2.24659 1.56072 2.05166 1.47287 1.9152 1.41085C1.77875 1.3385 1.6423 1.30233 1.50585 1.30233C1.2232 1.30233 1.07212 1.52972 1.05263 1.9845H0C0.0194932 1.36434 0.155945 0.878553 0.409357 0.527131C0.662768 0.17571 0.999025 0 1.41813 0C1.6423 0 1.85185 0.0361757 2.04678 0.108527C2.24172 0.180879 2.4269 0.268734 2.60234 0.372093C2.79727 0.485788 2.95322 0.568475 3.07018 0.620155C3.18713 0.671834 3.30897 0.697674 3.43567 0.697674C3.76706 0.697674 3.94249 0.475452 3.96199 0.0310078H5C4.98051 0.609819 4.86355 1.08527 4.64912 1.45736C4.4347 1.81912 4.08382 2 3.59649 2Z'
        fill='currentColor'
      />
      <line y1='4.5' x2={5} y2='4.5' stroke='currentColor' />
    </svg>
  );
};

export default ApproEqualIcon;
