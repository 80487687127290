import { TOKEN_KEY } from '@/components/popups/XLaunchpadSnapshotPopup/types';

import clsxm from '@/services/clsxm';

export interface TokenSwitchProps {
  value: string;
  className?: string;
  onChange: (value: TOKEN_KEY) => void;
}

const imageSrcMap = {
  [TOKEN_KEY.SEGLD]: 'https://cdn.app.hatom.com/images/liquidstaking/app/tokens/segld-diamond.png',
  [TOKEN_KEY.HSEGLD]: 'https://cdn.app.hatom.com/images/liquidstaking/app/tokens/hsegld-diamond.png',
};

const selectedColorMap = {
  [TOKEN_KEY.SEGLD]: 'border-[#006FFF] hover:bg-[#006FFF1A] dark:hover:bg-[#0364E626]',
  [TOKEN_KEY.HSEGLD]: 'border-[#7E19FF] hover:bg-[#7E19FF1A] dark:hover:bg-[#7E19FF26 ]',
};

const symbolMap: Record<string, string> = {
  [TOKEN_KEY.SEGLD]: 'sEGLD',
  [TOKEN_KEY.HSEGLD]: 'HsEGLD',
};

const TokenSwitch = ({ value, className, onChange }: TokenSwitchProps) => {
  return (
    <div className={clsxm(className)}>
      <div className={clsxm('text-[12px] lg:text-[16px]', 'font-semibold text-[#535367]', 'mb-[4px] lg:mb-[8px]')}>
        Choose token
      </div>
      <div className='w-full flex  justify-center'>
        <div
          className={clsxm(
            'w-[360px] lg:w-full flex justify-between',
          )}
        >
          {[TOKEN_KEY.SEGLD, TOKEN_KEY.HSEGLD].map((interaction) => {
            return (
              <div
                key={interaction}
                className={clsxm(
                  'text-[12px]',
                  'lg:text-[16px]',
                  'cursor-pointer',
                  'font-semibold',
                  'h-[40px] w-[174px]',
                  'lg:h-[56px] lg:w-[222px]',
                  'flex',
                  interaction === TOKEN_KEY.SEGLD
                    ? 'pl-[55.8px] gap-[10px]'
                    : 'pl-[56px] gap-[8px]',
                  'items-center',
                  'rounded-[8.5px] md:rounded-[12px]',
                  'border-[2px]',
                  'border-[#E2E1FF] ',
                  interaction === value
                    ? ['dark:text-[#fff]', 'text-[#1F2226] hover:bg-opacity-80', selectedColorMap[interaction]]
                    : ['text-[#A9A7D1] hover:bg-[#F9F9FF] dark:border-[#504B87] dark:hover:bg-[#2D2863]'],
                )}
                onClick={() => {
                  onChange(interaction);
                }}
              >
                <img
                  className={clsxm('w-[24px] lg:w-[36px]')}
                  src={imageSrcMap[interaction]}
                  alt={interaction}
                />
                <span>
                  {symbolMap[interaction]}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TokenSwitch;
