import { IPlainTransactionObject } from '@multiversx/sdk-core/out';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppDispatch, GetRootState, RootState } from '@/store/index';

export const TRANSACTION_GROUPS_STORAGE_KEY = 'TRANSACTION_GROUPS_STORAGE_KEY';

export enum TRANSACTION_GROUP_TYPE {
  LIQUID = 'LIQUID',
  LIQUID_TAO = 'LIQUID_TAO',
  LENDING = 'LENDING',
  DEFAULT = 'DEFAULT',
}

export enum TRANSACTION_SUBGROUP_TYPE {
  DEFAULT = 'DEFAULT',

  // LIQUID
  STAKE_UNDERLYING = 'STAKE_UNDERLYING',
  STAKE_TOKEN = 'STAKE_TOKEN',
  STAKE_TOKEN_COLLATERAL = 'STAKE_TOKEN_COLLATERAL',
  UNSTAKE_UNDERLYING = 'UNSTAKE_UNDERLYING',
  UNSTAKE_TOKEN = 'UNSTAKE_TOKEN',
  UNSTAKE_TOKEN_COLLATERAL = 'UNSTAKE_TOKEN_COLLATERAL',
  MIGRATE_TOKEN_COLLATERAL_TO_TOKEN_WALLET = 'MIGRATE_TOKEN_COLLATERAL_TO_TOKEN_WALLET',
  MIGRATE_TOKEN_COLLATERAL_TO_UNDERLYING_WALLET = 'MIGRATE_TOKEN_COLLATERAL_TO_UNDERLYING_WALLET',
  MIGRATE_UNDERLYING_WALLET_TO_TOKEN_COLLATERAL = 'MIGRATE_UNDERLYING_WALLET_TO_TOKEN_COLLATERAL',
  MIGRATE_UNDERLYING_WALLET_TO_TOKEN_WALLET = 'MIGRATE_UNDERLYING_WALLET_TO_TOKEN_WALLET',
  MIGRATE_TOKEN_WALLET_TO_UNDERLYING_WALLET = 'MIGRATE_TOKEN_WALLET_TO_UNDERLYING_WALLET',
  MIGRATE_TOKEN_WALLET_TO_TOKEN_COLLATERAL = 'MIGRATE_TOKEN_WALLET_TO_TOKEN_COLLATERAL',
  CLAIM = 'CLAIM',

  // LENDING AND LIQUID
  CLAIM_REWARDS = 'CLAIM_REWARDS',

  // LENDING
  SUPPLY = 'SUPPLY',
  WITHDRAW = 'WITHDRAW',
  ADD_COLLATERAL = 'ADD_COLLATERAL',
  REMOVE_COLLATERAL = 'REMOVE_COLLATERAL',
  BORROW = 'BORROW',
  REPAY_BORROW = 'REPAY_BORROW',
  STAKE_HTM = 'STAKE_HTM',
  UNSTAKE_HTM = 'UNSTAKE_HTM',
  CLAIM_HTM = 'CLAIM_HTM',
  REBALANCE_PORTFOLIO = 'REBALANCE_PORTFOLIO',

  // LOCKING
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK',
  UNBOND = 'UNBOND',
  UNBOND_ALL = 'UNBOND_ALL',
}

export enum TRANSACTION_PROCESSING_STATUS_TYPE {
  STORING_WEB_WALLET_TXS_SIGNED = 'STORING_WEB_WALLET_TXS_SIGNED',
  GETTING_STORED_TXS = 'GETTING_STORED_TXS',
  SENDING_PENDING_TXS = 'SENDING_PENDING_TXS',
  STORING_TXS = 'STORING_TXS',
}

export interface TransactionItem {
  hash: string;
  status: string;
  transactionSigned?: IPlainTransactionObject;
}

export interface TransactionConfig {
  group: TRANSACTION_GROUP_TYPE;
  subgroup: TRANSACTION_SUBGROUP_TYPE;
  isSecuencial: boolean;
  accountAddress: string;
  result: string;
  token: string;
}

export interface TransactionGroup {
  id: string;
  config: TransactionConfig;
  amount: number;
  transactions: TransactionItem[];
}

export interface TransactionState {
  currentTransactions: TransactionItem[];
  transactionConfig: TransactionConfig;
  transactionAmount: number;
  transactionProcessingStatus: TRANSACTION_PROCESSING_STATUS_TYPE;
  transactionGroups: TransactionGroup[];
  silentModeActive: boolean;
}

export const defaultTransactionConfig: TransactionConfig = {
  group: TRANSACTION_GROUP_TYPE.DEFAULT,
  subgroup: TRANSACTION_SUBGROUP_TYPE.DEFAULT,
  isSecuencial: false,
  accountAddress: '',
  result: '0',
  token: '',
};

export const defaultTransactionGroup: TransactionGroup = {
  config: defaultTransactionConfig,
  amount: 0,
  id: '',
  transactions: [],
};

const initialState: TransactionState = {
  currentTransactions: [],
  transactionConfig: { ...defaultTransactionConfig },
  transactionAmount: 0,
  transactionProcessingStatus:
    TRANSACTION_PROCESSING_STATUS_TYPE.STORING_WEB_WALLET_TXS_SIGNED,
  transactionGroups: [],
  silentModeActive: false,
};

export const transactionSlice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setSilentModeActive: (state, action: PayloadAction<boolean>) => {
      state.silentModeActive = action.payload;
    },

    setCurrentTransactions: (
      state,
      action: PayloadAction<TransactionItem[]>,
    ) => {
      state.currentTransactions = action.payload;
    },

    setTransactionConfig: (state, action: PayloadAction<TransactionConfig>) => {
      state.transactionConfig = action.payload;
    },

    setTransactionAmount: (state, action: PayloadAction<number>) => {
      state.transactionAmount = action.payload;
    },

    setTransactionProcessingStatus: (
      state,
      action: PayloadAction<TRANSACTION_PROCESSING_STATUS_TYPE>,
    ) => {
      state.transactionProcessingStatus = action.payload;
    },

    setTransactionGroups: (
      state,
      action: PayloadAction<TransactionGroup[]>,
    ) => {
      state.transactionGroups = action.payload;
    },

    addTransactionGroup: (state, action: PayloadAction<TransactionGroup>) => {
      state.transactionGroups = [...state.transactionGroups, action.payload];
    },

    addOrUpdateTransactionGroup: (
      state,
      action: PayloadAction<Partial<TransactionGroup>>,
    ) => {
      const transactionGroupIndex = state.transactionGroups.findIndex(
        (transactionGroup) => transactionGroup.id === action.payload.id,
      );

      if (transactionGroupIndex === -1) {
        state.transactionGroups = [
          ...state.transactionGroups,
          {
            ...defaultTransactionGroup,
            ...action.payload,
          },
        ];
      } else {
        state.transactionGroups = state.transactionGroups.map(
          (transactionGroupItem) => {
            if (transactionGroupItem.id === action.payload?.id) {
              return {
                ...transactionGroupItem,
                ...action.payload,
              };
            }

            return transactionGroupItem;
          },
        );
      }
    },

    updateTransactionGroup: (
      state,
      action: PayloadAction<Partial<TransactionGroup>>,
    ) => {
      state.transactionGroups = state.transactionGroups.map(
        (transactionGroupItem) => {
          if (transactionGroupItem.id === action.payload?.id) {
            return {
              ...transactionGroupItem,
              ...action.payload,
            };
          }

          return transactionGroupItem;
        },
      );
    },

    deleteTransactionGroup: (state, action: PayloadAction<string>) => {
      state.transactionGroups = state.transactionGroups.map(
        (transactionGroupItem) => {
          if (transactionGroupItem.id !== action.payload) {
            return transactionGroupItem;
          }

          const { id, config } = transactionGroupItem;

          return {
            ...defaultTransactionGroup,
            id,
            config,
          };
        },
      );
    },
  },
});

export const {
  setCurrentTransactions,
  setTransactionConfig,
  setTransactionAmount,
  setTransactionProcessingStatus,
  setTransactionGroups,
  updateTransactionGroup,
  addOrUpdateTransactionGroup,
  addTransactionGroup,
  setSilentModeActive,
  deleteTransactionGroup,
} = transactionSlice.actions;

export const addCurrentTransaction =
  (transactionGroupId: string, transactionItem: TransactionItem) =>
  async (dispatch: AppDispatch, getState: GetRootState) => {
    const state = getState();
    const { transactionGroups } = state.transaction;

    const transactionGroupIndex = transactionGroups.findIndex(
      (transactionGroupItem) => transactionGroupItem.id === transactionGroupId,
    );

    if (transactionGroupIndex === -1) {
      return;
    }

    const transactions = [
      ...transactionGroups[transactionGroupIndex].transactions,
      transactionItem,
    ];

    dispatch(updateTransactionGroup({ id: transactionGroupId, transactions }));
  };

export const updateCurrentTransaction =
  (
    transactionGroupId: string,
    transactionHash: string,
    transactionItem: Partial<TransactionItem>,
  ) =>
  async (dispatch: AppDispatch, getState: GetRootState) => {
    const state = getState();
    const { transactionGroups } = state.transaction;

    const transactionGroupIndex = transactionGroups.findIndex(
      (transactionGroupItem) => transactionGroupItem.id === transactionGroupId,
    );

    if (transactionGroupIndex === -1) {
      return;
    }

    const transactions = transactionGroups[
      transactionGroupIndex
    ].transactions.map((transactionGroupItem) => {
      if (transactionGroupItem.hash === transactionHash) {
        return {
          ...transactionGroupItem,
          ...transactionItem,
        };
      }

      return transactionGroupItem;
    });

    dispatch(updateTransactionGroup({ id: transactionGroupId, transactions }));
  };

export const transactionSelector = (state: RootState) => state.transaction;

export const pendingTransactionsSelector = (state: RootState) =>
  state.transaction.currentTransactions.filter(
    ({ status }) => status === 'pending',
  );

export const hasPendingTransactionsSelector = (state: RootState) =>
  state.transaction.currentTransactions.some(
    ({ status }) => status === 'pending',
  );

export default transactionSlice.reducer;
