import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useState } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Provider } from 'react-redux';

import 'react-loading-skeleton/dist/skeleton.css';

import { store } from '@/store';

import { AnimationControllerProvider } from '@/contexts/AnimationControllerContext';
import { AuthProvider } from '@/contexts/AuthContext/index';
import { BlockchainProvider } from '@/contexts/BlockchainContext';
import { BoosterProvider } from '@/contexts/BoosterContext';
import { GovernanceProvider } from '@/contexts/GovernanceContext';
import { HatomMetaTagProvider } from '@/contexts/HatomMetaTagContext';
import { IndexerProvider } from '@/contexts/IndexerContext';
import { LendAppProvider } from '@/contexts/LendAppContext';
import { LiquidStakingAppProvider } from '@/contexts/LiquidStakingAppContext';
import { LiquidStakingLandingProvider } from '@/contexts/LiquidStakingLandingContext';
import { LiquidStakingTaoAppProvider } from '@/contexts/LiquidStakingTaoAppContext';
import LoaderContextProvider from '@/contexts/LoaderContextProvider';
import PopupContextProvider from '@/contexts/PopupContextProvider';
import { ProtocolProvider } from '@/contexts/ProtocolContext';
import { ThemeProvider } from '@/contexts/ThemeContext';
import { TransactionProvider } from '@/contexts/TransactionContext';

const ContextProviderManager = ({ children = <></> }) => {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <SkeletonTheme baseColor='#A3A7B8' highlightColor='#D1D2DC'>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <HatomMetaTagProvider>
            <ThemeProvider>
              <AnimationControllerProvider>
                <PopupContextProvider>
                  <AuthProvider>
                    <TransactionProvider>
                      <LendAppProvider>
                        <GovernanceProvider>
                          <LiquidStakingAppProvider>
                            <LiquidStakingTaoAppProvider>
                              <LiquidStakingLandingProvider>
                                <BlockchainProvider>
                                  <BoosterProvider>
                                    <ProtocolProvider>
                                      <IndexerProvider>
                                        <LoaderContextProvider>
                                          {/*  */}
                                          {children}
                                        </LoaderContextProvider>
                                      </IndexerProvider>
                                    </ProtocolProvider>
                                  </BoosterProvider>
                                </BlockchainProvider>
                              </LiquidStakingLandingProvider>
                            </LiquidStakingTaoAppProvider>
                          </LiquidStakingAppProvider>
                        </GovernanceProvider>
                      </LendAppProvider>
                    </TransactionProvider>
                  </AuthProvider>
                </PopupContextProvider>
              </AnimationControllerProvider>
            </ThemeProvider>
          </HatomMetaTagProvider>
        </Provider>
      </QueryClientProvider>
    </SkeletonTheme>
  );
};

export default ContextProviderManager;
