import styles from '@/components/popups/ConnectWallet.module.css';

const Loader: React.FC<any> = () => {
  let green = '#89C0E8';
  let darkBlue = '#1A1A1A';

  return (
    <svg
      width={100}
      height={100}
      viewBox='0 0 109 109'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <g id='body'>
          <path
            id='Vector'
            d='M54.5 16C46.8854 16 39.4419 18.258 33.1106 22.4884C26.7793 26.7189 21.8446 32.7317 18.9307 39.7667C16.0167 46.8016 15.2543 54.5427 16.7398 62.011C18.2253 69.4792 21.8921 76.3393 27.2764 81.7236C32.6607 87.1079 39.5208 90.7747 46.989 92.2602C54.4573 93.7458 62.1984 92.9833 69.2333 90.0694C76.2683 87.1554 82.2812 82.2207 86.5116 75.8895C90.742 69.5582 93 62.1146 93 54.5C93 44.2892 88.9438 34.4965 81.7236 27.2764C74.5035 20.0562 64.7109 16 54.5 16V16ZM55 65C53.0222 65 51.0888 64.4135 49.4443 63.3147C47.7998 62.2159 46.5181 60.6541 45.7612 58.8268C45.0043 56.9996 44.8063 54.9889 45.1922 53.0491C45.578 51.1093 46.5304 49.3275 47.9289 47.9289C49.3275 46.5304 51.1093 45.578 53.0491 45.1921C54.9889 44.8063 56.9996 45.0043 58.8269 45.7612C60.6541 46.5181 62.2159 47.7998 63.3147 49.4443C64.4135 51.0888 65 53.0222 65 55C65 57.6522 63.9465 60.1957 62.0711 62.0711C60.1957 63.9464 57.6522 65 55 65Z'
            fill={green}
          />
          <circle cx='55' cy='55' r='9' stroke={darkBlue} strokeWidth='2' />
        </g>
        <g className={styles.Animate} id='inner'>
          <path
            d='M87.9904 55.786C87.3428 65.2639 83.8459 72.6372 77.9084 78.6522C68.7596 87.9205 55.8716 90.2564 44.3035 86.3797'
            stroke={darkBlue}
            strokeWidth='2'
            strokeLinecap='round'
          />
          <path
            d='M80.9414 34.2559C86.0197 40.6168 87.1413 46.2062 87.7982 51.2628'
            stroke={darkBlue}
            strokeWidth='2'
            strokeLinecap='round'
          />
        </g>
        <g className={`${styles.Animate} ${styles.Reversed}`} id='outer'>
          <path
            d='M12.4979 59.412C10.5586 36.8008 24.308 18.3021 46.1362 13.9316'
            stroke={green}
            strokeWidth={2}
            strokeLinecap='round'
          />
          <path
            d='M13.1167 63.9067C13.5276 65.7142 14.3422 67.7468 14.9845 69.43'
            stroke={darkBlue}
            strokeWidth={2}
            strokeLinecap='round'
          />
        </g>
      </g>
    </svg>
  );
};

export default Loader;
