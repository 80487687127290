import { gql } from 'graphql-request';

import client from '@/services/indexer/client';
import type {
  QueryGovernanceProposal,
  ResponseGovernanceProposal,
} from '@/services/indexer/governance/types';

export const getGovernanceProposals = async (): Promise<
  ResponseGovernanceProposal[]
> => {
  const query = gql`
    query Governance_QueryGovernanceProposal () @cacheControl(maxAge: 10) {
      queryGovernanceProposal {
        id
        creationBlock
        creationTimestamp
        publishedBlock
        publishedTimestamp
        description
        published
        executed
        forVotes
				againstVotes
      }
    }
  `;

  const variables = {};

  const queryGovernanceProposalResponse =
    await client.request<QueryGovernanceProposal>(query, variables);

  return queryGovernanceProposalResponse.queryGovernanceProposal.map(
    (proposal) => ({
      ...proposal,
      upvotes: proposal.forVotes || '0',
      downvotes: proposal.againstVotes || '0',
    })
  );
};
