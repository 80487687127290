import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useRef, useState } from 'react';
import { useMemo } from 'react';

import useExchangeRate, {
  EXCHANGE_RATE_KEY,
} from '@/hooks/protocol/useExchangeRate';

import BrandPieChart, {
  BrandPieChartWrapper,
} from '@/components/BrandPieChart';
import ClosePopupBtn from '@/components/ClosePopupBtn';
import Row from '@/components/popups/CurrentPositionPopup/components/Row';
import PopupBed from '@/components/popups/PopupBed';

import { useAppDispatch, useAppSelector } from '@/store/index';
import {
  liquidStakingAppSelector,
  TOKEN_SOURCE,
} from '@/store/liquid-staking-app';
import { liquidStakingTaoAppSelector } from '@/store/liquid-staking-tao-app';
import { closePopup, popupSelector } from '@/store/popup';
import { MARKET_KEY } from '@/store/protocol';

const CurrentPositionPopup = () => {
  const { data } = useAppSelector(popupSelector);
  const liquidStaking = data.liquidStaking as
    | MARKET_KEY.sEGLD
    | MARKET_KEY.sWTAO;
  const { tokens: lsTokens } = useAppSelector(liquidStakingAppSelector);
  const { tokens: lsTaoTokens } = useAppSelector(liquidStakingTaoAppSelector);

  const tokens = useMemo(() => {
    return {
      ...lsTokens,
      ...lsTaoTokens,
    };
  }, [lsTaoTokens, lsTokens]);

  const isLiquidStakingSEGLD = useMemo(() => {
    return liquidStaking === MARKET_KEY.sEGLD;
  }, [liquidStaking]);

  const UNDERLYING_KEY = useMemo(() => {
    return isLiquidStakingSEGLD
      ? EXCHANGE_RATE_KEY.sEGLD
      : EXCHANGE_RATE_KEY.sWTAO;
  }, [isLiquidStakingSEGLD]);

  const TOKEN_KEY = useMemo(() => {
    return isLiquidStakingSEGLD
      ? EXCHANGE_RATE_KEY.HsEGLD
      : EXCHANGE_RATE_KEY.HsWTAO;
  }, [isLiquidStakingSEGLD]);

  const underlyingToToken = useExchangeRate(UNDERLYING_KEY, TOKEN_KEY);

  const underlyingWallet = useMemo(
    () => tokens[`${UNDERLYING_KEY}-${TOKEN_SOURCE.wallet}`],
    [UNDERLYING_KEY, tokens],
  );
  const tokenWallet = useMemo(
    () => tokens[`${TOKEN_KEY}-${TOKEN_SOURCE.wallet}`],
    [TOKEN_KEY, tokens],
  );

  const tokenCollateral = useMemo(
    () => tokens[`${TOKEN_KEY}-${TOKEN_SOURCE.collateral}`],
    [TOKEN_KEY, tokens],
  );

  const underlyingWalletValueInToken = useMemo(() => {
    return new DefiUtils(underlyingWallet.balance)
      .multipliedBy(underlyingToToken)
      .toString();
  }, [underlyingWallet.balance, underlyingToToken]);

  const totalValueInToken = useMemo(() => {
    const result = new DefiUtils(underlyingWalletValueInToken)
      .plus(tokenWallet.balance)
      .plus(tokenCollateral.balance)
      .toString();

    return result || '0';
  }, [
    underlyingWalletValueInToken,
    tokenCollateral.balance,
    tokenWallet.balance,
  ]);

  const rows = useMemo(() => {
    const underlyingWalletPercentage =
      new DefiUtils(underlyingWalletValueInToken)
        .multipliedBy(100)
        .dividedBy(totalValueInToken)
        .toNumber() || 0;
    const tokenWalletPercentage =
      new DefiUtils(tokenWallet.balance)
        .multipliedBy(100)
        .dividedBy(totalValueInToken)
        .toNumber() || 0;
    const tokenCollateralPercentage =
      new DefiUtils(tokenCollateral.balance)
        .multipliedBy(100)
        .dividedBy(totalValueInToken)
        .toNumber() || 0;

    function idGen(a: any, b: any, c = false) {
      return `${a}-${b | 0}${c ? '-collateral' : ''}`;
    }

    return [
      {
        id: idGen(UNDERLYING_KEY, underlyingWalletPercentage),
        token: UNDERLYING_KEY,
        accentColor: '#2E85FF',
        percentage: underlyingWalletPercentage,
        value: underlyingWallet.balance,
        valueUSD: underlyingWallet.balanceUSD,
      },
      {
        id: idGen(TOKEN_KEY, tokenWalletPercentage),
        token: TOKEN_KEY,
        accentColor: '#7E19FF',
        percentage: tokenWalletPercentage,
        value: tokenWallet.balance,
        valueUSD: tokenWallet.balanceUSD,
      },
      {
        id: idGen(
          TOKEN_KEY,
          tokenCollateralPercentage,
          !new DefiUtils(tokenCollateral.balance).isZero(),
        ),
        token: TOKEN_KEY,
        collateral: !new DefiUtils(tokenCollateral.balance).isZero(),
        accentColor: '#D146E8',
        // secondaryColor: '#72C686',
        percentage: tokenCollateralPercentage,
        value: tokenCollateral.balance,
        valueUSD: tokenCollateral.balanceUSD,
      },
    ];
  }, [
    underlyingWalletValueInToken,
    totalValueInToken,
    tokenWallet.balance,
    tokenWallet.balanceUSD,
    tokenCollateral.balance,
    tokenCollateral.balanceUSD,
    UNDERLYING_KEY,
    underlyingWallet.balance,
    underlyingWallet.balanceUSD,
    TOKEN_KEY,
  ]);

  const empty = useMemo(
    () => Math.max(...rows.map((r) => r.percentage)) == 0,
    [rows],
  );
  let SAFE_PERCENT = 5;

  const safePercentages = useMemo(() => {
    let pumped = new Map<number, number>();
    return cloneDeep(rows)
      .map((e: any, i: number) => {
        if (e.percentage < SAFE_PERCENT && e.percentage > 0) {
          pumped.set(i, SAFE_PERCENT - e.percentage);
          e.percentage = SAFE_PERCENT;
        }
        return e;
      })
      .map((e: any, i: number) => {
        if (pumped.has(i)) return e;
        const pumpedSum =
          (Array.from(pumped.values()).reduce((a, b) => a + b, 0) ?? 0) / 2;
        e.percentage -= pumpedSum;
        return e;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const dispatch = useAppDispatch();

  const svgRef = useRef<SVGSVGElement>(null);

  /* 
    tail is a dot at the end of an arc, 
    this logic in order to repositioning the dot in z axis, 
    and the only way is to reorder the dom element
  */
  useEffect(() => {
    const $0 = svgRef.current;
    if (!$0) return;

    const tail = $0.querySelector('[data-js=tail]');
    if (!tail) return;

    const svgChildren = Array.from($0.children);
    const last = svgChildren.indexOf(tail) == $0.children.length;
    if (last) return;

    tail.remove();
    $0.append(tail);
  }, [svgRef, safePercentages]);

  const renderArcProps = (array: [], i: number) => {
    const {
      percentage,
      accentColor,
      token,
      collateral,
      ...props
    }: {
      percentage: number;
      accentColor: string;
      token: string;
      collateral: boolean;
    } = array[i];
    const lastIndex = array.findLastIndex((x: any) => x.percentage) ?? -0;
    return {
      [`data-params`]: `${percentage | 0}-${token}${
        collateral ? '-collateral' : ''
      }`,
      tail: i === lastIndex,
      color: accentColor,
      percentage: percentage,
      rotateTurn: array
        .slice(0, i)
        .map((r: any) => r.percentage)
        .reduce((a, b) => a + b, 0),
      ...props,
    };
  };

  const [hoveredArc, setHoveredArc] = useState(new Map());
  const { t } = useTranslation('liquid-app');
  return (
    <PopupBed close={false}>
      <div
        className={classNames(
          'p-5 font-semibold leading-tight shadow-xl sm:min-w-[495px]',
        )}
      >
        <div className='flex items-center justify-between text-[#3A3C40] dark:text-white'>
          <h5 className='text-[16px] capitalize tracking-wide'>
            {t('current-position', 'Current position')}
          </h5>
          <button
            className='transition-all hover:scale-105 hover:opacity-80'
            onClick={() => {
              dispatch(closePopup());
            }}
          >
            <ClosePopupBtn
              theme={{ light: '#3C3A40', dark: 'white' }}
              iconClassName='w-[11px]'
            />
          </button>
        </div>
        <div className='mt-7 grid min-h-[192px] place-items-center items-center gap-8 text-[12px] sm:grid-cols-[160px,1fr] sm:gap-8'>
          <BrandPieChartWrapper ref={svgRef}>
            <BrandPieChart color='#340175' placeholder />
            {[2, 1, 0].map((i) => (
              <BrandPieChart
                key={i}
                handleHover={(state: any) => {
                  setHoveredArc((_) => {
                    const map = new Map(_);
                    map.set(safePercentages[i].id, state);
                    return map;
                  });
                }}
                {...renderArcProps(safePercentages, i)}
              />
            ))}
          </BrandPieChartWrapper>
          <div className='space-y-3'>
            {rows
              .filter((x) => x.percentage)
              .map((r: any, ri: number) => (
                <Row hovered={hoveredArc.get(r.id)} key={ri} {...r} />
              ))}
            {empty && (
              <span className='capitalize'>{t('no-asset-available')}</span>
            )}
          </div>
        </div>
      </div>
    </PopupBed>
  );
};

export default CurrentPositionPopup;
