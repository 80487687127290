import { useExtensionLogin } from '@/hooks/auth/providers/useExtensionLogin';
import { useLedgerLogin } from '@/hooks/auth/providers/useLedgerLogin';
import { useWalletConnectV2Login } from '@/hooks/auth/providers/useWalletConnectV2Login';
import useWebviewLogin from '@/hooks/auth/providers/useWebviewLogin';
import { useWebWalletLogin } from '@/hooks/auth/providers/useWebWalletLogin';

import Logout from '@/components/Icons/Logout';

import { useAppDispatch, useAppSelector } from '@/store';
import {
  clearAuthStates,
  loggingInSelector,
  loginInfoSelector,
} from '@/store/auth';
import { clearDappProvider } from '@/store/network';

import { LoginMethodsEnum } from '@/types/enums';
import { DappProvider } from '@/types/network';
import useExperimentalWebviewLogin from '@/hooks/auth/providers/useExperimentalWebviewLogin';

export interface Logout {
  dappProvider?: DappProvider;
  callbackRoute?: string;
  redirectFn?: (callbackRoute?: string) => void;
}

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const loggingInSnap = useAppSelector(loggingInSelector);
  const loginInfoSnap = useAppSelector(loginInfoSelector);

  const { logout: logoutWalletConnect } = useWalletConnectV2Login();
  const { logout: logoutExtension } = useExtensionLogin();
  const { logout: logoutWebWalletExtension } = useWebWalletLogin();
  const { logout: logoutLedgerExtension } = useLedgerLogin();
  const { logout: logoutWebview } = useWebviewLogin();
  const { logout: logoutExperimentalWebview } = useExperimentalWebviewLogin();

  const logout = async (params?: Logout) => {
    switch (loginInfoSnap.loginMethod) {
      case LoginMethodsEnum.walletconnect: {
        await logoutWalletConnect(params);
        break;
      }

      case LoginMethodsEnum.extension: {
        await logoutExtension(params);
        break;
      }

      case LoginMethodsEnum.wallet: {
        await logoutWebWalletExtension(params);
        break;
      }

      case LoginMethodsEnum.ledger: {
        await logoutLedgerExtension(params);
        break;
      }

      case LoginMethodsEnum.extra: {
        await logoutWebview();
        break;
      }

      case LoginMethodsEnum.experimentalWebview: {
        await logoutExperimentalWebview();
        break;
      }

      default: {
        dispatch(clearAuthStates());
        dispatch(clearDappProvider());
      }
    }
  };

  return {
    logout,
    pending: loggingInSnap.pending,
    loggedIn: loggingInSnap.loggedIn,
    error: loggingInSnap.error,
  };
};
