import Head from 'next/head';
import { createContext } from 'react';

import { bridgeUrl, indexerUrl, smartContracts } from '@/config/envVars';
import { chainType, networkConfig } from '@/config/network';

const HatomMetaTagContext = createContext({});

const HatomMetaTagConsumer = HatomMetaTagContext.Consumer;

type HatomMetaTagProviderProps = {
  children: React.ReactNode;
};
const HatomMetaTagProvider = ({ children }: HatomMetaTagProviderProps) => {
  return (
    <HatomMetaTagContext.Provider value={[]}>
      {children}

      <Head>
        <meta property='htm:indexer-url' content={indexerUrl} />
        <meta property='htm:chain-type' content={chainType} />
        <meta
          property='htm:mx-api-url'
          content={networkConfig[chainType].apiAddress}
        />
        <meta
          property='htm:mx-gateway-url'
          content={networkConfig[chainType].gatewayAddress}
        />
        <meta
          property='htm:hatom-gateway-url'
          content={networkConfig[chainType].hatomGatewayAddress}
        />
        <meta property='htm:bridge-url' content={bridgeUrl} />
        <meta
          property='htm:lens-address'
          content={smartContracts.lensAddress}
        />
      </Head>
    </HatomMetaTagContext.Provider>
  );
};

export default HatomMetaTagContext;

export { HatomMetaTagConsumer, HatomMetaTagProvider };
