import classNames from 'classnames';
import React from 'react';

import Hint from '@/components/Hint';
import InfoIcon from '@/components/Icons/InfoIcon';

import { Shimmer } from '@/sections/Liquid/App';
import { useHintProps } from '@/sections/Liquid/App/global';

export const Notes: React.FC<{ notes: Array<any>; className?: string }> = ({
  notes = [],
  className,
}) => {
  const defaultHintProps = useHintProps();

  return (
    <ul
      className={classNames(
        'space-y-2 rounded-[16px] bg-[#F9F9FE] p-5 dark:bg-[#292651] sm:space-y-2.5',
        className,
        'theme-switch-transition'
      )}
    >
      {notes.map(
        (
          {
            caption,
            captionTooltip,
            tooltipMax,
            value,
            alwaysOpen,
            customIcon,
          },
          i
        ) => (
          <li
            key={i}
            className='flex justify-between text-[14px] leading-6 sm:text-[16px]'
          >
            <div className='theme-switch-transition relative inline-flex gap-1 whitespace-nowrap text-[#535367] dark:text-[#7D7AAA]'>
              {caption}
              {captionTooltip && (
                <Hint
                  {...defaultHintProps}
                  alwaysOpen={alwaysOpen}
                  autoReplace
                  content={
                    <div
                      className='max-w-[266px] text-[8px] md:text-[10px] leading-tight'
                      style={{
                        maxWidth: tooltipMax ?? undefined,
                      }}
                    >
                      {captionTooltip}
                    </div>
                  }
                >
                  {customIcon ? (
                    customIcon
                  ) : (
                    <InfoIcon
                      className='theme-switch-transition inline-block text-[#5C5C5C] dark:text-[#625E8F]'
                      width={11}
                      height={11}
                      strokeWidth='1.5'
                    />
                  )}
                </Hint>
              )}
            </div>{' '}
            <div className='text-right text-[12px] sm:text-[14px]'>
              <Shimmer w={60} {...{ lg: i == 2, sm: i == 1 }}>
                {value}
              </Shimmer>
            </div>
          </li>
        )
      )}
    </ul>
  );
};
