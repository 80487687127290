import classNames from 'classnames';
import { domAnimation, LazyMotion, m } from 'framer-motion';

import WrappedImg from '@/components/WrappedImg';

export const Stone = () => {
  return (
    <LazyMotion features={domAnimation}>
      <m.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 0.3,
          ease: [0.4, 0, 0.2, 1],
        }}
        className={classNames(
          'pointer-events-none* absolute -right-[14px] top-16 md:-right-[84px] lg:-right-[80px]',
          '-z-10'
        )}
      >
        <WrappedImg
          className={classNames('w-[80.39px] md:w-[122.11px] lg:w-[129.24px]')}
          src='https://cdn.app.hatom.com/images/liquidstaking/app/stone2.png'
        />
      </m.div>
    </LazyMotion>
  );
};
