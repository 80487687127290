import { useRouter } from 'next/router';
import React from 'react';

import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';
import useLendInteraction from '@/hooks/interaction/useLendInteraction';

import Hint from '@/components/Hint';
import WrappedLottiePlayer from '@/components/WrappedLottiePlayer';

import { getRouteGroup } from '@/store/popup';
import {
  TRANSACTION_SUBGROUP_TYPE,
} from '@/store/transaction';

import ClaimButton from '@/sections/Lend/MyPosition/components/Claims&Requests/components/ClaimButton';
import { useHintProps } from '@/sections/Liquid/App/global';
import clsxm from '@/services/clsxm';


type Props = {
  col1: string;
  col2: string;
  claim: boolean;
  ids?: number[];
  amount?: string;
  img?: boolean;
  tab?: number;
  timestamp?: string;
  className?: string;
};

const HintableCell: React.FC<any> = ({
  children,
  content,
  visible = false,
}) => {
  const defaultHintProps = useHintProps();

  return (
    <Hint
      {...defaultHintProps}
      content={content}
      layerStyle={{ textAlign: 'center', minWidth: 30 }}
      unvisible={!visible}
    >
      {children}
    </Hint>
  );
};

export const TimerAnimation: React.FC<any> = () => {
  return (
    <WrappedLottiePlayer className='w-[12px]' src='https://cdn.app.hatom.com/animations/time1.json' />
  );
};

function ClaimRow(props: Props) {
  const { signTransactions } = useSignMultipleTransactions();
  const { claimBooster } = useLendInteraction();
  const router = useRouter();

  return (
    <div
      className={clsxm(
        'w-full grid grid-cols-3 lg:grid-cols-4 text-[10px] lg:text-[12px] lg:min-h-[24px]',
        props.className,
      )}
    >
      <div className='flex gap-1 items-center'>
        {props.img && (
          <div className='w-[14px] rounded-full lg:w-[16px]'>
            <img src='https://cdn.app.hatom.com/images/img42.png' alt='' />
          </div>
        )}
        <HintableCell content={`${props.col1}`} visible={props.claim}>
          <span>{props.col1}</span>
        </HintableCell>
      </div>
      <div className='flex items-center lg:col-span-2 -translate-x-2 lg:translate-x-0.5'>
        <HintableCell content={`$${props.col2}`} visible={props.claim}>
          <span>{props.col2}</span>
        </HintableCell>
      </div>
      {props.claim && (
        <div className='flex justify-end items-center'>
          {props.tab === 0 ? (
            <ClaimButton
              label='Claim'
              small
              onClick={() => {
                if (!props.ids || !props.amount || props.ids?.length <= 0) {
                  return;
                }

                signTransactions(
                  props.ids.map((id) => claimBooster(id)),
                  {
                    group: getRouteGroup(),
                    subgroup: TRANSACTION_SUBGROUP_TYPE.CLAIM_HTM,
                    token: 'HTM',
                    result: props.amount,
                  },
                );
              }}
            />
          ) : (
            <HintableCell content={props.timestamp}>
              <div className='flex cursor-default items-center gap-1 text-[]'>
                <TimerAnimation />
                {props.timestamp}
              </div>
            </HintableCell>
          )}
        </div>
      )}
    </div>
  );
}

export default ClaimRow;
