import classNames from 'classnames';
import { Trans } from 'next-i18next';

export const Description = () => {
  return (
    <div
      className={classNames(
        'bg-[#F3F1FE]',
        'dark:bg-[#101015]',
        'border-[1px]',
        'border-[#E5E1F2]',
        'dark:border-[#1D1D33]',
        'p-1.5',
        'px-2',
        'rounded-[12px]',
        'text-[#77778B]',
        'dark:text-[#625e8f]',
        'text-xs',
        'leading-[125%]',
        'mb-5',
        'font-normal'
      )}
    >
      <p
        className={classNames(
          'text-[#77778B]',
          'dark:text-[#625e8f]',
          'text-xs',
          'leading-[125%]',
          'font-normal'
        )}
      >
        <Trans
          i18nKey='paragraph-h9mBc'
          defaults='You can either vote using your wallet balance or use the <0>HTM</0> staked or supplied on the safety module. More <0>HTM</0> means more voting power.'
          ns='governance'
          components={[<span className={classNames('text-[#38a98a]')} />]}
        />
      </p>
    </div>
  );
};
