import { createContext } from 'react';
import { ToastBar, Toaster } from 'react-hot-toast';

import CustomToast from '@/components/CustomToast';

import useElrondNetworkSync from '@/contexts/AuthContext/hooks/useElrondNetworkSync';
import useWalletAuthenticatedToast from '@/contexts/AuthContext/hooks/useWalletAuthenticatedToast';

const AuthContext = createContext({});

const AuthConsumer = AuthContext.Consumer;

type AuthProviderProps = {
  children: React.ReactNode;
};
const AuthProvider = ({ children }: AuthProviderProps) => {
  useElrondNetworkSync();
  useWalletAuthenticatedToast();

  return (
    <AuthContext.Provider value={[]}>
      {children}
      <Toaster containerStyle={{ top: 52 }} toastOptions={{ duration: 2000 }}>
        {(t) => (
          <ToastBar
            toast={t}
            style={{
              background: '#fff0',
              boxShadow: 'none',
              padding: 0,
              margin: 0,
            }}
          >
            {({ icon, message }) => {
              return <CustomToast icon={icon}>{message}</CustomToast>;
            }}
          </ToastBar>
        )}
      </Toaster>
    </AuthContext.Provider>
  );
};

export default AuthContext;

export { AuthConsumer, AuthProvider };
