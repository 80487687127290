const Logout = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      width={12}
      height={13}
      viewBox='0 0 12 13'
      fill='none'
      stroke='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10.4115 6.3335H5.07812M8.40625 8.33325L10.4062 6.33325L8.40625 4.33325M4.40667 1C1.60667 1 1 1.66667 1 6.33333C1 11 1.60667 11.6667 4.40667 11.6667'
        strokeWidth='1.33333'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Logout;
