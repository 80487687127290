import {
  AbiRegistry,
  Address,
  AddressType,
  AddressValue,
  List,
  ListType,
} from '@multiversx/sdk-core';
import DefiUtils from 'defi-utils';

import lensABI from '@/abis/lens';
import { smartContracts } from '@/config/envVars';
import client from '@/services/blockchain/client';
import {
  AccountBoosterReward,
  AccountControllerReward,
  AccountMarketBoosterData,
  AccountMarketData,
  BoosterAccountClaim,
  BoosterAccumulator,
  BoosterData,
  BoosterRewardsBatch,
  ControllerAccumulator,
  ControllerData,
  ControllerRewardsBatch,
  DelegationContractData,
  HatomData,
  InterestRateModelData,
  LiquidStakingData,
  MarketData,
  MarketsBoosterData,
  PriceAggregatorPrices,
  TaoData,
  TaoLiquidStakingData,
  TokenPriceQuote,
} from '@/services/blockchain/lens/types';
import { MARKET_KEY } from '@/store/protocol';
import { MID_CACHE_TIME, MIN_CACHE_TIME, queryClient } from '@/utils/query';
import logger from '@/utils/logger';

export const getMarketData = async (moneyMarketAddress: string) => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getMarketData',
    abi: AbiRegistry.create(lensABI),
    args: [new AddressValue(new Address(moneyMarketAddress))],
    hasConvertType: true,
  });

  return response;
};

export const getMarketsData = async (
  moneyMarketAddress: string[],
): Promise<MarketData[]> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getMarketsData',
    abi: AbiRegistry.create(lensABI),
    args: [
      new List(
        new ListType(new AddressType()),
        moneyMarketAddress.map(
          (address) => new AddressValue(new Address(address)),
        ),
      ),
    ],
    hasConvertType: true,
  });

  return response || [];
};

export const getLiquidStakingData = async (): Promise<LiquidStakingData> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getLiquidStakingData',
    abi: AbiRegistry.create(lensABI),
    hasConvertType: true,
  });

  return response;
};

export const getDelegationContractsData = async (): Promise<
  DelegationContractData[]
> => {
  try {
    const response = await client({
      address: smartContracts.lensAddress,
      method: 'getDelegationContractsData',
      abi: AbiRegistry.create(lensABI),
      hasConvertType: true,
    });

    return response || [];
  } catch (error) {
    logger.error(error?.toString?.());
    return [];
  }
};

export const getBoosterData = async (): Promise<BoosterData> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterData',
    abi: AbiRegistry.create(lensABI),
    hasConvertType: true,
  });

  return response;
};

export const getAccountMarketsData = async (
  accountAddress: string,
  moneyMarketsAddress: string[],
): Promise<AccountMarketData[]> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getAccountMarketsData',
    abi: AbiRegistry.create(lensABI),
    args: [
      new AddressValue(new Address(accountAddress)),
      new List(
        new ListType(new AddressType()),
        moneyMarketsAddress.map(
          (address) => new AddressValue(new Address(address)),
        ),
      ),
    ],
    hasConvertType: true,
  });

  return response || [];
};

export const getInterestRateModelsData = async (): Promise<
  InterestRateModelData[]
> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getInterestRateModelsData',
    abi: AbiRegistry.create(lensABI),
    args: [new List(new ListType(new AddressType()), [])],
    hasConvertType: true,
  });

  return response || [];
};

export const getControllerRewardsBatches = async (): Promise<
  ControllerRewardsBatch[]
> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getControllerRewardsBatches',
    abi: AbiRegistry.create(lensABI),
    args: [new List(new ListType(new AddressType()), [])],
    hasConvertType: true,
  });

  return (response || []).map((item) => {
    const lastTime = new Date(+item.lastTime * 1000).toISOString();
    const endTime = new Date(+item.endTime * 1000).toISOString();

    return {
      ...item,
      lastTime,
      endTime,
    };
  });
};

export const getAccountControllerRewards = async (
  accountAddress: string,
): Promise<AccountControllerReward[]> => {
  if (accountAddress.length === 0) {
    return [];
  }

  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getAccountControllerRewards',
    abi: AbiRegistry.create(lensABI),
    args: [
      new AddressValue(new Address(accountAddress)),
      new List(new ListType(new AddressType()), []),
    ],
    hasConvertType: true,
  });

  return response || [];
};

export const getAccountBoosterRewards = async (
  accountAddress: string,
): Promise<AccountBoosterReward[]> => {
  if (accountAddress.length === 0) {
    return [];
  }

  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getAccountBoosterRewards',
    abi: AbiRegistry.create(lensABI),
    args: [
      new AddressValue(new Address(accountAddress)),
      new List(new ListType(new AddressType()), []),
    ],
    hasConvertType: true,
  });

  return response || [];
};

export const getHatomData = async (): Promise<HatomData> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getHatomData',
    abi: AbiRegistry.create(lensABI),
    hasConvertType: true,
  });

  return response;
};

export const getAccountMarketsBoosterData = async (
  accountAddress: string,
): Promise<AccountMarketBoosterData[]> => {
  if (accountAddress.length === 0) {
    return [];
  }

  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getAccountMarketsBoosterData',
    abi: AbiRegistry.create(lensABI),
    args: [
      new AddressValue(new Address(accountAddress)),
      new List(new ListType(new AddressType()), []),
    ],
    hasConvertType: true,
  });

  return (response || []).map((item) => {
    return {
      ...item,
      priceIntegralTimestamp: (+item.priceIntegralTimestamp * 1000).toString(),
      marketBoosterData: {
        ...item.marketBoosterData,
        priceIntegralTimestamp: new Date(
          +item.marketBoosterData.priceIntegralTimestamp * 1000,
        ).toISOString(),
      },
    };
  });
};

export const getBoosterRewardsBatches = async (): Promise<
  BoosterRewardsBatch[]
> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterRewardsBatches',
    abi: AbiRegistry.create(lensABI),
    args: [new List(new ListType(new AddressType()), [])],
    hasConvertType: true,
  });

  return (response || []).map((item) => {
    return {
      ...item,
      lastTime: new Date(+item.lastTime * 1000).toISOString(),
      endTime: new Date(+item.endTime * 1000).toISOString(),
    };
  });
};

export const getMarketsBoosterData = async (): Promise<
  MarketsBoosterData[]
> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getMarketsBoosterData',
    abi: AbiRegistry.create(lensABI),
    args: [new List(new ListType(new AddressType()), [])],
    hasConvertType: true,
  });

  return (response || []).map((item) => {
    return {
      ...item,
      priceIntegralTimestamp: new Date(
        +item.priceIntegralTimestamp * 1000,
      ).toISOString(),
    };
  });
};

export const getBoosterAccountClaims = async (
  accountAddress: string,
): Promise<BoosterAccountClaim[]> => {
  if (accountAddress.length === 0) {
    return [];
  }

  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterAccountClaims',
    abi: AbiRegistry.create(lensABI),
    args: [new AddressValue(new Address(accountAddress))],
    hasConvertType: true,
  });

  return (response || []).map((item) => {
    return {
      ...item,
      claimTimestamp: new Date(+item.claimTimestamp * 1000).toISOString(),
    };
  });
};

export const getAccountBoosterMarkets = async (
  accountAddress: string,
): Promise<string[]> => {
  if (accountAddress.length === 0) {
    return [];
  }

  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getAccountBoosterMarkets',
    abi: AbiRegistry.create(lensABI),
    args: [new AddressValue(new Address(accountAddress))],
    hasConvertType: true,
  });

  return response || [];
};

export const getBoosterAccumulators = async (): Promise<
  BoosterAccumulator[]
> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getBoosterAccumulators',
    abi: AbiRegistry.create(lensABI),
    args: [new List(new ListType(new AddressType()), [])],
    hasConvertType: true,
  });

  return response || [];
};

export const getControllerAccumulators = async (): Promise<
  ControllerAccumulator[]
> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getControllerAccumulators',
    abi: AbiRegistry.create(lensABI),
    args: [new List(new ListType(new AddressType()), [])],
    hasConvertType: true,
  });

  return response || [];
};

export const getTaoData = async (): Promise<TaoData> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getTaoData',
    abi: AbiRegistry.create(lensABI),
    hasConvertType: true,
  });

  return {
    ...(response || {}),
    wrappedTaoData: {
      ...(response?.wrappedTaoData || {}),
    },
    taoLiquidStakingData: {
      ...(response?.taoLiquidStakingData || {}),
      apr: new DefiUtils(response?.taoLiquidStakingData.apr)
        .dividedBy(100)
        .toString(),
    },
  };
};

export const getTaoLiquidStakingData =
  async (): Promise<TaoLiquidStakingData> => {
    const response = await client({
      address: smartContracts.lensAddress,
      method: 'getTaoLiquidStakingData',
      abi: AbiRegistry.create(lensABI),
      hasConvertType: true,
    });

    return {
      ...response,
      apr: new DefiUtils(response.apr).dividedBy(100).toString(),
    };
  };

export const getControllerData = async (): Promise<ControllerData> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getControllerData',
    abi: AbiRegistry.create(lensABI),
    hasConvertType: true,
  });

  return response;
};

export const getLendegate = async (): Promise<string> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getLendegate',
    abi: AbiRegistry.create(lensABI),
    hasConvertType: true,
  });

  return response;
};

export const getPriceAggregatorPrices = async (): Promise<
  PriceAggregatorPrices[]
> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getPriceAggregatorPrices',
    abi: AbiRegistry.create(lensABI),
    hasConvertType: true,
  });

  return response || [];
};

export const getTokenPrices = async (): Promise<TokenPriceQuote[]> => {
  const response = await client({
    address: smartContracts.lensAddress,
    method: 'getTokenPrices',
    abi: AbiRegistry.create(lensABI),
    hasConvertType: true,
  });

  return response || [];
};

export const getTokenPricesV2 = async (): Promise<Record<string, string>> => {
  const queryKey = ['blockchain:getTokenPricesV2'];

  const queryFn = async () => {
    const [liquidStakingTaoData, liquidStakingData, tokenPrices] =
      await Promise.all([
        getTaoLiquidStakingData(),
        getLiquidStakingData(),
        getTokenPrices(),
      ]);

    const liquidStakingExchangeRate = liquidStakingData.exchangeRate;
    const liquidStakingTaoExchangeRate =
      liquidStakingTaoData.currentExchangeRate;

    const tokenPricesMap = tokenPrices.reduce(
      (prev, current) => ({
        ...prev,
        [current.ticker]: new DefiUtils(current.price)
          .toFullDecimals(18)
          .toString(),
      }),
      {} as Record<string, string>,
    );

    const priceSegldInUSD = new DefiUtils(1)
      .toUnderlying(liquidStakingExchangeRate)
      .toUSD(tokenPricesMap?.[MARKET_KEY.EGLD] || '0')
      .toString();

    const priceSwtaoInUSD = new DefiUtils(1)
      .toUnderlying(liquidStakingTaoExchangeRate)
      .toUSD(tokenPricesMap?.[MARKET_KEY.WTAO] || '0')
      .toString();

    return {
      ...tokenPricesMap,
      [MARKET_KEY.sEGLD]: priceSegldInUSD,
      [MARKET_KEY.sWTAO]: priceSwtaoInUSD,
    } as Record<string, string>;
  };

  return queryClient.fetchQuery({
    queryKey,
    queryFn,
    cacheTime: MID_CACHE_TIME,
    staleTime: MID_CACHE_TIME,
  }) as Promise<Record<string, string>>;
};
