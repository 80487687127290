import useTheme from "@/hooks/useTheme";

function HeaderLogo({ className }: { className: string }) {
  const { isDark } = useTheme()
  return (<svg className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="16" fill={!isDark ? "#CECEE8" : "#433D77"} />
    <rect x="4" y="4" width="32" height="32" rx="16" fill="url(#paint0_linear_0_858)" />
    <path d="M20.8564 20.3329L27.9482 16.534L26.7571 14.2454L20.2601 16.8716C20.0932 16.9396 19.9068 16.9396 19.7399 16.8716L13.2429 14.2454L12.0482 16.5413L19.1399 20.3402L12.0482 24.1392L13.2429 26.435L19.7399 23.7886C19.9068 23.7206 20.0932 23.7206 20.2601 23.7886L26.7571 26.4222L27.9518 24.1263L20.8564 20.3329Z" fill="#F2542D" />
    <defs>
      <linearGradient id="paint0_linear_0_858" x1="3.9997" y1="20.0003" x2="36.0001" y2="20.0003" gradientUnits="userSpaceOnUse">
        <stop stopColor="#010101" />
        <stop offset="1" />
      </linearGradient>
    </defs>
  </svg>
  );
}

export default HeaderLogo;