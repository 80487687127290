import classNames from 'classnames';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';

import { EXCHANGE_RATE_KEY } from '@/hooks/protocol/useExchangeRate';

import { MaxButton } from '@/sections/Liquid/App/Form/components/MaxButton';
import { Percent80Limit } from '@/sections/Liquid/App/Form/components/Percent80Limit';
import { WalletCollateralBalanceSwitcher } from '@/sections/Liquid/App/Form/components/WalletCollateralBalanceSwitcher';
import { formatNumber } from '@/utils/helpers';

export const MigrateInput: React.FC<any> = ({
  poweredEGLD = EXCHANGE_RATE_KEY.HsEGLD,
  mutableSource,
  allowEdit = false,
  value,
  options,
  onChange,
  onInputValueChange,
  select = 0,
  valueUSD,
  decimalsLimit,
  onMax = () => {},
  hasHundredPercentButton = true,
}) => {
  // @ts-ignore
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { logo, name, color } = {
    [EXCHANGE_RATE_KEY.HsEGLD]: {
      logo: 'app/HSEGLD-diamond.png',
      name: 'HsEGLD',
    },
    [EXCHANGE_RATE_KEY.sEGLD]: {
      logo: 'app/SEGLD-diamond.png',
      name: 'sEGLD',
    },
  }[poweredEGLD];

  const height = mutableSource ? 77 : 69;
  return (
    <div
      className={classNames(
        'flex items-center px-3 pr-5',
        'w-full rounded-[20px] border-[2px] border-[#DCDCE3] bg-[#F9F9FE] dark:border-[#2E2C51] dark:bg-[#292651]',
        'font-hass font-semibold'
      )}
      style={{ borderColor: color, height }}
    >
      {mutableSource ? (
        <div className='mr-auto'>
          <WalletCollateralBalanceSwitcher
            onChange={onChange}
            options={options}
            select={select}
          />
        </div>
      ) : (
        <div className='mr-auto grid grid-cols-[38px,auto] items-center gap-2.5'>
          <img src={`https://cdn.app.hatom.com/images/liquidstaking/${logo}`} alt='' />
          <div>{poweredEGLD}</div>
        </div>
      )}
      <div
        className={classNames({
          'text-right': !allowEdit,
          'ml-3 w-full': allowEdit,
          'mt-4': allowEdit,
        })}
      >
        <div className='flex justify-end gap-1'>
          {allowEdit ? (
            <CurrencyInput
              inputMode='text'
              autoComplete='off'
              className={classNames(
                'w-full bg-transparent font-semibold placeholder:text-current focus:outline-none',
                'placeholder:text-[#5C5C71] dark:placeholder:text-white/70',
                {
                  'pl-3 text-right': !allowEdit,
                }
              )}
              placeholder='0'
              groupSeparator=','
              decimalSeparator='.'
              decimalsLimit={decimalsLimit}
              allowNegativeValue={false}
              onValueChange={onInputValueChange}
              value={value}
            />
          ) : (
            formatNumber(value, 4)
          )}
        </div>

        <div className='text-[12px] text-[#535367] dark:text-[#625E8F]'>
          ≈ ${formatNumber(valueUSD)}
        </div>
      </div>
      {allowEdit && (
        <div className='-mr-2'>
          {hasHundredPercentButton ? (
            <MaxButton onMax={onMax} />
          ) : (
            <Percent80Limit onClick={onMax} />
          )}
        </div>
      )}
    </div>
  );
};
