import classNames from 'classnames';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { appWithTranslation } from 'next-i18next';
import { GoogleAnalytics } from 'nextjs-google-analytics';
import { ReactElement, ReactNode, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import '@splidejs/react-splide/css/core';

import 'react-aspect-ratio/aspect-ratio.css';
import 'simplebar-react/dist/simplebar.min.css';
import '@/styles/globals.scss';

import ContextProviderManager from '@/components/ContextProviderManager';
import Layout from '@/components/Layout';

import { useAppSelector } from '@/store';
import { indexerSelector } from '@/store/indexer';

import { gtmId, indexationEnabled, isProd } from '@/config/envVars';

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  useEffect(() => {
    if (isProd && indexationEnabled) {
      TagManager.initialize({
        gtmId,
      });
    }
  }, []);
  const { hasFirstInfo, hasFirstAccountInfo } = useAppSelector(indexerSelector);

  const getLayout = Component.getLayout || ((page) => <Layout>{page}</Layout>);

  return (
    <>
      <div
        data-testid='loading'
        className={classNames({
          hidden: hasFirstInfo && hasFirstAccountInfo,
        })}
      ></div>
      <GoogleAnalytics trackPageViews />
      {getLayout(<Component {...pageProps} />)}
    </>
  );
}

const WrapperApp = (props: AppPropsWithLayout) => (
  <ContextProviderManager>
    <MyApp {...props} />
  </ContextProviderManager>
);

export default appWithTranslation(WrapperApp);
