import classNames from 'classnames';
import { Trans, useTranslation } from 'next-i18next';
import { useMemo, useRef, useState } from 'react';
import { useOnClickOutside, useToggle } from 'usehooks-ts';

import Hint from '@/components/Hint';
import InfoIcon from '@/components/Icons/InfoIcon';

import { governanceSelector, USER_BALANCE_SOURCE } from '@/store/governance';
import { useAppSelector } from '@/store/index';

import {
  BalanceButton,
  Container,
} from '@/sections/Governance/Balance/components';
import { useHintProps } from '@/sections/Liquid/App/global';

export const Balance = () => {
  const defaultHintProps = useHintProps();
  const [isBalanceOpen, toggleIsBalanceOpen] = useToggle(false);
  const [userBalanceSource, setUserBalanceSource] =
    useState<USER_BALANCE_SOURCE>(USER_BALANCE_SOURCE.wallet);
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('governance');

  useOnClickOutside(ref, () => {
    if (isBalanceOpen) {
      toggleIsBalanceOpen();
    }
  });

  const { userBalances } = useAppSelector(governanceSelector);

  const formattedBalance = useMemo(() => {
    return (
      userBalances?.[userBalanceSource as USER_BALANCE_SOURCE]?.amount || '0'
    );
  }, [userBalances, userBalanceSource]);

  return (
    <Container>
      <div
        className={classNames(
          'text-base capitalize',
          'leading-[125%]',
          'text-[#7264C9]',
          'pb-[18px]',
          'dark:text-[#625E8F]',
          'flex',
          'items-center',
          'gap-[4px]',
        )}
      >
        {t('staked-balance', 'Staked Balance')}

        <Hint
          {...defaultHintProps}
          autoReplace={true}
          content={
            <div className={classNames('max-w-[253px] tracking-[0.01em]')}>
              <Trans
                i18nKey='This value represents the amount of <0>HTM</0> tokens you currently have staked in Governance.'
                components={[<span className='text-[#38A98A]' />]}
              />
            </div>
          }
        >
          <InfoIcon width={11} height={11} strokeWidth='1.5' />
        </Hint>
      </div>

      <div className={classNames('relative', 'z-10', 'mb-[20px]')} ref={ref}>
        <BalanceButton />

        <div
          className={classNames(
            'absolute',
            'h-[2px]',
            'bg-[#D3CFE2]',
            'w-full',
            'bottom-0',
            'left-0',
            'z-1',
            'dark:bg-[#585584]',
          )}
        />
      </div>

      <div
        className={classNames(
          'text-base',
          'leading-[125%]',
          'mb-3',
          'capitalize',
        )}
      >
        {t('snapshot-process', 'Snapshot Process')}
      </div>

      <p
        className={classNames(
          'text-[#77778B]',
          'text-[10px]',
          'leading-[125%]',
          'dark:text-[#9995C8]',
          'lg:min-w-[348px]',
        )}
      >
        <Trans
          i18nKey='A <0>Snapshot</0> of your <1>Voting Power</1> is randomly taken 2 days after each proposal creation. Your <1>Voting Power</1> may vary between proposals based on the amount of <1>HTM</1> staked at the time of the <0>Snapshot</0>. To ensure your full <1>Voting Power</1>, keep your <1>HTM</1> tokens staked during this period.'
          ns='governance'
          components={[
            <span className='text-[#F0B90B]' />,
            <span className='text-[#38A98A]' />,
          ]}
        />
      </p>
    </Container>
  );
};
