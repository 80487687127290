import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

export const Container = ({ children }: PropsWithChildren) => {
  return (
    <div
      className={classNames(
        'max-w-[1202px]',
        'mx-auto',
        'relative',
        'font-semibold',
      )}
    >
      <div
        className={classNames(
          'grid',
          'gap-10',
          'grid-cols-1',
          'grid-rows-[auto, auto, auto]',
          'lg:grid-cols-[500px]',
          'xl:grid-cols-[1fr,376px]',
          'lg:gap-[40px]',
          'justify-center',
        )}
      >
        {children}
      </div>
    </div>
  );
};
