import classNames from 'classnames';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { EXCHANGE_RATE_KEY } from '@/hooks/protocol/useExchangeRate';

import { nFormatter } from '@/utils/helpers';

export const SelectItem: React.FC<any> = ({
  onClick,
  circular,
  source,
  highLighted,
  balance,
  symbol,
  caret,
  balanceUSD,
  priceUSD,
  ...props
}) => {
  const { t } = useTranslation('liquid-app');

  return (
    <div
      onClick={onClick}
      className={classNames(
        highLighted
          ? symbol === EXCHANGE_RATE_KEY.HsEGLD
            ? 'bg-[#F4F1FD] dark:bg-[#413c80]'
            : 'bg-[#016fff26] dark:bg-[#354880]'
          : symbol === EXCHANGE_RATE_KEY.HsEGLD
          ? 'hover:bg-[#F4F1FD] dark:hover:bg-[#5F5BA3]'
          : 'hover:bg-[#016fff26] dark:hover:bg-[#354880]',
        'inline-grid min-h-[46px] w-full cursor-pointer grid-cols-[32px,auto] items-center gap-2 p-1 sm:grid-cols-[34px,auto]'
      )}
      {...props}
    >
      <img
        src={
          circular
            ? symbol === EXCHANGE_RATE_KEY.HsEGLD
              ? `https://cdn.app.hatom.com/images/liquidstaking/app/tokens/hsegld.png`
              : 'https://cdn.app.hatom.com/images/sEGLD.svg'
            : `https://cdn.app.hatom.com/images/liquidstaking/app/tokens/${symbol.toLowerCase()}-diamond.png`
        }
        alt=''
      />

      <div>
        <div className='flex items-center gap-1.5 text-[12px] sm:text-[16px]'>
          {symbol}

          {caret && (
            <div className='text-[#535367] dark:text-[#837ED3]'>
              <svg
                width={8}
                height={5}
                viewBox='0 0 8 5'
                fill='none'
                stroke='currentColor'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M1 1.125L3.75 3.875L6.5 1.125'
                  strokeWidth={2}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </div>
          )}
        </div>
        {source && balance != undefined && (
          <div className='whitespace-nowrap text-[8px] font-normal text-[#535367] dark:text-[#837ED3] sm:text-[9.5px]'>
            {t(`${source.toLowerCase()}-balance`)}: <br className='sm:hidden' />{' '}
            {nFormatter(balance)} {symbol}
          </div>
        )}
      </div>
    </div>
  );
};
