const lensABI = {
  buildInfo: {
    rustc: {
      version: '1.80.0-nightly',
      commitHash: '791adf759cc065316f054961875052d5bc03e16c',
      commitDate: '2024-05-21',
      channel: 'Nightly',
      short: 'rustc 1.80.0-nightly (791adf759 2024-05-21)',
    },
    contractCrate: {
      name: 'lens',
      version: '0.0.0',
    },
    framework: {
      name: 'multiversx-sc',
      version: '0.48.1',
    },
  },
  name: 'Lens',
  constructor: {
    inputs: [
      {
        name: 'controller',
        type: 'Address',
      },
      {
        name: 'liquid_staking',
        type: 'Address',
      },
      {
        name: 'lendegate',
        type: 'Address',
      },
      {
        name: 'booster',
        type: 'Address',
      },
      {
        name: 'wrapped_tao',
        type: 'Address',
      },
      {
        name: 'tao_liquid_staking',
        type: 'Address',
      },
      {
        name: 'tao_lendegate',
        type: 'Address',
      },
    ],
    outputs: [],
  },
  endpoints: [
    {
      name: 'upgrade',
      mutability: 'mutable',
      inputs: [],
      outputs: [],
    },
    {
      name: 'getHatomData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'HatomData',
        },
      ],
    },
    {
      name: 'getControllerData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'ControllerData',
        },
      ],
    },
    {
      name: 'getControllerRewardsBatches',
      mutability: 'readonly',
      inputs: [
        {
          name: 'markets_in',
          type: 'List<Address>',
        },
      ],
      outputs: [
        {
          type: 'List<ControllerRewardsBatch>',
        },
      ],
    },
    {
      name: 'getControllerRewardsTokenIds',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'List<EgldOrEsdtTokenIdentifier>',
        },
      ],
    },
    {
      name: 'getAccountControllerRewards',
      mutability: 'mutable',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'tokens_in',
          type: 'List<EgldOrEsdtTokenIdentifier>',
        },
      ],
      outputs: [
        {
          type: 'List<AccountRewardsTokenData>',
        },
      ],
    },
    {
      name: 'getInterestRateModelData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'market',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'InterestRateModelData',
        },
      ],
    },
    {
      name: 'getInterestRateModelsData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'markets_in',
          type: 'List<Address>',
        },
      ],
      outputs: [
        {
          type: 'List<InterestRateModelData>',
        },
      ],
    },
    {
      name: 'getMarketData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'market',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'MarketData',
        },
      ],
    },
    {
      name: 'getMarketsData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'markets_in',
          type: 'List<Address>',
        },
      ],
      outputs: [
        {
          type: 'List<MarketData>',
        },
      ],
    },
    {
      name: 'getLightMarketData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'market',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'LightMarketData',
        },
      ],
    },
    {
      name: 'getAccountMarketData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'market',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'AccountMarketData',
        },
      ],
    },
    {
      name: 'getAccountMarketsData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'markets_in',
          type: 'List<Address>',
        },
      ],
      outputs: [
        {
          type: 'List<AccountMarketData>',
        },
      ],
    },
    {
      name: 'getLiquidStakingData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'LiquidStakingData',
        },
      ],
    },
    {
      name: 'getDelegationContractData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'delegation_contract',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'DelegationContractData',
        },
      ],
    },
    {
      name: 'getDelegationContractsData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'List<DelegationContractData>',
        },
      ],
    },
    {
      name: 'getDelegationContracts',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'variadic<Address>',
          multi_result: true,
        },
      ],
    },
    {
      name: 'getBoosterData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'BoosterData',
        },
      ],
    },
    {
      name: 'getMarketBoosterData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'market',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'MarketBoosterData',
        },
      ],
    },
    {
      name: 'getMarketsBoosterData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'markets_in',
          type: 'List<Address>',
        },
      ],
      outputs: [
        {
          type: 'List<MarketBoosterData>',
        },
      ],
    },
    {
      name: 'getAccountMarketBoosterData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'market',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'AccountMarketBoosterData',
        },
      ],
    },
    {
      name: 'getAccountMarketsBoosterData',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'markets_in',
          type: 'List<Address>',
        },
      ],
      outputs: [
        {
          type: 'List<AccountMarketBoosterData>',
        },
      ],
    },
    {
      name: 'getBoosterRewardsBatches',
      mutability: 'readonly',
      inputs: [
        {
          name: 'markets_in',
          type: 'List<Address>',
        },
      ],
      outputs: [
        {
          type: 'List<BoosterRewardsBatch>',
        },
      ],
    },
    {
      name: 'getBoosterRewardsTokenIds',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'List<EgldOrEsdtTokenIdentifier>',
        },
      ],
    },
    {
      name: 'getAccountBoosterMarkets',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'List<Address>',
        },
      ],
    },
    {
      name: 'getAccountBoosterRewards',
      mutability: 'mutable',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'tokens_in',
          type: 'List<EgldOrEsdtTokenIdentifier>',
        },
      ],
      outputs: [
        {
          type: 'List<AccountRewardsTokenData>',
        },
      ],
    },
    {
      name: 'getBoosterAccumulator',
      mutability: 'readonly',
      inputs: [
        {
          name: 'token_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'AccumulatorData',
        },
      ],
    },
    {
      name: 'getBoosterAccumulators',
      mutability: 'readonly',
      inputs: [
        {
          name: 'tokens_in',
          type: 'List<EgldOrEsdtTokenIdentifier>',
        },
      ],
      outputs: [
        {
          type: 'List<AccumulatorData>',
        },
      ],
    },
    {
      name: 'getControllerAccumulator',
      mutability: 'readonly',
      inputs: [
        {
          name: 'token_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
      ],
      outputs: [
        {
          type: 'AccumulatorData',
        },
      ],
    },
    {
      name: 'getControllerAccumulators',
      mutability: 'readonly',
      inputs: [
        {
          name: 'tokens_in',
          type: 'List<EgldOrEsdtTokenIdentifier>',
        },
      ],
      outputs: [
        {
          type: 'List<AccumulatorData>',
        },
      ],
    },
    {
      name: 'getBoosterAccountClaims',
      mutability: 'readonly',
      inputs: [
        {
          name: 'account',
          type: 'Address',
        },
      ],
      outputs: [
        {
          type: 'List<BoosterClaim>',
        },
      ],
    },
    {
      name: 'getPriceAggregatorPrices',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'List<PriceAggregatorPrice>',
        },
      ],
    },
    {
      name: 'testPrices',
      mutability: 'readonly',
      inputs: [
        {
          name: 'safe_egld',
          type: 'bool',
        },
        {
          name: 'safe_price',
          type: 'bool',
        },
      ],
      outputs: [
        {
          type: 'List<TokenPriceQuote>',
        },
      ],
    },
    {
      name: 'getTokenPrices',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'List<TokenPriceQuote>',
        },
      ],
    },
    {
      name: 'getWrappedTaoData',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'WrappedTaoData',
        },
      ],
    },
    {
      name: 'getTaoLiquidStakingData',
      mutability: 'mutable',
      inputs: [],
      outputs: [
        {
          type: 'TaoLiquidStakingData',
        },
      ],
    },
    {
      name: 'getTaoData',
      mutability: 'mutable',
      inputs: [],
      outputs: [
        {
          type: 'TaoData',
        },
      ],
    },
    {
      name: 'getController',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getLiquidStaking',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getLendegate',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getBooster',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getWrappedTao',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getTaoLiquidStaking',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
    {
      name: 'getTaoLendegate',
      mutability: 'readonly',
      inputs: [],
      outputs: [
        {
          type: 'Address',
        },
      ],
    },
  ],
  esdtAttributes: [],
  hasCallback: false,
  types: {
    AccountMarketBoosterData: {
      type: 'struct',
      fields: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'market',
          type: 'Address',
        },
        {
          name: 'stake',
          type: 'BigUint',
        },
        {
          name: 'price_integral',
          type: 'BigUint',
        },
        {
          name: 'price_integral_timestamp',
          type: 'u64',
        },
        {
          name: 'market_booster_data',
          type: 'MarketBoosterData',
        },
        {
          name: 'stored_collateral_tokens',
          type: 'BigUint',
        },
      ],
    },
    AccountMarketData: {
      type: 'struct',
      fields: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'money_market',
          type: 'Address',
        },
        {
          name: 'collateral',
          type: 'BigUint',
        },
        {
          name: 'borrow_amount',
          type: 'BigUint',
        },
        {
          name: 'borrow_index',
          type: 'BigUint',
        },
        {
          name: 'stored_borrow_amount',
          type: 'BigUint',
        },
        {
          name: 'market_data',
          type: 'LightMarketData',
        },
        {
          name: 'current_borrow_amount',
          type: 'BigUint',
        },
      ],
    },
    AccountRewardsTokenData: {
      type: 'struct',
      fields: [
        {
          name: 'account',
          type: 'Address',
        },
        {
          name: 'token_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'rewards',
          type: 'BigUint',
        },
      ],
    },
    AccumulatorData: {
      type: 'struct',
      fields: [
        {
          name: 'token_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'premium',
          type: 'BigUint',
        },
        {
          name: 'amount_left',
          type: 'BigUint',
        },
        {
          name: 'distributed_amount',
          type: 'BigUint',
        },
        {
          name: 'swap_path',
          type: 'List<SwapStep>',
        },
      ],
    },
    BoosterClaim: {
      type: 'struct',
      fields: [
        {
          name: 'id',
          type: 'u32',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
        {
          name: 'claim_timestamp',
          type: 'u64',
        },
        {
          name: 'claimed',
          type: 'bool',
        },
      ],
    },
    BoosterData: {
      type: 'struct',
      fields: [
        {
          name: 'address',
          type: 'Address',
        },
        {
          name: 'is_active',
          type: 'bool',
        },
        {
          name: 'stake_token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'total_stake',
          type: 'BigUint',
        },
        {
          name: 'staking_ratio_threshold',
          type: 'BigUint',
        },
        {
          name: 'cooldown_period',
          type: 'u64',
        },
      ],
    },
    BoosterRewardsBatch: {
      type: 'struct',
      fields: [
        {
          name: 'id',
          type: 'u32',
        },
        {
          name: 'money_market',
          type: 'Address',
        },
        {
          name: 'token_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
        {
          name: 'distributed_amount',
          type: 'BigUint',
        },
        {
          name: 'speed',
          type: 'BigUint',
        },
        {
          name: 'index',
          type: 'BigUint',
        },
        {
          name: 'last_time',
          type: 'u64',
        },
        {
          name: 'end_time',
          type: 'u64',
        },
      ],
    },
    ControllerData: {
      type: 'struct',
      fields: [
        {
          name: 'address',
          type: 'Address',
        },
        {
          name: 'markets',
          type: 'List<Address>',
        },
        {
          name: 'price_oracle',
          type: 'Address',
        },
        {
          name: 'max_markets_per_account',
          type: 'u32',
        },
        {
          name: 'global_seize_allowed',
          type: 'bool',
        },
      ],
    },
    ControllerRewardsBatch: {
      type: 'struct',
      fields: [
        {
          name: 'id',
          type: 'u32',
        },
        {
          name: 'money_market',
          type: 'Address',
        },
        {
          name: 'market_type',
          type: 'MarketType',
        },
        {
          name: 'token_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'amount',
          type: 'BigUint',
        },
        {
          name: 'distributed_amount',
          type: 'BigUint',
        },
        {
          name: 'speed',
          type: 'BigUint',
        },
        {
          name: 'index',
          type: 'BigUint',
        },
        {
          name: 'last_time',
          type: 'u64',
        },
        {
          name: 'end_time',
          type: 'u64',
        },
      ],
    },
    DelegationContractData: {
      type: 'struct',
      fields: [
        {
          name: 'contract',
          type: 'Address',
        },
        {
          name: 'total_value_locked',
          type: 'BigUint',
        },
        {
          name: 'cap',
          type: 'Option<BigUint>',
        },
        {
          name: 'nr_nodes',
          type: 'u64',
        },
        {
          name: 'apr',
          type: 'BigUint',
        },
        {
          name: 'service_fee',
          type: 'BigUint',
        },
        {
          name: 'delegation_score',
          type: 'BigUint',
        },
        {
          name: 'pending_to_delegate',
          type: 'BigUint',
        },
        {
          name: 'total_delegated',
          type: 'BigUint',
        },
        {
          name: 'pending_to_undelegate',
          type: 'BigUint',
        },
        {
          name: 'total_undelegated',
          type: 'BigUint',
        },
        {
          name: 'total_withdrawable',
          type: 'BigUint',
        },
        {
          name: 'outdated',
          type: 'bool',
        },
        {
          name: 'blacklisted',
          type: 'bool',
        },
      ],
    },
    HatomData: {
      type: 'struct',
      fields: [
        {
          name: 'controller',
          type: 'Address',
        },
        {
          name: 'markets',
          type: 'List<Address>',
        },
        {
          name: 'liquid_staking',
          type: 'Address',
        },
        {
          name: 'lendegate',
          type: 'Address',
        },
        {
          name: 'booster',
          type: 'Address',
        },
      ],
    },
    InterestRateModelData: {
      type: 'struct',
      fields: [
        {
          name: 'market',
          type: 'Address',
        },
        {
          name: 'base_rate',
          type: 'BigUint',
        },
        {
          name: 'first_slope',
          type: 'BigUint',
        },
        {
          name: 'last_slope',
          type: 'BigUint',
        },
        {
          name: 'optimal_utilization',
          type: 'BigUint',
        },
        {
          name: 'max_borrow_rate',
          type: 'BigUint',
        },
      ],
    },
    LightMarketData: {
      type: 'struct',
      fields: [
        {
          name: 'address',
          type: 'Address',
        },
        {
          name: 'borrow_index',
          type: 'BigUint',
        },
        {
          name: 'accrual_timestamp',
          type: 'u64',
        },
        {
          name: 'borrow_rate_per_second',
          type: 'BigUint',
        },
      ],
    },
    LiquidStakingData: {
      type: 'struct',
      fields: [
        {
          name: 'address',
          type: 'Address',
        },
        {
          name: 'is_active',
          type: 'bool',
        },
        {
          name: 'ls_token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'undelegate_token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'total_supply',
          type: 'BigUint',
        },
        {
          name: 'cash_reserve',
          type: 'BigUint',
        },
        {
          name: 'rewards_reserve',
          type: 'BigUint',
        },
        {
          name: 'protocol_reserve',
          type: 'BigUint',
        },
        {
          name: 'exchange_rate',
          type: 'BigUint',
        },
        {
          name: 'total_fee',
          type: 'BigUint',
        },
        {
          name: 'unbond_period',
          type: 'u64',
        },
      ],
    },
    MarketBoosterData: {
      type: 'struct',
      fields: [
        {
          name: 'market',
          type: 'Address',
        },
        {
          name: 'price_integral',
          type: 'BigUint',
        },
        {
          name: 'price_integral_timestamp',
          type: 'u64',
        },
        {
          name: 'stored_total_collateral_tokens',
          type: 'BigUint',
        },
      ],
    },
    MarketData: {
      type: 'struct',
      fields: [
        {
          name: 'address',
          type: 'Address',
        },
        {
          name: 'is_active',
          type: 'bool',
        },
        {
          name: 'underlying_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'cash',
          type: 'BigUint',
        },
        {
          name: 'total_borrows',
          type: 'BigUint',
        },
        {
          name: 'total_reserves',
          type: 'BigUint',
        },
        {
          name: 'staking_rewards',
          type: 'BigUint',
        },
        {
          name: 'revenue',
          type: 'BigUint',
        },
        {
          name: 'exchange_rate',
          type: 'BigUint',
        },
        {
          name: 'borrow_index',
          type: 'BigUint',
        },
        {
          name: 'total_supply',
          type: 'BigUint',
        },
        {
          name: 'total_collateral',
          type: 'BigUint',
        },
        {
          name: 'close_factor',
          type: 'BigUint',
        },
        {
          name: 'stake_factor',
          type: 'BigUint',
        },
        {
          name: 'reserve_factor',
          type: 'BigUint',
        },
        {
          name: 'collateral_factor',
          type: 'BigUint',
        },
        {
          name: 'liquidation_incentive',
          type: 'BigUint',
        },
        {
          name: 'protocol_seize_share',
          type: 'BigUint',
        },
        {
          name: 'supply_rate_per_second',
          type: 'BigUint',
        },
        {
          name: 'borrow_rate_per_second',
          type: 'BigUint',
        },
        {
          name: 'accrual_time_threshold',
          type: 'u64',
        },
        {
          name: 'accrual_timestamp',
          type: 'u64',
        },
        {
          name: 'liquidity_cap',
          type: 'BigUint',
        },
        {
          name: 'borrow_cap',
          type: 'BigUint',
        },
        {
          name: 'is_mint_allowed',
          type: 'bool',
        },
        {
          name: 'is_borrow_allowed',
          type: 'bool',
        },
        {
          name: 'is_seize_allowed',
          type: 'bool',
        },
        {
          name: 'max_rewards_batches',
          type: 'u32',
        },
      ],
    },
    MarketType: {
      type: 'enum',
      variants: [
        {
          name: 'Supply',
          discriminant: 0,
        },
        {
          name: 'Borrow',
          discriminant: 1,
        },
      ],
    },
    PriceAggregatorPrice: {
      type: 'struct',
      fields: [
        {
          name: 'money_market',
          type: 'Address',
        },
        {
          name: 'underlying_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'ticker',
          type: 'bytes',
        },
        {
          name: 'timestamp',
          type: 'u64',
        },
        {
          name: 'price',
          type: 'BigUint',
        },
      ],
    },
    SwapStep: {
      type: 'struct',
      fields: [
        {
          name: 'pair_address',
          type: 'Address',
        },
        {
          name: 'input_token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'output_token_id',
          type: 'TokenIdentifier',
        },
      ],
    },
    TaoData: {
      type: 'struct',
      fields: [
        {
          name: 'wrapped_tao_data',
          type: 'WrappedTaoData',
        },
        {
          name: 'tao_liquid_staking_data',
          type: 'TaoLiquidStakingData',
        },
        {
          name: 'tao_lendegate',
          type: 'Address',
        },
      ],
    },
    TaoLiquidStakingData: {
      type: 'struct',
      fields: [
        {
          name: 'address',
          type: 'Address',
        },
        {
          name: 'is_active',
          type: 'bool',
        },
        {
          name: 'ls_token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'ls_token_supply',
          type: 'BigUint',
        },
        {
          name: 'cash',
          type: 'BigUint',
        },
        {
          name: 'apr',
          type: 'u64',
        },
        {
          name: 'pending_rewards',
          type: 'BigUint',
        },
        {
          name: 'accrual_timestamp',
          type: 'u64',
        },
        {
          name: 'cap_percentage',
          type: 'Option<u64>',
        },
        {
          name: 'stored_exchange_rate',
          type: 'BigUint',
        },
        {
          name: 'current_exchange_rate',
          type: 'BigUint',
        },
      ],
    },
    TokenPriceQuote: {
      type: 'struct',
      fields: [
        {
          name: 'money_market',
          type: 'Address',
        },
        {
          name: 'underlying_id',
          type: 'EgldOrEsdtTokenIdentifier',
        },
        {
          name: 'ticker',
          type: 'bytes',
        },
        {
          name: 'timestamp',
          type: 'u64',
        },
        {
          name: 'price',
          type: 'BigUint',
        },
        {
          name: 'currency',
          type: 'bytes',
        },
      ],
    },
    WrappedTaoData: {
      type: 'struct',
      fields: [
        {
          name: 'address',
          type: 'Address',
        },
        {
          name: 'is_active',
          type: 'bool',
        },
        {
          name: 'token_id',
          type: 'TokenIdentifier',
        },
        {
          name: 'token_supply',
          type: 'BigUint',
        },
        {
          name: 'fwd_fee',
          type: 'u64',
        },
        {
          name: 'bwd_fee',
          type: 'u64',
        },
        {
          name: 'next_fwd_fee',
          type: 'Option<tuple<u64,u64>>',
        },
        {
          name: 'revenue',
          type: 'BigUint',
        },
        {
          name: 'max_rewards',
          type: 'BigUint',
        },
        {
          name: 'bridged_rewards',
          type: 'BigUint',
        },
        {
          name: 'min_burn_amount',
          type: 'BigUint',
        },
      ],
    },
  },
};

export default lensABI;
