import classNames from 'classnames';
import { domAnimation, LazyMotion, m } from 'framer-motion';
import React, { ReactNode, useState } from 'react';
import { useInterval } from 'usehooks-ts';

import useSetVariantOnLoadingEnd, {
  TRANSITION_VARIANTS,
} from '@/hooks/framerMotion/useSetVariantOnLoadingEnd';

const AnimateBanners: React.FunctionComponent<{
  children: ReactNode[];
  duration?: number;
}> = ({ children, duration = 15000 }) => {
  const { controls } = useSetVariantOnLoadingEnd({ timeout: 150 });

  const [currentIndex, setIndex] = useState(0);

  useInterval(() => {
    setIndex((_) => (_ + 1) % children.length);
  }, duration);

  return (
    <LazyMotion features={domAnimation}>
      <m.div
        initial='hidden'
        custom={{ reversed: true, movePx: 12 }}
        variants={TRANSITION_VARIANTS}
        animate={controls}
        className='relative isolate mb-4 grid rounded-[12px] bg-black'
      >
        {children.map((child, i) => (
          <div
            key={i}
            className={classNames(
              'col-start-1 row-start-1',
              'origin-center transition-all duration-700 ease-in-out',
              i === currentIndex ? 'z-10 opacity-100 ' : 'opacity-0'
            )}
          >
            {child}
          </div>
        ))}
      </m.div>
    </LazyMotion>
  );
};

export default AnimateBanners;
