import { motion } from 'framer-motion'

function EyeIcon({ size, fill, hoverFill, className }: { size: number, fill: string, hoverFill?: string, className?: string }) {
  const variants = {
    "initial": {
      fill
    },
    "hover": {
      fill: hoverFill
    },
  }
  return (<svg
    className={className}
    width={size} height="auto" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1264_25461)">
      <motion.path d="M7.16074 3.08908C6.58538 3.11678 6.00183 3.08908 5.43717 3.18288C4.24553 3.38054 3.3359 4.36759 3.15964 5.56553C3.11726 5.85342 2.94792 5.99758 2.65164 5.998C2.19022 5.998 1.72816 5.9917 1.26737 6.00177C1.00676 6.00744 0.91863 5.90294 0.934997 5.64233C1.09552 3.08719 3.24085 0.994742 5.80165 0.924238C6.72387 0.899058 7.64734 0.92235 8.57081 0.913537C8.79743 0.911648 8.89438 0.991594 8.8906 1.22451C8.88179 1.74888 8.87927 2.27325 8.8906 2.79763C8.89626 3.05446 8.77981 3.12308 8.54626 3.11741C8.08484 3.10734 7.62342 3.11426 7.162 3.11426L7.16074 3.08782V3.08908Z"
        variants={variants}
      />
      <motion.path d="M4.87872 11.0597C4.39652 11.0597 3.9137 11.0528 3.43213 11.0628C3.20488 11.0679 3.10857 10.9879 3.11234 10.7537C3.12179 10.2294 3.12431 9.705 3.11109 9.18126C3.10479 8.92442 3.21936 8.85769 3.45227 8.85958C4.26999 8.86713 5.08771 8.86336 5.90606 8.8621C7.47792 8.85958 8.63871 7.85175 8.85337 6.2906C8.88359 6.07279 8.9585 5.97963 9.18826 5.98529C9.70194 5.99788 10.2156 5.99725 10.7293 5.98466C10.9918 5.97837 11.0755 6.07531 11.0629 6.33781C10.9521 8.6103 9.09258 10.6757 6.82387 10.986C6.18304 11.0735 5.52836 11.0578 4.87998 11.0893C4.87998 11.0798 4.87935 11.0697 4.87872 11.0603V11.0597Z"
        variants={variants}
      />
      <motion.path d="M7.44551 5.98743C7.44551 6.79759 6.81035 7.43716 6.00207 7.44031C5.20639 7.44346 4.54604 6.78311 4.54982 5.98931C4.5536 5.18607 5.19883 4.53895 6.00081 4.53517C6.80091 4.5314 7.44551 5.17915 7.44551 5.98743Z"
        variants={variants}
      />
    </g>
    <defs>
      <clipPath id="clip0_1264_25461">
        <rect width="10.1311" height="10.1758" fill={fill} transform="translate(0.934326 0.912109)" />
      </clipPath>
    </defs>
  </svg>
  );
}

export default EyeIcon;