import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { useTranslation } from 'next-i18next';
import { FormEvent, useEffect, useMemo, useState } from 'react';

import { useLogin } from '@/hooks/auth/useLogin';
import useMediaQuery from '@/hooks/useMediaQuery';

import Hint from '@/components/Hint';
import {
  AmountInput,
  Description,
  Header,
  SliderInput,
} from '@/components/popups/HTMStakePopup/components';
import HTMStaked from '@/components/popups/HTMStakePopup/components/HTMStaked';
import PopupBed from '@/components/popups/PopupBed';
import TransactionButton from '@/components/TransactionButton';

import { useAppDispatch, useAppSelector } from '@/store/index';
import { closePopup, openPopup, popupSelector } from '@/store/popup';
import { hasEnoughEGLDBalanceSelector } from '@/store/protocol';
import { hasPendingTransactionsSelector } from '@/store/transaction';

import { formatNumber } from '@/utils/helpers';

const HTMStakePopup = () => {
  const {
    data: { interaction },
  } = useAppSelector(popupSelector);
  const hasEnoughEGLDBalance = useAppSelector(hasEnoughEGLDBalanceSelector);
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const { t } = useTranslation('governance');

  const [amount, setAmount] = useState<string>('0');
  const [slider, setSlider] = useState<string | undefined>('0');

  const md = useMediaQuery('(min-width: 768px)', window.innerWidth > 768);

  const balance = useMemo(() => {
    return '10';
  }, []);

  const isBtnValid = useMemo(
    () =>
      typeof amount !== 'undefined' &&
      hasEnoughEGLDBalance &&
      !new DefiUtils(amount).isZero() &&
      new DefiUtils(amount).isLessThan(balance),
    [amount, hasEnoughEGLDBalance, balance],
  );

  const handleSlider = (value: string | undefined) => {
    setSlider(value);
  };

  const handleVote = async (event: FormEvent) => {
    event.preventDefault();

    if (hasPendingTransactions) {
      return;
    }
  };

  useEffect(() => {
    const amount = new DefiUtils(balance || 0)
      .multipliedBy(slider || 0)
      .dividedBy(100)
      .toSafeFixed(18, DefiUtils.ROUND_FLOOR);
    setAmount(amount);
  }, [balance, slider]);

  const { isLoggedIn } = useLogin();
  const dispatch = useAppDispatch();

  const handleConnectWallet = () => {
    dispatch(closePopup());
    setTimeout(() => {
      dispatch(
        openPopup({
          name: 'connect_bridge_multiverse_wallet',
        }),
      );
    }, 100);
  };

  return (
    <PopupBed width={400} close={false}>
      <Header />
      <form
        onSubmit={handleVote}
        className={classNames(
          md ? 'max-w-[400px]' : 'max-w-[350px]',
          'px-4',
          'pt-[22px]',
          'pb-[16px]',
          'font-semibold',
        )}
      >
        <AmountInput max={balance} value={amount} onChange={setAmount} />

        <HTMStaked
          balance={balance}
          nextBalance={
            new DefiUtils(amount).isGreaterThan(0)
              ? new DefiUtils(balance).minus(amount).toString()
              : undefined
          }
        />

        <SliderInput value={slider} onChange={handleSlider} />

        <Description />

        {!isLoggedIn ? (
          <TransactionButton
            onClick={handleConnectWallet}
            className='h-[48px] capitalize'
            text={t('translation:connect-wallet')}
          />
        ) : (
          <Hint
            content={
              hasPendingTransactions
                ? t('translation:wait-tx-inprogress')
                : t('translation:tx-not-allowed')
            }
            placement='top-center'
            className='w-full'
            unvisible={!hasPendingTransactions}
          >
            <TransactionButton
              className='h-[48px] capitalize'
              text={t(interaction, interaction)}
              disabled={!isBtnValid}
              type='submit'
            />
          </Hint>
        )}

        <div
          className={classNames(
            'mt-[8px]',
            'flex',
            'justify-between',
            'text-[12px]',
            'leading-[15px]',
            'font-regular',
            'text-[#6A6A8C]',
          )}
        >
          <span>Wallet Balance</span>

          <span>{formatNumber(balance)} HTM</span>
        </div>
      </form>
    </PopupBed>
  );
};

export default HTMStakePopup;
