import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useCounter, useInterval } from 'usehooks-ts';

import { useAppDispatch, useAppSelector } from '@/store';
import { closePopup, popupSelector } from '@/store/popup';

import Overlay from './Overlay';

const SELECTOR = '[data-body-block-scroll]';

type GenericWrapperProps = {
  children: React.ReactNode;
  overlayProps?: {};
};

const GenericWrapper = ({
  children,
  overlayProps = {},
}: GenericWrapperProps) => {
  const dispatch = useAppDispatch();
  const { isOpen } = useAppSelector(popupSelector);
  const router = useRouter();
  const { count, increment } = useCounter(0);

  useInterval(increment, 1000);

  useEffect(() => {
    // @ts-ignore
    const targetElements: HTMLElement[] = Array.from(
      document.querySelectorAll(SELECTOR),
    );
    const $body = document.querySelector('body');

    if (isOpen) {
      // lock(targetElements);
      $body?.style.setProperty('overflow-y', 'hidden');
    }

    return () => {
      // unlock(targetElements);
      $body?.style.removeProperty('overflow-y');
    };
  }, [isOpen, count]);

  useEffect(() => {
    const closePopupWrap = () => dispatch(closePopup());

    router.events.on('routeChangeStart', closePopupWrap);

    return () => {
      router.events.off('routeChangeStart', closePopupWrap);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Overlay {...overlayProps}>{children}</Overlay>;
};

export default GenericWrapper;
