import classNames from 'classnames';
import DefiUtils from 'defi-utils';
import { useRouter } from 'next/router';
import { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useSignMultipleTransactions from '@/hooks/core/useSignMultipleTransactions';
import useLendInteraction from '@/hooks/interaction/useLendInteraction';
import useTailwindBreakpoint from '@/hooks/useTailwindBreakpoint';
import useTheme from '@/hooks/useTheme';

import ClosePopupBtn from '@/components/ClosePopupBtn';
import Hint from '@/components/Hint';
import PopupBed from '@/components/popups/PopupBed';

import { useAppSelector } from '@/store';
import { boosterSelector } from '@/store/booster';
import { getRouteGroup } from '@/store/popup';
import { MARKET_KEY, protocolSelector } from '@/store/protocol';
import {
  hasPendingTransactionsSelector,
  TRANSACTION_SUBGROUP_TYPE,
} from '@/store/transaction';

import TriangleWarning from '@/sections/Lend/MyPosition/icons/TriangleWarning';
import clsxm from '@/services/clsxm';


const PositionBalancerPopup = () => {
  const { markets } = useAppSelector(protocolSelector);
  const { boosterAccount } = useAppSelector(boosterSelector);
  const { isDark } = useTheme();
  const { t } = useTranslation();
  const { signTransactions } = useSignMultipleTransactions();
  const { rebalancePortfolio } = useLendInteraction();
  const isLg = useTailwindBreakpoint('lg', { serverValue: true });
  const hasPendingTransactions = useAppSelector(hasPendingTransactionsSelector);
  const router = useRouter();

  const boosterItems = useMemo(() => {
    return Object.entries(boosterAccount)
      .map(([marketKey, { percentageBoost, hasCollateral }]) => {
        const market = markets[marketKey as MARKET_KEY];

        return {
          name: market.underlying?.name,
          logo: market.logo?.normal,
          percentageBoost,
          hasCollateral,
        };
      })
      .filter(
        ({ percentageBoost, hasCollateral }) =>
          hasCollateral && new DefiUtils(percentageBoost).isLessThan(100),
      );
  }, [boosterAccount, markets]);

  return (
    <PopupBed close={false} enableScaling={false} width={isLg ? 399 : 308.5}>
      <div className='relative h-fit bg-white dark:bg-[#191733]'>
        <div
          className={classNames(
            'h-fit text-[#3C3A40] dark:text-white',
            'lg:p-[16px]',
            'px-[16px] py-[12.67px]',
          )}
        >
          <div
            className={clsxm(
              'relative flex justify-center items-center h-full w-full',
            )}
          >
            <p
              className={clsxm([
                'inline-block font-hass font-[600] ',
                'lg:text-[18px] lg:leading-[24px] lg:mr-0',
                'mr-[0.9px] text-[14px] leading-[17.5px]',
              ])}
            >
              {/* {t('position-balancer')} */}
              Position Balancer
            </p>

            <ClosePopupBtn
              className={clsxm([
                'absolute right-0 top-1/2 z-10  m-0 flex -translate-y-1/2 items-center  justify-center ',
                'lg:mt-[-1.1px] lg:mr-[-0.5px] lg:w-[18px] lg:px-[1.6px] lg:pb-[1.05px] lg:pt-[2.05px]',
                'mt-[0.3px] h-[13.97px] w-[13.97px] px-[2.8px] py-[2.7px]',
              ])}
              iconClassName={clsxm(['w-full h-full text-[11px]'])}
              theme={{ light: '#1E1E1E', dark: 'white' }}
            />
          </div>
        </div>

        <div
          className={clsxm([
            'lg:px-[20px] lg:py-[20px] lg:pt-[19px] ',
            'px-[16px] py-[16px] pt-[17px]',
          ])}
        >
          <div className='font-[600] '>
            <div
              className={clsxm(
                'flex items-start bg-[#FFE9E9] dark:bg-[#6E2F2F99]',
                'p-[9.26px] pl-[8.7px] pb-[8px] pr-0 lg:px-[11.5px] lg:py-[12px]',
                'max-h-[57.57px] lg:max-h-fit',
                'rounded-[8px] lg:rounded-[12px] ',
              )}
            >
              <TriangleWarning />
              <p
                className={clsxm(
                  'text-[#976363] dark:text-[#FFE8E8]',
                  'ml-[4px] text-[10px] leading-[12.8px] max-w-[238.68px]',
                  'lg:ml-[8px] lg:text-[12px] lg:leading-[15px] lg:max-w-[292px]',
                )}
              >
                Due to price fluctuations, the following positions in the HTM
                Booster have become unbalanced, please consider rebalancing
                them:
              </p>
            </div>

            <div className='mt-[16px] lg:mt-[20px] text-[#6A6A8C] dark:text-white'>
              {boosterItems.map((boosterItem, boosterItemIndex) => {
                return (
                  <div
                    key={boosterItem.name}
                    className={classNames(
                      'flex items-center justify-start gap-0 lg:gap-[12.2px]',
                      boosterItemIndex !== 0 ? 'mt-[5px] lg:mt-[12px]' : '',
                    )}
                  >
                    <div className='w-[4.64px] h-[4.64px] lg:w-[6px] lg:h-[6px] rounded-full bg-[#63637E] dark:bg-[#6A6A8C]' />
                    <img
                      className={classNames(
                        'gradient-border rounded-full ml-[9.3px] lg:ml-0',
                      )}
                      style={
                        {
                          ['--border-size']: '1.9px',
                          ['--content-background']: '#DFDBEE',
                          ['--linear-gradient']: isDark
                            ? '#isLg?30:252C60'
                            : 'linear-gradient(90deg, #C2C7FF 0%, #CED3FF 100%)',
                        } as CSSProperties
                      }
                      width={isLg ? 30 : 25}
                      height={isLg ? 30 : 25}
                      src={boosterItem.logo}
                      alt={boosterItem.name}
                    />
                    <p className='text-[12px] lg:text-[14px] ml-[8.19px] lg:ml-[-0.3px]'>
                      {boosterItem.name}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          <Hint
            content={
              hasPendingTransactions
                ? t('translation:wait-tx-inprogress')
                : t('translation:tx-not-allowed')
            }
            placement='top-center'
            className='w-full'
            unvisible={!hasPendingTransactions}
          >
            <button
              className={clsxm(
                'btnHover w-[273.7px] lg:w-full font-[600] text-white bg-gradient-to-l from-[#49D2AE] to-[#8474E3] uppercase',
                'mt-[15.4px] text-[12px] rounded-[8px] py-[12px] px-[0px]',
                'lg:mt-[20px] lg:text-[14px] lg:rounded-[10px] lg:py-[12px] lg:px-[97]',
              )}
              disabled={hasPendingTransactions}
              onClick={() => {
                signTransactions([rebalancePortfolio()], {
                  group: getRouteGroup(),
                  subgroup: TRANSACTION_SUBGROUP_TYPE.REBALANCE_PORTFOLIO,
                });
              }}
            >
              rebalance Position
            </button>
          </Hint>
        </div>
      </div>
    </PopupBed>
  );
};

export default PositionBalancerPopup;
