import React from 'react';

const ScrollBlockTargetElement = React.forwardRef<any, any>((props, ref) => {
  const { children, ...attr } = props;
  return (
    <div data-body-block-scroll ref={ref} {...attr}>
      {children}
    </div>
  );
});

ScrollBlockTargetElement.displayName = 'ScrollBlockTargetElement';

export default ScrollBlockTargetElement;
