import { AbiRegistry, Address, AddressValue } from '@multiversx/sdk-core/out';

import liquidLockingABI from '@/abis/liquid-locking';
import client from '@/services/blockchain/client';
import {
  LockedToken,
  UnlockedToken,
} from '@/services/blockchain/liquid-locking/types';

export const getUnbondPeriod = async (
  liquidLockingAddress: string,
): Promise<string> => {
  const response = await client({
    address: liquidLockingAddress,
    method: 'unbondPeriod',
    abi: AbiRegistry.create(liquidLockingABI),
  });

  const value = parseInt(response?.[0]?.toString() || '0');

  return (value + 1).toString();
};

export const getLockedTokenAmounts = async (
  liquidLockingAddress: string,
  accountAddress: string,
): Promise<LockedToken[]> => {
  if (!accountAddress) {
    return [];
  }

  const response = await client({
    address: liquidLockingAddress,
    method: 'lockedTokenAmounts',
    abi: AbiRegistry.create(liquidLockingABI),
    args: [new AddressValue(new Address(accountAddress))],
  });

  const items = response?.[0]?.backingCollection?.items || [];

  return (
    items.map((item) => {
      return {
        tokenIdentifier: item.fieldsByName
          .get('token_identifier')
          .value.value.toString(),
        tokenNonce: item.fieldsByName.get('token_nonce').value.value.toString(),
        amount: item.fieldsByName.get('amount').value.value.toString(),
      };
    }) || []
  );
};

export const getUnlockedTokenAmounts = async (
  liquidLockingAddress: string,
  accountAddress: string,
): Promise<UnlockedToken[]> => {
  if (!accountAddress) {
    return [];
  }

  const response = await client({
    address: liquidLockingAddress,
    method: 'unlockedTokenAmounts',
    abi: AbiRegistry.create(liquidLockingABI),
    args: [new AddressValue(new Address(accountAddress))],
  });

  const items = response?.[0]?.backingCollection?.items || [];

  return (
    items.map((item) => {
      const tokenField = item.fieldsByName.get('token').value;

      return {
        token: {
          tokenIdentifier: tokenField.fieldsByName
            .get('token_identifier')
            .value.toString(),
          tokenNonce: tokenField.fieldsByName
            .get('token_nonce')
            .value.toString(),
          amount: tokenField.fieldsByName.get('amount').value.toString(),
        },
        unbondEpoch: item.fieldsByName.get('unbond_epoch').value.toString(),
      };
    }) || []
  );
};
