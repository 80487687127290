import { Account, Address } from '@multiversx/sdk-core';
import { WalletProvider } from '@multiversx/sdk-web-wallet-provider';
import { captureException } from '@sentry/nextjs';
import { useTranslation } from 'next-i18next';
import { useRef } from 'react';
import { toast } from 'react-hot-toast';

import { Logout } from '@/hooks/auth/useLogout';

import LogoutIcon from '@/components/Icons/Logout';

import { useAppDispatch, useAppSelector } from '@/store';
import {
  accountSelector,
  clearAuthStates,
  setAccountState,
  setLoggingInState,
  setLoginInfoState,
} from '@/store/auth';
import {
  clearDappProvider,
  networkStateSelector,
  setNetworkState,
} from '@/store/network';

import { chainType, DAPP_INIT_ROUTE, networkConfig } from '@/config/network';
import { getNewLoginExpiresTimestamp } from '@/utils/expiresAt';
import { getParamFromUrl } from '@/utils/getParamFromUrl';

import { Login } from '@/types/account';
import { LoginMethodsEnum } from '@/types/enums';

export const useWebWalletLogin = (params?: Login) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const accountSnap = useAppSelector(accountSelector);
  const provider = useAppSelector(
    networkStateSelector<WalletProvider>('dappProvider')
  );
  const providerRef = useRef<any>(provider);

  const initiateLogin = () => {
    try {
      const address = getParamFromUrl('address') || accountSnap?.address;
      const signature = getParamFromUrl('signature');

      if (signature) {
        dispatch(setLoginInfoState('signature', signature));
      }

      if (address) {
        const dappProvider = new WalletProvider(
          `${networkConfig[chainType].walletAddress}${DAPP_INIT_ROUTE}`
        );
        dispatch(setNetworkState('dappProvider', dappProvider));
        const userAddressInstance = new Address(address);
        const userAccountInstance = new Account(userAddressInstance);
        dispatch(
          setAccountState('address', userAccountInstance.address.bech32())
        );
      }
    } catch (error) {
      captureException(error);
    }
  };

  const logout = async (params?: Logout) => {
    const provider = params?.dappProvider || providerRef.current;

    if (!provider) {
      dispatch(clearAuthStates());
      dispatch(clearDappProvider());
      return;
    }

    try {
      dispatch(setLoggingInState('pending', true));
      await provider.logout();

      dispatch(clearAuthStates());
      dispatch(clearDappProvider());

      if (params?.callbackRoute) {
        if (typeof params?.redirectFn === 'function') {
          params?.redirectFn(params?.callbackRoute);
        } else if (typeof window !== 'undefined') {
          window.location.href = params?.callbackRoute;
        }
      }

      dispatch(setLoggingInState('loggedIn', false));
      toast(t('logged-out'), {
        icon: (
          <div className='grid h-[18px] w-[18px] place-content-center rounded bg-[#E45059] text-white'>
            <LogoutIcon height={14} />
          </div>
        ),
        id: 'logged-out',
      });
    } catch (e: any) {
      captureException(e);
      toast.error(t('logout-error-retry'), { duration: 4000 });
      dispatch(setLoggingInState('error', e?.message));
    } finally {
      dispatch(setLoggingInState('pending', false));
    }
  };

  const login = async () => {
    dispatch(setLoggingInState('pending', true));

    const providerInstance = new WalletProvider(
      `${networkConfig[chainType].walletAddress}${DAPP_INIT_ROUTE}`
    );

    const callbackUrl: string =
      typeof window !== 'undefined'
        ? encodeURIComponent(
            `${window.location.origin}${
              params?.callbackRoute || window.location.pathname
            }`
          )
        : '/';

    const providerLoginData = {
      callbackUrl,
      ...(params?.token && { token: params?.token }),
    };

    try {
      await providerInstance.login(providerLoginData);
      dispatch(setLoginInfoState('loginMethod', LoginMethodsEnum.wallet));
      dispatch(setLoginInfoState('expires', getNewLoginExpiresTimestamp()));
      if (params?.token) {
        dispatch(setLoginInfoState('loginToken', params.token));
      }
    } catch (e: any) {
      captureException(e);
      dispatch(setLoggingInState('error', `Error logging in ${e?.message}`));
    } finally {
      dispatch(setLoggingInState('pending', false));
    }
  };

  return {
    login,
    logout,
    initiateLogin,
  };
};
