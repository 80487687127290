import React from 'react';

const Separator = () => {
  return (
    <svg
      width='416'
      height='2'
      viewBox='0 0 416 2'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='w-full'
    >
      <line
        x1='1.2'
        y1='1.3'
        x2='414.8'
        y2='1.3'
        stroke='url(#paint0_linear_14428_34681)'
        strokeWidth='1.4'
        strokeLinecap='round'
      />
      <defs>
        <linearGradient
          id='paint0_linear_14428_34681'
          x1='412'
          y1='4.99845'
          x2='25'
          y2='1.99845'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#DADAE8' stopOpacity='0' />
          <stop offset='0.544367' stopColor='#DCDCE2' />
          <stop offset='1' stopColor='#DADAE8' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Separator;
