const FilterDashIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  const { fill, ...rest } = props;

  return (
    <svg
      width='12.4px'
      height='12.4px'
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 14"
      fill="none"
      {...rest}
    >
      <path
        d="M5.76562 9.64453H7.72506"
        stroke={fill}
        strokeWidth="0.89"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.21094 6.36328H4.17034"
        stroke={fill}
        strokeWidth="0.89"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.30469 6.15234H11.2641"
        stroke={fill}
        strokeWidth="0.89"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.74219 11.326V9.64453"
        stroke={fill}
        strokeWidth="0.89"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.1875 11.3266V6.36328"
        stroke={fill}
        strokeWidth="0.89"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2891 11.329V6.15234"
        stroke={fill}
        strokeWidth="0.89"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.1875 4.31668V2.67578"
        stroke={fill}
        strokeWidth="0.89"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2891 4.10337V2.67578"
        stroke={fill}
        strokeWidth="0.89"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.74219 7.59848V2.67578"
        stroke={fill}
        strokeWidth="0.89"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterDashIcon;
