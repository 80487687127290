import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';

import TitleAndSub from '@/components/popups/AccountDetailsPopup/components/TokenRow/TitleAndSub';

export function TokenRowSkeleton({ other = false }) {
  return (
    <div
      className={classNames(
        'flex justify-between rounded-xl border p-2 py-2.5 text-lg font-semibold leading-tight tracking-normal',
        'border-[#D3D0E4] bg-white dark:border-[#2E2C51] dark:bg-[#34315A]'
      )}
    >
      <figure className='flex items-center gap-2'>
        <Skeleton height={36} width={36} circle className='aspect-square' />
        <TitleAndSub
          title={<Skeleton width={100} />}
          sub={other ? undefined : <Skeleton width={40} />}
        />
      </figure>

      <div className='flex items-center'>
        <TitleAndSub
          title={
            other ? (
              <div className='flex items-center gap-1'>
                <Skeleton width={86} />
                <Skeleton width={32} />
              </div>
            ) : (
              <Skeleton width={100} />
            )
          }
          sub={other ? undefined : <Skeleton width={70} />}
          right
        />
      </div>
    </div>
  );
}
