import React, { ReactNode } from 'react';

import useCanAnimate from '@/hooks/useCanAnimate';

interface AnimationController {
  canAnimate?: boolean;
  can?: boolean;
}

const initialState: AnimationController = {
  canAnimate: undefined,
  can: undefined,
};

export const AnimationControllerContext =
  React.createContext<AnimationController>(initialState);

export const AnimationControllerProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const can = useCanAnimate();

  return (
    <AnimationControllerContext.Provider value={{ canAnimate: can, can }}>
      {children}
    </AnimationControllerContext.Provider>
  );
};

type MediaOptimizationPipeProps = {
  children: ReactNode;
  optimized: ReactNode;
};

const MediaOptimizationPipe = ({
  children,
  optimized,
}: MediaOptimizationPipeProps) => {
  if (!optimized) throw Error('define the optimized version');
  if (!children) throw Error('no way, without children ');

  return (
    <AnimationControllerContext.Consumer>
      {({ can }) => (can === undefined ? <></> : can ? children : optimized)}
    </AnimationControllerContext.Consumer>
  );
};

export { MediaOptimizationPipe };
