import logger from '@/utils/logger';

function safeJsonParse<T>(jsonString: string, defaultValue: T) {
  try {
    const parsedJson = JSON.parse(jsonString);

    return parsedJson;
  } catch (error) {
    logger.error('Error parsing JSON:', error);
    return defaultValue;
  }
}

export default safeJsonParse;
